import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    passwordEyeToggleForPassword: boolean;
    passwordInputTypeForPassword: string;
    passwordEyeToggleForCon_Password: boolean;
    passwordInputTypeForCon_Password: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class SignUpDetailsControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiAccountDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            passwordEyeToggleForPassword: true,
            passwordInputTypeForPassword: 'password',
            passwordEyeToggleForCon_Password: true,
            passwordInputTypeForCon_Password: 'password',
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForAccountDetail(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
    handleGoBack = () => {
        this.props.navigation.navigate('EmailAccountLogin')
    }

    handlePasswordToggle = () => {
        this.setState({
            passwordEyeToggleForPassword: !this.state.passwordEyeToggleForPassword,
        }, () => {
            this.setState({
                passwordInputTypeForPassword: this.state.passwordEyeToggleForPassword == false ? "text" : "password"
            })
        })
    }

    handlePasswordToggleForCon_Password = () => {
        this.setState({
            passwordEyeToggleForCon_Password: !this.state.passwordEyeToggleForCon_Password,
        }, () => {
            this.setState({
                passwordInputTypeForCon_Password: this.state.passwordEyeToggleForCon_Password == false ? "text" : "password"
            })
        })
    }

    //API Function
    settingUpAccountDetails = (value: any) => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem("SignUpToken")
        };

        const attrs = {
            "full_name": value.full_name,
            "password": value.password,
            "confirm_password": value.password
        };

        const data = {
            "type": "email_account",
            attributes: attrs
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiAccountDetailsCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_details'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //API Response Function
    handleResForAccountDetail = async (from: string, message: Message) => {
        if (this.apiAccountDetailsCallId == message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                localStorage.setItem("SignUpToken", responseJson.meta.token)
                localStorage.setItem("accountID", responseJson.data.attributes.restaurant_locations[0].id)
                this.props.navigation.navigate('LocationSignUp')
                ToastMessage("success", "Account Details Added Successfully")
            } else {
                ToastMessage("error", "Something Went Wrong")
            }
        }

    }

    // Customizable Area End

}