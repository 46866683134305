import React from 'react'

// Customizable Area Start
import { Grid } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import SubscriptionPurchasedController from './SubscriptionPurchasedController';
import PulseLoader from "react-spinners/PulseLoader";

const dummyData = [
    {id:1,name:"Customer Reservations",is_selected:true},
    {id:2,name:"Waitlist",is_selected:true},
    {id:3,name:"Restaurant Profile Update",is_selected:true},
    {id:4,name:"Dashboard",is_selected:true},
    {id:5,name:"Fudu Support",is_selected:true},
    {id:6,name:"Upgrade Subscription",is_selected:true},
    {id:7,name:"Payment preference",is_selected:true},
    {id:8,name:"Social Media",is_selected:false},
    {id:9,name:"Customer Data",is_selected:false},
    {id:10,name:"Chat with customer",is_selected:false},
]
// Customizable Area End

// Customizable Area Start
class SubscriptionPurchased extends SubscriptionPurchasedController {

    render() {
        const { subscriptionPurchasedData, isLoading } = this.state;
        return (
            <>
                <h2>My Subscription</h2>
                <Grid container>
                    <Grid item sm={12}>
                        <div className="mylocation">
                            <h2 className="your-subscription-head">Your Subscription</h2>
                            <Grid container justifyContent="center">
                                {isLoading ? <PulseLoader color="#096fff" className="loader" /> : 
                                <Grid item lg={7}>
                                    <section className="your-subscription">
                                        <div className="billing-info">
                                            <div>
                                                <span>Billing Amount</span>
                                                <h3>KWD 100<span>/Yearly</span></h3>
                                            </div>
                                            <div>
                                                <span>Subscription Type</span>
                                                <h3>Basic</h3>
                                            </div>
                                            <div>
                                                <button className="btn-theme">Expiring in 7 Days</button>
                                                <p>Upgrade to Premium</p>
                                            </div>
                                        </div>
                                        <div className="plan-details">
                                            <ul>
                                                <Grid container>
                                                    {dummyData?.map((planOptions: any,i:number) => {
                                                        return (
                                                            <Grid item lg={6}  key={i}>
                                                                <li className="innerbasic">
                                                                    {planOptions?.is_selected ? 
                                                                    <CheckCircleRoundedIcon style={{ "color": "22AB4D", padding: "0 10px 0 0" }} /> :
                                                                    <CancelRoundedIcon style={{ "color": "DB1C1C", padding: "0 10px 0 0" }} /> }
                                                                    {planOptions?.name} 
                                                                </li>
                                                            </Grid>
                                                        )
                                                    })}
                                                </Grid>
                                            </ul>
                                            <div className="text-end" style={{ padding: "0 45px 30px 0" }}>
                                                <button className="btn-theme">Renew now</button>
                                            </div>
                                        </div>
                                    </section>
                                </Grid>}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default SubscriptionPurchased;
// Customizable Area End
