import React from "react";
// Customizable Area Start
import { Grid, Modal, Box, Select } from "@material-ui/core";
import LocationControllerWeb, { Props } from "./LocationControllerWeb";
//import { cancel } from "./assets";
// @ts-ignore
import GoogleMapReact from "google-map-react";
import { Formik } from "formik";
import * as Yup from "yup";
import "./MyLocation.css";
// @ts-ignore 
import Geocode from "react-geocode";
import { cancel } from "./assets";
Geocode.setApiKey("AIzaSyDggGoFSnzL7Vley8afXotWIAJWB4-DZcA");
Geocode.enableDebug();
import PlacesAutocomplete from "react-places-autocomplete";
import { styled } from "@material-ui/core/styles";
import { Country } from "country-state-city";
// Customizable Area End

const websiteReg = "(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})";

const startTimeSchema = {
  start_mins: Yup.string().required("Can't be Empty").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
  start_hours: Yup.string().required("Can't be Empty").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
  start_time_type: Yup.string().required("Can't be Empty"),
};

const endTimeSchema = {
  end_hours: Yup.string().required("Can't be Empty").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
  end_mins: Yup.string().required("Can't be Empty").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
  end_time_type: Yup.string().required("Can't be Empty"),
};

const schema = Yup.object().shape({
  restaurant_name: Yup.string().required("This Field is required *"),
  restaurant_nick_name: Yup.string().required("This Field is required *"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  location: Yup.string().required("This Field is required *"),
  full_phone_number: Yup.string()
    .min(7, "Please enter valid mobile number")
    .max(15, "Please enter valid mobile number")
    .required("Please enter mobile number "),
  website: Yup.string()
    .matches(
      // @ts-ignore
      websiteReg,
      "Please enter a valid url"
    )
    .required("This Field is required *"),
  average_price: Yup.string().required("This Field is required *"),
  overview: Yup.string().required("This Field is required *"),
  address: Yup.string().required("This Field is required *"),
  city: Yup.string().test('required',
  'City is Required', (val) => val !== "placeholder"),
  state: Yup.string().test('required',
  'State is Required', (val) => val !== "placeholder"),
  country: Yup.string().test('required',
  'Country is Required', (val) => val !== "placeholder"),
  ...startTimeSchema, ...endTimeSchema
});

const secondFormSchema = Yup.object().shape({
  valet_parking_availability: Yup.string().required("This Field is required *"),
  parking_availability: Yup.string().required("This Field is required *"),
  kids_area: Yup.string().required("This Field is required *"),
  serves_alcohol: Yup.string().required("This Field is required *"),
  sheesha: Yup.string().required("This Field is required *"),
  dining: Yup.string().required("This Field is required *"),
  category: Yup.string().required("This Field is required *"),
  speciality: Yup.string().required("This Field is required *"),
  type_of_place: Yup.string().required("This Field is required *"),
  smoking_area: Yup.string().required("This Field is required *"),
});

class AddAnotherLocation extends LocationControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  AddInput = (handleChange: any, handleBlur: any, touched: any, errors: any, inputType: string, inputName: string, inputPlaceholderLabel: string) => {
    return (
      <div className="mb15">
        <label>{inputPlaceholderLabel}</label>
        <input
          autoComplete='false'
          onChange={handleChange}
          onBlur={handleBlur}
          type={inputType}
          name={inputName}
          placeholder={inputPlaceholderLabel} step="any"/>
        {touched[inputName] && errors[inputName] && <p className="err">{errors[inputName]}</p>}
      </div>
    )
  }

GenSelect = (formikFn: any, inputName: string, placeholderText: string, iterator: any, optionValue: any, optionText: any, handleChangeFn: Function) => {
  
  return (



      <div className="mb15">
        <label>{placeholderText.slice(6)}</label>
          <Select
              native
              disabled={iterator.length == 0}
              onBlur={formikFn.handleBlur}
              value={formikFn.values[inputName]}
              name={inputName}
              disableUnderline
              fullWidth
              onChange={(e) => handleChangeFn(e, formikFn.handleChange, formikFn.setFieldValue)}
              className="gen-select"

          >
              <option disabled value={"placeholder"}>
                  {placeholderText}
              </option>
              {iterator.map((data: any) => (
                  <option key={data[optionValue]} value={optionValue ? data[optionValue] : JSON.stringify(data)}>
                      {data[optionText]}
                  </option>
              ))}
          </Select>
          {formikFn.touched[inputName] && formikFn.errors[inputName] &&
              <p className="err">{formikFn.errors[inputName]}</p>
          }
      </div>
    )
  }

  render() {
    // Customizable Area Start
    const { step, stateOfCountryAdd , citiesOfStateAdd} = this.state;

    return (
      // Customizable Area Start
      <>
        <Grid container>
          <Grid item sm={12}>
            <Grid>
              <section>
                <p
                  className="add-another"
                  data-testid="addanother"
                  onClick={() => this.addAnotherLocationModal("add")}
                >
                  Add another location
                </p>
                {/* Add another Location Modal */}
                <Modal
                  open={this.state.addAnotherLocationModal}
                  onClose={this.hideAddAnotherLocationModal}
                  data-testid="addModal"
                >
                  <Box className="modalContainer">
                    <div className="modal-close-btn">
                      <img
                        src={cancel}
                        alt=""
                        onClick={this.hideAddAnotherLocationModal}
                      />
                      <h1 className="add-head">Add Another Location</h1>
                    </div>
                    <section className="add-modal-body">
                      <section className="add-modal-content">
                        {step === false && (
                          <Formik
                            initialValues={{
                              restaurant_name: this.state?.getAllMyLocationData[0]?.attributes?.restaurant_name,
                              location: "",
                              full_phone_number: "",
                              restaurant_nick_name: "",
                              email: "",
                              start_hours: "",
                              start_mins: "",
                              start_time_type: "AM",
                              end_hours: "",
                              end_mins: "",
                              end_time_type: "AM",
                              average_price: "",
                              website: "",
                              address: "",
                              overview: "",
                              city: 'placeholder',
                              state: 'placeholder',
                              country: 'placeholder',
                              currency_type:"KWD"
                         }}
                            validateOnBlur={false}
                            validationSchema={schema}
                            onSubmit={this.addNextButton}
                            render={({
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              dirty, isValid, isSubmitting, values, setFieldValue
                            }) => {
                              const hasErrors = (
                                (touched.start_hours && errors.start_hours) ||
                                (touched.end_hours && errors.end_hours) || (touched.start_mins && errors.start_mins) || (touched.end_mins && errors.end_mins)
                              );
                              return (
                                <form onSubmit={handleSubmit}>
                                  <Grid container spacing={6}>
                                    <Grid item lg={6}>
                                    <div className="mb15">
                                      <label htmlFor="resname">
                                        Restaurant Name
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Restaurant Name"
                                        id="resname"
                                        value={values.restaurant_name}
                                      />
                                    </div>
                                      <div className="mb15">
                                        <label htmlFor="location">Location</label>
                                        <PlacesAutocomplete
                                          value={this.state.addressWeb}
                                          onChange={(addressWeb) => this.setState({ addressWeb })}
                                          onSelect={this.addHandleSelect}
                                          data-testid='location'
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: "Search Places...",
                                                            className: "location-search-input"
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container" style={{ overflowY: 'auto', maxHeight: '100px', width: '43%', cursor: 'pointer',marginBottom:"5px" }}>
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                              return <div {...getSuggestionItemProps(suggestion)} style={{ overflowWrap: 'break-word',fontSize:"15px",padding:"3px" }}>{suggestion.description} </div>
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                      </div>
                                      {this.AddInput(handleChange, handleBlur, touched, errors, "text", "restaurant_nick_name", "Restaurant Nick Name")}
                                      {this.GenSelect(
                                          { errors, touched, handleChange, handleBlur, handleSubmit, values, setFieldValue }, 'country', 'Select Country', Country.getAllCountries(), 'isoCode', 'name', this.handleCountryChange
                                      )}
                                      {this.GenSelect(
                                          { errors, touched, handleChange, handleBlur, handleSubmit, values, setFieldValue }, 'state', 'Select State/Province', stateOfCountryAdd, '', 'name', this.handleStateChange
                                      )}
                                        {this.GenSelect(
                                          { errors, touched, handleChange, handleBlur, handleSubmit, values, setFieldValue }, 'city', 'Select City', citiesOfStateAdd, 'name', 'name', this.handleCityChange
                                      )}
                                      {this.AddInput(handleChange, handleBlur, touched, errors, "tel", "full_phone_number", "Contact no.")}
                                      {this.AddInput(handleChange, handleBlur, touched, errors, "email", "email", "Email")}
                                      <div>
                                        <label>Operational Hours</label>
                                        <div className="operational-box">
                                          <div className="working-hours">
                                            <input
                                              type="text"
                                              placeholder="hh"
                                              maxLength={2}
                                              data-testid="starthours"
                                              name="start_hours" onChange={handleChange} onBlur={handleBlur} value={values.start_hours} onKeyDown={e => this.preventTypeNumber(e)}
                                            />
                                            <span>:</span>
                                            <input
                                              type="text"
                                              placeholder="mm"
                                              maxLength={2}
                                              data-testid="startminutes"
                                              value={values.start_mins} name="start_mins" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => this.preventTypeNumber(e)}
                                            />
                                            <select onChange={handleChange}  name="start_time_type" value={values.start_time_type}>
                                              <option value="AM">AM</option>
                                              <option value="PM">PM</option>
                                            </select>
                                            <span className="work-to">to</span>
                                            <input
                                              type="text"
                                              placeholder="hh"
                                              maxLength={2}
                                              data-testid="endhours"
                                              onChange={handleChange} onBlur={handleBlur} name="end_hours" value={values.end_hours} onKeyDown={e => this.preventTypeNumber(e)} 
                                            />
                                            <span>:</span>
                                            <input
                                              type="text"
                                              placeholder="mm"
                                              maxLength={2}
                                              data-testid="endminutes"
                                              onChange={handleChange} onBlur={handleBlur} name="end_mins" value={values.end_mins} onKeyDown={e => this.preventTypeNumber(e)}
                                            />
                                            <select onChange={handleChange} data-test-id='input_end_type' name="end_time_type">
                                              <option value="AM">AM</option>
                                              <option value="PM">PM</option>
                                            </select>
                                          </div>
                                        </div>
                                        {hasErrors && ( <p className="err">
                                                        {errors.start_hours || errors.start_mins || errors.start_time_type || errors.end_hours || errors.end_mins || errors.end_time_type}
                                                    </p>
                                                )}
                                      </div>
                                   </Grid>
                                   <Grid item lg={6}>
                                      <label>Map Location</label>
                                      <div style={{ height: "270px", width: "100%", margin: "5px 0 20px 0" }}>
                                        <GoogleMapReact
                                          bootstrapURLKeys={{ key: "AIzaSyDggGoFSnzL7Vley8afXotWIAJWB4-DZcA" }}
                                          options={{ fullscreenControl: false, keyboardShortcuts: false }}
                                          center={{ lat: this.state.latitudeWeb, lng: this.state.longitudeWeb }}
                                          zoom={15}
                                          data-test-id='gmap'
                                          onChange={() => setFieldValue('location', this.state.addressWeb)}
                                          onGoogleApiLoaded={({ map, maps }: any) => {this.handleMarkerPosition(map, maps); console.log("aaa",maps)}}
                                        />
                                      </div>
                                      <div className="dai">
                                        <select name="currency_type" value={values.currency_type} onChange={handleChange} className="currency-select">
                                          <option value="KWD" selected>KWD</option>
                                          <option value="USD">USD</option>
                                        </select>
                                        <div className="w-100">
                                          {this.AddInput(handleChange, handleBlur, touched, errors, "number","average_price", "Average Price For 2")}
                                        </div>
                                      </div>
                                      {this.AddInput(handleChange, handleBlur, touched, errors, "text","website", "Website")}
                                      {this.AddInput(handleChange, handleBlur, touched, errors, "text","address", "Address" )}
                                      {this.AddInput(handleChange, handleBlur, touched, errors, "text","overview", "Overview")}
                                      <div style={{marginTop:"20px"}}>
                                        <button
                                          className="btnTransparent mr10"
                                          onClick={
                                            this.hideAddAnotherLocationModal
                                          }
                                        >
                                          Cancel
                                        </button>
                                        <button
                                          className="btnTheme"
                                          type="submit"
                                          data-testid="addNextButton"
                                        >
                                          Next
                                        </button>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </form>
                              )
                            }}
                          />)}

                        {step === true && (
                          <Formik
                            initialValues={{
                              parking_availability: "",
                              valet_parking_availability: "",
                              serves_alcohol: "",
                              kids_area: "",
                              dining: "",
                              sheesha: "",
                              speciality: "",
                              category: "",
                              smoking_area: "",
                              type_of_place: "",
                            }}
                            validationSchema={secondFormSchema}
                            onSubmit={(values) => {
                              let previousValues = JSON.parse(
                                // @ts-ignore
                                localStorage.getItem("firstFormValues")
                              );
                              this.addLocation(previousValues);
                              this.setState({
                                addAnotherLocationModal: false,
                                restaurantNo: values
                              });
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              isSubmitting,
                              isValid,
                              dirty,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <Grid container spacing={6}>
                                  <>
                                    <Grid item lg={6}>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">
                                          Parking Availability
                                        </p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="parkyes"
                                              name="parking_availability"
                                              value="true"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="parkyes">Yes</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="parkno"
                                              name="parking_availability"
                                              value="false"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="parkno">No</label></div>
                                            </div>
                                          {errors.parking_availability &&
                                            touched.parking_availability && (
                                              <p className="err">
                                                {errors.parking_availability}
                                              </p>
                                            )}

                                      </div>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">
                                          Valet Parking Availability
                                        </p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="valetparkyes"
                                              name="valet_parking_availability"
                                              value="true"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="valetparkyes">
                                              Yes
                                            </label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="valetparkno"
                                              name="valet_parking_availability"
                                              value="false"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="valetparkno">No</label> </div>
                                            </div>
                                          {errors.valet_parking_availability &&
                                            touched.valet_parking_availability && (
                                              <p className="err">
                                                {
                                                  errors.valet_parking_availability
                                                }
                                              </p>
                                            )}
                                      </div>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">Serves Alcohol</p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="alcoholyes"
                                              name="serves_alcohol"
                                              value="true"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="alcoholyes">Yes</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="alcoholno"
                                              name="serves_alcohol"
                                              value="false"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="alcoholno">No</label></div>
                                            </div>
                                          {errors.serves_alcohol &&
                                            touched.serves_alcohol && (
                                              <p className="err">
                                                {errors.serves_alcohol}
                                              </p>
                                            )}
                                      </div>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">Kids Area</p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="kidsyes"
                                              name="kids_area"
                                              value="true"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="kidsyes">Yes</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="kidsno"
                                              name="kids_area"
                                              value="false"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="kidsno">No</label></div>
                                            </div>
                                          {errors.kids_area &&
                                            touched.kids_area && (
                                              <p className="err">
                                                {errors.kids_area}
                                              </p>
                                            )}
                                      </div>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">Dining</p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="dining"
                                              name="dining"
                                              value="fine"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="dining">Fine</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="casual"
                                              name="dining"
                                              value="casual"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="casual">Casual</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="both"
                                              name="dining"
                                              value="both"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="both">Both</label>
                                          </div>
                                          </div>
                                          {errors.dining && touched.dining && (
                                            <p className="err">{errors.dining}</p>
                                          )}
                                      </div>
                                    </Grid>
                                    <Grid item lg={6}>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">Shisha</p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="sheeshayes"
                                              name="sheesha"
                                              value="true"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="sheeshayes">Yes</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="sheeshano"
                                              name="sheesha"
                                              value="false"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="sheeshano">No</label>
                                          </div>
                                          </div>
                                          {errors.sheesha && touched.sheesha && (
                                            <p className="err">
                                              {errors.sheesha}
                                            </p>
                                          )}
                                      </div>
                                      <div className="radio-box">
                                        <label htmlFor="speciality">
                                          Speciality
                                        </label>
                                        <input
                                          type="text"
                                          id="speciality"
                                          placeholder="Speciality"
                                          name="speciality"
                                          value={values.speciality || ""}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        {errors.speciality &&
                                          touched.speciality && (
                                            <p className="err">
                                              {errors.speciality}
                                            </p>
                                          )}
                                      </div>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">Category</p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="veg"
                                              name="category"
                                              value="veg"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="veg">Veg</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="nonveg"
                                              name="category"
                                              value="non_veg"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="nonveg">Non-veg</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="catboth"
                                              name="category"
                                              value="both_category"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="catboth">Both</label>
                                          </div>
                                          </div>
                                          {errors.category &&
                                            touched.category && (
                                              <p className="err">
                                                {errors.category}
                                              </p>
                                            )}
                                      </div>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">Smoking Area</p>
                                        <div className="sheesha_radio">
                                          <div>
                                            <input
                                              type="radio"
                                              id="smokingyes"
                                              name="smoking_area"
                                              value="true"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="smokingyes">Yes</label>
                                          </div>
                                          <div>
                                            <input
                                              type="radio"
                                              id="smokingno"
                                              name="smoking_area"
                                              value="false"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="smokingno">No</label>
                                          </div>
                                          </div>
                                          {errors.smoking_area &&
                                            touched.smoking_area && (
                                              <p className="err">
                                                {errors.smoking_area}
                                              </p>
                                            )}
                                      </div>
                                      <div className="radio-box">
                                        <p className="mt0 mb10">Type of Place</p>
                                        <input
                                          type="radio"
                                          id="sheesha"
                                          name="type_of_place"
                                          value="shisha"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <label htmlFor="sheesha">Shisha</label>
                                        <input
                                          type="radio"
                                          id="premium"
                                          name="type_of_place"
                                          value="premium"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <label htmlFor="premium">Premium</label>
                                        <input
                                          type="radio"
                                          id="outdoor"
                                          name="type_of_place"
                                          value="outdoor"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                        <label htmlFor="outdoor">Outdoor</label>
                                        {errors.type_of_place &&
                                          touched.type_of_place && (
                                            <p className="err">
                                              {errors.type_of_place}
                                            </p>
                                          )}
                                      </div>
                                      <div>
                                        <div>
                                          <button
                                            className="btnTransparent mr10"
                                            onClick={
                                              this.hideAddAnotherLocationModal
                                            }
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            className="btnTheme"
                                            type="submit"
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </Grid>
                                  </>
                                </Grid>
                              </form>
                            )}
                          </Formik>
                        )}
                      </section>
                    </section>
                  </Box>
                </Modal>
                {/* Add another Location Modal */}
              </section>
            </Grid>
          </Grid>
        </Grid>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default AddAnotherLocation;
export { AddAnotherLocation };
// Customizable Area End

