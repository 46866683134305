import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  nextButton: boolean
  vehicleNo: string
  vehicleModels: any[]
  selectedVehicleModel: string
  vehicleMake: any[]
  selectedVehicleMake: string
  vehicleType: any[]
  selectedVehicleType: string
  reservationDetails: {
    customerName: string
    customerEmail: string
    customerPhone: string
    NoofPeople: string

  }
  reservationDateTime: {
    reservationDate: string
    reservationTime: string
  }
  specialOccassion: boolean;
  WaitingList: boolean;
  showTable: any;
  reservationSelect: {

    specialOccation: string
    WaitingTime: string
  },
  errorMessage: any
  dietarySelect: {
    UserDietary: any,
    GuestDietary: any
  },
  buttonDisable: boolean,
  isError: boolean,
  errorText: string,
  RoomTableSelect: any,
  availabelTable: any,
  isLoader: boolean,
  Dietary: any;
  FoodPrefrences: any;
  timeSlots: any;
  opening_time: any
  reservationCreatAdded: boolean
  specialOccasion: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReservationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  vehicleDetailsApiCallIdErr: any
  getVehicleModelsApiCallId: any;
  getVehicleMakeApiCallId: any;
  getVehicleTypeApiCallId: any;
  vehicleDetailsApiCallId: any;
  updateVehicleDetailsApiCallId: any
  createReservationCallID: string = "";
  getAvailableTabelCallID: string = "";
  GetDietaryRestricationAPICallID: string = "";
  GetFoodPrefrencesCallID: string = "";
  getReservationsTimeDataCallID: string = "";
  getSpecialOccasionCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      nextButton: false,
      vehicleNo: "",
      vehicleModels: [],
      vehicleMake: [],
      vehicleType: [],
      selectedVehicleMake: "",
      selectedVehicleModel: "",
      selectedVehicleType: "",
      reservationDetails: {
        customerName: "",
        customerEmail: "",
        customerPhone: "",
        NoofPeople: "",

      },
      reservationDateTime: {
        reservationDate: '',
        reservationTime: ""
      },
      specialOccassion: false,
      WaitingList: false,
      showTable: {},
      reservationSelect: {

        specialOccation: "",
        WaitingTime: '',
      },
      errorMessage: {
        reservationDate: '',
        reservationTime: '',
        WaitingTime: "",
        customerName: '',
        customerEmail: '',
        customerPhone: '',
        NoofPeople: '',
        specialOccassion: '',
        RoomTableSelect: ''
      },
      dietarySelect: {
        UserDietary: [],
        GuestDietary: []
      },
      buttonDisable: true,
      isError: false,
      errorText: "",
      RoomTableSelect: [],
      availabelTable: [],
      isLoader: false,
      Dietary: [],
      FoodPrefrences: [],
      timeSlots: { 'sunday': [], 'monday': [], 'tuesday': [], 'wednesday': [], 'thursday': [], 'friday': [], 'saturday': [] },
      opening_time: [],
      reservationCreatAdded: false,
      specialOccasion: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getVehicleModelsApiCallId) {
        this.setState({ selectedVehicleModel: "", buttonDisable: true })
         this.setState({vehicleModels:responseJson.success.data.map((value:string)=>({value}))})
      }
      if (apiRequestCallId === this.getVehicleMakeApiCallId) {
         this.setState({vehicleMake:responseJson.success.data.map((value:string)=>({value}))})
      }
      if (apiRequestCallId === this.getVehicleTypeApiCallId) {
         this.setState({vehicleType:responseJson.success.data.map((value:string)=>({value}))})
      }
      if (apiRequestCallId === this.vehicleDetailsApiCallId) {
        const { restId, date, time, menuData, itemIndex, menuIndex, couponId, restDetails, totalCoupons } = await this.props.navigation.state.params
        if (!responseJson.errors) {
          this.props.navigation.navigate("MenuOrder", {
            cameFrom: "Drive Through",
            restId: restId, date: date, time: time, count: 0, menuData: menuData, modifyIdSee: undefined, option: "Drive Through", itemIndex: itemIndex, menuIndex: menuIndex, couponId: couponId, vehicleID: responseJson.data.id, totalCoupons: totalCoupons, restDetails: restDetails
          })
        } else {
          this.setState({ isError: true, errorText: responseJson.errors.message[0] })
        }
      }
      if (apiRequestCallId === this.updateVehicleDetailsApiCallId) {
        const restIds = await this.props.navigation.state.params.restId
        const dates = await this.props.navigation.state.params.date
        const times = await this.props.navigation.state.params.time
        const menuDatas = await this.props.navigation.state.params.menuData
        const iteamIndexTable = await this.props.navigation.state.params.itemIndex
        const menuIndexTable = await this.props.navigation.state.params.menuIndex
        const couponId = await this.props.navigation.state.params.couponId
        const restDetails = await this.props.navigation.state.params.restDetails
        const totalCoupons = await this.props.navigation.state.params.totalCoupons
        this.props.navigation.navigate("MenuOrder", {
          cameFrom: "Drive Through",
          restId: restIds, date: dates, time: times, count: 0, menuData: menuDatas, modifyIdSee: undefined, option: "Drive Through", itemIndex: iteamIndexTable, menuIndex: menuIndexTable, couponId: couponId, vehicleID: responseJson.data.id, totalCoupons: totalCoupons, restDetails: restDetails
        })
      }
      this.ReceivegetAvailableTabel(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.getVehicleMakeData()
    this.getVehicleTypeData()
    this.GetDietaryRestricationAPI()
    this.GetFoodPrefrences()
    this.getReservationsTimeData()
    this.getSpecialOccasion()
  }

  formateDateStr = (dateString: string) => {
    const newdate = new Date(dateString);
    const utcTimed = newdate.getTime() + (newdate.getTimezoneOffset() * 60000);
    const filterDate = new Date(utcTimed + (5.5 * 60 * 60000));
    const hours = filterDate.getHours();
    const minutes = filterDate.getMinutes();
    const ampmTime = hours >= 12 ? 'PM' : 'AM';
    const formateHours = hours % 12 || 12;
    const formateMinutes = String(minutes).padStart(2, '0');

    const timeStringDate = `${formateHours}:${formateMinutes} ${ampmTime}`;
    return timeStringDate;

  }

  filterAndFormatData = (category: string, data: any[]) => {
    const filterData = data?.filter((day: { attributes: { category: string; }; }) => day?.attributes?.category === category);
    const FormateData = filterData?.map((day: { id: string, attributes: { serving_hours: { data: any[]; }; day: any; status: string }; }) => {
      const servingHours = day?.attributes?.serving_hours?.data?.map(hour => {

        return {

          openingTime: this.formateDateStr(hour?.attributes?.opening_time),
          closingTimeStr: hour?.attributes?.closing_time,
          hour_id: hour.id,
          closingTime: this.formateDateStr(hour?.attributes?.closing_time),
          openingTimeStr: hour?.attributes?.opening_time,

        };
      });

      return {

        day: day.attributes.day,
        order_id: configJSON.orderList[`${day.attributes.day}`],
        status: day.attributes.status,
        day_id: day.id,
        servingHours: servingHours

      };
    });
    return FormateData;

  };
  

  ReceivegetAvailableTabel = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getAvailableTabelCallID) {
      this.setState({ availabelTable: responseJson?.data, isLoader: false })
    } else if (apiRequestCallId === this.createReservationCallID) {
      this.setState({ reservationCreatAdded: false })
      if (responseJson.message) {
        ToastMessage("error", "This table has already booked in the period you want to book it")
      }
      else if (!responseJson.errors) {
        ToastMessage("success", "Reservation created succesfully")
        this.props.navigation.navigate("OrderDetailView")
        this.setState({
          reservationDetails: { customerEmail: '', customerName: '', customerPhone: "", NoofPeople: '' }, reservationSelect: {
            specialOccation: "",
            WaitingTime: ''
          }, dietarySelect: { UserDietary: [], GuestDietary: [] }, RoomTableSelect: '', reservationDateTime: { reservationDate: '', reservationTime: "" }
        })
      }
    } else if (apiRequestCallId === this.GetDietaryRestricationAPICallID) {
      this.DietaryGet(responseJson)
    } else if (apiRequestCallId === this.GetFoodPrefrencesCallID) {
      this.FoodPrefrenceGet(responseJson)
    } else if (apiRequestCallId === this.getReservationsTimeDataCallID) {
      this.convertToReservationSlotForWeek(this.filterAndFormatData("reservation", responseJson.data))
    } else if (apiRequestCallId === this.getSpecialOccasionCallID) {
      this.setState({ specialOccasion: responseJson?.occasions })
    }
  }
  DietaryGet = (responseJson: any) => {
    if (responseJson.errors) {
      ToastMessage("error", "Dietary Restrication Not Found")
    } else {
      this.setState({ Dietary: responseJson?.dietary || [] })
    }
  }

  getTimeFromTimestamp = (timeStemp: number) => {
    const dateObject = new Date(timeStemp);

     const options: Intl.DateTimeFormatOptions & { timeZone?: string } = {
      timeZone: 'Asia/Kolkata',
       hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const indianTime = dateObject.toLocaleTimeString('en-IN', options);
    return indianTime;
  }
  convertToReservationSlotForDay = (dayObj: any, day: string) => {
    const timeSlot: string[] = [];

    dayObj.servingHours.forEach((slot: any) => {
      const startTimeStemp = new Date(slot.openingTimeStr).getTime(), endTimestemp = new Date(slot.closingTimeStr).getTime();

      let currectTimeStemp = startTimeStemp;

      while (currectTimeStemp <= endTimestemp) {
        timeSlot.push(this.getTimeFromTimestamp(currectTimeStemp))
        currectTimeStemp = currectTimeStemp + (30 * 60 * 1000);
      }
    })


    this.setState(prevState => ({
      timeSlots: {
        ...prevState.timeSlots,
        [day]: timeSlot
      }
    }));
  }
  convertToReservationSlotForWeek = (resevartionData: any) => {
    for (const day of configJSON.reservationServingDay) {
      const filteredData = resevartionData?.filter((item: any) => item.day.toLowerCase() === day);

      if (filteredData?.length > 0 && filteredData[0].status == 'opened') {
        this.convertToReservationSlotForDay(filteredData[0], day)
      }
    }
  }

  FoodPrefrenceGet = (responseJson: any) => {
    if (responseJson.errors) {
      ToastMessage("error", "Food Prefrences Not Found")
    } else {
      this.setState({ FoodPrefrences: responseJson?.dietary || [] })
    }
  }
  onUpdateVehicle = async () => {
    const { id } = this.props.navigation.state.params.vehicleInfo
    let usertoken = await getStorageData("token-access");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: usertoken
    };

    const httpBody = {
      id: id,
      vehicle_type: this.state.selectedVehicleType,
      vehicle_make: this.state.selectedVehicleMake,
      vehicle_model: this.state.selectedVehicleModel,
      vehicle_number: this.state.vehicleNo
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateVehicleDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/user_vehicle_details/update_user_vehicle_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getVehicleTypeData = async () => {
    let usertoken = await getStorageData("token-access");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: usertoken

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVehicleTypeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/choose_vehicle_type`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVehicleMakeData = async () => {
    let usertoken = await getStorageData("token-access");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: usertoken

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVehicleMakeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/choose_vehicle_make`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getVehicleModelsData = async () => {

    let usertoken = await getStorageData("token-access");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: usertoken

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVehicleModelsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/choose_vehicle_model?make_name=${this.state.selectedVehicleMake}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "NoofPeople") {
      if (!Number.isNaN(Number(value))) {
        this.setState(prevState => ({
          reservationDetails: {
            ...prevState.reservationDetails,
            [name]: value
          }
        }));

        const callObj: any = {
          customerName: this.isValidCustomerName,
          customerEmail: this.isValidEmail,
          customerPhone: this.isValidPhoneNumber
        };
        this.validateField(
          configJSON.creatField[`${name}`],
          value,
          name,
          true,
          callObj[`${name}`]
        );
      }
    } else {
      this.setState(prevState => ({
        reservationDetails: {
          ...prevState.reservationDetails,
          [name]: value
        }
      }));

      const callObj: any = {
        customerName: this.isValidCustomerName,
        customerEmail: this.isValidEmail,
        customerPhone: this.isValidPhoneNumber
      };
      this.validateField(
        configJSON.creatField[`${name}`],
        value,
        name,
        true,
        callObj[`${name}`]
      );
    }
  }

  handleCheckboxChange = () => {
    this.setState(prevState => ({
      specialOccassion: !prevState.specialOccassion
    }))
  }
  handleWaitingListChange = () => {
    this.setState(prevState => ({
      WaitingList: !prevState.WaitingList
    }))
  }
  handleChangeSelect = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      reservationSelect: {
        ...prevState.reservationSelect,
        [name]: value,
      },
    }));
    this.validateField(configJSON.creatField[`${name}`], value, name, true)
  };

  handleChangeDateTime = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      reservationDateTime: {
        ...prevState.reservationDateTime,
        [name]: value,
      },
    }),
      () => {
        if (this.state.reservationDateTime.reservationDate && this.state.reservationDateTime.reservationTime) {
          this.getAvailableTable();
        }
      });
    this.validateField(configJSON.creatField[`${name}`], value, name, true)
  };

  handleRoomTableChange = (event: any) => {
    const { RoomTableSelect } = this.state;
    const selectedTableId = event.target.value;

    let updatedSelection = [...RoomTableSelect];

    if (!updatedSelection.includes(selectedTableId)) {
      updatedSelection.push(selectedTableId);
    }
    else {
      updatedSelection = updatedSelection.filter(id => id !== selectedTableId);
    }

    this.setState({ RoomTableSelect: updatedSelection });
  };


  handleDietaryChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      dietarySelect: {
        ...prevState.dietarySelect,
        [name]: value,
      },
    }));
  };

  handleAvailableTable = (tableId: any) => {
    this.setState(prevState => ({
      showTable: {
        ...Object.keys(prevState.showTable).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        [tableId]: !prevState.showTable[tableId],
      },
    }));
    this.setState({ RoomTableSelect: [] })
  }

  onNextPress = async () => {

    let usertoken = await getStorageData("token-access");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: usertoken
    };
    const httpBody = {
      vehicle_type: this.state.selectedVehicleType,
      vehicle_make: this.state.selectedVehicleMake,
      vehicle_model: this.state.selectedVehicleModel,
      vehicle_number: this.state.vehicleNo
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.vehicleDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/user_vehicle_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDropdownChange = (vehicleNo: string) => {
    if (vehicleNo.length >= 4 && vehicleNo !== "" && this.state.selectedVehicleMake !== "" && this.state.selectedVehicleModel !== "" && this.state.selectedVehicleType !== "") {
      this.setState({ buttonDisable: false })
    } else {
      this.setState({ buttonDisable: true })
    }
  }

  isValidEmail = (email: any) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email) && !email.includes("..");
  }

  isValidPhoneNumber = (phone: any) => {
    return /^(\+)?\d{7,15}$/.test(phone);
  }

  isValidCustomerName = (name: any) => {
    const regex = /^.{3,}$/;
    return regex.test(name);
  }

  validateField = async (fieldName: string, value: string, errorMessageKey: string, required = false, validationFn?: (value: string) => boolean) => {
    const errorMessage = { ...this.state.errorMessage };

    if (required && (value === "")) {
      errorMessage[errorMessageKey] = `${fieldName} is required`;
    } else if (validationFn && !validationFn(value)) {
      errorMessage[errorMessageKey] = `Invalid ${fieldName}`;
    } else {
      errorMessage[errorMessageKey] = '';
    }
    this.setState({ errorMessage });
    return errorMessage
  };

  isFormValid = async (reservationDetails: any, state: any) => {
    const { customerName, customerEmail, customerPhone, NoofPeople } = reservationDetails;
    await this.validateField("Customer Name", customerName, "customerName", true, this.isValidCustomerName);
    await this.validateField("Customer Email", customerEmail, "customerEmail", true, this.isValidEmail);
    await this.validateField("Customer Phone", customerPhone, "customerPhone", true, this.isValidPhoneNumber);
    await this.validateField("Number of People", NoofPeople, "NoofPeople", true);

    if (!state.WaitingList) {
      await this.validateField("Date", state.reservationDateTime.reservationDate, "reservationDate", true);
      await this.validateField("Reservation Time", state.reservationDateTime.reservationTime, "reservationTime", true);
    } else {
      await this.validateField("Waiting Time", state.reservationSelect.WaitingTime, "WaitingTime", true);
      await this.validateField("Date", state.reservationDateTime.reservationDate, "reservationDate", false);
      await this.validateField("Reservation Time", state.reservationDateTime.reservationTime, "reservationTime", false);
    }

    if (state.specialOccassion) {
      await this.validateField("Special Occasion", state.reservationSelect.specialOccation, "specialOccassion", true);
    }

  }

  createReservationPostAPI = async () => {
    if (this.state.reservationCreatAdded === false) {
      await this.isFormValid(this.state.reservationDetails, this.state);
      const isValid = Object.values(this.state.errorMessage).every((message) => message === '');

      const { reservationDateTime, WaitingList } = this.state;
      if (WaitingList === false && reservationDateTime.reservationDate && reservationDateTime.reservationTime && this.state.RoomTableSelect === "") {
        ToastMessage("error", "Please select the Table")
        return;
      }
      if (isValid) {
        const locationID = localStorage.getItem("locationID")
        const token = localStorage.getItem("authToken")
        const header = {
          "Content-Type": "application/json",
          token: token
        };
        const currentDate = new Intl.DateTimeFormat('en-IN', {
          weekday: 'short',
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          timeZone: 'Asia/Kolkata',
        }).format(new Date());

        const currentTime = new Date().toLocaleTimeString('en-IN', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: 'Asia/Kolkata',
        });
        const httpBody = {
          "data": {
            "restaurant_location_id": locationID,
            "restaurant_customer_name": this.state.reservationDetails.customerName,
            "customer_email": this.state.reservationDetails.customerEmail,
            "customer_phone": this.state.reservationDetails.customerPhone,
            "guest_count": this.state.reservationDetails.NoofPeople,
            "apply_for_waiting_list": this.state.WaitingList,
            "estimate_waiting_time": this.state.reservationSelect.WaitingTime,
            "date": this.state.WaitingList ? `${currentDate},${currentTime}` : `${this.state.reservationDateTime.reservationDate},${this.state.reservationDateTime.reservationTime}`,
            "time": this.state.WaitingList ? currentTime : this.state.reservationDateTime.reservationTime,
            "user_dietary_restriction": this.state.dietarySelect.UserDietary,
            "guest_dietary_restriction": this.state.dietarySelect.GuestDietary,
            "special_occasion": this.state.specialOccassion,
            "occasion": this.state.reservationSelect.specialOccation,
            "room_layout_property_ids": this.state.RoomTableSelect
          }
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_featured_restaurants/feature_restaurants/restaurant_reservation`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        this.createReservationCallID = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ reservationCreatAdded: true })

      }

    }
  }

  reservationCancel = () => {
    this.props.navigation.navigate("OrderDetailView")
  }

  onlyEnterNumber = (e: any) => {
    const validChars = '0123456789';
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  }

  getAvailableTable = async () => {
    const { reservationDateTime } = this.state;
    const headergetData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const req = apiCall({
      httpBody: {},
      header: headergetData,
      url: `bx_block_layout/rooms/available_tables?${reservationDateTime.reservationDate}${reservationDateTime.reservationTime}`,
      httpMethod: 'GET'
    })
    this.setState({ isLoader: true })
    this.getAvailableTabelCallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  onPressBack = () => {
    const { editMode } = this.props.navigation.state.params
    if (editMode) {
      this.props.navigation.navigate("ReviewReservation", {
        cameFrom: "Drive Through"
      })
    } else {
      this.props.navigation.goBack()
    }
  }

  GetDietaryRestricationAPI = () => {
    const headerData = {
      "token": localStorage.getItem("authToken")
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: "bx_block_featured_restaurants/dietary_restrictions",
      httpMethod: 'GET'
    })
    this.GetDietaryRestricationAPICallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  GetFoodPrefrences = () => {
    const headerData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: 'bx_block_featured_restaurants/dietary_preferences',
      httpMethod: 'GET'
    })
    this.GetFoodPrefrencesCallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  getReservationsTimeData = async () => {
    const headergetData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const req = apiCall({
      httpBody: {},
      header: headergetData,
      url: 'bx_block_location/serving_days',
      httpMethod: 'GET'
    })
    this.getReservationsTimeDataCallID = req?.messageId;
    runEngine.sendMessage(req?.id, req);
  }

  getCurrectDay = () => {
    const dateObject = new Date(this.state.reservationDateTime.reservationDate);
    const dayOfWeek = dateObject.toLocaleDateString('en-US', { weekday: 'long' });

    return dayOfWeek.toLocaleLowerCase()
  }
  getSpecialOccasion = async () => {
    const headergetData = {
      "token": localStorage.getItem("authToken")
    };

    const req = apiCall({
      httpBody: {},
      header: headergetData,
      url: 'bx_block_featured_restaurants/occasions',
      httpMethod: 'GET'
    })
    this.getSpecialOccasionCallID = req?.messageId;
    runEngine.sendMessage(req?.id, req);
  }
  // Customizable Area End
}
