import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  getRejectPostsData: any;
  anchorElMenuMoreBtn: any;
  detailsShowId: any;
  showDetatilsData: any;
  showImageData: any,
  openImgCardModal: boolean;
  loader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RejectedPostsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  getRejectPostsCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      getRejectPostsData: [],
      anchorElMenuMoreBtn: null,
      openImgCardModal: false,
      detailsShowId: "",
      showDetatilsData: "",
      showImageData: [],
      loader:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getRejectPostsCall()
    // Customizable Area End
}

 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.HandleRejectPostsCall(from, message)
    // Customizable Area End
    // Customizable Area Start
}
  // Customizable Area End

  // web events
  handleClickopenAnchor = async(event: any, showPostId: any,showData:any, showImages:any) => {
    this.setState({ anchorElMenuMoreBtn: event.currentTarget,
      detailsShowId: showPostId, 
      showDetatilsData: showData,
      showImageData: showImages 
    })
  }

  handleOpenDetailsModal = () => {this.setState({ openImgCardModal: true, anchorElMenuMoreBtn: null })}
  handleCloseMenuDelete = () => {this.setState({ anchorElMenuMoreBtn: null })}
  handleCloseDetailsModal = () => {this.setState({openImgCardModal: false})}


  getRejectPostsCall = () => {
    this.setState({loader:true});
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectPostsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/post_status?restaurant_location_id=${RestaurantId}&status=rejected`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  HandleRejectPostsCall = async (from: string, message: Message) => {
    if (this.getRejectPostsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
             if(apiResponse.data.length == 0){
              ToastMessage('error', "No rejected post(s) are available"); this.setState({loader:false});
             } else {
            this.setState({ getRejectPostsData: apiResponse?.data, loader:false})
            }
        }
    }
  }
  // Customizable Area End
}
