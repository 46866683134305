Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
exports.loginApiContentType = 'application/json';
exports.error = "Password is requried";
exports.errorTwo = "Password is too short";
exports.errorThree = "Confirm password is too short"
exports.errorPsdMessage = "Password must be at least 8 characters and must include at least one upper case letter, one lower case letter, one special character, and one numeric digit."
exports.forgetPassErrorMessage = "Please Enter Valid Email"
exports.forgetOTPPageTitle = "Enter Your OTP Code"
exports.newPasswordPageTitle = "Reset Password"
exports.errPsdMessgeConfirm = "Reset Password"
exports.emailRegx = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.EMAILOTPENDPOINT = 'account_block/email_resend_otp'
exports.PHONEOTPENDPOINT = "account_block/phone_resend_otp"
exports.contentTypeApiAddDetail = "application/json";
exports.passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.otpErrMsg1 = 'Please enter full OTP.';
exports.descriptioMessage = "Enter 4-digit OTP we've sent to your";
exports.emailAdress = "email address";
exports.phoneAdress = "phone number";
exports.successMessage = "Your password has been reset Successfully";
exports.otpErrMsg = 'Please enter valid OTP.'
exports.otpErrMsgRequired = "Please enter OTP."
const emailID = "Email ID"
exports.emailID =  emailID
exports.succesOTPMsg =`An OTP has been sent to your ${emailID} successfully`
exports.errOTPMessage = "Entered OTP is incorrect."
exports.countryCode = "+91"
exports.exitAppMessage = "Are you sure you want to exit app?"
exports.holdOnMessage = 'Hold on!'
exports.phoneNumber = "Mobile Number"
// Customizable Area End