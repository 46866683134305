import React from "react";
// Customizable Area Start
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box, TextField, InputAdornment
} from "@material-ui/core";
import SupportProfileController, { Props } from "./SupportProfileController";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { men, group_arrow, group_arrow2, group_arrow3, group_arrow4 } from "./assets";
import SearchIcon from '@material-ui/icons/Search';
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End

const Styles = () => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px",
  },

  needBlock: {
    padding: "10px 0 0px 0px",
  },

  needheading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#111111",
    margin: "-5px 0 30px 0",
  },

  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    // boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    boxShadow: "20px 0 27px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    margin: "25px 0 30px 0",
    minHeight: "70vh",
    // padding: "25px 30px",
    maxHeight: "70vh",
    overflowY: "scroll",

    // marginTop:'25px',
    // marginRight: "18px",
    // height:'100vh',
    // overflow:'auto'
  },

  tablecell: {
    borderBottom: "none",
    color: "#4B5563",
    paddingLeft:"40px"
  },

  tabhead: {
    fontSize: "14px",
    whiteSpace: 'nowrap'
  },

  tickedId: {
    fontSize: "15px",
    color: "#096FFF",
  },

  subject: {
    fontSize: "15px",
    color: "#111827"
  },

  img: {
    height: "32px",
    width: "32px",
    borderRadius: "100%",
  },

  assigndiv: {
    display: "flex",
    alignItems: "center",
  },

  assign: {
    fontSize: "15px",
    color: "#111827",
    marginLeft: "12px",
  },

  unresolved: {
    color: "#FF0A0A",
    backgroundColor: "#FFCECE",
    textTransform: "uppercase",
    padding: "5px 10px",
    fontSize: "12px",
    borderRadius: "4px",
  },

  resolved: {
    color: "#0FBB4C",
    backgroundColor: "#E2FFEC",
    textTransform: "uppercase",
    padding: "5px 19px",
    fontSize: "12px",
    borderRadius: "4px",
  },

  date: {
    fontSize: "15px",
    color: "#111827",
  },

  view: {
    fontSize: "15px",
    color: "#096FFF",
    textDecoration: "underline",
    cursor: "pointer",
  },

  btncreateticket: {
    fontSize: "15px",
    color: "#fff",
    borderRadius: "4px",
    backgroundColor: "#096FFF",
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    marginBottom: '12px'
  },

  alltickets: {
    fontSize: "18px",
    color: "#000",
    fontWeight: "bold",
    borderBottom: '3px solid #096FFF',
    cursor: 'pointer'
  },

  open: {
    fontSize: "18px",
    color: "#4B5563",
    cursor: 'pointer'
  },

  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end"
  },
  paginationselect: {
    border: "1px solid #e5e7eb",
    borderRadius: "3px",
    background: "#fff",
    height: "31px",
    padding: "2px 16px 0 3px",
    margin: "0 10px"
  },
  opactiy_0: {
    opacity: 0.5,
    cursor: "not-allowed",
    border: 0,
    borderRadius: "100%"
  },
  pointer: {
    cursor: "pointer",
    border: 0,
    borderRadius: "100%"
  },
  table: {
    overflow: 'auto'
  },
  noTickets:{
    position:"absolute",
    top:"50%",
    left:"50%"
  }
});
// Customizable Area End

class SupportProfileData extends SupportProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    /* @ts-ignore */
    const { classes } = this.props;
    const { getAllTicketsData } = this.state;

    return (
      // Customizable Area Start
      <Grid container>
        <Grid item sm={12} lg={12} xs={12} >
          <div className={classes.needBlock}>
            <h2 className={classes.needheading}>Support</h2>
            <Grid className={classes.wrapperSubscreption}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #EBEBEB', position: "sticky", top: 0, zIndex: 9, backgroundColor: "#fff" }}>
                <Box style={{ display: 'flex', marginLeft: '30px', marginTop: '20px' }}>
                  <Box onClick={() => this.filterData(true)} className={this.state.unresolvedData ? classes.alltickets : classes.open}
                    data-testid="alltickets">
                    All Tickets
                  </Box>
                  <Box style={{ marginLeft: '25px' }} onClick={() => this.filterData(false)} className={this.state.unresolvedData ? classes.open : classes.alltickets}
                    data-testid="opentickets">
                    Open
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Search here by subject"
                      type="text"
                      style={{width: "500px",borderRadius: "5px",background: "#F9FAFB",position: "relative",left: "50px", top: "-7px"}}
                      name="discounts"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={this.state.searchDescription}
                      onChange={(e: any) => {
                        this.handleSearch(e)
                      }}
                      onKeyPress={this.searchDescription}
                      data-testid="searchTicket"
                    />
                  </Box>
                </Box>
                <Box style={{ marginRight: '30px', marginTop: '17px' }}>
                  <button
                    className={classes.btncreateticket}
                    data-testid="createticket"
                    onClick={this.createTicket}
                  >
                    Create Ticket
                  </button>
                </Box>
              </Box>
              {getAllTicketsData?.data?.data?.length > 0 ? <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tablecell}>
                      <span className={classes.tabhead}>TICKET ID</span>
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      <span className={classes.tabhead}>SUBJECT </span>
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      <span className={classes.tabhead}>ASSIGNED TO</span>
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      <span className={classes.tabhead}>STATUS </span>
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      <span className={classes.tabhead}>DATE </span>
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      <span className={classes.tabhead}>ACTION </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody data-testid="tablebody">
                  {getAllTicketsData?.data?.data?.sort((a: any, b: any) => (a.id < b.id) ? 1 : -1).filter((el: { attributes: { status: string; }; }) => {
                    if (this.state.unresolvedData) {
                      return true;
                    }
                    else {
                      return el.attributes.status === 'unresolved';
                    }
                  }).map((elem: any) => {
                    let date = new Date(elem.attributes.created_at);
                    let year = date.getFullYear();
                    let month = "" + (date.getMonth() + 1);
                    let day = "" + date.getDate();
                    if (month.length < 2) {
                      month = "0" + month;
                    }
                    if (day.length < 2){
                      day = "0" + day;
                    }
                    let result = `${day}-${month}-${year}`;
                    return (
                      <TableRow key={elem.id}>
                        <TableCell scope="row" className={classes.tablecell}>
                          <span className={classes.tickedId}>
                            {elem.id}
                          </span>
                        </TableCell>
                        <TableCell className={classes.tablecell} style={{ maxWidth: '260px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          <span className={classes.subject} >
                            {elem.attributes.description}
                          </span>
                        </TableCell>
                        <TableCell className={classes.tablecell} >
                          <div className={classes.assigndiv}>
                            <img src={men} alt="" className={classes.img} />
                            <span className={classes.assign} style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Not Assigned</span>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tablecell}>
                          {elem.attributes.status === "resolved" ? (
                            <span className={classes.resolved}>RESOLVED</span>
                          ) : (
                            <span className={classes.unresolved}>
                              UNRESOLVED
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tablecell}>
                          <span className={classes.date}>{result}</span>
                        </TableCell>
                        <TableCell className={classes.tablecell}>
                          <span className={classes.view} onClick={() => this.SupportChat(elem.id)} data-testid="viewtickets">View</span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table> : <div>{this.state.noTickets.length > 0 ? <span className={classes.noTickets}>{this.state.noTickets}</span> : <PulseLoader color="#096fff" className="loader" /> }</div>}
            </Grid>
            {getAllTicketsData?.data?.data?.length > 0 && <section className={classes.pagination}>
              <button className={`${getAllTicketsData?.meta?.current_page === 1 ? classes.opactiy_0 : classes.pointer}`} disabled={getAllTicketsData?.meta?.current_page === 1} onClick={() => this.nextPageData(getAllTicketsData.meta, "firstpage")} data-testid="firstpage">
                <img src={group_arrow2} alt="" />
              </button>
              <button className={`${getAllTicketsData?.meta?.current_page === 1 ? classes.opactiy_0 : classes.pointer}`} disabled={getAllTicketsData?.meta?.current_page === 1} onClick={() => this.nextPageData(getAllTicketsData.meta, "previouspage")} data-testid="previouspage">
                <img src={group_arrow} alt="" />
              </button>
              <span style={{ margin: "0 15px" }}>Shows {getAllTicketsData?.meta?.current_page} out of {getAllTicketsData?.meta?.total_pages}</span>
              <button className={`${getAllTicketsData?.meta?.next_page === null ? classes.opactiy_0 : classes.pointer}`} disabled={getAllTicketsData?.meta?.next_page === null} onClick={() => this.nextPageData(getAllTicketsData.meta, "nextpage")} data-testid="nextpage">
                <img src={group_arrow3} alt="" />
              </button>
              <button className={`${getAllTicketsData?.meta?.next_page === null ? classes.opactiy_0 : classes.pointer}`} disabled={getAllTicketsData?.meta?.next_page === null} onClick={() => this.nextPageData(getAllTicketsData.meta, "lastpage")} data-testid="lastpage">
                <img src={group_arrow4} alt="" />
              </button>
            </section>}
          </div>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

/* @ts-ignore */

SupportProfileData.propTypes = {
  classes: PropTypes.object.isRequired,
};

/* @ts-ignore */
export default withStyles(Styles)(SupportProfileData);
export { SupportProfileData };
// Customizable Area End
