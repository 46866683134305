import React from 'react'

// Customizable Area Start
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, Select, MenuItem, IconButton,  AppBar, Toolbar, Box, Icon } from '@material-ui/core'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import SubscriptionDetailsController from './SubscriptionDetailsController';
import CustomisePlan from "./CustomisePlan.web"
import { withRouter } from "react-router-dom";
import { dashbord_hover, navlogo } from './assets';
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End

// Customizable Area Start
const Styles = (theme: any) => ({
    root: {
        backgroundColor: "#F2F2F2",
        padding: "40px 20px"
    },
    sidenav: {
        backgroundColor: "#FFFFFF",
        padding: "50px 8px 30px",
        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.08)",
        borderRadius: "16px",
        position: "relative",
        height:"100vh"
    },
    sidemenu: {
        listStyle: "none",
        padding: "0",
        margin: "10px 0 0 0",
    },
    sidelist: {
        color: "#9F9FAE",
        fontSize: "14px",
        fontWeight: "400",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        width: "184px",
    },
    navicon: {
        paddingRight: "6px"
    },
    sidelistActive: {
        color: "#FFFFFF",
        backgroundColor: "#096FFF",
        fontSize: "14px",
        fontWeight: "400",
        padding: "10px",
        display: "flex",
        alignItems: "center",
        borderRadius: "16px"
    },
    sidearrow: {
        background: "#ECF0FF",
        borderRadius: "8px 0px 0px 8px",
        display: "inline-block",
        position: "absolute",
        top: "10px",
        right: "0",
        padding: "10px 4px",
        color: "#BAC4FF",
    },
    needheading: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#111111",
        margin:'0 0 30px 0'
    },
    basicBlock: {
        "background": "#FFFFFF",
        "boxShadow": "0px 12px 24px rgba(0, 0, 0, 0.118608)",
        "borderRadius": "16px",
        position: "relative",
        padding: "30px 15px 100px",
        // height: "100%",
        boxSizing: "border-box"
    },
    basiclist: {
        listStyle: "none",
        margin: "0",
        padding: "0",
    },
    innerbasic: {
        padding: "0 0 15px 0",
        display: "flex",
        alignItems: "center",
        textTransform: 'capitalize',
    },
    formbutton: {
        fontWeight: "700",
        fontSize: "16px",
        padding: "20px",
        backgroundColor: "#096FFF",
        color: "white",
        textTransform: 'none',
        borderRadius: "10px",
        position: "absolute",
        bottom: "20px",
        left: "15px",
        right: "15px",
        '&:hover': {
            opacity: "0.7",
            backgroundColor: "#096FFF"
        },
    },
    customiseBlock: {
        backgroundColor: "#ECF0FF",
        "background": "#ECF0FF", "border": "1px dashed #096FFF", "borderRadius": "16px",
        textAlign: "center",
        // height: "100%",
        position: "relative",
        padding: "30px 15px 100px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height:"666px"
    },
    wrapperSubscreption: {
        backgroundColor: "#FFFFFF",
        boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
        borderRadius: "16px",
        // margin: "25px 0 0",
        padding: "40px",
        height:"100vh"
    },
    subsheading:{
        textAlign: "center",
        margin:"0 0 10px 0"
    },
    needsubheading: {
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "29px",
        textAlign: "center",
        marginBottom:"10px",
        color: "#4B5563",
    },
    cardTitle: {
        fontSize: "24px",
        fontWeight: "700",
        color: "#111827",
        textAlign: "center",
        textTransform: "capitalize",
        marginBottom: "8px",
        marginTop:0
    },
    planRate: {
        fontSize: "22px",
        fontWeight: "700",
        color: "#111827",
        textAlign: "center",
        marginRight: "15px",
        // marginLeft: "7rem"
    },
    planRatePerYear: {
        fontSize: "14px",
        fontWeight: "400",
        color: "#9CA3AF",
        textAlign: "center"
    },
    customizePlanTitle: {
        fontsize: "24px",
        fontWeight: "700",
        color: "#111827"
    },
    customizePlanBody: {
        fontSize: "16px", fontWeight: "400", color: "#4B5563"
    },
    appbar: {
        backgroundColor: "#096FFF",
    },
    alterToolbar: {
        display: "flex",
        justifyContent: 'space-between',
        width: "94%"
    },
    firstBox: {
        display: "flex",
        gap: "145px",
        alignItems: "center",
        justifyContent: 'space-around',
    },
    iconBg:{
        background:"#096FFF",
        padding: "15px",
        borderRadius: "13px"
    },
    iconTitle:{
        color:"#fff",
        fontSize:"20px",
        fontWeight:"600",
        // paddingLeft: "12px"
    }
});
// Customizable Area End

// Customizable Area Start
class SubscriptionDetails extends SubscriptionDetailsController {

    render() {
        // @ts-ignore 
        const { classes } = this.props;
        const { planListData, basicPlanType, basicPrice, premiumPice, premiumPlanType, planDataDuration } = this.state;
        
        return (
            <>
                <AppBar position='sticky' className={classes.appbar}>
                    <Toolbar className={classes.alterToolbar}>
                        <Box className={classes.firstBox}>
                            <Box>
                                <img src={navlogo} alt="navlogo" style={{marginLeft:'10px'}} />
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <section className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item lg={2}>
                            <div className={classes.sidenav}>
                                <div className={classes.iconBg}>
                                    <Icon className={classes.navicon}>                  
                                        <img src={dashbord_hover} className={classes.imageSvg} />
                                    </Icon>
                                    <span className={classes.iconTitle}>Subscription </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={10}>
                            {this.state.loader ? <PulseLoader color="#096fff" className="loader" /> : <Grid container >
                                <Grid item lg={12}>
                                    <div>
                                        <h2 className={classes.needheading}>My Subscription</h2>
                                        <Grid className={classes.wrapperSubscreption} container>
                                            <IconButton style={{ position: "absolute", top: 5, right: 5 }}>
                                                <CancelRoundedIcon color="primary" />
                                            </IconButton>
                                            {/* <Grid item lg={12}>
                                                <h2 className={classes.subsheading}>Subscription</h2>
                                                <h3 className={classes.needsubheading}>Choose a solution that fits your need</h3>
                                            </Grid> */}
                                            <Grid item lg={4}>
                                                <div className={classes.basicBlock}>
                                                    <h3 className={classes.cardTitle}>Basic</h3>
                                                    <div className="price-plan">
                                                        <p className={classes.planRate}>KD {basicPrice}</p>                                                          
                                                        <Select
                                                            variant="outlined"
                                                            // @ts-ignore
                                                            MenuProps={{
                                                                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                                                transformOrigin: { vertical: 'top', horizontal: 'left' },
                                                                getContentAnchorEl: null,
                                                                disableScrollLock: true,
                                                            }}
                                                            value={this.state.basicPlanType}
                                                            // disableUnderline
                                                            onChange={(event: any) => this.onChangeHandler(event, planListData.basic, "basic")}
                                                            // defaultValue={elem?.attributes?.duration}
                                                            style={{ width: "125px", height: "48px", textTransform:"capitalize" }}
                                                            data-testid="basicplanoption"
                                                        >
                                                            {planListData?.basic?.map((elem:any)=> 
                                                                <MenuItem value={elem.attributes.duration} style={{textTransform:"capitalize"}}>{elem.attributes.duration.replace('_',' ')}
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                    </div>
                                                    {planListData?.basic?.map((elem: any) => {
                                                        return (
                                                            <div key={elem.id}>
                                                                {/* {(elem.attributes.duration === ("monthly" || "half_yearly" || "yearly")) && */}
                                                                    <>
                                                                        <ul className={classes.basiclist}>
                                                                            {elem?.attributes?.plan_options?.map((planOptions: any) => {return(elem.attributes.duration === (planDataDuration[elem.attributes.plan_type] || basicPlanType) && (<li className={classes.innerbasic} key={planOptions.id}>
                                                                                    {planOptions.attributes.is_selected === true ?
                                                                                        <CheckCircleRoundedIcon style={{ "color": "22AB4D", padding: "0 10px 0 20px" }} /> : <CancelRoundedIcon style={{ "color": "DB1C1C", padding: "0 10px 0 20px" }} />}
                                                                                    {planOptions.attributes.name}</li>))
                                                                            })}
                                                                        </ul>
                                                                        <div className=''>
                                                                            <Button className={classes.formbutton} onClick={() => this.createSubscription("basic")} data-testid="gobasic">
                                                                                Go Basic
                                                                            </Button>
                                                                        </div>
                                                                    </>
                                                                {/* }  */}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <div className={classes.basicBlock}>
                                                    <StarsRoundedIcon style={{ color: "#096FFF", position: "absolute", top: "15px" }} />
                                                    <h3 className={classes.cardTitle}>Premium</h3>
                                                    <div className="price-plan">
                                                        <p className={classes.planRate}>KD {premiumPice}</p>
                                                        <Select
                                                            variant="outlined"
                                                            // @ts-ignore
                                                            MenuProps={{
                                                                anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'left' },
                                                                getContentAnchorEl: null,

                                                                disableScrollLock: true,
                                                            }}
                                                            value={this.state.premiumPlanType}
                                                            // disableUnderline
                                                            onChange={(event: any) => this.onChangeHandler(event, planListData.premium, "premium")}
                                                            // defaultValue={elem?.attributes?.duration}
                                                            style={{ width: "125px", height: "48px", textTransform:"capitalize" }}
                                                        >{planListData?.premium?.map((elem:any)=> 
                                                            <MenuItem value={elem.attributes.duration} style={{textTransform:"capitalize"}}>{elem.attributes.duration.replace('_',' ')}</MenuItem>
                                                        )}

                                                        </Select>
                                                    </div>
                                                    {planListData?.premium?.map((elem: any) => {
                                                        return (

                                                            <div key={elem.id}>
                                                                {/* {elem.attributes.duration === ("monthly" || "half_yearly" || "yearly") && */}
                                                                    <div>
                                                                        <ul className={classes.basiclist}>
                                                                            {elem?.attributes?.plan_options?.map((planOptions: any) => (elem.attributes.duration === (planDataDuration[elem.attributes.plan_type] || premiumPlanType)) && 
                                                                                (<li className={classes.innerbasic} key={planOptions.id}>
                                                                                    {planOptions.attributes.is_selected === true ? <CheckCircleRoundedIcon style={{ "color": "22AB4D", padding: "0 10px 0 20px" }} /> : <CancelRoundedIcon style={{ "color": "DB1C1C", padding: "0 10px 0 20px" }} />}

                                                                                    {planOptions.attributes.name}</li>)
                                                                            )}
                                                                        </ul>
                                                                        <Button className={classes.formbutton} onClick={() => this.createSubscription("premium")} data-testid="gopremium">
                                                                            Go Premium
                                                                        </Button>
                                                                    </div>
                                                                {/* } */}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <div className={classes.customiseBlock}>
                                                    <h3 className={classes.customizePlanTitle}>Customise your plan</h3>
                                                    <p className={classes.customizePlanBody}>Create your own plan. Pay for what you use.</p>
                                                    <Button className={classes.formbutton} onClick={this.handleCustomizeModal}>
                                                        Customize now
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>
                </section>
                {/* @ts-ignore  */}
                <CustomisePlan navigation={undefined} show={this.state.openCustomizePlanModal} handleClose={this.handleCloseCustomizePlanModal} />
            </>
        )
    }
}

{/* @ts-ignore */ }
export default withRouter(withStyles(Styles)(SubscriptionDetails));
export { SubscriptionDetails }
// Customizable Area End
