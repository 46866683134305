import React from "react";

import {
  Grid,
  // Customizable Area Start
  RadioGroup,
  Radio,
  Checkbox,
  Switch,
  Typography,
  FormControlLabel
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const BlueRadio = withStyles({
  root: {
    marginRight: "10px",
    width: "5px",
    "&$checked": {
      color: "rgba(9, 111, 255, 1)"
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: "#9CA3AF",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});
const GreenCheckbox = withStyles({
  root: {
    width: "4px",
    "&$checked": {
      color: "green"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);


// Customizable Area End

import ChargesVenueController, {
  Props,
} from "./ChargesVenueController.web";

export default class ChargesVenue extends ChargesVenueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <Grid style={{ height: 'auto', margin: "10px" }}>
          <Grid item xs={12} sm={12} lg={12} style={{ height: "auto" }}>
            <Grid style={configstyle.grid}>
              <Typography style={configstyle.configheading}>
                Checkout Configurations
              </Typography>

              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Ask for dietary restrictions on checkout page
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Mandate active consent for processing dietary restrictions
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Ask if special occassion on checkout page
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Mandate age consent during checkout
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Show opt-in for venue group marketing policy
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <GreenCheckbox />
              <label style={configstyle.checkboxlabel}>
                Opt-in checked by default
              </label>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Show opt-in for venue marketing policy
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <GreenCheckbox data-test-id="checkbox" />
              <label style={configstyle.checkboxlabel}>
                Opt-in checked by default
              </label>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Mandate custom policy agreement to book
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch data-test-id="switch" />
                </Grid>
              </Grid>
              <GreenCheckbox />
              <label style={configstyle.checkboxlabel}>
                Checked by default
              </label>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>
                    Show special attention message
                  </label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <Grid xs={12} sm={7} lg={4} style={configstyle.labelswitchgrid}>
                <Grid>
                  <label style={configstyle.label}>Enable reCAPTCHA</label>
                </Grid>
                <Grid>
                  <IOSSwitch />
                </Grid>
              </Grid>
              <Grid style={{ marginTop: "20px" }}>
                <label style={{ fontSize: "16px", fontWeight: 500 }}>
                  Birthday input
                </label>
                <RadioGroup
                  aria-labelledby="radio-buttons"
                  name="radio-buttons"
                  data-test-id="radiogroup" style={{ marginBottom: "20px", marginTop: "10px", marginLeft: "10px" }}>
                  <FormControlLabel value="Hidden" control={<BlueRadio data-test-id="radio" />} label="Hidden" />
                  <FormControlLabel value="Required" control={<BlueRadio />} label="Required" />
                  <FormControlLabel value="Optional" control={<BlueRadio />} label="Optional" />
                </RadioGroup>
                <label style={configstyle.postallabel}>Postal code input</label>
                <RadioGroup
                  aria-labelledby="radio-buttons"
                  name="radio-buttons-postal" style={{ marginBottom: "20px", marginTop: "10px", marginLeft: "10px" }}>
                  <FormControlLabel value="Hidden" control={<BlueRadio />} label="Hidden" />
                  <FormControlLabel value="Required" control={<BlueRadio />} label="Required" />
                  <FormControlLabel value="Optional" control={<BlueRadio />} label="Optional" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const configstyle = {
  grid: {
    border: "1px solid rgba(235, 235, 235, 1)",
    marginTop: "35px",
    borderRadius: '4px',
    padding: "20px",
  },
  configheading: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "20px"
  },
  radiolabel: {
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: "5px"
  },
  postallabel: {
    fontSize: "16px",
    fontWeight: 500
  },
  labelswitchgrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "5px",
    marginBottom: "5px"
  },
  label: {
    fontSize: "15px",
    fontWeight: 500
  },
  checkboxlabel: {
    marginLeft: "10px",
    fontSize: "15px",
    fontWeight: 400
  }
};

// Customizable Area End

