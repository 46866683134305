import React from "react";

import {
  Grid,
  // Customizable Area Start
  Avatar,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { attachtransparent, editIcon, deleteIcon } from "./assets"
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import TransactionalEmailController, {
  Props,
} from "./TransactionalEmailController.web";
export default class TransactionalEmail extends TransactionalEmailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <Grid style={{ height: 'auto', margin: "10px" }}>
          <Grid item sm={12} style={{
            height: "auto", border: "1px solid rgba(235, 235, 235, 1)",
            borderRadius: "5px",
            padding: "15px",
            marginTop: "30px"
          }}>
            <p style={webstyle.emailparagraph as any}>Transactional Email Design</p>
            <p style={{ fontSize: "14px", color: "rgba(156, 163, 175, 1)", width: "55%" }}>
              Manage the design of transactional guest-facing emails,
              reservation confirmation, reservation reminder, and feedback
              emails.
            </p>
            <p style={webstyle.uploadlogo as any}>Upload your logo</p>
            <Grid
              container
              spacing={3}
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Grid item xs={12} sm={6}>
                <div style={webstyle.logodiv}>
                  <Grid container style={webstyle.actiondiv}>
                    <Grid item lg={8} xs={12} style={{
                      gap: '15px', display: 'flex'
                    }}>
                      <img src={attachtransparent} alt="" />
                      <span style={{ color: "rgba(17, 24, 39, 1)", }}>logo.jpg</span>
                    </Grid>
                   
                      <Grid item lg={2} xs={12} style={{
                        gap: '10px', display: 'flex',marginLeft:'-75px'
                      }}>
                        <img src={editIcon} alt="" />
                        <span style={{ color: "#0060e8" }}>Edit</span>
                      </Grid>
                      <Grid item lg={2} xs={12} style={{
                        gap: '10px', display: 'flex',marginRight:'25px'
                      }}>
                        <img src={deleteIcon} alt="" style={{ width: '17px', height: '17px' }} />
                        <span style={{ color: "red" }}>Delete</span>
                      </Grid>
                  </Grid>
                  <div style={webstyle.secondlogodiv}>
                    <svg
                      width="320"
                      height="320"
                      viewBox="0 0 320 320"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="160" cy="160" r="160" fill="#D8D8D8" />
                    </svg>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ height: "auto" }}>
                <div style={webstyle.seconddiv as any}>
                  <div style={{ padding: "30px" }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Avatar style={{ height: "80px", width: "80px", backgroundColor: "#D8D8D8" }} /></div>
                    <Typography
                      style={{ color: "rgba(156, 163, 175, 1)", fontSize: "16px", marginTop: '25px' }}>John Doe</Typography>
                  </div>
                  <Typography style={{ ...webstyle.datetime, marginTop: '-25px' }}>
                    Thursday, August 5,2023
                  </Typography>
                  <Typography style={{ ...webstyle.datetime, marginTop: '10px' }}>
                    for 2 guests at 7:00 PM - 8:30 PM
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "rgba(156, 163, 175, 1)",
                      marginTop: '10px'
                    }}
                  >
                    Reserved for Lounge Bar
                  </Typography>
                  <Grid container style={webstyle.managecancel} justifyContent="center">
                    <Grid item lg={5} md={5} xs={12} style={{ whiteSpace: 'nowrap' }}>
                      Manage Reservation
                    </Grid>
                    <Grid item lg={1} md={1} justifyContent="center" xs={12}>
                      <span>|</span>
                    </Grid>
                    <Grid item lg={5} md={5} xs={12} style={{ whiteSpace: 'nowrap' }}>
                      Cancel Reservation
                    </Grid>
                  </Grid>
                  <Typography
                    style={{
                      color: "rgba(156, 163, 175, 1)",
                      fontSize: "16px",
                      marginTop: '30px'
                    }}
                  >
                    Add to calendar
                  </Typography>

                  <Grid container style={{ ...webstyle.managecancel, marginTop: '7px' }} justifyContent="center" spacing={3}>
                    <Grid item lg={2} xs={12}>Apple</Grid>
                    <Grid item lg={2} xs={12}>Google</Grid>
                    <Grid item lg={2} xs={12}>Outlook</Grid>
                  </Grid>
                  <Typography style={webstyle.contact}>Contact</Typography>
                  <Typography
                    style={{
                      color: "rgba(156, 163, 175, 1)",
                      fontSize: "14px",
                      marginTop: '10px'
                    }}
                  >
                    Al Hamra Mall
                    <br />
                    Al Kuwayt, Al Asimah Governate
                    <br />
                  </Typography>
                  <span style={{ color: "rgba(17, 24, 39, 1)" }}>+96522087778</span>

                  <Grid container style={webstyle.managecancel} justifyContent="center" >
                    <Grid item lg={2} md={6} xs={12}>Map</Grid>
                    <Grid item lg={1} xs={12} justifyContent="center" ><span>|</span></Grid>
                    <Grid item lg={2} md={6} xs={12}>Website</Grid>
                  </Grid>
                  <Typography style={webstyle.note}>
                    A note from us
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "rgba(156, 163, 175, 1)"
                    }}
                  >
                    Thank you for your reservation. Should you plans change,
                    <br />
                    please let us know 24 hours before your reservation
                    time.
                    <br />
                    We look forward to serving you!
                  </Typography>
                  <Typography style={webstyle.commitement}>
                    Our commitment to your safety
                  </Typography>
                  <Typography style={webstyle.commitementparagraph}>
                    The safety of our customers and team members is a top
                    <br />
                    priority
                  </Typography>
                  <div style={webstyle.reservationdiv}>
                    Your reservation number is
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid >
        </Grid >
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webstyle = {
  uploadlogo: {
    fontSize: "16px",
    color: "rgba(17, 24, 39, 1)",
    fontWeight: "500",
    marginTop: '20px',
    marginBottom: '10px'
  },
  logodiv: {
    border: "1px solid rgb(210, 209, 209)",
    borderRadius: "10px",
    height: "auto",
    width: "100%"
  },
  note: {
    fontSize: "15px",
    color: "rgba(17, 24, 39, 1)",
    marginTop: "40px",
    fontWeight: 600,
    marginBottom: "10px"
  },
  emailparagraph: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "rgba(17, 24, 39, 1)",
    marginTop: '0px',
    marginBottom: '-5px'
  },
  managecancel: {
    fontSize: "16px",
    color: "rgba(17, 24, 39, 1)",
    fontWeight: 500,
    marginTop: '15px'
  },
  actiondiv: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #C2C2C2"
  },
  svgImages: {
    display: "flex",
    gap: "7px",
    marginLeft: "10px"
  },
  delete: {
    fontSize: "14px",
    color: "rgba(219, 28, 28, 1)",
  },
  secondlogodiv: {
    padding: "50px",
    display: "flex",
    justifyContent: "center",
  },
  seconddiv: {
    border: "1px solid rgb(210, 209, 209)",
    borderRadius: "10px",
    textAlign: "center"
  },
  datetime: {
    fontSize: "15px",
    fontWeight: 600
  },
  contact: {
    fontSize: "15px",
    color: "rgba(17, 24, 39, 1)",
    marginTop: "40px",
    fontWeight: 600
  },
  commitement: {
    fontSize: "15px",
    color: "rgba(17, 24, 39, 1)",
    marginTop: "40px",
    fontWeight: 600
  },
  commitementparagraph: {
    marginTop: "6px",
    fontSize: "14px",
    color: "rgba(156, 163, 175, 1)",
    marginBottom: '35px'
  },
  reservationdiv: {
    color: "white",
    backgroundColor: "rgba(9, 111, 255, 1)",
    padding: "8px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px"
  }
};

// Customizable Area End

