
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  passchange: {
    expassword: string;
    newpassword: string;
  };
  password: string;
  showPassword: boolean;
  showPasswordNew: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangepassowordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changepasswordCallId: string = ""
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      passchange: {
        expassword: '',
        newpassword: '',
      },
      password: '',
      showPassword: false,
      showPasswordNew: false,
    };

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.CountryCodeMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) {
      ToastMessage("error", "Something Went Wrong");

      return;
    }
    if (apiRequestCallId === this.changepasswordCallId) {
      this.changePasswordRecive(responseJson);
    }

    // Customizable Area End
  }

  // Customizable Area Start
  changePasswordRecive = (responseJson: any) => {
    if (responseJson.message === "successfully updated new password") {
      ToastMessage("success", "Password change successfully");
      this.setState({
        passchange: {
          expassword: '',
          newpassword: '',
        }
      });
      window.location.href = '/'
      localStorage.clear()
    }
    else {
      ToastMessage("error", "Please enter valid existing password");
    }
  }

  handlechangepassowrd = (e: any) => {
    this.setState({
      passchange: { ...this.state.passchange, [e.target.name]: e.target.value }
    });
  }

  changePassword = () => {
    const { passchange } = this.state;

    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(passchange.newpassword);
    const hasLowercase = /[a-z]/.test(passchange.newpassword);
    const hasDigit = /\d/.test(passchange.newpassword);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(passchange.newpassword);

    if (
      passchange.newpassword.length < minLength ||
      !hasUppercase ||
      !hasLowercase ||
      !hasDigit ||
      !hasSpecialChar
    ) {
      ToastMessage('error', 'Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
    }
    else if (this.state.passchange.expassword.trim() === "" && this.state.passchange.newpassword.trim() === "") {
      ToastMessage("error", "Please enter existing and new passwords");
    } else if (this.state.passchange.expassword.trim() === "") {
      ToastMessage("error", "Please enter the existing password");
    } else if (this.state.passchange.newpassword.trim() === "") {
      ToastMessage("error", "Please enter the new password");
    } else if (this.state.passchange.expassword === this.state.passchange.newpassword) {
      ToastMessage("error", "This password is already been taken, Please try again with another password");
    } else {
      const headerData = {
        "Content-Type": "application/json",
        "token": localStorage.getItem("authToken")
      };
      const httpBody = {
        "data": {
          "type": "email_account",
          "attributes": {
            "old_password": this.state.passchange.expassword,
            "new_password": this.state.passchange.newpassword
          }
        }
      };
      const req = apiCall({
        httpBody: httpBody,
        header: headerData,
        url: 'account_block/restaurant_update_password',
        httpMethod: 'PUT'
      })
      this.changepasswordCallId = req.messageId;
      runEngine.sendMessage(req.id, req);
    }
  }


  handleTogglePassword = (id: any) => {
    if (id == "1") {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    } else if (id == "2") {
      this.setState((prevState) => ({
        showPasswordNew: !prevState.showPasswordNew,
      }));
    }
  };
  // Customizable Area End
}
