// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import { Redirect, Switch } from 'react-router-dom';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { withRouter } from 'react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../blocks/navigationmenu/src/TopNav.web";
import Sidebar from "../../components/src/Sidebar.web";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselWebDisplay.web";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails.web";
import BuySubscription from "../../blocks/customisableusersubscriptions/src/BuySubscription.web";
import AddCard from "../../blocks/customisableusersubscriptions/src/AddCard.web";
import SubscriptionPurchased from "../../blocks/customisableusersubscriptions/src/SubscriptionPurchased.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import CompleteSignUp from "../../blocks/email-account-registration/src/CompleteSignUp.web"
import ResturantImgUpload from "../../blocks/email-account-registration/src/ResturantImgUpload.web"
import SignUpSuccess from "../../blocks/email-account-registration/src/SignUpSuccess.web"
import SignUpEmailVerify from "../../blocks/email-account-registration/src/SignUpEmailVerify.web"
import SignUpOTPVerify from "../../blocks/email-account-registration/src/SignUpOTPVerify.web"
import SignUpDetails from "../../blocks/email-account-registration/src/SignUpDetails.web"
import LocationSignUp from "../../blocks/email-account-registration/src/LocationSignUp.web"
import Favourites from "../../blocks/Favourites/src/Favourites";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen.web";
import Dashboard1 from "../../blocks/dashboard/src/Dashboard1.web";
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web'
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP.web'
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web'
import Followers from "../../blocks/Followers/src/Followers.web";
import CompleteAddress from '../../blocks/email-account-registration/src/CompleteAddress.web'
import InfluencersProposals from "../../blocks/user-profile-basic/src/InfluencersProposals.web"
import ProfileInfluencers from "../../blocks/user-profile-basic/src/ProfileInfluencers.web"
import SuggestedInfluencers from "../../blocks/user-profile-basic/src/SuggestedInfluencers.web"
import ProfileMenuUpload from "../../blocks/MenuOrdering/src/ProfileMenuUpload.web"
import ShowCreatedMenu from "../../blocks/MenuOrdering/src/ShowCreatedMenu.web"
import DiscountCoupon from "../../blocks/promocodes/src/DiscountCoupon.web"
import ShowOffersAndDiscounts from "../../blocks/promocodes/src/ShowOffersAndDiscounts.web"
import ShowDiscountCoupon from "../../blocks/promocodes/src/ShowDiscountCoupon.web"
import UserProfile from "../../blocks/user-profile-basic/src/UserProfile";
import SupportChat from '../../blocks/Chat9/src/SupportChat.web';
import SupportProfileData from '../../blocks/Chat9/src/SupportProfileData.web';
import MyLocation from '../../blocks/location/src/MyLocation.web';
import SupportProfile from '../../blocks/Chat9/src/SupportProfile.web';
import ContactFudu from '../../blocks/requestmanagement/src/RequestManagement.web';
import HelpCenter from '../../blocks/requestmanagement/src/HelpCenter.web';
import LoginPassword from "../../blocks/email-account-login/src/LoginPassword.web";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView.web";
import MenuListing from "../../blocks/MenuOrdering/src/MenuListing.web";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole.web";
import ContectFuduData from "../../blocks/requestmanagement/src/ContectFuduData.web";
import ContectFuduChat from "../../blocks/requestmanagement/src/ContectFuduChat.web";
import PrivacyPolicy from "../../blocks/AdminConsole/src/PrivacyPolicy.web";
import TermsAndCondition from "../../blocks/AdminConsole/src/TermsAndCondition.web";
import TopNavPublic from "../../blocks/navigationmenu/src/TopNavPublic.web";
import PostDetailsPage from '../../blocks/Repost/src/PostDetailsPage.web'
import ViewAllNotification from "../../blocks/navigationmenu/src/ViewAllNotification.web";
import ChangePassword from '../../blocks/AdminConsole/src/ChangePassword.web';
import DietaryRestrictions from '../../blocks/AdminConsole/src/DietaryRestrictions.web';
import FoodPreferencess from '../../blocks/AdminConsole/src/FoodPreferences.web';
import VenueSettings from '../../blocks/AdminConsole/src/VenueSettings.web';
import ServingHours from "../../blocks/scheduling/src/ServingHours.web";
import Reservations from "../../blocks/Reservations/src/Reservations.web";
import TableLayout from "../../blocks/Reservations/src/TableLayout.web"
const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

const theme = createTheme({
  palette: {
    primary: { main: '#096FFF' }
  }
});

const privateroutes = {
  home: {
    component: Dashboard1,
    path: '/dashboard',
    exact: true
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  InfluencersProposals: {
    component: InfluencersProposals,
    path: "/InfluencersProposals"
  },
  ProfileInfluencers: {
    component: ProfileInfluencers,
    path: "/ProfileInfluencers"
  },
  SuggestedInfluencers: {
    component: SuggestedInfluencers,
    path: '/SuggestedInfluencers'
  },
  ProfileMenuUpload: {
    component: ProfileMenuUpload,
    path: "/ProfileMenuUpload"
  },
  ShowCreatedMenu: {
    component: ShowCreatedMenu,
    path: "/ShowCreatedMenu"
  },
  DiscountCoupon: {
    component: DiscountCoupon,
    path: "/DiscountCoupon"
  },
  ShowOffersAndDiscounts: {
    component: ShowOffersAndDiscounts,
    path: "/ShowOffersAndDiscounts"
  },
  ShowDiscountCoupon: {
    component: ShowDiscountCoupon,
    path: "/ShowDiscountCoupon"
  },
  UserProfile: {
    component: UserProfile,
    path: "/UserProfile",
    exact: true
  },
  SupportChat: {
    component: SupportChat,
    path: '/SupportChat'
  },
  SupportProfile: {
    component: SupportProfile,
    path: '/SupportProfile'
  },
  SupportProfileData: {
    component: SupportProfileData,
    path: '/SupportProfileData'
  },
  MyLocation: {
    component: MyLocation,
    path: '/MyLocation'
  },
  SupportProfile: {
    component: SupportProfile,
    path: '/SupportProfile'
  },
  SupportChat: {
    component: SupportChat,
    path: '/SupportChat'
  },
  ContactFudu: {
    component: ContactFudu,
    path: '/ContactFudu'
  },
  HelpCenter: {
    component: HelpCenter,
    path: '/HelpCentre'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  MenuListing: {
    component: MenuListing,
    path: '/MenuListing'
  },
  PostDetailsPage: {
    component: PostDetailsPage,
    path: '/Posts'
  },
  Settings: {
    component: AdminConsole,
    path: '/Settings',
    exact: true
  },
  ContectFuduData: {
    component: ContectFuduData,
    path: '/ContactFuduData'
  },
  ContectFuduChat: {
    component: ContectFuduChat,
    path: '/ContactFuduChat'
  },
  ViewAllNotification: {
    component: ViewAllNotification,
    path: '/ViewAllNotification'
  },
  BuySubscription: {
    component: BuySubscription,
    path: '/BuySubscription'
  },
  AddCard: {
    component: AddCard,
    path: '/AddCard'
  },
  SubscriptionPurchased: {
    component: SubscriptionPurchased,
    path: '/SubscriptionPurchased'
  },
  ChangePassword: {
    component: ChangePassword,
    path: '/Settings/ChangePassword'
  },
  DietaryRestrictions: {
    component: DietaryRestrictions,
    path: '/Settings/DietaryRestrictions'
  },
  FoodPreferencess: {
    component: FoodPreferencess,
    path: '/Settings/FoodPreferencess'
  },
  VenueSettings: {
    component: VenueSettings,
    path: '/Settings/VenueSettings'
  },
  ServingHours: {
    component: ServingHours,
    path: '/Servinghours'
  },
  Reservations: {
    component: Reservations,
    path: '/Reservations'
  },
  TableLayout: {
    component: TableLayout,
    path: '/TableLayout'
  },
}


const Authroutes = {
  EmailAccountLogin: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLogin',
    exact: true
  },
  LoginPassword: {
    component: LoginPassword,
    path: '/LoginPassword'
  },
  CompleteSignUp: {
    component: CompleteSignUp,
    path: '/CompleteSignUp'
  },
  ResturantImgUpload: {
    component: ResturantImgUpload,
    path: '/ResturantImgUpload'
  },
  SignUpSuccess: {
    component: SignUpSuccess,
    path: '/SignUpSuccess'
  },
  LocationSignUp: {
    component: LocationSignUp,
    path: '/LocationSignUp'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  SignUpOTPVerify: {
    component: SignUpOTPVerify,
    path: '/SignUpOTPVerify'
  },
  SignUpEmailVerify: {
    component: SignUpEmailVerify,
    path: '/SignUpEmailVerify'
  },
  SignUpDetails: {
    component: SignUpDetails,
    path: '/SignUpDetails'
  },
  CompleteAddress: {
    component: CompleteAddress,
    path: '/CompleteAddress'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
};

const AuthComponent = (routeMap) => {
  return (
    <div>
      {window.location.pathname === "/SubscriptionDetails" ? 
          <Switch>
            {WebRoutesGenerator({ routeMap })}
            <Redirect from="*" to="/EmailAccountLogin" />
            <Redirect from="/dashboard" to="/EmailAccountLogin" />
          </Switch> 
          : 
        <CarouselDisplay>
          <Switch>
            {WebRoutesGenerator({ routeMap })}
            <Redirect from="*" to="/EmailAccountLogin" />
            <Redirect from="/dashboard" to="/EmailAccountLogin" />
          </Switch>
        </CarouselDisplay>
      }
    </div>
  );
};

const PublicRoutes = {
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy',
  },
  TermsAndCondition: {
    component: TermsAndCondition,
    path: '/TermsAndCondition',
  },

};

const PublicComponent = routeMap => {
  return (
    <>
      <TopNavPublic />
      <Switch>
        {WebRoutesGenerator({ routeMap })}
        <Redirect from="*" to="/EmailAccountLogin" />
        <Redirect from="/" to="/EmailAccountLogin" />
      </Switch>
    </>
  );
};

const data = localStorage.getItem("plan_type")
const AppLayoutComponents = (routeMap, toggle, handleToggle, closetoggleSidebar) => {
  return (
    <>
      <TopNav handleToggle={handleToggle} />
      <Sidebar toggle={toggle} handleClose={closetoggleSidebar}>
        <Switch>
          {WebRoutesGenerator({ routeMap })}
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </Sidebar>
    </>
  );
};

const loggedin = localStorage.getItem('authToken');
// const loggedin = true

class App extends Component {
  constructor() {
    super();
    this.state = {
      toggle: false,
    }
  }

  toggleSideBar = () => {
    this.setState((prevState) => ({ toggle: !prevState.toggle }));
  }
  closetoggleSidebar = () => {
    this.setState({ toggle: false });
  }
  componentDidMount() {
    if (!sessionStorage.getItem('splashScreen')) {
      setTimeout(() => {
        sessionStorage.setItem('splashScreen', 'done');
        this.props.history.push({
          pathname: `/dashboard`
        });
      }, 2000);
    }
  }

  render() {
    // const Authroutes = {
    //   EmailAccountLogin: {
    //     component: EmailAccountLoginBlock,
    //     path: "/EmailAccountLogin",
    //     loginStatusFun:this.loginStatusFun,
    //     exact: true
    //   },
    // }

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const isPublic = Object.values(PublicRoutes).map(route => route.path).includes(window.location.pathname);
    return (
      <MuiThemeProvider theme={theme}>
        <View style={{ height: '100vh', width: '100vw' }}>
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            style={{ zIndex: 9999 }}
            rtl={false}
          />
          {sessionStorage.getItem("splashScreen") ?
            loggedin ?
              isPublic ? PublicComponent(PublicRoutes) :
                AppLayoutComponents(privateroutes, this.state.toggle, this.toggleSideBar, this.closetoggleSidebar) : AuthComponent(Authroutes) : <Splashscreen />
          }
          <ModalContainer />
        </View>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);