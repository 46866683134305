import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    // Customizable Area Start
    classes?: any;
    handleClose: () => void;
    show: boolean;
    editID: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showMenuopen: boolean;
    radioValue: string;
    couponName: string;
    discount: any;
    uptoAmount: any | number;
    maxQuantity: any | number;
    CouponCode: any;
    discountType: any;
    offerValidFromDate: any;
    offerValidUpto: any;
    quantity: number;
    description: string;
    imageFile: any | ArrayBuffer | null | unknown | File;
    showMenuData: any;
    searchCoupon: string;
    isLoading: boolean;
    openFilter: boolean;
    active: boolean;
    filter1: boolean;
    filter2: boolean;
    showMenuEditimg: boolean;
    imageShow: any;
    deleteImg: boolean;
    email: any;
    addVariantsData: any[],
    showSelectedCuisines: any;
    showAllSubCategoriesData: any;
    allCuisinesData: any;
    allSubCategoriesTagsList: any;
    createdDishes: any;
    selectedTagIds: any;
    selectedCuisineId: any;
    isDisabled:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ShowCreatedMenuController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showMenuCallId: any;
    editMenuImgCallId: any;
    deleteMenuCallId: any;
    getAllSubCategoryCallId: any;
    allCuisinesApiId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            email: '',
            showMenuopen: true,
            radioValue: "",
            couponName: "",
            discount: "",
            uptoAmount: 0,
            quantity: 0,
            maxQuantity: 0,
            CouponCode: "",
            discountType: "% percentage",
            offerValidFromDate: "",
            offerValidUpto: "",
            description: "",
            imageFile: "",
            showMenuData: "",
            searchCoupon: "",
            isLoading: false,
            openFilter: false,
            active: false,
            filter1: false,
            filter2: false,
            showMenuEditimg: false,
            imageShow: "",
            deleteImg: false,
            addVariantsData: [],
            showSelectedCuisines: "",
            showAllSubCategoriesData:[],
            allCuisinesData: [],
            allSubCategoriesTagsList: [],
            createdDishes: [],
            selectedTagIds: [],
            selectedCuisineId: "",
            isDisabled:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getAllSubCategoriesCall()
        this.getAllCuisinesDataCall()
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.HandleShowMenuCall(from, message)
        this.HandleEditMenuCall(from, message)
        this.handledeleteMenuItemCall(from, message)
        this.HandleAllSubCategoriesCall(from, message)
        this.HandleAllCuisinesCall(from, message)

        // Customizable Area End
    }
    // Customizable Area End


    // Customizable Area Start

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.show !== this.props.show) {
            this.showMenuCall();
        }
    }

    handleClose = () => {
        this.setState({ showMenuopen: false })
    }

    ReaderMenuImg = async (e: any) => {
        const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
        const fileSizeLimitInMB = 5;
        if (!e.target.files || e.target.files.length === 0) {
            ToastMessage('error', 'Please select an image file.');
            return;
        }
        const file = e.target.files[0];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB <= fileSizeLimitInMB) {
            if (allowedFormats.includes(file.type)) {
                this.setState({ imageFile: file,imageShow: "" });
            } else {
                ToastMessage('error', `File '${file.name}' is not in PNG, JPEG, or JPG format.`);
            }
        } else {
            ToastMessage('error', `File '${file.name}' exceeds the ${fileSizeLimitInMB}MB file size limit.`);
        }
    }

    handleCreatedDishChange = (event:any) => {
        const { showAllSubCategoriesData } = this.state;
        const createdDishes = event.target.value;
        
        const selectedIds = createdDishes.map((name:any) => {
          const selectedDish = showAllSubCategoriesData.find((item:any) => item.attributes?.title === name);
          return selectedDish ? selectedDish.id : '';   
        });
      
        this.setState({
          createdDishes: createdDishes,
          selectedTagIds: selectedIds
        });
    }

    handleCuisinesChange = (event:any) => {
        const selectedCuisineName = event.target.value;
        const selectedCuisine = this.state.allCuisinesData.find((cuisine:any) => cuisine.name === selectedCuisineName);
      
        this.setState({
            showSelectedCuisines: selectedCuisine ? { ...selectedCuisine } : "" ,
            selectedCuisineId: selectedCuisine ? selectedCuisine.id : ""
        });
      
      };

    handleAddVariantsInput = () => {
        const { addVariantsData } = this.state;
          this.setState({
            addVariantsData: [...addVariantsData, '']
          });
      }

      handleRemoveInput = (index: number) => {
        const { addVariantsData } = this.state;
        let id=addVariantsData[index].id
        console.log("ye id hai iska ",id);
        this.deleteMenuItemCall(id)
        
        addVariantsData.splice(index, 1); // Remove the input field at the specified index
        this.setState({ addVariantsData }); // Update the state
      }

      handleDelete = (chipToDelete:any) => {
        this.setState((prevState) => ({
          allSubCategoriesTagsList: prevState.allSubCategoriesTagsList.filter(
            (chip:any) => chip !== chipToDelete
          ),
        }));
      };
    
    
      deleteMenuItemCall= async (variantId:any) => {
        const header = {
            "token": localStorage.getItem("authToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteMenuCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/variants/${variantId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `DELETE`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }    

    showMenuCall = async () => {
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showMenuCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/menus/${this.props.editID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getHeader=()=>{
    const IsImage = !this.state.imageShow && !this.state.imageFile
    if (IsImage) {
        ToastMessage('error', "Please add an Image")
    } else {
    const header = {
        "token": localStorage.getItem("authToken")
    };
    return header
    }
}
     editMenuCall = (files: any) => {
       this.setState({isDisabled:true})
        const header=this.getHeader()
        let formdata = new FormData();
        formdata.append("data[category_type]", this.state.showMenuData?.category_type);
        formdata.append("data[item_name]", this.state.showMenuData?.item_name);
        if(Array.isArray(this.state.addVariantsData)){
            this.state.addVariantsData.forEach((d, i) => {
                if(this.state.addVariantsData[i].id){
                    formdata.append(`data[variants_attributes[${i}][id]]`,this.state.addVariantsData[i].id );
                    formdata.append(`data[variants_attributes[${i}][unit]]`, this.state.addVariantsData[i].unit);
                    formdata.append(`data[variants_attributes[${i}][price]]`, this.state.addVariantsData[i].price);
                    formdata.append(`data[variants_attributes[${i}][quantity]]`, this.state.addVariantsData[i].quantity);
                }else{
                    formdata.append(`data[variants_attributes[${i}][unit]]`, this.state.addVariantsData[i].unit);
                    formdata.append(`data[variants_attributes[${i}][price]]`, this.state.addVariantsData[i].price);
                    formdata.append(`data[variants_attributes[${i}][quantity]]`, this.state.addVariantsData[i].quantity);  
                }
               
              }) 
        }
        formdata.append("data[item_type]", this.state.showMenuData?.item_type);
        formdata.append("data[description]", this.state.showMenuData?.description);

        this.state.selectedTagIds?.forEach((tag:any,i:any) => {
            formdata.append(`data[dish_tag_ids][]`, this.state.selectedTagIds[i]); 
        })
        formdata.append("data[cuisine_id]", this.state.showSelectedCuisines ? this.state.showSelectedCuisines.id : this.state.selectedCuisineId);

        
        if (!this.state.imageShow && !this.state.imageFile) {
            formdata.append("delete_image", 'true');
        }
        if (this.state.imageFile) {
            formdata.append("data[image]", this.state.imageFile);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editMenuImgCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/menus/${this.props.editID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PATCH`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


getAllSubCategoriesCall = () => {
    const header = {
        "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubCategoryCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/dish_tags`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}  

getAllCuisinesDataCall = () => {
    const header = {
        "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allCuisinesApiId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_menu_ordering/cuisines`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }  

    HandleShowMenuCall = async (from: string, message: Message) => {
        if (this.showMenuCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', apiResponse.errors)
            } else {
                this.setState({
                    showMenuData: apiResponse?.data?.attributes,
                    addVariantsData: apiResponse?.data?.attributes?.variants,
                    showSelectedCuisines: apiResponse?.data?.attributes?.cuisine,
                    allSubCategoriesTagsList: apiResponse?.data?.attributes?.dish_tag_ids,
                    imageShow: apiResponse?.data?.attributes?.image,
                    createdDishes:apiResponse?.data?.attributes?.dish_tag_ids,
                })
                console.log("getting show menu data ...", apiResponse.data);
            }
        }
    }

    HandleEditMenuCall = async (from: string, message: Message) => {
        if (this.editMenuImgCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
               
                alert(apiResponse.errors[0].menu)
            } else {
                this.setState({ showMenuData: apiResponse?.data?.attributes })
                this.props.handleClose()
                ToastMessage( 'success',"Menu updated successfully");
            }
        }
    }

    handledeleteMenuItemCall = async (from: string, message: Message) => {
        if (this.deleteMenuCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error',apiResponse.errors)
            } else {
                ToastMessage('success',apiResponse.message)
            }
        }
    }
    HandleAllSubCategoriesCall = async (from: string, message: Message) => {
        if (this.getAllSubCategoryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', apiResponse.errors)
            } else {
                this.setState({
                    showAllSubCategoriesData: apiResponse?.data
                })
                console.log("getting all subcategories data...", apiResponse.data);
            }
        }
    }   
    
    HandleAllCuisinesCall = async (from: string, message: Message) => {
        if (this.allCuisinesApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', apiResponse.errors)
            } else {
                this.setState({
                    allCuisinesData: apiResponse
                })
                console.log("getting all subcategories data...", apiResponse.data);
            }
        }
    }    
    // Customizable Area End
}
