import React from "react";

// Customizable Area Start
import {
  Grid
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { data, errors } = this.state;
    const { needHelp, queryDesc} = this.state.data;
    const enable = needHelp.length > 0 &&
      queryDesc.length > 0;
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: '100%' }}>
          <Grid item sm={12} style={{ height: '100%' }}>
            <div style={webStyle.needBlock}>
              <h2 style={webStyle.needheading as React.CSSProperties}>Contact Fudu</h2>
              <Grid style={webStyle.wrapperSubscreption}>
                <Grid item lg={7}>
                  <h1 style={webStyle.heading}>We are here to help</h1>
                  <div style={webStyle.mb20}>
                    <label htmlFor="help" style={webStyle.labelfont}>
                      Reason
                    </label>
                    <br />
                    <input
                      data-test-id='input_Reason' type='text' name="needHelp"
                      placeholder="Eg., Facing issue with accepting orders..."
                      onChange={(event) => this.handleInputChange(event)}
                      value={data.needHelp}
                      style={webStyle.formInput} />
                    {errors.needHelp && (
                      <p style={webStyle.error}>{errors.needHelp}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="desc" style={webStyle.labelfont}>
                      Your comments
                      <sup style={webStyle.themeColor}>*</sup>
                    </label>
                    <textarea
                      id="desc"
                      style={webStyle.textarea as React.CSSProperties}
                      name="queryDesc"
                      placeholder="Explain what you need help with..."
                      data-testid="select_change"
                      value={data.queryDesc}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                    {errors.queryDesc && (
                      <p style={webStyle.error}>{errors.queryDesc}</p>
                    )}
                  </div>
                  <div style={webStyle.btndiv}>
                    <button
                      style={webStyle.btntransparent }
                      data-testid="cancel"
                      onClick={this.cancelQuery}
                    >
                      Cancel
                    </button>

                    <button
                      style={!enable ? webStyle.btntheme : webStyle.btnopacity}
                      data-testid="submit"
                      disabled={!enable}
                      onClick={()=>this.PostContactfuduAPICall()}
                    >
                      Submit
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px",
  },

  needBlock: {
    padding: "10px 0 30px 20px",
  },

  needheading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#111111",
    margin: "0",
    marginTop: '-3px'
  },

  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    padding: "25px 30px",
    height: '87%',
    marginTop: '30px',
    marginRight: '25px'
  },

  heading: {
    fontSize: "24px",
    marginBottom: "30px",
  },

  mb20: {
    marginBottom: "20px",
  },

  themeColor: {
    color: "#096fff",
  },

  labelfont: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
  },

  formInput: {
    background: 'rgb(244, 247, 249)',
    color: '#001F49',
    width: "100%",
    height: "40px",
    fontSize: "14px",
    borderRadius: "8px",
    margin: "10px 0px",
    border: "1px solid #E5E7EB",
    padding: '8px',
    outline: 'none',
  },

  textarea: {
    padding: "6px 10px",
    outline: "none",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    width: "100%",
    background: 'rgb(244, 247, 249)',
    color: '#001F49',
    minHeight: "100px",
    resize: "none",
    marginTop: "10px",
    marginBottom: "20px",
  },

  btntransparent: {
    background: "#fff",
    border: "1px solid #096fff",
    color: "#096fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    marginRight: "15px",
    marginTop: '12px'
  },

  btntheme: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    opacity: '0.5',
    marginTop: '12px'
  },
  btnopacity: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    opacity: '1',
    marginTop: '12px'
  },

  btndiv: {
    marginTop: "64px",
  },

  error: {
    fontSize: "15px",
    color: "red",
    marginTop: "5px",
  },
};
// Customizable Area End
