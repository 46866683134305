import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import ToastMessage from "../../../components/src/ToastMessage";
import { apiCall } from "../../../components/src/common";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleClose: () => void;
    show: boolean;
}

export interface S {
    // Customizable Area Start
    featuresData: any;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SignUpControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    apiCompleteProfileCallId: string = "";
    getFeaturesCallId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            featuresData: {}
            // Customizable Area End
        };

        // Customizable Area Start

        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (!apiRequestCallId || !responseJson) {
            ToastMessage("error", "Something Went Wrong");
            return;
        }

        if (apiRequestCallId === this.apiCompleteProfileCallId) {
            if (responseJson?.data?.attributes && responseJson?.data?.id) {
                ToastMessage("success", "Edit FEATURES Details updated successfully");
                this.setState({ featuresData: responseJson?.data });
                this.props.handleClose()
            } else {
                ToastMessage("error", "Something Went Wrong");
            }
        } else if (apiRequestCallId === this.getFeaturesCallId) {
            if (responseJson?.data?.attributes && responseJson?.data?.id) {
                this.setState({ featuresData: responseJson.data });
            } else {
                ToastMessage("error", "Something Went Wrong");
            }
        }
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getFeaturesData()
    }

    updateFeature = (dataObj: any) => {
        const header = {
            "Content-Type": "application/json",
            "token": localStorage.getItem("authToken")
        };
        const httpBody = {
            data: {
                "parking_availability": dataObj.parking_availability.toLowerCase(),
                "valet_parking_availability": dataObj.valet_parking.toLowerCase(),
                "kids_area": dataObj.kids_area.toLowerCase(),
                "serves_alcohol": dataObj.serves_alcohol.toLowerCase(),
                "sheesha": dataObj.shisha.toLowerCase(),
                "smoking_area": dataObj.smoking_area.toLowerCase(),
                "dining": dataObj.dining,
                "speciality": dataObj.speciality,
                "category": dataObj.category,
            }
        };
        const req = apiCall({
            httpBody: httpBody,
            header: header,
            url: `account_block/features/${this.state.featuresData.id}`,
            httpMethod: 'PATCH'
        })
        this.apiCompleteProfileCallId = req.messageId;
        runEngine.sendMessage(req.id, req);
    }

    getFeaturesData = async () => {
        const header = {
            "Content-Type": "application/json",
            "token": localStorage.getItem("authToken")
        };

        const req = apiCall({
            httpBody: {},
            header: header,
            url:  `/account_block/current_restaurant_feature?restaurant_location_id=${localStorage.getItem('locationID')}`,
            httpMethod: 'GET'
        })
        this.getFeaturesCallId = req.messageId;
        runEngine.sendMessage(req.id, req);
    }

    // Customizable Area End
}
