import React from 'react'
// Customizable Area Start
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { Typography,Grid, Card } from "@material-ui/core"
import ShowDetailedApprovedPostsController from './ShowDetailedApprovedPostsController';


export default class ShowDetailedRejectedPosts extends ShowDetailedApprovedPostsController {
    render() {
        return (
            <div>
                <Dialog fullWidth maxWidth="lg" open={this.props.show}
                    onClose={this.props.handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" >
                        <IconButton
                            data-test-id="viewclose"
                            aria-label="close"
                            style={{position: "absolute",right: "2px",
                                top: "2px",
                                color: "blue",
                            }}
                            onClick={
                                () =>
                                this.props.handleClose()
                            }
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>

               <DialogContent>
                {(this.props.imagesData && this.props.imagesData.length !== 0) ?   
                   (  <Grid container style={{marginBottom: "35px", marginTop: "5px"}}>
                      <Grid item xs={8}>
                      <Card>
                      <Carousel
                          autoPlay={false}
                          indicators={true}
                          swipe
                          className="my-carousel"
                          navButtonsAlwaysVisible
                          NextIcon={<ArrowForwardIosIcon fontSize='small' />}
                          PrevIcon={<ArrowBackIosIcon fontSize='small' />}
                        >
                        {this.props.imagesData.map((file:any, index:any) => (
                            <div key={index}>
                                {file.url.endsWith('.jpg') 
                                || file.url.endsWith('.jpeg') || file.url.endsWith('.png') ? (
                                    <img src={file.url} alt=""
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                ) : file.url.endsWith('.mp4') && (
                                    <video width="100%" height="400px" controls> 
                                    <source src={file.url} type="video/mp4" 
                                     />
                                    </video>
                                ) }
                            </div>
                        ))}
            
                      </Carousel>
                      </Card>  
                      </Grid> 
                      <Grid item xs={4}>
                        <Typography style={{color: "green", 
                                            fontWeight: "bold",
                                            fontSize:"20px", 
                                            marginLeft: "20px"
                                            }}
                                            >
                            Post Description
                        </Typography>
                        <Typography style={{fontSize: "16px",marginLeft: "20px"}}>
                            {this.props.detailsData}
                        </Typography>
                      </Grid>  
                     </Grid>) : (
                      <Card style={{padding:"20px"}}>
                          <Typography 
                                    style={{color: "green",fontWeight: "bold",fontSize: "20px",textAlign: "center"}}
                          >
                            Post Description
                        </Typography>
                        <Typography 
                              style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                    }}
                                    >
                            {this.props.detailsData}
                        </Typography>
                      </Card>
                     )
                   }    
                    </DialogContent>

                </Dialog>
            </div>
        )
    }
}
// Customizable Area End




