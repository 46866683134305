export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const BluPlusImg = require("../assets/bluePlus.png")
export const BlueCrossImg = require("../assets/blueCross.png")
export const BlueCutImg = require("../assets/blueCut.png")
export const mastercard = require("../assets/mastercard.png")
export const visa = require("../assets/visa.png")
export const delivery = require("../assets/delivery.png")
export const noWaiting = require("../assets/noWaiting.png")
export const vip = require("../assets/vipBonus.png")
export const gold = require("../assets/gold.png")
export const platinum = require("../assets/platinum.png")
export const calendar = require("../assets/calendar.png")
export const navlogo = require("../assets/navlogo.png")
export const dashbord_hover = require("../assets/dashbord_hover.png")