import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    Grid,
    Avatar,
    Tab, Tabs, Paper, Card, CardContent, CardActions, ListItem, List, ListItemAvatar, ListItemText, CardMedia, IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
// Customizable Area End
import RejectPost from "./Rejectpost.web";
// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: "#9CA3AF" }
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        caption: {
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "20px"
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: "10px",
            lineHeight: "20px"
        },
        h5: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
        },
    },
});

export const StyledTab2: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16.94px",
            padding: "7px 16px",
            color: "#9CA3AF",
            minWidth: "0px",
            '&:hover': {
                color: '#40a9ff'
            },
            '&$selected': {
                color: '#096FFF',
                fontWeight: 500,
            }
        },
        selected: {},
    }),
)((props: any) => <Tab {...props} />);

export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            height: "32px",
            borderRadius: "20px",
            textTransform: "none",
            '&:hover': {
                opacity: "0.7",
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import PostDetailsController, {
    Props
} from "./PostDetailsController";
import PendingPosts from "./PendingPosts.web";
import ApprovedPosts from "./ApprovedPosts.web";
import RejectedPosts from "./RejectedPosts.web";

export default class PostDetails extends PostDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    TabPanel = (userProps: { children: React.ReactNode, value: number, index: number }) => {
        const { children, value, index } = userProps;
        return (
            <div
                role="tabpane"
                aria-labelledby={`scrollable-auto-tab-${index}`}
                id={`${index}`}
                hidden={value != index}
            >
                {value == index && (
                    <div>
                        {children}
                    </div>
                )}
            </div>
        );
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>

                <Paper elevation={3} style={webStyle.childrenSection as React.CSSProperties}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={12} lg={10}>
                            <Tabs
                                value={this.state.tabPanelNo}
                                onChange={this.hanleTabs}
                                indicatorColor="secondary"
                                data-test-id='handletab'
                                TabIndicatorProps={{ style: { display: "none" } }}
                            >
                                {['Pending Request','Approved', 'Rejected'].map((val, index) => {
                                    return (
                                        <StyledTab2 label={val} id={index} data-test-id='tab'/>
                                    )
                                })}
                            </Tabs>
                        </Grid>
                        {this.props.enableFilterSearch && 
                            <Grid item alignItems="center"  xs={12} lg={2} style={{display:'flex',justifyContent:'flex-end',gap:'35px'}}>
                                <IconButton style={{ padding: "0" }}>
                                    <SearchIcon />
                                </IconButton>
                                <IconButton style={{ padding: "0" }}>
                                    <FilterListOutlinedIcon />
                                </IconButton>
                        </Grid>}
                    </Grid>


                    <Grid container style={webStyle.featureMenu}>
                        <Grid item xs={12}>
                            <this.TabPanel value={this.state.tabPanelNo} index={0} data-test-id='tabpanel'>
                                <PendingPosts navigation={undefined}/>
                            </this.TabPanel>

                            <this.TabPanel value={this.state.tabPanelNo} index={1}>
                                <ApprovedPosts navigation={undefined}/>
                            </this.TabPanel>

                            <this.TabPanel value={this.state.tabPanelNo} index={2}>
                                <RejectedPosts navigation={undefined}/>
                            </this.TabPanel>
                        </Grid>
                    </Grid>

                </Paper>
                
                <RejectPost navigation={'Rejectpost'} id={'Rejectpost'}
                show={this.state.rejectpostmodel} handleClose={this.handleCloseRejectpost} />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    childrenSection: {
        marginTop: "24px",
        padding: "24px",
        borderRadius: "8px",
        position: "relative",
        minHeight:"70vh"
    },
    featureMenu: {
        marginTop: "17px"
    },
    cardList: {
        padding: "0px"
    },
    cardListItem: {
        padding: "12px"
    },
    postCardRoot: {
        borderRadius: "8px",
        boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.12)"
    }
};
// Customizable Area End