import { Dimensions, Platform } from "react-native";

//Screen Constatnts
const SCREEN_HEIGHT = 736;
const SCREEN_WIDTH = 414;

const { height, width } = Dimensions.get("window");

/**
 * Function to scale a value based on the size of the screen size and the original
 * size used on the design.
 */
export default function(units = 1) {
  return (width / SCREEN_WIDTH) * units;
}

const verticalScale = (size: number) => (height / SCREEN_HEIGHT) * size;

const horizontalScale = (size: number) => (width / SCREEN_WIDTH) * size;

const moderateScale = (size: number, factor = 0.5) => size + (horizontalScale(size) - size) * factor;

export { verticalScale,width,height, moderateScale , horizontalScale};
