import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    latitude: number,
    longitude: number,
    zoom: number,
    locationFlag: boolean,
    location: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class SignUpLocationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiSubmitLocationCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            latitude: 28.7041,
            longitude: 77.1025,
            zoom: 17,
            location: '',
            locationFlag: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForSubmitLocation(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Logic Function
    getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                this.setState({ latitude, longitude, zoom: 17, locationFlag: true },()=>
                this.handleCoordinatesChange(latitude,longitude))
            }, (error) => {
                if (error.code === 1) {
                    // User denied location permission
                    ToastMessage('error', 'You need to enable your location to run this app')
                } else {
                    // Other error occurred
                    ToastMessage('error', error.message)
                }
            });
        } else {
            // Geolocation is not supported by this browser
            ToastMessage('error', 'Location Permission is Blocked')
        }
    }

    handleCoordinatesChange = async (lat: any, lng: any) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDggGoFSnzL7Vley8afXotWIAJWB4-DZcA`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                this.setState({ location: data.results[0].formatted_address });
            }
        } catch (error) {
            console.error('Error', error);
        }
    };

    handleMarkerPosition = (map: any, maps: any) => {
        const geocoder = new maps.Geocoder();
        const latlng = {
            lat: this.state.latitude,
            lng: this.state.longitude
        };
        geocoder.geocode({ location: latlng }, (results: any, status: any) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.setState({ location: results[0].formatted_address });
                }
            }
        });
        let marker = new maps.Marker({
            position: { lat: this.state.latitude, lng: this.state.longitude },
            map,
            draggable: true,
        });
        maps.event.addListener(marker, 'dragend', () => {
            const position = marker.getPosition();
            this.setState({ latitude: position.lat(), longitude: position.lng() })
            const latlng = {
                lat: position.lat(),
                lng: position.lng()
            };
            geocoder.geocode({ location: latlng }, (results: any, status: any) => {
                if (status === 'OK') {
                    if (results[0]) {
                        this.setState({ location: results[0].formatted_address });
                    }
                }
            });

        });
        this.componentDidUpdate = () => {
            marker.setPosition({ lat: this.state.latitude, lng: this.state.longitude });
        };
    }

    //API Function
    submitLocation = () => {
        const Params = localStorage.getItem('accountID')
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem("SignUpToken")
        };

        const data = {
            "latitude": this.state.latitude,
            "longitude": this.state.longitude,
            "location": this.state.location,
            "status": "active"
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiSubmitLocationCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/restaurant_locations/${Params}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //API's Response Function
    handleResponseForSubmitLocation = (from: string, message: Message) => {

        if (this.apiSubmitLocationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                ToastMessage('error', 'Something went Wrong')
            } else {
                this.props.navigation.navigate('CompleteAddress')
                ToastMessage('success', 'Location saved successfully')
            }
        }
    }

    // Customizable Area End

}