import React from 'react'

// Customizable Area Start
import { Grid, Accordion, AccordionSummary, Typography, AccordionDetails,Chip, TextField } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "./TableLayout.css"
import {SwitchStyle} from "../../MenuOrdering/src/MenuListing.web"
import { add, Vector, 
} from './assets';
import TableLayoutController from './TableLayoutController';

const data = [
    {
        id: 1,
        name: "Tables",
    },
    {
        id: 2,
        name: "Plants & Decorations",
    },
    {
        id: 3,
        name: "Other Facilities",
    }
]

// Customizable Area End

// Customizable Area Start
export class TableLayout extends TableLayoutController {
    dragAreaRef:any = React.createRef();

    render() {
        const {allTablesListData,allPlantsandDecorationData,allOtherFacilitiesData,roomNames,roomName,
            anchorEl,anchorEl1, popoverId, activeButtonId, popId, bookableSeats} = this.state
        return (
            <>
                <h2>My Profile {">"} Table Layout</h2>
                <Grid container spacing={4}>
                    <Grid item lg={9}>
                        <section className="table-layout-container ">
                        
                            <section>
                                <div className="layout-header">
                                    <div className="label-box" style={{width:"100%", overflow: "auto"}}>                                   
                                    { 
                                     roomNames.map((d:any,i:any)=>{                                        
                                        let icon;
                                        return(
                                        <Chip
                                        key={i}
                                        icon={icon}
                                        label={d.name}
                                        data-test-id={`chip-${i}`}
                                        onClick={()=>{
                                         this.handleClickChip(d.id)
                                        }}
                                        onDelete={() => this.handleDelete(d.name, d.id)}
                                        style={{backgroundColor: this.state.deleteRoomId == d.id ?"gray" : "#D3D3D3"}}
                                        />
                                        )
                                    })}
                                    {this.state.singleRoom==null && this.state.addRoomSet &&
                                      <input
                                      data-test-id="input"
                                            type="text"
                                            placeholder="Room name"
                                            value={roomName}
                                            onChange={(e) => 
                                               this.handleChangeRoom(e)
                                            }
                                        />
                                      }
                                        <p className="add-room" 
                                         data-test-id="addRoom"
                                          onClick={this.handleAddRoom}
                                          style={{cursor: "pointer"}}
                                        >
                                            <img src={add} alt="" /> Add room
                                        </p>
                                    </div>
                                  
                                    <button className="btn-table-layout" 
                                      data-test-id = "savebtn"
                                      onClick={()=>{
                                        this.setState({singleRoom: true, addRoomSet: false})

                                        if(this.state.singleRoom) {
                                            this.updateRoomcall()
                                        } else {
                                            this.CreateRoomcall()
                                        }
                                    }
                                    
                                    }>Save</button>
                                </div>

                                <div className='drag-area' ref={this.dragAreaRef}
                                     style={{width:"100%",minHeight:"80vh"}}
                                onMouseDown={()=>this.setState({updatedState: !this.state.updatedState})}
                                >   
                                  {this.state.singleRoom ?
                                  (this.state.singleRoomLayout?.map((d:any, index:any)=> {
                                        return (
                                            <img data-number={"idn"+index} src={d.layout_property?.image.url} style={{width:"80px",
                                             position: "absolute", top: d.coordinates[1] + "px",
                                             left: d.coordinates[0] + "px"
                                             }}
                                             data-id={d.id}
                                             data-test-id={`room-${index}`}

                                             onMouseDown={(e:any)=> {
                                                 this.handleMouseDown(e, d.id)
                                            } }  
                                            onClick={(e:any)=>this.handleClick(e, d.id, d.layout_property.seats, 
                                            d.available_for_booking, d.table_no, d.layout_property.type,
                                            d.layout_property.name)
                                           }                                        
                                           />         
                                        )
                                    })) : null
                                  }

                                     <Popover
                                        id="external-popover"
                                        open={Boolean(anchorEl1) && Boolean(popId)} 
                                        anchorEl={anchorEl1}
                                        onClose={this.handleFirstDragClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                    >
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                            {this.state.draggedDataType &&
                                            <div>   
                                            <Typography className='typography'>
                                               Table Name
                                             </Typography>
                                             <TextField variant='outlined' 
                                               style={{marginLeft: "20px",
                                                       width: "85%",
                                                       height: "60%"
                                                    }}
                                                // value= {popId} 
                                               data-test-id='table_name'    
                                               value={this.state.tablename} 
                                               onChange={(e:any)=> {
                                                this.handleNameChange(e)
                                               }} 
                                               />
                                            <Typography className='typography'>
                                               Available for booking
                                             </Typography> 
                                               <FormControlLabel
                                                    control={<SwitchStyle checked=
                                                        {this.state.tableStatus}
                                                    onChange=
                                                        {this.handleSwitchChange}
                                                    name="checkedB" 
                                                    />
                                                    }
                                                    label="Active"
                                                    style={{marginLeft:"10px"}}
                                                />
                                               </div>
                                                } 
                                              <Typography 
                                              data-test-id = 'delete_item'
                                                 id = "deleteImage"
                                                 data-id={this.state.draggedImg} 
                                                onClick={()=>this.handleDeleteDraggedItem(this.state.draggedImg)}
                                                style={{margin:"15px", cursor: "pointer"}}
                                              >
                                                Delete  
                                              </Typography> 
                                            </div>
                                           </Popover>  
                                    
                                     <Popover
                                        id="external-popover"
                                        open={Boolean(anchorEl) && Boolean(activeButtonId)} 
                                        anchorEl={anchorEl}
                                        onClose={this.handleClose}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left',
                                        }}
                                    >
                                            <div style={{display: "flex", flexDirection: "column"}}>
                                            { this.state.draggedItemType == "Table" ?    
                                            <div>
                                            <Typography className='typography'>
                                              Update Table Name
                                             </Typography>
                                             <TextField variant='outlined'
                                               id='uptable_name'
                                               style={{marginLeft: "20px",
                                                       width: "85%",
                                                       height: "60%"
                                                    }}
                                                value={this.state.updateTableName}
                                                onChange={(e:any)=> 
                                                    this.handleUpdateTnameChange(e)                                        
                                                }
                                               />
                                                   <Typography className='typography'>
                                               No of people servicable
                                             </Typography>
                                             <TextField variant='outlined' 
                                               style={{marginLeft: "20px",width: "85%",height: "60%"}}
                                               value={bookableSeats} 
                                               disabled 
                                               />
                                            <Typography className='typography'>
                                               Available for booking
                                             </Typography> 
                                             <FormControlLabel
                                                    control={<SwitchStyle checked={this.state.updateTStatus}
                                                        onChange={this.handleTableSwitchChange}
                                                        name="checkedB"
                                                    />}
                                                    label="Active"
                                                    id="switch"
                                                    style={{marginLeft: "10px"}}
                                                />
                                            </div> 
                                            : <div>
                                                <Typography className='typography'>
                                                 Plants and decorations or others name
                                               </Typography>
                                               <TextField variant='outlined' 
                                                style={{marginLeft: "20px",width: "85%",height: "60%"}}
                                                value={this.state.plantorOthersName}
                                                disabled
                                               />
                                              </div>
                                             }      
                                             <Typography className='typography' style={{cursor:"pointer"}}
                                              data-test-id='click_delete'
                                              onClick={()=>this.deleteTableApiCall(popoverId)}
                                             >
                                               Delete 
                                             </Typography>  
                                            </div>
                                           </Popover>  
                                        
                                 
                                </div>
                            </section>
                        </section>
                    </Grid>
                    <Grid item lg={3}>
                        <section className="table-layout-head p_25">
                            <h4 className="mt0">Building Blocks</h4>
                            {data.map((elem: any, i: number) => {
                                return (
                                    <Accordion key={i}>
                                        <AccordionSummary
                                            expandIcon={<img src={Vector} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{elem.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{display:"block"}}>
                                            <Grid container spacing={4} alignItems="center" >
                                                {elem.name == "Tables" && allTablesListData.map((tables: any, i: any) => {
                                                    return (
                                                        <Grid item xs={6} sm={6} lg={6} key={i}>
                                                            <div className="text-center"                            
                                                            >
                                                                <div> 
                                                                <img src={tables.attributes?.image?.url}
                                                                  data-type = "table"
                                                                  className='drags-item'  data-id={tables.id}  style={{width:"80px", display:"block"}}/>
                                                                <p className="table-info">{tables.attributes?.seats} seater {tables.attributes?.shape} {tables.type}</p>
                                                                 </div>
                                                            </div> 
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            <Grid container spacing={4} alignItems="center">
                                                {elem.name == "Plants & Decorations" && allPlantsandDecorationData.map((tab: any, i: any) => {
                                                    return (
                                                        <Grid item xs={6} sm={6} lg={6} key={i}>
                                                            <div className="text-center">
                                                                    <img src={tab.attributes?.image?.url} 
                                                                     data-type = "plants"
                                                                     data-id={tab.id} className='drags-item' 
                                                                     style={{width:"80px", display: "block"}}
                                                                     />
                                                                <p className="table-info">{tab.attributes?.name}</p>
                                                            </div>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            <Grid container spacing={4} alignItems="center">
                                                {elem.name == "Other Facilities" && allOtherFacilitiesData.map((fac: any, i: any) => {
                                                    return (
                                                        <Grid item xs={6} sm={6} lg={6} key={i}>
                                                            <div className="text-center">
                                                                    <img src={fac.attributes?.image?.url} style={{width:"80px",
                                                                                                                  display: "block"}}
                                                                                                                  data-type = "others"                                             
                                                                     data-id={fac.id} className='drags-item' />
                                                                <p className="table-info">{fac.attributes?.name}</p>
                                                            </div>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>   
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </section>
                    </Grid>
                </Grid>
                {/* </section> */}
            </>
        )
    }
}
export default TableLayout;
// Customizable Area End

