import React from "react";

import {
  Grid,
  // Customizable Area Start
  Typography,
  Switch,
  Checkbox,
  Select,
  MenuItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    height: 26,
    width: 42,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#52d869",
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff"
    }
  },

  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    opacity: 1,
    borderRadius: 26 / 2,
    backgroundColor:"#9CA3AF",
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}

}))(({ classes, ...props }: any) => {

  return (

    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple

      classes={{

        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked

      }} {...props} />
  );
});

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: 'green',
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

// Customizable Area End

import GuestEmailSMSController, {
  Props,
} from "./GuestEmailSMSController.web";

export default class GuestEmailSMS extends GuestEmailSMSController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedOptions } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid style={{ height: "auto", margin: '10px' }}>
          <Grid
            style={webStyleguest.gridStyle}>
            <Typography
              variant="h5"
              style={{

                fontWeight: "bold",
                fontSize: "18px"
              }}
            >
              Guest Booking Notification Emails
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p style={{ fontSize: "16px", marginTop: '22px', marginBottom: '5px' }}>Send by default</p>
                <span style={{ fontSize: "15px", color: 'rgba(156, 163, 175, 1)' }}>
                  Automatically send guests a booking notification email when
                  their reservation is booked online
                </span></div>
              <IOSSwitch />
            </div>

            <p style={{ fontSize: "16px", marginTop: '30px', marginBottom: '-10px' }}>
              Optional fields to display
            </p>
            <p style={{ color: "rgba(156, 163, 175, 1)", fontSize: "15px" }}>
              In addition to reservation date, party size, and time, you can
              also show the following optional fields{" "}
            </p>
            {[
              "Reservation end time",
              "Seating area",
              "Special attention message"
            ].map((value) => (
              <div>
                <GreenCheckbox />
                <label
                  style={{ fontSize: "16px" }}
                >
                  {value}
                </label>
              </div>
            ))}
          </Grid>
          <Grid
            style={{
              border: "1px solid rgba(235, 235, 235, 1)",
              borderRadius: "5px",
              padding: "15px",
              marginTop: "30px"
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                fontSize: "18px"
              }}
            >
              Guest Reservation Reminder Email/SMS
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: "16px", fontWeight: 500, marginTop: '30px', marginBottom: '-7px' }}>
                Send reservation reminder emails
              </p>
              <IOSSwitch data-test-id="switch" />
            </div>
            <p style={{ fontSize: "14px", color: "rgba(156, 163, 175, 1)" }}>
              Send time(s) for reservation reminder emails prompting guests to
              confirm their reservation
            </p>
            <GreenCheckbox data-test-id="checkbox" />
            <label>Breakfast</label>
            <br />
            <Grid container spacing={2} style={{ width: '60%' }}>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  name="GuestBreakfast1"
                  value={selectedOptions.GuestBreakfast1}
                  data-test-id="select"
                  style={webStyleguest.selectmenu}
                  onChange={this.handleselectChange}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },

                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null, disableScrollLock: true,
                  }}
                >
                  {['Immediately', '1 Hours', '2 Hours'].map((obj) => {
                    return (
                      <MenuItem value={obj} key={obj}>

                        {obj}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  name="GuestBreakfast2"
                  variant="outlined"
                  data-test-id="select"
                  value={selectedOptions.GuestBreakfast2}
                  style={webStyleguest.selectmenu}
                  onChange={this.handleselectChange}
                  MenuProps={{

                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                    disableScrollLock: true,

                  }} >
                  {[
                    'After reservation', 'Before reservation'
                  ].map((obj) => {
                    return (
                      <MenuItem value={obj} key={obj}>
                        {obj}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
            <br />
            <GreenCheckbox />
            <label>Lunch</label>
            <br />
            <Grid container spacing={2} style={{ width: '60%' }}>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  data-test-id="select"
                  name='GuestLunch1'
                  value={selectedOptions.GuestLunch1}
                  onChange={this.handleselectChange}
                  style={webStyleguest.selectmenu}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  }}>
                  {["Immediately", "1 Hours", "2 Hours"].map((obj) => {
                    return (
                      <MenuItem value={obj} key={obj} >
                        {obj}</MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  name='GuestLunch2'
                  value={selectedOptions.GuestLunch2}
                  data-test-id="select"
                  style={webStyleguest.selectmenu}
                  onChange={this.handleselectChange}
                  MenuProps={{

                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  }}>
                  {[
                    "After reservation",
                    "Before reservation"].map((obj) => {
                      return (<MenuItem value={obj} key={obj} > {obj} </MenuItem>)
                    })}
                </Select>
              </Grid>
            </Grid>
            <br />
            <GreenCheckbox />
            <label>Dinner</label>
            <br />
            <Grid container spacing={2} style={{ width: '60%' }} >
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  style={webStyleguest.selectmenu}
                  data-test-id="select"
                  name='GuestDinner1'
                  value={selectedOptions.GuestDinner1}
                  onChange={this.handleselectChange}
                  MenuProps={{

                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,

                    disableScrollLock: true,
                  }}>
                  {["Immediately",
                    "1 Hours", "2 Hours"].map((obj) => {
                      return (
                        <MenuItem value={obj} key={obj}>
                          {obj} </MenuItem>
                      )
                    })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>

                <Select
                  name='GuestDinner2'
                  fullWidth
                  variant="outlined"
                  value={selectedOptions.GuestDinner2}
                  data-test-id="select"
                  style={webStyleguest.selectmenu}
                  onChange={this.handleselectChange}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },

                    getContentAnchorEl: null, disableScrollLock: true,
                  }}>
                  {[
                    "After reservation",
                    "Before reservation"
                  ].map((obj) => {
                    return (
                      <MenuItem value={obj} key={obj}> {obj}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
            </Grid>
            <br />
            <GreenCheckbox />
            <label>All day</label>
            <br />
            <Grid container spacing={2} style={{ width: '60%' }}>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  data-test-id="select"
                  name='GuestAllDay1'
                  style={webStyleguest.selectmenu}
                  value={selectedOptions.GuestAllDay1}
                  onChange={this.handleselectChange}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },

                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  }}
                >
                  {["Immediately",
                    "1 Hours",
                    "2 Hours"].map((obj) => {
                      return (
                        <MenuItem value={obj} key={obj}>
                          {obj}
                        </MenuItem>
                      )
                    })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  variant="outlined"
                  data-test-id="select"
                  name='GuestAllDay2'
                  value={selectedOptions.GuestAllDay2}
                  style={webStyleguest.selectmenu}
                  onChange={this.handleselectChange}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },

                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null, disableScrollLock: true,

                  }}>
                  {["After reservation", "Before reservation"].map((obj) => {
                    return (
                      <MenuItem value={obj} key={obj}> {obj}  </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
            </Grid>
            <br />
            <GreenCheckbox />
            <label>Delivery/Pickup orders</label>
            <p style={{ fontSize: "16px", fontWeight: 500 }}>
              Feedback categories
            </p>
            <p style={{ color: "rgba(156, 163, 175, 1)", fontSize: "14px" }}>
              Customize the categories you would like your guests to rate
            </p>
            {["Overall", "Food", "Drinks", "Services", "Ambience"].map((value) => (
              <div>
                <GreenCheckbox />
                <label style={{ fontSize: "16px", color: "rgba(17, 24, 39, 1)" }}>{value}</label>
              </div>
            ))}

          </Grid>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyleguest = {
  gridStyle: {
    border: "1px solid rgba(235, 235, 235, 1)",
    borderRadius: '4px',
    marginTop: "35px",
    padding: "20px",
  },

  visiblepass: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: '10px',
    cursor: 'pointer',
  },

  boxdiv: {
    width: '72%',
    paddingLeft: '20px',
    paddingTop: '15px',
    maxWidth: '700px'
  },

  needBlockStyle: {
    padding: "10px 0 30px 20px",
  },

  tagChipRage: {
    fontSize: "14px",
    backgroundColor: "#F9FAFB",
    fontWeight: "400"
  },

  formInput: {
    background: 'rgb(244, 247, 249)',
    width: "100%",
    height: "40px",
    borderRadius: "8px",
    color: '#001F49',
    padding: '8px',
    outline: 'none',
    margin: "10px 0px",
    fontSize: "14px",
    border: "1px solid #E5E7EB",
  },

  heading2: {
    fontWeight: 400,
    fontFamily: 'Inter',
    color: '#001F49',
    marginTop: '10px'
  },
  selectmenu: {
    width: "80%",
    height: "40px",
    padding: "3px",
    borderRadius: "8px",
    fontSize: "14px",
    margin: "2px",
    background: '#F3F3F3',
    minWidth: '170px'
  },
  btnchange: {
    background: "#096fff",
    borderRadius: "8px",
    fontSize: "15px",
    border: "1px solid #096fff",
    height: "38px",
    textTransform: "none",
    minWidth: '120px',
    marginTop: '11px',
    marginLeft: '25px',
    color: "#fff",
    marginBottom: '15px',
  },

}

// Customizable Area End

