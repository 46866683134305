import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  data: any;
  errors: any;
  attachFileName: string;
  getAllTicketsData: any;
  unresolvedData: boolean;
  tabPanelNo: number;
  searchDescription: string;
  loader: boolean;
  noTickets:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SupportProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllTicketsId: string = "";
  createTicketId: string = "";
  nextPageDataId: string = "";
  searchDescriptionId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      tabPanelNo: 1,
      // Customizable Area Start
      data: {
        needHelp: "",
        queryDesc: "",
        attachFile: "",
      },
      errors: {
        needHelp: "",
        queryDesc: "",
        attachFile: "",
      },
      attachFileName: "",
      getAllTicketsData: [],
      unresolvedData: true,
      searchDescription: "",
      loader: false,
      noTickets:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getAllTicketsId:
      case this.searchDescriptionId:
        if (responseJson) {
          this.setState({
            getAllTicketsData: responseJson,
          });
        }
        if (responseJson.errors) {
          this.setState({noTickets: responseJson.errors?.[0]?.message})
        }
        break;
      case this.createTicketId:
        if (responseJson.data) {
          this.getAllTickets();
          this.props.navigation.navigate("SupportProfileData");
          this.setState({ loader: false })
        }
        break;
      case this.nextPageDataId:
        if (responseJson.data) {
          this.setState({
            getAllTicketsData: responseJson,
          });
        }
        break;
      default:
        break;
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  async componentDidMount() {
    this.getAllTickets();
  }

  // Customizable Area Start
  validate = (name: any, value: any) => {
    switch (name) {
      case "needHelp":
      case "queryDesc":
      case "attachFile": {
        if (!value || value.trim() === "") {
          return "This Field is Required";
        } else {
          return "";
        }
      }
      default: {
        return "";
      }
    }
  };

  handleInputChange = (e: any) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [e.target.name]: this.validate(e.target.name, e.target.value),
      },
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });

    if (e.target.name === "attachFile") {
      if(e.target.files[0].size <= 10e6){
        if(e.target.files[0].type == ("image/png" || "image/jpg" || "image/jpeg" || "image/svg")){
          this.setState({ attachFileName: e.target.files[0] });
        }else{
          ToastMessage("error","Only upload format in jpg, jpeg, png or svg")
          this.setState({ attachFileName: "" });
        }
      }else {
        ToastMessage("error","File size should be smaller than 10 mb")
      }
    }
  };

  handleSubmit = () => {
    const { data } = this.state;
    let validationErrors = {};
    Object.keys(data).forEach((name) => {
      const error = this.validate(name, data[name]);
      if (error && error.length > 0) {
        // @ts-ignore
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      this.setState({ errors: validationErrors });
      return;
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const locationId = localStorage.getItem("locationID");

    const formdata = new FormData();
    formdata.append("data[topic]", this.state.data.needHelp);
    formdata.append("data[description]", this.state.data.queryDesc);
    formdata.append("data[image]", this.state.attachFileName);
    // @ts-ignore 
    formdata.append("data[restaurant_location_id]", locationId);
    // @ts-ignore 
    formdata.append("data[admin_user_id]", 1);

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTicketId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllTicketsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    this.setState({ loader: true })
  };

  deleteFile = () => {
    this.setState({ attachFileName: "", data: { ...this.state.data, attachFile: "" } });
  };

  createTicket = () => {
    this.props.navigation.navigate("SupportProfile");
  };

  hanleTabs = (event: object, value: number) => {
    this.setState({ tabPanelNo: value })
  }

  cancelTicket = () => {
    this.props.navigation.navigate("SupportProfileData");
  };

  SupportChat = (id: string) => {
    this.props.navigation.navigate("SupportChat");
    localStorage.setItem("chatStatus", this.state.getAllTicketsData?.data?.data?.find((el: { id: string; }) => el.id === id)?.attributes?.status)
  }

  filterData = (value: boolean) => {
    this.setState({ unresolvedData: value })

  }

  getAllTickets = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllTicketsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllTicketsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  nextPageData = (meta: any, value: any) => {

    let result;
    if (value === "nextpage") {
      result = meta.next_page
    }
    if (value === "lastpage") {
      result = meta.total_pages
    }
    if (value === "previouspage") {
      result = meta.current_page - 1
    }
    if (value === "firstpage") {
      result = 1
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.nextPageDataId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetAllTicketsApiEndPoint}?page=${result}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleSearch = (event: any) => {
    const searchValue = event.target.value;
    this.setState({ searchDescription: searchValue }, () => {
      if (searchValue === "") {
        this.getAllTickets();
      }
    });
  }

  searchDescription = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchDescriptionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetsearchDescription}?search=${this.state.searchDescription}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
