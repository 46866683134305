import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area End
}

interface S {
    allowAccess: boolean;
    gpsLocation: string;
    isSpinnerShowing: boolean;
    // Customizable Area Start
    subscriptionPurchasedData:any;
    isLoading:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscriptionPurchasedController extends BlockComponent<Props, S, SS> {
    getCityApiCallId: any;
    updateDefaultCityApiCallId: any;
    googleMapAPIId: any;
    // Customizable Area Start
    subscriptionPurchasedDataId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        // Customizable Area Start
        this.state = {
            allowAccess: true,
            gpsLocation: "",
            isSpinnerShowing: false,
            subscriptionPurchasedData:[],
            isLoading:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.subscriptionPurchasedDataId) {
                this.setState({ subscriptionPurchasedData: responseJson.data })
                this.setState({isLoading:false})
            }
        }
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.subscriptionPurchasedData();
    }

    subscriptionPurchasedData = () => {
        this.setState({isLoading:true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("authToken"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.subscriptionPurchasedDataId = getValidationsMsg.messageId;
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.subscriptionPurchasedApiEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    };

    
    // Customizable Area End
}
