import React from 'react'
import {
  Box,
  Typography
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import ContractTimeController from './ContractTimeController';


export default class ContractTime extends ContractTimeController {
    render() {
        return (
            <React.Fragment>

            <Dialog
              fullWidth
              maxWidth="sm"
              open={this.props.show}
              onClose={this.props.handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle
                id="max-width-dialog-title"
                style={webPage.diaHeader}
              >
                <Typography style={{ fontWeight: "bold", fontSize: "24px" }}>Contract timeline</Typography>

                <IconButton
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "6px",
                    color: "blue",
                  }}
                  data-test-id="openviewicon"
                  onClick={() => this.props.handleClose()}
                >
                  <CancelIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent >
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", alignItems: 'center', marginBottom: "30px" }}>
                    <img src={this.props.profilePic} alt="" style={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50px",
                    }} />
                    &nbsp;&nbsp;&nbsp;
                    <Typography>{this.props.userName}</Typography>
                  </Box>
                  <Box style={{ display: "flex" }}>
                    
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "20px", marginBottom: "30px" }}>
                      <Typography style={webPage.type}>Offer date:</Typography>
                      <Typography style={webPage.type}>Rejection date:</Typography>
                      <Typography style={webPage.type}>Rejection reason:</Typography>
                      <Typography style={webPage.type}>Counter offer date:</Typography>
                      <Typography style={webPage.type}>Acceptance date:</Typography>
                    </Box>
                  
                        <Box style={webPage.typeBox as React.CSSProperties}>
                        <Typography style={webPage.type1}>{this.state.getContractTimeData.offer_date}</Typography>
                        <Typography style={webPage.type1}>{this.state.getContractTimeData.reject_date}</Typography>
                        {/* @ts-ignore */}
                        <Typography style={webPage.typeReason}>{this.state.getContractTimeData.reject_reason}</Typography>
                        <Typography style={webPage.type1}>{this.state.getContractTimeData.counter_date}
                        </Typography>
                        <Typography style={webPage.type1}>{this.state.getContractTimeData.accept_date}</Typography>
                      </Box>

                  </Box>

                </Box>


              </DialogContent>

            </Dialog>
          </React.Fragment>
        )
    }
}

const webPage = {
    diaHeader: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid lightGrey",
        padding: "18px 30px"
      },
      
    type: {
        color: "#9CA3AF",
        fontSize: "14px",
        marginLeft: "10px"
      },
    
      typeBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
        marginBottom: "30px"
      },
    
      type1: {
        color: "#111827",
        marginLeft: "20px",
        fontSize: "16px"
      },

      typeReason: {
        marginLeft: "20px",
        color: "#111827",
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        fontSize: "16px"
      }
  
  };


