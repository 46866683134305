import React from "react";
// Customizable Area Start
import { Grid, Dialog, Select, Typography, Avatar } from "@material-ui/core";
import OrderSummaryController, { Props } from "./OrderSummaryController";
import { close, photo2 } from "./assets";
import "./OrderSummary.css"
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End

class OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  cancelstatusmodal = () => {
    let content;

    if (this.state.reservationData.attributes?.status == "cancelled") {
      content = '';
    } else if (this.props.tabpanel == 0) {
      content = (
        <>
          <h4 className="order-sub-head mb15"> No Team Members</h4>
        </>
      );
    }

    return content;
  }

  updatebuttonFunc = () => {
    const isCancelled = this.state.reservationData.attributes?.status === "cancelled";
    const isTabPanelZero = this.props.tabpanel === 0;

    let contentToRender;

    if (isCancelled) {
      contentToRender = '';
    } else {
      contentToRender = isTabPanelZero ? (
        <div className="text-center">
          <button className="btn-update" onClick={this.updateReservationData} data-test-id="update_button" style={{ cursor: 'pointer' }}>Update</button>
        </div>
      ) : (
        <div>
          <label className="order-sub-head">Attendant</label>
          <p>No team member</p>
        </div>
      );
    }
    return contentToRender
  }
  cunstome_ss = () => this.state.reservationData?.attributes?.restaurant_customer_name || this.state.reservationData?.attributes?.customer_name
  getResevationOpt = () => {
    const { reservationData, orderStatus } = this.state;

    if (reservationData?.attributes?.status === "cancelled" || reservationData?.attributes?.status === "completed") {
      return (<Typography style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>{orderStatus}</Typography>)
    }
    return (<Select
      native
      disableUnderline
      fullWidth
      value={orderStatus}
      style={{ marginTop: 10, marginBottom: 20, cursor: 'pointer', backgroundColor: 'rgb(242, 242, 242)', minHeight: '25px' }}
      data-test-id="manageStatus"
      disabled={this.props.tabpanel !== 0}
      onChange={(e) => this.manageStatus(e)}
    >
      <option value="confirmed">Confirm</option>
      <option value="completed">Complete</option>
      {reservationData?.attributes?.status === 'in_progress' && <option value="in_progress">Pending</option>}
      <option value="cancelled">Cancel</option>
    </Select>)
  }
  // Customizable Area End
  render() {
    // Customizable Area Start

    const { reservationData } = this.state;
    const date = new Date(reservationData?.attributes?.date);
    const reserveddate = new Date(reservationData?.attributes?.order_time);
    const utcOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + utcOffset + 330);

    const formattedDate = `${reserveddate.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })} at ${reserveddate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: '2-digit'
    }).toUpperCase()}`;

    const dateformate = `${date.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })} at ${date.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: '2-digit'
    }).toUpperCase()}`;

    const formatDate = `${date.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: '2-digit'
    })}`.replace(/-/g, ' ').replace(/\b(\w)/g, char => char.toUpperCase());
    return (
      <>
        <Dialog
          fullWidth
          open={this.props.show}
          style={{ height: '100%' }}

        >
          <section className="order-body" >
            <div className="close-btn-div" style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px 15px' }}>
              <img src={close} alt="" data-test-id="close_icon" className="close-btn" style={{ cursor: 'pointer' }} onClick={() => {
                this.props.handleClose();
                this.handleAssign(null)
              }} />
            </div>
            <Grid
              container
              justifyContent="center"
            >

              <Grid item lg={8}>
                <h3 className="order-title" style={{ textAlign: 'center' }}>Order Summary</h3>
                {this.state.IsLoading === true ? <PulseLoader color="#096fff" className="loader" /> : <Grid> <section className={`order-section ${this.props.tabpanel == 0? 'mb30' : ''}`}>
                  <div className="text-center">
                    <h4 className="order-sub-head">{reservationData?.attributes?.reservation_id}</h4>
                    <span className="order-sub-text">Booking ID</span>
                  </div>
                  <div className="text-center">
                    <h4 className="order-sub-head"> {reservationData?.attributes?.status === "cancelled" ? formattedDate : dateformate}</h4>
                    <span className="order-sub-text">Booking Date</span>
                  </div>
                  {reservationData.attributes?.status !== "cancelled" && <div className="text-center">
                    <h4 className="order-sub-head">{reservationData?.attributes?.guest_count}</h4>
                    <span className="order-sub-text">Guests</span>
                  </div>}
                </section>
                  {reservationData.attributes?.status === "cancelled" && <section className="order-section-cancel mb30">
                    <div className="text-center">
                      <h4 className="order-sub-head">{formatDate}</h4>
                      <span className="order-sub-text">Reserved For</span>
                    </div>
                    <div className="text-center">
                      <h4 className="order-sub-head"> {date.toLocaleTimeString('en-IN', {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true
                      }).toUpperCase()}</h4>
                      <span className="order-sub-text">Reservation Time</span>
                    </div>
                    <div className="text-center">
                      <h4 className="order-sub-head">{reservationData?.attributes?.guest_count}</h4>
                      <span className="order-sub-text">Guests</span>
                    </div>
                  </section>}

                  <div className="text-center">
                    <h4 className="order-sub-head">{Array.isArray(reservationData?.attributes?.room_layout_properties) ? reservationData?.attributes?.room_layout_properties.map((table: any) => table.attributes.table_no || table.id).join(',') : 'N/A'}</h4>
                    <span className="order-sub-text">Table No.</span>
                  </div>
                  <section className="customer-section" style={{ marginTop: '20px' }}>
                    <div className="customer-div"><Avatar> {this.cunstome_ss()?.substring(0, 2)}</Avatar>
                      <p className="customer-name">{this.cunstome_ss()}</p>
                    </div>
                  </section>
                  {this.cancelstatusmodal()}
                  <section style={{ width: '90%', marginLeft: '5%', marginBottom: '20px' }}>
                    <label className="order-sub-head">Order Status</label>
                    {this.getResevationOpt()}
                  </section>
                  {this.updatebuttonFunc()}
                </Grid>}
              </Grid>
            </Grid>
          </section>
        </Dialog >
      </>
      // Customizable Area End
    );
  }
}

export default OrderSummary;
export { OrderSummary };

const WebstyleSummary = {

  billingSectionTotal: {
    minHeight: "52px",
    marginTop: "17px",
    padding: "0px 18px",
    borderBottom: "1px solid #9CA3AF",
    borderTop: "1px solid #9CA3AF"
  },
  billingSection: {
    marginTop: "19px",
  },
  paymentStatus: {
    padding: "0px 13px",
    marginTop: "13px",
  },
  chipRagepaid: {
    height: "24px",
    width: "70px",
    backgroundColor: "#03AC26",
    marginBottom: '23px'
  },
  chipRagenotpaid: {
    height: "24px",
    width: "85px",
    backgroundColor: "rgb(9, 111, 255)",
    marginBottom: '23px'
  },

}
// Customizable Area End


