Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";

exports.btnExampleTitle = "CLICK ME";
exports.DietaryPostURL = "bx_block_featured_restaurants/dietary_restrictions";
exports.DietaryDeleteURL = "bx_block_featured_restaurants/dietary_restrictions";
exports.DietaryGetURL = "bx_block_featured_restaurants/dietary_restrictions";
exports.FoodPostURL = "bx_block_featured_restaurants/dietary_preferences";
exports.FoodGetURL = "bx_block_featured_restaurants/dietary_preferences";
exports.FoodDeleteURL = "bx_block_featured_restaurants/dietary_preferences";
exports.reservationSettingURL = "reservation_settings/get_settings";
exports.PatchReservationURL = "reservation_settings/update_settings";
// Customizable Area End