import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  handleClose: () => void;
  show: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  coupon_name: string;
  Image_File: any | ArrayBuffer | null | unknown | File;
  BookingOpen: boolean;
  category_value: string;
  upto_amount: any | number;
  discount: any | number;
  Coupon_Code: any;
  maximum_quantity: any | number;
  discount_type: any;
  offerValidUpto: any;
  description: string;
  offerValidFromDate: any;
  checkedB: any;
  quantity: any | number;
  isLoading: boolean;
  createDiscountCouponData: any;
  codeError: boolean;
  codeErrorData: any
  discountTypeError: boolean;
  discountTypeErrorData: any;
  allSelect: boolean;
  field: any;
  email: any;
  openFilter: boolean;
  selectFlat: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OffersAndDiscountsControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createDiscountCouponCallId: any
  dateInputRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      BookingOpen: true,
      email: '',
      category_value: "",
      discount: "",
      coupon_name: "",
      upto_amount: "",
      maximum_quantity: "",
      quantity: "",
      Coupon_Code: "",
      discount_type: "% percentage",
      offerValidUpto: "",
      offerValidFromDate: "",
      Image_File: "",
      description: "",
      checkedB: false,
      openFilter: false,
      createDiscountCouponData: [],
      codeError: false,
      codeErrorData: "",
      discountTypeError: false,
      discountTypeErrorData: "",
      selectFlat: false,
      allSelect: false,
      field: "",
      isLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
 
  handleDiscountClose = async() => {
    this.setState({ Image_File: "", category_value: "", discount: "", discount_type: "", description: "",
    coupon_name: "", Coupon_Code: "", upto_amount: "", maximum_quantity: "", checkedB: "", offerValidFromDate: "",
    offerValidUpto: ""
  })
    this.props.handleClose()
  };

  onSubmit = (e: any) => {
    console.log(e, this.state.Image_File);
  };

  handleChangeradio = async(event:any) => {
    this.setState({category_value:event.target.value})
  }

  ReaderImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    const fileSizeLimitInMB = 5;
    if (!e.target.files || e.target.files.length === 0) {
      ToastMessage('error', 'Please select an image file.');
      return;
    }

    const file = e.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);

    if (fileSizeInMB <= fileSizeLimitInMB) {
      if (allowedFormats.includes(file.type)) {
        this.setState({ Image_File: file });
      } else {
        ToastMessage('error', `File '${file.name}' is not in PNG, JPEG, or JPG format.`);
      }
    } else {
      ToastMessage('error', `File '${file.name}' exceeds the ${fileSizeLimitInMB}MB file size limit.`);
    }
  };

  handleChangeDescription=async(event:any)=> {
    this.setState({description: event.target.value})
  }


  handleCreateCoupon = () => {
    const requiredFields = [
      "category_value",
      "discount",
      "coupon_name",
      "upto_amount",
      "maximum_quantity",
      "Coupon_Code",
      "offerValidUpto",
      "offerValidFromDate",
      "discount_type",
      "description",
      "Image_File",
    ];
    // @ts-ignore
    const missingFields = requiredFields.filter((field) => !this.state[field]);
    if (missingFields.length > 0) {
      const missingFieldsMessage = missingFields.join(", ");
      ToastMessage('error', `Please fill in the following fields: ${missingFieldsMessage}`)
    }
    else {
      const x = new Date(this.state.offerValidFromDate);
      const y = new Date();

      // Set the time component of both dates to 00:00:00 to compare only the dates
      x.setHours(0, 0, 0, 0);
      y.setHours(0, 0, 0, 0);

      if (x.getTime() < y.getTime()) {
        ToastMessage("error", "Date should not be earlier than current date");
      } else {
        this.createDiscountCouponCall();
      }
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createDiscountCouponCallId) {
          if (!responseJson?.data?.attributes?.errors) {
            console.log("it's comming.....", responseJson);
            this.setState({
              category_value: "", discount: "", coupon_name: "", upto_amount: "", maximum_quantity: "",
              Coupon_Code: "", offerValidUpto: "", offerValidFromDate: "", discount_type: "% percentage", description: "",
              checkedB: false, Image_File: ""
            })
            this.props.handleClose()
          }
          else {
            if (responseJson?.data?.attributes?.errors?.code[0]) {
              ToastMessage("error", "Coupon Code" + " " + responseJson?.data?.attributes?.errors?.code[0])
            }
          }
        }
      }

    }

    // Customizable Area End
  }

  // Customizable Area End


  // Customizable Area Start

  handleDateValidation = async (event: any) => {
    this.setState({ offerValidFromDate: event.target.value })
    const currentDate = new Date();
    console.log("see curnt date...", currentDate);

    if (event.target.value < currentDate) {
      ToastMessage("error", 'Date should not be earlier than current date');
      this.setState({ offerValidFromDate: "" });
    }
    else if (this.state.offerValidUpto && event.target.value > this.state.offerValidUpto) {
      ToastMessage("error", 'Offer valid date should not be older than offer valid upto date');
      this.setState({ offerValidFromDate: "" });
    }
  }

  handleDateValidationTo = async (event: any) => {
    this.setState({ offerValidUpto: event.target.value })
    if (event.target.value < this.state.offerValidFromDate) {
      ToastMessage("error", "Offer valid updo date should not be earlier than offer valid from date")
      this.setState({ offerValidUpto: "" });
    }
  }

  handleChangeDiscountType = async (event: any) => {
    if (event.target.value === "flat") {
      this.setState({ discount_type: "flat", selectFlat: true })
    } else {
      this.setState({ discount_type: "percentage", selectFlat: false })
    }
  }

  handleChangeDiscount = async (event: any) => {
    event.preventDefault();
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      if (event.target.value.length < 4) {
        this.setState({ discount: event.target.value });
      }
    }
  }

  handleChangeUptoAmount = async (event: any) => {
    event.preventDefault();
    if (!this.state.selectFlat) {
      const regex = /^[0-9\b]+$/;
      if (event.target.value === '' || regex.test(event.target.value)) {
        this.setState({ upto_amount: event.target.value });
      }
    }
    else {
      this.setState({ upto_amount: this.state.discount })
    }
  }

  handleChangeMaxQuantity = async (event: any) => {
    event.preventDefault();
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      this.setState({ maximum_quantity: event.target.value });
    }
  }

  handleChange = async (event: any) => {
    this.setState({ ...this.state.checkedB, [event.target.name]: event.target.checked })
  }

  createDiscountCouponCall = async () => {
    const header = {
      "token": localStorage.getItem("authToken")
    };

    let formdata = new FormData();
    formdata.append("data[coupon_category]", this.state.category_value);
    formdata.append("data[coupon_name]", this.state.coupon_name);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[code]", this.state.Coupon_Code);
    formdata.append("data[discount_type]", this.state.discount_type);
    formdata.append("data[discount]", this.state.discount);
    formdata.append("data[valid_from]", this.state.offerValidFromDate);
    formdata.append("data[valid_to]", this.state.offerValidUpto);
    formdata.append("data[coupon_status]", this.state.checkedB);
    formdata.append("data[up_to_ammount]", this.state.upto_amount);
    formdata.append("data[max_quantity]", this.state.maximum_quantity);
    if (this.state.Image_File != "") {
      formdata.append("data[image]", this.state.Image_File);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createDiscountCouponCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_coupon_cg/coupon_code_generator`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  // Customizable Area End
}
