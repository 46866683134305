import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose: () => void;
  show: boolean;
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  displayFiles: any;
  openUploadedImg: boolean;
  imageAdded: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}


export default class AddPhotosModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  FileUploadCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      displayFiles: [],
      openUploadedImg: false,
      imageAdded: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start

    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForImageUpload(from, message)
    // Customizable Area End
  }

  // Customizable Area Start

  //Web logic Function
  handleFileUpload = (event: any) => {
    const selectedFiles = Array.from(event?.target?.files);

    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];

    const newFiles: any = [];
    selectedFiles.forEach((file: any) => {
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB <= 5) {
        if (allowedFormats.includes(file.type)) {

          newFiles.push(file);
        } else {
          ToastMessage('error', `File '${file.name}' is not in PNG, JPEG, or JPG format.`);
        }
      } else {
        ToastMessage('error', `File '${file.name}' exceeds the 5MB file size limit.`);
      }
    });
    this.setState({ displayFiles: [...this.state.displayFiles, ...newFiles] });
    setTimeout(() => {
      if (this.state.displayFiles.length > 0) {

        this.setState({ openUploadedImg: true });
      }
      if (this.state.displayFiles.length >= 10) {
        ToastMessage('error', 'Only 10 images are allowed.');
      }
    });
  };


  deleteImg = (index: number) => {
    this.state.displayFiles.splice(index, 1);
    this.setState({ displayFiles: this.state.displayFiles })
    if (this.state.displayFiles.length === 0) {
      this.setState({ openUploadedImg: false });
    }
  }

  goUploadPage = () => {
    this.setState({ openUploadedImg: false })
  }

  //API Function
  FileUpload = async () => {

    if (this.state.imageAdded === false) {
      if (this.state.displayFiles.length == 0) {
        return ToastMessage('error', 'Please select atleast one image file');
      }
      if (this.state.displayFiles.length > 10) {
        return ToastMessage('error', 'Only 10 images are allowed.');
      }
      const formData = new FormData();

      for (const [i] of this.state.displayFiles.entries()) {
        formData.append(
          "restaurant_images[]",
          this.state.displayFiles[i],
        );
      }

      const header = {
        token: localStorage.getItem("authToken")
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.FileUploadCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_location/restaurant_images?restaurant_location_id=${localStorage.getItem('locationID')}`
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ imageAdded: true });
      return true;
    }
  };


  //API Response Function
  handleResForImageUpload = async (from: string, message: Message) => {
    if (this.FileUploadCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ imageAdded: false })
      if (!apiResponse.message) {
        ToastMessage("success", "Image Uploaded Successfully")
        this.setState({
          displayFiles: [], openUploadedImg: false
        })
        this.props.handleClose()
      } else {
        ToastMessage("error", "You can't upload more than 10 image")
      }
    }

  }

  handleCloseModal = () => {
    this.setState({ displayFiles: [], openUploadedImg: false })
    this.props.handleClose()
  }
  // Customizable Area End

}