import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openEditModalId: any;
  openManageQueueId: any;
  tabPanelNo: number;
  isloading: boolean;
  selectedTab: string;
  upComingOrderData: any[];
  historicalOrderData: any[];
  currentPage: any;
  itemsPerPage: any;
  upcomingdata: any;
  historicaldata: any;
  GetWaitinglistData:any[];
  waitingDataCount:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderDetailViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  upComingOrderId: string = "";
  historicalOrderId: string = "";
  WaitinglistDataGetCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openEditModalId: null,
      openManageQueueId: null,
      tabPanelNo: 0,
      selectedTab: "Reservations",
      upComingOrderData: [],
      isloading: false,
      historicalOrderData: [],
      currentPage: 1,
      itemsPerPage: 10,
      historicaldata: '0',
      upcomingdata: '0',
      GetWaitinglistData:[],
      waitingDataCount:'0'
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.upComingOrderId) {
      if (responseJson.data?.upcomming_reservations?.data) {

        this.setState({ upComingOrderData: responseJson.data.upcomming_reservations.data, isloading: false, upcomingdata: responseJson.upcomming_orders_count }, () => {
        });
      }
      else {
        this.setState({ isloading: false, upComingOrderData: [] });
      }
    } else if (apiRequestCallId === this.historicalOrderId && responseJson.data?.historical_orders?.data) {
      this.setState({ historicalOrderData: responseJson.data.historical_orders.data, historicaldata: responseJson.historical_orders_count });
    } else if (apiRequestCallId === this.WaitinglistDataGetCallID && responseJson.data?.waiting_list_reservations?.data) {
      this.setState({ GetWaitinglistData: responseJson.data.waiting_list_reservations.data, waitingDataCount: responseJson.waiting_list_orders_count });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    this.handleUpComingOrder();
    this.handleHistoricalOrder();
    this.handleWaitingListData()
  }

  handleCloseEditModal = () => {
    this.setState({ openEditModalId: null })
  }

  handleopenEditModalId = (id: any) => {
    this.setState({ openEditModalId: id })
  }

  handleCloseManageQueueModal = () => {
    this.setState({ openManageQueueId: null })
  }

  handleOpenManageQueueModal = (id: any) => {
    this.setState({ openManageQueueId: id })
  }

  handleOpenViewModal = (id: any) => {
    this.setState({ openEditModalId: id })
  }

  hanleTabs = (value: number) => {
    this.setState({ tabPanelNo: value, currentPage: 1 }, () => {
      if (this.state.tabPanelNo === 0) {
        this.handleUpComingOrder();
      } else if (this.state.tabPanelNo === 1) {
        this.handleHistoricalOrder();
      }
    })

  }
  handleTabClick = (value: any) => {
    this.setState({ selectedTab: value })
  }
  handleUpComingOrder = async () => {
    const { currentPage } = this.state;
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,

    };
    const locationId = localStorage.getItem("locationID");
    const endPoint = `upcoming_reservation_orders?order_type=reservation&restaurant_location_id=${locationId}&page=${currentPage}&per_page=10`
    const req = apiCall({
      httpBody: {},
      header: headers,
      url: endPoint,
      httpMethod: 'GET'
    })
    this.setState({ isloading: true });
    this.upComingOrderId = req.messageId;
    runEngine.sendMessage(req.id, req);

  };

  handleHistoricalOrder = async () => {
    const { currentPage } = this.state;
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,
    };
    const locationId = localStorage.getItem("locationID");
    const endPoint = `historical_reservation_orders?order_type=reservation&restaurant_location_id=${locationId}&page=${currentPage}&per_page=10`
    const req = apiCall({
      httpBody: {},
      header: headers,
      url: endPoint,
      httpMethod: 'GET'
    })
    this.historicalOrderId = req.messageId;
    runEngine.sendMessage(req.id, req);

  };

  handleChangePage = (event: any, newPage: any) => {
    this.setState({ currentPage: newPage }, () => {
      if (this.state.tabPanelNo === 0) {
        this.handleUpComingOrder();
      } else if (this.state.tabPanelNo === 1) {
        this.handleHistoricalOrder();
      }
    });
  };

  CreateReservation = () => {
    this.props.navigation.navigate("Reservations")
  }
  handleWaitingListData = async () => {
    const { currentPage } = this.state;
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,
    };
    const locationId = localStorage.getItem("locationID");
    const endPoint = `waiting_list_reservation_orders?order_type=reservation&restaurant_location_id=${locationId}&page=${currentPage}&per_page=10`
    const req = apiCall({
      httpBody: {},
      header: headers,
      url: endPoint,
      httpMethod: 'GET'
    })
    this.WaitinglistDataGetCallID = req.messageId;
    runEngine.sendMessage(req.id, req);

  };
  // Customizable Area End
}
