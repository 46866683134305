import * as React from "react";

// Customizable Area Start
import {
  MenuItem, Divider, ListItemIcon, ListItemText, Avatar, Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import NotificationMenuController, {
  Props
} from "./NotificationMenuController.web";
export default class ViewAllNotification extends NotificationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { menuItems } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Grid container >
          <Grid item sm={12} >
            <div style={webStyleAllNotification.needBlock}>
              <h2 style={webStyleAllNotification.needheading}>Notification Centre</h2>
              <Grid style={webStyleAllNotification.wrapperSubscreption}>
                <span style={webStyleAllNotification.readAllDataLabel} data-test-id="markasAllRead" onClick={this.getMarkAsAllRead}>Mark all as read</span>
                <Grid style={{ marginLeft: '-15px' }} >
                  {menuItems ? menuItems.filter((menuItem: { attributes: { is_read: boolean; }; }) => menuItem.attributes?.is_read === false)
                    .concat(menuItems.filter((menuItem: { attributes: { is_read: boolean; }; }) => menuItem.attributes?.is_read === true))
                    .map((menuItem: any, index: any) => (
                      <div>

                        <MenuItem data-test-id="menu_close" key={index} onClick={() => {
                          if (!menuItem.attributes?.is_read) {
                            this.putAllNotification(menuItem.id);
                          }
                        }}>
                          <ListItemIcon>
                            <div style={{ position: 'relative' }}>
                              {menuItem.attributes?.is_read === true ? "" : <div style={webStyleAllNotification.Dot as any} />}

                              <Avatar style={webStyleAllNotification.avatar} >{menuItem.attributes.account?.data?.attributes?.profile_picture ? menuItem.attributes.account?.data?.attributes?.profile_picture : menuItem.attributes.account?.data?.attributes?.user_name.slice(0, 2)}</Avatar>
                            </div>
                          </ListItemIcon>
                          <div style={webStyleAllNotification.textdiv as any}>
                            <ListItemText primaryTypographyProps={{ style: menuItem.attributes?.is_read === true ? webStyleAllNotification.ListitemRead as any : webStyleAllNotification.Listitem as any }}>{menuItem.attributes.contents}</ListItemText>
                            <div style={webStyleAllNotification.time}>
                              {new Date(menuItem.attributes?.created_at).toLocaleString('en-IN', {
                                day: '2-digit',
                                month: '2-digit',
                                hour: '2-digit',
                                year: 'numeric',
                                minute: '2-digit'
                              })}
                            </div>
                          </div>
                        </MenuItem>

                        <Divider style={webStyleAllNotification.divider} />
                      </div>
                    )) : <span style={webStyleAllNotification.spanNoData}>No Notification Found</span>}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>

      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyleAllNotification = {

  needBlock: {
    padding: "5px",
    width: ' 100%',
  },

  needheading: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#111111",
    margin: "0",
  },

  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    margin: "25px 0 0",
    padding: "25px 30px",
    height: 'auto',
    minHeight: "100vh"
  },
  avatar: {
    width: "32px",
    height: "32px",

  },
  Dot: {
    position: 'absolute',
    top: '-4px',
    right: '28px',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: 'orange'
  },
  Listitem: {
    fontWeight: 500,
    fontSize: '12px',
    overflow: 'hidden',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '16px',
  },
  ListitemRead: {
    fontWeight: 500,
    fontSize: '12px',
    overflow: 'hidden',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: '16px',
    color: '#9D9D9D',
  },
  time: {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#9D9D9D',
  },
  header: {
    padding: '10px 0 10px 20px',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',

  },
  viewall: {
    padding: '10px 0 10px 20px',
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#096FFF',
    fontStyle: 'normal',
    fontWeight: 600,
    paddingTop: '15px',
    paddingRight: '15px',
    textDecoration: 'none',
  },
  spanNoData: { color: '#6a6a6f', marginLeft: '30px' },
  textdiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  readAllDataLabel: { color: '#096FFF', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' },
  divider: {
    width: '98%',
    marginLeft: '15px'
  }
}
// Customizable Area End
