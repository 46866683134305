import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleClose: () => void;
    show: boolean;
    editId:any
    tabpanel:any;
    recallTableApi: () => void
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    assignedMember:null;
    orderStatus:string;
    reservationData:any;
    reservationItem:any;
    IsLoading:boolean;
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class OrderSummaryController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getResrvationCallID:string=""
    updateResrvationCallID:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            assignedMember:null,
            orderStatus:'',
            reservationData:[],
            reservationItem:[],
            IsLoading:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start        
         this.getReservationData()
        // Customizable Area End
    }



    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.getResrvationCallID) {
            this.setState({reservationData:responseJson.data?.reservation?.data,IsLoading: false, orderStatus: responseJson.data?.reservation?.data?.attributes?.status,reservationItem:responseJson.data?.reservation_items?.data});
        } else if(apiRequestCallId === this.updateResrvationCallID){
          this.props.recallTableApi();
          this.props.handleClose()
        }
        // Customizable Area End
    }

    // Customizable Area Start
     handleAssign = (memberId:any) => {
        this.setState({assignedMember:memberId});
      };
      manageStatus=(e:any)=>{
        this.setState({orderStatus:e.target.value})
      }
    
      getReservationData = async () => {
        let tokenValue = await localStorage.getItem('authToken');
        const headers = {
          token: tokenValue,
    
        };
        const endPoint = `bx_block_featured_restaurants/feature_restaurants/get_regervation/${this.props.editId}?data[latitude]=44.981165&data[longitude]=-93.279225`
        const req = apiCall({
          httpBody: {},
          header: headers,
          url: endPoint,
          httpMethod: 'GET'
        })
        this.setState({ IsLoading: true });
        this.getResrvationCallID = req.messageId;
        runEngine.sendMessage(req.id, req);
        
      };
          
      updateReservationData= async () => {
        let tokenValue = await localStorage.getItem('authToken');
        const headers = {
          token: tokenValue,
    
        };
        let formdata = new FormData();
        formdata.append("reservation_id", this.props.editId);
        formdata.append("status", this.state.orderStatus);
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_featured_restaurants/feature_restaurants/restaurant_reservation_edit"
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PUT"
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        this.updateResrvationCallID = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    // Customizable Area End

}

