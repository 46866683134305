import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Tab,
  Tabs,
  Paper,
  Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Avatar, Chip, Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CreateIcon from '@material-ui/icons/Create';
import OrderSummary from "./OrderSummary.web";
import Loader from "../../../components/src/Loader.web";
import Pagination from '@material-ui/lab/Pagination';
import ManageQueue from "./ManageQueue.web";
const theme = createTheme({
  palette: {
    primary: { main: '#096FFF' },
    secondary: { main: '#9CA3AF' },
  },
  typography: {
    "fontFamily": `inter, sans-serif`,
    h2: {
      fontSize: "24px",
      fontWeight: 400
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400
    },
    h1: {
      fontSize: "40px",
      fontWeight: 500
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400
    }
  },
});

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      borderBottom: "none",
    },
  }),
)((props: any) => <TableCell {...props} />);

const StyledIconButton: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      padding: "8px",
    },
  }),
)((props: any) => <IconButton {...props} />);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      '&:hover': {
        borderRadius: "8px",
        boxShadow: "0px 12px 18px rgba(0, 0, 0, 0.121203)",
      }
    },
  }),
)((props: any) => <TableRow {...props} />);

const waitingTableHeader = [
  { title: 'CUSTOMER NAME', align: "left" },
  { title: 'BOOKING ID', align: "center" },
  { title: 'BOOKING TIME', align: "center" },
  { title: 'QUEUE', align: "center" },
  { title: 'STATUS', align: "center" },
  { title: 'ACTION', align: "center" }
]

const tableheader = [
  { title: 'CUSTOMER NAME', align: "left" },
  { title: 'BOOKING ID', align: "center" },
  { title: 'BOOKING TIME', align: "center" },
  { title: 'QUEUE/TABLE NO', align: "center" },
  { title: 'STATUS', align: "center" },
  { title: 'ACTION', align: "center" }
]

const tableheaderHistorical = [
  { title: 'CUSTOMER NAME', align: "left" },
  { title: 'BOOKING ID', align: "center" },
  { title: 'BOOKING TIME', align: "center" },
  { title: 'STATUS', align: "center" },
  { title: 'ACTION', align: "center" }
]
// Customizable Area End

import OrderDetailViewController, {
  Props
} from "./OrderDetailViewController";


export default class OrderDetailView extends OrderDetailViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  QueueAddFunction = (attributes: any) => {
    let QueueData;
    if (attributes.status === "waiting") {
      QueueData = attributes.queue_count;
    } else if (Array.isArray(attributes?.room_layout_properties)) {
      QueueData = <Tooltip title={attributes.room_layout_properties.map((table: any) => table.attributes.table_no || table.id).join(',')} arrow><span style={{
        maxWidth: '115px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'

      }}>{attributes.room_layout_properties.map((table: any) => table.attributes.table_no || table.id).join(',')}</span></Tooltip>;
    } else {
      QueueData = 'N/A';
    }

    return QueueData;
  }
  GenTableRow = (attributes: any, id: any) => {
    interface StatusLabel {
      label: string;
      style: {
        backgroundColor: string;
        color: string;
        minWidth: string;
      };
    }

    const statusLabel: { [key: string]: StatusLabel } = {
      cancelled: { label: "Cancelled", style: webStyle.chipCancel },
      confirmed: { label: "Confirmed", style: webStyle.chipConfirm },
      in_progress: { label: "Pending", style: webStyle.chipWaitlist },
      completed: { label: "Completed", style: webStyle.chipConfirm },
      waiting: { label: "Waiting", style: webStyle.chipWaitlist }
    };

    const statusChip = statusLabel[attributes.status];
    const Newdate = new Date(attributes.date);
    const utcOffset = Newdate.getTimezoneOffset();
    Newdate.setMinutes(Newdate.getMinutes() + utcOffset + 330);
    const formattedNewDate = `${Newdate.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })} at ${Newdate.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).toUpperCase()}`;


    const customer_names = attributes?.restaurant_customer_name || attributes?.customer_name;
    return (
      <StyledTableRow key={id} data-test-id="StyledTableRow">

        <StyledTableCell data-test-id="tableCell" align="left" style={webStyle.tableCellCustomerName}>
          <Avatar style={webStyle.avatarRage} src={attributes.profile_picture} >{customer_names?.substring(0, 2)}</Avatar >
          <Typography variant="body1" style={{
            minWidth: '100%', overflow: 'hidden',
            textOverflow: 'ellipsis', whiteSpace: 'nowrap'
          }}>{customer_names}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1">{attributes.reservation_id}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1" >{formattedNewDate}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1" style={{ display: 'flex', justifyContent: 'center' }}>
            {this.QueueAddFunction(attributes)}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1">{statusChip && <Chip style={statusChip.style} label={statusChip.label} />}</Typography>
        </StyledTableCell>

        <StyledTableCell padding="none" align="center">
          {attributes.status === "waiting" ? <StyledIconButton data-test-id="tablecelledit" onClick={() => this.handleOpenManageQueueModal(id)} >
            <CreateIcon style={{ fontSize: "16px" }} color="primary" />
          </StyledIconButton> : <StyledIconButton data-test-id="handleopenEditModalId" onClick={() => this.handleopenEditModalId(id)} >
            <CreateIcon style={{ fontSize: "16px" }} color="primary" />
          </StyledIconButton>}
        </StyledTableCell>
      </StyledTableRow>
    )
  }

  GenTableRowHistorical = (attributes: any, id: any) => {
    interface StatusLabel {
      label: string;

      style: {

        color: string;
        backgroundColor: string;

        minWidth: string;
      };
    }

    const statusLabel: { [key: string]: StatusLabel } = {
      completed: { label: "Completed", style: webStyle.chipConfirm },
      confirmed: { label: "Confirmed", style: webStyle.chipConfirm },
      in_progress: { label: "Pending", style: webStyle.chipWaitlist },
      cancelled: { label: "Cancelled", style: webStyle.chipCancel },
    };

    const status = statusLabel[attributes.status];

    const date = new Date(attributes.date);
    const utcOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + utcOffset + 330);
    const formattedDate = `${date.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })} at ${date.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: '2-digit'
    }).toUpperCase()}`;

    const customer_name = attributes?.restaurant_customer_name || attributes?.customer_name;
    return (
      <StyledTableRow >
        <StyledTableCell style={webStyle.tableCellCustomerName} align="left" data-test-id="tableCell">
          <Avatar style={webStyle.avatarRage} src={attributes.profile_picture}>{customer_name?.substring(0, 2)}</Avatar>
          <Typography variant="body1" style={{
            minWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>

            {customer_name}

          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1">
            {attributes.reservation_id}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1">
            {formattedDate}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1">
            {status && <Chip label={status.label} style={status.style} />}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center" padding="none">
          <StyledIconButton onClick={() => this.handleOpenViewModal(id)} data-test-id="tablecellview">
            <VisibilityOutlinedIcon color="primary" style={{ fontSize: "16px" }} />
          </StyledIconButton>
        </StyledTableCell>

      </StyledTableRow >
    )

  }

  tableDataAllUpcomming = () => {

    let content;
    const isArrayWithData = Array.isArray(this.state.upComingOrderData) && this.state.upComingOrderData.length > 0;

    if (this.state.isloading) {
      content = <Loader loading={this.state.isloading} />;
    } else if (isArrayWithData) {
      content = this.state.upComingOrderData.map((val: any) => {
        return this.GenTableRow(val.attributes, val.id);
      });
    } else {
      content = (

        <TableRow>
          <StyledTableCell colSpan={6} align="center">
            No Upcoming Order Data
          </StyledTableCell>
        </TableRow>

      );
    }
    return content;

  }

  getHistoricalTable = () => {
    let historicalOrderTableContent;
    if (this.state.historicalOrderData.length > 0) {
      historicalOrderTableContent = this.state.historicalOrderData.map((val: any) => {
        return this.GenTableRowHistorical(val.attributes, val.id);
      });

    } else {

      historicalOrderTableContent = (
        <TableRow>
          <StyledTableCell colSpan={6} align="center">
            No Historical Order Data
          </StyledTableCell >
        </TableRow>
      );
    }
    return historicalOrderTableContent;
  }

  getCurrectTabStyle = (tabname: string) => {
    const styleForTab = this.state.selectedTab === tabname
      ? webStyle.tableNavigationSelected
      : webStyle.tableNavigationText;
    return styleForTab;
  }

  renderReservationTab = (tabName: any, label: any, count: any) => {
    return (
      <Typography
        variant="body1"
        style={this.getCurrectTabStyle(tabName)}
        data-test-id={tabName}
        onClick={() => this.handleTabClick(tabName)}
      >
        {label}
        {this.state.selectedTab === tabName ? <span style={webStyle.tableNavigationSelectedNo}>{count}</span> : ''}
      </Typography>
    );
  };
  GenrateWaitingListTableRow = (attributes: any, id: any) => {

    const Waitingdate = new Date(attributes.date);
    const utcOffsetDate = Waitingdate.getTimezoneOffset();
    Waitingdate.setMinutes(Waitingdate.getMinutes() + utcOffsetDate + 330);
    const formateDate = `${Waitingdate.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })} at ${Waitingdate.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).toUpperCase()}`;


    const customerName1 = attributes?.restaurant_customer_name || attributes?.customer_name;
    return (
      <StyledTableRow key={id} data-test-id="StyledTableRow">
        <StyledTableCell data-test-id="tableCell" align="left" style={webStyle.tableCellCustomerName}>
          <Avatar style={webStyle.avatarRage} src={attributes.profile_picture} >{customerName1?.substring(0, 2)}</Avatar >
          <Typography variant="body1" style={{minWidth: '100%', overflow: 'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{customerName1}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1">{attributes.reservation_id}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1" >{formateDate}</Typography>
        </StyledTableCell >

        <StyledTableCell align="center">
          <Typography variant="body1" style={{ display: 'flex', justifyContent: 'center' }}>{this.QueueAddFunction(attributes)}</Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1"><Chip style={webStyle.chipWaitlist} label={attributes.status} /></Typography>
        </StyledTableCell>

        <StyledTableCell padding="none" align="center">
          <StyledIconButton data-test-id="tablecelledit11" onClick={() => this.handleOpenManageQueueModal(id)} ><CreateIcon style={{ fontSize: "16px" }} color="primary" /></StyledIconButton></StyledTableCell>
      </StyledTableRow>
    )
  }
  WaitingListTabData = () => {
    let waitingData;
    const isWaitingArrayData = Array.isArray(this.state.GetWaitinglistData) && this.state.GetWaitinglistData.length > 0;
    if (this.state.isloading) {
      waitingData = <Loader loading={this.state.isloading} />;
    } else if (isWaitingArrayData) {
      waitingData = this.state.GetWaitinglistData.map((val: any) => {
        return this.GenrateWaitingListTableRow(val.attributes, val.id);
      });
    } else {
      waitingData = (
        <TableRow><StyledTableCell colSpan={6} align="center">No Waiting Order Data</StyledTableCell></TableRow>
      );
    }
    return waitingData;
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Grid style={{ display: 'flex', justifyContent: "space-between" }}> <Typography variant="h2" component={'h2'} style={{ marginTop: '6px', fontWeight: 600 }}>My Orders</Typography>
          <button
            style={webStyle.btntheme}
            data-test-id="submit"
            onClick={this.CreateReservation}
          >
            Create Reservation
          </button>
        </Grid>
        <div style={webStyle.mainWrapper}>
          <Paper elevation={3} style={webStyle.tableSection as any}>
            <TableContainer style={webStyle.tableLayout}>
              <Grid container xs={12} sm={8} lg={12} justifyContent="space-between" alignItems="center" style={webStyle.tableNavigationContainer}>
               <Grid item xs={12} sm={12} lg={6} style={{ display: 'flex', gap: '30px' }}>
                  <Typography
                    variant="body1"
                    style={this.state.tabPanelNo === 0 ? webStyle.tabletabSelected : webStyle.tabletab}
                    data-test-id="upcoming"
                    onClick={() => this.hanleTabs(0)}
                  >
                    UPCOMING ORDERS
                  </Typography>
                  <Typography
                    variant="body1"
                    style={this.state.tabPanelNo === 1 ? webStyle.tabletabSelected : webStyle.tabletab}
                    data-test-id="historical"
                    onClick={() => this.hanleTabs(1)}
                  >
                    HISTORICAL ORDERS
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} style={webStyle.tableNavigation}>
                  {this.renderReservationTab('Waiting_List', 'Waiting List', this.state.waitingDataCount)}
                  {this.renderReservationTab('Reservations', 'Reservations', this.state.tabPanelNo === 0 ? this.state.upcomingdata : this.state.historicaldata)}
                </Grid>

              </Grid>

              {this.state.selectedTab === "Reservations" ? <Table>
                <TableHead>
                  {this.state.tabPanelNo == 0 ? <TableRow>
                    {tableheader.map(({ title, align }) => {
                      return (
                        <StyledTableCell align={align} key={align}>
                          <Typography variant="caption" style={{ color: "#9CA3AF" }}>
                            {title}
                          </Typography>
                        </StyledTableCell>
                      )
                    })}
                  </TableRow> :
                    <TableRow>
                      {tableheaderHistorical.map(({ title, align }) => {
                        return (
                          <StyledTableCell align={align} key={align}>
                            <Typography variant="caption" style={{ color: "#9CA3AF" }}>
                              {title}
                            </Typography>
                          </StyledTableCell>
                        )
                      })}
                    </TableRow>
                  }
                </TableHead>



                {this.state.tabPanelNo === 0 ? <TableBody>
                  {this.tableDataAllUpcomming()}
                </TableBody>
                  :
                  <TableBody>
                    {this.getHistoricalTable()}
                  </TableBody>
                }


              </Table> : <Table>
                <TableHead> <TableRow>
                  {waitingTableHeader.map(({ title, align }) => {
                    return (
                      <StyledTableCell align={align} key={align}>
                        <Typography variant="caption" style={{ color: "#9CA3AF" }}>
                          {title}
                        </Typography>
                      </StyledTableCell>
                    )
                  })}
                </TableRow></TableHead>
                <TableBody>
                  {this.WaitingListTabData()}
                </TableBody>
              </Table>}
              <Pagination count={this.state.tabPanelNo === 0 ? Math.ceil(this.state.upcomingdata / 10) : Math.ceil(this.state.historicaldata / 10)} variant="outlined" color="primary"
                page={this.state.currentPage}
                data-test-id="pagination"
                onChange={this.handleChangePage}
                style={webStyle.paginationstyle} />
            </TableContainer>
          </Paper>
        </div>
        {this.state.openEditModalId !== null && <OrderSummary navigation={'OrderDetailModal'} id={'OrderDetailModalId'} handleClose={this.handleCloseEditModal} show={this.state.openEditModalId === null ? false : true} recallTableApi={this.handleUpComingOrder} editId={this.state.openEditModalId} tabpanel={this.state.tabPanelNo} />}
        {this.state.openManageQueueId !== null && <ManageQueue navigation={'ManageQueue'} id={'ManageQueue'} handleClose={this.handleCloseManageQueueModal} show={this.state.openManageQueueId === null ? false : true} recallTableApi={this.handleUpComingOrder} editId={this.state.openManageQueueId} tabpanel={this.state.tabPanelNo} />}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    marginTop: "30px",
  },
  paperLayout: {
    height: "168px",
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #EFEFF5",
    boxShadow: "20px 10px 40px rgba(0, 0, 0, 0.121012)"
  },
  paginationstyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '40px'
  },
  dotIcon: {
    fontSize: "26px"
  },
  paperHeader: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  paperMainInfoContainer: {
    marginTop: "32px"
  },
  paperContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableNavigationSelectedNo: {
    color: "black",
    padding: "3px 5px",
    backgroundColor: "white",
    borderRadius: "8px",
    marginLeft: "12px",
    fontSize: "12px",
  },
  circularInfo: {
    height: "96px",
    width: "96px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "50%",
    backgroundColor: "#ECF0FF"
  },
  tableSection: {
    marginTop: "24px",
    padding: "16px 8px",
    borderRadius: "8px",
    position: "relative",
  },
  tableLayout: {
    border: "1px solid #EFEFF5",
    borderRadius: "8px",
    overflow: 'auto'
  },
  avatarRage: {
    height: "24px",
    width: "24px"
  },
  tableCellRage: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    marginBottom: "10px"
  },
  chipRage: {
    maxWidth: "fit-content",
    color: "#03AC26",
    borderColor: "#03AC26"
  },
  chipRageInActive: {
    maxWidth: "fit-content",
    color: "#DB1C1C",
    borderColor: "#DB1C1C"
  },
  activeDot: {
    width: "8px",
    height: "8px",
    marginLeft: "7px",
    color: "#03AC26",
  },
  inActiveDot: {
    width: "8px",
    height: "8px",
    marginLeft: "7px",
    color: "#DB1C1C",
  },
  tableCellCustomerName: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    width: '150px'
  },
  planChip: {
    height: "12px",
    fontSize: "6px"
  },
  planChipGold: {
    height: "12px",
    fontSize: "6px",
    backgroundColor: "#EACB09"
  },
  chipConfirm: {
    backgroundColor: "#03AC26",
    color: "white",
    minWidth: "100px"
  },
  chipCancel: {
    backgroundColor: "#DB1C1C",
    color: "white",
    minWidth: "100px"
  },
  chipWaitlist: {
    backgroundColor: "#096FFF",
    color: "white",
    minWidth: "100px"
  },
  secondTableHead: {
    backgroundColor: "#F8F8F8",
  },
  rightTopTitle1: {
    display: "flex",
    width: "660px",
    height: "40px",
    backgroundColor: "#F2F2F2",
    borderRadius: "24px",
    marginTop: "8px"
  },
  rightTopTitle2: {
    backgroundColor: "#096FFF",
    display: "flex",
    height: "40px",
    borderRadius: "22px",
    margin: "8px 10px 9px -25px"
  },
  tableNavigationContainer: {
    padding: "14px 24px 14px 24px",
    borderBottom: "1px solid rgba(9, 111, 255, 0.24)",
  },
  tableNavigation: {
    display: "flex",
    //justifyContent: "space-between",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    borderRadius: "18px",
    marginTop: '12px'
  },
  tableNavigationSelected: {
    color: "white",
    borderRadius: "18px",
    padding: "13px 38px",
    backgroundColor: "#096FFF"
  },
  tabletab: {
    marginTop: '12px',
    color: '#9CA3AF',
    cursor: "pointer",
  },
  tabletabSelected: {
    color: "#096FFF",
    borderBottom: '2px solid #096FFF',
    display: "inline-block",
    padding: "12px 0px",
    cursor: "pointer",
    fontWeight: 600
  },
  tableNavigationText: {
    padding: "6px 24px"
  },
  btntheme: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "50px",
    minWidth: "180px",
    borderRadius: "23px",
    fontSize: "15px",
    cursor: 'pointer'
  },
};
// Customizable Area End
