import React from 'react'
import {
  Button,
  Box,
  Typography,
  FormControl,
  FormLabel,
  TextField,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';

import ViewReasonController from './ViewReasonController.web';


export default class ViewReason extends ViewReasonController {
    render() {
        const {viewReasonData, price, posts, description, imageFile} = this.state
        const hasCounterofferFields= !price || !posts || !description || !imageFile
        return (
            <React.Fragment>
            {viewReasonData ? 
            <Dialog
              fullWidth
              maxWidth="sm"
              open={this.props.show}
              onClose={this.props.handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle
                id="max-width-dialog-title"
                style={webPage.diaHeader}
              >
                <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>Rejected Reason</Typography>

                <IconButton
                  data-test-id ="viewclose"
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "6px",
                    color: "blue",
                  }}
                  onClick={() => 
                    this.props.handleClose()
                  }
                >
                  <CancelIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent >
                <form style={webPage.form} noValidate>
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <Box style={{ display: "flex", alignItems: 'center' }}>
                      <img src={viewReasonData?.profile_picture?.url} alt="" style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50px",
                      }} />
                      &nbsp;&nbsp;&nbsp;
                      <Typography>{viewReasonData.influencer_id?.influencer_name}</Typography>
                    </Box>
                 
                  </Box>
                  <Box style={{  
                    marginTop: 10,
                    minWidth: 120,
                    height: "auto",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "4px",
                    padding: "10px",
                    overflow: "hidden", // Hide any overflowing content
                    wordWrap: "break-word",
                    }}
                    >
                     { viewReasonData.reject_reason !=null ? viewReasonData.reject_reason : "There is no reason described yet"}           
                  </Box>
                  
                  <Box style={{ display: "flex", justifyContent: "space-between", margin: "30px" }}>
                    <Button
                      data-test-id="btnRemoveCase"
                      style={{
                        textTransform: "capitalize",
                        color: "#5C8BF6",
                        border: "1px solid #5C8BF6",
                        borderRadius: "20px",
                        width: "45%"
                      }}
                      onClick={()=> this.props.handleClose()}
                    >
                      Reject Proposal
                    </Button>
                    <Button
                     data-test-id = "modalClose"
                      color="primary"
                      style={{
                        textTransform: "capitalize",
                        background: "#5C8BF6",
                        color: "#fff",
                        borderRadius: "20px",
                        width: "45%"
                      }}
                      onClick={()=> {
                        this.props.handleClose()
                        this.setState({counterModalOpen: true})
                      }}
                    >
                      Counter offer
                    </Button>
                  </Box>

                </form>
              </DialogContent> 

            </Dialog>
            : <></> }
        <div>
          <Dialog
            maxWidth="sm"
            fullWidth
            onClose={this.handleCounterOfferModalClose}
            open={this.state.counterModalOpen}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title" 
             style={webPage.diaHeader}>
              <Typography>
                Proposal
              </Typography>
              <IconButton
                data-test-id="btnCloseIcon"
                aria-label="close"
                style={{ position: "absolute",right: "0px",top: "0px",color: "blue"
                }}
                onClick={
                  this.handleCounterOfferModalClose
                }
              >
            <CloseIcon />
          </IconButton>
          </DialogTitle>
            <DialogContent>
              <form style={{display: "flex",flexDirection: "column",margin: "auto"}}
                noValidate
              >
              <FormControl>
                <FormLabel>Post per month</FormLabel>
                <TextField variant="outlined"
                     id='posts'
                     fullWidth
                     size="small"
                     style={{fontFamily: "Verdana",marginTop: "10px",marginBottom: "10px"}}
                     type="text"
                     placeholder="Enter posts per month ..."
                     name="post"
                     value={this.state.posts}
                     onChange={(e:any)=> this.handleChangePosts(e)}
                  />
                </FormControl>  
               <FormControl>
                <FormLabel>Price per post</FormLabel>
                  <div style={webPage.dai}>
                    <select name="currency_type"
                      id='select_currency'
                      style={{height: "42px",marginRight: "-2px",border: "1px solid #e5e7eb",borderRadius: "5px 0 0 5px",
                       background: "transparent"
                    }}
                    value={this.state.currency_type}
                    onChange={(e:any)=> this.handleChangeCurrencyType(e)}
                    >
                  <option value="KWD" selected>KWD</option>
                      <option value="USD">USD</option>
                    </select>
                <div className="w-100">
                    <input
                     id='price21'
                     style={{
                       width: "100%",
                       height: "41px",
                       paddingLeft: "5px",
                       fontFamily: "Verdana",
                       border: "1px solid rgba(0, 0, 0, 0.23)",
                       borderRadius: "4px",
                       borderLeft: "none",
                     }}
                     type="text"
                     placeholder="Enter price per post ..."
                     name="post"
                     value={this.state.price}
                     onChange={(e:any)=> this.handleChangePrice(e)}
                   />
                  </div>
                  </div>
               </FormControl>
                <FormControl style={{ marginTop: 10, minWidth: 120 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id= "description"
                    size="small"
                    style={{fontFamily: "Verdana"}}
                    type="text"
                    name="proposal"
                    placeholder="Write your description here ..."
                    value={this.state.description}
                    onChange={(e:any)=> this.handleChangeDesc(e)}
                    rows={3}
                    multiline
                  />
                </FormControl>
                <FormControl>
                <Box>
                  <Button component="label"
                      style={{
                        border: "none",
                        background: "transparent",
                        textDecoration: "underline",
                        textTransform: "capitalize",
                        color: "blue",
                      }}
                    >
                  <LinkIcon style={{transform: "rotate(90deg)"}}/>
                      Attachement
                  <input type="file" accept="image/*" id="image-upload" hidden onChange={this.ReaderImg}/>
                  </Button>
                    {this.state.imageName && 
                      <p>Image name: {this.state.imageName}</p>
                    }
                  </Box>
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions style={{ 
                                   justifyContent: "space-around", 
                                   marginBottom: "30px" 
                                }}>
              <Button
                data-test-id="btnRemoveCase"
                style={{
                  textTransform: "capitalize",color: "#5C8BF6",border: "1px solid #5C8BF6",borderRadius: "20px",
                  width: "40%"
                }}
                onClick={
                  this.handleCounterOfferModalClose
                }
              >
                Remove
              </Button>
              <Button
                color="primary"
                 style={{
                   width: "40%",
                   background: hasCounterofferFields? "#89CFF0" : "#096FFF",
                   color: "#fff",
                   textTransform: "capitalize",
                   borderRadius: "20px",
                   }}
                disabled={hasCounterofferFields}
                onClick={this.counterOfferPropsal}
               >
                Send Proposal
              </Button>

            </DialogActions>

          </Dialog>

         </div>
        </React.Fragment>
        )
    }
}

const webPage = {
    diaHeader: {
      justifyContent: "space-between",
      borderBottom: "1px solid lightGrey",
      padding: "18px 30px",
      display: "flex",
    },

    form: {
      display: "flex",
      margin: "auto",
      padding: "10px 10px 20px 10px",
      flexDirection: "column",
    } as React.CSSProperties,
  
    dai: {
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
    }
  };


