import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  show: any;
  handleClose: () => void;
  openCustomizePlanModal: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openCustomisePlan: boolean;
  planOptionListData: any;
  token: string;
  subscriptions: any;
  data: any;
  customisePlan: any;
  totalAmount: number;
  plan: any;
  customisePlanResponse:number;
  duration:string;
  createSubscriptionId: any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  planOptionListCallId: any
  customiseSubscriptionId: string = "";
  createSubscriptionId : string = "";
  subscriptionPaymentRequestId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      openCustomisePlan: true,
      planOptionListData: [],
      token: "",
      subscriptions: [],
      data: [],
      customisePlan: [],
      totalAmount: 0,
      plan: {
        monthly: 0,
        halfYearly: 0,
        yearly: 0
      },
      customisePlanResponse:0,
      duration:"",
      createSubscriptionId: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.planOptionListCallId:
            this.setState({ planOptionListData: responseJson.data });
            break;
          case this.customiseSubscriptionId:
            this.setState({ customisePlanResponse: responseJson.data }, () => {
              this.createCustomiseSubscription(responseJson.data.id);
            });
            break;
          case this.createSubscriptionId:
            this.setState({ createSubscriptionId: responseJson.data }, () => {
              this.subscriptionCustomisePaymentRequest(responseJson.data.id);
            });
            break;
          case this.subscriptionPaymentRequestId:
            this.openCustomisePaymentPopUp(responseJson.data.attributes.redirect_url);
            break;
          default:
            break;
        }
      }
      
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.planOptionsListCall();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  planOptionsListCall = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("SignUpToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.planOptionListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_plan/plan_options`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  addCustomisePlanHandler = (data: any) => {
    this.setState({ customisePlan: [...this.state.customisePlan, data] });
    this.setState({ planOptionListData: this.state.planOptionListData?.filter((f:any) => f?.id !== data?.id) });
  }

  removePlanHandler = (data: any) => {
    this.setState({ planOptionListData: [data, ...this.state.planOptionListData] });
    this.setState({ customisePlan: this.state.customisePlan?.filter((f:any) => f?.id !== data?.id) });
  }

  onChangeHandler = (e:any) => {
    if(e.target.value === "monthly"){
      this.setState({ totalAmount:1 });
    }
    if(e.target.value === "half_yearly"){
      this.setState({ totalAmount:6 });
    }
    if(e.target.value === "yearly"){
      this.setState({ totalAmount:12 });
    }
    this.setState({duration:e.target.value})
  }

  customiseSubscription = () => {
        
    const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("SignUpToken"),
    };

    let updatedData = this.state.customisePlan.map((item: any) => ({
      plan_option_id:item.id,
      is_selected: true,
    }));

    const requestBody = {
        data: {
          plan_for: "restaurant",
          price: this.state.totalAmount ? this.state?.customisePlan?.reduce((total:number, item:any) => (total += item?.attributes?.amount), 0) * this.state.totalAmount : this.state?.customisePlan?.reduce((total:number, item:any) => (total += item?.attributes?.amount), 0),
          duration:this.state.duration || "monthly",
          plan_option_plans_attributes:updatedData
        }
    };

    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.customiseSubscriptionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.planListApiEndPoint
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
    );
    // pass request parametres
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  createCustomiseSubscription = (id:number) => {
    const headers = {
        "Content-Type": configJSON.validationApiContentType,

        token: localStorage.getItem("SignUpToken"),
    };

    const requestBody = {subscription: { plan_id: id 
    }};
    const getValidationsMsg = new Message(

        getName(MessageEnum.RestAPIRequestMessage));

    this.createSubscriptionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createSubscriptionApiEndPoint

    );
    getValidationsMsg.addData(
      
        getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );

    getValidationsMsg.addData(

        getName(MessageEnum.RestAPIRequestMethodMessage),

        configJSON.postApiMethodType
    );
    // pass request parametres
    getValidationsMsg.addData(


        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    
  }


subscriptionCustomisePaymentRequest = (id: number) => {
  const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("SignUpToken"),
  };

  const requestBody = { subscription_id: id };

  const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.subscriptionPaymentRequestId = getValidationsMsg.messageId;
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionPaymentRequestApiEndPoint
  );
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
  );
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.postApiMethodType
  );
  // pass request parametres
  getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)


  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}

openCustomisePaymentPopUp = (redirectUrl: any) => {

    const left = window.screenX + (window.outerWidth - 500) / 2;
    
    const top = window.screenY + (window.outerHeight - 400) / 2.5;
    const title = `WINDOW TITLE`;
    window.location.href = redirectUrl;
    window.resizeTo(500, 400);
    window.moveBy(left, top);
    document.title = title;
  }

  // Customizable Area End
}
