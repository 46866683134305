import React from 'react'
// Customizable Area Start
import {
    Typography,
    Grid,
    Button,
    FormControl,
    FormLabel,
    TextField,
  } from "@material-ui/core";
 
  import Box from '@material-ui/core/Grid'
  import CloseIcon from '@material-ui/icons/Close';
  import Dialog from "@material-ui/core/Dialog";
  import DialogActions from "@material-ui/core/DialogActions";
  import DialogContent from "@material-ui/core/DialogContent";
  import DialogTitle from "@material-ui/core/DialogTitle";
  import SearchIcon from '@material-ui/icons/Search';
  import InputAdornment from '@material-ui/core/InputAdornment'; 
  import IconButton from "@material-ui/core/IconButton";
  import LinkIcon from '@material-ui/icons/Link';
  import {user1,user2,user3,user4} from './assets'
import ShortListedController from './ShortListedController.web';
import "./TableLayout.css"
  // Customizable Area End

// Customizable Area Start
const styles = {
    indicator: {
      backgroundColor: '#BEBEBE'
    }
  };
// Customizable Area End

// Customizable Area Start
export default class ShortListed extends ShortListedController {
  render() {
   const {price,posts,description,imageFile , flag} = this.state 
   const hasEmptySentProposal = !price || !posts || !description || !imageFile || flag
    return (
      <div>
         <Box className='influencers-search'>
            <Grid xs={12} lg={6} md={6}>
              <TextField style={{ width: '98%', minWidth: '120px' }}
                size="small"
                variant="outlined"
                type="text"
                placeholder="Search by name..."
                InputProps={{ startAdornment: (
                    <InputAdornment position="start"> <SearchIcon style={{ color: "gray" }} /> </InputAdornment>
                  ),
                }}
                data-test-id="text"
                value={this.state.searchShortlisted}
                onChange={(e: any) => {this.HandleShortlistedSearch(e)}}
                // onKeyPress={this.searchShortlistInfluencersCall}
              />
            </Grid>
          </Box>
        <Grid container spacing={2} style={{marginBottom:"20px", marginTop: "50px"}}>
          {this.state.shortlistedInfluencersData?.data?.length > 0 ? this.state.shortlistedInfluencersData?.data?.map((item: any, i: number) => {
            return (
              <Grid item xs={12} sm={6} lg={3}  key={i}>
                <Box style={{
                   backgroundColor: "#fff", textAlign:"center", display: "grid",gridGap: "10px", borderRadius: "10px",
                   boxShadow: "rgba(1, 1, 1, 0.2) 0px 5px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 2px",
                   padding: "8px",
                  }}
                >
              <Box style={{display:"flex" ,
                 justifyContent:"end"}}
                >
              </Box>
                  <Box
                    style={{
                      height: "75px",
                      width: "75px",
                      margin: "auto",
                      borderRadius: "50%",
                    }}
                  >
                    <img src={item.attributes.profile_picture?.url}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>
               <Typography 
                style={{ fontSize: "16px", fontWeight: "bold",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',      
               }}
                >
                    {item.attributes.influencer_shortlists.user_name !=null ? item.attributes.influencer_shortlists.user_name : "unknown"}
                  </Typography>
                  {item.attributes.influencer_shortlists.user_handle? (
                  <Typography style={{ 
                    fontSize: "14px", color: "grey",whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',
                  }}>
                     @{item.attributes.influencer_shortlists.user_handle}
                  </Typography>
                  ) : (
                    <Typography style={{fontSize: "14px", color: "grey",}}>
                      NA
                    </Typography>
                  )}
                  <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                            <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "16px" }}>
                            {item.attributes.influencer_shortlists.posts_count}
                            </Typography>

                            <Typography variant="subtitle2" style={{ color: "grey", fontSize: "10px" }}>Posts</Typography>
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "16px" }}>
                            {item.attributes.influencer_shortlists.followers_count}
                            </Typography>

                            <Typography variant="subtitle2" style={{ color: "grey", fontSize: "10px" }}>Followers</Typography>
                          </Grid>

                          <Grid item xs={6} sm={4}>
                            <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "16px" }}>
                            {item.attributes.influencer_shortlists.followings_count}
                            </Typography>

                            <Typography variant="subtitle2" style={{ color: "grey", fontSize: "10px" }}>Following</Typography>
                            </Grid>
                        </Grid>

                   <Button
                    style={{
                      textTransform: "capitalize",
                      background: "#5C8BF6",
                      color: "#fff",
                      borderRadius: "20px",
                    }}
                    data-test-id = "btnAddList"
                    onClick={() => this.handleOpenProposalModal(item.id)}
                  >
                    Send proposal
                  </Button>
                </Box>
              </Grid>
            );
          }) : <p className="noData" style={{
            width: "305px",
          }}>Influencer Result is not found</p>}
        </Grid>
    <React.Fragment>

          {/* ts-ignore */}
          <Dialog
            fullWidth
            maxWidth="sm"
            open={this.state.propopasModalOpen}
            onClose={this.handleProposalModalClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle id="max-width-dialog-title" style={webStyle.diaHeader}>
              <Typography style={{fontWeight: "bold", fontSize: "20px"}}>Proposal</Typography>

              <IconButton
                aria-label="close"
                data-test-id="btnCloseIcon"
                style={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  color: "blue"
                }}
                onClick={this.handleProposalModalClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto"
                }}
                noValidate
              >
                <FormControl>
                  <FormLabel>
                    Post per month
                  </FormLabel>
                  <TextField
                     id='posts'
                     variant="outlined"
                     size="small"
                     style={{
                       fontFamily: "Verdana",
                       marginTop: "10px",
                       marginBottom: "10px"
                     }}
                     placeholder="Enter posts per month ..."
                     type="text"
                     name="post"
                     fullWidth
                     value={this.state.posts}
                     onChange={(e:any)=>this.HandleChangePosts(e)}
                  />
                </FormControl>
               
               <FormControl>
                <FormLabel>
                  Price per post
                </FormLabel>
                  <div style={webStyle.dai}>
                    <select name="currency_type"
                     id='select_currency'
                     style={{
                       height: "42px",
                       marginRight: "-2px",
                       border: "1px solid #e5e7eb",
                       borderRadius: "5px 0 0 5px",
                       background: "transparent"
                    }}
                    value={this.state.currency_type}
                    onChange={(e:any)=>this.HandleChangeCurrency(e)}
                    >
                      <option value="KWD" selected>KWD</option>
                      <option value="USD">USD</option>
                    </select>
                    <div className="w-100">
                  <input
                    id = "price21"
                     style={{
                       fontFamily: "Verdana",
                       borderLeft: "none",
                       height: "41px",
                       width: "100%",
                       border: "1px solid rgba(0, 0, 0, 0.23)",
                       borderRadius: "4px",
                       paddingLeft: "5px"
                     }}
                     placeholder="Enter price per post ..."
                     type="text"
                     name="post"
                     value={this.state.price}
                     onChange={(e:any)=>this.HandleChangePrice(e)}
                  />
                    </div>
                  </div>
               </FormControl>

                <FormControl style={{ marginTop: 10, minWidth: 120 }}>
                  <TextField
                    id= "description"
                    variant="outlined"
                    size="small"
                    style={{
                      fontFamily: "Verdana"
                    }}
                    placeholder="Write your description here ..."
                    type="text"
                    name="proposal"
                    value={this.state.description}
                    onChange={(e:any)=>    
                      this.setState({ description: e.target.value })
                  }
                    multiline
                    rows={3}
                    fullWidth
                  />
                </FormControl>
                <FormControl>
                  <Box>
                    <Button
                      component="label"
                      style={{
                        background: "transparent",
                        border: "none",
                        color: "blue",
                        textDecoration: "underline",
                        textTransform: "capitalize"
                      }}
                    >
                      {/* ts-ignore   */}
                      <LinkIcon style={{transform: "rotate(90deg)"}}/>
                      Attachment
                      <input
                        type="file"
                        accept="image/*"
                        id="image-upload"
                        hidden
                        onChange={this.ReaderImg}
                      />
                    </Button>
                    {this.state.imageName && <p>Image name: {this.state.imageName}</p>}
                  </Box>
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-around", marginBottom: "30px" }}>
              <Button
                data-test-id="btnRemoveCase"
                style={{
                  textTransform: "capitalize",
                  color: "#5C8BF6",
                  border: "1px solid #5C8BF6",
                  borderRadius: "20px",
                  width: "40%"
                }}
                onClick={this.handleProposalModalClose}
              >
                Remove
              </Button>
              <Button
                color="primary"
                style={{
                  textTransform: "capitalize",
                  background: hasEmptySentProposal? "#89CFF0" : "#096FFF",
                  color: "#fff",
                  borderRadius: "20px",
                  width: "40%"
                }}
                onClick={this.createPropsal}
                disabled={hasEmptySentProposal}
              >
                Send Proposal
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </div>
    )
  }
}
// Customizable Area End


// Customizable Area Start

export const suggestedInfluencersData = [
    {
        img:user1,
        name:"Samantha Trope",
        id:"@influcer_id",
    },
    {
        img:user2,
        name:"Joana",
        id:"@influcer_id",
    },
    {
        img:user3,
        name:"Sam Wikins",
        id:"@influcer_id",
    },
    {
        img:user4,
        name:"Folna Wikins",
        id:"@influcer_id",
    },
    {
        img:user1,
        name:"Samantha Trope",
        id:"@influcer_id", 
    },
    {
        img:user2,
        name:"Joana",
        id:"@influcer_id",
    },
   
]

const webStyle = {
    diaHeader: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid lightGrey",
    },
    dai: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      marginBottom: "10px"
    }
}
// Customizable Area End
