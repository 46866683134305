import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OtpInput from 'react-otp-input';
// Customizable Area End


// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "319px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import SignUpOTPVerifyControllerWeb, {
    Props
} from './SignUpOTPVerifyControllerWeb';

export default class SignUpOTPVerify extends SignUpOTPVerifyControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyle.root}>

                    {/* @ts-ignore */}
                    <Paper style={webStyle.formdiv}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                            <Grid item style={webStyle.welcomeFormDiv} xs={12}>
                                <Grid container spacing={1}>

                                    <Grid item>
                                        <IconButton data-test-id="goBackBtn" onClick={this.handleGoBack} style={webStyle.backbutton}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography gutterBottom variant="h1">
                                            Confirm Your
                                            Email Address
                                        </Typography>
                                        <Typography variant="body1">
                                            Enter 4-digit OTP we’ve sent to your email address
                                        </Typography>
                                        <Typography gutterBottom style={{ color: "#4B5563" }} variant="body2">
                                            <span style={{wordBreak: "break-all"}}>{localStorage.getItem("SignUpEmail")}</span>
                                            <span
                                                onClick={this.handleGoBack}
                                                data-test-id="emailEditBtn"
                                                style={webStyle.editLink}
                                            >
                                                edit

                                            </span>
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <OtpInput
                                            data-test-id="otpInput"
                                            value={this.state.otpValue}
                                            onChange={this.handleChangeOTP}
                                            numInputs={4}
                                            inputStyle={webStyle.otpInput}
                                            containerStyle={webStyle.otpContainer}
                                            isInputNum={true}
                                        />
                                        {/* @ts-ignore */}
                                        <div style={webStyle.timerDiv as React.CSSProperties}>
                                            OTP TimeOut: { this.state.timer > 0 ? <>{Math.floor(this.state.timer / 60)}:{this.state.timer % 60 < 10 ? '0' : ''}{this.state.timer % 60}</> : "00:00"}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                        <StyledButton
                                            onClick={() => this.doOTPVerify()}
                                            data-test-id="otpSubmit"
                                            variant="contained"
                                            disabled={this.state.disabledButton}>
                                            Verify
                                        </StyledButton>
                                        {/* @ts-ignore */}
                                        <Typography style={webStyle.assistSection as React.CSSProperties} variant="body2">
                                            Didn’t recieve the OTP? <span data-test-id="sendAgainOtpBtn" onClick={this.sendOTPAgain} style={webStyle.createAccountLink}>Send again</span>
                                        </Typography>
                                    </Grid>

                                  </Grid>

                               </Grid>

                          </Grid>

                      </Paper>
                </div>

            </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const webStyle = {
    formdiv: {
        maxWidth: "488px",
        minHeight: "420px",
        margin: "0 auto",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        padding: "30px 0 36px 0px",
        borderRadius: "40px",
        position: "relative"
    },
    otpInput: {
        margin: '0 5px',
        border: "0",
        padding: "20px",
        background: "rgb(241 241 241)",
        minWidth: "70px",
        minHeight: "64px",
        fontSize: "16px",
        borderRadius: "8px",
    },
    welcomeFormDiv: {
        width: "319px",
        height: "auto",
    },

    otpContainer: {
        justifyContent: "space-between",
    },
    backbutton: {
        border: "2px solid #F3F4F6",
    },
   
    root: {
        padding: "20px 0",
        overflowX: "hidden"
    },
    editLink: {
        color: "#FF91D5",
        textDecoration: "none",
        cursor: "pointer",
        marginLeft: "8px",
        borderBottom: "1px solid #FF91D5",
    },
    createAccountLink: {
        textDecoration: "none",
        color: "#096FFF",
        borderBottom: "1px solid #096FFF",
        cursor: "pointer"
    },
  
    
    assistSection: {
        marginTop: "22px",
        textAlign: "center",
    },
    timerDiv: {
        marginTop: "20px",
        textAlign: "center"
    },
}

const theme = createTheme({
    palette: {
    },
    typography: {
        "fontFamily": `inter, sans-serif`,

        h1: {
            fontWeight: 500,
            fontSize: "35px",
            lineHeight: "43px"
          },

        body1: 
        {
            fontWeight: 400,
            lineHeight: "24px",
            color: "#6B7280",
            fontSize: "16px",
        },

        body2: 
        {
            fontSize: "16px",
            lineHeight: "24px",
            color: "#6B7280",
            fontWeight: 500,
        }
    }
});
// Customizable Area End


