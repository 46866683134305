import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  propopasModalOpen: boolean;
  suggestedInfluencersData: any;
  suggestedSearch: any;
  currentPage: any;
  // timeOut: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class SuggestedInfluencersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  suggestedInfluencersCallId: string = "";
  createShortListcallId: string = "";
  searchSuggestedCallId: string = "";
  timeOut : any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      propopasModalOpen: false,
      suggestedInfluencersData: {data:[]},
      suggestedSearch: "",
      currentPage: 1,
      // timeOut : null
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getSuggestedInfluencersCall()
    window.addEventListener('scroll', this.handleScroll)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleSuggestedInfluencersCall(from, message)
    this.HandleCreateShortListcall(from, message)
    this.HandleSearchSuggestedCall(from, message)
    // Customizable Area End
  }

  // Customizable Area Start

  handleScroll = () => {
    const { clientHeight, scrollHeight } = document.documentElement;
    const scrollElement = document.scrollingElement || document.documentElement;
    const scroll = scrollElement.scrollTop;

    if (scroll + clientHeight == scrollHeight) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () => this.getSuggestedInfluencersCall());
    }
  }

  HandleSuggestedSearch = (event: any) => {
    const searchValue = event.target.value;
    clearTimeout(this.timeOut)
    this.setState({ suggestedSearch: searchValue, suggestedInfluencersData: {data:[]} }, () => {
      if (searchValue === "" ) {
        this.setState({ currentPage: 1,suggestedInfluencersData: {data:[]} }, () => this.getSuggestedInfluencersCall());
      } else {
        this.timeOut = setTimeout(()=>{
          this.searchSuggestedInfluencersCall()
        }, 1000)
        // this.searchSuggestedInfluencersCall()
      }
    });
  }

  getSuggestedInfluencersCall = () => {
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.suggestedInfluencersCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/influencers?restaurant_location_id=${RestaurantId}&page=${this.state.currentPage}&per_page=11`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createShortListcall = (shortlistId: any) => {
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
      "token": localStorage.getItem("authToken")
    };

    let formdata = new FormData();
    formdata.append("data[restaurant_location_id]", RestaurantId);
    formdata.append("data[account_id]", shortlistId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createShortListcallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/influencers`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchSuggestedInfluencersCall = () => {
    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchSuggestedCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/search_suggested?search=${this.state.suggestedSearch}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSuggestedInfluencersCall = async (from: string, message: Message) => {
    if (this.suggestedInfluencersCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!apiResponse || apiResponse.errors) {
        ToastMessage('error', apiResponse.errors || "Something went wrong")
      } else {
        if (apiResponse?.data?.length == 0) {
          ToastMessage("error", "No suggested influencers availabe")
        } else {
          this.setState(prevState => ({
              ...prevState, 
              suggestedInfluencersData: {
                ...prevState.suggestedInfluencersData, 
                data: [...prevState.suggestedInfluencersData.data, ...apiResponse.data.data],
              },
            }));
        }
      }
    }
  }

  HandleCreateShortListcall = async (from: string, message: Message) => {
    if (this.createShortListcallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage("error", apiResponse.errors.influencer[0])
      } else {
        ToastMessage("success", "Successfully added to shortlist")
        this.setState({suggestedInfluencersData :  {data:[]}})
        this.getSuggestedInfluencersCall()
      }
    }
  }

  HandleSearchSuggestedCall = async (from: string, message: Message) => {
    if (this.searchSuggestedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!apiResponse?.errors) {
        this.setState({ suggestedInfluencersData: apiResponse.data })
      }
    }
  }

  // Customizable Area End

}