import React from "react";

// Customizable Area Start
import {
    Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PostDetails from "./PostDetails.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: '#9CA3AF' },
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h2: {
            fontSize: "24px",
            fontWeight: 400
        },
        body1: {
            fontSize: "16px",
            fontWeight: 400
        },
        h1: {
            fontSize: "40px",
            fontWeight: 500
        },
        h6: {
            fontSize: "16px",
            fontWeight: 400
        },
        caption: {
            fontSize: "12px",
            fontWeight: 400
        }
    },
});
// Customizable Area End

import PostDetailsController, {
    Props
} from "./PostDetailsController";

export default class PostDetailsPage extends PostDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Typography variant="h2" style={{fontWeight:600,marginTop:'5px'}}>Posts</Typography>
                <div style={webStyle.mainWrapper}>
                    <PostDetails enableFilterSearch={true} navigation={"PostDetails"} id={"PostDetails"} />
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapper: {
        marginTop: "30px",
        padding: "0px 24px",
    }
};
// Customizable Area End
