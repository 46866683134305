import React from "react";

// Customizable Area Start
import { Button, Typography, Grid, Paper } from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import { logo } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {},
  typography: {
    fontFamily: `inter, sans-serif`,
    h1: {
      fontSize: "35px",
      fontWeight: 500,
      lineHeight: "43px"
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#6B7280"
    },
    body2: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#6B7280"
    }
  }
});

export const StyledButton: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "319px",
      textTransform: "none",
      padding: "20px",
      fontSize: "16px",
      backgroundColor: "#096FFF",
      color: "white",
      "&:hover": {
        opacity: "0.7",
        backgroundColor: "#096FFF"
      }
    }
  })
)((props: any) => <Button {...props} />);
// Customizable Area End

import EmailLoginControllerWeb, {
  Props,
  configJSON
} from "./EmailLoginControllerWeb";

export default class EmailAccountLoginBlock extends EmailLoginControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  GenInput = (
    formikFunction: any,
    inputType: string,
    inputName: string,
    inputPlaceholder: string,
    testID: string,
    defaultInputValue?: string | null
  ) => {
    return (
      <div>
        <input
          autoComplete="false"
          data-test-id={testID}
          onChange={formikFunction.handleChange}
          onBlur={formikFunction.handleBlur}
          type={inputType}
          name={inputName}
          defaultValue={defaultInputValue || ""}
          style={webStyle.formInput}
          placeholder={inputPlaceholder}
        />
        {formikFunction.touched[inputName] && formikFunction.errors[inputName] && (
          <FormHelperText error={true} style={webStyle.errorMessage}>
            {" "}
            {formikFunction.errors[inputName]}
          </FormHelperText>
        )}
      </div>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <div style={webStyle.root}>
          {/* @ts-ignore */}
          <Paper style={webStyle.formdiv}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item style={webStyle.welcomeFormDiv} xs={12}>
                <Grid container spacing={1}>
                  <Grid item style={webStyle.logoMargin}>
                    <img src={logo} alt="logo" width="100%" height="auto" />
                  </Grid>

                  <Grid item>
                    <Typography variant="h1">
                      Oh hello,
                      <br />
                      Welcome back!
                    </Typography>
                    
                  </Grid>

                  <Grid item>
                    <Typography variant="body1" style={webStyle.typoHeight}>
                      {configJSON.instructionforlogin}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Formik
                      initialValues={{
                        email: localStorage.getItem("userEmail")
                          ? localStorage.getItem("userEmail")
                          : ""
                      }}
                      validateOnBlur={false}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .required("Please enter an Email")
                          .email("Please Enter Valid Email")
                      })}
                      onSubmit={dataObject => {
                        this.handleEmailSubmit(dataObject.email);
                      }}
                      render={({
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                      }) => {
                        return (
                          // @ts-ignore
                          <form
                            onSubmit={handleSubmit}
                            // @ts-ignore
                            style={webStyle.formTest}
                          >
                            {this.GenInput(
                              { handleChange, handleBlur, touched, errors },
                              "text",
                              "email",
                              "Email Address",
                              "emailInput",
                              localStorage.getItem("userEmail")
                            )}
                            {/* @ts-ignore */}
                            <StyledButton
                              data-test-id="formSubmitBtn"
                              type="submit"
                              variant="contained"
                            >
                              Next
                            </StyledButton>
                          </form>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Typography>
                      New to Fudu?{" "}
                      <Link
                        to="/SignUpEmailVerify"
                        style={webStyle.createAccountLink}
                      >
                        Create your account here.
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  welcomeFormDiv: {
    height: "auto",
    width: "319px"
  },
  formdiv: {
    maxWidth: "488px",
    margin: "0 auto",
    padding: "30px 0 64px 0px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 6px 14px 3px",
    borderRadius: "40px",
    minHeight: "420px",
    position: "relative"
  },
  formInput: {
    background: "#F9FAFB",
    border: "none",
    height: "64px",
    width: "319px",
    padding: "20px",
    fontSize: "16px",
    borderRadius: "8px"
  },
  errorMessage: {
    paddingLeft: "10px",
    fontSize: "15px"
  },
  formTest: {
    display: "flex",
    flexDirection: "Column",
    gap: "40px",
    margin: "0px"
  },
  root: {
    padding: "50px 0",
    overflowX: "hidden"
  },
  logoMargin: {
    marginBottom: "57px",
    marginTop: "10px"
  },
  createAccountLink: {
    textDecoration: "none",
    color: "#096FFF",
    borderBottom: "1px solid #096FFF",
    cursor: "pointer"
  },
  typoHeight: {
    minHeight: '48px',
  },
};
// Customizable Area End
