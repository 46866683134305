import React from "react";

import {
  Grid,
  // Customizable Area Start
  Typography,
  Switch,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: 'green'
    }
  },
  checked: {}
})(({ checked, ...props }: any) => <Checkbox checked={checked} color="default"  {...props} />);

const BlueRadio = withStyles({
  root: {
    "&$checked": {
      color: "rgba(9, 111, 255, 1)"
    }
  },
  checked: {}

})(({ checked, ...props }: any) => <Radio color="default" checked={checked} {...props} />);

const IOSSwitch = withStyles((theme) => ({
  root: {
    height: 26,
    padding: 0,
    width: 42,
    margin: theme.spacing(1)
  },

  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        border: "none",
        backgroundColor: "#52d869",
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    }
  },

  thumb: {
    height: 24,
    width: 24,
  },
  track: {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: "#9CA3AF",
    transition: theme.transitions.create(["background-color", "border"])
  },

  checked: {},
  focusVisible: {}

}))(({ classes, ...props }: any) => {
  return (

    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

// Customizable Area End

import ReservationVenueController, {
  Props,
} from "./ReservationVenueController.web";

export default class ReservationVenue extends ReservationVenueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedOptions, switchButton, selectedDate, reservationCheckbox } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid style={{ height: 'auto', margin: "10px" }}>
          <Grid item style={resrvationstyle.grid} xs={12} sm={12} lg={12}>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "18px",
                marginBottom: "15px"
              }}
            >
              Reservation Booking
            </Typography>
            <Typography style={{ fontSize: "18px", fontWeight: 500 }}>
              Guest contact information
            </Typography>
            <Typography
              style={{
                color: "rgba(156, 163, 175, 1)",
                fontSize: "14px",
                marginTop: "10px",
                marginBottom: "10px"
              }}
            >
              When booking reservation internally require certain fields to be filled
            </Typography>
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
              <div style={resrvationstyle.checkboxlable}>
                <GreenCheckbox name="Phone" checked={reservationCheckbox.Phone} onChange={this.handlereservationCheckbox} />
                <Typography style={{ fontSize: "18px", fontWeight: 500 }}>
                  Phone
                </Typography>

              </div >
              <div style={resrvationstyle.checkboxlable}>

                <GreenCheckbox name="Email" checked={reservationCheckbox.Email} onChange={this.handlereservationCheckbox} />
                <Typography style={{ fontSize: "18px", fontWeight: 500 }}>   Email     </Typography>
              </div>
              <div style={resrvationstyle.checkboxlable}>

                <GreenCheckbox name="EitherPhoneOrEmail" checked={reservationCheckbox.EitherPhoneOrEmail} onChange={this.handlereservationCheckbox} />
                <Typography style={{ fontSize: "18px", fontWeight: 500 }}> Either phone or email
                </Typography>

              </div>
              <div style={resrvationstyle.checkboxlable}>

                <GreenCheckbox name="FullName" checked={reservationCheckbox.FullName} onChange={this.handlereservationCheckbox} />
                <Typography style={{ fontSize: "18px", fontWeight: 500 }}>
                  Full name
                </Typography>
              </div>
            </div>
            <div>
              <Typography style={{ fontSize: "18px", fontWeight: 500 }}>
                Additional configurations
              </Typography>
            </div>
            <div>
              <GreenCheckbox name="AutoAssignTable" checked={reservationCheckbox.AutoAssignTable} onChange={this.handlereservationCheckbox} />
              <label style={{ fontSize: "16px", fontWeight: 400 }}>
                Auto-assign tables
              </label>
              <Typography style={resrvationstyle.autoassign}>
                Enable auto-assign reservation table option, allowing the system
                to optimize table assignments
              </Typography>
              <GreenCheckbox name="multiReservation" checked={reservationCheckbox.multiReservation} onChange={this.handlereservationCheckbox} />
              <label style={{ fontSize: "16px", fontWeight: 400 }}>
                Multiple reservation for the same guest
              </label>
              <Typography style={resrvationstyle.autoassign}>
                Allow a guest to have multiple reservation booked on the same
                shift
              </Typography>
              <GreenCheckbox name="problemReservation" checked={reservationCheckbox.problemReservation} onChange={this.handlereservationCheckbox} />
              <label style={{ fontSize: "16px", fontWeight: 400 }}>
                Show problem reservations
              </label>
              <Typography style={resrvationstyle.autoassign}>
                Indicates major and minor problems with reservation on the
                reservation list
              </Typography>
            </div>
          </Grid>
          <Grid style={resrvationstyle.grid} xs={12} sm={12} lg={12}>
            <Typography style={resrvationstyle.configinternal as any}>
              Internal Configurations
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: 500, marginTop: '15px' }}>
              Default seating area for internal search
            </Typography>

            <Typography
              style={{ fontSize: "14px", color: "rgba(156, 163, 175, 1)" }}
            >
              When searching for reservation availability internally, choose
              which seating area should be selected by default
            </Typography>
            <div>
              <RadioGroup
                aria-labelledby="radio-buttons"
                name="radio-buttons-position" style={{ marginBottom: "15px", marginTop: "10px", marginLeft: "10px" }}
                value={selectedOptions.radioselect} onChange={this.handleRadioChange} data-test-id="radioChange">
                {["All", "Main", "Lounge", "VIP Room", "Bar", "Terrace"].map((value, i) => (
                  <FormControlLabel value={value} control={<BlueRadio checked={selectedOptions.radioselect.includes(value)} />} label={value} key={i} />
                ))}
              </RadioGroup>
            </div>
            <div style={{ display: 'flex' }}>
              <GreenCheckbox name="coverdBookBySeating" checked={reservationCheckbox.coverdBookBySeating} onChange={this.handlereservationCheckbox} data-test-id="checkboxChecked" />
              <label style={{ display: 'flex', alignItems: 'center' }}>Show covers booked by seating area and time</label>
            </div>
            <Typography style={resrvationstyle.autoassign}>
              When searching for availability in specific seating areas, also
              show covers booked at each time slot for that seating area
            </Typography>
          </Grid>
          <Grid style={resrvationstyle.grid} xs={12} sm={12} lg={12}>
            <Typography style={resrvationstyle.configinternal as any}>
              Waitlist
            </Typography>
            <Typography style={{ fontSize: "16px" }}>
              Waitlist wait time options
            </Typography>
            <Typography
              style={{ fontSize: "14px", color: "rgba(156, 163, 175, 1)" }}
            >
              Define the wait time values for waitlist entries that are
              booked by you and your team internally
            </Typography>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography style={{ marginBottom: '20px' }}>Maximum wait time:</Typography>
                <Select
                  fullWidth
                  name="MaximumWaitTime"
                  variant="outlined"
                  value={selectedOptions.MaximumWaitTime}
                  data-test-id="select"
                  onChange={this.handleselectChange}
                  style={resrvationstyle.selectmenu}
                  MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },

                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,

                    disableScrollLock: true,
                  }}>
                  {[{ key: "1", value: "1 hours" }, { key: "2", value: "2 hours" }, { key: "3", value: "3 hours" },].map((obj, index) => (
                    <MenuItem value={obj.key} key={index}>
                      {obj.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography style={{ marginBottom: '20px' }}>With interval:</Typography>
                <Select

                  fullWidth
                  variant="outlined"
                  data-test-id="select"
                  name="WithInterval"
                  value={selectedOptions.WithInterval}
                  style={resrvationstyle.selectmenu}
                  onChange={this.handleselectChange}
                  MenuProps={{

                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                  }}>
                  {[{ label: "5", value: "5 Minute" },
                  { label: "10", value: "10 Minute" },
                  { label: "15", value: "15 Minute" }].map((obj, index) => (
                    <MenuItem value={obj.label} key={index}>
                      {obj.value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Typography style={{ fontSize: "16px", marginTop: '20px' }}>
              Waitlist auto-remove timing
            </Typography>
            <Typography
              style={{
                color: "rgba(156, 163, 175, 1)",
                marginBottom: "15px"
              }}
            >
              Automatically remove waitlist entries that have been marked as
              cancelled by the guest from the standard app view, after a
              designated period
            </Typography>
            <Grid xs={12} sm={6} lg={4}>
              <Select
                fullWidth
                variant="outlined"
                data-test-id="select"
                name='Waitlist'
                style={{ ...resrvationstyle.selectmenu, width: '96%' }}
                value={selectedOptions.Waitlist}
                onChange={this.handleselectChange}
                MenuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                }}>
                {[{ key: "5", value: "5 minutes after" },
                { key: "10", value: "10 minutes after" },
                { key: "15", value: "15 minutes after" }].map((obj, i) => {
                  return (
                    <MenuItem value={obj.key} key={i}> {obj.value} </MenuItem>
                  )
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid style={resrvationstyle.grid} xs={12} sm={12} lg={12}>
            <Typography style={resrvationstyle.configinternal}>
              Reservation Confirmation
            </Typography>
            <div>
              <GreenCheckbox name="reservConfirm" checked={reservationCheckbox.reservConfirm} onChange={this.handlereservationCheckbox} />
              <label>Auto-confirm reservations</label>
            </div>
            <Typography style={{ ...resrvationstyle.autoassign, marginLeft: '30px' }}>
              Reservation booked will be automatically set to confirmed status
              if booked on or after
            </Typography>
          </Grid>
          <Grid style={resrvationstyle.grid} xs={12} sm={12} lg={12}>
            <Typography style={resrvationstyle.configinternal}>
              Reservation Settings
            </Typography>
            <div>
              <GreenCheckbox name="isChecked" checked={this.state.isChecked} onChange={this.handlecheckboxchange} data-test-id="checkbox" />
              <label>Disable online reservation from</label>
            </div>
            <Grid container spacing={2} style={{ marginTop: '5px' }}>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography>From</Typography>
                <input
                  style={{
                    ...resrvationstyle.inputbox, color: this.state.isChecked === false ? '#999' : 'initial',
                    cursor: 'pointer'
                  }}
                  data-test-id="date"
                  type="date"
                  name="selectFormDate"
                  disabled={this.state.isChecked === false}
                  value={selectedDate.selectFormDate}
                  onChange={this.handleDateChange}
                  placeholder="Your Placeholder Text"
                  min={new Date().toISOString().split('T')[0]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Typography>To</Typography>
                <input
                  style={{
                    ...resrvationstyle.inputbox, color: this.state.isChecked === false ? '#999' : 'initial',
                    cursor: 'pointer'
                  }}
                  data-test-id="date"
                  type="date"
                  name="selectToDate"
                  disabled={this.state.isChecked === false}
                  value={selectedDate.selectToDate}
                  onChange={this.handleDateChange}
                  placeholder="Your Placeholder Text"
                  min={new Date().toISOString().split('T')[0]}
                />
              </Grid>
            </Grid>

            <Grid
              xs={12} sm={6} lg={6}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <label style={{ fontSize: "15px", whiteSpace: 'nowrap' }}>
                Enable waiting list for this duration
              </label>
              <IOSSwitch data-test-id="switch" name="waitinglistSwitch" checked={switchButton.waitinglistSwitch}
                onChange={this.changeSwitchBtn} />
            </Grid>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "13px"
              }}
              lg={8}
              sm={8}
            >
              <Grid lg={5} xs={12} sm={12}>
                <label style={{ fontSize: "15px", whiteSpace: 'nowrap' }}>
                  Maximum distance to join waiting list
                </label>
              </Grid>
              <Grid lg={5} xs={12} sm={12}>

                <input
                  style={resrvationstyle.inputbox}
                  type="text"
                  placeholder="2 KMs"
                  name="waitinglistDistance"
                  data-test-id="waitingDistance"
                  value={selectedDate.waitinglistDistance}
                  onChange={this.handleDateChange}
                  onKeyPress={this.onlyEnterNumber}
                />


              </Grid>
            </Grid>
            <Grid
              xs={12} sm={6} lg={6}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <label style={{ fontSize: "15px", whiteSpace: 'nowrap', fontWeight: 500 }}>
                Table layout visible to customer
              </label>
              <IOSSwitch data-test-id="switch" name="visibleTableLayout" checked={switchButton.visibleTableLayout} onChange={this.changeSwitchBtn} />
            </Grid>
            <div style={{marginLeft:'-10px'}}>
              <GreenCheckbox name="disableMenu" checked={this.state.disableMenu} onChange={this.handlerChangeDisbleMEnu} data-test-id="disblemenu" />
              <label>Disable menu for customers</label>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const resrvationstyle = {
  grid: {
    border: "1px solid rgba(235, 235, 235, 1)",
    borderRadius: '4px',
    marginTop: "35px",
    padding: "20px",
  },
  configheading: {
    marginTop: "10px",
    fontSize: "16px",
    marginBottom: "10px",
    fontWeight: 500
  },
  autoassign: {
    color: "rgba(156, 163, 175, 1)",
    fontSize: "14px",
    marginLeft: "42px"
  },
  configinternal: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "20px"
  },
  selectmenu: {
    width: "100%",
    height: "40px",
    margin: "2px",
    borderRadius: "8px",
    padding: "3px",
    fontSize: "14px",
    background: '#F3F3F3',
    minWidth: '140px'
  },
  checkboxlable: {
    display: 'flex',
    alignItems: 'center'
  },
  inputbox: {
    width: "85%",
    height: "40px",
    marginBottom: "15px",
    borderRadius: "5px",
    fontSize: "14px",
    marginTop: "5px",
    minWidth: '150px',
    border: '1px solid #dfdfdf',
    color: 'black',
    maxWidth: "260px"
  }
};

// Customizable Area End

