import * as React from "react";

// Customizable Area Start
import {
  Grid,
  ListItemText,
  ListItem,
  List,
  Collapse,
  Box
} from "@material-ui/core";
import { CSSProperties } from "react";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RequestManagementController, {
  Props
} from "./RequestManagementController";
export default class HelpCenter extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <Grid container >
          <Grid item sm={12} >
            <div style={webStyle.needBlock}>
              <h2 style={webStyle.needheading as CSSProperties}>Help Centre</h2>
              <Grid style={webStyle.wrapperSubscreption}>
                <h1 style={webStyle.heading}>FAQs</h1>
                <List style={webStyle.list}>
                  {this.state.faqs.length > 0 ? (this.state.faqs.map((item: any) => (
                    <Box key={item.id} style={this.state.open[item.id] ? webStyle.openbox : webStyle.box}>
                      <ListItem style={{ cursor: 'pointer' }} onClick={() => this.handleClick(item.id)} data-test-id='show_answer'>
                        <ListItemText primary={item.question} primaryTypographyProps={{ style: { fontWeight: 500, fontFamily: 'Inter', width: '92%' } }} />
                        {this.state.open[item.id] ? <RemoveIcon /> : <AddIcon />}
                      </ListItem>
                      <Collapse in={this.state.open[item.id]} timeout="auto" unmountOnExit style={{ marginTop: '-10px' }}>
                        <List disablePadding >
                          <ListItem style={{ paddingLeft: theme.spacing(2), marginTop: '-7px' }}>
                            <ListItemText primary={item.answer} primaryTypographyProps={{ style: { fontWeight: 400, fontFamily: 'Inter', fontSize: '14px', width: '97%' } }} />
                          </ListItem>
                        </List>
                      </Collapse>
                    </Box>
                  ))) : (<p style={{textAlign:'center',marginTop:'10px'}}>NO FAQS AVAILABLE </p>)}
                </List>
              </Grid>
            </div>
          </Grid>
        </Grid>

      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px",
  },

  needBlock: {
    padding: "10px 0 30px 20px",
    width: ' 100%',
    marginRight: '71px',
    marginLeft: '-25px',
  },

  needheading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#111111",
    margin: "0",
    marginTop: '-2px'
  },
  list: {
    display: 'grid',
    gridRowGap: '20px',
    width: '100%',
    padding: '0',
  },
  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    margin: "25px 0 0",
    padding: "25px 30px",
    height: 'auto',
    minHeight: "100vh"
  },

  heading: {
    fontSize: "24px",
    marginBottom: "30px",
  },
  box: {
    border: '1px solid #EBEBEB',
    borderRadius: '5px',
  },
  openbox: {
    border: '1px solid #096FFF',
    borderRadius: '5px',
  },
};
// Customizable Area End
