Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Followers";
exports.labelBodyText = "Followers Body";
exports.searchBoxText = "Search a follower";
//custom
exports.labelTitleTextFollowing = "Following";
exports.labelTitleTextFollowers = "Followers";
exports.followersApiEndPoint = "bx_block_followers/follows/followers";
exports.followingApiEndpoint = "bx_block_followers/follows/following";
exports.unFollowApisApiEndPoint = "bx_block_followers/follows"
exports.addFollowersApiEndPoint = "bx_block_followers/follows"
exports.addFollowingApiEndPoint = "bx_block_followers/follows"
exports.getFollowersApiMethodType = "GET";
exports.getFollowingApiMethodType = "GET";
exports.deleteApiMethodType = "DELETE"
exports.postApiMethodType = "POST";
exports.userListEndPoint ='account_block/accounts'
exports.userListFollowersEndPoint ='/bx_block_followers/follows/following'
exports.userListFollowingEndPoint ='/bx_block_followers/follows/followers'
exports.followerProfileEndPoint = "/account_block/accounts/"
exports.followEndpoint = 'bx_block_followers/follows'
exports.btnExampleTitle = "CLICK ME";
exports.trendingFoodiesTitle = "Trending Foodies";
exports.Following = "Following";
exports.Followers = "Followers";
exports.followers = "followers";
exports.page_no = "page"
exports.item_per_page ="per_page"
exports.search ="search"
exports.noResultFound ="No record found."
exports.End ="End."
exports.Follow="Follow"
exports.UnFollow="UnFollow"
exports.trendingFoodiesScreen="Trending Foodies"
exports.screenNameFollowers= "Followers"
exports.screenNameFollowings="Following"
exports.followerFollowingEndPoints="?account_id="
exports.folowerListRoutes="FollowerList"
// Customizable Area End