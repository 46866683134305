import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  upComingOrderDataDashboard:any[];
  activeTab:string
  openEditModal:any
  isloading:boolean
  upcomingDataLength:any
  openManageQueueId:any
  // Customizable Area End
}
interface SS {
  id: any;
   // Customizable Area Start
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  upComingOrderIdDashboard: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      upComingOrderDataDashboard:[],
      openEditModal: null,
      activeTab: 'reservations',
      isloading: false,
      upcomingDataLength: '0',
      openManageQueueId: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleUpComingOrderDashboard()
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.upComingOrderIdDashboard) {
      if (responseJson?.data?.upcomming_reservations?.data) {
        this.setState({ upComingOrderDataDashboard: responseJson.data.upcomming_reservations.data, isloading: false, upcomingDataLength: responseJson.upcomming_orders_count });
      } else {
        this.setState({ isloading: false, upComingOrderDataDashboard: [] });
      }
    }
  // Customizable Area End
  }

  // Customizable Area Start

  handleUpComingOrderDashboard = async () => {
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,

    };
    const locationId = localStorage.getItem("locationID");
    const endPoint = `upcoming_reservation_orders?order_type=reservation&restaurant_location_id=${locationId}&page=1&per_page=10`
    const req = apiCall({
      httpBody: {},
      header: headers,
      url: endPoint,
      httpMethod: 'GET'
    })
    this.setState({ isloading: true })
    this.upComingOrderIdDashboard = req.messageId;
    runEngine.sendMessage(req.id, req);

  };

  handleTabChange = (value: any) => {
    this.setState({ activeTab: value })
  }
  handleCloseEditModal = () => {
    this.setState({ openEditModal: null })
  }

  handleOpenEditModal = (id: any) => {
    this.setState({ openEditModal: id })
  }
  handleOpenManageQueueModal = (id: any) => {
    this.setState({ openManageQueueId: id })
  }
  handleCloseManageQueueModal = () => {
    this.setState({ openManageQueueId: null })
  }
  // Customizable Area End

}