import React from "react";

import {
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#6200ee",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import AdminConsoleController, {
  Props,
} from "./AdminConsoleController";

export default class TermsAndCondition extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: 'auto' }}>
          <Grid item sm={12} style={{ height: 'auto' }}>
            <div style={webStyleTerms.needBlockStyle}>
              <Grid style={webStyleTerms.gridStyle}>
                <h1>Terms And Conditions</h1>
                <div>
                  <h3>Lorem Ipsum is not simply random text</h3>
                  <p style={{textAlign:'justify'}}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,
                    making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                    on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section
                    comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise
                    "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914
                    consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum
                    1.10.32.The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from
                    translation by H. Rackham</p>
                </div>
                <div>
                  <h3>The standard Lorem Ipsum passage, used since the 1500s</h3>
                  <p style={{textAlign:'justify'}}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                    anim id est laborum.""Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                    eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                    sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                </div>
                <div>
                  <h3>1914 translation by H. Rackham</h3>
                  <p style={{textAlign:'justify'}}>C"But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account
                    of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects,
                    dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter
                    consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is
                    pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example,
                    which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with
                    a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"</p>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyleTerms = {
  gridStyle: {
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    margin: "25px 13px 0 0",
    padding: "25px 30px",
    backgroundColor: "#FFFFFF",
  },
  needBlockStyle: {

    padding: "10px 0 30px 20px",

  },
}

// Customizable Area End

