//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    openProfileMenuUploadModal: boolean;
    allMenuListingData: any;
    addVariantsData: any;
    openEditMenuId: any;
    openEditMenuModal: boolean;
    anchorElMenuMoreBtn: any;
    deleteId: any;
    openDeleteModal: boolean;
    searchMenu: any;
    openMenuFilter: boolean;
    All: boolean;
    breakfast: boolean;
    lunch: boolean;
    dinner: boolean;
    allDay: boolean;
    veg: boolean;
    nonVeg: boolean;
    allCategoryValue: any;
    allItemValue: any;
    email: string;
    openManageModel: boolean;
    checkedBb: any;
    checkedBl: any;
    checkedBd: any;
    Bstart_hours: string;
    Lstart_hours: string;
    Dstart_hours: string;
    Bstart_mins: string;
    Lstart_mins: string;
    Dstart_mins: string;
    Bend_hours: string;
    Lend_hours: string;
    Dend_hours: string;
    Bend_mins: string;
    Lend_mins: string;
    Dend_mins: string;
    Bstart_time_type: any;
    Lstart_time_type: any;
    Dstart_time_type: any;
    Bend_time_type: any;
    Lend_time_type: any;
    Dend_time_type: any;
    errors: {
        Lstart_hours?: string ,
        Dstart_hours?: string ,
        Lstart_mins?: string ,
        Dstart_mins?: string ,
        Lend_hours?: string ,
        Dend_hours?: string ,
        Lend_mins?: string ,
        Dend_mins?: string ,
        Bstart_hours?: string ;
        Bstart_mins?: string ;
        Bend_hours?: string ;
        Bend_mins?: string ;
      };
    passwordEyeToggleForPassword: boolean;
    passwordInputTypeForPassword: boolean;
    openDescriptionModal: boolean;
    descriptionId: any;
    loader:boolean;
    menuErr:string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class MenuListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getMenusCallId: string = "";
    searchMenuCallId: string = "";
    deleteMenuCallId: string = "";
    filterMenuCallId: string = "";
    manageMenuApiCallId: string = "";
    getManageMenuTimingsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            openProfileMenuUploadModal: false,
            allMenuListingData: [],
            addVariantsData: [],
            openEditMenuId: null,
            openEditMenuModal: false,
            anchorElMenuMoreBtn: null,
            deleteId: null,
            openDeleteModal: false,
            searchMenu: "",
            openMenuFilter: false,
            All: false,
            breakfast: false,
            lunch: false,
            dinner: false,
            allDay: false,
            veg: false,
            nonVeg: false,
            allCategoryValue: "",
            allItemValue: "",
            email: '',
            openManageModel: false,
            checkedBb: false,
            checkedBl: false,
            checkedBd: false,
            Bstart_hours: '',
            Lstart_hours: '',
            Dstart_hours: '',
            Bstart_mins: '',
            Lstart_mins: '',
            Dstart_mins: '',
            Bend_hours: '',
            Lend_hours: '',
            Dend_hours: '',
            Bend_mins: '',
            Lend_mins: '',
            Dend_mins: '',

            start_time_type: 'AM',
            end_time_type: 'AM',
            errors: {},
            passwordEyeToggleForPassword: true,
            passwordInputTypeForPassword: true,
            openDescriptionModal: false,
            descriptionId: "",
            loader:false,
            Bstart_time_type: "AM",
            Lstart_time_type: "PM",
            Dstart_time_type: "PM",
            Bend_time_type: "AM",
            Lend_time_type: "PM",
            Dend_time_type: "PM",
            menuErr:""
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getMenuListingCallApi()
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.HandleMenuListingCall(from, message)
        this.HandleSearchMenuCall(from, message)
        this.HandleDeleteMenuCall(from, message)
        this.handleFilterMenuCall(from, message)
        this.handleManageMenuTimings(from, message)
        this.handleGetManageMenuTimings(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
     
    handleChange = async(e: any) => {
        const { name, value } = e.target;
        this.setState({
          [name]: value,
        } as Pick<S, keyof S>);
      };

validateForm = () => {
  const {
    Bstart_hours,
    Bstart_mins,
    Bend_hours,
    Bend_mins,
    Lstart_hours,
    Lstart_mins,
    Lend_hours,
    Lend_mins,
    Dstart_hours,
    Dstart_mins,
    Dend_hours,
    Dend_mins,
  } = this.state;
  const errors: S['errors'] = {};
 
  
  const validateHours = (field: keyof Errors, value: string): void => {
    if (parseInt(value, 10) > 12) {
      errors[field] = 'Hours cannot exceed 12';
      ToastMessage('error', 'Hours cannot exceed 12');
    }
  };

  const validateMinutes = (field: keyof Errors, value: string): void => {
     if (parseInt(value, 10) > 60) {
      errors[field] = 'Minutes cannot exceed 60';
      ToastMessage('error', 'Minutes cannot exceed 60');
    }
  };

  validateHours('Bstart_hours', Bstart_hours);
  validateMinutes('Bstart_mins', Bstart_mins);
  validateHours('Bend_hours', Bend_hours);
  validateMinutes('Bend_mins', Bend_mins);
  validateHours('Lstart_hours', Lstart_hours);
  validateMinutes('Lstart_mins', Lstart_mins);
  validateHours('Lend_hours', Lend_hours);
  validateMinutes('Lend_mins', Lend_mins);
  validateHours('Dstart_hours', Dstart_hours);
  validateMinutes('Dstart_mins', Dstart_mins);
  validateHours('Dend_hours', Dend_hours);
  validateMinutes('Dend_mins', Dend_mins);


  this.setState({ errors });

  return Object.keys(errors).length === 0;
};

    
      handleSubmit = async(e: any) => {
        e.preventDefault();
    
        // Validate the form
        if (this.validateForm()) {
          // Submit the form
          this.manageMenuTimings()
        }
      };

    handleBrSwitchChange = async (event: any) => {
        this.setState({ checkedBb: event.target.checked })
    }

    handleLuSwitchChange = async (event: any) => {
        this.setState({ checkedBl: event.target.checked })
    }

    handleDiSwitchChange = async (event: any) => {
        this.setState({ checkedBd: event.target.checked })
    }


    HandleSearch = async(event: any) => {
        const searchValue = event.target.value;
        this.setState({ searchMenu: searchValue }, () => {
            if (searchValue === "") {
                this.getMenuListingCallApi();
            }
        });
    };

hasEmptyManageFields=() => {
    const {Bstart_hours,Bstart_mins, Bend_hours,Bend_mins,Lstart_hours,Lstart_mins,Lend_hours,Lend_mins,
        Dstart_hours,Dstart_mins, Dend_hours,Dend_mins} = this.state
        const hasEmptyManageField = 
            !Bstart_hours || !Bstart_mins || !Bend_hours || !Bend_mins ||
            !Lstart_hours || !Lstart_mins || !Lend_hours || !Lend_mins ||
            !Dstart_hours || !Dstart_mins || !Dend_hours || !Dend_mins
            return hasEmptyManageField
        }

    handleMouseEnter = (dec:any)=> {
        this.setState({openDescriptionModal: true, descriptionId: dec })
    }

    handleMouseLeave =()=> {
        this.setState({openDescriptionModal: false })
    }

    handleCloseProfileMenuUploadModal = () => {
        this.setState({ openProfileMenuUploadModal: false })
        this.getMenuListingCallApi()
    }

    handleEditMenuModal = (menuId: any) => {
        this.setState({ openEditMenuModal: true, openEditMenuId: menuId , loader: true })
    }

    handleEditMenuModalClose = () => {
        this.setState({ openEditMenuModal: false , loader: false})
    }

    handleClickopenAnchor = async(event: any, delId: any) => {
        this.setState({ anchorElMenuMoreBtn: event.currentTarget, deleteId: delId })
    }

    handleOpenDeletePopup = (delId: any) => {
        this.setState({ deleteId: delId })
    }
    handleCloseMenuDelete = () => {
        this.setState({ anchorElMenuMoreBtn: null })
    }

    handleDeleteMenu = () => {
        this.setState({ openDeleteModal: true, anchorElMenuMoreBtn: null })
    }

    handleModalClose = () => {
        this.setState({ openDeleteModal: false })
    }

    handleManageModalClose = () => {
        this.setState({ openManageModel: false })
    }

    findLeastPrice = (menuId: any) => {
        const data = this.state.allMenuListingData
        const lowestPrice = data
            .find((item: any) => item.id === menuId)
            ?.attributes?.variants.reduce(
                (minPrice: any, variant: any) => (variant.price < minPrice ? variant.price : minPrice),
                Infinity
            );

        return lowestPrice

    }

    getWorkingTimeString = () => {
        let Brstarting_time = `${this.state.Bstart_hours}:${this.state.Bstart_mins}`
        let Brclosing_time = `${this.state.Bend_hours}:${this.state.Bend_mins}`
        let Lustarting_time = `${this.state.Lstart_hours}:${this.state.Lstart_mins}`
        let Luclosing_time = `${this.state.Lend_hours}:${this.state.Lend_mins}`
        let Distarting_time = `${this.state.Dstart_hours}:${this.state.Dstart_mins}`
        let Diclosing_time = `${this.state.Dend_hours}:${this.state.Dend_mins}`
        return { Brstarting_time, Brclosing_time, Lustarting_time, Luclosing_time, Distarting_time, Diclosing_time }
    }


    //API Function
    getMenuListingCallApi = () => {
        this.setState({loader:true})
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getMenusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/menus`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    searchMenuCall = () => {
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.searchMenuCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/menus?q=${this.state.searchMenu}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteMenuApiCall = () => {
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteMenuCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/menus/${this.state.deleteId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `DELETE`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    filterMenuCall = () => {
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.filterMenuCallId = requestMessage.messageId;
        const filterCategoryValue = this.state.allCategoryValue;
        const filterItemValue = this.state.allItemValue;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_menu_ordering/menus?q=&category_type=${filterCategoryValue}&item_type=${filterItemValue}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    manageMenuTimings = () => {
        const workingTime = this.getWorkingTimeString()
        const header = {
            "token": localStorage.getItem("authToken")
        };
        let formdata = new FormData();
        formdata.append("data[breakfast]", this.state.checkedBb);
        formdata.append("data[lunch]", this.state.checkedBl);
        formdata.append("data[dinner]", this.state.checkedBd);
        formdata.append("data[breakfast_start_time]", workingTime.Brstarting_time + " " + this.state.Bstart_time_type);
        formdata.append("data[breakfast_end_time]", workingTime.Brclosing_time + " " + this.state.Bend_time_type);
        formdata.append("data[lunch_start_time]", workingTime.Lustarting_time + " " + this.state.Lstart_time_type);
        formdata.append("data[lunch_end_time]", workingTime.Luclosing_time + " " + this.state.Lend_time_type);
        formdata.append("data[dinner_start_time]", workingTime.Distarting_time + " " + this.state.Dstart_time_type);
        formdata.append("data[dinner_end_time]", workingTime.Diclosing_time + " " + this.state.Dend_time_type);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.manageMenuApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/menu_meal_timings`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getManageMenuTimings = () => {
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getManageMenuTimingsCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_menu_ordering/menu_meal_timings`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTimeConversion = (str:string) => {
        const originalDate = new Date(str);
        originalDate.setMinutes(originalDate.getMinutes() + 330);
        const formattedTime = originalDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
        return formattedTime;
    }

    //API Response Function
    HandleMenuListingCall = async (from: string, message: Message) => {
        if (this.getMenusCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ allMenuListingData: [] })
                ToastMessage('error', apiResponse?.errors[0]?.message);
                this.setState({loader:false, menuErr:apiResponse?.errors[0]?.message})
            } else {
                if (apiResponse?.data?.length == 0) {
                    ToastMessage('error', "No menu found, please add a menu");
                } else {
                    this.setState({
                        allMenuListingData: apiResponse?.data,
                        loader:false
                    })
                }
            }
        }
    }

    HandleSearchMenuCall = async (from: string, message: Message) => {
        if (this.searchMenuCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse?.errors) {
                this.setState({allMenuListingData: ""})
                ToastMessage('error', apiResponse.errors[0].message)
            } else {
                this.setState({ allMenuListingData: apiResponse.data })
            }
        }
    }

    HandleDeleteMenuCall = async (from: string, message: Message) => {
        if (this.deleteMenuCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ openDeleteModal: false })
            } else {
                ToastMessage('success', "Menu Deleted Successfully")
                this.setState({ openDeleteModal: false })
                this.getMenuListingCallApi()
            }
        }
    }

    handleFilterMenuCall = async (from: string, message: Message) => {
        if (this.filterMenuCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', apiResponse.errors[0].message)
                this.setState({allMenuListingData: "", openMenuFilter: false})
            } else {
                if (apiResponse?.data?.length === 0) {
                    ToastMessage('error', "No data found")
                }
                this.setState({
                    allMenuListingData: apiResponse.data,
                    openMenuFilter: false
                })
            }
        }
    }

    handleManageMenuTimings = async (from: string, message: Message) => {
        if (this.manageMenuApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (!apiResponse.errors) {
            
                ToastMessage('success', "Meal timings have been successfully saved")
                this.setState({openManageModel: false})
            }
        }
    }

    handleGetManageMenuTimings = async (from: string, message: Message) => {
        if (this.getManageMenuTimingsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse?.data) {
                const breakfastStartTime = this.getTimeConversion(apiResponse?.data[0]?.attributes?.breakfast_start_time);
                const breakfastEndTime = this.getTimeConversion(apiResponse?.data[0]?.attributes?.breakfast_end_time);
                const lunchStartTime = this.getTimeConversion(apiResponse?.data[0]?.attributes?.lunch_start_time);
                const lunchEndTime = this.getTimeConversion(apiResponse?.data[0]?.attributes?.lunch_end_time);
                const dinnerStartTime = this.getTimeConversion(apiResponse?.data[0]?.attributes?.dinner_start_time);
                const dinnerEndTime = this.getTimeConversion(apiResponse?.data[0]?.attributes?.dinner_end_time);
                this.setState({Bstart_hours:breakfastStartTime?.slice(0,2), Bstart_mins:breakfastStartTime?.slice(3,6),Bend_hours:breakfastEndTime?.slice(0,2), Bend_mins:breakfastEndTime?.slice(3,6),Lstart_hours:lunchStartTime?.slice(0,2), Lstart_mins:lunchStartTime?.slice(3,6), Lend_hours:lunchEndTime?.slice(0,2), Lend_mins:lunchEndTime?.slice(3,6),Dstart_hours:dinnerStartTime?.slice(0,2), Dstart_mins:dinnerStartTime?.slice(3,6),Dend_hours:dinnerEndTime?.slice(0,2), Dend_mins:dinnerEndTime?.slice(3,6),checkedBb:apiResponse?.data[0]?.attributes?.breakfast,checkedBl:apiResponse?.data[0]?.attributes?.lunch,checkedBd:apiResponse?.data[0]?.attributes?.dinner})
            }else{
                ToastMessage("error", "Something went wrong")
            }
        }
    }

    // Customizable Area End

}