import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  foodPreference: any;
  Food: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  PostFoodPrefrencesCallID: string = ""
  GetFoodPrefrencesCallID: string = ""
  DeleteFoodPrefrencesCallID: string = ""
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      Food: '',
      foodPreference: [],
    };

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)  ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    
    // Customizable Area Start

    const apiRequestCallId = 
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = 
    message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) {      
      ToastMessage("error", "Something Went Wrong");
      return;
    }             
    if (apiRequestCallId === this.PostFoodPrefrencesCallID) {
      this.FoodPrefrenceRecive(responseJson)
    }else if (apiRequestCallId === this.GetFoodPrefrencesCallID) {
      this.setState({ foodPreference: responseJson?.dietary || [] })
    }else if (apiRequestCallId === this.DeleteFoodPrefrencesCallID) {
      this.GetFoodPrefrences()
    }            

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.GetFoodPrefrences();
  }

  FoodPrefrenceRecive = (responseJson: any) => {
    if (this.state.foodPreference.name === responseJson.dietary?.name) {
      ToastMessage('error', "Name has already been taken")
    }
    else {
      this.setState({ Food: '' })
      this.GetFoodPrefrences();
    }
  }

  handleFoodChange = (e: any) => {
    this.setState({
      Food: e.target.value
    });
  }

  PostFoodPrefrences = (e:any) => {
    e.preventDefault()
    if (this.state.Food === "") {
      ToastMessage("error", "Please fill this field")
    } else {
      const headerData = {
        "Content-Type": "application/json",
        "token": localStorage.getItem("authToken")
      };
      const httpBody = {
        "data": {
          "name": this.state.Food
        }
      }
      const req = apiCall({
        httpBody: httpBody,
        header: headerData,
        url: configJSON.FoodPostURL,
        httpMethod: 'POST'
      })
      this.PostFoodPrefrencesCallID = req.messageId;
      runEngine.sendMessage(req.id, req);
    }
  }

  GetFoodPrefrences = () => {
    const headerData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: configJSON.FoodGetURL,
      httpMethod: 'GET'
    })
    this.GetFoodPrefrencesCallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  DeleteFoodPrefrences = (index: any) => {
    const headerData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    const httpBody = {
      "data": {
        "name": this.state.Food
      }
    }
    const req = apiCall({
      httpBody: httpBody,
      header: headerData,
      url: `${configJSON.FoodDeleteURL}/${index}`,
      httpMethod: 'DELETE'
    })
    this.DeleteFoodPrefrencesCallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  // Customizable Area End
}
