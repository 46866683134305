import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Paper, Select
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Country } from "country-state-city";
import "./signup.css"
// Customizable Area End

// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            marginTop: "26px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);

const formFields = [
    {
         placeHolder: "Restaurant name",
         name: "restaurant_name",
        type: "text"
    },

    {
          placeHolder: "Address",
         name: "address",
        type: "text"
    },

    {
        name: "city",
        placeHolder: "City",

        type: "text"
    },

    {
        name: "state",
        
        placeHolder: "State",
        type: "text"
    },
    {
        name: "country",
        
         placeHolder: "Country",
        type: "text"
    },

    {
          placeHolder: "Restaurant email id",
         name: "restaurant_email_id",
        type: "text"
    },
    
    {
        name: "restaurant_nick_name",
    
        placeHolder: "Restaurant Location NickName",
    
        type: "text"
    },
    {
        name: "overview",
    
        placeHolder: "Restaurant Overview",
        type: "text"
    },
    
    {
        placeHolder: "Website (optional)",
        name: "website",
    
        type: "text"
    },
    
    {
        placeHolder: "Phone No (optional)",
    
        name: "phone_no",
        type: "number"
    },
]
// Customizable Area End

import CompleteAddressController, {
    Props,
    configJSON
} from "./CompleteAddressController";

export default class CompleteAddress extends CompleteAddressController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    GenInput = (handleChange: any, handleBlur: any, touched: any, errors: any, inputType: string, inputName: string, inputPlaceholder: string) => {
        return (
            <div>
                <input
                    autoComplete='false'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={inputType}
                    name={inputName}
                    style={webStyleAddress.formInput}
                    placeholder={inputPlaceholder} />
                {
                    touched[inputName] &&
                    errors[inputName] &&
                    <FormHelperText error={true} style={webStyleAddress.errorMessage}>
                        {errors[inputName]}
                    </FormHelperText>
                }
            </div>
        )
    }

    GenSelect = (formikFn: any, inputName: string, placeholderText: string, iterator: any, optionValue: any, optionText: any, handleChangeFn: Function) => {
        return (
            <div>
                <Select
                    native
                    disabled={iterator.length == 0}
                    onBlur={formikFn.handleBlur}
                    value={formikFn.values[inputName]}
                    name={inputName}
                    style={formikFn.values[inputName] == "placeholder" ? webStyleAddress.selectRagePlaceholder : webStyleAddress.selectRage}
                    disableUnderline
                    fullWidth
                    data-test-id={`selectInput_${inputName}`}
                    onChange={(e) => handleChangeFn(e, formikFn.handleChange, formikFn.setFieldValue)}
                    className="countrySelect"
                >
                    <option disabled value={"placeholder"}>
                        {placeholderText}
                    </option>
                    {iterator.map((data: any) => (
                        <option key={data[optionValue]} value={optionValue ? data[optionValue] : JSON.stringify(data)}>
                            {data[optionText]}
                        </option>
                    ))}
                </Select>
                {
                    formikFn.touched[inputName] &&
                    formikFn.errors[inputName] &&
                    <FormHelperText error={true} style={webStyleAddress.errorMessage}>
                        {formikFn.errors[inputName]}
                    </FormHelperText>
                }
            </div>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyleAddress.root}>
                    {/* @ts-ignore */}
                    <Paper style={webStyleAddress.formdiv}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                            <Grid item style={webStyleAddress.welcomeFormDiv} xs={12}>

                                <Grid container spacing={2}>

                                    <Grid item>
                                        <IconButton data-test-id="goBackBtn" onClick={this.handleGoBack} style={webStyleAddress.backbutton}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="h5">Complete your address</Typography>
                                    </Grid>

                                    <Grid item>
                                        <Formik
                                            initialValues={{
                                                restaurant_name: '',
                                                address: '',
                                                city: 'placeholder',
                                                state: 'placeholder',
                                                country: 'placeholder',
                                                restaurant_email_id: '',
                                                phone_no: '',
                                                website: '',
                                                restaurant_nick_name: '',
                                                overview: '',
                                            }}
                                            validateOnBlur={false}
                                            validationSchema={Yup.object().shape({
                                                overview: Yup.string().required("Restaurant Overview is Required"),
                                                restaurant_name: Yup.string().required("Restaurant name is Required"),
                                                restaurant_nick_name: Yup.string().required("Restaurant Location Nickname is Required"),
                                                address: Yup.string().required("Address is Required"),
                                                city: Yup.string().test('required',
                                                    'City is Required', (val) => val !== "placeholder"),
                                                state: Yup.string().test('required',
                                                    'State is Required', (val) => val !== "placeholder"),
                                                country: Yup.string().test('required',
                                                    'Country is Required', (val) => val !== "placeholder"),
                                                restaurant_email_id: Yup.string().required("Restaurant email id is Required").email('Invalid Email ID'),
                                                website: Yup.string().matches(configJSON.urlRegx, 'Please enter valid URL'),
                                                phone_no:  Yup.string()
                                                .matches(/^\d{8,15}$/, "Please enter a valid phone number between 8 and 15 digits.")
                                            })}
                                            onSubmit={(dataObject) => {
                                                this.submitLocationDetails(dataObject)
                                            }}
                                            render={({
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                values,
                                                setFieldValue
                                            }) => {
                                                return (
                                                    // @ts-ignore
                                                    <form onSubmit={handleSubmit} style={webStyleAddress.formTest}>

                                                        {formFields.slice(0, 2).map((form) => {
                                                            return this.GenInput(handleChange, handleBlur, touched, errors, form.type, form.name, form.placeHolder)
                                                        })}

                                                        {this.GenSelect(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit, values, setFieldValue }, 'country', 'Select Country', Country.getAllCountries(), 'isoCode', 'name', this.handleCountryChange
                                                        )}
                                                        {this.GenSelect(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit, values, setFieldValue }, 'state', 'Select State/Province', this.state.stateOfCountry, '', 'name', this.handleStateChange
                                                        )}
                                                        {this.GenSelect(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit, values, setFieldValue }, 'city', 'Select City', this.state.citiesOfState, 'name', 'name', this.handleCityChange
                                                        )}

                                                        {formFields.slice(5).map((form) => {
                                                            return this.GenInput(handleChange, handleBlur, touched, errors, form.type, form.name, form.placeHolder)
                                                        })}

                                                        <StyledButton
                                                            data-test-id="formSubmitBtn"
                                                            fullWidth type="submit" variant="contained">
                                                            Next
                                                        </StyledButton>

                                                    </form>
                                                )
                                            }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h5: {
            fontWeight: 500,
            marginBottom: "24px",
            fontSize: "35px",
        }
    }
});

const webStyleAddress = {
    root: {
        overflowX: "hidden",
        padding: "50px 0",
    },
    welcomeFormDiv: {
        width: "319px",
        height: "auto",
    },
    formTest: {
        gap: "12px",
        display: "flex",
        flexDirection: "Column",
    },
    formInput: {
        border: "none",
        backgroundColor: "#F9FAFB",
        padding: "20px",
        borderRadius: "8px",
        fontSize: "16px",
        height: "64px",
        width: "319px",
    },
    formdiv: {
        position: "relative",
        maxWidth: "488px",
        margin: "0 auto",
        minHeight: "620px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        padding: "30px 0 24px 0px",
        borderRadius: "40px",
    },
    backbutton: {
        border: "2px solid #F3F4F6",
    },

    errorMessage: {
        fontSize: "15px",
        paddingLeft: "10px",
    },

    selectRage: {
        border: "none",
        backgroundColor: "#F9FAFB",
        height: "64px",
        padding: "10px",
        borderRadius: "8px",
        fontSize: "16px",
    },
    selectRagePlaceholder: {
        backgroundColor: "#F9FAFB",
        color: "grey",
        fontSize: "16px",
        height: "64px",
        borderRadius: "8px",
        padding: "10px",
        border: "none",
    },
}
// Customizable Area End

