import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose: () => void;
  show: boolean;
  editId: any
  tabpanel: any;
  recallTableApi: () => void
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  IsLoading: boolean;
  EstimateWaitingTime: string
  waitingListData: any
  RemoveModal: any
  queueCount: number
  reservationData: any
  orderStatus: any
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageQueueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  WaitingListAPICallID: string = ""
  getResrvationCallID: string = ""
  updateResrvationCallID: string = ""
  removefromWaitinglistCallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      IsLoading: false,
      EstimateWaitingTime: '',
      waitingListData: [],
      RemoveModal: null,
      queueCount: 0,
      reservationData: [],
      orderStatus: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start 
    this.getReservationData()
    this.WaitingListData()
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getResrvationCallID) {
      this.setState({
        EstimateWaitingTime: responseJson.reservation?.data?.attributes?.estimate_waiting_time, queueCount: responseJson?.data?.reservation?.data?.attributes.queue_count,
        reservationData: responseJson.data?.reservation?.data, IsLoading: false, orderStatus: responseJson.data?.reservation?.data?.attributes?.status
      });
    } else if (apiRequestCallId === this.WaitingListAPICallID) {
      this.setState({ waitingListData: responseJson?.data, IsLoading: false })
    } else if (apiRequestCallId === this.updateResrvationCallID) {
      this.getReservationData()
      this.props.recallTableApi();
      this.props.handleClose()
    }
    else if (apiRequestCallId === this.removefromWaitinglistCallID) {
      this.handleRemoveModalClose()
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getReservationData = async () => {
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,

    };
    const endPoint = `bx_block_featured_restaurants/feature_restaurants/get_regervation/${this.props.editId}?data[latitude]=44.981165&data[longitude]=-93.279225`
    const req = apiCall({
      httpBody: {},
      header: headers,
      url: endPoint,
      httpMethod: 'GET'
    })

    this.getResrvationCallID = req.messageId;
    runEngine.sendMessage(req.id, req);

  };

  WaitingListData = async () => {
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,

    };
    const req = apiCall({
      httpBody: {},
      header: headers,
      url: `reservation_waitinglist/${this.props.editId}`,
      httpMethod: 'GET'
    })
    this.setState({ IsLoading: true });
    this.WaitingListAPICallID = req.messageId;
    runEngine.sendMessage(req.id, req);

  };

  updateReservationData = async () => {
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,
    };

    let formdata = new FormData();
    formdata.append("reservation_id", this.props.editId);
    formdata.append("status", this.state.orderStatus);
    formdata.append("estimate_waiting_time", this.state.EstimateWaitingTime);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_featured_restaurants/feature_restaurants/restaurant_reservation_edit"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    this.updateResrvationCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removefromWaitinglist = async () => {
    let tokenValue = await localStorage.getItem('authToken');
    const headers = {

      token: tokenValue,
    };

    let formdata = new FormData();
    formdata.append("reservation_id", this.props.editId);
    formdata.append("status", "cancelled");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_featured_restaurants/feature_restaurants/restaurant_reservation_edit"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.removefromWaitinglistCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };

  manageWaitingTime = (e: any) => {
    this.setState({ EstimateWaitingTime: e.target.value })
  }

  handleRemoveModalClose = () => {
    this.setState({ RemoveModal: null })
  }

  handleRemoveModalOpen = (id: any) => {
    this.setState({ RemoveModal: id })
  }
  RemoveWaitingQueue = () => {
    this.removefromWaitinglist()
    this.handleRemoveModalClose()
    this.WaitingListData()
  }

  manageStatus=(e:any)=>{
    this.setState({orderStatus:e.target.value})
  }
  // Customizable Area End

}

