import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  restriction: any;
  tagsData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DietaryRestrictionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  DietaryRestricationAPICallID: string = ""
  GetDietaryRestricationAPICallID: string = ""
  DeleteDietaryRestricationAPICallID: string = ""
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      restriction: '',
      tagsData: [],
    };

    // Customizable Area Start
    // Customizable Area End


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (!apiRequestCallId || !responseJson) {
      ToastMessage("error", "Something Went Wrong");
      return;
    }
    if (apiRequestCallId === this.GetDietaryRestricationAPICallID) {

      this.setState({ tagsData: responseJson?.dietary || [] })
    } else if (apiRequestCallId === this.DietaryRestricationAPICallID) {

      this.DietaryPostRecive(responseJson)
    } else if (apiRequestCallId === this.DeleteDietaryRestricationAPICallID) {
      this.GetDietaryRestricationAPI()

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.GetDietaryRestricationAPI();
  }

  DietaryPostRecive = (responseJson: any) => {
    if (this.state.tagsData.name === responseJson.dietary?.name) {
      ToastMessage('error', "Name has already been taken")
    }
    else {
      this.setState({ restriction: '' })
      this.GetDietaryRestricationAPI();
    }
  }
  handlerestrictionChange = (e: any) => {
    this.setState({
      restriction: e.target.value
    });
  }

  DietaryRestricationAPI = (e:any) => {
    e.preventDefault()
    if (this.state.restriction === "") {
      ToastMessage("error", "Please fill this field")
    }
    else {
      const headerData = {
        "Content-Type": "application/json",
        "token": localStorage.getItem("authToken")
      };
      const httpBody = {
        "data": {
          "name": this.state.restriction
        }
      }
      const req = apiCall({
        httpBody: httpBody,
        header: headerData,
        url: configJSON.DietaryPostURL,
        httpMethod: 'POST'
      })
      this.DietaryRestricationAPICallID = req.messageId;
      runEngine.sendMessage(req.id, req);
    }
  }

  GetDietaryRestricationAPI = () => {
    const headerData = {
      "token": localStorage.getItem("authToken")
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: configJSON.DietaryGetURL,
      httpMethod: 'GET'
    })
    this.GetDietaryRestricationAPICallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  DeleteDietaryRestricationTag = (index: number) => {
    const headerData = {
      "token": localStorage.getItem("authToken")
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: `${configJSON.DietaryDeleteURL}/${index}`,
      httpMethod: 'DELETE'
    })
    this.DeleteDietaryRestricationAPICallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }
  // Customizable Area End
}
