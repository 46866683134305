import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  index: number;
  textInput: any;
  favouriteData: any;
  restaurants: boolean;
  food: boolean;
  likeId: any;
  foodsData:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ElasticSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  swiper: any;
  apiGetAllFavourites: string = "";
  apiDeleteFav: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      index: 0,
      textInput: "",
      favouriteData: [],
      restaurants: true,
      food: false,
      likeId: '',
      foodsData:[
      {"id":"328","type":"menu_list","attributes":{"item_name":"green ","category_type":"lunch","image":{"url":"https://fudu1-152366-ruby.b152366.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTRKIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dae5fea60cb788413bf8f0fd91d983db86ccd5d2/bOvf94dPRxWu0u3QsPjF_tree.jpg","id":2487}}},
      {"id":"330","type":"menu_list","attributes":{"item_name":"Green Saladu","category_type":"breakfast","image":{"url":"https://fudu1-152366-ruby.b152366.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9d7f3ff134cf8f4a5c6c1135164ba347d5314a40/Restaurant%20salad.jpg","id":1709}}}
    ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    //runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId === this.apiGetAllFavourites){
        this.setState({favouriteData: responseJson.data.data})
      }
      if(apiRequestCallId === this.apiDeleteFav){
        this.dislikeDashFavResponse(responseJson , errorReponse)
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.favouriteApisCall()
  }

  rest = () => {
    this.setState({restaurants: true , food: false})
  }
  food = () => {
    this.setState({restaurants: false, food: true})
  }
  navigateOnNext=(item : any)=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), "RestaurantDetails"); 
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { item: item });  
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  navigateToNext=(item:any)=>{
    this.props.navigation.navigate("FeaturedRestaurant", { searchedTxt:item })
  }

  heartFavDisLike = (id: any , item: any) => {
    this.dislikeDashFavFavourite(id)
   
  }

  dislikeDashFavFavourite = async (id: any) => {
    let usertoken = await getStorageData('token-access')
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: usertoken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteFav = requestMessage.messageId;

    // id in URL should be dynamic
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  dislikeDashFavResponse = ( response: any , error: any) => {
    if (response != null) {
      if (response.message === "Destroyed Successfully") {
        Alert.alert("Restaurant has been removed from my Favourites")
        this.favouriteApisCall()
    }
    }
    
    this.parseApiCatchErrorResponse(error);
  }

  favouriteApisCall = async () => {
    let token = await getStorageData('token-access')
    let latitude = await getStorageData('latitudes')
    let longitude = await getStorageData('longitudes')
    const header = {
        "Content-Type": configJSON.favouritesApiApiContentType,
         token: token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAllFavourites = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_favourites/favourites?data[latitude]=${latitude}&&data[longitude]=${longitude}`
    );


    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFavList=()=>{
    let filterData=this.state.food?this.state.favouriteData.filter((item:any)=>item.attributes.favouriteable_type === 'BxBlockMenuOrdering::Menu')
    :
    this.state.favouriteData.filter((data:any)=>data.attributes.favouriteable_type==="BxBlockLocation::RestaurantLocation")
   return filterData
  }

  // Customizable Area End
}
