import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  getPendingPostsData: any;
  openRejectModal: boolean;
  rejectId: any;
  rejectReason: any;
  anchorElMenuMoreBtn: any;
  detailsShowId: any;
  showDetatilsData: any;
  showImageData: any,
  openImgCardModal: boolean;
  loader: boolean;
  isrejected:boolean;
  // Customizable Area End
}
;
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PendingPostsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  getPendingPostsCallId: any;
  rejectApiCallId: any;
  approveApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      getPendingPostsData: [],
      detailsShowId: "",
      showDetatilsData: "",
      anchorElMenuMoreBtn: null,
      openImgCardModal: false,
      openRejectModal: false,
      rejectId: "",
      rejectReason: "",
      showImageData: [],
      loader:false,
      isrejected:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getPendingPostsCall()
    // Customizable Area End
}

 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.HandlePendingPostsCall(from, message)
    this.HandleRejectReasonCall(from, message)
    this.HandleApproveCall(from, message)

    // Customizable Area End

    // Customizable Area Start

}
  // Customizable Area End

  // web events
  RejectCall =(RjctId: any) => {
    this.setState({openRejectModal: true, rejectId: RjctId })
  }

  handleCloseReject =() => {
    this.setState({openRejectModal: false, rejectReason: ""})
  }

  handleChangeReason =(event:any) => {
    this.setState({rejectReason: event.target.value})
  }

  handleClickopenAnchor = async(event: any, showPostId: any,showData:any, showImages:any) => {
    this.setState({ anchorElMenuMoreBtn: event.currentTarget, detailsShowId: showPostId, showDetatilsData: showData,
      showImageData: showImages 
    })
  }
  handleCloseMenuDelete = () => {
    this.setState({ anchorElMenuMoreBtn: null })
  }
  handleOpenDetailsModal = () => {
    this.setState({ openImgCardModal: true, anchorElMenuMoreBtn: null })
  }
  handleCloseDetailsModal = () => {
    this.setState({openImgCardModal: false})
  }

  getPendingPostsCall = async() => {
    this.setState({loader:true});
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
      "token": await localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPendingPostsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/post_status?restaurant_location_id=${RestaurantId}&status=pending`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  RejectApiCall =() => {
    this.setState({isrejected:true});
    const header = {
      "token": localStorage.getItem("authToken")
    };

    let formdata = new FormData();
    formdata.append("data[status]", "rejected");
    formdata.append("data[reject_reason]", this.state.rejectReason);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rejectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/${this.state.rejectId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ApproveApiCall =(approveId:any) => {
    this.setState({loader:true})
    const header = {
      "token": localStorage.getItem("authToken")
    };

    let formdata = new FormData();
    formdata.append("data[status]", "approved");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.approveApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/${approveId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  HandlePendingPostsCall = async (from: string, message: Message) => {
    if (this.getPendingPostsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
          this.setState({ getPendingPostsData: apiResponse?.data, loader:false})
          if(apiResponse.data.length == 0){
            ToastMessage('error', "No pending post(s) are available ");
           } 
        }
    }
  }

  HandleRejectReasonCall = async (from: string, message: Message) => {
    if (this.rejectApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
           ToastMessage("success", "Post has been rejected successfully") 
           this.setState({openRejectModal: false, rejectReason: "", isrejected:false})
           this.getPendingPostsCall()
        }else {
          ToastMessage("error", "Reason for rejection can't be blank ")
        }
    }
  }

  HandleApproveCall = async (from: string, message: Message) => {
    if (this.approveApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
           ToastMessage("success", "Post has been approved successfully") 
           this.getPendingPostsCall(); 
           this.setState({loader:false})
        }
    }
  }
  // Customizable Area End
}
