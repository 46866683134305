import React from "react";

import {
  Grid,
  // Customizable Area Start
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#6200ee",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import ChangepassowordController, {
  Props,
} from "./ChangepassowordController.web";

export default class ChangePassword extends ChangepassowordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { passchange, showPassword, showPasswordNew } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: 'auto' }}>
          <Grid item sm={12} style={{ height: 'auto' }}>
            <div style={{ marginTop: '-14px' }}>
              <h2>Settings &#62; Change password</h2>
              <Grid style={webStylechangepass.gridStyle}>
                <div style={webStylechangepass.boxdiv}>
                  <h3>Change Password</h3>
                  <Typography style={webStylechangepass.heading2}>Existing password</Typography>
                  <div style={{ position: 'relative' }}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="expassword"
                      data-test-id='existingpass'
                      placeholder="Enter existing password "
                      value={passchange.expassword}
                      style={webStylechangepass.formInput} onChange={(e) => this.handlechangepassowrd(e)} />
                    {showPassword ? (
                      <VisibilityIcon style={webStylechangepass.visiblepass as React.CSSProperties} onClick={() => this.handleTogglePassword('1')} data-test-id='toggle_password' />
                    ) : (
                      <VisibilityOffIcon style={webStylechangepass.visiblepass as React.CSSProperties} onClick={() => this.handleTogglePassword('1')} data-test-id='toggle_password' />
                    )}
                  </div>
                 
                  <Typography style={{ ...webStylechangepass.heading2, marginTop: '25px' }}>New password</Typography>
                  <div style={{ position: 'relative' }}>
                    <input
                      type={showPasswordNew ? 'text' : 'password'}
                      name="newpassword"
                      data-test-id='newpass'
                      placeholder="Enter new password"
                      value={passchange.newpassword}
                      style={webStylechangepass.formInput} onChange={(e) => this.handlechangepassowrd(e)} />
                    {showPasswordNew ? (
                      <VisibilityIcon style={webStylechangepass.visiblepass as React.CSSProperties} onClick={() => this.handleTogglePassword('2')} data-test-id='toggle_password_new' />
                    ) : (
                      <VisibilityOffIcon style={webStylechangepass.visiblepass as React.CSSProperties} onClick={() => this.handleTogglePassword('2')} data-test-id='toggle_password_new' />
                    )}
                  </div>
                  <Button style={webStylechangepass.btnchange as any} data-test-id='changepass_btn' onClick={this.changePassword}>
                    Change password
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStylechangepass = {
  gridStyle: {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    padding: "10px 30px",
    margin: "25px 13px 0 0",
    height: 'auto'
  },
  visiblepass: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  boxdiv: {
    paddingLeft: '20px',
    paddingTop: '15px',
    width: '72%',
    maxWidth: '700px'
  },
  btnchange: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "56px",
    borderRadius: "8px",
    fontSize: "15px",
    marginTop: '50px',
    marginBottom: '15px',
    minWidth: '182px',
    textTransform: "none",
  },
  heading2: {
    color: '#001F49',
    fontFamily: 'Inter',
    fontWeight: 500,
    marginTop: '10px'
  },
  formInput: {
    background: 'rgb(244, 247, 249)',
    color: '#001F49',
    width: "100%",
    height: "40px",
    fontSize: "14px",
    borderRadius: "8px",
    margin: "10px 0px",
    border: "1px solid #E5E7EB",
    padding: '8px',
    outline: 'none',
  },
}

// Customizable Area End

