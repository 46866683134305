import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
import { State, City, Country } from "country-state-city";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    stateOfCountry: any;
    citiesOfState: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class CompleteAddressController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiSubmitLocationDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            stateOfCountry: [],
            citiesOfState: []
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForSubmitLocationDetails(from, message)
        // Customizable Area End
    }

    // Customizable Area Start
    handleGoBack = () => {
        this.props.navigation.navigate('LocationSignUp')
    }

    handleCountryChange = (e: any, handleChange: any, setFieldValue: any) => {
        this.setState({ stateOfCountry: [], citiesOfState: [] })
        setFieldValue("state", "placeholder")
        setFieldValue("city", "placeholder")
        const statesArray = State.getStatesOfCountry(e.target.value);
        this.setState({ stateOfCountry: statesArray }, () => {
            if (statesArray.length == 0) {
                let data: any = Country.getCountryByCode(e.target.value)
                this.setState({ stateOfCountry: [{ name: data.name, countryCode: 'test', isoCode: 'test' }] })
            }
        })
        handleChange(e)
    };

    handleStateChange = (e: any, handleChange: any, setFieldValue: any) => {
        let hh = JSON.parse(e.target.value)
        setFieldValue("city", "placeholder")
        const citiesArray = City.getCitiesOfState(hh.countryCode, hh.isoCode);
        console.log(citiesArray, "citiesArraycitiesArray");
        this.setState({ citiesOfState: citiesArray }, () => {
            if (citiesArray.length == 0) {
                this.setState({ citiesOfState: [{ name: hh.name }] })
            }
        })
        handleChange(e)
    };

    handleCityChange = (e: any, handleChange: any, setFieldValue?: any) => {
        handleChange(e)
    };

    //API Function
    submitLocationDetails = (dataForm: any) => {
        const Params = localStorage.getItem('accountID')
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem("SignUpToken")
        };

        const data = {
            restaurant_name: dataForm.restaurant_name,
            address: dataForm.address,
            email: dataForm.restaurant_email_id,
            full_phone_number: dataForm.phone_no,
            city: dataForm.city,
            state: JSON.parse(dataForm.state).name,
            country: dataForm.country,
            website: dataForm.website,
            overview: dataForm.overview,
            restaurant_nick_name: dataForm.restaurant_nick_name
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiSubmitLocationDetailsCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/restaurant_locations/${Params}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //API's Response Function
    handleResponseForSubmitLocationDetails = (from: string, message: Message) => {
        if (this.apiSubmitLocationDetailsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                ToastMessage('error', 'Something went Wrong')
            } else {
                this.props.navigation.navigate('CompleteSignUp')
                ToastMessage('success', 'Details saved successfully')
            }
        }
    }

    // Customizable Area End

}