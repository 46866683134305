import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Paper
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormHelperText from '@material-ui/core/FormHelperText';
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "319px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import ForgotPwdController, {
    Props,
    configJSON,
} from "./ForgotPwdController";

export default class ForgotPassword extends ForgotPwdController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
    
        // Customizable Area End
    }
    // Customizable Area Start
    GenInput = (
        formikFunction: any,
        inputType: string,
        inputName: string,
        inputPlaceholder: string,
        testID: string,
        defaultInputValue?: string | null
    ) => {
        return (
            <div>
                <input
                    autoComplete="false"
                    data-test-id={testID}
                    onChange={formikFunction.handleChange}
                    onBlur={formikFunction.handleBlur}
                    type={inputType}
                    name={inputName}
                    defaultValue={defaultInputValue || ""}
                    style={webStyle.formInput}
                    placeholder={inputPlaceholder}
                />
                {formikFunction.touched[inputName] && formikFunction.errors[inputName] && (
                    <FormHelperText error={true} style={webStyle.errorMessage}>
                        {" "}
                        {formikFunction.errors[inputName]}
                    </FormHelperText>
                )}
            </div>
        );
    };
    // Customizable Area End


    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
            {/* @ts-ignore */}
            <div style={webStyle.root}>

                {/* @ts-ignore */}
                <Paper style={webStyle.formdiv}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                        <Grid item style={webStyle.welcomeFormDiv} xs={12}>
                            <Grid container spacing={3}>

                                <Grid item>
                                    <IconButton onClick={this.handleGoBack} data-test-id="goBackBtn" style={webStyle.backbutton}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>

                                <Grid item>
                                    <Typography gutterBottom variant="h1">
                                        Forgot<br />
                                        Password?
                                    </Typography>
                                    <Typography variant="body1">
                                        Don’t worry, it happens! Please enter your email address or phone number associated with your account
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Formik
                                        initialValues={{
                                            email: localStorage.getItem("forgotpwdEmail")
                                                ? localStorage.getItem("forgotpwdEmail")
                                                : ""
                                        }}
                                        validateOnBlur={false}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string().required("Email is Required").email(configJSON.forgetPassErrorMessage)
                                        })}
                                        onSubmit={(dataObject) => {
                                            this.callForgotPwdApi(dataObject)
                                        }}
                                        render={({
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit
                                        }) => {
                                            return (
                                                // @ts-ignore
                                                <form onSubmit={handleSubmit} style={webStyle.formTest}>
                                                    {this.GenInput(
                                                        { handleChange, handleBlur, touched, errors },
                                                        "text",
                                                        "email",
                                                        "Email Address",
                                                        "emailInput",
                                                        localStorage.getItem('forgotpwdEmail')
                                                    )}

                                                    <StyledButton style={{ marginTop: "20px" }}
                                                        data-test-id="formSubmitBtn4Password"
                                                        type="submit"
                                                        variant="contained"
                                                        disabled={this.state.buttonFlag}>
                                                        Next
                                                    </StyledButton>

                                                </form>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </ThemeProvider>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h1: {
            fontSize: "35px",
            fontWeight: 500,
            lineHeight: "43px"
        },
        body1: {
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#6B7280"
        },
        body2: {
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#6B7280"
        }
    }
});

const webStyle = {
    welcomeFormDiv: {
        height: "auto",
        width: "319px",
    },
    formdiv: {
        maxWidth: "488px",
        margin: "0 auto",
        padding: "30px 0 52px 0px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "40px",
        minHeight: "420px",
        position: "relative"
    },
    formInput: {
        background: "#F9FAFB",
        border: "none",
        height: "64px",
        width: "319px",
        padding: "20px",
        fontSize: "16px",
        borderRadius: "8px"
    },
    backbutton: {
        border: "2px solid #F3F4F6",
    },
    errorMessage: {
        paddingLeft: "10px",
        fontSize: "15px"
    },
    formTest: {
        display: "flex",
        flexDirection: "Column",
        gap: "40px",
        margin: "0px"
    },
    root: {
        padding: "50px 0",
        overflowX: "hidden"
    },
    createAccountLink: {
        textDecoration: "none",
        color: "#096FFF",
        borderBottom: "1px solid #096FFF",
        cursor: "pointer"
    },
    eyeIcon: {
        right: "10px",
        position: "absolute",
        top: "10px"
    },
    passwordInputContainer: {
        position: "relative"
    },
    editLink: {
        textDecoration: "none",
        color: "#FF91D5",
        borderBottom: "1px solid #FF91D5",
        cursor: "pointer",
        marginLeft: "8px"
    },
}
// Customizable Area End



