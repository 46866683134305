import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  IconButton,
  Grid,
  Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OtpInput from 'react-otp-input';

export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "319px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import ForgotPasswordOTPController, {
  Props,
  configJSON,
} from "./ForgotPasswordOTPController";

export default class ForgotPasswordOTP extends ForgotPasswordOTPController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
  
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
          {/* @ts-ignore */}
          <div style={webStyle.root}>

              {/* @ts-ignore */}
              <Paper style={webStyle.formdiv}>
                  <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                      <Grid item style={webStyle.welcomeFormDiv} xs={12}>
                          <Grid container spacing={1}>

                              <Grid item>
                                  <IconButton data-test-id="goBackBtn" onClick={this.handleGoBack} style={webStyle.backbutton}>
                                      <ArrowBackIcon />
                                  </IconButton>
                              </Grid>

                              <Grid item xs={12}>
                                  <Typography gutterBottom variant="h1">
                                      {configJSON.forgetOTPPageTitle}
                                  </Typography>
                                  <Typography variant="body1">
                                      Enter 4-digit OTP we’ve sent to your email address
                                  </Typography>
                                  <Typography gutterBottom style={{ color: "#4B5563" }} variant="body2">
                                      <span style={{wordBreak: "break-all"}} >{this.state.email}</span>
                                      <span
                                          onClick={this.handleGoBack}
                                          data-test-id="emailEditBtn"
                                          style={webStyle.editLink}
                                      >
                                          edit
                                      </span>
                                  </Typography>
                              </Grid>

                              <Grid item>
                                  <OtpInput
                                      value={this.state.otpVal}
                                      onChange={this.handleChange}
                                      numInputs={4}
                                      inputStyle={webStyle.otpInput}
                                      containerStyle={webStyle.otpContainer}
                                      isInputNum={true}
                                  />

                                  {/* @ts-ignore */}
                                  <div style={webStyle.timerDiv as React.CSSProperties}>
                                  OTP TimeOut: {Math.floor(this.state.timer / 60)}:{this.state.timer % 60 < 10 ? '0' : ''}{this.state.timer % 60}
                                  </div>
                              </Grid>

                              <Grid item xs={12} style={{ marginTop: "20px" }}>
                                  <StyledButton
                                     onClick={this.verifyOTPCall}
                                      data-test-id="otpSubmit"
                                      variant="contained">
                                      Verify
                                  </StyledButton>
                                  {/* @ts-ignore */}
                                  <Typography style={webStyle.assistSection as React.CSSProperties} variant="body2">
                                      Didn’t recieve the OTP? <span data-test-id="sendOtpAgain" onClick={this.sendOTPAgain} style={webStyle.createAccountLink}>Send again</span>
                                  </Typography>
                              </Grid>

                          </Grid>
                      </Grid>
                  </Grid>
              </Paper>
          </div>
      </ThemeProvider>
  )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  welcomeFormDiv: {
      height: "auto",
      width: "319px",
  },
  formdiv: {
      maxWidth: "488px",
      margin: "0 auto",
      padding: "30px 0 36px 0px",
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      borderRadius: "40px",
      minHeight: "420px",
      position: "relative"
  },
  otpInput: {
      margin: '0 5px',
      background: "rgb(241 241 241)",
      border: "0",
      padding: "20px",
      minHeight: "64px",
      minWidth: "70px",
      fontSize: "16px",
      borderRadius: "8px",
  },
  otpContainer: {
      justifyContent: "space-between",
  },
  backbutton: {
      border: "2px solid #F3F4F6",
  },
  root: {
      padding: "50px 0",
      overflowX: "hidden"
  },
  createAccountLink: {
      textDecoration: "none",
      color: "#096FFF",
      borderBottom: "1px solid #096FFF",
      cursor: "pointer"
  },
  editLink: {
      textDecoration: "none",
      color: "#FF91D5",
      borderBottom: "1px solid #FF91D5",
      cursor: "pointer",
      marginLeft: "8px"
  },
  timerDiv: {
      marginTop: "20px",
      textAlign: "center"
  },
  assistSection: {
      textAlign: "center",
      marginTop: "22px"
  }
}

const theme = createTheme({
  palette: {
  },
  typography: {
      "fontFamily": `inter, sans-serif`,
      h1: {
          fontSize: "35px",
          fontWeight: 500,
          lineHeight: "43px"
      },
      body1: {
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          color: "#6B7280"
      },
      body2: {
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          color: "#6B7280"
      }
  }
});
// Customizable Area End
