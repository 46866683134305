import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedOptions: any;
  visible: boolean;
  SelectsendTime:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InternalTeamEmailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      selectedOptions: {
        internalcheck1: '10:30 PM',
        internalcheck2: 'same day',
        internalcheck3: '10:30 PM',
      },
     visible: false,
     SelectsendTime: [''],
    };

    // Customizable Area Start

    // Customizable Area End


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handlechangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ visible:e.target.checked});
  };

  handleselectChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      selectedOptions: {
        ...prevState.selectedOptions,
        [name]: value,
      },
    }));
  };
  addSendTime = () => {
    const { SelectsendTime } = this.state;
    this.setState({
      SelectsendTime: [...SelectsendTime, '']
    });
  }
  // Customizable Area End
}
