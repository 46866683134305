import React, { CSSProperties } from 'react'

// Customizable Area Start
import PropTypes from 'prop-types';
import { Grid, FormHelperText, ButtonProps, Modal } from '@material-ui/core';
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import * as Yup from "yup";
import { Formik } from "formik";
// Customizable Area End

// Customizable Area Start
const rem = "Selection is Needed"
// Customizable Area End

import FeaturesEditController, {
    Props,
} from './FeaturesEditController';
import { BlueCrossImg } from "./assets"

export default class FeaturesEdit extends FeaturesEditController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <>
                {/* @ts-ignore */}
                <Modal
                    open={this.props.show}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div style={{ width: "70%", height: "93%", overflowY: 'auto', position: "absolute", top: "2%", left: "15%", backgroundColor: "white", boxShadow: "24", paddingTop: 2 }}>

                        <div>
                            <h2 style={{ display: "flex", justifyContent: "center" }}>Edit Features</h2>
                            <img src={BlueCrossImg} style={{
                                position: "absolute",
                                top: "18px",
                                right: "18px"
                            }} onClick={this.props.handleClose} />

                            {this.state.featuresData?.id && <Formik
                                initialValues={{
                                    valet_parking: this.state.featuresData?.attributes?.valet_parking_availability.toString(),
                                    parking_availability: this.state.featuresData?.attributes?.parking_availability.toString(),
                                    serves_alcohol: this.state.featuresData?.attributes?.serves_alcohol.toString(),
                                    speciality: this.state.featuresData?.attributes?.speciality,
                                    dining: this.state.featuresData?.attributes?.dining,
                                    category: this.state.featuresData?.attributes?.category,
                                    shisha: this.state.featuresData?.attributes?.sheesha.toString(),
                                    kids_area: this.state.featuresData?.attributes?.kids_area.toString(),
                                    smoking_area: this.state.featuresData?.attributes?.smoking_area.toString(),
                                }}

                                validationSchema={Yup.object().shape({
                                    serves_alcohol: Yup.string().required(rem),
                                    parking_availability: Yup.string().required(rem),
                                    valet_parking: Yup.string().required(rem),
                                    dining: Yup.string().required(rem),
                                    smoking_area: Yup.string().required(rem),
                                    kids_area: Yup.string().required(rem),
                                    shisha: Yup.string().required(rem),
                                    category: Yup.string().required(rem),
                                    speciality: Yup.string().required("Can't be Empty"),

                                })}

                                validateOnBlur={false}

                                onSubmit={(dataObject) => {
                                    this.updateFeature(dataObject)
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleSubmit,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit} >

                                            <Grid container style={featureStyle.container}>
                                                <Grid item xs={12} lg={6} style={{ marginTop: '20px' }}>
                                                    <div style={featureStyle.formcontrol}>

                                                        <FormControl variant="standard" fullWidth >
                                                            <span style={featureStyle.titlePart} >Parking Availability</span>
                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_parking'
                                                                name="parking_availability"
                                                                onChange={handleChange}
                                                                value={values.parking_availability}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer}>
                                                                    <FormControlLabel control={<Radio color="primary" />} value="true" label="Yes" style={{ color: '#9CA3AF' }} />
                                                                    <FormControlLabel control={<Radio color="primary" />} value="false" label="No" style={{ color: '#9CA3AF' }} />
                                                                </div>
                                                            </RadioGroup>
                                                            {
                                                                touched.parking_availability &&
                                                                errors.parking_availability && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.parking_availability}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>
                                                    </div>

                                                    <div style={featureStyle.formcontrol}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <span style={featureStyle.titlePart}>
                                                                Valet Parking Availability
                                                            </span>
                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_valet_parking'
                                                                name="valet_parking"
                                                                onChange={handleChange}
                                                                value={values.valet_parking}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer}>
                                                                    <FormControlLabel control={<Radio color="primary" />} value="true" label="Yes" style={{ color: '#9CA3AF' }} />
                                                                    <FormControlLabel control={<Radio color="primary" />} value="false" label="No" style={{ color: '#9CA3AF' }} />
                                                                </div>
                                                            </RadioGroup>
                                                            {
                                                                touched.valet_parking &&
                                                                errors.valet_parking && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.valet_parking}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>
                                                    </div>
                                                    <div style={featureStyle.formcontrol}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <span style={featureStyle.titlePart}>
                                                                Serves Alcohol
                                                            </span>
                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_serves_alcohol'
                                                                name="serves_alcohol"
                                                                onChange={handleChange}
                                                                value={values.serves_alcohol}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer}>
                                                                    <FormControlLabel control={<Radio color="primary" />} value="true" label="Yes" style={{ color: '#9CA3AF' }} />
                                                                    <FormControlLabel control={<Radio color="primary" />} value="false" label="No" style={{ color: '#9CA3AF' }} />
                                                                </div>
                                                            </RadioGroup>
                                                            {
                                                                touched.serves_alcohol &&
                                                                errors.serves_alcohol && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.serves_alcohol}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>
                                                    </div>
                                                    <div style={featureStyle.formcontrol}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <span style={featureStyle.titlePart}>
                                                                Kids Area
                                                            </span>
                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_kids_area'
                                                                name="kids_area"
                                                                onChange={handleChange}
                                                                value={values.kids_area}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer}>
                                                                    <FormControlLabel control={<Radio color="primary" />} value="true" label="Yes" style={{ color: '#9CA3AF' }} />
                                                                    <FormControlLabel control={<Radio color="primary" />} value="false" label="No" style={{ color: '#9CA3AF' }} />
                                                                </div>
                                                            </RadioGroup>
                                                            {
                                                                touched.kids_area &&
                                                                errors.kids_area && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.kids_area}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>

                                                    </div>
                                                    <div style={featureStyle.formcontrol} >
                                                        <FormControl variant="standard" fullWidth>
                                                            <span style={featureStyle.titlePart}>
                                                                Dining Type
                                                            </span>
                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_dining'
                                                                name="dining"
                                                                onChange={handleChange}
                                                                value={values.dining}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer3opt}>
                                                                <Grid container>
                                                                <Grid item xs={12} lg={4}>
                                                                    <FormControlLabel control={<Radio color="primary" />}  value="fine" label="Fine" style={{ color: '#9CA3AF' }} />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={4}>
                                                                    <FormControlLabel control={<Radio color="primary" />}  value="casual" label="Casual" style={{ color: '#9CA3AF' }}/>
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={4}>
                                                                    <FormControlLabel control={<Radio color="primary" />} style={{ color: '#9CA3AF' }} value="both" label="Both" />
                                                                    </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </RadioGroup>
                                                            {
                                                                touched.dining &&
                                                                errors.dining && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.dining}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>
                                                    </div>
                                                    <div style={featureStyle.formcontrol}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <span style={featureStyle.titlePart}>
                                                                Shisha
                                                            </span>
                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_sheesha'
                                                                name="shisha"
                                                                onChange={handleChange}
                                                                value={values.shisha}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer}>
                                                                    <FormControlLabel control={<Radio color="primary" />} style={{ color: '#9CA3AF' }} value="true" label="Yes" />
                                                                    <FormControlLabel control={<Radio color="primary" />} style={{ color: '#9CA3AF' }} value="false" label="No" />
                                                                </div>
                                                            </RadioGroup>
                                                            {
                                                                touched.shisha &&
                                                                errors.shisha && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.shisha}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} lg={6} direction="column" style={{ marginTop: '20px' }} >
                                                    <div style={featureStyle.formcontrol2}>
                                                        <span style={featureStyle.titlePart}>
                                                            Speciality
                                                        </span>
                                                        <input placeholder='type speciality' data-test-id='input_speciality' onChange={handleChange} type='text' value={values.speciality} name="speciality" style={featureStyle.formInput} />
                                                        {
                                                            touched.speciality &&
                                                            errors.speciality && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                {errors.speciality}
                                                            </FormHelperText>
                                                            )
                                                        }
                                                    </div>

                                                    <div style={featureStyle.formcontrol2}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <span style={featureStyle.titlePart}>
                                                                Food Type
                                                            </span>

                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_category'
                                                                name="category"
                                                                onChange={handleChange}
                                                                value={values.category}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer3opt}>
                                                                    <Grid container>
                                                                    <Grid item xs={12} lg={4}>
                                                                    <FormControlLabel control={<Radio color="primary" />} style={{ color: '#9CA3AF' }} value="veg" label="Veg" />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={5}>
                                                                    <FormControlLabel control={<Radio color="primary" />} value="non_veg" style={{ color: '#9CA3AF' }} label="Non-veg" />
                                                                    </Grid>
                                                                    <Grid item xs={12} lg={3}>
                                                                    <FormControlLabel control={<Radio color="primary" />} style={{ color: '#9CA3AF' }} value="both_category" label="Both" />
                                                                    </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </RadioGroup>

                                                            {
                                                                touched.category &&
                                                                errors.category && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.category}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>
                                                    </div>

                                                    <div style={featureStyle.formcontrol2}>
                                                        <FormControl variant="standard" fullWidth>
                                                            <span style={featureStyle.titlePart}>
                                                                Smoking Area
                                                            </span>
                                                            <RadioGroup
                                                                row={true}
                                                                data-test-id='radio_smoking_area'
                                                                name="smoking_area"
                                                                onChange={handleChange}
                                                                value={values.smoking_area}
                                                            >
                                                                <div style={featureStyle.radioBtnContainer}>
                                                                    <FormControlLabel control={<Radio color="primary" />} style={{ color: '#9CA3AF' }} value="true" label="Yes" />
                                                                    <FormControlLabel control={<Radio color="primary" />} style={{ color: '#9CA3AF' }} value="false" label="No" />
                                                                </div>
                                                            </RadioGroup>
                                                            {
                                                                touched.smoking_area &&
                                                                errors.smoking_area && (<FormHelperText error={true} style={{ fontSize: '16px' }}>
                                                                    {errors.smoking_area}
                                                                </FormHelperText>
                                                                )
                                                            }
                                                        </FormControl>
                                                    </div>

                                                    <Grid container style={{ display: 'flex', justifyContent: 'space-evenly', width: "94%", marginTop: "165px" }}>
                                                        <Grid item xs={12} lg={6}>
                                                        <Button data-test-id='button_cancel'{...featureStyle.formbuttoncancle} variant="outlined" onClick={this.props.handleClose}>Cancel</Button>
                                                        </Grid>
                                                        <Grid item xs={12} lg={6}>
                                                        <Button type="submit" data-test-id='button_add' color="primary" {...featureStyle.formbutton} variant="contained">Submit</Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </form>)
                                }}
                            </Formik>}
                        </div>
                    </div>
                </Modal>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
interface StyleType {
    formcontrol: CSSProperties;
    titlePart: CSSProperties;
    radioBtnContainer: CSSProperties;
    radioBtnContainer3opt: CSSProperties;
    formwrapperInput: CSSProperties;
    createAccountLinkWrap1: CSSProperties;
    createAccountLink: CSSProperties;
    formcontrol2: CSSProperties;
    formInput: CSSProperties;
    formbutton: ButtonProps;
    formbuttoncancle: ButtonProps;
    container: any;
    inputTime: CSSProperties;
    selectHrs: CSSProperties;
}
const featureStyle: StyleType = {
   
    titlePart: {
        paddingTop: '7px',
        color: "black",
        fontSize: "16px",
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    radioBtnContainer: {
        "width": "75%",
        "display": "flex",
        "gap": "20%",

    },
    radioBtnContainer3opt: {
        "width": "90%",
        "display": "flex",
        "gap": "15%",
    },
    formwrapperInput: {
        padding: "10px 10px 0px 10px",
        width: "80%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        border: "1px solid #E5E7EB",
        borderRadius: "8px",
        marginBottom: "15px",
    },
    inputTime: {
        width: "32px",
        backgroundColor: "#f3f4f6",
        padding: "5px",
        border: "none",
        height: "32px",
        borderRadius: "2px"
    },
    formInput: {
        width: "95%",
        fontSize: "16px",
        backgroundColor: "#F9FAFB;",
        paddingLeft: "20px",
        margin: "16px 0px",
        border: "1px solid #E5E7EB",
        height: "40px",
        alignSelf: "center",
        borderRadius: "8px"
    },
    selectHrs: {
        height: "32px",
        padding: "5px",
        marginLeft: "5px",
        backgroundColor: "#f3f4f6",
        border: "none",
        borderRadius: "2px"
    },
    formbutton: {
        style: {
            width: "95%",
            marginTop: "38px",
            textTransform: "none",
            fontSize: "16px",
            color: "white",
            borderRadius: '8px',
            height: "70%"
        }
    },
    formbuttoncancle: {
        style: {
            width: "95%",
            marginTop: "38px",
            textTransform: "none",
            fontSize: "16px",
            border: '1px solid #096FFF',
            borderRadius: '10px',
            color: "#096FFF",
            height: "70%"
        }
    },
    createAccountLinkWrap1: {
        width: "434px",
        height: "68px",
        borderRadius: "8px",
        marginBottom: "15px"
    },
    createAccountLink: {
        color: "#096FFF",
        textDecoration: "none",
        borderBottom: "1px solid #096FFF",
        cursor: "pointer"
    },
    formcontrol: {
        padding: "20px 0px 0 15px",
        height: "auto",
        border: "1px solid #E5E7EB",
        borderRadius: "8px",
        maxWidth: '84%',
        marginBottom: "10px",
    },
    formcontrol2: {
        padding: "20px 0px 0 15px",
        height: "auto",
        border: "1px solid #E5E7EB",
        borderRadius: "8px",
        maxWidth: '84%',
        marginBottom: "10px",
    },

    container: {
        padding: "10px 0px 0px 15px",
        border: "1px solid #E5E7EB",
        borderRadius: "15px",
        marginBottom: "15px",
        marginLeft: "15px",
        display: 'flex',
        justifyContent: 'space-evenly',
        maxWidth: "97%"
    }
}
// Customizable Area End