import React, { CSSProperties } from "react";
// Customizable Area Start
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End
import ContectFuduDataController, { Props } from "./ContectFuduDataController";
interface StyleType {
  root: any;
  needBlock: CSSProperties;
  needheading: CSSProperties;
  wrapperSubscreption: CSSProperties;
  tablecell: CSSProperties;
  tabhead: CSSProperties;
  btncreatequery: CSSProperties;
  alltickets: CSSProperties;
}
const WebStyles: StyleType = {
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px",
  },

  needBlock: {
    // padding: "10px 0 30px 20px",
  },

  needheading: {
    fontSize: "24px",
    fontWeight: 600,
    margin: "0",
    marginTop: '6px'
  },

  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    margin: "25px 18px 0 0",
    overflowX: 'auto',
    height: '100vh'
  },

  tablecell: {
    borderBottom: "none",
    color: "#4B5563",
    maxWidth: '200px',
  },

  tabhead: {
    fontSize: "14px",
  },


  btncreatequery: {
    fontSize: "15px",
    color: "#fff",
    borderRadius: "4px",
    backgroundColor: "#096FFF",
    padding: "7px 16px",
    border: "none",
    cursor: "pointer",
    marginBottom: '17px'
  },

  alltickets: {
    fontSize: "18px",
    color: "#000",
    fontWeight: "bold",
    cursor: 'pointer',
    marginLeft: '25px'
  },
};
// Customizable Area End

class ContectFuduData extends ContectFuduDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderTable() {
    if (this.state.isLoading === true) {
      return <PulseLoader color="#096fff" className="loader" />;
    }

    if (this.state.contactData.length > 0) {
      return (
        <TableBody>
          {this.state.contactData.map((item: any, i: any) => {
            const createdAt = new Date(item.attributes.created_at);
            const day = String(createdAt.getDate()).padStart(2, '0');
            const month = String(createdAt.getMonth() + 1).padStart(2, '0');
            const year = createdAt.getFullYear();
            const formattedDate = `${day}-${month}-${year}`;

            return (
              <TableRow key={i}>
                <TableCell style={WebStyles.tablecell}>
                  <span style={WebStyles.tabhead}>{item.id}</span>
                </TableCell>
                <TableCell style={{
                  ...WebStyles.tablecell,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  <span style={WebStyles.tabhead}>
                    {item.attributes.reason.slice(0, 40)}{item.attributes.reason.length < 40 ? "" : "..."}
                  </span>
                </TableCell>
                <TableCell style={WebStyles.tablecell}>
                  <span style={WebStyles.tabhead}>{item.attributes.status === false ? "Ongoing" : "Close"}</span>
                </TableCell>
                <TableCell style={WebStyles.tablecell}>
                  <span style={WebStyles.tabhead}>{formattedDate}</span>
                </TableCell>
                <TableCell style={{ ...WebStyles.tablecell, color: '#007aff', cursor: 'pointer' }}>
                  <span style={{ ...WebStyles.tabhead, color: '#007aff', cursor: 'pointer' }} data-test-id='handle_view' onClick={() => this.handleView(item.id)}>View</span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      );
    }

    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={6} align="center" style={{ borderBottom: "none" }}>
            No Query has been created
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  // In your render() method, call this.renderTable() to use the result.

  // Customizable Area End

  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
      <Grid container>
        <Grid item sm={12} lg={12} md={12} xs={12}>
          <div style={WebStyles.needBlock}>
            <h2 style={WebStyles.needheading}>Contact Fudu</h2>
            <Grid style={WebStyles.wrapperSubscreption}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #EBEBEB' }}>
                <Box style={{ display: 'flex', marginLeft: '30px', marginTop: '20px' }}>
                  <Box style={WebStyles.alltickets} >
                    All Query
                  </Box>
                </Box>
                <Box style={{ marginRight: '30px', marginTop: '17px' }}>
                  <button
                    style={WebStyles.btncreatequery}
                    data-testid="createquery"
                    onClick={this.createQuery}
                  >
                    Create Query
                  </button>
                </Box>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={WebStyles.tablecell}>
                      <span style={WebStyles.tabhead}>Query ID</span>
                    </TableCell>
                    <TableCell style={WebStyles.tablecell}>
                      <span style={WebStyles.tabhead}>Reason</span>
                    </TableCell>
                    <TableCell style={WebStyles.tablecell}>
                      <span style={WebStyles.tabhead}>Status </span>
                    </TableCell>
                    <TableCell style={WebStyles.tablecell}>
                      <span style={WebStyles.tabhead}>Created Date </span>
                    </TableCell>
                    <TableCell style={WebStyles.tablecell}>
                      <span style={WebStyles.tabhead}>Action </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {this.renderTable()}
              </Table>
            </Grid>
          </div>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}
export default ContectFuduData;
// Customizable Area End
