import React from "react";

import {
  Grid,
  // Customizable Area Start
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import VenueSettingController, {
  Props,
} from "./VenuesettingController.web";
import InternalTeamEmails from "./InternalTeamEmails.web";
import GuestEmailSMS from "./GuestEmailSMS.web";
import ReservationVenue from "./ReservationVenue.web";
import ChargesVenue from "./ChargesVenue.web";
import TransactionalEmail from "./TransactionalEmail.web";

export default class VenueSettings extends VenueSettingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  tabCondition = () => {
    let selectedComponent = null;

    if (this.state.selectedTab === 0) {
      selectedComponent = <InternalTeamEmails navigation={"navigation"} id={"id"} />;
    } else if (this.state.selectedTab === 1) {
      selectedComponent = <GuestEmailSMS navigation={"navigation"} id={"id"} />;
    } else if (this.state.selectedTab === 2) {
      selectedComponent = <ReservationVenue navigation={"navigation"} id={"id"}  patchAPIData={this.updatedReservationData}/>;
    } else if (this.state.selectedTab === 3) {
      selectedComponent = <ChargesVenue navigation={"navigation"} id={"id"} />;
    }
    return selectedComponent;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: 'auto', overflowY: 'auto' }}>
          <Grid item sm={12} style={{ height: 'auto', overflowY: 'auto' }}>
            <div style={{marginTop:'-14px'}}>
              <h2>Settings &#62; Venue Settings</h2>
              <Grid style={webStylevenue.firstgridStyle}>
                <div style={webStylevenue.firstdiv}>
                  <h3 style={{ paddingTop: '5px' }}>Venue settings</h3>
                  <Button variant="contained" style={webStylevenue.savebtn} onClick={this.updateAPICall} data-test-id="save_button">
                    Save
                  </Button>

                </div>
                <Grid item xs={12} sm={12} lg={8} style={{ display: 'flex', gap: '30px',overflow:'auto' }}>
                  <Typography
                    variant="body1"
                    style={this.state.selectedTab === 0 ? webStylevenue.tabletabSelected : webStylevenue.tabletab}
                    data-test-id="InternalTeamEmails"
                    onClick={() => this.handleTabs(0)}
                  >
                    Internal Team Emails
                  </Typography>
                  <Typography
                    variant="body1"
                    style={this.state.selectedTab === 1 ? webStylevenue.tabletabSelected : webStylevenue.tabletab}
                    data-test-id="GuestEmail"
                    onClick={() => this.handleTabs(1)}
                  >
                    Guest Email/SMS
                  </Typography>
                  <Typography
                    variant="body1"
                    style={this.state.selectedTab === 2 ? webStylevenue.tabletabSelected : webStylevenue.tabletab}
                    data-test-id="Reservations"
                    onClick={() => this.handleTabs(2)}
                  >
                    Reservations
                  </Typography>
                  <Typography
                    variant="body1"
                    style={this.state.selectedTab === 3 ? webStylevenue.tabletabSelected : webStylevenue.tabletab}
                    data-test-id="Charges"
                    onClick={() => this.handleTabs(3)}
                  >
                    Charges
                  </Typography>
                </Grid>
              </Grid>
              <Grid style={webStylevenue.gridStyle}>

                {this.tabCondition()}

              </Grid>
              {
                this.state.selectedTab === 1 && <Grid style={webStylevenue.gridStyle}>
                  <TransactionalEmail navigation={"navigation"} id={"id"} />
                </Grid>
              }
            </div>

          </Grid>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStylevenue = {
  firstgridStyle: {
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.12) 5px 2px 8px",
    padding: "0px 30px",
    backgroundColor: "#FFFFFF",
    height: 'auto',
    margin: "25px 13px 0 0",
  },
  gridStyle: {
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.12) 5px 2px 8px",
    padding: "10px 30px",
    backgroundColor: "#FFFFFF",
    height: 'auto',
    margin: "25px 13px 0 0",
  },
  boxdiv: {
    paddingLeft: '20px',
    paddingTop: '15px',
    width: '72%',
    maxWidth: '700px'
  },
  tabletab: {
    marginTop: '12px',
    color: '#4B5563',
    cursor: "pointer",
  },
  tabletabSelected: {
    color: "#096FFF",
    borderBottom: ' 2px solid #096FFF',
    display: "inline-block",
    padding: "12px 0px",
    cursor: "pointer",
    fontWeight: 600
  },
  firstdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  savebtn: {
    backgroundColor: "rgba(9, 111, 255, 1)",
    borderRadius: "5px",
    width: "120px",
    height: "40px",
    color: "white"
  },


}

// Customizable Area End

