import React from 'react'

// Customizable Area Start
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { imgSplashWeb } from './assets'
// Customizable Area End


// Customizable Area Start
const Styles = (theme: any) => ({
    root: {
        width: "100%",
        height: "100%",
        backgroundColor: "#096FFF"
    },
    img: {
        width: "auto",
        height: "auto",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto"
    }
});
// Customizable Area End

// Customizable Area Start

class Splashscreen extends React.Component<any, any>{

    render() {
        const { classes } = this.props;
        return (
            <>
                <div className={classes.root}>
                    <img className={classes.img} src={imgSplashWeb} alt='logo' />
                </div>
            </>
        )
    }
}

{/* @ts-ignore */ }
Splashscreen.propTypes = {
    classes: PropTypes.object.isRequired,
};
{/* @ts-ignore */ }
export default withStyles(Styles)(Splashscreen);
// Customizable Area End
