import React from "react";

import {
  Grid,
  // Customizable Area Start
  Typography,
  Button,
  Chip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#6200ee",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import DietaryRestrictionsController, {
  Props,
} from "./DietaryRestrictionsController.web";

export default class DietaryRestrictions extends DietaryRestrictionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: 'auto' }}>
          <Grid item sm={12} style={{ height: 'auto' }}>
            <div style={{ marginTop: '-14px' }}>
              <h2>Settings &#62; Dietary restrictions</h2>
              <Grid style={webStyleDietary.gridStyle}>
                <div style={webStyleDietary.boxdiv}>
                  <h3>Dietary restrictions</h3>
                  <Typography style={webStyleDietary.heading2}>Add dietary restrictions</Typography>
                  <form onSubmit={this.DietaryRestricationAPI} data-test-id='add_btn'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={8}>
                        <input
                          type='text'
                          data-test-id='restriction'
                          placeholder="Name of restriction"
                          name="restriction"
                          value={this.state.restriction}
                          onChange={this.handlerestrictionChange}
                          style={webStyleDietary.formInput}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Button style={webStyleDietary.btnchange as any} type="submit" >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Grid container style={webStyleDietary.featureMenu} spacing={4}>
                    {this.state.tagsData.map((val: any) => {
                      return (
                        <Grid item key={val.id}>
                          <Chip style={webStyleDietary.tagChipRage as React.CSSProperties} label={val.name} data-test-id='deleteTag_click' onDelete={() => this.DeleteDietaryRestricationTag(val.id)} />
                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
              </Grid>
            </div>

          </Grid>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyleDietary = {
  gridStyle: {
    borderRadius: "8px",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    backgroundColor: "#FFFFFF",
    margin: "25px 13px 0 0",
    padding: "10px 30px",
    height: '100vh'
  },
  visiblepass: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  boxdiv: {
    paddingLeft: '20px',
    paddingTop: '15px',
    maxWidth: '700px',
    width: '72%'
  },
  featureMenu: {
    marginTop: "17px"
  },
  tagChipRage: {
    backgroundColor: "#F9FAFB",
    fontSize: "14px",
    fontWeight: "400"
  },
  btnchange: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    height: "38px",
    borderRadius: "8px",
    fontSize: "15px",
    marginTop: '11px',
    marginBottom: '15px',
    minWidth: '120px',
    textTransform: "none",
    marginLeft: '25px'
  },
  heading2: {
    fontFamily: 'Inter',
    fontWeight: 500,
    marginTop: '10px'
  },
  formInput: {
    background: 'rgb(244, 247, 249)',
    width: "100%",
    padding: '8px',
    height: "40px",
    borderRadius: "8px",
    margin: "10px 0px",
    fontSize: "14px",
    color: '#001F49',
    outline: 'none',
    border: "1px solid #E5E7EB",
  },
}

// Customizable Area End

