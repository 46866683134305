import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Grid,
    Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import GoogleMapReact from 'google-map-react';
// Customizable Area End

// Customizable Area Start
export const StyledButton:any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "324px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);

export const StyledButton2:any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "324px",
            textTransform: "none",
            fontSize: "16px",
            '&:hover': {
                opacity: "0.7",
            },
        },
    }),
)((props: any) => <Button {...props} />);

// Customizable Area End

import SignUpLocationController, {
    Props
} from "./SignUpLocationController";

export default class LocationSignUp extends SignUpLocationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyle.root}>
                    {/* @ts-ignore */}
                    <Paper style={webStyle.formdiv}>
                        <Grid container spacing={2} direction="column" alignItems='center'>
                            <Grid item xs={12} >
                                <div style={webStyle.mapContainer}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "" }}
                                        options={{ fullscreenControl: false, keyboardShortcuts: false }}
                                        center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                        zoom={this.state.zoom}
                                        onGoogleApiLoaded={({ map, maps }: any) => this.handleMarkerPosition(map, maps)} />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={webStyle.welcomeFormDiv}>
                                <Grid container spacing={2}>
                                <Grid item>
                                        <Typography variant='body1' style={{fontSize: "24px", fontWeight: "bold", textAlign:"center",
                                           marginLeft: "55px"
                                         }}>
                                            Enable your location
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft:"15px"}}>
                                        <Typography variant='body1' style={{fontWeight:600}}>
                                           This app requires that location services are 
                                           </Typography>
                                        <Typography variant='body1' style={{fontWeight:600}}> 
                                           turned on your device and for this app. You must 
                                           </Typography>
                                           <Typography variant='body1' style={{fontWeight:600}}>    
                                           enable them in Settings before using this app.
                                        </Typography>
                                    </Grid>
                                    {!this.state.locationFlag &&
                                        <Grid item style={{marginLeft:"15px"}}>
                                            <StyledButton data-test-id="allowBtn" onClick={() => this.getUserLocation()} variant="contained">
                                                Allow only while using the app
                                            </StyledButton>
                                        </Grid>}
                                    {this.state.locationFlag &&
                                        <Grid item>
                                            <StyledButton data-test-id="confirmBtn"  style={webStyle.confimrBtn} onClick={this.submitLocation} variant="contained">
                                                Confirm
                                            </StyledButton>
                                        </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </ThemeProvider>
        )
        // Customizable Area End

    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        body1: {
            fontWeight: 400,
            fontSize: "14px",
            textAlign: "center"
        }
    }
});

const webStyle = {
    formdiv: {
        maxWidth: "488px",
        margin: "0 auto",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 6px 14px 0px",
        borderRadius: "40px",
        minHeight: "700px",
        position: "relative",
        overflow: "hidden"
    },
    welcomeFormDiv: {
        width: "365px",
        height: "auto",
        paddingTop: "10px"
    },
    mapContainer: {
        height: '406px',
        width: '488px',
        boxShadow: "0px 15px 10px -15px rgba(17, 12, 46, 0.15)"
    },
    root: {
        padding: "50px 0",
        overflowX: "hidden"
    },
    confimrBtn: {
        marginTop: "50px",
        marginLeft: "18px"
    }
}
// Customizable Area End
