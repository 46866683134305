// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  FormLabel,
  Checkbox,
  ListItemText,
  FormControlLabel

} from "@material-ui/core";
import Box from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { BluePlusImg, RedDeleteImg } from "./assets"
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

// Customizable Area Start
import ProfileMenuUploadController, { Props } from "./ProfileMenuUploadController.web";
import "./ProfileMenuUpload.css"
// Customizable Area 


export default class ProfileMenuUpload extends ProfileMenuUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {

    const { radioValue, ItemName, units, quantity, price, Itemtype, description,allIndex,selectedDishes,cuisine} = this.state;
    const hasEmptyFields = !radioValue || !ItemName || allIndex.some((d:any,i:any)=>!units[i]) ||
    allIndex.some((d:any,i:any)=>!quantity[i]) || allIndex.some((d:any,i:any)=>!price[i]) || !Itemtype || !description || !selectedDishes || selectedDishes.length === 0 || !cuisine;

    return (
      <React.Fragment>
        <Dialog
          maxWidth="lg"
          fullWidth
          onClose={this.handleClose}
          open={this.props.show}
          aria-labelledby="max-width-dialog-title"
        >
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            style={{ zIndex: 9999 }}
            rtl={false}
          />
          <DialogTitle id="max-width-dialog-title" style={{ padding: "0" }}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <IconButton
                aria-label="close"
                style={webPage.closeButton}
                onClick={this.handleClose}
              >
                <CancelIcon style={{ color: "#096FFF" }} />
              </IconButton>
              <Box style={{ textAlign: "center" }}>
                <Typography variant="h6"
                  style={{ fontFamily: "Inter", fontWeight: "bold", fontSize: "32px", color: "#111827", marginTop: "-10px" }}>
                  Menu
                </Typography>
                <Typography variant="body2" style={{
                  fontFamily: "Inter", fontSize: "24px", color: "#4B5563", fontWeight: 500, marginTop: "5px",
                  marginBottom: "15px"
                }}>
                  Upload menu item
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Card style={webPage.postLinkModal}>

              <Grid
                container
                 className="profile-image"
              >
                <Grid item xs={12} lg={5}>
                          <Box
                          >
                            <Typography
                              style={{
                                alignSelf: "start",
                                marginBottom: "14px",
                                color: "#9CA3AF"
                              }}
                            >
                              Attach Image
                            </Typography>
                            <Box
                              style={webPage.mainMenuImgBox}
                            >
                              {this.state.imageFile === "" ? (
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center", 
                                    border: "2px dashed #096FFF",
                                    height: "100%",
                                    width: "100%" ,
                                    background: "#ECF0FF",
                                    gridGap: "5px",
                                    borderRadius: "10px"
                                  }}
                                >
                                  <Button
                                    component="label"
                                    style={webPage.Postbtn}
                                  >
                                    +
                                    <input
                                      type="file"
                                      accept="image/*"
                                      data-test-id='inputchange'
                                      id="image-upload"
                                      hidden
                                      onChange={this.ReaderMenuImg}
                                    />
                                  </Button>
                                </Box>
                              ) : (
                                <Box style={{border: "none",borderRadius: "10px"}}>
                                  <img
                                    src={URL.createObjectURL(
                                      this.state.imageFile
                                    )}
                                    style={{
                                      borderRadius: "8px",
                                      objectFit: "cover",
                                      height: "100%",
                                      width: "100%",
                                      padding: 0
                                    }}
                                  />

                                </Box>
                              )}
                            </Box>
                    
                            <Box style={{ display: "flex" }}>
                              <Box style={{ marginTop: "10px" }}>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#9CA3AF",
                                    fontFamily: "inter"
                                  }}
                                >
                                  Supported Image type :{""}
                                  <span style={{ color: "#4B5563" }}>.jpg, .png</span>
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#9CA3AF",
                                    fontFamily: "inter"
                                  }}
                                >
                                  Maximum image size :{" "}
                                  <span style={{ color: "#4B5563" }}>5MB per image</span>
                                </Typography>
                              </Box>
                             {this.state.imageFile &&  
                              <Box style={{ marginLeft: "5px" }}>
                                <Button
                                  component="label"
                                  data-test-id="editImg"
                                  style={{ marginRight: "10px", marginTop: "-5px" }}
                                >
                                  <EditIcon color="primary" />
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="image-upload"
                                    hidden
                                    onChange={this.ReaderMenuImg}
                                  />
                                </Button>

                                <img src={RedDeleteImg} style={{marginTop: "20px",marginLeft: "-5px",cursor: "pointer"}}
                                  onClick={() => this.setState({ imageFile: "" })}
                                />
                              </Box>
                              }
                            </Box>
                          </Box>
                        </Grid>
                <Grid item xs={12} lg={7}>
                  <Grid container spacing={1}>
                    <Grid item lg={12}>
                      <FormLabel component="legend" style={webPage.formlabel}>
                        Category
                      </FormLabel>
                      <FormControl component="fieldset">
                        <RadioGroup
                          name="radioValue"
                          id="rdo"
                          value={this.state.radioValue}
                          onChange={(e: any) => {
                            this.setState({ radioValue: e.target.value })
                          }}
                          // style={{
                          //   display: "flex",
                          //   flexDirection: "row",
                          //   gap: "77px",
                          // }}
                          className="categoryMenuRadio"
                        >
                          <FormControlLabel value="breakfast" control={<Radio color="primary" />}
                            label="BreakFast" />
                          <FormControlLabel value="lunch" control={<Radio color="primary" />}
                            label="Lunch" />
                          <FormControlLabel value="dinner" control={<Radio color="primary" />}
                            label="Dinner" />
                          <FormControlLabel value="all_day" control={<Radio color="primary" />}
                            label="All Day" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item lg={12}>
                      <FormLabel component="legend" style={webPage.formlabel}>
                        Item Name
                      </FormLabel>
                      <FormControl style={webPage.messageField}>
                        <TextField
                          data-test-id="txtInputConfirmPassword"
                          variant="outlined"
                          size="small"
                          id="ItemName"
                          placeholder="Enter Item Name"
                          type="text"
                          style={{
                            fontFamily: "Verdana",
                            backgroundColor: "#F9FAFB"
                          }}
                          name="ItemName"
                          fullWidth
                          value={this.state.ItemName}
                          onChange={(e: any) => {
                            this.setState({ ItemName: e.target.value })
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item lg={12}>
                      {this.state?.allIndex?.map((_fields: any, allId: any) => {
                        return (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          xs={12}
                          spacing={2}
                          style={{ marginTop: "10px" }}
                          key={allId}
                        >
                            <Grid item lg={3} xs={12} className="mobMb15">
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Unit
                              </FormLabel>
                              <FormControl variant="outlined">
                               { allIndex.some((d:any,i:any)=>!units[0]) && 
                                <InputLabel htmlFor="unitsa" shrink={false}>
                                  Enter unit type
                                </InputLabel>
                               }
                                <Select
                                  style={{
                                    border: "1px solid gray",
                                    height: "38px",
                                    width: "193px",
                                    marginTop: "7px",
                                    borderRadius: "4px",
                                    background: "#F9FAFB",
                                    paddingLeft: "10px"
                                  }}
                                  id="unitsa"
                                  label="Unit"
                                  value={units[allId]}
                                  onChange={(e:any) => this.handleChangeUnitType(e, allId)} fullWidth 
                                >
                                  {DiscountPercentage.map((value, index) => (
                                    <MenuItem value={value} key={index}>
                                      {value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item lg={3} xs={12} 
                              style={{ marginTop: "-2px" }}  className="mobMb15">
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Quantity
                              </FormLabel>
                              <FormControl
                                style={webPage.smallMessageField} 
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  id="quantity"
                                  placeholder="Enter qty"
                                  // type="number"
                                  style={{
                                    fontFamily: "inter",
                                    backgroundColor: "#F9FAFB"
                                  }}
                                  value={quantity[allId]}
                                  onChange={(e) => this.handleChangeQuantity(e, allId)}
                                />
                              </FormControl>
                            </Grid>
                        
                            <Grid item lg={3} xs={12} style={{ marginTop: "-2px" }} className="mobMb15">
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Price
                              </FormLabel>
                              <FormControl
                                style={webPage.smallMessageField} 
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  id="price"
                                  placeholder="Enter Price"
                                  // type="number"
                                  style={{
                                    fontFamily: "inter",
                                    backgroundColor: "#F9FAFB"
                                  }}
                                  name="price"
                                  value={price[allId]}
                                  onChange={(e) => this.handleChangePrice(e, allId)}
                                />
                              </FormControl>
                            </Grid>
                          <Grid item xs={1}>
                             {allId!== 0 &&
                              <ClearIcon  
                               style={{
                                cursor:"pointer", 
                                marginTop:"30px"
                              }}
                              id='remove'
                               onClick={() => 
                                this.handleRemoveInput(allId)} 
                                />
                              }   
                          </Grid>
                        </Grid>
                      )})}
                    </Grid>
                    <Grid item lg={12}>
                      <Box style={{ 
                        display: "flex", 
                        marginTop: "5px" }}
                       >
                        <div 
                        id="divs"
                          style={{
                          display:"flex", cursor: "pointer",gap:"8px"}}
                          onClick={this.handleAddInput}
                        >
                        <div style={{width:"20px",height:"20px",backgroundColor: "#ECF0FF", borderRadius: "5px",border:"none"}}>
                        <img src={BluePlusImg}
                           style={{ marginRight: "10px", marginTop: "4px",marginLeft:"4px"}}/>
                        </div>     
                        <Typography style={{ 
                                    color: "#096FFF", 
                                    textDecoration: "underLine" 
                                    }}
                        >
                          Add variant
                        </Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item lg={12}>
                      <Grid item >
                        <FormLabel component="legend" style={webPage.formlabel}>
                          Item Type
                        </FormLabel>
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="Itemtype"
                            id='rdotwo'
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                            value={this.state.Itemtype}
                            onChange={(e: any) => {
                              this.setState({ Itemtype: e.target.value })
                            }}
                          >
                            <FormControlLabel value="veg" control={<Radio color="primary" />}
                              label=" Veg" />
                            <FormControlLabel value="non_veg" control={<Radio color="primary" />}
                              label="Non-veg" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center"
                      style={{marginBottom:"10px",paddingRight: "70px",paddingLeft:"3px"}}
                      spacing={2}
                    >
                    <Grid item xs={6}>
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Cuisine
                              </FormLabel>
                              <FormControl variant="outlined" style={{width:"100%"}}>
                              { !cuisine && 
                                <InputLabel htmlFor="unitsa" shrink={false}>
                                  Select cuisine
                                </InputLabel>
                               }
                                <Select
                                  style={{
                                    marginTop: "7px",
                                    border: "1px solid gray",
                                    height: "38px",
                                    background: "#F9FAFB",
                                    paddingLeft: "10px",
                                    borderRadius: "4px",
                                  }}
                                  label="Unit"
                                  value={this.state.cuisine}
                                  data-test-id="selectcusines"

                                  onChange={this.handleChangeCuisines}
                                >
                                  {Array.isArray(this.state.allCuisineData) && this.state.allCuisineData?.map((value:any, index:any) => (

                                    <MenuItem value={value.name} key={index}>{value.name}</MenuItem>
                                    
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={6} >
                              <FormLabel component="legend" style={{fontWeight:"bold"}}>
                              Tags <span style={{ fontSize: "10px", fontWeight:"bold" }}>(multiple selection added)</span>
                              </FormLabel>
                              <FormControl variant="outlined" style={{width:"100%"}}>
                              { !selectedDishes || selectedDishes.length === 0 && 
                                <InputLabel htmlFor="unitsa" shrink={false}>
                                  Select tags
                                </InputLabel>
                               }
                              <Select
                                 labelId="name-label"
                                 data-test-id="selects"
                                 id="selects"
                                 multiple                                 
                                 value={this.state.selectedDishes}
                                 onChange={this.handleDishChange}
                                 renderValue={(selected:any) => (
                                  <div>
                                    {selected.map((value:any) => (
                                      <span key={value}>{value}, </span>
                                    ))}
                                  </div>
                                )}
                                style={{
                                  border: "1px solid gray",
                                  height: "38px",
                                  marginTop: "7px",
                                  borderRadius: "4px",
                                  background: "#F9FAFB",
                                  paddingLeft: "3px"
                                }}
                               >
                             {this.state.allSubCategoryData?.map((name:any) => (
                              <MenuItem key={name.id} value={name.attributes?.title}>
                              <Checkbox
                                checked={this.state.selectedDishes.indexOf(name.attributes?.title) > -1}
                                color="primary"
                               />
                              <ListItemText primary={name.attributes?.title} />

                              </MenuItem>
                            ))}
                            </Select>
                            </FormControl>
                  </Grid>
                  <Grid item xs={1}/>
                    </Grid>
                    <Grid item lg={12}>
                      <FormLabel component="legend" style={webPage.formlabel}>
                        Item Description
                      </FormLabel>
                      <FormControl style={webPage.messageField}>
                        <TextField
                          variant="outlined"
                          size="small"
                          style={{
                            fontFamily: "inter",
                            backgroundColor: "#F9FAFB"
                          }}
                          placeholder="Enter Item Description"
                          type="text"
                          id="desc"
                          name="description"
                          value={this.state.description}
                          onChange={(e: any) => {
                            this.setState({ description: e.target.value })
                          }}
                          multiline
                          rows={3}
                          maxRows={3}
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      style={{
                        marginTop: "10px",
                        paddingRight: "50px"
                      }}
                      xs={12}
                      spacing={2}
                    >
                      <Grid item xs={12} lg={6}>
                        <Button
                          variant="contained"
                          style={{
                            height: "54px",
                            width: "100%",
                            marginLeft: "2px",
                            background: "transparent",
                            color: "#096FFF",
                            borderRadius: "10px",
                            boxShadow: "none",
                            border: "1px solid #096FFF",
                            textTransform: "none",
                          }}
                          data-test-id="close"
                          onClick={this.props.handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Button
                          data-test-id="AddMenuCall"
                          style={{
                            width: "100%",
                            background: hasEmptyFields? "#89CFF0" : "#096FFF",
                            borderRadius: "10px",
                            color: "#fff",
                            height: "54px",
                            textTransform: "none"
                          }}
                          variant="contained"
                          type="submit"
                          color="primary"
                          onClick={this.createMenuApiCall}
                          disabled={hasEmptyFields || this.state.isDisabled}                          >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </DialogContent>
        </Dialog>

      </React.Fragment>

    );
  }
  // Customizable Area End
}
// Customizable Area Start


const DiscountPercentage = ["gms", "kg", "portion", "quantity"]

const webPage = {
  butnStyle: {
    width: "90%",
    background: "#096FFF",
    borderRadius: "10px",
    color: "#fff",
    height: "54px",
    marginLeft: "-25px",
    textTransform: "none"
  },
  cancelbutnStyle: {
    height: "54px",
    width: "90%",
    marginLeft: "5px",
    background: "transparent",
    color: "#096FFF",
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid #096FFF",
    textTransform: "none"
  },
  mainMenuImgBox: {
    width: "85%",
    borderRadius: "10px",
    height: 310, 
    padding: "0px"
  },
  messageField: {
    width: "90%",
    background: "#e4e4e4",
    marginTop: "10px",
  },

  postLinkModal: {
    marginBottom: "14px",
    border: "1px solid #B6B5BB",
    borderRadius: "10px",
    boxShadow: "none",
  },

  smallMessageField: {
    background: "#e4e4e4",
    marginTop: "10px",
    width: "193px",
  },
  Postbtn: {
    width: "150px",
    color: "#096FFF",
    background: "transparent",
    border: "none",
    fontSize: "50px",
  },
  diaHeader: {
    flexDirection: "column",
    display: "flex",
  } as React.CSSProperties,

  closeButton: {
    alignSelf: "end",
  },

  formlabel: {
    color: "#111827",
    fontSize: "16px",
    fontFamily: "inter",
    fontWeight: 600,
  },

  formlabel1: {
    marginBottom: "8px",
    fontSize: "16px",
    fontFamily: "inter",
    fontWeight: 600,
    color: "#111827",
  }

};

// Customizable Area End


