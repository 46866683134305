// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  IsopenModal: boolean,
  servingTimeDataReservations: any,
  servingTimeDataPickup: any,
  servingTimeDataDelivery: any,
  servingTimeDataDrive: any,
  modalType: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditTagsControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  getReservationsTimeDataCallID: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      IsopenModal: false,
      servingTimeDataReservations: [],
      servingTimeDataPickup: [],
      servingTimeDataDelivery: [],
      servingTimeDataDrive: [],
      modalType: ''
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getReservationsTimeDataCallID) {

      this.setState({
        servingTimeDataReservations: this.filterAndFormatData("reservation", responseJson.data), servingTimeDataPickup: this.filterAndFormatData("pick_up", responseJson.data),
        servingTimeDataDelivery: this.filterAndFormatData("delivery", responseJson.data), servingTimeDataDrive: this.filterAndFormatData("drive_through", responseJson.data)
      })
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getReservationsTimeData();
  }
  formateDateStr = (dateString: string) => {
    const date = new Date(dateString);
    const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    const adjustedDate = new Date(utcTime + (5.5 * 60 * 60000)); 

    const hours = adjustedDate.getHours();
    const minutes = adjustedDate.getMinutes();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; 
    const formattedMinutes = String(minutes).padStart(2, '0'); 

    const timeString = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return timeString;
  }
  filterAndFormatData = (category: string, data: any[]) => {

    const filteredData = data?.filter((day: { attributes: { category: string; }; }) => day?.attributes?.category === category);
    const formattedData = filteredData.map((day: { id: string, attributes: { serving_hours: { data: any[]; }; day: any; status: string }; }) => {
      const servingHours = day?.attributes?.serving_hours?.data?.map(hour => {
        return {
          hour_id: hour.id,
          openingTime: this.formateDateStr(hour?.attributes?.opening_time),
          openingTimeStr: hour?.attributes?.opening_time,
          closingTime: this.formateDateStr(hour?.attributes?.closing_time),
          closingTimeStr: hour?.attributes?.closing_time
        };
      });

      return {
        day_id: day.id,
        order_id: configJSON.orderList[`${day.attributes.day}`],
        status: day.attributes.status,
        day: day.attributes.day,
        servingHours: servingHours
      };
    });

    return formattedData;
  };
  IsReservationOpanModal = (type: any) => {
    this.setState({ IsopenModal: true, modalType: type })
  }
  IsReservationCloseModal = () => {
    this.setState({ IsopenModal: false, modalType: "" })
  }

  getReservationsTimeData = async () => {
    const headergetData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const req = apiCall({
      httpBody: {},
      header: headergetData,
      url: 'bx_block_location/serving_days',
      httpMethod: 'GET'
    })
    this.getReservationsTimeDataCallID = req?.messageId;
    runEngine.sendMessage(req?.id, req);
  }

  getModalData = () => {
    if (this.state.modalType.includes("Reservations")) {
      return this.state.servingTimeDataReservations
    } else if (this.state.modalType.includes("Pick")) {
      return this.state.servingTimeDataPickup
    } else if (this.state.modalType.includes("Delivery")) {
      return this.state.servingTimeDataDelivery
    } else if (this.state.modalType.includes("Drive")) {
      return this.state.servingTimeDataDrive
    } else {
      return [];
    }
  }
  // Customizable Area End
}
