import React from "react";
// Customizable Area Start
import { Grid, Dialog, Typography, Switch } from "@material-ui/core";
import EditReservationServingController, { Props, configJSON } from "./EditReservationServingController";
import { close, DeleteIcon } from "./assets";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { withStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
const SwitchStyle = withStyles((theme) => ({
  root: {
    width: 42,
    padding: 0,
    height: 26,
    margin: 0
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      color: theme.palette.common.white,
      transform: "translateX(16px)",
      "& + $track": {
        opacity: 1,
        backgroundColor: "#52d869",
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      border: "6px solid #fff",
      color: "#52d869"
    }
  },

  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    opacity: 1,
    backgroundColor: "#B4BBC6",
    borderRadius: 26 / 2,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }: any) => {

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,

        thumb: classes.thumb,

        track: classes.track,
        checked: classes.checked
      }}{...props} />);
});

type Person = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday"
// Customizable Area End

export default class EditReservationServing extends EditReservationServingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { AllSwitch } = this.state;
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.props.show}
        >
          <section style={{ width: '100%', height: '100vh' }} >
            <div style={Webstyle.iconDiv}>
              <img src={close} alt="" data-test-id="close_icon" style={{ cursor: 'pointer' }} onClick={() => { this.props.handleClose() }} />
            </div>
            <Grid
              container
              style={{ marginTop: '-23px', padding: '0px 30px 30px 30px' }}
            >
              <h3 style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{this.props.modalType} Hours</h3>
              <Grid item lg={12} sm={12} xs={12} style={{ border: '1px solid #d9d6d6', borderRadius: '20px', padding: '15px' }}>
                <Grid lg={6} md={6} xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <Typography style={{ display: "flex", paddingRight: "7rem" }}>
                    <span><QueryBuilderIcon /></span>
                    <span style={{ marginLeft: '15px', fontWeight: 600, fontSize: "17px" }}>Reservation Hours</span>
                  </Typography>
                </Grid>
                {(configJSON.reservationServingDay as Person[]).map((day: Person, index) => (
                  <>
                    <Grid container style={{ display: "flex", justifyContent: "center", paddingTop: '15px', marginTop: '12px' }} key={index}>
                      <Grid item lg={2} md={2} xs={12}>
                        <Typography variant="h6" color="initial" style={{ display: "flex", justifyContent: "start" }}>
                          {day}
                        </Typography>
                      </Grid>
                      <Grid item lg={7} md={7} xs={12}  >
                        <div style={{ display: 'flex', gap: '30px' }}>
                          <SwitchStyle
                            data-test-id="switch"
                            checked={AllSwitch[day]}
                            onChange={() => this.handleSwitchToggle(day)}
                          />
                          <span style={{ marginTop: '5px' }}>
                            {AllSwitch[day] === true ? "Open" : "Closed"}
                          </span>
                        </div>
                        {AllSwitch[day] === true &&
                          this.state.SelectTime[day].map((fields: any, key: number) => (
                            <Grid container style={Webstyle.timeConatiner} key={fields.id}>
                              <Grid item xs={12} sm={5} lg={5} style={{ ...Webstyle.timeDiv }}>
                                <input
                                  type="time"
                                  name="startTime"
                                  value={fields.value.start}
                                  style={Webstyle.Timeselect}
                                  data-test-id="startTime"
                                  onChange={(e) => this.handleChangeTime(e, day, key, 'startTime')}
                                />
                              </Grid>
                              <Grid item xs={12} sm={1} lg={1}>
                                <p style={{ ...Webstyle.timeDiv, justifyContent: ' center' }}> to </p>
                              </Grid>
                              <Grid item xs={12} sm={5} lg={5} style={{ ...Webstyle.timeDiv }}>
                                <input type="time" name="endTime" style={Webstyle.Timeselect}
                                  data-test-id="endTime"
                                  value={fields.value.end}
                                  onChange={(e) => this.handleChangeTime(e, day, key, 'endTime')} />
                                {fields.id !== 0 && <img src={DeleteIcon} style={{ height: '25px', width: '25px', cursor: 'pointer' }} data-test-id="DeleteIcon" onClick={() => this.deleteField(day, key)} />}
                              </Grid>
                            </Grid>
                          ))}
                        {AllSwitch[day] === true && (
                          <Grid item style={{ marginTop: '7px' }}>
                            <Typography style={Webstyle.addhour} data-test-id="addField" onClick={() => this.addHours(day)}> <AddIcon />Add Hours</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ))}
                <Grid style={Webstyle.btnDiv}>

                  <button style={Webstyle.btntransparent} data-test-id="cancel_button" onClick={() => this.props.handleClose()}>
                    Cancel
                  </button>

                  <button style={Webstyle.btntheme} data-test-id="submit" onClick={this.PostReservationServingDayData}>
                    Save
                  </button>

                </Grid>
              </Grid>
            </Grid>
          </section>
        </Dialog >
      </>
      // Customizable Area End
    );
  }
}

const Webstyle = {
  dayGrid: {
    display: "flex",
    paddingTop: '30px',
    gap: '70px',
    justifyContent: 'center'
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px 15px'
  },
  timeConatiner: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: '5px'
  },
  timeDiv: {
    display: "flex",
    alignItems: "center",
  },
  btntheme: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    marginTop: '10px',
    cursor: 'pointer'
  },
  addhour: {
    color: 'rgb(18, 117, 255)',
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    maxWidth: '110px'
  },
  btntransparent: {
    background: "#fff",
    border: "1px solid #096fff",
    color: "#096fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    marginTop: '12px',
    cursor: 'pointer'
  },
  Timeselect: {
    height: "40px",
    margin: "2px",
    borderRadius: "8px",
    paddingLeft: "14px",
    fontSize: "14px",
    minWidth: '200px',
    border: ' 1px solid #d0d0d0'
  },
  btnDiv: {
    display: 'flex',
    flexFlow: 'wrap',
    marginBottom: '20px',
    marginTop: '30px',
    gap: '40px',
    justifyContent: "center"
  }
}
// Customizable Area End


