import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  handleClose: () => void;
  show: boolean;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  email: any;
  isLoading: boolean;
  BookingOpen: boolean;
  radioValue: string;
  Itemtype: string;
  ItemName: string;
  unitType: string;
  units: string[];
  quantity: any;
  price: any;
  unitErrors: boolean,
  quantityErrors: boolean,
  priceErrors: boolean,
  description: string;
  imageFile: any | ArrayBuffer | null | unknown | File;
  createdMenuData: any;
  allSubCategoryData: any;
  allCuisineData: any;
  createMenuErrorData: any;
  createMenuError: boolean;
  deleteImage: any;
  allIndex: any;
  isValid: boolean;
  selectedDishes: any;
  selectedTagIds: any;
  selectedCuisinesId: any;
  cuisine: any;
  isDisabled:boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileMenuUploadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createMenuAPICallId: any
  getAllSubCategoryId: any
  getAllCuisinesId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: '',
      isLoading: false,
      BookingOpen: true,
      radioValue: "",
      Itemtype: '',
      ItemName: "",
      unitType: "",
      units: [],
      quantity: [],
      price: [],
      unitErrors: false,
      quantityErrors: false,
      priceErrors: false,
      description: "",
      imageFile: "",
      createdMenuData: "",
      allSubCategoryData: [],
      allCuisineData: [],
      createMenuErrorData: [],
      createMenuError: false,
      deleteImage: false,
      allIndex: [''],
      isValid: false,
      selectedDishes: [],
      selectedTagIds: [],
      selectedCuisinesId: null,
      cuisine: '',
      isDisabled:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

 
  async componentDidMount() {
    super.componentDidMount();
    this.getAllSubCategories()
    this.getAllCuisinesCall()
    // Customizable Area Start
    // Customizable Area End
} 
  // ReaderMenuImg = (e: any) => {
  //   this.setState({ imageFile: e.target.files[0] })
  //   console.log("upimg", this.state.imageFile)
  // }

  ReaderMenuImg = async (e:any) => {
    const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
    const fileSizeLimitInMB = 5;
    
    if (!e.target.files || e.target.files.length === 0) {
      ToastMessage('error', 'Please select an image file.');
      return;
    }
  
    const file =await e.target.files[0];
  
    const fileSizeInMB = file.size / (1024 * 1024);
  
    if (fileSizeInMB <= fileSizeLimitInMB) {
      if (allowedFormats.includes(file.type)) {
        this.setState({ imageFile: file });
      } 
      else {
        ToastMessage('error', `File '${file.name}' is not in PNG, JPEG, or JPG format.`);
      }
    }
     else {
      ToastMessage('error', `File '${file.name}' exceeds the ${fileSizeLimitInMB}MB file size limit.`);

    }
  };

  handleClose = async () => {
    this.setState({   imageFile: '',radioValue: '', ItemName: "",Itemtype:"",description: "",units: [],quantity: [],price: [],allIndex: [''],
    selectedDishes: [], cuisine: ""})
     this.props.handleClose()
  };

  handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file && file.size <= 5 * 1024 * 1024) {
     this.setState({ imageFile: file });
    } else {
      alert("Please select an image file that is 5MB or less in size.");
    }
  };

  onSubmit = (e: any) => {
    console.log(e, this.state.imageFile);
  };

  handleErrorClose = () => {
    this.setState({ createMenuError: false })
  }

  handleAddInput = () => {
    const { allIndex } = this.state;
      this.setState({
        allIndex: [...allIndex, '']
      });
  }

  handleRemoveInput = (index: number) => {
    const { allIndex } = this.state;
    allIndex.splice(index, 1); // Remove the input field at the specified index
    this.setState({ allIndex }); // Update the state
  }

  handleChangeUnitType = (e: any, index: number) => {
    const { value } = e.target;
    this.setState(prevState => {
      const units = [...prevState.units];
      units[index] = value;
      return { units };
    });
  }


  handleChangeQuantity = (event: any, index: number) => {
    const { quantity } = this.state;
    quantity[index] = event.target.value; // Update the value of the input field at the specified index
    this.setState({ quantity }); // Update the state 
  }


  handleChangePrice = (event: any, index: number) => {
    const { price } = this.state;
    price[index] = event.target.value; // Update the value of the input field at the specified index
    this.setState({ price }); // Update the state
  }

  handleDishChange = (event:any) => {
    const { allSubCategoryData } = this.state;
    const selectedDishes = event.target.value;
    
    const selectedIds = selectedDishes.map((name:any) => {
      const selectedDish = allSubCategoryData.find((item:any) => item?.attributes?.title === name);
      return selectedDish ? selectedDish.id : '';
    });
  
    this.setState({
      selectedDishes: selectedDishes,
      selectedTagIds: selectedIds
    });
  
  };

 
  handleChangeCuisines = (event:any) => {
    const selectedCuisine = event.target.value;
    const selectedCuisineData = this.state.allCuisineData.find((item:any) => item.name === selectedCuisine);
    const selectedCuisineId = selectedCuisineData ? selectedCuisineData.id : null;

    this.setState({
      cuisine: selectedCuisine,
      selectedCuisinesId: selectedCuisineId
    });
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.HandleCreateMenuApiCall(from, message)
    this.HandleGetAllSubCategories(from, message)
    this.HandleGetAllCuisinesCall(from, message)
    // Customizable Area End

    // Customizable Area Start

}
  // Customizable Area End

  // web events

  createMenuApiCall = async () => {
    this.setState({isDisabled:true})
    if(!this.state.imageFile) {
      ToastMessage('error', "Please add an Image")
    } else {
    const header = {
      "token": localStorage.getItem("authToken")
    };
    let formdata = new FormData();
    formdata.append("data[category_type]", this.state.radioValue);
    formdata.append("data[item_name]", this.state.ItemName);
    this.state.units.forEach((d, i) => {
      formdata.append(`data[variants_attributes[${i}][unit]]`, this.state.units[i]);
      formdata.append(`data[variants_attributes[${i}][price]]`, this.state.price[i]);
      formdata.append(`data[variants_attributes[${i}][quantity]]`, this.state.quantity[i]);
    })
    formdata.append("data[item_type]", this.state.Itemtype);
    formdata.append("data[description]", this.state.description);
    formdata.append("data[image]", this.state.imageFile);
    this.state.selectedTagIds.forEach((tag:any,i:any) => {
      formdata.append("data[dish_tag_ids][]", this.state.selectedTagIds[i]);
    })
    formdata.append("data[cuisine_id]", this.state.selectedCuisinesId)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createMenuAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_menu_ordering/menus`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
} 

  getAllSubCategories = () => {
    const header = {
        "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubCategoryId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/dish_tags`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}  

getAllCuisinesCall = () => {
  const header = {
      "token": localStorage.getItem("authToken")
  };
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAllCuisinesId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_menu_ordering/cuisines`
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}  

HandleCreateMenuApiCall = async (from: string, message: Message) => {
  if (this.createMenuAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
             ToastMessage( 'error',apiResponse.errors?.menu[0])
        } else {
          this.setState({
            createdMenuData: apiResponse?.data?.attributes,
            BookingOpen: false,
            imageFile: '',radioValue: '', ItemName: "",Itemtype:"",description: "",units: [],quantity: [],price: [],allIndex: [''],
            selectedDishes: [], cuisine: ""
          })
          this.props.handleClose();
          ToastMessage( 'success',"Menu added successfully");
      }
  }
}

HandleGetAllSubCategories = async (from: string, message: Message) => {
  if (this.getAllSubCategoryId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage('error', "No Dish Tags Found")
      } else {
          this.setState({
            allSubCategoryData: apiResponse?.data
          })
      }
  }
}

HandleGetAllCuisinesCall = async (from: string, message: Message) => {
  if (this.getAllCuisinesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage('error', "No Cuisines Found")
      } else {
          this.setState({
            allCuisineData: apiResponse            
          })
          console.log("all cuisines mil rahe..", apiResponse)

      }
  }
}

  // Customizable Area End
}
