import React from "react";

import {
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#6200ee",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import AdminConsoleController, {
  Props,
} from "./AdminConsoleController";

export default class PrivacyPolicy extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: 'auto' }}>
          <Grid item sm={12} style={{ height: 'auto' }}>
            <div style={webStylePrivacy.needBlockStyle}>
              <Grid style={webStylePrivacy.gridStyle}>
                <h1>Privacy Policy</h1>
                <div>
                  <h3>Last updated: January 24, 2023</h3>
                  <p style={{ textAlign: 'justify' }}>This Privacy Policy includes important information about your personal data and we encourage you to read it carefully.</p>
                </div>
                <div>
                  <h3>Welcome</h3>
                  <p style={{ textAlign: 'justify' }}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in
                    some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum,
                    you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat
                    predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined
                    with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always
                    free from repetition, injected humour, or non-characteristic words etc.</p>
                </div>
                <div>
                  <h3>Where can I get some</h3>
                  <p style={{ textAlign: 'justify' }}>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
                    literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one
                    of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,
                    discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good
                    and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of
                    Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
                </div>
                <div>
                  <h3>Why do we use it?</h3>
                  <p style={{ textAlign: 'justify' }}>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                    in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                    qui officia deserunt mollit anim id est laborum."</p>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStylePrivacy = {
  gridStyle: {
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    padding: "25px 30px",
    margin: "25px 13px 0 0",
  },

  needBlockStyle: {
    padding: "10px 0 30px 20px",
  },
}

// Customizable Area End

