import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine as callApi } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import ToastMessage from "../../../components/src/ToastMessage";
import { apiCall } from "../../../components/src/common";
import {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

export const staticData = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleClose: () => void;
  show: boolean;
}

export interface S {
  // Customizable Area Start
  overViewData: any;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  suggestions: any,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditOverviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  patchOverviewId: string = "";
  getOverViewCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    callApi.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      overViewData: {},
      latitude: 28.7041,
      longitude: 77.1025,
      zoom: 17,
      address: '',
      suggestions: [],
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }


  async receive(from: string, msg: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== msg.id) {
      return;
    }

    const apiReqCallId = msg.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const resJson = msg.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiReqCallId || !resJson) {
      return;
    }

    if (apiReqCallId === this.patchOverviewId) {

      if (resJson?.data?.attributes && resJson?.data?.id) {
        ToastMessage("success", "Edit Overview Details updated successfully");
        this.setState({ overViewData: resJson.data });
        this.props.handleClose()
      } else {
        ToastMessage("error", "Something's Wrong");
      }
    }

    if (apiReqCallId === this.getOverViewCallId) {

      if (resJson?.data?.attributes && resJson?.data?.id) {
        this.setState({ overViewData: resJson.data, address: resJson.data?.attributes?.location, latitude: resJson.data.attributes.latitude, longitude: resJson.data.attributes.longitude });
      } else {
        ToastMessage("error", "Something Went Wrong ");
      }
    }
    // Customizable Area Start

  }

  // Customizable Area Start
  async componentDidMount() {
    this.getOverViewData()
  }

  preventETypeNumber = (e: any) => {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Shift"];
    return (allowedKeys.includes(e.key) ? false : !/^[^A-Za-z\W_]+$/.test(e.key)) && e.preventDefault();
  }

  getWorkingTimeString = (data: any) => {
    let s_hours = data.start_hours.toString().length > 1 ? data.start_hours : `0${data.start_hours}`
    let s_mins = data.start_mins.toString().length > 1 ? data.start_mins : `0${data.start_mins}`

    let e_hours = data.end_hours.toString().length > 1 ? data.end_hours : `0${data.end_hours}`
    let e_mins = data.end_mins.toString().length > 1 ? data.end_mins : `0${data.end_mins}`

    let starting_time = `${s_hours}:${s_mins} ${data.start_time_type}`
    let closing_time = `${e_hours}:${e_mins} ${data.end_time_type}`
    return { starting_time, closing_time }
  }

  updateOverview = async (dataObj: any) => {

    const workTime = this.getWorkingTimeString(dataObj)
    let token = localStorage.getItem('authToken');
    const header = {
      "Content-Type": staticData.contentTypeApiGetUserProfile,
      "token": token
    };

    const dataBody = {
      "restaurant_name": dataObj.req_name,
      "full_phone_number": dataObj.contect,
      "website": dataObj.website,
      "email": dataObj.email,
      "address": dataObj.address,
      "latitude": this.state.latitude,
      "longitude": this.state.longitude,
      "location": this.state.address,
      "oprational_hour": `${workTime.starting_time} - ${workTime.closing_time}`,
      "average_price": dataObj.avg_price_two,
      "overview": dataObj.overview_area,
      "currency_type": dataObj.currency_type
    };

    const Body = {
      data: dataBody
    };

    const req = apiCall({
      httpBody: Body,
      header: header,
      url: `/bx_block_location/restaurant_locations/${this.state.overViewData.id}`,
      httpMethod: 'PATCH'
    })
    this.patchOverviewId = req.messageId;
    callApi.sendMessage(req.id, req);
  }

  getOverViewData = async () => {
    let tokenValue = await localStorage.getItem('authToken')
    const header = {
      "Content-Type": staticData.contentTypeApiGetUserProfile,
      "token": tokenValue
    };
    const req = apiCall({
      httpBody: {},
      header: header,
      url: `bx_block_location/restaurant_locations/${localStorage.getItem('locationID')}`,
      httpMethod: 'GET'
    })
    this.getOverViewCallId = req.messageId;
    callApi.sendMessage(req.id, req);
  }

  handleMarkerPosition = (map: any, maps: any) => {
    const geocoder = new maps.Geocoder();
    let mark = new maps.Marker({
      position: { lat: this.state.latitude, lng: this.state.longitude },
      map,
      draggable: true,
    });

    maps.event.addListener(mark, 'dragend', () => {
      const position = mark.getPosition();
      this.setState({ latitude: position.lat(), longitude: position.lng() });
      const latlng = {
        lat: position.lat(),
        lng: position.lng()
      };
      geocoder.geocode({ location: latlng }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.setState({ address: results[0].formatted_address });
          }
        }
      });
    });

    this.componentDidUpdate = () => {
      mark.setPosition({ lat: this.state.latitude, lng: this.state.longitude });
    };
  };

  formatTime(inputString: string) {
    const [startTimeStr, endTimeStr] = inputString.split('-');

    // Trim any leading or trailing whitespace
    const startTime = startTimeStr.trim();
    const endTime = endTimeStr.trim();

    // Parse the start time components
    const [startHoursStr, startMinsStr, startTimeType] = startTime.split(/:|\s/);
    const startHours = parseInt(startHoursStr, 10);
    const startMins = parseInt(startMinsStr, 10);
    const startMinsPadded = startMins.toString().padStart(2, '0');


    // Parse the end time components
    const [endHoursStr, endMinsStr, endTimeType] = endTime.split(/:|\s/);
    const endHours = parseInt(endHoursStr, 10);
    const endMins = parseInt(endMinsStr, 10);
    const endMinsPadded = endMins.toString().padStart(2, '0');
    // Create an object with the extracted time information
    return {
      start_hours: startHours,
      start_mins: startMinsPadded,
      start_time_type: startTimeType,
      end_hours: endHours,
      end_mins: endMinsPadded,
      end_time_type: endTimeType
    };
  }

  handleSelect = async (value: any) => {
    try {
      const results = await geocodeByAddress(value);
      const { lat, lng } = await getLatLng(results[0]);
      this.setState({ address: value, latitude: lat, longitude: lng });
    } catch (error) {
      console.error("Error", error);
    }
  };
  addKD(str?: string) {
    if (!str?.startsWith('KD')) {
      return `KD ${str}`
    } else {
      return str;
    }
  }


  // Customizable Area End
}
