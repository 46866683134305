import React from "react";

import {
  Grid,
  // Customizable Area Start
  Typography,
  Button,
  Chip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import FoodPrefrencesController, {
  Props,
} from "./FoodPrefrencesController.web";

export default class FoodPreferences extends FoodPrefrencesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: 'auto' }}>
          <Grid item sm={12} style={{ height: 'auto' }}>
            <div style={{ marginTop: '-14px' }}>
              <h2>Settings &#62; Food Preferences</h2>
              <Grid style={webStyleFood.gridStyle}>
                <div style={webStyleFood.boxdiv}>
                  <h3>Food Preferences</h3>

                  <Typography style={webStyleFood.heading2}>Add Food Preferences</Typography>
                  <form onSubmit={this.PostFoodPrefrences} data-test-id='add_btn_food'>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={8}>
                        <input
                          type='text'
                          name="Food"
                          data-test-id='Food'
                          placeholder="Name of Food Preferencess"
                          value={this.state.Food}
                          style={webStyleFood.formInput} onChange={(e) => this.handleFoodChange(e)} />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Button style={webStyleFood.btnchange as any} type="submit">Add</Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Grid container style={webStyleFood.featureMenu} spacing={4}>

                    {this.state.foodPreference.map((val: any) => {
                      return (
                        <Grid item key={val.id}>

                          <Chip style={webStyleFood.tagChipRage as React.CSSProperties} label={val.name} data-test-id='deleteTag_click' onDelete={() => this.DeleteFoodPrefrences(val.id)} />

                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
              </Grid>
            </div>

          </Grid>
        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyleFood = {
  gridStyle: {
    borderRadius: "8px",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    padding: "10px 30px",
    backgroundColor: "#FFFFFF",
    height: '100vh',
    margin: "25px 13px 0 0",
  },

  visiblepass: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },

  boxdiv: {
    paddingLeft: '20px',
    paddingTop: '15px',
    width: '72%',
    maxWidth: '700px'
  },

  featureMenu: {
    marginTop: "17px"
  },


  tagChipRage: {
    fontSize: "14px",
    backgroundColor: "#F9FAFB",
    fontWeight: "400"
  },

  btnchange: {
    background: "#096fff",
    color: "#fff",
    border: "1px solid #096fff",
    borderRadius: "8px",
    height: "38px",
    marginTop: '11px',
    fontSize: "15px",
    textTransform: "none",
    minWidth: '120px',
    marginLeft: '25px',
    marginBottom: '15px',
  },

  heading2: {
    fontWeight: 400,
    fontFamily: 'Inter',
    color: '#001F49',
    marginTop: '10px'
  },

  formInput: {
    background: 'rgb(244, 247, 249)',
    width: "100%",
    padding: '8px',
    height: "40px",
    borderRadius: "8px",
    margin: "10px 0px",
    fontSize: "14px",
    color: '#001F49',
    outline: 'none',
    border: "1px solid #E5E7EB",
  },
}

// Customizable Area End

