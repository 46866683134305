import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  BookingOpen: boolean;
  radioValue: string;
  couponName: string;
  discount: any;
  uptoAmount: any | number;
  maxQuantity: any | number;
  CouponCode: any;
  discountType: any;
  offerValidFromDate: any;
  offerValidUpto: any;
  quantity: number;
  description: string;
  checkedB: any;
  imageFile: any | ArrayBuffer | null | unknown | File;
  getDiscountCouponData: any;
  getAllCoupons: any;
  searchCouponData: any;
  searchCoupon: string;
  isLoading: boolean;
  openFilter: boolean;
  active: boolean;
  all: boolean;
  allFilter: any;
  inactive: boolean;
  openAction: any;
  openDiscountCouponModal: boolean;
  openShowDiscountCouponModel: boolean;
  filterResult: boolean;
  editCouponId: any;
  couponStatus: boolean;
  email: any;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShowOffersAndDiscountsControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDiscountCouponCallId: any
  searchCouponCallId: any
  filterCouponCallId: any
  nextPageDataId: any
  makeInactiveCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      BookingOpen: true,
      couponName: "",
      radioValue: "",
      discount: "",
      uptoAmount: 0,
      maxQuantity: 0,
      quantity: 0,
      discountType: "% percentage",
      CouponCode: "",
      offerValidFromDate: "",
      email: '',
      description: "",
      offerValidUpto: "",
      imageFile: "",
      checkedB: false,
      searchCouponData: [],
      getDiscountCouponData: [],
      getAllCoupons: {},
      searchCoupon: "",
      openFilter: false,
      isLoading: false,
      active: false,
      all: true,
      inactive: false,
      allFilter: "",
      openAction: null,
      openDiscountCouponModal: false,
      openShowDiscountCouponModel: false,
      filterResult: false,
      editCouponId: null,
      couponStatus: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getDiscountCouponCall();
  }
  // Customizable Area End



  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.HandleGetDiscountCouponCall(from, message)
    this.HandleSearchCouponCall(from, message)
    this.handleFilterCouponCall(from, message)
    this.HandleMakeInactiveCall(from, message)
    this.handleProposalNextPageListCall(from, message)
    // Customizable Area End
  }
  // Customizable Area End


  // Customizable Area End


  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.openDiscountCouponModal !== this.state.openDiscountCouponModal) {
      this.getDiscountCouponCall()
    }
  }

  handleOpenFilter = () => {
    this.setState({ openFilter: true })
  }

  HandleSearch = async(event:any) => {
    const searchValue = event.target.value;
    this.setState({ searchCoupon: searchValue }, () => {
      if (searchValue === "") {
        this.getDiscountCouponCall();
      }
    });
  }

  handleOpenDiscountCouponModal = () => {
    this.setState({ openDiscountCouponModal: true })
  }

  handleCloseFilter = () => {
    this.setState({ openFilter: false })
  }

  handleEditBox = async(event: any) => {
    this.setState({ openAction: event.currentTarget })
  }

  handleCloseMenu = () => {
    this.setState({ openAction: null })
  }

  handleCloseDiscountCoupon = () => {
    this.setState({ openDiscountCouponModal: false, openAction: null })
  }

  handleShowDiscountCouponAll = (allId: any, AllCouponStatus: any) => {
    this.setState({ editCouponId: allId, couponStatus: AllCouponStatus })
  }

  handleShowDiscountCoupon = (Id: any, couponStat: any) => {
    this.setState({ editCouponId: Id, couponStatus: couponStat })
  }

  handleChangeAll = async (event: any) => {
    this.setState({
      all: true, active: false, inactive: false,
      allFilter: event.target.value
    })
  }

  HandleChangeActive = async (event: any) => {
    this.setState({
      active: true, all: false, inactive: false,
      allFilter: event.target.value
    })
  }

  handleChangeInactive = async (event: any) => {
    this.setState({
      inactive: true, all: false, active: false,
      allFilter: event.target.value
    })
  }

  handleOpenShowDiscountCouponModel = (event: any) => {
    this.setState({openShowDiscountCouponModel: true, openAction: null})
  }

  handleCloseShowDiscountCoupon = () => {
    this.setState({ openShowDiscountCouponModel: false, openAction: null })
    this.getDiscountCouponCall()
  }

  getDiscountCouponCall = () => {

    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDiscountCouponCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_coupon_cg/coupon_code_generator?page=1&per_page=5`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  searchCouponCall = async () => {

    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchCouponCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_coupon_cg/search_coupon?search=${this.state.searchCoupon}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  filterCouponCall = () => {

    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.filterCouponCallId = requestMessage.messageId;
    const filterValue = this.state.allFilter;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_coupon_cg/search_coupon?filter=${filterValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  makeInactivecall = () => {
    let token = localStorage.getItem("authToken")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.makeInactiveCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `inactive_status/${this.state.editCouponId}?token=${token}&coupon_status=${!this.state.couponStatus}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  nextPageData = (meta: any, value: any) => {
    
    let result;
    if (value === "nextpage") {
      result = meta?.next_page
    }
    if (value === "lastpage") {
      result = meta?.total_pages
    }
    if (value === "previouspage") {
      result = meta?.current_page - 1
    }
    if (value === "firstpage") {
      result = 1
    }

    const headers = {"Content-Type": configJSON.validationApiContentType, token: localStorage.getItem("authToken")};

    const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.nextPageDataId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_coupon_cg/coupon_code_generator?page=${result}&per_page=5`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }; 

  HandleGetDiscountCouponCall = async (from: string, message: Message) => {
    if (this.getDiscountCouponCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage('error', apiResponse.errors[0]?.message)
      } else {
        this.setState({ getDiscountCouponData: apiResponse.data, getAllCoupons: apiResponse })
        console.log("getting discountcoupon data ...", apiResponse.data);

      }
    }
  }

  HandleSearchCouponCall = async (from: string, message: Message) => {
    if (this.searchCouponCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!apiResponse.errors) {
        if(apiResponse.data?.length == 0) {
          ToastMessage('error', "No search items found")
        } else {
          this.setState({ searchCouponData: apiResponse.data })
        }    
      }
    }
  }

  handleFilterCouponCall = async (from: string, message: Message) => {
    if (this.filterCouponCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage('error', apiResponse.errors)
      } else {
        if (apiResponse?.data?.length === 0) {
          this.setState({ filterResult: true })
          ToastMessage('error', "No data found")
        }
        this.setState({
          searchCouponData: apiResponse.data, getDiscountCouponData: apiResponse.data, openFilter: false,
          getAllCoupons: apiResponse,
        })
      }
      console.log("filtering data ...", apiResponse.data);
    }
  }

  HandleMakeInactiveCall = async (from: string, message: Message) => {
    if (this.makeInactiveCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage('error', apiResponse.errors)
      } else {
        ToastMessage("success", apiResponse.message)
        this.setState({ openAction: null })
        this.getDiscountCouponCall();
        this.searchCouponCall();
      }
    }
  }

  handleProposalNextPageListCall = async (from: string, message: Message) => {
    if (this.nextPageDataId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!apiResponse?.errors) {
      
          this.setState({ getAllCoupons: apiResponse, getDiscountCouponData: apiResponse.data, searchCouponData: apiResponse.data })
      }
  } 
}
  // Customizable Area End
}
