import React from 'react'

// Customizable Area Start
import {
    Typography,
    IconButton,
    Grid,
    Paper,
    Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Avatar, Chip
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CreateIcon from '@material-ui/icons/Create';
import OrderSummary from '../../OrderDetailView/src/OrderSummary.web';
import Loader from '../../../components/src/Loader.web';
import { NavLink } from "react-router-dom";
import ManageQueue from "../../OrderDetailView/src/ManageQueue.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: { main: '#096FFF' },
    secondary: { main: '#9CA3AF' },
  },
  typography: {
    "fontFamily": `inter, sans-serif`,
    h2: {
      fontSize: "24px",
      fontWeight: 400
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      whiteSpace: "nowrap"
    },
    h1: {
      fontSize: "40px",
      fontWeight: 500
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400,
      whiteSpace: "nowrap"
    }
  },
});

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      '&:hover': {
        borderRadius: "8px",
        boxShadow: "0px 12px 18px rgba(0, 0, 0, 0.121203)",
      }
    },
  }),
)((props: any) => <TableRow {...props} />);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      borderBottom: "none"
    },
  }),
)((props: any) => <TableCell {...props} />);

const StyledIconButton: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      padding: "8px",
    },
  }),
)((props: any) => <IconButton {...props} />);
// Customizable Area End

import DashboardController1, {
  Props,
  configJSON,
} from "./DashboardController1.web";

export default class Dashboard extends DashboardController1 {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  GenInfoPaper = (title: string, firstData: any, secondData?: any, circularType?: boolean) => {
    return (
      <Paper elevation={3} style={webStyle.paperLayout}>
        <div style={webStyle.paperHeader}>
          <FiberManualRecordIcon color="primary" style={webStyle.dotIcon} />
          <Typography variant="body1">
            {title}
          </Typography>
        </div>

        {!circularType && <Grid container justifyContent="space-around" alignItems="center" style={webStyle.paperMainInfoContainer}>

          <Grid item xs={6} style={webStyle.paperContentContainer as any}>
            <Typography variant="h1" component={"span"}>
              {firstData?.primaryText}
            </Typography>
            <Typography variant="h6" component={"span"}>
              {firstData?.secondaryText}
            </Typography>
          </Grid>

          <Grid item xs={6} style={webStyle.paperContentContainer as any}>
            <Typography variant="h1" color="secondary" component={"span"}>
              {secondData?.primaryText}
            </Typography>
            <Typography variant="h6" color="secondary" component={"span"}>
              {secondData?.secondaryText}
            </Typography>
          </Grid>
        </Grid>}

        {circularType && <Grid container justifyContent="space-around" alignItems="center" style={{ marginTop: "18px" }}>

          <div style={webStyle.circularInfo as any}>
            <div>
              <Typography variant="h1" component={"span"}>
                {firstData?.primaryText}
              </Typography>
              <br />
              <Typography variant="h6" component={"span"}>
                {firstData?.secondaryText}
              </Typography>
            </div>
          </div>
        </Grid>}
      </Paper>
    )
  }

  queueCountFunction = (attributes: any) => {
    let resultData;

    if (attributes.status === "waiting") {
      resultData = attributes.queue_count;
    } else if (Array.isArray(attributes?.room_layout_properties)) {
      resultData = <span style={{
        maxWidth: '115px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }}>{attributes.room_layout_properties.map((table: any) => table.id).join(',')}</span>
    } else {
      resultData = 'N/A';
    }
    return resultData;
  }

  GenTableRow = (attributes: any, id: any) => {
    interface StatusLabel {

      label: string;

      style: {
        backgroundColor: string;

        minWidth: string;

        color: string;
      };

    }

    const statusLabel: { [key: string]: StatusLabel } = {
      completed: { label: "Completed", style: webStyle.chipConfirm },
      in_progress: { label: "Pending", style: webStyle.chipWaitlist },
      confirmed: { label: "Confirmed", style: webStyle.chipConfirm },
      cancelled: { label: "Cancelled", style: webStyle.chipCancel },
      waiting: { label: "Waiting", style: webStyle.chipWaitlist }
    };

    const status = statusLabel[attributes.status];

    const date = new Date(attributes.date);
    const utcOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + utcOffset + 330);
    const formattedDate = `${date.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })} at ${date.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: '2-digit'
    }).toUpperCase()}`;
    return (

      <StyledTableRow key={id} data-test-id="tableRaw" >
        <StyledTableCell align="left" data-test-id="tableCell" style={webStyle.tableCellCustomerName} >
          <Avatar style={webStyle.avatarRage}>
            <Typography variant="body1">{attributes.customer_name?.substring(0, 2)}
            </Typography> </Avatar>

          <Typography variant="body1" style={{ fontWeight: 500, minWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
            {attributes.customer_name}
          </Typography>

        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            {attributes.reservation_id}
          </Typography>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Typography variant="body1" style={{ fontWeight: 500 }}> {formattedDate}</Typography>
        </StyledTableCell>
        <StyledTableCell align="center" style={{ display: 'flex', justifyContent: 'center' }}>
          {this.queueCountFunction(attributes)}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            {status && <Chip label={status.label} style={status.style} />}
          </Typography>
        </StyledTableCell>
        <StyledTableCell padding="none" align="center" >
          {attributes.status === "waiting" ? <StyledIconButton data-test-id="tablecelledit" onClick={() => this.handleOpenManageQueueModal(id)} >
            <CreateIcon style={{ fontSize: "16px" }} color="primary" />
          </StyledIconButton> : <StyledIconButton data-test-id="tablecelledit" onClick={() => this.handleOpenEditModal(id)} >
            <CreateIcon style={{ fontSize: "16px" }} color="primary" />
          </StyledIconButton>}
        </StyledTableCell>

      </StyledTableRow>
    )
  }

  tableDataAllUpcommingDashboard = () => {

    let content;
    let isArrayData = Array.isArray(this.state.upComingOrderDataDashboard) && this.state.upComingOrderDataDashboard.length > 0;
    if (this.state.isloading) {

      content = <Loader loading={this.state.isloading} />;
    } else if (isArrayData) {
      content = this.state.upComingOrderDataDashboard.slice(0, 10).map((val: any) => {
        return this.GenTableRow(val.attributes, val.id);
      });
    } else {
      content = (
        <TableRow>
          <StyledTableCell colSpan={6} align="center">
            Currently, there are no upcoming orders in the system.
          </StyledTableCell>
        </TableRow>
      );
    }
    return content;

    }
    // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Typography variant="h2" component={'h2'} style={{ fontWeight: 600, marginTop: '6px' }}>{configJSON.labelTitleText}</Typography>
        <div style={webStyle.mainWrapper}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4} md={4}>
              {this.GenInfoPaper('BOOKINGS', { primaryText: this.state.upcomingDataLength, secondaryText: "Upcoming" }, { primaryText: "0", secondaryText: "Total" })}
            </Grid>
            <Grid item xs={12} lg={4} md={4} >
              {this.GenInfoPaper('CELEBRITIES ASSOCIATED', { primaryText: "0", secondaryText: "Active" }, { primaryText: "0", secondaryText: "Overall" })}
            </Grid>
            <Grid item xs={12} lg={4} md={4}>
              {this.GenInfoPaper('TOTAL EARNINGS', { primaryText: "0", secondaryText: "mn" }, null, true)}
            </Grid>
          </Grid>

          <Paper elevation={3} style={webStyle.tableSection as any}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <TableContainer style={webStyle.tableLayout}>
                  <Table>

                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="body1" color="secondary">
                            AMBASSADORS
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1" color="secondary">
                            FOLLOWERS
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>

                      <p style={{ textAlign: 'center', marginLeft: '100px' }}>Coming Soon</p>

                    </TableBody>

                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} md={8} lg={8} >

                <TableContainer style={webStyle.tableLayout}>

                  <Grid container justifyContent="space-between" alignItems="center" style={webStyle.tableNavigationContainer as any}>
                    <Grid item >
                      <Typography variant="body1" style={webStyle.tableOrderUp}>
                        UPCOMING ORDERS
                      </Typography>
                    </Grid>
                    <Grid item xs={9} style={webStyle.tableNavigation}>
                      <Typography
                        variant="body1"
                        style={this.state.activeTab === 'reservations' ? webStyle.tableNavigationSelected : webStyle.tableNavigationText}
                        data-test-id="reservation" onClick={() => this.handleTabChange('reservations')}
                      >
                        Reservations
                        {this.state.activeTab === 'reservations' ? <span style={webStyle.tableNavigationSelectedNo}>{this.state.upcomingDataLength}</span> : ''}
                      </Typography>

                    </Grid>
                  </Grid>

                  {this.state.activeTab === 'reservations' ? <Table>
                    <TableHead>
                      <TableRow>
                        {configJSON.tableHeaderText.map(({ title, align }: any) => {
                          return (
                            <StyledTableCell align={align}>
                              <Typography variant="caption" style={{ color: "rgb(54, 59, 66)", fontSize: '14px' }}>
                                {title}
                              </Typography>
                            </StyledTableCell>
                          )
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody>

                      {this.tableDataAllUpcommingDashboard()}
                      {this.state.upcomingDataLength > 10 ? (<NavLink to="/OrderDetailView" style={webStyle.MoreOrder}>More...</NavLink>) : (null)}
                    </TableBody>

                  </Table> : <Table>
                    <TableBody> <TableRow>
                      <StyledTableCell colSpan={6} align="center" >
                        <p style={{ fontSize: "20px" }}> Coming Soon... </p>
                      </StyledTableCell>
                    </TableRow></TableBody>
                  </Table>}
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
          {this.state.openEditModal !== null && <OrderSummary navigation={'OrderDetailModal'} id={'OrderDetailModalId'} handleClose={this.handleCloseEditModal} show={this.state.openEditModal !== null} recallTableApi={this.handleUpComingOrderDashboard} editId={this.state.openEditModal} tabpanel={0} />}
          {this.state.openManageQueueId !== null && <ManageQueue navigation={'ManageQueue'} id={'ManageQueue'} handleClose={this.handleCloseManageQueueModal} show={this.state.openManageQueueId === null ? false : true} recallTableApi={this.handleUpComingOrderDashboard} editId={this.state.openManageQueueId} tabpanel={0} />}
        </div>
      </ThemeProvider>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    marginTop: "30px"
  },

  dotIcon: {
    fontSize: "26px"
  },
  paperHeader: {
    alignItems: "center",
    display: "flex",
    gap: "8px"
  },
  paperLayout: {
    border: "1px solid #EFEFF5",
    height: "168px",
    borderRadius: "8px",
    padding: "12px",
    boxShadow: "20px 10px 40px rgba(0, 0, 0, 0.121012)"
  },
  paperMainInfoContainer: {
    marginTop: "32px"
  },
  paperContentContainer: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center"
  },
  circularInfo: {
    textAlign: "center",
    height: "96px",
    alignItems: "center",
    width: "96px",
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
    flexDirection: "column",
    backgroundColor: "#ECF0FF"
  },

  tableLayout: {
    border: "1px solid #EFEFF5",
    borderRadius: "8px",
    overflow: "auto",
    maxHeight: '607px',
    minHeight: '607px'
  },
  MoreOrder: {
    textDecoration: 'none',
    color: '#096fff',
    padding: '20px',
  },
  tableCellRage: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginBottom: "10px"
  },
  avatarRage: {
    height: "25px",
    width: "25px",
  },
  tableSection: {
    marginTop: "24px",
    borderRadius: "8px",
    padding: "16px 8px",
    position: "relative"
  },
  chipRage: {
    color: "#03AC26",
    maxWidth: "fit-content",
    borderColor: "#03AC26"
  },
  activeDot: {
    marginLeft: "7px",
    width: "8px",
    height: "8px",
    color: "#03AC26",
  },
  chipRageInActive: {
    maxWidth: "fit-content",
    color: "#DB1C1C",
    borderColor: "#DB1C1C"
  },
  inActiveDot: {
    width: "8px",
    height: "8px",
    marginLeft: "7px",
    color: "#DB1C1C",
  },
  tableCellCustomerName: {
    gap: "12px",
    display: "flex",
    alignItems: "center",
    width: '140px'
  },

  planChipGold: {
    fontSize: "6px",
    height: "12px",
    backgroundColor: "#EACB09"
  },
  chipConfirm: {
    color: "white",
    backgroundColor: "#03AC26",
    minWidth: "100px"
  },
  planChip: {
    height: "12px",
    fontSize: "6px"
  },
  chipCancel: {
    backgroundColor: "#DB1C1C",
    color: "white",
    minWidth: "100px"
  },
  chipWaitlist: {
    color: "white",
    backgroundColor: "#096FFF",
    minWidth: "100px"
  },
  secondTableHead: {
    backgroundColor: "#F8F8F8",
  },

  rightTopTitle2: {
    height: "40px",
    backgroundColor: "#096FFF",
    display: "flex",
    margin: "8px 10px 9px -25px",
    borderRadius: "22px",
  },
  tableNavigationContainer: {
    borderBottom: "1px solid rgba(9, 111, 255, 0.24)",
    height: "54px",
  },
  rightTopTitle1: {
    display: "flex",
    marginTop: "8px",
    width: "660px",
    backgroundColor: "#F2F2F2",
    borderRadius: "24px",
    height: "40px",
  },
  tableNavigationSelected: {
    borderRadius: "18px",
    padding: "6px 24px",
    backgroundColor: "#096FFF",
    color: "white",
  },
  tableNavigation: {
    backgroundColor: "#F8F8F8",
    display: "flex",
    alignItems: "center",
    // justifyContent: "space-around",
    justifyContent: 'flex-end',
    marginRight: '20px',
    //---------
    borderRadius: "18px",
    maxWidth: '66%'

  },
  tableOrderUp: {
    marginLeft: '16px',
    fontWeight: 500,
    fontSize: '16px'
  },
  tableNavigationSelectedNo: {
    color: "black",
    padding: "3px 5px",
    backgroundColor: "white",
    borderRadius: "8px",
    marginLeft: "12px",
    fontSize: "12px",
  },

  tableNavigationText: {
    padding: "6px 24px"
  },

};
// Customizable Area End
