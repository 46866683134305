import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    openViewReason: boolean,
    openContractTime: boolean,
    proposalsInfluencersListData: any,
    proposalSearchData: any;
    searchProposal: any,
    openAction: any,
    perRow: any,
    viewReasonId: any,
    contractTimeId: any,
    proposalStatus: any,
    profileImg: any,
    influencerName: any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class InfluencersProposalsControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    proposalsInfluencersListCallId: string = "";
    perRowProposalCallId: string = "";
    nextPageDataId: string = "";
    searchProposalCallId: string = "";
    timeOut: any = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
          openViewReason: false,
          openContractTime: false,
          proposalsInfluencersListData: {},
          proposalSearchData: [],
          searchProposal: "",
          openAction: null,
          perRow: "",
          viewReasonId: "",
          contractTimeId: "",
          proposalStatus: "",
          profileImg: "",
          influencerName: "",
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
         this.getProposalInfluencersListCall()       
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleProposalInfluencersListCall(from, message)
        this.handleProposalNextPageListCall(from, message)
        this.handleProposalPerrowListCall(from, message)
        this.HandleSearchProposalCall(from, message)
        // Customizable Area End
    }

    // Customizable Area Start
    
    //Web events
    
    changeBackgroundColor =(data:any)=> {
      if(data =="accepted"){
        return "green"
      } else if(data == "pending") {
        return "blue"
      } else if(data == "closed"){
        return "gray"
      } else if(data == "rejected") {
        return "red"
      } else {
        return "black"
      }
    }

    handleSetStatus =(stat: any) => {
      if(stat == "accepted"){
        return "Accepted"
      } else if(stat == "pending") {
        return "Pending"
      } else if(stat == "closed"){
        return "Closed"
      } else if(stat == "rejected"){
        return "Rejected"
      } else {
        return "Expired"
      }
    }

    handleOpenViewReason =() => {
        this.setState({ openViewReason: true, openAction: null })
    }

    handleViewReasonClose = () => {
      this.setState({openViewReason: false})
    }

    handleContractTime =()=> {
      this.setState({openContractTime: true, openAction: null})
    }

    handleContractTimeClose =()=> {
      this.setState({openContractTime: false})
    }

    handleActionBox = async(event:any,viewId:any,status: any,accountId:any, imgdata:any, influName: any)=> {
      this.setState({openAction: event.currentTarget,viewReasonId: viewId,proposalStatus: status, contractTimeId: accountId,
       profileImg: imgdata, influencerName: influName
      })
    }

    handleCloseActionBox=()=>{
      this.setState({openAction: null})
    }

    HandleProposalSearch =(event:any)=> {
      const searchValue = event.target.value;
      clearTimeout(this.timeOut)
      this.setState({ searchProposal: searchValue }, () => {
          if (searchValue === "") {
              this.getProposalInfluencersListCall();
          }else{
            this.timeOut = setTimeout(() => {
              this.searchProposalInfluencersCall();
            }, 1000)
          }
      });   
    }

    handleChangeRow = async(event:any)=> {
        let perRowdata = event.target.value
        this.setState({perRow: perRowdata})
        this.getPerrowProposalCall(perRowdata)
    }

    getProposalInfluencersListCall = () => {
      let RestaurantId: any = localStorage.getItem("locationID")
        const header = {
          "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.proposalsInfluencersListCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_influencer/proposal_list?restaurant_location_id=${RestaurantId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }

      nextPageData = (meta: any, value: any) => {

        let RestaurantId: any = localStorage.getItem("locationID")
        
        let result;
        if (value === "nextpage") { result = meta.next_page
        }
        if (value === "lastpage") {
          result = meta.total_pages
        }
        if (value === "previouspage") {result = meta.current_page - 1}
        if (value === "firstpage") {result = 1
        }
    
        const headers = {"Content-Type": configJSON.validationApiContentType, token: localStorage.getItem("authToken")};
    
        const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        
        this.nextPageDataId = getValidationsMsg.messageId;
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_influencer/proposal_list?restaurant_location_id=${RestaurantId}&page=${result}&per_page=${this.state.perRow}`
        );
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }; 

      getPerrowProposalCall = (perrow:any) => {
        let RestaurantId: any = localStorage.getItem("locationID")
          const header = {
            "token": localStorage.getItem("authToken")
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.perRowProposalCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.proposalPerrowEndpoint}?restaurant_location_id=${RestaurantId}&per_page=${perrow}`
            );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
      
        }
      
      searchProposalInfluencersCall = () => {
        let RestaurantId: any = localStorage.getItem("locationID")
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.searchProposalCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_influencer/search?user_name=${this.state.searchProposal}&restaurant_location_id=${RestaurantId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

      handleProposalInfluencersListCall = async (from: string, message: Message) => {
        if (this.proposalsInfluencersListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse.errors) {
            ToastMessage('error', apiResponse.errors || "Something went wrong")
          } else {
            if(apiResponse?.data?.length === 0) {
                ToastMessage('error', "No Propsal List Found")
            } else {
              console.log("test--------------------- 2",apiResponse)
              this.setState({ proposalsInfluencersListData: apiResponse })
            }
          }
        }
      }  

     handleProposalNextPageListCall = async (from: string, message: Message) => {
        if (this.nextPageDataId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (!apiResponse?.errors) {
          
              this.setState({ proposalsInfluencersListData: apiResponse })
          }
      } 
    }

    handleProposalPerrowListCall = async (from: string, message: Message) => {
      if (this.perRowProposalCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
      
            this.setState({ proposalsInfluencersListData: apiResponse })
        }
    } 
  }
    
    HandleSearchProposalCall = async (from: string, message: Message) => {
      if (this.searchProposalCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );

          if (!apiResponse?.errors) {
              this.setState({ proposalsInfluencersListData: {data: apiResponse} })
          }
      }
  }
    // Customizable Area End

}