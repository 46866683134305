import React from 'react'

// Customizable Area Start
import PropTypes from 'prop-types';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Grid'
import Dialog from "@material-ui/core/Dialog";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { withStyles } from '@material-ui/core/styles';
import { BluPlusImg, BlueCutImg } from "../src/assets"
import "./CustomisePlan.css";
// Customizable Area End

// Customizable Area Start
const Styles = (theme: any) => ({
    modalCross: {
        position: "absolute",
        top: "55px",
        right: "105px"
    },
    rightBottomButton1: {
        marginRight: "44px",
        marginTop: "18px",
        fontSize: "14px",
        fontWeight: 600,
    },
    formbutton: {
        width: "315px",
        height: "60px",
        padding: "20px",
        marginLeft: "18px",
        textTransform: "none",
        fontSize: "16px",
        backgroundColor: "#096FFF",
        color: "white",
        borderRadius: "8px",
        '&:hover': {
            opacity: "0.7",
            backgroundColor: "#096FFF"
        },

    },
})
// Customizable Area End



import CustomisableusersubscriptionsController, {
    Props,
    configJSON,
} from "./CustomisableusersubscriptionsController";

class CustomisePlan extends CustomisableusersubscriptionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    static propTypes: { classes: PropTypes.Validator<object>; };
    // Customizable Area End
    render() {
        // Customizable Area Start
        {/* @ts-ignore */ }
        const { classes } = this.props;
        const { customisePlan, planOptionListData, totalAmount } = this.state;
        return (
            <div>

                <Dialog
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // open={this.state.openCustomisePlan}
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box className='mainBox'>
                        <IconButton style={{ position: "absolute", top: 5, right: 5 }}
                            //   onClick={()=>this.setState({openCustomisePlan: false})}
                            onClick={this.props.handleClose}

                        >
                            <CancelRoundedIcon color="primary" />
                        </IconButton>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <p className='mainTypo'>{configJSON.pageTitle}</p>
                            <p className='subTypo'>Choose a solution that fits your need</p>
                        </Box>
                        <Grid container>
                            <Grid item lg={6}>
                                <Box className='halfBoxLeft'>
                                    <Box style={{ padding: "20px" }}>
                                        <Typography style={{ fontSize: "20px", fontWeight: 600, color: "#111827" }}>
                                            Choose the features you need</Typography>
                                        <Typography style={{ fontSize: "16px", fontWeight: 500, color: "#4B5563" }}>
                                            Click the feature to add in your customised plan</Typography>
                                    </Box>
                                    <Box style={{ marginLeft: "15px", marginRight: "8px" }}>
                                        <Grid container spacing={1}>
                                            {planOptionListData?.flat().map((item: any, index: any) => {
                                                return (
                                                    <Grid item xs={4} key={index}>
                                                        <Box className='smallBox'>
                                                            <p className='smallBoxTitle'>{item?.attributes?.name}</p>
                                                            <p className='smallBoxContent'>
                                                                {item?.attributes?.description}
                                                            </p>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <Typography style={{ color: "#25282b", fontWeight: 600 }}>
                                                                    KD {item?.attributes?.amount}</Typography>
                                                                <img src={BluPlusImg} style={{ height: "90%", cursor: "pointer" }} onClick={() => this.addCustomisePlanHandler(item)} data-testid="addplan" />
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={6}>
                                <Box className='rightmainBox'>
                                    {customisePlan.length <= 0 && <Typography style={{ fontSize: "20px", fontWeight: 600, color: "#111827" }}>
                                        Customised Plan
                                    </Typography>}
                                    {customisePlan.length <= 0 && <p className='rightBoxMiddle'>No features added yet</p>}
                                    <section>
                                        {customisePlan.length >= 1 && <Typography style={{ fontSize: "20px", fontWeight: 600, color: "#111827" }}>
                                            Customised Plan
                                        </Typography>}
                                        <Grid container spacing={1} style={{ minHeight: "220px", maxHeight: "260px", overflowY: "auto" }}>
                                            {customisePlan?.map((item: any, index: any) => {
                                                return (
                                                    <Grid item lg={4} key={index} >
                                                        <Box className='smallBox'>
                                                            <p className='smallBoxTitle'>{item?.attributes?.name}</p>
                                                            <p className='smallBoxContent'>
                                                                {item?.attributes?.description}
                                                            </p>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <Typography style={{ color: "#25282b", fontWeight: 600 }}>
                                                                    KD {item?.attributes?.amount}</Typography>
                                                                <img src={BlueCutImg} style={{ height: "90%", cursor: "pointer" }} onClick={() => this.removePlanHandler(item)} data-testid="removeplan" />
                                                            </div>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </section>
                                    <Box>
                                        <section className='cutomise-plan-section'>
                                            <div>
                                                <input type="radio" name="plan" id="monthly" value="monthly" defaultChecked onChange={(e)=>this.onChangeHandler(e)} data-testid="monthly"/>
                                                <label htmlFor="monthly">Monthly</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="plan" id="halfyearly" value="half_yearly" onChange={(e)=>this.onChangeHandler(e)} data-testid="halfyearly"/>
                                                <label htmlFor="halfyearly">Half Yearly</label>
                                            </div>
                                            <div>
                                                <input type="radio" name="plan" id="yearly" value="yearly" onChange={(e)=>this.onChangeHandler(e)} data-testid="yearly"/>
                                                <label htmlFor="yearly">Yearly</label>
                                            </div>
                                        </section>
                                        <Button style={{
                                            height: "60px", width: "190px", backgroundColor: "#fff", borderRadius: "8px"
                                        }}>
                                            <div style={{ height: "80px" }}>
                                                <Typography className={classes.rightBottomButton1}>TOTAL AMOUNT</Typography>
                                                <div className='dollerStyle'>KD {totalAmount ? this.state?.customisePlan?.reduce((total:number, item:any) => (total += item?.attributes?.amount), 0) * totalAmount : this.state?.customisePlan?.reduce((total:number, item:any) => (total += item?.attributes?.amount), 0)}</div>
                                            </div>
                                        </Button>
                                        <Button data-testid="purchase" className={classes.formbutton} variant="contained" onClick={this.customiseSubscription}>
                                            Purchase Plan
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Dialog>
            </div>
        )
        // Customizable Area End  
    }
}

// Customizable Area Start
CustomisePlan.propTypes = {
    classes: PropTypes.object.isRequired,
};
{/* @ts-ignore */ }
export default withStyles(Styles)(CustomisePlan);
export { CustomisePlan };
// Customizable Area End
