import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: any;
  otpVal: any;
  timer: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPasswordOTPController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiConfirmOTPCallId: any;
  apiSendOTPAgainCallId: any;
  timerID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {

      // Customizable Area Start
      email: '' || localStorage.getItem('forgotpwdEmail'),
      otpVal: "",
      timer: 60
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000); // update every second
  }

  async componentWillUnmount() {
    if (this.timerID) clearInterval(this.timerID);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiConfirmOTPCallId) {
          if (!responseJson?.errors && responseJson?.token) {
            ToastMessage("success", "OTP Successfully Matched")
            localStorage.setItem("forgotpwdToken", responseJson.token)
            this.props.navigation.navigate("NewPassword")
            localStorage.removeItem('forgotpwdEmail')
          }
          else {
            ToastMessage("error", responseJson.errors[0].pin)
          }
        }

        if (apiRequestCallId === this.apiSendOTPAgainCallId) {
          if (responseJson?.data && responseJson?.data?.attributes) {
            localStorage.setItem("forgotpwdToken", responseJson?.meta?.token)
            this.setState({ timer: 60 })
            this.timerID = setInterval(() => this.tick(), 1000);
            ToastMessage("success", "OTP is sent to your Email")
          }
          else {
            ToastMessage("error", responseJson?.errors[0]?.email || "Something went Wrong")
          }
        }

      }

    }

    // Customizable Area End
  }

  // Customizable Area End

  // web events

  // Customizable Area Start
  verifyOTPCall = async () => {
    if (this.state.otpVal.length != 4) {
      return ToastMessage("error", "Please enter OTP")
    }
    let ForgotToken = localStorage.getItem("forgotpwdToken")

    const header = {
      token: ForgotToken,
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const attrs = {
      pin: this.state.otpVal,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiConfirmOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/confirm_otp`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    // requestMessage.getData

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGoBack = () => {
    this.props.navigation.navigate("ForgotPassword")
  }

  handleChange = (otp: any) => {
    this.setState({ otpVal: otp });
  };

  sendOTPAgain = () => {
    if(this.state.timer > 0){
      return ToastMessage("error", "OTP is already sent to your registered Email ID, you can try again after few seconds")
  }
    this.setState({ otpVal: "", timer: 0 });
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const attrs = {
      email: localStorage.getItem("forgotpwdEmail"),
      login_type:	"website"
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSendOTPAgainCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/forgot_password`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  tick = () => {
    if (this.state.timer <= 0) {
      if (this.timerID) clearInterval(this.timerID);
      // handle timeout here
    } else {
      this.setState(prevState => ({ timer: prevState.timer - 1 }));
    }
  }

  // Customizable Area End
}
