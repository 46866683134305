Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.paymentUrl= "bx_block_featured_restaurants/feature_restaurants/pay_your_order_type/"
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.ApiMethodTypePatch = "PATCH";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "Dashboard";
exports.labelBodyText = "dashboard Body";
exports.Booking = "BOOKINGS"
exports.Favourites = "Favourites"
exports.Orders = "Orders"
exports.Payments = "Payments"
exports.myVoucher = "My Voucher"
exports.AboutUs = "About Us"
exports.SETTINGS = "Settings"
exports.INFLUENCER = "Share Your Social Profile Link"
exports.ERRMESSAGE = `The link you provided is not valid. Please make sure it starts with "http://" , "https://" or "www"`
exports.Help_center = "Help Center"
exports.tableHeaderText =
  [{ title: 'Customer Name', align: "left" }, { title: 'Booking ID', align: "center" }, { title: 'Booking Time', align: "center" },
  { title: 'Queue', align: "center" },
  { title: 'Status', align: "center" },
  { title: 'Action', align: "right" }]
exports.INFLUENCERREQUESTENDPOINT = "/bx_block_influencer/influencer_requests"
exports.POSTAPIMETTHOD = "POST"
exports.NOTVERIFIED = "Not Verified"
exports.MYPROPOSALS = "My Proposals"
exports.EXPIRE = "Expired"
exports.ACTIVE = "Active"
exports.ACCEPTED = "accepted"
exports.PENDING = "pending"
exports.REJECTED = "REJECTED"
exports.Blocked = "Blocked"
exports.Date = "Date"
exports.errBlockedMessage = "Your ability to send further requests is blocked by Admin."
exports.PROPOSALLIST = "bx_block_influencer/proposal_list?restaurant_location_id=586&page="
exports.PROPOSALUPDATE = "/bx_block_influencer/update_proposal/"
exports.trendingListEndPoint ='account_block/accounts'
exports.page = "page"
exports.per_page ="per_page"
exports.SUBSCRIPTIONApi = "bx_block_subscriptions/subscriptions"
exports.roomTableEndpoint = "/bx_block_layout/rooms/room_tables"
exports.roomListEndpoint = "/bx_block_layout/rooms"
exports.unFollowApisApiEndPoint = "bx_block_followers/follows"
exports.deleteApiMethodType = "DELETE"
exports.validationApiContentType = "application/json";
exports.messagePermission = "Go to Settings"
exports.Error = "Error"
exports.messagePermission2 = "Unable to open settings."
exports.messagePermission1 = `Turn on Location Services to access your location.`
exports.notAvailableMsg = "No Record found."
exports.screenNameFollowers= "Followers"
exports.trendingFoodiesScreen="Trending Foodies"
exports.screenNameFollowings="Following"
exports.restuarnatSearchEndPoint = "account_block/search_restaurant_locations?search=";
// Customizable Area End