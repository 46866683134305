import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    enableFilterSearch:boolean;
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    tabPanelNo: number;
    rejectpostmodel:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class PostDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            tabPanelNo: 0,
            rejectpostmodel: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
       
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
     
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function   
    hanleTabs = (event: object, value: number) => {
        this.setState({ tabPanelNo: value })
    }
    handleOpenRejectpost = () => {
        this.setState({ rejectpostmodel: true })
    }
    handleCloseRejectpost = () => {
        this.setState({ rejectpostmodel: false })
    }
    //API Function
    
    //API Response Function

    // Customizable Area End

}