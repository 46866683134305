// Customizable Area Start
import React from "react";
import {
    Typography,
    Grid,
    Button,
    Select,
    FormControl,
    RadioGroup,
    Radio,
    MenuItem,
    FormLabel,
    FormControlLabel,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Card from "@material-ui/core/Card";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { RedDeleteImg } from "./assets"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import {SwitchStyle} from "../../AdminConsole/src/AdminConsole.web"
// Customizable Area End

// Customizable Area Start
import OffersAndDiscountsControllerWeb, { Props } from "./OffersAndDiscountsControllerWeb";
// Customizable Area 


const IOSSwitch = withStyles((theme: Theme) =>

    createStyles({
        root: 
        {   padding: 0,
            margin: theme.spacing(1),
            height: 26,
            width: 42,},

        switchBase: {
            padding: 1,
            '&$checked': {
                color: theme.palette.common.white,
                transform: 'translateX(16px)',
                '& + $track': {
                    opacity: 1,
                    backgroundColor: '#52d869',
                    border: 'none' },
                  },
            '&$focusVisible $thumb': {
                border: '6px solid #fff',
                color: '#52d869',
               },
            },
        thumb: { width: 24,height: 24,
        },

        track: {
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: 26 / 2,
            transition: theme.transitions.create(['background-color', 'border']),
            opacity: 1,
            backgroundColor: theme.palette.grey[50] },

        checked: {},

        focusVisible: {},
    }),
    )(({ classes, ...props }: Props) => {
     return (
        <Switch
            disableRipple
            focusVisibleClassName={classes.focusVisible}
            classes={{
                root: classes.root,
                thumb: classes.thumb,
                switchBase: classes.switchBase,
                checked: classes.checked,
                track: classes.track,
                }}
            {...props}/>
       );
    });

const currentDate = new Date();

export default class DiscountCoupon extends OffersAndDiscountsControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.dateInputRef = React.createRef();
        // Customizable Area End
    }
    // Customizable Area Start

    render() {

        return (

            <React.Fragment>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={this.props.show}
                    onClose={this.handleDiscountClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    	
                     <ToastContainer
                        position="top-center"
                        autoClose={3500}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        rtl={false}
                    />
                    <DialogTitle id="max-width-dialog-title" style={{ padding: "0" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <IconButton
                                aria-label="close"
                                style={webStyle.closeButton}
                                onClick={this.handleDiscountClose}
                             >
                            <CancelIcon 
                              style={{ color: "#096FFF" }} 
                             />
                            </IconButton>
                            <div style={{ textAlign: "center" }}>
                                <Typography variant="h6"
                                    style={{ fontFamily: "Inter", 
                                             fontWeight: "bold", 
                                             fontSize: "32px", 
                                             color: "#111827", 
                                             marginTop: "-20px" }}
                                >
                                    Create Discount
                                </Typography>
                                <Typography variant="body2" 
                                  style={{
                                    fontFamily: "Inter", 
                                    fontSize: "24px", 
                                    color: "#515961", 
                                    fontWeight: 500,
                                    marginBottom: "20px"
                                  }}>
                                    Create a discount coupon
                                </Typography>
                             </div>
                          </div>
                    </DialogTitle>

                    <DialogContent>
                        <Card style={webStyle.postLinkModal}>
                            <Grid container className="profile-image">
                             <Grid item xs={12} lg={5}>
                                <div
                                //  style={{display: "flex",justifyContent: "space-between",flexDirection: "column",marginLeft: "80px"}}
                                >
                                    <Typography style={{alignSelf: "start",marginBottom: "14px",color: "#9CA3AF"}}>
                                    Attach image for banner
                                    </Typography>
                                    <div style={{ width: "78%",borderRadius: "10px",
                                                  height: 290, padding: "10px",
                                                }} >
                                     {this.state.Image_File === "" ? (
                                        <div style={{display: "flex",flexDirection: "column",justifyContent: "center",
                                                     alignItems: "center",gridGap: "5px",height: "100%",
                                                     border: "2px dashed #096FFF",
                                                     width: "100%" ,
                                                     background: "#ECF0FF",
                                                     borderRadius: "10px"
                                                    }}
                                         >
                                        <Button component="label" style={webStyle.Postbtn}>
                                            +
                                         <input type="file" accept="image/*" id="image-upload" value={this.state.Image_File} hidden
                                             onChange={this.ReaderImg}
                                          />
                                         </Button>
                                        </div>
                                         ) : (
                                         <div style={{ border: "none",borderRadius: "10px" }}>
                                             <img src={URL.createObjectURL(this.state.Image_File)}
                                              style={{maxWidth: "100%",borderRadius: "8px",
                                              display: "block",padding: 0
                                            }}
                                             />
                                             </div>
                                         )}
                                         </div>
                                         <div style={{ display: "flex" }}>
                                            <div style={{ marginTop: "10px" }}>
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: "#9CA3AF",
                                                        fontFamily: "inter"
                                                    }}
                                                >
                                                    Supported Image type :{""}
                                                    <span style={{ color: "#4B5563" }}>.jpg, .png</span>
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: "#9CA3AF",
                                                        fontFamily: "inter"
                                                    }}
                                                >
                                                    Maximum image size :{" "}
                                                    <span style={{ color: "#4B5563" }}>5MB per image</span>
                                                </Typography>
                                            </div>
                                            {this.state.Image_File &&
                                            <div style={{ marginLeft: "5px" }}>


                                                <Button
                                                    component="label"
                                                    style={{ marginTop: "-13px" }}
                                                >
                                                    <EditIcon color="primary" />
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        id="image-upload"
                                                        // value={this.state.Image_File}
                                                        hidden
                                                        onChange={this.ReaderImg}
                                                    />
                                                </Button>


                                                <img id="red_img"
                                                  src={RedDeleteImg} style={{ marginTop: "20px", marginLeft: "-5px" }}
                                                    onClick={() => this.setState({ Image_File: "" })}
                                                />
                                            </div>
                                             }
                                        </div>
                                 </div>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                        <FormControl component="fieldset">  
                                            <FormLabel component="legend" style={webStyle.formlabel}>
                                                Category*
                                            </FormLabel>
                                            <RadioGroup
                                                id="rdogrp"
                                                name="category_value"
                                                value={this.state.category_value}
                                                onChange={(e:any)=>{
                                                    this.handleChangeradio(e)
                                                    
                                                }}
                                                // style={webStyle.categoryRadio as React.CSSProperties}
                                                className="categoryRadio"
                                            >
                                                <FormControlLabel value="breakfast" control={<Radio color="primary" />} label="Breakfast" />
                                                <FormControlLabel value="lunch" control={<Radio color="primary" />} label="Lunch" />
                                                <FormControlLabel value="dinner" control={<Radio color="primary" />} label="Dinner" />
                                                <FormControlLabel value="all_day" control={<Radio color="primary" />} label="All Day" />
                                            </RadioGroup>
                                        </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormLabel component="legend" style={webStyle.formlabel}>
                                                Coupon Name*
                                            </FormLabel>
                                            <FormControl style={webStyle.messageField}>
                                                <TextField
                                                    data-test-id="txtInputConfirmPassword"
                                                    variant="outlined"
                                                    size="small"
                                                    id="CouponName"
                                                    placeholder="Enter coupon name"
                                                    type="text"
                                                    style={{
                                                        fontFamily: "Verdana",
                                                    }}
                                                    name="CouponName"
                                                    fullWidth
                                                    value={this.state.coupon_name}
                                                    onChange={(e:any)=>{
                                                        this.setState({coupon_name: e.target.value})
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <FormLabel component="legend" style={webStyle.formlabel2}>
                                            Discount Amount or Percentage*
                                        </FormLabel>
                                        <Grid container style={{ display: "flex" }} spacing={2}>
                                            <Grid item lg={4} xs={12}>
                                            <FormControl variant="outlined" >   
                                            <Select
                                                    label= "% percentage"
                                                    id = "discountType"
                                                    value= {this.state.discount_type}
                                                    onChange={(e:any) => this.handleChangeDiscountType(e)}
                                                    className="discount-percent-select"
                                                    >

                                                        <MenuItem value='percentage' >Percentage</MenuItem>
                                                        <MenuItem value='flat' >Flat</MenuItem>

                                                    </Select>
                                                </FormControl>    
                                                </Grid>

                                                {/* <Grid item xs={1} /> */}

                                            <Grid item lg={8} xs={12}>
                                                <FormControl
                                                    style={{ background: "#F9FAFB", 
                                                                width: "84%", 
                                                                marginTop: "10px" 
                                                                }}
                                                    >
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        placeholder="Enter amount/percentage"
                                                        fullWidth
                                                        id="discountId"
                                                        style={{fontFamily: "Verdana"}}
                                                        name="discounts"
                                                        value={this.state.discount}
                                                        onChange={(e:any)=> {
                                                            this.handleChangeDiscount(e)
                                                        }}
                                                    />
                                                </FormControl>

                                            </Grid>
                                        </Grid>

                                            <Grid container>
                                                <Grid item xs={12} lg={6}>

                                                    <FormLabel component="legend" 
                                                        style={webStyle.formlabel2} >
                                                        Upto Amount
                                                    </FormLabel>
                                                    <FormControl style={webStyle.smallMessageField}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            variant="outlined"
                                                            placeholder="Max. discount amount"
                                                            id="upto_amount"
                                                            // style={{width: "292px",fontFamily: "inter"}}
                                                            name="upto_amount"
                                                            value={this.state.upto_amount}
                                                            onChange={(e:any)=>{
                                                                this.handleChangeUptoAmount(e)
                                                            }}
                                                            className="max-amount"
                                                        />
                                                    </FormControl>

                                                </Grid>
                                                <Grid item xs={12} lg={6} className="max-amount-grid">
                                                    <FormLabel component="legend" style={webStyle.formlabel2} >
                                                        Max. Quantity*
                                                    </FormLabel>
                                                    <FormControl style={webStyle.smallMessageField}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            id="maxquantity"
                                                            placeholder="No.of times coupon can be used"
                                                            name="max_quantity"
                                                            value={this.state.maximum_quantity}
                                                            onChange={(e:any)=>{
                                                                this.handleChangeMaxQuantity(e)
                                                            }}
                                                            className="max-amount"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        <Grid item style={{ marginLeft: "-5px", marginTop: "10px" }}>
                                            <FormLabel component="legend" style={webStyle.formlabel}>
                                                Coupon Code*
                                            </FormLabel>
                                            <FormControl style={webStyle.messageField}>
                                                <TextField
                                                    data-test-id="txtInputConfirmPassword"
                                                    variant="outlined"
                                                    size="small"
                                                    id="Coupon_code"
                                                    placeholder="Enter Coupon Code"
                                                    type="text"
                                                    style={{
                                                        fontFamily: "Verdana",
                                                    }}
                                                    name="Coupon_code"
                                                    fullWidth
                                                    value={this.state.Coupon_Code}
                                                    onChange={(e:any)=>{
                                                        this.setState({Coupon_Code: e.target.value.toUpperCase(), codeError: false})
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} lg={6}>
                                                <FormLabel component="legend" style={webStyle.formlabel3} >
                                                    Offer Valid From*
                                                </FormLabel>
                                                <input
                                                    className="max-amount"
                                                    id="from_date"
                                                    type="date"
                                                    ref={this.dateInputRef}
                                                    value={this.state.offerValidFromDate}
                                                    onChange= {(e:any)=>{
                                                    this.handleDateValidation(e)
                                                    }}
                                                    min={currentDate.toISOString().split('T')[0]} 
                                                    />
                                            </Grid>
                                            <Grid item xs={12} lg={6} className="max-amount-grid">
                                                <FormLabel component="legend" style={webStyle.formlabel3} >
                                                    Offer Valid Upto*
                                                </FormLabel>
                                                <input
                                                    className="max-amount"
                                                    id="end_date"
                                                    type="date"
                                                    // onClick={(event:any) => event.target.focus()}
                                                    value={this.state.offerValidUpto}
                                                    onChange = {(e:any)=>{
                                                        this.handleDateValidationTo(e)
                                                    }}
                                                    min={currentDate.toISOString().split('T')[0]} 
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column">
                                            <FormLabel component="legend" style={webStyle.formlabel3} >
                                                Coupon Description*
                                            </FormLabel>
                                    
                                            <TextareaAutosize
                                                aria-label="maximum height"
                                                minRows={5}
                                                placeholder="Enter Coupon Description"
                                                id="description"
                                                value={this.state.description}
                                                onChange={(e:any)=>{
                                                    // this.setState({description: e.target.value})
                                                    this.handleChangeDescription(e)
                                                }}
                                                name="description"
                                                style={{
                                                    maxWidth: "89%",
                                                    minWidth: "89%",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#F9FAFB",
                                                    border: "0.2px solid gray",
                                                    fontFamily: "inter",
                                                    padding: "11px"
                                                }}
                                            />
                                        </Grid>
                                        <Grid container style={{ display: "flex", marginTop: "20px" }}>
                                            <Typography style={webStyle.switchBtn}>
                                                Coupon Status?
                                            </Typography>
                                            
                                            <FormGroup>

                                                <FormControlLabel
                                                    //  @ts-ignore
                                                    control={<SwitchStyle checked=
                                                        {this.state.checkedB}
                                                    onChange=
                                                        {this.handleChange}
                                                    name="checkedB" 
                                                    />
                                                    }
                                                    label="Active"
                                                />
                                            </FormGroup>

                                        </Grid>
                                        <Grid container spacing={4} className="discount-btn-section">
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained" 
                                                    style={{
                                                        width: "86%",
                                                        background: "transparent",
                                                        borderRadius: "10px",
                                                        color: "#096FFF",
                                                        height: "54px",
                                                        border: "1px solid #096FFF",
                                                        boxShadow: "none",
                                                        textTransform: "none"
                                                    }}
                                                    onClick={this.handleDiscountClose}
                                                    > 
                                                    Cancel 
                                                </Button>
                                            </Grid>

                                            <Grid item xs={6} >
                                                <Button style={{
                                                       width: "86%",
                                                       height: "54px",
                                                       color: "#fff",
                                                       background: "#096FFF",
                                                       borderRadius: "10px",
                                                       marginLeft: "-35px",
                                                       textTransform: "none"
                                                    }} 
                                                    variant="contained"
                                                    onClick={
                                                        this.handleCreateCoupon
                                                    }
                                                >
                                                Create
                                                </Button>

                                            </Grid>

                                        </Grid>

                                </Grid>
                            </Grid>

                                </Grid>
                        </Card>

                </DialogContent>

                </Dialog>

            </React.Fragment>

        );
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
const webStyle = {
    butnStyle: {
        width: "86%",
        height: "54px",
        color: "#fff",
        background: "#096FFF",
        borderRadius: "10px",
        marginLeft: "-35px",
        textTransform: "none"
    },
    cancelbutnStyle: {
        width: "86%",
        background: "transparent",
        borderRadius: "10px",
        color: "#096FFF",
        height: "54px",
        border: "1px solid #096FFF",
        boxShadow: "none",
        textTransform: "none"
    },
    postLinkModal: {
        boxShadow: "none",
        border: "1px solid #B6B5BB",
        borderRadius: "10px",
        marginBottom: "14px"
    },

    Postbtn: {
        background: "transparent",
        border: "none",
        color: "#096FFF",
        width: "150px",
        fontSize: "50px",
    },

    messageField: {
        background: "#F9FAFB",
        width: "90%",
        marginTop: "10px",
    },

    categoryRadio: {
        display: "flex",
        flexDirection: "row",
        gap: "65px",
    },
 
    smallMessageField: {
        marginTop: "10px",
        background: "#F9FAFB",
    },
  
    formlabel2: {
        marginTop: "15px",
        fontSize: "16px",
        fontFamily: "inter",
        fontWeight: 600,
        color: "#111827",
    },
    formlabel3: {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "inter",
        marginTop: "15px",
        marginBottom: "15px",
        color: "#111827",
    },
    formlabel: {
        fontFamily: "inter",
        fontSize: "16px",
        color: "#111827",
        fontWeight: 600,
    },
  
    dateField: {
        height: "40px",
        width: "292px",
        backgroundColor: "#F9FAFB",
        border: "0.2px solid gray",
        borderRadius: "8px"
    },
   
    diaHeader: {
        display: "flex",
        flexDirection: "column"
    } as React.CSSProperties,
    closeButton: {
        alignSelf: "end",
    },
    switchBtn: {
        marginTop: "10px",
        marginRight: "30px",
        fontSize: "16px",
        fontFamily: "inter",
        fontWeight: 600,
        color: "#111827",
    },
};

// Customizable Area End


