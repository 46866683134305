import React from "react";

import {
  Box,
  Grid,
  Checkbox,
  Switch,
  Typography,
  // Customizable Area Start
  Collapse,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const SwitchStyle = withStyles((theme) => ({
  root: {
    width: 52,
    height: 30,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(22px)',
      color: 'white',
      '& + $track': {
        backgroundColor: '#03AC26',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: 'grey',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 28,
    height: 28,
  },
  track: {
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      defaultChecked
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const CheckboxStyle = withStyles({
  root: {
    '&$checked': {
      color: '#4caf50',
    },
  },
  checked: {},
})(Checkbox);
// Customizable Area End

import AdminConsoleController, {
  Props,
} from "./AdminConsoleController";

export default class AdminConsole extends AdminConsoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { checked } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: '100%' }}>
          <Grid item sm={12} lg={12} xs={12} md={12} style={{ height: '100%' }}>
            <div style={webStyle.needBlock}>
              <h2 style={webStyle.needheading}>Settings</h2>
              <Grid style={webStyle.grid}>
                <Grid item>
                  <Box style={webStyle.firstbox}>
                    <div style={webStyle.boxdiv}>
                      <Typography style={webStyle.heading}>Notification Center</Typography>
                      <Typography style={webStyle.onoff}>{checked === true ? "On" : "Off"}</Typography>
                    </div>
                    <div style={webStyle.switchdiv}>
                      <SwitchStyle data-test-id="switch" checked={checked} onChange={this.notificationOnOff} />
                    </div>
                  </Box>

                  <NavLink to='/Settings/ChangePassword' style={webStyle.navLink}>
                    <Box style={webStyle.secondbox}>
                      <Typography style={{
                        ...webStyle.heading, paddingLeft: '20px',
                        paddingTop: '15px'
                      }}>Change Password </Typography>
                      <ArrowForwardIosIcon style={webStyle.arrowright} data-test-id="change_password" />
                    </Box>
                  </NavLink>

                  <NavLink to='/Settings/DietaryRestrictions' style={webStyle.navLink}>
                    <Box style={webStyle.fifthbox}>
                      <Box>
                        <Typography style={webStyle.tac} >Dietary restrictions</Typography>
                        <Typography style={webStyle.checkbox}><span>Add and edit dietary restrictions</span> </Typography></Box>
                      <ArrowForwardIosIcon style={webStyle.arrowright} data-test-id="Dietery_restrictions" />
                    </Box>
                  </NavLink>

                  <NavLink to='/Settings/FoodPreferencess' style={webStyle.navLink}>
                    <Box style={webStyle.fifthbox}>
                      <Box>
                        <Typography style={webStyle.tac} >Food Preferences</Typography>
                        <Typography style={webStyle.checkbox}><span>Add and edit Food Preferencess</span> </Typography></Box>
                      <ArrowForwardIosIcon style={webStyle.arrowright} data-test-id="Food_Preferencess" />
                    </Box>
                  </NavLink>

                  <NavLink to='/Settings/VenueSettings' style={webStyle.navLink}>
                    <Box style={webStyle.sixbox}>
                      <Box>
                        <Typography style={webStyle.tac} >Venue settings</Typography>
                        <Typography style={webStyle.checkbox}> <span>Customize venue settings as per your requirements</span> </Typography></Box>
                      <ArrowForwardIosIcon style={webStyle.arrowright} data-test-id="Venue" />
                    </Box>
                  </NavLink>

                  <Box style={webStyle.thirdbox}>
                    <div style={webStyle.privacydiv}>
                      <Typography style={webStyle.privacy}>Privacy Policy </Typography>
                      <ArrowForwardIosIcon style={{ height: '15px', width: '15px', cursor: 'pointer' }} data-test-id="privacy_btn" onClick={() => this.HandlePrivacypolicy()} />
                    </div>

                  </Box>
                  <Box style={webStyle.fourbox}>
                    <Box>
                      <Typography style={webStyle.tac} >Terms and Conditions</Typography>
                      <Typography style={webStyle.checkbox}><CheckboxStyle checked={this.state.termsCheckbox} onChange={this.checkboxOnOff} /> <span>I agree to the terms and conditions</span> </Typography></Box>
                    <ArrowForwardIosIcon style={{ height: '15px', width: '15px', margin: '15px', cursor: 'pointer' }} data-test-id="termscondition_btn" onClick={() => this.HandleTermsCondition()} />
                  </Box>
                  <div style={webStyle.btndiv}>
                    <button
                      style={webStyle.btntheme}
                      onClick={this.signout}
                      data-test-id="signout"
                    >
                      Logout
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid >
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px",
  },

  needBlock: {
    padding: "10px 0 30px 24px",
    width: ' 100%',
    marginLeft: '-25px',
  },

  grid: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    margin: "25px 18px 0 0",
    padding: "25px 30px",
    height: '87%'
  },
  heading: {
    color: '#001F49',
    fontFamily: 'Inter',
    fontWeight: 500,
    marginBottom: '13px',

  },
  heading2: {
    color: '#001F49',
    fontFamily: 'Inter',
    fontWeight: 400,
    marginTop: '10px'
  },

  firstbox: {
    width: '100%',
    height: 'auto',
    marginBottom: "20px",
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondbox: {
    width: '100%',
    marginBottom: "20px",
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  thirdbox: {
    width: '100%',
    marginBottom: "20px",
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    height: 'auto',

  },
  fifthbox: {
    width: '100%',
    marginBottom: "20px",
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    height: 'auto',
    display: 'flex',
    paddingBottom: '15px',
    justifyContent: 'space-between'
  },
  sixbox: {
    width: '100%',
    marginBottom: "20px",
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    height: 'auto',
    display: 'flex',
    paddingBottom: '15px',
    justifyContent: 'space-between'
  },
  fourbox: {
    width: '100%',
    marginBottom: "20px",
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  formInput: {
    background: 'rgb(244, 247, 249)',
    color: '#001F49',
    width: "100%",
    height: "40px",
    fontSize: "14px",
    borderRadius: "8px",
    margin: "10px 0px",
    border: "1px solid #E5E7EB",
    padding: '8px',
    outline: 'none',
  },
  btntheme: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    width: '30%',
    minWidth: '206px',
    height: ' 64px',
    borderRadius: "8px",
    fontSize: "16px",
  },
  btnchange: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "64px",
    borderRadius: "8px",
    fontSize: "16px",
    marginTop: '30px',
    marginBottom: '15px',
    minWidth: '170px',
    textTransform: "none",
  },
  needheading: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#111111",
    margin: "0",
    marginTop: '-4px'
  },
  btndiv: {
    marginTop: "64px",
  },
  boxdiv: {
    paddingLeft: '20px',
    paddingTop: '15px',
    width: '72%',
    maxWidth: '700px'
  },
  switchdiv: {
    paddingTop: '20px',
    paddingRight: '10px'
  },
  onoff: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#9CA3AF'
  },
  tac: {
    color: '#001F49',
    paddingTop: '15px',
    paddingLeft: '15px',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  navLink: {
    textDecoration: 'none'
  },
  privacy: {
    color: '#001F49',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  privacydiv: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  checkbox: {
    marginLeft: '15px',
    color: '#9CA3AF',
  },

  arrowright: {
    margin: '15px',
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    color: 'black'
  },
  visiblepass: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  }
};

// Customizable Area End

