import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
import {
  setStorageData,
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import { Alert } from "react-native";
const mainConfig = require("../../../framework/src/config")
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabPanelNo: number;
  restaurantDetail: any;
  imageFile: any;
  restaurantID: any;
  userName: string;
  userEmail: string;
  userMobile: string;
  userPic: string;
  profileImage: any;
  userFullPhoneNumber?: string;
  userAdress?: string;
  userPhoto: any;
  token: string;
  userProfileImage?: string;
  isLoading: boolean;
  loginEmail1: string;
  isLoginEmail1: boolean;
  checkValid1: string;
  isCheckValid1: boolean;
  checkUser: string;
  isUser: boolean;
  checkPhone: string;
  isPhone: boolean;
  followings_count : number;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  FileUploadCallId: string = "";
  apiEditProfileCallId: string = "";
  apiProfilePhotoUpdate: string = "";
  getUserProfileCallId: string = "";
  didFocusListner?: {
    remove: () => void
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      tabPanelNo: 0,
      restaurantDetail: "",
      imageFile: null,
      restaurantID: "",
      userName: "",
      userEmail: "",
      userMobile: "",
      userPic: "",
      profileImage: {},
      userPhoto: "",
      token: "",
      userProfileImage: "",
      isLoading: false,
      loginEmail1: "",
      isLoginEmail1: false,
      checkValid1: "",
      isCheckValid1: false,
      checkUser: "",
      isUser: false,
      checkPhone: "",
      followings_count:0,
      isPhone: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    this.handleResForImageUpdation(from, message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiEditProfileCallId) {
        this.putProfileData(responseJson, errorReponse);
      }
      if(apiRequestCallId ===  this.apiProfilePhotoUpdate){
         this.putPhoto(responseJson, errorReponse)
      }
      else if (apiRequestCallId === this.getUserProfileCallId) {
        this.handleAPIResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    this.didFocusListner?.remove()
  }

  //Web logic Function
  hanleTabs = (value: number) => {
    this.setState({ tabPanelNo: value });
  };

  setDataFromProps = (data: any) => {
    this.setState({ restaurantDetail: data.attributes, restaurantID: data.id });
  };

  handleFileUpload = (event: any, type: any) => {
    const selectedFile = event?.target?.files[0];

    const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];

    if (selectedFile) {
      const fileSizeInMB = selectedFile.size / (1024 * 1024);

      if (fileSizeInMB <= 5) {
        if (allowedFormats.includes(selectedFile.type)) {
          this.setState({ imageFile: selectedFile }, () =>
            this.FileUpload(type)
          );
        } else {
          ToastMessage(
            "error",
            `File '${selectedFile.name}' is not in PNG, JPEG, or JPG format.`
          );
        }
      } else {
        ToastMessage(
          "error",
          `File '${selectedFile.name}' exceeds the 5MB file size limit.`
        );
      }
    }
  };

  //API Function
  FileUpload = async (type: any) => {
    const formData = new FormData();

    if (type == "profile") {
      formData.append("data[profile_image]", this.state.imageFile);
    }
    if (type == "cover") {
      formData.append("data[cover_image]", this.state.imageFile);
    }

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FileUploadCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_location/restaurant_locations/${this.state.restaurantID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //API Response Function
  handleResForImageUpdation = async (from: any, message: Message) => {
    if (
      this.FileUploadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.errors) {
        ToastMessage("error", "Something went wrong");
      } else {
        ToastMessage("success", "Image is Successfully Updated");
        this.setState({ restaurantDetail: apiResponse.data.attributes });
        location.reload();

      }
    }
  };
   putPhoto= async (response:any , error:any)=>{
    if (!response?.errors && response?.data) {
      const profilephotoData = response.data.attributes;
      await setStorageData("userProfileData", JSON.stringify(profilephotoData));
      this.setState(
        {
          userEmail: profilephotoData.email,
          userName: profilephotoData.user_name,
          userFullPhoneNumber: profilephotoData.full_phone_number,
          userAdress: profilephotoData.address,
          userProfileImage: profilephotoData.profile_picture?.url
            ?  profilephotoData.profile_picture.url
            : "",
        },
        () => {
          this.setState({ isLoading: false });         
        }
      );
      Alert.alert("Profile photo uploaded successfully")
      this.props.navigation.navigate("Profile")
    } else {
      Alert.alert(response.message[0])
      this.parseApiErrorResponse(response);
      this.setState({ isLoading: false });
    }
   }
  cancelProfilePhoto = () => {
    this.setState({ userPic: "" })
    this.props.navigation.navigate('Profile')
   }
  putProfileData = (responseJson: any, errorReponse: any) => {
    if (responseJson != null) {
      if (responseJson.message) {
        this.setState({
          checkValid1: responseJson.message,
          isCheckValid1: true,
        });
      } else {
        this.successMessage(responseJson)
      }
    } 
  };
  setImage = (file: any, imageUrl: any) => {
    this.setState({ profileImage: file, userPic: imageUrl });
  }
  successMessage = async (response: any) => {
    await setStorageData(
      "userProfileData",
      JSON.stringify(response.data?.attributes)
    );
    Alert.alert("Your Profile details have updated successfully")
    this.props.navigation.navigate("Profile");
  };

  myAdressScreen = () => {
    this.props.navigation.navigate("MyAdrees");
  };

  getUserProfileData = () => {
    this.setState({ isLoading: true });
    const headers = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PROFILE_DETAILS_ENDPOINT
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAPIResponse = async (response: any) => {
    if (!response?.errors && response?.data) {
      const profileData = response.data.attributes;
      await setStorageData("userProfileData", JSON.stringify(profileData));
      this.setState(
        {
          userEmail: profileData.email,
          userName: profileData.user_name,
          userFullPhoneNumber: profileData.full_phone_number,
          userAdress: profileData.address,
          userProfileImage: profileData.profile_picture?.url
            ? profileData.profile_picture.url
            : "",
          followings_count : profileData.followings_count
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    } else {
      this.parseApiErrorResponse(response);
      this.setState({ isLoading: false });
    }
  };

  handleLogoutFunction = async () => {
    await this.logoutHandleFunction();
    this.props.navigation.replace("EmailAccountLoginBlock");
  };

  navigateToEdit = () => {
    this.props.navigation.navigate("UserEditBlock", {
      name: this.state.userName,
      email: this.state.userEmail,
      mobile: this.state.userFullPhoneNumber,
      profilePic: this.state.userProfileImage
    });
  };

  logoutHandleFunction = async () => {
    await removeStorageData("token-access");
    await removeStorageData("userName");
    await removeStorageData("userEmail");
    await removeStorageData("userProfileData");
  };

  goBackBtn = () => {
    this.props.navigation.navigate("Dashboard");
  };
  handleNameEdit = (name: any) => {
    this.setState({ userName: name, checkUser: "", isUser: false });
  };
  handleMobileEdit = (name: any) => {
    this.setState({ userMobile: name, checkPhone: "", isPhone: false });
  };
  handleEmailEdit = (name: any) => {
    this.setState({ userEmail: name , loginEmail1: "", isLoginEmail1: false, checkValid1: "", isCheckValid1: false  });
  };
  navigateToEditImage = () => {
    this.props.navigation.navigate("UserPhotoEdit");
  };

  onNext = () => {
    if(this.state.userName === ""){
      this.setState({
        checkUser: "Please enter your Name",
        isUser: true
      })
      return
    }
    if(!this.state.userMobile){
      this.setState({
        checkPhone: "Please enter your Mobile number",
        isPhone: true
      })
      return
    }
    if(!this.state.userEmail){
      this.setState({
        checkValid1: "Please enter your Email",
        isCheckValid1: true,
      })
      return
    }
    else {
      this.validate(this.state.userEmail, this.state.userMobile, this.state.userName);
    }
  };
  validate = (text: any, value: any, name: any) => {
    let Emailreg = configJSON.emailRegValid;
    let mobileReg = configJSON.mobileRegValid
    let nameReg =   configJSON.nameRegValid
    if (!Emailreg.test(text)) {
      this.setState({
        checkValid1: "Please enter valid Email",
        isCheckValid1: true,
      });
      return false;
    } 
    if(!mobileReg.test(value)){
      this.setState({
        checkPhone: "Please enter valid Mobile number",
        isPhone: true
      })
      return false
    }
    if(!nameReg.test(name)){
      this.setState({
        checkUser: "Please enter valid Name",
        isUser: true
      })
      return false
    }
    else {
      this.editProfileData();
    }
  };

  editProfileData = async () => {
    let photo = this.props.navigation.state.params?.photoFile;
    let usertoken = await getStorageData("token-access");

    if(this.state.userName === ""){
      this.setState({
        checkUser: "Please enter your Name",
        isUser: true
      })
      return false
    }
    if(this.state.userMobile === ""){
      this.setState({
        checkPhone: "Please enter your Mobile number",
        isPhone: true
      })
      return false
    }
    const header = {
      "Content-Type": "multipart/form-data",
      token: usertoken,
    };
    let formdata = new FormData();
    formdata.append("[data][attributes][email]", this.state.userEmail);
    formdata.append("[data][attributes][user_name]", this.state.userName);
    formdata.append(
      "[data][attributes][full_phone_number]",
      this.state.userMobile
    );
    if(photo){
      formdata.append("[data][attributes][profile_picture]", photo);
    }
     
      
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEditProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editPhotoData = async () => {
    let photo = this.state.profileImage
    if(Object.values(photo).length == 0){
      Alert.alert("Please select your Profile photo")
     }
    let usertoken = await getStorageData("token-access");
    const header = {
      "Content-Type": "multipart/form-data",
      token: usertoken,
    };
    let formdata = new FormData();
      formdata.append("[data][attributes][profile_picture]", photo);
     
      
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProfilePhotoUpdate = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  myPost = () => { { this.props.navigation.navigate("MyPosts",{ myPostScreen:true}) } }

  // Customizable Area End

}
