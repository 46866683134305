import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Platform } from "react-native";
import { ScreenName, TrendingFoodies } from "./type";
export interface DataListItem {
    id: string,
    attributes: {
        first_name: string,
        last_name: string,
        email: string,
        user_name:string,
        is_follow: string
    }
}

export interface DataListItemTwo {
    id: string,
    attributes: { account_email: string, email: string, current_user_id: string, account_id: string }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    tabPanelNo: number,
    listData: DataListItem[],
    followingData: DataListItem[],
    openModal: boolean,
    errorMsg: string,
    openFollowerModel: boolean
    postsCount: number,
    followersCount: string,
    userList: boolean,
    token: string,
    followerList: boolean,
    loder: boolean
    followingList: boolean,
    searchTerm: string,
    profilePhoto: string,
    showFollowersScreen: boolean,
    userListData: TrendingFoodies[]
    userFollowerListData: DataListItemTwo[]
    userFollowingListData: DataListItemTwo[]
    followers: any
    followersTrending: any
    searchText:string;
    pageNo:number;
    itemPerPage:number;
    totalPages:number;
    errorEnd : string,
    screenName : ScreenName,
    userAccountID:string | null
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}



export default class FollowersController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    followerCallId: string = "";
    userFollowerCallID: string = "";
    userFollowingCallID: string = '';
    addFollowerFromFollowingCallId: string = "";
    unFollowFromFollowingCallId: string = "";
    followFromFollowingCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            searchText:"",
            tabPanelNo: 0,
            listData: [],
            followingData: [],
            openModal: false,
            errorMsg: "",
            searchTerm: "",
            showFollowersScreen: true,
            userList: true,
            followerList: false,
            followingList: false,
            loder: true,
            userFollowerListData: [],
            userListData: [],
            userFollowingListData: [],
            followersCount: imgPasswordVisible,
            token: '',
            postsCount: 20,
            profilePhoto: 'https://via.placeholder.com/150',
            openFollowerModel: false,
            followers: [],
            pageNo: 1,
            itemPerPage: 10,
            totalPages: 0,
            errorEnd:"",
            followersTrending : [],
            screenName: configJSON.trendingFoodiesScreen,
            userAccountID:""
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        await this.getToken()
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
            const responseData = message.getData(
              getName(MessageEnum.SessionResponseData),
            );
            const token =  await getStorageData('token-access')
           this.setState({ 
            listData: [],
            followingData: [], 
            userListData:[], 
            searchText:"",
            userFollowerListData:[],
            userFollowingListData:[], 
            followers:[],
            token , 
            loder:true,
            pageNo:1,
            screenName:responseData?.screenName , 
            userAccountID :  responseData?.userID ?? "" },()=>{
           this.handleScreen()  
           })
          }
          else{
        this.handleResponseForSuggestion(from, message)
        this.handleResForUnFollowFromFollowing(from, message)
        this.handleResForFollowFromFollowing(from, message)
        this.handleResponseForAddFromFollowing(from, message)
        this.handleResponseForSuggestionFollowers(from, message)
        this.handleResponseForSuggestionFollowing(from, message)
    }
        // Customizable Area End
    }

    // Customizable Area Start
  

    getToken = async () => {
        const token: string = Platform.OS == 'android' || Platform.OS == 'ios' ? await getStorageData('token-access') : localStorage.getItem('authToken')
        this.setState({ token: token })
    }

    getListOfSuggestionData = async () => {
        this.setState({ loder: true })
        const token =  this.state.token
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        const followerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.followerCallId = followerDataMessage.messageId;

        const routes = `?${configJSON.page_no}=${this.state.pageNo}${configJSON.item_per_page}=${this.state.itemPerPage}&${configJSON.search}=${this.state.searchText}`
        
        followerDataMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.userListEndPoint + routes
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
    }

    getListOfSuggestionDataFollowers = async () => {
        this.setState({ loder : true })
        const token = this.state.token
        const accountUserId =  this.state.userAccountID
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const followerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userFollowerCallID = followerDataMessage.messageId;
        const endPoint = accountUserId ? configJSON.userListFollowersEndPoint + configJSON.followerFollowingEndPoints + accountUserId  : configJSON.userListFollowersEndPoint 
        const routes = `?${configJSON.page_no}=${this.state.pageNo}${configJSON.item_per_page}=${this.state.itemPerPage}&${configJSON.search}=${this.state.searchText}`
        followerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint + routes
            );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
    }

    getListOfSuggestionDataFollowing = async () => {
        this.setState({ loder : true })
        const token =  this.state.token
        const accountUserId =  this.state.userAccountID
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const followerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userFollowingCallID = followerDataMessage.messageId;
        const endPoint = accountUserId ? configJSON.userListFollowingEndPoint + configJSON.followerFollowingEndPoints + accountUserId  : configJSON.userListFollowingEndPoint
        const routes = `?${configJSON.page_no}=${this.state.pageNo}${configJSON.item_per_page}=${this.state.itemPerPage}&${configJSON.search}=${this.state.searchText}`
        followerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint + routes
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
    }

    addFromFollowing = async (account_id: string) => {
        this.setState({ loder: true })
        const token = await this.state.token
        let headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token

        };
        let httpBody = {}
        httpBody = {
            data: {
                    account_id: account_id,
            }
        }
        const addFollowerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addFollowerFromFollowingCallId = addFollowerDataMessage.messageId;

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addFollowingApiEndPoint}`
        );

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `${configJSON.postApiMethodType}`
        );

        runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);

    }

    unFollowFromFollowing = async (account_id: string) => {
        this.setState({ loder: true })
        const token = await this.state.token
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const unFollowDataUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.unFollowFromFollowingCallId = unFollowDataUserMessage.messageId;

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.unFollowApisApiEndPoint}/${account_id}`
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `${configJSON.deleteApiMethodType}`
        );

        runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
    }

    followingOnPress = () => { this.setState({ userList: false, followerList: false, followingList: true }) };

    followerListOnPress = () => { this.setState({ userList: false, followerList: true, followingList: false }) };

    userListOnPress = () => { this.setState({ userList: true, followerList: false, followingList: false }) };

    handleResponseForSuggestion = (from: string, message: Message) => {

        if (this.followerCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })
            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })

            } else {
                const trendingFoodiData = this.state.userListData.concat(apiResponse.data)
                const followersTrending = this.state.followersTrending.concat(apiResponse.data)
                this.setState({ 
                    userListData: trendingFoodiData ,
                    followersTrending: followersTrending , 
                    totalPages : apiResponse?.meta?.page?.total_pages ?? 0
                })
            }
        }
    }
    handleResponseForSuggestionFollowers = (from: string, message: Message) => {

        if (this.userFollowerCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })
                if (apiResponse.errors[0].message == "Not following to any user.") {
                    this.setState({ userFollowerListData: [] })
                }

            } else {
                const userFollowerListData = this.state.userFollowerListData.concat(apiResponse.data)
                const followers = this.state.followers.concat(apiResponse.data)
                this.setState({ 
                    userFollowerListData: userFollowerListData ,
                    followers: followers , 
                    totalPages : apiResponse?.meta?.page?.total_pages ?? 0
                })
            }
        }
    }
    handleResponseForSuggestionFollowing = (from: string, message: Message) => {

        if (this.userFollowingCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })

            } else {
                const userFollowingListData = this.state.userFollowerListData.concat(apiResponse.data)
                const followers = this.state.followers.concat(apiResponse.data)
                this.setState({ 
                    userFollowingListData: userFollowingListData,
                    followers: followers, 
                    totalPages : apiResponse?.meta?.page?.total_pages ?? 0
                })
            }
        }
    }
    handleResponseForAddFromFollowing = async (from: string, message: Message) => {
        if (this.addFollowerFromFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })
            if (apiResponse.errors) {
                this.parseApiErrorResponse(apiResponse)
                this.setState({ errorMsg: apiResponse.errors , loder: false})
            } else {
                this.setState({
                    followers: [], followersTrending: [], pageNo : 1 , 
                    userListData : [] , 
                    userFollowerListData:[],
                    searchText:"",
                     userFollowingListData:[]  
                    })
                this.handleScreen()
            }
        }
    }

    handleScreen = async () => {
        switch (this.state.screenName) {
          case configJSON.trendingFoodiesScreen:
            await this.getListOfSuggestionData();
            break;
          case configJSON.screenNameFollowings:
            await this.getListOfSuggestionDataFollowers();
            break;
          case configJSON.screenNameFollowers:
            await this.getListOfSuggestionDataFollowing();
            break;
          default:
            break;
        }
      };
    handleResForFollowFromFollowing = async (from: string, message: Message) => {
        if (this.followFromFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponseFollower = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })
            if (apiResponseFollower.errors) {
                this.parseApiErrorResponse(apiResponseFollower)
                this.setState({ errorMsg: apiResponseFollower.errors })
            } else {
                this.setState({
                    followers: [], followersTrending: [], pageNo : 1 , 
                    userListData : [] , 
                    userFollowerListData:[],
                     userFollowingListData:[]  
                    })
                this.handleScreen()
            }
        }

    }
    handleResForUnFollowFromFollowing = async (from: string, message: Message) => {
        if (this.unFollowFromFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder :  false })
            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })
            } else {
                this.setState({
                    followers: [], followersTrending: [], pageNo : 1 , 
                    userListData : [] , 
                    userFollowerListData:[],
                     userFollowingListData:[]  
                    })
                this.handleScreen()
            }
        }

    }

    renderCheck = (item: any) => {
        let data = this.state.followers;
        for (let value of data) {
            if (value.id == item.id) {
                if (!value.isTouchable) {
                    value.isTouchable = true;
                } else {
                    value.isTouchable = false;
                }
                break;
            }
        }
        this.setState({ followers: this.state.followers });
        this.followAPIReq(item.id)
    };

    onPressDetlies = (item: any) => { this.props.navigation.navigate("FollowerProfile", {userId: String(item.id)}) }

    //for Web File
    hanleTabs = (event: object, value: number) => {
        this.setState({ tabPanelNo: value })
    }


    followAPIReq = (userAccId:string)=> {
        let headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
    
        let httpBody = {
            data: {
                    account_id: userAccId,
            }
        }
        const addFollowerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.followFromFollowingCallId = addFollowerDataMessage.messageId;
    
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.followEndpoint
        );
    
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
    
        runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);
      }

      handleSearch = (text: string) => {
        this.setState({ searchText: text ,
            listData: [],
            followingData: [], 
            userListData:[], 
            userFollowerListData:[],
            userFollowingListData:[], 
            followers:[],
            loder:true,
            pageNo:1,
            followersTrending: [] ,
        }, () => this.handleScreen())
      };

      fetchMoreData = () => {
        if (this.state.loder) return null;
        if (this.state.totalPages >= this.state.pageNo) {
          this.setState({ pageNo: this.state.pageNo + 1, errorEnd: "" }, () => {
            this.handleScreen();
          });
        } else {
          this.setState({ errorEnd: configJSON.End });
        }
      };

      async componentWillUnmount() {
          this.setState({ pageNo : 0 })
      }
    // Customizable Area End
}
