Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.googleApiEndPoint= 'account_block/create_social_account';
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.loginWebAPiEndPoint = "/bx_block_login/logins";
exports.phoneRegx = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
exports.emailRegx = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.instructionforlogin = "Enter your email address to log in."
exports.errorPswMsg = "Password is incorrect."
exports.errorAccMsg = "Account not found"
exports.errorAuthMsg = "You are not authorized"
exports.errorVerifyMsg = "Account need to verify OTP"
exports.errorEmail404Msg ="Something went wrong."
exports.errMesgBackend = "Please Add valid Email and Password"
exports.errMesgBackendPhone = "Please Add valid Phone and Password"
exports.errMesgOTP = "Please enter OTP that we just shared on your email."
exports.typePhone = "Phone"
exports.typeEmail = "email"
exports.exitAppMessage = "Are you sure you want to exit app?"
exports.holdOnMessage = 'Hold on!'
exports.SUBSCRIPTIONApi = "bx_block_subscriptions/subscriptions"
exports.create_login = "is_account_details_present"
// Customizable Area End
