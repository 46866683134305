import React from 'react'

// Customizable Area Start
import {
  MenuItem, Menu, Divider, ListItemIcon, ListItemText, Avatar, Backdrop, Grid
} from "@material-ui/core";
import { withStyles, Theme } from "@material-ui/core/styles";
import NotificationMenuController, { Props } from './NotificationMenuController.web';
import { NavLink } from "react-router-dom";
// Customizable Area End


// Customizable Area Start
const StyledMenu: any = withStyles((theme: Theme) => { }
)((props: any) => <Menu {...props}
  getContentAnchorEl={null}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  PaperProps={{
    style: {
      maxHeight: "400px",
      width: "300px",
      marginTop: "10px",
      marginRight: "200px",
      borderRadius: '8%'
    }
  }}
  disableScrollLock={true}
/>);
// Customizable Area End


export default class NotificationMenu extends NotificationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { handleCloseNot, show, anchorElprops } = this.props;
    const { menuItems } = this.state;

    return (
      <>
        <StyledMenu
          open={show}
          onClose={handleCloseNot}
          id="basic-menu"
          anchorEl={anchorElprops}
          data-test-id='selectedmenu'
        >
          <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListItemText primary="Notifications Center" primaryTypographyProps={{ style: classes.header }} />
            <NavLink to='/ViewAllNotification' style={classes.viewall} onClick={handleCloseNot} >View All</NavLink>
          </Grid>
          {menuItems ?this.MenuItemConcat().map((menuItem: any, index: any) => (
              <div>
                <NavLink to="/OrderDetailView" style={{ textDecoration: 'none', color: 'gray' }}><MenuItem key={index} data-test-id="menu_close"
                  onClick={() => {this.menuitemonClick(menuItem)}}>
                  <ListItemIcon>
                    <div style={{ position: 'relative' }}>
                      {menuItem.attributes.is_read === true ? "" : <div style={classes.Dot} />}

                      <Avatar style={classes.avatar} >{menuItem.attributes.account?.data?.attributes?.profile_picture ? menuItem.attributes.account?.data?.attributes?.profile_picture : menuItem.attributes.account?.data?.attributes?.user_name.slice(0, 2)}</Avatar>
                    </div>
                  </ListItemIcon>
                  <div style={classes.textdiv}>
                    <ListItemText primaryTypographyProps={{
                      style: menuItem.attributes.is_read === true ? classes.ListitemRead : classes.Listitem
                    }}>
                      {menuItem.attributes.contents}</ListItemText>
                    <div style={classes.time}>
                      {new Date(menuItem.attributes.created_at).toLocaleString('en-IN', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </div>
                  </div>
                </MenuItem></NavLink>
                <Divider style={classes.divider} />
              </div>
            )) : <span style={{ color: '#6a6a6f', marginLeft: '30px' }}>No Notification Found</span>}

        </StyledMenu>
        <Backdrop open={show}
          onClick={handleCloseNot}
        />
      </>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
interface StyleType {
  avatar: any;
  Dot: any;
  Listitem: any;
  ListitemRead: any;
  time: any;
  header: any;
  viewall: any;
  textdiv: any;
  divider: any;
}
const classes: StyleType = {
  avatar: {
    width: "32px",
    height: "32px",

  },
  Dot: {
    position: 'absolute',
    top: '-4px',
    right: '28px',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: 'orange'
  },
  Listitem: {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',

  },
  time: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#9D9D9D',
  },
  header: {
    padding: '10px 0 10px 20px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px'
  },
  viewall: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    color: '#096FFF',
    paddingTop: '15px',
    paddingRight: '35px',
    textDecoration: 'none',
  },
  textdiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  divider: {
    width: '87%',
    marginLeft: '18px'
  },
  ListitemRead: {
    maxWidth: '200px',
    fontWeight: 500,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    textOverflow: 'ellipsis',
    lineHeight: '16px',
    color: '#9D9D9D',
  },
}
// Customizable Area End
