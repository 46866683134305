import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleClose: () => void;
    show: boolean;
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    data:string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class RejectpostController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
  
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            data:''
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
       
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
     
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function   
    handleInputChange = (e: any) => {
        this.setState({data: e.target.value });
    };
    //API Function
    
    //API Response Function

    // Customizable Area End

}