import React from 'react'
// Customizable Area Start
import {
    Typography,
    Grid,
    Avatar,
    Button,
    Card, CardContent, CardActions, ListItem, List, ListItemAvatar, ListItemText, CardMedia, Menu, MenuItem
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RejectedPostsController from './RejectedPostsController';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {dummyImg} from "./assets"
import ShowDetailedRejectedPosts from './ShowDetailedRejectedPosts.web';
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End

// Customizable Area Start
export default class RejectedPosts extends RejectedPostsController {
  render() {
    return (
    <div>
    <Grid container spacing={3} direction="row">
    {this.state.loader ? <PulseLoader color="#096fff" className="loader" />  : (this.state.getRejectPostsData.length > 0 ?
     (this.state.getRejectPostsData?.map((item:any, index:any)=> {
        return (
         <Grid item xs={12} lg={3}>  
            <Card style={webStyle.postCardRoot} key={index}>

                <List style={webStyle.cardList}>
                    
                    <ListItem style={webStyle.cardListItem}>

                        <ListItemAvatar style={{ minWidth: '48px' }}>

                            <Avatar src={item.attributes?.profile_picture?.url} 
                              style={{ width: "32px", 
                                       height: "32px" 
                                       }} 
                                    />
                        </ListItemAvatar>
                        <ListItemText disableTypography

                            secondary={<Typography variant="caption" color="secondary">{item.attributes?.day_ago}</Typography>}

                            primary={<Typography variant="h5">{item.attributes?.accounts.user_name}</Typography>} />
                    </ListItem>
                       <div>
                <IconButton 
                 style={{width: "24px",height: "24px",margin: "6px",borderRadius: "50%",backgroundColor: "rgba(0, 0, 0, 0.24)",
                        right: 10,top: 20,cursor: "pointer"
                      }}
                    data-test-id={`anchor-${index}`}
                    onClick={(e) => {this.handleClickopenAnchor(e,item.id,item.attributes?.description,item.attributes?.files)}}>
                    <MoreHorizIcon style={{ color: "white" }} />
                </IconButton>
                <Menu
                    disableScrollLock={false}
                    anchorEl={this.state.anchorElMenuMoreBtn}
                    open={Boolean(this.state.anchorElMenuMoreBtn)}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                    onClose={this.handleCloseMenuDelete}
                >
                    <MenuItem onClick={() => 
                        this.handleOpenDetailsModal()
                    }
                    data-test-id={`deletemo-${index}`}
                    > 
                    View post details
                    </MenuItem>
                </Menu>
                </div>
                </List>
                { item.attributes?.files.length == 0 && 
                    <div>
                    <CardMedia
                    style={{ 
                        width: '100%', 
                        height: "170px"
                     }}
                    image={dummyImg} title="Paella dish"
                    /> 
                    <p style={{
                        textAlign: "center",
                        fontSize:"8px",
                        color:"red"
                        }}
                    >
                        No pictures posted yet!
                    </p>
                    </div>       
                }
                {(item.attributes?.files && item.attributes?.files.length !== 0) &&
                <Carousel
                        indicators={true}
                        autoPlay={false}
                        navButtonsAlwaysVisible
                        swipe
                        className="my-carousel"
                        NextIcon={<ArrowForwardIosIcon fontSize='small' style={{color: "#fff"}}/>}            
                        PrevIcon={<ArrowBackIosIcon fontSize='small' />}
                    >    
            {item.attributes?.files.map((file: any, index: number) => (
                <CardMedia key={index} style={{ width: '100%', height: '170px' }}>
                    {file.url.endsWith('.jpg') || file.url.endsWith('.jpeg') || file.url.endsWith('.png') ? (
                        <img
                            src={file.url}
                            alt=""
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        />
                    ) : 
                    file.url.endsWith('.mp4') && (<video width="100%" height="100%" controls>
                            <source src={file.url} type="video/mp4" />
                        </video>)}
                  </CardMedia>
                ))}
                </Carousel>
                } 
                <CardContent>
                    <Typography variant="caption" 
                      style={{color: "#4B5563",lineHeight: "16px",overflow: "hidden",display: "-webkit-box",WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2, // Limit to 2 lines
                        textOverflow: "ellipsis", // Add ellipsis at the end
                        wordBreak: "break-all",
                        height: "35px"
                        }}   
                        >
                        {item.attributes?.description}
                    </Typography>

                </CardContent>
                <CardActions style={{
                    padding: "9px 12px 16px 12px",
                    textAlign: "center",
                    justifyContent: "center"
                 }}
                >
                    <Button fullWidth variant="contained"
                      style={{
                        backgroundColor: item.attributes?.status=="rejected"? "red": "#d2d2d2",
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "20px",
                        width: "95%"
                      }}
                     >
                        Post &nbsp;{item.attributes?.status}
                    </Button>
                </CardActions>
            </Card>
        </Grid>    
          )
       })
    ): <p className='noPosts'>No posts available</p>)
   }
    </Grid>  

             <div>
                <ShowDetailedRejectedPosts navigation={undefined} handleClose={this.handleCloseDetailsModal}
                    show={this.state.openImgCardModal} postID={this.state.detailsShowId}
                    detailsData={this.state.showDetatilsData} imagesData={this.state.showImageData} />
            </div> 
 </div>
)}}

// Customizable Area Start
const webStyle = {
    childrenSection: {
        marginTop: "24px",
        padding: "24px",
        borderRadius: "8px",
        position: "relative"
    },
    featureMenu: {
        marginTop: "17px"
    },
    cardList: {
        padding: "0px",
        display: "flex"
    },
    cardListItem: {
        padding: "12px"
    },
    postCardRoot: {
        borderRadius: "8px",
        boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.12)",
        maxHeight: "400px",
        minHeight: "400px"
    },
    cardActionBtnContainer: {
        padding: "9px 12px 16px 12px",
        textAlign: "center",
        justifyContent: "center"
    }
};
// Customizable Area End