import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Dialog,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area End

// Customizable Area Start
const customTheme = createTheme({
   
    typography: {
        "fontFamily": `inter, sans-serif`,
        body1: {
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: 400,
        },
        caption: {
            fontWeight: 300,
            fontSize: "12px",
            lineHeight: "24px"
        },
        h1: {
            fontWeight: 500,
            lineHeight: "27px",
            fontSize: "20px",
        },
      
    },
    palette: {
        secondary: { main: "#9CA3AF" },
        primary: { main: '#096FFF' },
    },
});

export const StyledButton1: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            fontSize: "16px",
            minHeight: "64px",
            fontWeight: 500,
            textTransform: 'none',
            borderRadius: "8px"
        },
    }),
)((props: any) => <Button {...props} />);

// Customizable Area End

// Customizable Area Start
import RejectpostController,{Props} from "./RejectpostControllerWeb";
// Customizable Area End

export default class RejectPost extends RejectpostController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={customTheme}>

                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                >
                    <Grid container>
                        <Grid container >
                            <Grid item  style={webStyle.dialogHeadingSection}>
                                <Typography style={{ padding: "16px 0px" }} variant="h1">Reject Post</Typography>
                                <IconButton data-test-id="cancle_icon" onClick={this.props.handleClose}>
                                    <CancelIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={webStyle.dialogMainSection}>
                        <div>
                    <label htmlFor="desc" style={webStyle.labelfont}>
                      Please share rejection reason
                      <sup style={webStyle.themeColor}>*</sup>
                    </label>
                    <textarea
                      id="desc"
                      style={webStyle.textarea as React.CSSProperties}
                      name="description"
                      placeholder="Enter rejection reson..."
                      data-test-id="rejection_reason"
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>

                            <Grid container direction="row" spacing={2} style={{ margin: "48px 0px" }}>
                                <Grid item xs={12} lg={6}>
                                    <StyledButton1 onClick={this.props.handleClose} fullWidth variant="outlined" color="primary" data-test-id='cancle'>
                                        Cancel
                                    </StyledButton1>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <StyledButton1 fullWidth variant="contained" color="primary" data-test-id="reject">
                                        Reject
                                    </StyledButton1>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Dialog>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    dialogHeadingSection: {
        padding: "0px 50px",
        borderBottom: "1px solid #9CA3AF",
        display:'flex',justifyContent:'space-between',
        width:'100%'
    },
    dialogMainSection: {
        padding: "0px 50px",
        marginTop: "40px"
    },
    themeColor: {
        color: "#096fff",
      },
    
      labelfont: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
      },
    
      textarea: {
        padding: "6px 10px",
        outline: "none",
        border: "1px solid #e5e7eb",
        borderRadius: "5px",
        width: "100%",
        background:'rgb(244, 247, 249)',
        color:'#001F49',
        minHeight: "100px",
        resize: "none",
        marginTop: "10px",
        marginBottom: "20px",
      },
};
// Customizable Area End