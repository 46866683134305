import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/common";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  tabPanelNo: number;
  contactData:any;
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContectFuduDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getcontactquerycallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabPanelNo: 1,
      contactData:[],
      isLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responJson) { 
        if (
          apiRequestCallId === this.getcontactquerycallId &&
          responJson?.data
        ) {
          
          this.setState({ contactData: responJson?.data ,isLoading:false}, () => {
            this.setState((prevState) => {
              return {
                contactData: prevState.contactData.slice().reverse(),
              };
            });
          });
        }
      }
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  componentDidMount = async () => {
    this.getContactQuery();
    
  };
  createQuery = () => {
    this.props.navigation.navigate("ContactFudu");
  };

  hanleTabs = (event: object, value: number) => {
    this.setState({ tabPanelNo: value })
  }
  // Customizable Area End
 

  // Customizable Area Start
  handleView=(id:string)=>{
    localStorage.setItem("ContactfuduId",id)
    this.props.navigation.navigate("ContectFuduChat");
  }
  getContactQuery = () => {
    let tokenValue = localStorage.getItem('authToken');
    const headerData={
        token:tokenValue
    }
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: 'bx_block_contact/contacts',
      httpMethod: 'GET'
    })
    this.setState({isLoading:true})
    this.getcontactquerycallId = req.messageId;
    runEngine.sendMessage(req.id, req);
  }
  // Customizable Area End
}
