import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/common";

// Customizable Area Start
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  chatStatus: boolean;
  contactDatabyId:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContectFuduChatController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getcontactcallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      
      getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
     getName(MessageEnum.SessionResponseMessage),
   
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      chatStatus: localStorage.getItem('chatStatus') === 'unresolved',
      contactDatabyId:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {

          if (
            apiRequestCallId === this.getcontactcallId &&
            responseJson?.data
          ) {
            this.setState({ contactDatabyId: responseJson?.data?.attributes?.comment});
          }
        }
      }
      runEngine.debugLog("Message Recived", message);
      // Customizable Area End
    }

  // Customizable Area Start
  componentDidMount = async () => {
    this.getContactQuerybyID();
  };

  getContactQuerybyID = () => {
    let tokenValue = localStorage.getItem('authToken');
    let QueryID=localStorage.getItem('ContactfuduId');
    const headerData={
        token:tokenValue
    }
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: `bx_block_contact/contacts/${QueryID}`,
      httpMethod: 'GET'
    })
    this.getcontactcallId = req.messageId;
    runEngine.sendMessage(req.id, req);
  }
  // Customizable Area End
}
