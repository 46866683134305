import React from 'react'

// Customizable Area Start
import Grid from '@material-ui/core/Grid';
import { masterCard } from '../../dashboard/src/assets';
import BuySubscriptionController from './BuySubscriptionController';
import { withRouter } from "react-router-dom";
// Customizable Area End

// Customizable Area Start
class BuySubscription extends BuySubscriptionController {

    render() {
        return (
            <>
                <h2>Subscription {">"} Buy Subcription {">"} Payments</h2>
                <Grid container>
                    <Grid item sm={12}>
                        <div className="mylocation">
                            <div className='amount-section'>
                                <h3>Amount Payable : $ 50</h3>
                                <p className="add-card" onClick={() => this.props.navigation.navigate("AddCard")} data-testid="addanothercard">Add another card</p>
                            </div>
                            <p className="mt0 mb25 fw500">Select card to proceed</p>
                            <Grid container>
                                {[0, 1].map((elem, i) => {
                                    return (
                                        <Grid item lg={6} key={i}>
                                            <Grid item lg={8} md={12}>
                                                <label className="label-box">
                                                    <div>
                                                        <input type="radio" />
                                                    </div>
                                                    <div className="card-section blueborder">
                                                        <div className="">
                                                            <img src={masterCard} alt="" className="mb20" />
                                                            <p className="card-number">
                                                                **** **** 4444
                                                            </p>
                                                            <p className="card-expiry-date">
                                                                Expires 05 / 27
                                                            </p>
                                                        </div>
                                                    </div>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <div className="btn-div">
                                    <button className="btn-theme">Pay $50</button>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }
}
// @ts-ignore 
export default withRouter(BuySubscription);
export { BuySubscription }
// Customizable Area End
