import React from 'react'
// Customizable Area Start
import { Grid, Typography, Button, Menu, MenuItem, Box} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { group_arrow, group_arrow2, group_arrow3, group_arrow4 } from "./assets";
// Customizable Area End

// Customizable Area Start
import ShowOffersAndDiscountsControllerWeb, { Props } from "./ShowOffersAndDiscountsControllerWeb";
import DiscountCoupon from './DiscountCoupon.web';
import ShowDiscountCoupon from './ShowDiscountCoupon.web';
// eslint-disable-next-line
import "./Coupon.css"


export default class ShowOffersAndDiscounts extends ShowOffersAndDiscountsControllerWeb {
    constructor(props: Props) {
        super(props)

    }

    render() {
        const {getAllCoupons} = this.state
        return (
            <div style={{ height: '100vh' }}>
                {(this.state.getDiscountCouponData.length === 0 && !this.state.filterResult) ?
                    (
                        <div >
                            <div>
                                <h2 style={{ marginTop: '5px'}}>Offers & Discounts</h2>
                            </div>
                            <div style={{
                                display: 'flex',
                                width: '98%',
                                height: '100vh',
                                borderRadius: "10px",
                                backgroundColor: '#fff',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;',
                            }}>
                                <div>
                                    <Typography style={{ width: '100%', paddingLeft: '12px' }}>No offers or discount coupon created yet</Typography>
                                    <Button variant='contained' color='primary' startIcon={<AddIcon />} style={{ padding: '20px', marginTop: "30px", marginLeft: '20px', width: '92%' }}
                                      data-test-id = "couponModal"
                                        onClick={() =>
                                            this.setState({ openDiscountCouponModal: true })
                                        }
                                    >
                                        Create an Offer/Coupon
                                    </Button>
                                </div>
                            </div>
                            <DiscountCoupon navigation={undefined} show={this.state.openDiscountCouponModal} handleClose={this.handleCloseDiscountCoupon} />
                        </div>
                    ) : (
                        <div>
                            <h2 style={{ marginTop: "5px" }}>Offers & Discounts</h2>
                            
                            <div style={webStyle.mainBoxBody}>
                                <div style={{ position: "relative", borderRadius: "10px", paddingLeft: '27px', paddingRight: '10px', display: "flex" }} >

                                    <TextField
                                        data-test-id="text"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        id="discounts"
                                        placeholder="Search discounts by name/code..."
                                        type="text"
                                        style={{
                                            width: "40%",
                                            borderRadius: "5px",
                                            background: "#F9FAFB",
                                            fontFamily: "inter",
                                            marginTop: "30px",
                                            minWidth: '100px',
                                            marginLeft:"10px"
                                        }}
                                        name="discounts"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={this.state.searchCoupon}
                                        onChange={(e: any) => {
                                            this.HandleSearch(e)
                                        }}
                                        onKeyPress={this.searchCouponCall}
                                    />
                                    <div style={{ color: "#9CA3AF", marginTop: "40px", flex: '0.9', cursor: "pointer", marginLeft: '40px' }}>
                                        <FilterListOutlinedIcon onClick={this.handleOpenFilter} />
                                    </div>

                                    <div className='create-offer-button'>
                                        <Button variant='contained' color='primary' style={{ borderRadius: '8px' ,width:'100%'}}
                                            onClick={this.handleOpenDiscountCouponModal}
                                        >
                                            Create Offer/Discount
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    {/* @ts-ignore    */}
                                    <DiscountCoupon navigation={undefined} show={this.state.openDiscountCouponModal} handleClose={this.handleCloseDiscountCoupon} />
                                </div>

                                <Grid container>
                                    {this.state.openFilter &&
                                        <div style={{ position: "absolute", marginLeft: "390px", marginTop: "-16px" }}>
                                            <Box
                                                boxShadow={3}
                                                bgcolor="background.paper"
                                                m={1}
                                                p={1}
                                                style={{ width: '10rem', height: '14rem' }}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                    <Typography style={{ fontSize: '19px', fontWeight: 600 }}>FILTER</Typography>
                                                    <Typography style={{ fontSize: '19px', fontWeight: 600, marginLeft: '-5px', cursor: "pointer" }}
                                                        onClick={this.handleCloseFilter}
                                                    >
                                                        X
                                                    </Typography>

                                                </div>
                                                <FormControl component="fieldset">
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            data-test-id='All'
                                                            control={<Checkbox checked={this.state.all}
                                                                data-test-id='all'
                                                                value="all"
                                                                onChange={(e: any) => {
                                                                    this.handleChangeAll(e)
                                                                }}
                                                                name="all" />}
                                                            label="All"
                                                        />
                                                        <FormControlLabel
                                                            data-test-id="ActiveAll"
                                                            control={<Checkbox checked={this.state.active}
                                                                data-test-id='active'
                                                                value="active"
                                                                onChange={(e: any) => {
                                                                    this.HandleChangeActive(e)                      
                                                                }}
                                                                name="active" />}
                                                            label="Active"
                                                        />
                                                        <FormControlLabel
                                                            data-test-id="InactiveAll"
                                                            control={<Checkbox checked={this.state.inactive}
                                                                data-test-id='inactive'
                                                                value="inactive"
                                                                onChange={(e: any) => {
                                                                    this.handleChangeInactive(e)
                                                                }}
                                                                name="inactive" />}
                                                            label="Inactive"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                                <Button variant='contained' color='primary' style={{ width: '157px', marginTop: '10px', marginLeft: "2px" }}
                                                    onClick={this.filterCouponCall}
                                                >
                                                    apply filter</Button>
                                            </Box>
                                        </div>
                                    }
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table style={webStyle.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                 {/* @ts-ignore */}
                                                <TableCell style={webStyle.MuiTableCell1}>Offer/Coupon Name</TableCell>
                                                {/* @ts-ignore */}
                                                <TableCell align="center" style={webStyle.MuiTableCell2}>Code</TableCell>
                                                {/* @ts-ignore */}
                                                <TableCell align="center" style={webStyle.MuiTableCell3}>Amount Off</TableCell>
                                                {/* @ts-ignore */}
                                                <TableCell align="center" style={webStyle.MuiTableCellT}>Status</TableCell>
                                                {/* @ts-ignore */}
                                                <TableCell align="center" style={webStyle.MuiTableCellT}>Products</TableCell>
                                                {/* @ts-ignore */}
                                                <TableCell align="center" style={webStyle.MuiTableCellT}>Consumed</TableCell>
                                                {/* @ts-ignore */}
                                                <TableCell align="center" style={webStyle.MuiTableCellT}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {this.state.searchCouponData.length == 0 ? (

                                            <TableBody>
                                                {this.state.getDiscountCouponData?.map((row: any, index: any) => {
                                                    return (
                                                        <>
                                                            <TableRow key={index} style={webStyle.MuiTableCell}>
                                                                {/* @ts-ignore */}
                                                                <TableCell component="th" scope="row" style={webStyle.MuiTableCell}>
                                                                    {/* @ts-ignore */}
                                                                    <div>
                                                                        <Typography style={{ fontSize: "14px", color: "#111827" }}>{row?.attributes?.coupon_name}</Typography>
                                                                    </div>

                                                                </TableCell>
                                                                <TableCell align="center" style={webStyle.MuiTableCellCode}>{row?.attributes?.code}</TableCell>
                                                                <TableCell align="center" style={webStyle.MuiTableCell}>KD{row?.attributes?.up_to_ammount}</TableCell>
                                                                <TableCell align="center" style={webStyle.MuiTableCell}>
                                                                    <FiberManualRecordIcon style={row?.attributes?.coupon_status ? webStyle.fiberActionG : webStyle.fiberActionR} />
                                                                    {row?.attributes?.coupon_status ? "Active" : "Inactive"}
                                                                </TableCell>
                                                                 {/* @ts-ignore */}
                                                                <TableCell align="center" style={webStyle.MuiTableCellB}>{row?.attributes?.coupon_category}</TableCell>
                                                                <TableCell align="center" style={webStyle.MuiTableCell}>{row?.attributes?.consumed}</TableCell>
                                                                <TableCell align="center" style={{ border: "none", marginLeft: "20px", position: "relative", cursor: "pointer" }}
                                                                    onClick={(e: any) => {
                                                                        this.handleEditBox(e)
                                                                        this.handleShowDiscountCouponAll(row?.attributes?.id, row?.attributes?.coupon_status)
                                                                    }}
                                                                    data-test-id={`anchor-0`}
                                                                >
                                                                    <MoreHorizIcon style={{ color: "#9CA3AF" }} />
                                                                </TableCell>
                                                                <Menu

                                                                    anchorEl={this.state.openAction}
                                                                    open={Boolean(this.state.openAction)}
                                                                    onClose={this.handleCloseMenu}
                                                                    getContentAnchorEl={null}
                                                                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                    style={{
                                                                        marginLeft: "-65px", marginTop: "-40px", border: "none",
                                                                    }}

                                                                    PaperProps={{
                                                                        style: { boxShadow: 'rgba(0, 0, 0, 0.1) 0.5px 1px 2px' }
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 500,

                                                                            color: "#111827",
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={() =>
                                                                            this.setState({ openShowDiscountCouponModel: true, openAction: null })
                                                                        }
                                                                        data-test-id={`edit-0`}
                                                                    >
                                                                        Edit
                                                                    </MenuItem>

                                                                    <MenuItem
                                                                          style={{
                                                                            fontSize: '14px',fontWeight: 500,color: "#111827",
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={() => {
                                                                            this.makeInactivecall()
                                                                        }}
                                                                        data-test-id={`make-0`}
                                                                    >
                                                                        {this.state.couponStatus ? "Make Inactive" : "Make Active"}
                                                                    </MenuItem>

                                                                </Menu>
                                                            </TableRow>
                                                        </>
                                                    )
                                                })}
                                            </TableBody>
                                        ) :
                                            (

                                                <TableBody>
                                                    {this.state.searchCouponData?.map((searchvalue: any, index1: any) => {
                                                        return (
                                                            <>
                                                                <TableRow key={index1} style={webStyle.MuiTableCell}>
                                                                    {/* @ts-ignore */}
                                                                    <TableCell component="th" scope="row" style={webStyle.MuiTableCell}>
                                                                        {/* @ts-ignore */}
                                                                        <div>
                                                                            <Typography style={{ fontSize: "14px", color: "#111827" }}>{searchvalue?.attributes?.coupon_name}</Typography>
                                                                        </div>

                                                                    </TableCell>
                                                                    <TableCell align="center" style={webStyle.MuiTableCellCode}>{searchvalue?.attributes?.code}</TableCell>
                                                                    <TableCell align="center" style={webStyle.MuiTableCell}>KD{searchvalue?.attributes?.up_to_ammount}</TableCell>
                                                                    <TableCell align="center" style={webStyle.MuiTableCell}>
                                                                        <FiberManualRecordIcon style={searchvalue?.attributes?.coupon_status ? webStyle.fiberActionG : webStyle.fiberActionR}
                                                                        />
                                                                        {searchvalue?.attributes?.coupon_status ? "Active" : "Inactive"}
                                                                    </TableCell>
                                                                     {/* @ts-ignore */}
                                                                    <TableCell align="center" style={webStyle.MuiTableCellB}>{searchvalue?.attributes?.coupon_category}</TableCell>
                                                                    <TableCell align="center" style={webStyle.MuiTableCell}>{searchvalue?.attributes?.consumed}</TableCell>
                                                                    <TableCell align="center" style={{ border: "none", marginLeft: "20px", position: "relative", cursor: "pointer" }}

                                                                        onClick={(e: any) => {
                                                                            this.handleEditBox(e)
                                                                            this.handleShowDiscountCoupon(searchvalue?.attributes?.id, searchvalue?.attributes?.coupon_status)
                                                                        }}
                                                                        data-test-id={`anchors-0`}
                                                                    >
                                                                        <MoreHorizIcon style={{ color: "#9CA3AF" }} />

                                                                    </TableCell>

                                                                    <Menu
                                                                        anchorEl={this.state.openAction}
                                                                        open={Boolean(this.state.openAction)}
                                                                        onClose={this.handleCloseMenu}
                                                                        getContentAnchorEl={null}
                                                                        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                                        style={{ marginLeft: "-65px", marginTop: "-40px", border: "none" }}
                                                                        PaperProps={{
                                                                            style: { boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px' }
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 500,
                                                                                color: "#111827",
                                                                                cursor: "pointer"
                                                                            }}
                                                                            data-test-id = {`edits-0`}
                                                                            onClick={() => {
                                                                                this.setState({ openShowDiscountCouponModel: true, openAction: null })
                                                                            }
                                                                            }
                                                                        >
                                                                            Edit
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                             style={{
                                                                                fontSize: '14px',fontWeight: 500,color: "#111827",cursor: "pointer"
                                                                            }}
                                                                            onClick={() => {
                                                                                this.makeInactivecall()
                                                                            }}
                                                                            data-test-id={`makes-0`}
                                                                        >
                                                                            {this.state.couponStatus ? "Make Inactive" : "Make Active"}
                                                                        </MenuItem>
                                                                    </Menu>

                                                                </TableRow>
                                                            </>
                                                        )
                                                    })}
                                                </TableBody>
                                            )
                                        }
                                    </Table>
                                </TableContainer>
                            </div>
            <div>
              <section className="pagination">
                <button className={`${getAllCoupons?.meta?.current_page === 1 ? "opactiy_0" : "pointer"}`} disabled={getAllCoupons?.meta?.current_page === 1} onClick={() => this.nextPageData(getAllCoupons.meta, "firstpage")} data-testid="firstpage">
                  <img src={group_arrow2} alt="" />
                </button>
                <button className={`${getAllCoupons?.meta?.current_page === 1 ? "opactiy_0" : "pointer"}`} disabled={getAllCoupons?.meta?.current_page === 1} onClick={() => this.nextPageData(getAllCoupons.meta, "previouspage")} data-testid="previouspage">
                  <img src={group_arrow} alt="" />
                </button>
                <span style={{ margin: "0 15px" }}>Shows {getAllCoupons?.meta?.current_page} out of {getAllCoupons?.meta?.total_pages}</span>
                <button className={`${getAllCoupons?.meta?.next_page === null ? "opactiy_0" : "pointer"}`} disabled={getAllCoupons?.meta?.next_page === null }
                 onClick={() => this.nextPageData(getAllCoupons.meta, "nextpage")} data-testid="nextpage">
                  <img src={group_arrow3} alt="" />
                </button>
                <button className={`${getAllCoupons?.meta?.next_page === null ? "opactiy_0" : "pointer"}`} disabled={getAllCoupons?.meta?.next_page === null} onClick={() => this.nextPageData(getAllCoupons.meta, "lastpage")} data-testid="lastpage">
                  <img src={group_arrow4} alt="" />
                </button>
              </section>
            </div> 
                  </div>
                 )
               }

                <div>
                    {/* @ts-ignore */}
                    <ShowDiscountCoupon navigation={undefined} show={this.state.openShowDiscountCouponModel} handleClose={this.handleCloseShowDiscountCoupon}
                        showDiscountCouponId={this.state.editCouponId}
                    />
                </div>
            </div>

        )
    }
    // Customizable Area End
}

// Customizable Area Start
const webStyle = {
    mainBoxBody: {
        backgroundColor: "#fff",
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;',
    },
    MuiTableCell: {
        border: "none",
        alignItems: "center",
        fontSize: "12",
        color: "#111827",
    },
    MuiTableCellB: {
        alignItems: "center",
        border: "none",
        fontSize: "12",
        color: "#111827",
        textTransform: "capitalize",
    },
    MuiTableCellT: {
        color: "#111827",
        fontWeight: "bold",
        border: "none",
        fontSize: "14",
        alignItems: "center",
    },
    MuiTableCellAmount: {
        width: "180px",
        paddingLeft:"20px",
        border: "none",
        fontSize: "12",
        color: "#4B5563",
        fontweight: 600
    },
    MuiTableCell1: {
        width: "180px",
        border: "none",
        fontSize: "14",
        fontWeight: "bold",
        color: "#111827",
    },
    MuiTableCell2: {
        fontWeight: "bold",
        width: "100px",
        fontSize: "14",
        color: "#111827",
        border: "none"
    },
    MuiTableCellCode: {
        color: "#111827",
        fontSize: "12",
        border: "none",
    },

    MuiTableCell3: {
        border: "none",
        width: "130px",
        fontSize: "14",
        color: "#111827",
        fontWeight: "bold"
    },
    table: {
        minWidth: 670,
        // width: "670px",
        marginLeft:"20px",
        marginTop: "15px"
    },
    fiberActionG: {
        color: "green",
        fontSize: "8px",
        marginRight: "10px"
    },
    fiberActionR: {
        color: "red",
        marginRight: "10px",
        fontSize: "8px"
    },
    formControl: {
        backgroundColor: "#f9fafc",
        width: "350px",
        borderBottom: "none"
    },
}
// Customizable Area End