import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  propopasModalOpen: boolean;
  activeTab: number;
  shortlistedInfluencersData: any;
  description: any;
  imageFile: any;
  imageName: string | null;
  proposalId: any;
  searchShortlisted: any;
  currency_type: any;
  price: any;
  posts: any;
  flag: boolean;
  currentPage: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}


export default class ShortListedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  shortlistedInfluencersListCallId: string = "";
  createProposalcallId: string = "";
  searchShortlistedCallId: string = "";
  timeOut : any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      propopasModalOpen: false,
      activeTab: 0,
      shortlistedInfluencersData: {data:[]},
      description: "",
      imageFile: "",
      imageName: "",
      proposalId: "",
      searchShortlisted: "",
      currency_type: "",
      price: "",
      posts: "",
      flag: false,
      currentPage : 1
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.shortlistedInfluencersListCall()
    window.addEventListener('scroll', this.handleScroll)
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleShortlistedInfluencersListCall(from, message)
    this.handleCreateProposalcall(from, message)
    this.HandleSearchShortlistCall(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  handleScroll = () => {
    const { clientHeight, scrollHeight } = document.documentElement;
    const scrollElement = document.scrollingElement || document.documentElement;
    const scroll = scrollElement.scrollTop;

    if (scroll + clientHeight == scrollHeight) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () => this.shortlistedInfluencersListCall());
    }
  }
  HandleShortlistedSearch =(event:any)=> {
    const searchValue = event.target.value;
    clearTimeout(this.timeOut)
    this.setState({ searchShortlisted: searchValue,shortlistedInfluencersData: {data:[]} }, () => {
        if (searchValue === "") {
            this.setState({ currentPage: 1,shortlistedInfluencersData: {data:[]} }, () => this.shortlistedInfluencersListCall());
        }
        else {
          this.timeOut = setTimeout(()=>{
            this.searchShortlistInfluencersCall();
          }, 1000)
        }
    });   
  }

  HandleChangeCurrency =(event:any)=> {
      this.setState({currency_type: event.target.value})
  }

  HandleChangePrice =(event:any)=> {
    if (!isNaN(event.target.value)) {
    this.setState({price: event.target.value})
    }
  }

  HandleChangePosts = (event:any)=> {
    if (!isNaN(event.target.value)) {
    this.setState({posts: event.target.value})
    }
  }

  handleOpenProposalModal = (openModalId: any) => {
    this.setState({ propopasModalOpen: true, proposalId: openModalId })
  }
  handleProposalModalClose = () => {
    this.setState({ propopasModalOpen: false, price: "",posts: "",description: "",imageFile: "",imageName: ""})
  }
  ReaderImg = async (event: any) => {
    this.setState({imageFile:event.target.files[0]})
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ imageName: file.name });
    }
  }

  shortlistedInfluencersListCall = () => {
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shortlistedInfluencersListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/influencer_shortlist?restaurant_location_id=${RestaurantId}&page=${this.state.currentPage}&per_page=12`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  createPropsal = () => {
    this.setState({flag : true})
    const header = {
      "token": localStorage.getItem("authToken")
    };

    let formdata = new FormData();
    formdata.append("data[influencer_shortlist_id]", this.state.proposalId);
    formdata.append("data[proposal]", this.state.description);
    formdata.append("data[image]", this.state.imageFile);
    formdata.append("data[post_per_month]", this.state.posts);
    formdata.append("data[price]", this.state.price);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createProposalcallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/create_proposal`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  searchShortlistInfluencersCall = () => {
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
        "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchShortlistedCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_influencer/search_shortlist?user_name=${this.state.searchShortlisted}&restaurant_location_id=${RestaurantId}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  handleShortlistedInfluencersListCall = async (from: string, message: Message) => {
    if (this.shortlistedInfluencersListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage('error', apiResponse.errors[0])
      } else {
        if (apiResponse.data.length === 0) {
          ToastMessage('error', "No shortlisted data found")
        } else {
          // this.setState({ shortlistedInfluencersData: apiResponse.data })
          this.setState(prevState => ({
            ...prevState, 
            shortlistedInfluencersData: {
              ...prevState.shortlistedInfluencersData, 
              data: [...prevState.shortlistedInfluencersData.data, ...apiResponse.data.data],
            },
          }));
          console.log("getting shortlisted data data ...", apiResponse.data);
        }
      }
    }
  }


  handleCreateProposalcall = async (from: string, message: Message) => {
    if (this.createProposalcallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse.errors) {
        ToastMessage('error', apiResponse.errors[0])
      } else {
        ToastMessage("success", "Proposal sent successfully")
        this.setState({propopasModalOpen: false, price: "",posts: "",description: "",imageFile: "",imageName: "", flag: false, shortlistedInfluencersData : {data:[]}})
        this.shortlistedInfluencersListCall();
      }
    }
  }

  HandleSearchShortlistCall = async (from: string, message: Message) => {
    if (this.searchShortlistedCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
            this.setState({ shortlistedInfluencersData: apiResponse?.data})
        }
    }
  }
  // Customizable Area End

}