import React from "react";

import {
  // Customizable Area Start
  Grid, Typography, Card
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import EditRevationServing from "./EditReservationServing.web"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: { main: '#096FFF' },
    secondary: { main: '#9CA3AF' },
  },
  typography: {
    "fontFamily": `inter, sans-serif`,
    h2: {
      fontSize: "24px",
      fontWeight: 400
    },
    body1: {
      fontSize: "16px",
      fontWeight: 400
    },
    h1: {
      fontSize: "40px",
      fontWeight: 500
    },
    h6: {
      fontSize: "16px",
      fontWeight: 400
    },
    caption: {
      fontSize: "12px",
      fontWeight: 400
    }
  },
});
// Customizable Area End

import ServingHoursController, {
  Props
} from "./ServingHoursController.web";


export default class OrderDetailView extends ServingHoursController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getAddEditState = (data: any) => {
    return data.length > 0 ? "Edit" : "Add";
  };
  renderReservationData = () => {
    const { servingTimeDataReservations } = this.state;

    if (servingTimeDataReservations.length > 0) {
      return (
        <Grid style={webStyle.getDataStyle}>
          {servingTimeDataReservations.map((reservationData:any, index:any) => (
            <div style={{ marginBottom: '10px' }} key={index}>
              <Typography style={webStyle.daytypography}>
                {reservationData?.day}
              </Typography>
              {reservationData.status === "opened" ? (
                reservationData?.servingHours?.map((reservationTime:any, idx:any) => (
                  <Typography style={{ fontSize: '18px' }} key={idx}>
                    {reservationTime?.openingTime} - {reservationTime?.closingTime}
                  </Typography>
                ))
              ) : (
                <Typography style={{ fontSize: '18px', color: 'red' }}>
                  {reservationData.status}
                </Typography>
              )}
            </div>
          ))}
        </Grid>
      );
    } else {
      const addEditReservations = this.state.servingTimeDataReservations.length > 0 ? "Edit" : "Add";
      return (
        <Grid
          style={webStyle.addDataStyle}
          data-test-id="addreservationmodal"
          onClick={() => this.IsReservationOpanModal(`${addEditReservations} Reservations`)}
        >
          <AddIcon style={{ marginLeft: '75px', height: '50px', width: '40px' }} />
          <Typography>
            Add Reservations Hours
          </Typography>
        </Grid>
      );
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const addEditReservations = this.getAddEditState(this.state.servingTimeDataReservations)
    const addEditPickup = this.getAddEditState(this.state.servingTimeDataPickup)
    const addEditDelivery = this.getAddEditState(this.state.servingTimeDataDelivery)
    const addEditDrive = this.getAddEditState(this.state.servingTimeDataDrive)
    return (

      <ThemeProvider theme={theme}>
        <Typography variant="h2" component={'h2'} style={{ marginTop: '6px', fontWeight: 600 }}>Serving Hours</Typography>
        <Grid style={webStyle.MainWrapper}>
          <Typography style={{ marginTop: '6px', fontWeight: 500, fontSize: '16px', padding: '20px' }}>SERVING HOURS</Typography>

          <Grid container spacing={2} style={{ padding: "20px" }}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card style={webStyle.cardContainer}>
                <Grid style={webStyle.mainHeadborder}>
                  <Typography style={webStyle.typographyHead}>
                    Reservations
                  </Typography>
                  {this.state.servingTimeDataReservations.length > 0 && <EditIcon
                    style={{ color: "#096FFF", marginLeft: "auto", cursor: "pointer" }}
                    data-test-id="modal_res_edit"
                    onClick={() => this.IsReservationOpanModal(`${addEditReservations} Reservations`)}
                  />}
                </Grid>

                {this.renderReservationData()}

              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card style={webStyle.cardContainer}>
                <Grid style={webStyle.mainHeadborder}>
                  <Typography style={webStyle.typographyHead}>
                    Pick-up
                  </Typography>
                  {this.state.servingTimeDataPickup.length > 0 ? <EditIcon
                    style={{ color: "#096FFF", marginLeft: "auto", cursor: "pointer" }}
                    data-test-id="open_modal"
                    onClick={() => this.IsReservationOpanModal(`${addEditPickup} Pick-up`)}
                  /> : ''}
                </Grid>

                {this.state.servingTimeDataPickup.length > 0 ?
                  <div style={webStyle.getDataStyle}>
                    {this.state.servingTimeDataPickup.map((reservationData: any, index: any) => (
                      <>
                        <div style={{ marginBottom: '10px' }}>
                          <Typography style={webStyle.daytypography}>
                            {reservationData?.day}
                          </Typography>
                          {(reservationData?.servingHours?.map((reservationTime: any, index: any) => (
                            <Typography style={{ fontSize: '18px' }}>
                              {reservationTime?.openingTime} - {reservationTime?.closingTime}
                            </Typography>
                          )))}
                        </div>
                      </>
                    ))}
                  </div> :
                  <div style={webStyle.addDataStyle} data-test-id="addreservationpickup" onClick={() => this.IsReservationOpanModal(`${addEditPickup} Pick-up`)}>
                    <AddIcon style={{ marginLeft: '50px', height: '50px', width: '40px' }} />
                    <Typography>
                      Add Pick-up Hours
                    </Typography>
                  </div>}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card style={webStyle.cardContainer}>
                <Grid style={webStyle.mainHeadborder}>
                  <Typography style={webStyle.typographyHead}>
                    Delivery
                  </Typography>
                  {this.state.servingTimeDataDelivery.length > 0 ? <EditIcon
                    style={{ color: "#096FFF", marginLeft: "auto", cursor: "pointer" }}
                    data-test-id="open_modal"
                    onClick={() => this.IsReservationOpanModal(`${addEditDelivery} Delivery`)}
                  /> : ''}
                </Grid>
                {this.state.servingTimeDataDelivery.length > 0 ?
                  <div style={webStyle.getDataStyle}>
                    {this.state.servingTimeDataDelivery.map((reservationData: any, index: any) => (
                      <>
                        <div style={{ marginBottom: '10px' }}>
                          <Typography style={webStyle.daytypography}>
                            {reservationData?.attributes?.day}
                          </Typography>
                          {(reservationData?.servingHours?.map((reservationTime: any, index: any) => (
                            <Typography style={{ fontSize: '18px' }}>
                              {reservationTime?.openingTime} - {reservationTime?.closingTime}
                            </Typography>
                          )))}
                        </div>

                      </>
                    ))}
                  </div> :
                  <div style={webStyle.addDataStyle} data-test-id="addreservationdelivery" onClick={() => this.IsReservationOpanModal(`${addEditDelivery} Delivery`)}>
                    <AddIcon style={{ marginLeft: '50px', height: '50px', width: '40px' }} />
                    <Typography>
                      Add Delivery Hours
                    </Typography>

                  </div>}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Card style={webStyle.cardContainer}>
                <Grid style={webStyle.mainHeadborder}>
                  <Typography style={webStyle.typographyHead}>
                    Drive-through
                  </Typography>
                  {this.state.servingTimeDataDrive.length > 0 ? <EditIcon
                    style={{ color: "#096FFF", marginLeft: "auto", cursor: "pointer" }}
                    data-test-id="open_modal"
                    onClick={() => this.IsReservationOpanModal(`${addEditDrive} Drive-through`)}
                  /> : ''}
                </Grid>

                {this.state.servingTimeDataDrive.length > 0 ?
                  <Grid
                    style={webStyle.getDataStyle}
                  >
                    {this.state.servingTimeDataDrive.map((reservationData: any, index: any) => (
                      <>
                        <div style={{ marginBottom: '10px' }}>
                          <Typography style={webStyle.daytypography}>
                            {reservationData?.day}
                          </Typography>
                          {(reservationData?.servingHours?.map((reservationTime: any, index: any) => (
                            <Typography style={{ fontSize: '18px' }}>
                              {reservationTime?.openingTime} - {reservationTime?.closingTime}
                            </Typography>
                          )))}
                        </div>

                      </>
                    ))}
                  </Grid> : <div style={webStyle.addDataStyle} data-test-id="addreservationdrive" onClick={() => this.IsReservationOpanModal(`${addEditDrive} Drive-through`)}>
                    <AddIcon style={{ marginLeft: '75px', height: '50px', width: '40px' }} />
                    <Typography>
                      Add Drive-through Hours
                    </Typography>
                  </div>}
              </Card>
            </Grid>
          </Grid>
        </Grid >
        {this.state.IsopenModal && < EditRevationServing navigation={'OrderDetailModal'} id={'OrderDetailModalId'} timeData={this.getModalData()} handleClose={this.IsReservationCloseModal} show={this.state.IsopenModal} modalType={this.state.modalType} getReservationData={this.getReservationsTimeData} />}
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainWrapper: {
    width: '100%',
    height: 'auto',
    borderRadius: "10px",
    marginTop: '10px',
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.12) 5px 15px 20px",
  },
  reserHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #AAAAB7"
  },
  cardContainer: {
    width: '100%',
    height: 'auto',
    borderRadius: "8px",
    marginTop: '10px',
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(26, 20, 20, 0.12) 2px 2px 22px 2px",
    minHeight: '330px'
  },
  typographyHead: {
    fontSize: "18px",
    fontWeight: 500
  },
  mainHeadborder: {
    borderBottom: "1px solid #ddd",
    padding: '15px',
    display: 'flex'
  },
  daytypography: {
    color: 'gray',
    fontSize: '16px',
  },
  addDataStyle: {
    cursor: "pointer",
    display: "grid",
    color: 'rgb(9, 111, 255)',
    justifyContent: 'center',
    marginTop: '80px'
  },
  getDataStyle: {
    display: 'grid',
    paddingLeft: '15px',
  }
};
// Customizable Area End
