export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const user1 = require("../assets/user1Img.png")
export const user2 = require("../assets/user2Img.png")
export const user3 = require("../assets/user3Img.png")
export const user4 = require("../assets/user4Img.png")
export const Mask = require("../assets/Mask.png");
export const test = require("../assets/test.png");
export const BlueCrossImg = require("../assets/blueCross.png");
export const vegIcon =  require("../assets/vegIcon.png")
export const nonVegIcon =  require("../assets/nonVegIcon.png")
export const dots = require("../assets/dots.png")
export const phone = require("../assets/phone.png")
export const email = require("../assets/email.png")
export const location = require("../assets/location.png")
export const leftArrow = require("../assets/leftArrow.png")
export const follow = require("../assets/follow.png")
export const rightArrow = require("../assets/rightArrow.png")
export const pic = require("../assets/pic.png")
export const edit = require("../assets/edit.png")
export const group_arrow = require("../assets/group_arrow.png");
export const group_arrow2 = require("../assets/group_arrow2.png");
export const group_arrow3 = require("../assets/group_arrow3.png");
export const group_arrow4 = require("../assets/group_arrow4.png");
export const userPosts = require("../assets/userpost.png")
export const add = require("../assets/add.png")
export const Vector = require("../assets/Vector.png")
export const twocircular = require("../assets/two-circular.png")
export const tworectangular = require("../assets/two-rectangular.png")
export const fourcircular = require("../assets/four-circular.png")
export const fourrectangular = require("../assets/four-rectangular.png")
export const sixcircular = require("../assets/six-circular.png")
export const sixrectangular = require("../assets/six-rectangular.png")
export const eightcircular = require("../assets/eight-circular.png")
export const eightrectangular = require("../assets/eight-rectangular.png")
export const sofa = require("../assets/sofa.png")
export const barstool = require("../assets/barstool.png")
export const squaretable = require("../assets/squaretable.png")
export const rectangular = require("../assets/rectangular.png")
export const lbartable = require("../assets/lbartable.png")
export const bartable = require("../assets/bartable.png")
export const palmtree = require("../assets/palmtree.png")
export const plants = require("../assets/plants.png")
export const bush = require("../assets/bush.png")
export const cactus = require("../assets/cactus.png")
export const fountain = require("../assets/fountain.png")
export const lamp = require("../assets/lamp.png")
export const seaview = require("../assets/seaview.png")
export const vseaview = require("../assets/vseaview.png")
export const divider = require("../assets/divider.png")
export const restroom = require("../assets/restroom.png")
export const stairs = require("../assets/stairs.png")
export const dj = require("../assets/dj.png")
export const helpdesk = require("../assets/helpdesk.png")
export const billingcounter = require("../assets/billingcounter.png")
export const entry = require("../assets/entry.png")
export const exit = require("../assets/exit.png")
export const barbeque = require("../assets/barbeque.png")
export const kitchenw = require("../assets/kitchen.png")



