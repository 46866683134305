import React from 'react'

// Customizable Area Start
import {
    Button,
    Typography,
    Grid,
    Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import FormHelperText from '@material-ui/core/FormHelperText';
import { logo } from './assets'
import { Link } from "react-router-dom";
import "./signup.css"
// Customizable Area End


// Customizable Area Start
export const StyledButton:any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "319px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import SignUpEmailVerifyControllerWeb, {
    Props,
    configJSON,
} from './SignUpEmailVerifyControllerWeb';

export default class SignUpEmailVerify extends SignUpEmailVerifyControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    GenInput = (formikFunction: any, touched: any, errors: any, inputType: string, inputName: string, inputPlaceholder: string, defaultInputValue?: string | null) => {
        return (
            <div>
                <input
                    autoComplete='false'
                    onChange={formikFunction.handleChange}
                    onBlur={formikFunction.handleBlur}
                    type={inputType}
                    name={inputName}
                    defaultValue={defaultInputValue || ''}
                    style={webStyle.formInput}
                    placeholder={inputPlaceholder}
                />
                {touched[inputName] && errors[inputName] &&
                    <FormHelperText error={true} style={webStyle.errorMessage}> {errors[inputName]}
                    </FormHelperText>
                }
            </div>
        )
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyle.root}>
                    {/* @ts-ignore */}
                    <Paper style={webStyle.formdiv}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                            <Grid item style={webStyle.welcomeFormDiv} xs={12}>
                                <Grid container spacing={1}>

                                    <Grid item style={webStyle.logoMargin}>
                                        <img src={logo} alt="logo" width="100%" height="auto" />
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="h1">
                                            Create Your<br />
                                            Fudu Account
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="body1" style={webStyle.typoHeight}>
                                            To create your account, let’s start with your email address
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Formik
                                            initialValues={{
                                                email: this.state.email || localStorage.getItem("SignUpEmail") || '',
                                            }}
                                            validateOnBlur={false}
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string().required("Please enter an Email").email(configJSON.emailVerifyErrorMessage)
                                            })}
                                            onSubmit={(dataObject) => {
                                                this.handleEmailSubmit(dataObject.email)
                                            }}
                                            render={({
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                            }) => {
                                                return (
                                                    // @ts-ignore
                                                    <form onSubmit={handleSubmit} style={webStyle.formTest}>
                                                        {this.GenInput({ handleChange, handleBlur }, touched, errors, 'text', 'email', 'Email Address', localStorage.getItem('SignUpEmail'))}
                    
                                                        <StyledButton data-test-id='formSubmitBtn' type="submit" variant="contained"
                                                        disabled={this.state.isValid}
                                                        className={this.state.isValid && "opacity_5"}>
                                                            Create My Account
                                                        </StyledButton>
                                                    </form>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography align='center'>
                                            Have an account? <Link to="/EmailAccountLogin" style={webStyle.createAccountLink}>Log in instead.</Link>
                                        </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>
                        
                           </Grid>
                       </Paper>
                 
                 </div>
            </ThemeProvider>
            // Customizable Area End
        )
      }
     }

// Customizable Area Start
const theme = createTheme({

    palette: { },
    typography: 
        {
        "fontFamily": `inter, sans-serif`,
        h1: {
            fontWeight: 500,
            fontSize: "35px",
            lineHeight: "43px"
           },
        body1: {
            fontWeight: 400,
            fontSize: "16px",
            color: "#6B7280",
            lineHeight: "24px",
           },
        body2: {

            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#6B7280"
        }
       }
});

const webStyle = {
    formdiv: {
        margin: "0 auto",
        maxWidth: "488px",
        padding: "30px 0 64px 0px",
        borderRadius: "40px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 6px 14px 3px",
        position: "relative",
        minHeight: "420px",
    },
    welcomeFormDiv: {
        height: "auto",
        width: "319px",
    },
  
    errorMessage: {
        paddingLeft: "10px",
        fontSize: "15px"
    },
    formInput: {
        width: "319px",
        height: "64px",
        background: "#F9FAFB",
        border: "none",
        fontSize: "16px",
        padding: "20px",
        borderRadius: "8px"
    },

    root: {
        padding: "50px 0",
        overflowX: "hidden"
    },
    formTest: {
        display: "flex",
        flexDirection: "Column",
        margin: "0px",
        gap: "40px",
    },
  
    createAccountLink: {
        color: "#096FFF",
        textDecoration: "none",
        cursor: "pointer",
        borderBottom: "1px solid #096FFF",
    },
    logoMargin: {
        marginBottom: "57px",
        marginTop: "10px"
    },
    typoHeight: {
        minHeight: '48px',
    },  
}
// Customizable Area End


