import * as React from 'react';


import {
  Button,
  // Customizable Area Start
  Grid, Modal, Chip, Paper, Typography
  // Customizable Area End
} from "@material-ui/core";

import { BlueCrossImg } from "../src/assets";
import EditTagsControllerWeb, {
  Props,
} from './EditTagsController';


export default class EditTags extends EditTagsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    console.log("this.satte.tagdata",this.state.tagsData)
    console.log("this.state.storetag",this.state.storeTag)
    return (
      //  @ts-ignore 
      <Modal
        open={this.props.show}
        // open={true}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {/* @ts-ignore */}
        <Paper elevation={3} style={webStyle.childrenSection as React.CSSProperties}>
          <img src={BlueCrossImg} style={{
            position: "absolute",
            top: "18px",
            right: "18px",
            cursor: 'pointer'
          }} onClick={this.props.handleClose} />
          <Grid container alignItems="center" justifyContent="center">
            <Typography variant="h5" >Tags</Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={9}>
            <Paper
              style={{
                display: 'flex',
                width: "95%",
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                padding: "10px",
              }}
              //  @ts-ignore 
              component="ul"
            >
              {this.state.storeTag.map((data: any) => {
                return (
                  <li key={data.id}>

                    <Chip
                      style={webStyle.tagChipRage as React.CSSProperties}
                      label={data.title}
                      data-test-id='deleteTag_click'
                      onDelete={() => this.deleteTag(data.id)}
                    // onDelete={handleDelete(data)}
                    />
                  </li>
                );

              })}
            
            </Paper>
            </Grid>
            <Grid  item xs={12} lg={3}>
            <Button variant="contained" color="primary" style={webStyle.submitButton as React.CSSProperties} onClick={() => this.updateTags()} data-test-id='submit_button'>
              Submit
            </Button>
            </Grid>
          </Grid>
          <Grid container style={webStyle.featureMenu} spacing={4}>
            {this.state.tagsData.map((val: any) => {
              return (
                <Grid item key={val.id}>
                  <Chip style={webStyle.tagChipRage as React.CSSProperties} label={val.title} onClick={() => this.showTagData(val.id)} data-test-id='addTag_click' />
                </Grid>
              )
            })}

          </Grid>
        </Paper>
      </Modal>
    );
  }
}


// Customizable Area Start
const webStyle = {
  childrenSection: {
    marginTop: "10%",
    marginLeft: "12%",
    padding: "24px",
    borderRadius: "8px",
    maxWidth: "67%",
    position: "relative"
  },
  featureMenu: {
    marginTop: "17px"
  },
  tagChipRage: {
    backgroundColor: "#F9FAFB",
    fontSize: "14px",
    fontWeight: "400"
  },
  submitButton: {
    minWidth: "92px",
    height: "36px",
    borderRadius: "21px",
    textTransform: "none",
    marginTop: "25px",
    '&:hover': {
      opacity: "0.7",
    },
  }
};