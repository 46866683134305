import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Paper, ImageList, ImageListItem, ImageListItemBar
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import AddIcon from '@material-ui/icons/Add';
// Customizable Area End

// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            marginTop: "20px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);

export const StyledButton2: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "100%",
            minHeight: "200px",
            backgroundColor: "#ECF0FF",
            border: "1px dashed #096FFF",
            borderRadius: "8px"
        },
    }),
)((props: any) => <IconButton {...props} />);
// Customizable Area End

import ResturantImgUploadControllerWeb, {
    Props,
    configJSON,
} from "./ResturantImgUploadControllerWeb";

export default class ResturantImgUpload extends ResturantImgUploadControllerWeb {
    fileUploadRes: any
    fileUploadAddMore: any
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.fileUploadRes = React.createRef();
        this.fileUploadAddMore = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyleRestImg.root}>
                    {/* @ts-ignore */}
                    <Paper style={webStyleRestImg.formdiv}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                            <Grid item style={webStyleRestImg.welcomeFormDiv} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <IconButton data-test-id='goBackBtn' onClick={this.handleGoBack} style={webStyleRestImg.backbutton}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{configJSON.resturantPageTitle}</Typography>
                                    </Grid>
                                    {!this.state.openUploadImg && <Grid item xs={12}>
                                        <div style={{ marginBottom: '16px', marginTop: "-20px" }}>
                                            <StyledButton2 data-test-id="fileUploadBtn" fullWidth onClick={() => this.fileUploadRes.current.click()} >
                                                <AddRoundedIcon style={{ fontSize: "24px" }} color='primary' />
                                            </StyledButton2>
                                            <input
                                                data-test-id="fileInputForRes"
                                                type="file"
                                                ref={this.fileUploadRes}
                                                multiple
                                                style={{ display: "none" }}
                                                onChange={this.onFileChange}
                                                accept="image/*"
                                            />
                                        </div>
                                        <Typography gutterBottom color="secondary" variant="caption">
                                            You can select multiple images
                                        </Typography>
                                        <Typography color="secondary" variant="body1">
                                            Supported image type: <span style={{ color: "#4B5563" }}>
                                                .jpg, .png
                                            </span>
                                        </Typography>
                                        <Typography color="secondary" variant="body1">
                                            Maximum image size:  <span style={{ color: "#4B5563" }}>
                                                5MB per image
                                            </span>
                                        </Typography><Typography color="secondary" variant="body1">
                                            No. of images accepted: <span style={{ color: "#4B5563" }}>
                                                Upto 10 images
                                            </span>
                                        </Typography>

                                        <StyledButton data-test-id="nextBtn" onClick={this.goImagePage} fullWidth variant="contained">
                                            Upload
                                        </StyledButton>
                                    </Grid>}

                                    {this.state.openUploadImg && <Grid item xs={12}>
                                        <ImageList rowHeight={160} cols={2}>
                                            {this.state.displayFiles.map((item: any, index: any) => {
                                                return (
                                                    <ImageListItem key={index}>
                                                        {/* @ts-ignore */}
                                                        <img style={webStyleRestImg.gridImage as React.CSSProperties}
                                                            src={URL.createObjectURL(item)}
                                                            alt={item.name}
                                                        />
                                                        <ImageListItemBar
                                                            style={webStyleRestImg.imageCrossIcon}
                                                            position="top"
                                                            actionIcon={
                                                                <IconButton onClick={() => this.deleteImg(index)}>
                                                                    <CancelRoundedIcon color="primary" />
                                                                </IconButton>
                                                            }
                                                            actionPosition="right"
                                                        />
                                                    </ImageListItem>
                                                )
                                            })}
                                        </ImageList>


                                        <div>
                                            <Button data-test-id="fileUploadAddMoreBtn" onClick={() => this.fileUploadAddMore.current.click()} startIcon={<AddIcon style={webStyleRestImg.addMoreIcon} />} color="primary"
                                              style={{textDecoration: "underline",textTransform: "capitalize"}}
                                            >
                                                Add More
                                            </Button>
                                            <input
                                                data-test-id="fileInputForAddMore"
                                                type="file"
                                                ref={this.fileUploadAddMore}
                                                multiple
                                                style={{ display: "none" }}
                                                onChange={this.onFileChange}
                                                accept="image/*"
                                            />
                                        </div>
                                        <StyledButton data-test-id='uploadBtn' onClick={this.onFileUpload} fullWidth variant="contained" disabled={this.state.isDisabled && "opacity_5"}>
                                           Next
                                        </StyledButton>
                                    </Grid>}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: "#9CA3AF" }
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h5: {
            fontSize: "35px",
            marginBottom: "24px",
            fontWeight: 500,
        },
        caption: {
            fontSize: "12px",
            fontWeight: 300,
            lineHeight: "24px"
        },
        body1: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px"
        }
    }
});

const webStyleRestImg = {
    formdiv: {
        maxWidth: "488px",
        margin: "0 auto",
        padding: "30px 0 46px 0px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "40px",
        minHeight: "420px",
        position: "relative"
    },
    welcomeFormDiv: {
        width: "319px",
        height: "auto"
    },
    backbutton: {
        border: "2px solid #F3F4F6",
    },
    root: {
        padding: "50px 0",
        overflowX: "hidden"
    },
    gridImage: {
        objectFit: "cover",
        borderRadius: "8px",
        width: "148px",
        height: "148px"
    },
    imageCrossIcon: {
        background: "none"
    },
    addMoreIcon: {
        fontSize: "16px",
        backgroundColor: "#ECF0FF",
        textDecoration: "underline"
    }

}
// Customizable Area End

