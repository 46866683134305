import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedTab: number;
  reservationData: {
    isChecked: boolean;
    selectedDate: {
      selectFormDate: string;
      selectToDate: string;
      waitinglistDistance: string;

    },
    switchButton: {
      waitinglistSwitch: boolean;
      visibleTableLayout: boolean;
    },
    reservationCheckbox: {
      reservConfirm: boolean;
      Phone: boolean,
      Email: boolean,
      EitherPhoneOrEmail: boolean,
      FullName: boolean,
      AutoAssignTable: boolean,
      multiReservation: boolean,
      problemReservation: boolean,
      coverdBookBySeating: boolean
    },
    selectedOptions: {
      MaximumWaitTime: string,
      WithInterval: string,
      Waitlist: string,
      radioselect: any
    },
    disableMenu: boolean
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VenueSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  UpdateReservationSettingCallID: string = ""
  updateMenuDisbleCallID: string = ""
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      selectedTab: 0,
      reservationData: {
        isChecked: false,
        selectedDate: {
          selectFormDate: "",
          selectToDate: "",
          waitinglistDistance: "",
        },
        switchButton: {
          waitinglistSwitch: false,
          visibleTableLayout: false,
        },
        reservationCheckbox: {
          reservConfirm: false,
          Phone: false,
          Email: false,
          EitherPhoneOrEmail: false,
          FullName: false,
          AutoAssignTable: false,
          multiReservation: false,
          problemReservation: false,
          coverdBookBySeating: false
        },
        selectedOptions: {
          MaximumWaitTime: '',
          WithInterval: '',
          Waitlist: '',
          radioselect: ['']
        },
        disableMenu: false
      }
    };

    // Customizable Area Start


    // Customizable Area End

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.CountryCodeMessage)];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (!apiRequestCallId || !responseJson) {
      ToastMessage("error", "Something Went Wrong");
      return;
    }
    if (apiRequestCallId === this.UpdateReservationSettingCallID) {
      this.updateReservationSettingRecive(responseJson)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleTabs = (value: number) => {
    this.setState({ selectedTab: value })
  }

  updateReservationSettingRecive = (responseJson: any) => {
    if (this.state.reservationData.isChecked === true) {
      if (!this.state.reservationData.selectedDate.selectFormDate) {
        ToastMessage("error", "Please fill the selectFormDate field")
      }
      else if (!this.state.reservationData.selectedDate.selectToDate) {
        ToastMessage("error", "Please fill the selectToDate field")
      }
    }
    if (this.state.reservationData.switchButton.waitinglistSwitch && !this.state.reservationData.selectedDate.waitinglistDistance) {
      ToastMessage("error", "Please fill the waitinglist distance field")
    }
    else if (this.state.reservationData.switchButton.waitinglistSwitch === true && this.state.reservationData.isChecked === false) {
      ToastMessage("error", "Please Disable online reservation")
    }

    else {
      if (responseJson.data) {
        ToastMessage("success", "Your data updated successfully")
      }
      else {
        if (this.state.reservationData.selectedDate.selectFormDate > this.state.reservationData.selectedDate.selectToDate) {
          ToastMessage("error", 'Disable online reservation range is invalid')
        }
      }
    }
  }
  PatchReservationSetting = () => {
    const headerData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      "disable_online_reservation": this.state.reservationData.isChecked,
      "disable_online_reservation_from": this.state.reservationData.selectedDate.selectFormDate,
      "disable_online_reservation_to": this.state.reservationData.selectedDate.selectToDate,
      "enable_waiting_list": this.state.reservationData.switchButton.waitinglistSwitch,
      "enable_table_layout": this.state.reservationData.switchButton.visibleTableLayout,
      "maximum_distance_for_waitinglist": this.state.reservationData.selectedDate.waitinglistDistance,
      "auto_confirm_reservation": this.state.reservationData.reservationCheckbox.reservConfirm,
      "phone": this.state.reservationData.reservationCheckbox.Phone,
      "email": this.state.reservationData.reservationCheckbox.Email,
      "phone_or_email": this.state.reservationData.reservationCheckbox.EitherPhoneOrEmail,
      "full_name": this.state.reservationData.reservationCheckbox.FullName,
      "auto_assign_table": this.state.reservationData.reservationCheckbox.AutoAssignTable,
      "multiple_reservation_guest": this.state.reservationData.reservationCheckbox.multiReservation,
      "show_problem_reservation": this.state.reservationData.reservationCheckbox.problemReservation,
      "covers_booked_byseating_area_time": this.state.reservationData.reservationCheckbox.coverdBookBySeating,
      "maximum_wait_time": this.state.reservationData.selectedOptions.MaximumWaitTime,
      "with_interval": this.state.reservationData.selectedOptions.WithInterval,
      "waitlist_auto_remove_timing": this.state.reservationData.selectedOptions.Waitlist,
      "default_seating_area_internal_search": this.state.reservationData.selectedOptions.radioselect
    }
    const updateRequestReviewMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PatchReservationURL
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.UpdateReservationSettingCallID = updateRequestReviewMsg.messageId;
    runEngine.sendMessage(updateRequestReviewMsg.id, updateRequestReviewMsg);

  };

  PatchMenuDisbleReservationSetting = () => {
    const locationID = localStorage.getItem("locationID")
    const headerData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };

    const httpBody = {
      "disabled_menu": this.state.reservationData.disableMenu
    }
    const updateRequestReviewMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `  bx_block_location/restaurant_locations/${locationID}`
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.updateMenuDisbleCallID = updateRequestReviewMsg.messageId;
    runEngine.sendMessage(updateRequestReviewMsg.id, updateRequestReviewMsg);

  };
  updatedReservationData = (formData: any) => {
    this.setState({ reservationData: formData })
  }

  updateAPICall = () => {
    this.PatchReservationSetting()
    this.PatchMenuDisbleReservationSetting()
  }
  // Customizable Area End
}
