// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Button,
  FormControl,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  ListItemText,
  FormLabel,
  Chip,
  FormControlLabel

} from "@material-ui/core";
import Box from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { BluePlusImg, RedDeleteImg } from "./assets"
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

// Customizable Area Start
import ShowCreatedMenuController, { Props } from "./ShowCreatedMenuController.web";



// Customizable Area 
export default class ShowCreatedMenu extends ShowCreatedMenuController {
  GenCard: any;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    return (
      <React.Fragment>
        <Dialog
          maxWidth="lg"
          aria-labelledby="max-width-dialog-title"
          fullWidth
          open={this.props.show}
          onClose={this.props.handleClose}
        >
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            style={{ zIndex: 9999 }}
            rtl={false}
          />
          <DialogTitle id="max-width-dialog-title" style={{ padding: "0" }}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <IconButton
                style={webPage.closeButton}
                aria-label="close"
                onClick={() => {
                  this.props.handleClose();
                  this.setState({ imageShow: "" });
                }}
              >
                <CancelIcon style={{ color: "#096FFF" }} />
              </IconButton>
              <Box style={{ textAlign: "center" }}>
                <Typography variant="h6"
                  style={{ fontFamily: "Inter", fontWeight: "bold", fontSize: "32px", color: "#111827", marginTop: "-10px" }}>
                  Menu
                </Typography>
                <Typography variant="body2" style={{
                  fontFamily: "Inter", fontSize: "24px", color: "#4B5563", fontWeight: 500,
                  marginBottom: "25px", marginTop: "15px"
                }}>
                  Update Menu Item
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Card style={webPage.postLinkModal}>
              <form>
                <Grid
                  container
                  style={{
                    padding: "10px",
                  }}
                  className="profile-image"
                >
                  <Grid item xs={12} lg={5}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        marginLeft: "35px"
                      }}
                    >
                      <Typography
                        style={{
                          alignSelf: "start",
                          marginBottom: "14px",
                          color: "#9CA3AF"
                        }}
                      >
                        Attach Image
                      </Typography>
                      <Box style={webPage.mainMenuImageBox}>
                        {this.state.imageShow ?
                          (<Box style={{ width: "100%" }}>
                            <img
                              src={this.state.imageShow?.url}
                              style={{
                                objectFit: "cover",
                                borderRadius: "8px",
                                padding: 0,
                                height: "100%",
                                width: "100%",
                              }}
                            />                  
                          </Box>) :
                          (<Box style={{
                            width: "100%", display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gridGap: "5px",
                            height: "100%",
                          }}>
                            {this.state.imageFile ? <img
                              src={URL.createObjectURL(
                                new Blob([this.state.imageFile], { type: 'image/png' })
                              )}
                              style={{
                                borderRadius: "8px",
                                objectFit: "cover",
                                height: "100%",
                                width: "100%",
                                padding: 0
                              }}
                            /> : 
                           <Box style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            border: "2px dashed #096FFF",
                            alignItems: "center", 
                            gridGap: "5px",
                            width: "100%" ,
                            height: "100%",
                            borderRadius: "10px",
                            background: "#ECF0FF",
                           }}> 
                            <Button
                              component="label"
                              style={webPage.Postbtn}
                            >
                              +
                              <input
                                type="file"
                                accept="image/*"
                                id="image-upload"
                                value={this.state.imageFile}
                                hidden
                                onChange={this.ReaderMenuImg}
                              />
                            </Button>
                            </Box>
                            }
                          </Box>)
                        }
                      </Box>

                      <Box style={{ display: "flex" }}>
                        <Box style={{ marginTop: "10px" }}>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#9CA3AF",
                              fontFamily: "inter"
                            }}
                          >
                            Supported Image type :{""}
                            <span style={{ color: "#4B5563" }}>.jpg, .png</span>
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#9CA3AF",
                              fontFamily: "inter"
                            }}
                          >
                            Maximum image size :{" "}
                            <span style={{ color: "#4B5563" }}>5MB per image</span>
                          </Typography>
                        </Box>
                        {(this.state.imageShow || this.state.imageFile) && 
                        <Box style={{ marginLeft: "5px" }}>

                          <Button
                            component="label"
                            style={{ marginTop: "-13px" }}
                          >
                            <EditIcon color="primary" />
                            <input
                              type="file"
                              accept="image/*"
                              id="image-upload"
                              hidden
                              onChange={this.ReaderMenuImg}
                            />
                          </Button>
                          <img src={RedDeleteImg} style={{ marginTop: "20px", marginLeft: "-5px",cursor: "pointer" }}
                           data-test-id="img" onClick={() => this.setState({ imageFile: "", imageShow: "" })}
                          />
                        </Box>
                         }
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <Grid container spacing={1}>
                      <Grid item lg={12} style={{marginTop:'10px'}}>
                        <FormLabel component="legend" style={webPage.formlabel}>
                          Category
                        </FormLabel>
                        <FormControl component="fieldset">
                          <RadioGroup
                            name="radioValue"
                            data-test-id="radioValue"
                            value={this.state.showMenuData?.category_type}
                            onChange={(e: any) => {
                              this.setState({ showMenuData: { ...this.state.showMenuData, category_type: e.target.value } })
                            }}
                            // style={{
                            //   display: "flex",
                            //   flexDirection: "row",
                            //   gap: "77px",
                            // }}
                            className="categoryShowMenuRadio"
                          >
                           <FormControlLabel
  value="breakfast"
  control={<Radio color="primary" />}
  checked={this.state.showMenuData?.category_type === "breakfast"}
  label="Breakfast"
/>
<FormControlLabel
  value="lunch"
  control={<Radio color="primary" />}
  checked={this.state.showMenuData?.category_type === "lunch"}
  label="Lunch"
/>
<FormControlLabel
  value="dinner"
  control={<Radio color="primary" />}
  checked={this.state.showMenuData?.category_type === "dinner"}
  label="Dinner"
/>
<FormControlLabel
  value="all_day"
  control={<Radio color="primary" />}
  checked={this.state.showMenuData?.category_type === "all_day"}
  label="All Day"
/>

                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item lg={12}>
                        <FormLabel component="legend" style={webPage.formlabel}>
                          Item Name
                        </FormLabel>
                        <FormControl style={webPage.messageField}>
                          <TextField
                            data-test-id="txtInputConfirmPassword"
                            variant="outlined"
                            size="small"
                            id="ItemName"
                            placeholder="Enter Item Name"
                            type="text"
                            style={{
                              fontFamily: "Verdana",
                              background: "#F9FAFB"
                            }}
                            name="ItemName"
                            fullWidth
                            value={this.state.showMenuData?.item_name}
                            onChange={(e: any) => {
                              this.setState({ showMenuData: { ...this.state.showMenuData, item_name: e.target.value } })
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={12}>
                      {this.state.addVariantsData?.map((fields: any, allId: any) => (
                          <Grid
                            container
                            data-test-id="find"
                            direction="row"
                            justifyContent="space-between"
                            xs={12}
                            style={{ marginTop: "10px" }}
                            key={allId}
                          >
                            <Grid item lg={3} xs={12} 
                              style={{ marginRight: "13px" }}
                              className="mobMb15"
                            >
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Unit
                              </FormLabel>
                              <FormControl variant="outlined">
                                <Select
                                  style={{
                                    border: "1px solid gray",
                                    height: "39px",
                                    width: "188px",
                                    marginRight: "15px",
                                    marginTop: "7px",
                                    borderRadius: "4px",
                                    background: "#F9FAFB",
                                    paddingLeft: "10px"
                                  }}
                                  label="Unit"
                                  value={fields.unit || ""}
                                  data-test-id="select"
                                  onChange={(e) => {
                                    const updatedData = [...this.state.addVariantsData];
                                    updatedData[allId] = {
                                      ...fields,
                                      unit: e.target.value
                                    };
                                    this.setState({ addVariantsData: updatedData });
                                  }}
                                >
                                  {DiscountPercentage.map((value) => (
                                    <MenuItem key={value} value={value}>
                                      {value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item lg={3} xs={12}  style={{ marginRight: "13px", marginTop: "-2px" }}
                              className="mobMb15"
                            >
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Quantity
                              </FormLabel>
                              <FormControl style={webPage.smallMessageField}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  id={`quantity-${allId}`}
                                  placeholder="Enter qty"
                                  style={{
                                    fontFamily: "inter",
                                    background: "#F9FAFB"
                                  }}
                                  value={fields.quantity || ""}
                                  data-test-id="updatedata"
                                  onChange={(e) => {
                                    const updatedData = [...this.state.addVariantsData];
                                    updatedData[allId] = {
                                      ...fields,
                                      quantity: e.target.value
                                    };
                                    this.setState({ addVariantsData: updatedData });
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg={3} xs={12} style={{ marginTop: "-2px" }}
                               className="mobMb15"
                            >
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Price
                              </FormLabel>
                              <FormControl style={webPage.smallMessageField}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  id={`price-${allId}`}
                                  placeholder="Enter Price"
                                  style={{
                                    fontFamily: "inter",
                                    background: "#F9FAFB"
                                  }}
                                  name="price"
                                  defaultValue="$"
                                  value={fields.price || ""}
                                  data-test-id="pricedata"
                                  onChange={(e) => {
                                    const updatedData = [...this.state.addVariantsData];
                                    updatedData[allId] = {
                                      ...fields,
                                      price: e.target.value
                                    };
                                    this.setState({ addVariantsData: updatedData });
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            
                          <Grid item xs={1}>
                             {allId!== 0 &&
                              <ClearIcon  
                               style={{cursor:"pointer", marginTop:"30px"}}
                              data-test-id="handleremove" onClick={() => this.handleRemoveInput(allId)} />
                              }   
                          </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item lg={12}>
                      <Box style={{ display: "flex", marginTop: "5px" }}>
                          <div style={{ display: "flex", cursor: "pointer",gap:"8px" }}
                            onClick={this.handleAddVariantsInput}
                          >
                           <div style={{
                            width:"20px",
                            height:"20px",
                            backgroundColor: "#ECF0FF", 
                            borderRadius: "5px",
                            border:"none"
                           }}>
                            <img src={BluePlusImg}
                              style={{ marginRight: "10px",
                                       marginTop: "4px",
                                       marginLeft:"4px"
                                    }} 
                            />
                           </div>   
                            <Typography style={{ color: "#096FFF", textDecoration: "underLine" }}>
                              Add variant
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={12}>
                        <Grid item >
                          <FormLabel component="legend" style={webPage.formlabel}>
                            Item Type
                          </FormLabel>
                          <FormControl component="fieldset">
                            <RadioGroup
                              name="Itemtype"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                              value={this.state.showMenuData?.item_type}
                              data-test-id="showmenudata"
                              onChange={(e: any) => {
                                this.setState({ showMenuData: { ...this.state.showMenuData, item_type: e.target.value } })
                              }}
                            >
                              <FormControlLabel value="veg" control={<Radio color="primary" />}
                                checked={this.state.showMenuData?.item_type === "veg" ? true : false} label=" Veg" />
                              <FormControlLabel value="non_veg" control={<Radio color="primary" />}
                                checked={this.state.showMenuData?.item_type === "non_veg" ? true : false} label="Non-veg" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                  <Grid container direction="row" className="tagGrid" >
                         <Grid item xs={6} lg={6} md ={6}>
                              <FormLabel component="legend" style={webPage.formlabel}>
                                Cuisine
                              </FormLabel>
                              <FormControl variant="outlined" className="cuisineSelect">
                                <Select
                                  style={{
                                    height: "38px",
                                    border: "1px solid gray",
                                    borderRadius: "4px",
                                    marginTop: "7px",
                                    paddingLeft: "5px",
                                    background: "#F9FAFB",
                                  }}
                                  label="Unit"

                                  value={this.state.showSelectedCuisines?.name ?? ""}

                                  onChange={this.handleCuisinesChange}>

                                  {this.state.allCuisinesData?.map((value:any, index:any) => (

                                    <MenuItem value={value.name} key={index}>{value.name}</MenuItem>

                                  ))}

                                </Select>

                              </FormControl>

                            </Grid>

                            <Grid item xs={5} lg={5} md={6} style={{marginLeft:"-25px",marginTop:"-5px"}}>

                              <Typography component="legend" style={{fontWeight:"bolder"}}>

                              Tags <span style={{ fontSize: "10px", marginLeft: "4px" }}>
                                (multiple selection added)</span>

                              </Typography>
                              
                              <FormControl variant="outlined" style={{width:"305px"}}>
                              <Select
                                 labelId="name-label"
                                 multiple
                                 value={this.state.createdDishes}
                                 data-test-id="selected"
                                 onChange={this.handleCreatedDishChange}
                                 renderValue={(selected:any) => (
                                  <div>
                                    {selected.map((value:any) => (
                                      <span key={value}>{value}, </span>
                                    ))}
                                  </div>
                                 )}
                                 style={{
                                   height: "38px",
                                  border: "1px solid gray",
                                  marginTop: "7px",
                                  borderRadius: "4px",
                                  background: "#F9FAFB",
                                  paddingLeft: "5px"
                                }}
                               >
                             {this.state.showAllSubCategoriesData?.map((name:any) => (

                              <MenuItem key={name.id} value={name.attributes?.title}>
                                
                              <Checkbox
                                checked={this.state.createdDishes.indexOf(name.attributes?.title) > -1}
                                color="primary"
                               />
                              <ListItemText primary={name.attributes?.title} />

                              </MenuItem>
                            ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}/>
                  </Grid>
                  <Grid item lg={12}>
                    <Paper style={{width: "90%",display:"flex", alignItems: "center"}}>
                    <Typography component="legend" style={{fontWeight:600}}>
                     Tags:
                    </Typography>
                       <div style={{
                           display: 'flex',
                           gap: '8px',
                           padding: '8px',
                           overflowX: 'auto',
        
                         }}>
                      {this.state.allSubCategoriesTagsList?.map((data:any, index:any) => {
                         let icon;
                         return (
                           <Chip
                             key={index}
                             icon={icon}
                             label={data}
                             data-test-id="ondelete"
                             onDelete={() => this.handleDelete(data)}
                             />
                           );
                          })}
                         </div>
                      </Paper>
                    </Grid>
                      <Grid item lg={12}>
                        <FormLabel component="legend" style={webPage.formlabel}>
                          Item Description
                        </FormLabel>
                        <FormControl style={webPage.messageField}>
                          <TextField
                            variant="outlined"
                            size="small"
                            style={{
                              fontFamily: "inter",
                              background: "#F9FAFB"
                            }}
                            placeholder="Enter Item Description"
                            type="text"
                            name="description"
                            value={this.state.showMenuData?.description}
                            onChange={(e: any) => {
                              this.setState({ showMenuData: { ...this.state.showMenuData, description: e.target.value } })
                            }}
                            multiline
                            rows={3}
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        style={{
                          marginTop: "10px",
                        }}
                        xs={12}
                        spacing={2}
                      >
                        <Grid item lg={6} xs={12}>
                          <Button
                            onClick={this.props.handleClose}
                            variant="contained"
                            style={{
                              width: "88%",
                              height: "54px",
                              background: "transparent",
                              borderRadius: "10px",
                              boxShadow: "none",
                              border: "1px solid #096FFF",
                              color: "#096FFF",
                              textTransform: "none",
                              marginLeft: "5px"
                            }}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item  lg={6} xs={12}>
                          <Button
                            data-test-id='Edit'
                            style={{
                              width: "88%",
                              height: "54px",
                              background: "#096FFF",
                              color: "#fff",
                              borderRadius: "10px",
                              textTransform: "none",
                              marginLeft: "-15px"
                            }}
                            variant="contained"
                            onClick={this.editMenuCall}
                            disabled={this.state.isDisabled}
                          >
                            Update Item
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </DialogContent>
        </Dialog>
      </React.Fragment>

    );
  }
  // Customizable Area End
}

// Customizable Area Start

const DiscountPercentage = ["gms", "kg", "portion", "quantity"]

const webPage = {

  cancelbutnStyle: {
    width: "90%",
    height: "54px",
    background: "transparent",
    marginLeft: "5px",
    borderRadius: "10px",
    boxShadow: "none",
    border: "1px solid #096FFF",
    color: "#096FFF",
    textTransform: "none"
  },
  mainMenuImageBox: {
    height: 310, 
    width: "82%",
    padding: "0px",
    borderRadius: "10px",
  },
  butnStyle: {
    width: "90%",
    height: "54px",
    background: "#096FFF",
    color: "#fff",
    marginLeft: "-25px",
    borderRadius: "10px",
    textTransform: "none"
  },

  postLinkModal: {
    marginBottom: "14px",
    boxShadow: "none",
    borderRadius: "10px",
    border: "1px solid #B6B5BB",
  },
  messageField: {
    background: "#F9FAFB",
    width: "90%",
    marginTop: "10px",
  },
  smallMessageField: {
    width: "190px",
    background: "#F9FAFB",
    marginTop: "10px",
  },

  closeButton: {
    alignSelf: "end",
  },

  formlabel: {
    color: "#111827",
    fontWeight: 600,
    fontFamily: "inter",
    fontSize: "16px",
  },
  Postbtn: {
    width: "150px",
    color: "#096FFF",
    background: "transparent",
    border: "none",
    fontSize: "50px",
  },
};

// Customizable Area End


