import React from "react";

// Customizable Area Start
import {
    Typography,
    Grid,
    Paper
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

import SignUpSuccessController, {
    Props
} from "./SignUpSuccessController";

export default class SignUpSuccess extends SignUpSuccessController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyle.root}>
                    {/* @ts-ignore */}
                    <Paper style={webStyle.formdiv}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                            <Grid item style={webStyle.welcomeFormDiv} xs={12}>

                                <Grid justifyContent="center" container spacing={3}>

                                    <Grid item style={{ marginTop: "150px" }}>
                                        {/* @ts-ignore */}
                                        <CheckCircleRoundedIcon style={webStyle.checkIcon as React.CSSProperties} />
                                    </Grid>

                                    <Grid item style={{ marginTop: "16px" }}>
                                        <Typography variant="h1">Congratulations!</Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography align="center" color="secondary" variant="body1">
                                            Your signup request has been sent to Fudu team for review. Once approved, you’ll receive a mail from the team in your registered email id.
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: "#4B5563" }
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h1: {
            fontSize: "35px",
            fontWeight: 500,
        },
        body1: {
            fontSize: "16px",
            fontWeight: 400,
        }
    }
});

const webStyle = {
    formdiv: {
        maxWidth: "488px",
        margin: "0 auto",
        padding: "30px 0 46px 0px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "40px",
        minHeight: "620px",
        position: "relative"
    },
    welcomeFormDiv: {
        width: "319px",
        height: "auto"
    },
    root: {
        padding: "50px 0",
        overflowX: "hidden"
    },
    checkIcon: {
        fontSize: "144px",
        color: "#22AB4D",
        textAlign: "center"
    },
}
// Customizable Area End

