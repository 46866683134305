import React from 'react'

// Customizable Area Start
import {
    AppBar, Toolbar, Box,
} from "@material-ui/core";
const Navlogo = require('../../navigationmenu/assets/navlogo.png');

// Customizable Area End


// Customizable Area Start
const classes = {
    appbar: {
        backgroundColor: "#096FFF",
    },
    alterToolbar: {
        display: "flex",
        justifyContent: 'space-between',
        width: "94%"
    },
    firstBox: {
        display: "flex",
        gap: "145px",
        alignItems: "center",
        justifyContent: 'space-around',
    }
}


// Customizable Area End


export default class TopNavPublic extends React.Component {
    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <AppBar position='sticky' style={classes.appbar}>
                <Toolbar style={classes.alterToolbar}>
                    <Box style={classes.firstBox}>
                        <img src={Navlogo} alt="navlogo" style={{marginLeft:'10px'}} />
                    </Box>
                </Toolbar>
            </AppBar >
            // Customizable Area End
        )
    }
}

// Customizable Area Start
              
// Customizable Area End
