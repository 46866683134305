import React from "react";
import { City, State } from 'country-state-city';

 export const Dropdown = (props: any) => {
	return (
		<>
			<label>City</label>
			<select value={props.value} defaultValue={props?.value} onChange={props.handleChange} className="edit-select">

				{
					props.options?.length > 0 &&
					props.options?.map((option: any, index: any) => {
						return <option key={index} value={option.value}>{option.displayValue}</option>
					})
				}
			</select>
		</>)
}


const CityComponent = ({ countryCode = '', cityCode = '', value = null, handleChange }: any) => {

	let cityCodes = State.getAllStates()
	let newcitycode: any = cityCodes?.filter((city: any) => {

		if (city.name == cityCode) {
			return city
		}
	})
	const data = City.getCitiesOfState(countryCode, cityCode.length > 4 ? newcitycode[0]?.isoCode : cityCode).map(city => {

		return {
			value: city.name,
			displayValue: city.name
		}

	})

	return (<>
		{<Dropdown options={data ?? []} value={value} handleChange={handleChange} ></Dropdown>}
	</>)
}

export default CityComponent;