import React from "react";
// Customizable Area Start
import { Grid, Dialog, Select, Badge, Avatar, Typography } from "@material-ui/core";
import ManageQueueController, { Props } from "./ManageQueueController";
import { close } from "./assets";
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End

export default class ManageQueue extends ManageQueueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  getResevationStatusOption = () => {
    const { reservationData, orderStatus } = this.state;
    if (reservationData?.attributes?.status === "cancelled" || reservationData?.attributes?.status === "completed") {

      return (<Typography style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>{orderStatus}</Typography>)
    }
    
    return (
      <Select
        fullWidth
        native
        disableUnderline
        value={orderStatus}
        data-test-id="manageStatus"
        onChange={(e) => this.manageStatus(e)}
        style={{ marginTop: 10, marginBottom: 20, cursor: 'pointer', backgroundColor: 'rgb(242, 242, 242)', minHeight: '25px' }}
      >
         <option value="waiting">Waiting</option>
        <option value="confirmed">Confirm</option>
        <option value="completed">Complete</option>
        {reservationData?.attributes?.status === 'in_progress' && <option value="in_progress">Pending</option>}
        <option value="cancelled">Cancel</option>
      </Select>)
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { EstimateWaitingTime, IsLoading, waitingListData } = this.state;
    const cunstome_name_man = waitingListData?.attributes?.restaurant_customer_name || waitingListData?.attributes?.customer_name;
    return (
      <>
        <Dialog
          fullWidth
          open={this.props.show}
        >
          <section className="order-body">
            <div className="close-btn-div" style={{ display: 'flex', justifyContent: 'flex-end', padding: '15px 15px' }}> <img src={close} alt="" style={{ cursor: 'pointer' }} onClick={this.props.handleClose} /></div>
            <Grid
              container
              justifyContent="center"
            >
              {IsLoading === true ? <PulseLoader color="#096fff" className="loader" /> :
                <Grid item lg={8}>
                  <h3 className="order-title" style={{ marginBottom: 40 }}>Manage Queue</h3>
                  <label className="order-sub-head">Expected Waiting Time</label>
                  <Select
                    native
                    disableUnderline
                    fullWidth
                    value={EstimateWaitingTime}
                    style={{ marginTop: 15, marginBottom: 15, backgroundColor: 'rgb(242, 242, 242)' }}
                    data-test-id="manageWaitingTime"
                    onChange={(e) => this.manageWaitingTime(e)}
                  >
                    {[10, 20, 30, 40, 50].map((time) => {
                      return (<option value={`${time} min`} key={time}>{time} mins</option>
                      )
                    })}
                  </Select>
                  <h4 className="order-sub-head mb15">Queue<Badge badgeContent={this.state.queueCount} color="primary" style={{
                    position: "relative",
                    left: "20px",
                  }}></Badge></h4>
                  <section className="mb30">
                    {waitingListData.length > 0 ? (
                      waitingListData?.map((elem: any, i: any) => (
                        <section className="manage-queue" key={i}>
                          <div className="customer-div">
                            <Avatar src={elem.attributes?.profile_picture}>{cunstome_name_man.substring(0, 2)}</Avatar>
                            <p className="customer-name">{cunstome_name_man}</p>
                          </div>
                          <button
                            className="team-member-btn"
                            onClick={() => this.handleRemoveModalOpen(elem.id)}
                            style={{ cursor: 'pointer' }}
                            data-test-id="removeModalOpen"
                          >
                            Remove
                          </button>
                        </section>
                      ))) : (
                      <p>No data available</p>
                    )}
                  </section>
                  <section style={{ width: '90%', marginLeft: '5%', marginBottom: '20px' }}>
                    <label className="order-sub-head">Order Status</label>
                    {this.getResevationStatusOption()}
                  </section>
                  <div className="text-center">
                    <button className="btn-update" onClick={this.updateReservationData} data-test-id="updatebutton">Update</button>
                  </div>
                </Grid>}
            </Grid>
          </section>
        </Dialog>

        {/* Remove Modal  */}
        <Dialog
          open={this.state.RemoveModal === null ? false : true}>
          <div className="modal-header">
            <span className="fs18 fw500">Remove John Doe</span>
            <img src={close} alt="" onClick={this.handleRemoveModalClose} data-test-id="close_icon" />
          </div>
          <div className="modal-body">
            <p className="modal-body-content">
              Are you sure you want to remove John Doe from the waitlist ? <br />
              John Doe is you Platinum customer.
            </p>
          </div>
          <div className="text-center mb30">
            <button
              className="btnTransparentRadius"
              onClick={this.RemoveWaitingQueue}
              data-test-id="removewaitingQueue"
            >
              Yes, remove
            </button>
            <button
              className="btnThemeRadius"
              style={{ marginTop: '10px' }}
              onClick={this.handleRemoveModalClose}
              data-test-id="close_icon"
            >
              No, don't remove
            </button>
          </div>
        </Dialog>
        {/* Remove Modal  */}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area End
