import React from "react";

// Customizable Area Start
import {
  Typography,
  Grid,
  Avatar, Paper, Button, Badge, IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Mask } from "./assets"
import AboutPage from "./AboutPage.web";
import ProfileInfluencers from "./ProfileInfluencers.web";
import PostDetails from "../../Repost/src/PostDetails.web"
import EditIcon from '@material-ui/icons/Edit';
// Customizable Area End

// Customizable Area Start
const profileTheme = createTheme({
  palette: {
    primary: { main: '#096FFF' },
  },
  typography: {
    "fontFamily": `inter, sans-serif`,
    subtitle1: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: 1.2
    },
    subtitle2: {
      letterSpacing: "1px",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "22px",
      color: "#9CA3AF"
    },
    caption: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#4B5563"
    },
    body1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      color: "#4B5563"
    },
    h6: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
    },
    h5: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "22px",
      textTransform: "uppercase"
    },
    h4: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19.36px",
      textAlign: "center"
    },
    body2: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#9CA3AF",
      textAlign: "center"
    },
    h3: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "17px",
    },
    h2: {
      fontWeight: 400,
      fontSize: "12px",
    },
    h1: {
      fontSize: "10px",
      fontWeight: 400
    }
  },
});

export const StyledButton: any = withStyles((theme: Theme) =>
  createStyles({
    root:
    {
      minWidth: "92px",
      height: "36px",
      borderRadius: "21px",
      textTransform: "none",
      '&:hover': {
        opacity: "0.7",
      },
    },
  }),
)((props: any) => <Button {...props} />);
// Customizable Area End

// Customizable Area Start
import UserProfileBasicController, {
  Props,
  configJSON,
} from "./UserProfileBasicController";

// Customizable Area End

export default class UserProfile extends UserProfileBasicController {
  public fileUploadProfile: any
  public fileUploadCover: any
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.fileUploadProfile = React.createRef();
    this.fileUploadCover = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  UserprofileTab = () => {
    let contentComponent = null;

    if (this.state.tabPanelNo === 0) {
      contentComponent = <AboutPage sendData={this.setDataFromProps} navigation={'AboutPage'} id={'AboutPage'} />;
    } else if (this.state.tabPanelNo === 1) {
      contentComponent = <PostDetails navigation={'PostDetails'} id={'PostDetails'} enableFilterSearch={false} />;
    } else if (this.state.tabPanelNo === 2) {
      contentComponent = <ProfileInfluencers navigation={"ProfileInfluencers"} id={"ProfileInfluencers"} />;
    }

    return contentComponent;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={profileTheme}>
        <Typography variant="subtitle1" component={'h2'} style={{ marginTop: '5px', fontWeight: 600 }}>{configJSON.ProfilePageLable}</Typography>
        <div style={webStyle.mainWrapper}>

          <Grid elevation={3} xs={12} component={Paper} container style={webStyle.mainSection as React.CSSProperties}>
            <div>
              <StyledButton data-test-id="coverphoto" onClick={() => this.fileUploadCover.current.click()} style={webStyle.editBtn} variant="contained" color="primary" >
                Edit
              </StyledButton>
              <input
                type="file"
                ref={this.fileUploadCover}
                id="file-upload-cover"
                data-test-id="coverImageEdit"
                style={{ display: "none" }}
                onChange={(e: any) => {
                  this.handleFileUpload(e, "cover");
                  e.target.value = null;
                }}
                accept="image/*"
              />
            </div>


            <Grid item xs={12}>
              <Grid item xs={12} style={{
                backgroundImage: `url(${this.state.restaurantDetail.restaurant_cover_image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "160px"
              }}>
              </Grid>
            </Grid>
            <Grid item xs={12} style={webStyle.infoSection}>
              <Grid container direction="row">
                <Grid item xs={12} lg={5} style={webStyle.profileConatiner}>

                  <Badge
                    data-test-id="profileImageEdit"
                    style={webStyle.avatarCont as React.CSSProperties}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    badgeContent={
                      <div>
                        <IconButton data-test-id="currentClick" onClick={() => this.fileUploadProfile.current.click()} style={webStyle.avatarEditBtn}>
                          <EditIcon color='primary' style={{ fontSize: "12px" }} />
                        </IconButton>
                        <input
                          type="file"
                          ref={this.fileUploadProfile}
                          data-test-id="profileEdit"
                          id="file-upload-profile"
                          style={{ display: "none" }}
                          onChange={(e: any) => {
                            this.handleFileUpload(e, "profile");
                            e.target.value = null;
                          }}
                          accept="image/*"
                        />
                      </div>
                    }
                  >
                    <Avatar style={webStyle.avatar} src={this.state.restaurantDetail.restaurant_profile_image} />
                  </Badge>

                  <Typography style={webStyle.profileTitle} variant="h6">
                    {this.state.restaurantDetail?.restaurant_name?.slice(0, 55)} {this.state.restaurantDetail?.restaurant_name?.length < 55 ? "-" : "..."}  {this.state.restaurantDetail.city}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <Grid container style={webStyle.statusBarConatiner}>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', gap: '16px', maxWidth: '130px' }}>
                      <Typography variant="subtitle2" style={webStyle.title}>POSTS</Typography>
                      <Typography variant="subtitle2" style={webStyle.value}>0</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', gap: '16px', maxWidth: '180px' }}>
                      <Typography variant="subtitle2" style={webStyle.title}>BOOKINGS</Typography>
                      <Typography variant="subtitle2" style={webStyle.value}>0</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ display: 'flex', gap: '16px', maxWidth: '250px' }}>
                      <Typography variant="subtitle2" style={webStyle.title}>EARNINGS</Typography>
                      <Typography variant="subtitle2" style={webStyle.value}>0</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={8} style={webStyle.navSection}>
              <Typography
                variant="body1"
                style={this.state.tabPanelNo === 0 ? webStyle.tabletabSelected : webStyle.tabletab}
                data-test-id="About"
                onClick={() => this.hanleTabs(0)}
              >
                About
              </Typography>
              <Typography
                variant="body1"
                style={this.state.tabPanelNo === 1 ? webStyle.tabletabSelected : webStyle.tabletab}
                data-test-id="Posts"
                onClick={() => this.hanleTabs(1)}
              >
                Posts
              </Typography>
              <Typography
                variant="body1"
                style={this.state.tabPanelNo === 2 ? webStyle.tabletabSelected : webStyle.tabletab}
                data-test-id="Influencers"
                onClick={() => this.hanleTabs(2)}
              >
                Influencers
              </Typography>
            </Grid>
          </Grid>

          {this.UserprofileTab()}
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    marginTop: "30px",
  },
  navSection: {
    marginTop: "40px",
    display: 'flex',
    gap: '30px',
    marginLeft: '30px'
  },
  navTextContainer: {
    marginLeft: "32px"
  },
  mainSection: {
    borderRadius: "8px",
    position: "relative",
    width: '100%'
  },
  childrenSection: {
    marginTop: "24px",
    padding: "24px",
    borderRadius: "8px",
    position: "relative"
  },
  infoSection: {
    marginTop: "27px",
  },
  title: {
    fontSize: '16px'
  },
  value: {
    fontSize: '17px',
    fontWeight: 600,
    color: '#424040'
  },
  imageDiv: {
    backgroundImage: `url(${Mask})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "160px"
  },
  profileConatiner: {
    display: "flex",
    alignItems: "center",
  },
  avatarCont: {
    position: "absolute",
    top: "70px",
    left: "40px"
  },
  avatar: {
    height: "160px",
    width: "160px",
    border: "5px solid #FFFFFF",
  },
  statusBarConatiner: {
    display: "flex",
    justifyContent: 'space-evenly'
  },
  profileTitle: {
    marginLeft: "225px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
  },
  featureMenu: {
    marginTop: "17px"
  },
  cardList: {
    padding: "2px"
  },
  postCardRoot: {
    boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.12)"
  },
  editBtn: {
    right: "0px",
    margin: "24px",
    position: "absolute"
  },
  avatarEditBtn: {
    height: "24px",
    width: "24px",
    backgroundColor: "white"
  },
  tabletab: {
    marginTop: '12px',
    color: '#9CA3AF',
    cursor: "pointer",
  },
  tabletabSelected: {
    color: "#096FFF",
    borderBottom: '3px solid #096FFF',
    display: "inline-block",
    padding: "12px 0px",
    cursor: "pointer",
    fontWeight: 600
  },
};
// Customizable Area End