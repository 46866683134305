import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    displayFiles: any;
    openUploadImg: any;
    isDisabled: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class CompleteAddressController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    FileUploadCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            displayFiles: [],
            openUploadImg: false,
            isDisabled: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForUploadImage(from, message)
        // Customizable Area End
    }

    // Customizable Area Start
    deleteImg = (index: number) => {
        this.state.displayFiles.splice(index, 1);
        this.setState({ displayFiles: this.state.displayFiles })
        if( this.state.displayFiles.length === 0){
            this.setState({ openUploadImg: false })
        }
    }

    goImagePage = () => {
        if (this.state.displayFiles.length == 0) {
            return ToastMessage('error', 'Please select atleast one Image');
        }
        this.setState({ openUploadImg: true })
    }

    handleGoBack = () => {
        this.props.navigation.navigate('CompleteSignUp')
    }

    onFileChange = (event: any) => {
        const selectedFiles = Array.from(event.target.files);
        const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
        const newFiles: any = [];

        selectedFiles.forEach((file: any) => {
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB <= 5) {
                if (allowedFormats.includes(file.type)) {
                    newFiles.push(file);
                } else {
                    ToastMessage('error', `File '${file.name}' is not in PNG, JPEG, or JPG format.`);
                }
            } else {
                ToastMessage('error', `File '${file.name}' exceeds the 5MB file size limit.`);
            }
        });
        this.setState({ displayFiles: [...this.state.displayFiles, ...newFiles] })
        setTimeout(() => {
            this.state.displayFiles.length > 0 ? this.setState({ openUploadImg: true }) : null
        }, 0)
    };

    //API Function
    onFileUpload = async () => {
        if (this.state.displayFiles.length == 0) {
            return ToastMessage("error", "Please select Images")
        }
        if (this.state.displayFiles.length > 10) {
            return ToastMessage("error", "Only 10 Images is Allowed")
        }
        this.setState({isDisabled:true})
        const formData = new FormData();
        
        for (const [i] of this.state.displayFiles.entries()) {
            formData.append(
                "restaurant_images[]",
                this.state.displayFiles[i],
            );
        }

      

        const header = {
            token: localStorage.getItem("SignUpToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.FileUploadCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/restaurant_images?restaurant_location_id=${localStorage.getItem('accountID')}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    };

    //API's Response Function
    handleResponseForUploadImage = (from: string, message: Message) => {
        if (this.FileUploadCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (!responseJson.errors) {
                ToastMessage("success", "Image Uploaded Successfully")
                this.props.navigation.navigate("SignUpSuccess")
                this.setState({
                    displayFiles: [], isDisabled:false
                })
            } else {
                ToastMessage("error", "Something Went Wrong")
            }
        }
    }

    // Customizable Area End

}