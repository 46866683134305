import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    sendData: Function
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    overviewData: any,
    featureListData: any,
    showOverview: boolean,
    showTags: boolean,
    showFeatures: boolean,
    showAddPhotoModal: boolean,
    showAddMenuModal: boolean,
    showEditMenuModal: boolean,
    menuEditID: any,
    tabPanelNo: number,
    restaurantPhoto: any,
    restaurantTagData: any,
    restaurantMenu: any,
    anchorElPhotoMoreBtn: any,
    deleteImgID: any,
    galleryImgData: any,
    checkedG: boolean,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class AboutPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    featureLitsCallId: string = "";
    restaurantPhotoCallId: string = "";
    deleteImageCallId: string = "";
    getMenuCallId: string = "";
    getTagDataCallId: string = "";
    updateImageCallId: string = "";
    showMenuStatusCallId: string = "";
    enbleMenuCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            overviewData: {},
            featureListData: {},
            showOverview: false,
            showTags: false,
            showFeatures: false,
            showAddPhotoModal: false,
            showAddMenuModal: false,
            showEditMenuModal: false,
            tabPanelNo: 1,
            restaurantPhoto: [],
            restaurantTagData: [],
            anchorElPhotoMoreBtn: null,
            restaurantMenu: [],
            menuEditID: null,
            deleteImgID: null,
            galleryImgData: null,
            checkedG: false,
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        this.getFeatureList()
        this.getRestaurantPhoto()
        this.getAllMenu()
        this.getTags()
        this.showMenuStatusCall()
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForFeatureList(from, message)
        this.handleResForRestaurantPhoto(from, message)
        this.handleResForDeleteRestaurantImg(from, message)
        this.handleResForAllMenu(from, message)
        this.handleResForTags(from, message)
        this.handleResForUpdateImage(from, message)
        this.handleEnableMenuCall(from, message)
        this.handleShowMenuStatus(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
    handleMenuChange = async(event:any) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
        this.enableMenuApiCall(event.target.checked)
      };
    
    handleOpenOverviewEditModal = () => {
        this.setState({ showOverview: true })
    }

    handleOpenMenuAddModal = () => {
        this.setState({ showAddMenuModal: true })
    }

    handleOpenEditTagsModal = () => {
        this.setState({ showTags: true })
    }

    handleOpenFeatureEditModal = () => {
        this.setState({ showFeatures: true })
    }

    handleOpenPhotoAddModal = () => {
        this.setState({ showAddPhotoModal: true })
    }

    returnYesNo = (data: boolean) => {
        return data ? "Yes" : "No"
    }

    handleCloseMenuAdd = () => {
        this.setState({ showAddMenuModal: false })
        this.getAllMenu()
    }

    handleCloseMenuEdit = () => {
        this.setState({ showEditMenuModal: false })
        this.getAllMenu()
    }
    handleOpenMenuEdit = (id: any) => {
        this.setState({ showEditMenuModal: true, menuEditID: id })
    }

    handleCloseOverviewEdit = () => {
        this.setState({ showOverview: false })
        this.getRestaurantPhoto()
    }

    handleCloseAddPhotoModal = () => {
        this.setState({ showAddPhotoModal: false })
        this.getRestaurantPhoto()
    }

    handleCloseFeatureEdit = () => {
        this.setState({ showFeatures: false })
        this.getFeatureList()
    }

    hanleTabs = (event: object, value: number) => {
        this.setState({ tabPanelNo: value })
    }

    handleClickOpenMenu = (event: any, id: any, data: any) => {
        this.setState({ anchorElPhotoMoreBtn: event.currentTarget, deleteImgID: id, galleryImgData: data });
    };

    handleCloseMenu = () => {
        this.setState({ anchorElPhotoMoreBtn: null });
    };

    handleCloseEditTag = () => {
        this.setState({ showTags: false })
        this.getTags()
    }



    //API Function
    getFeatureList = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.featureLitsCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/account_block/current_restaurant_feature?restaurant_location_id=${localStorage.getItem('locationID')}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getRestaurantPhoto = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.restaurantPhotoCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/restaurant_locations/${localStorage.getItem('locationID')}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteImg = async () => {
        const header = {
            token: localStorage.getItem("authToken"),
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteImageCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/delete_restaurant_images/${this.state.deleteImgID}?restaurant_id=${localStorage.getItem('locationID')}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `DELETE`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getAllMenu = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMenuCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_menu_ordering/menus'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTags = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getTagDataCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_block/tags/current_user_tags'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getFileFromUrl = (url: any) => {

        return fetch(url)
            .then(response => response.blob())
            .then(blob => {
                return new File([blob], "filename")

          });
      }
    setImageFromGallery = async (profileImage?: any, backCoverImage?: any) => {
      
        const header = {
            "token": localStorage.getItem('authToken')
        };
        
        const formData = new FormData();

        if (profileImage) {
            const imgData=await this.getFileFromUrl(profileImage);
            formData.append("data[profile_image]", imgData);
        }

        if (backCoverImage) {
           const coverimg = await this.getFileFromUrl(backCoverImage)
            formData.append("data[cover_image]", coverimg);
        }

        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateImageCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/restaurant_locations/${localStorage.getItem('locationID')}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    showMenuStatusCall = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.showMenuStatusCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_location/restaurant_locations/586'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    } 

    enableMenuApiCall = async (checkedMenu: any) => {
      
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem('authToken')
        };
        
        const httpBody = {
            data: {
                "disable_menu": checkedMenu
              }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.enbleMenuCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/restaurant_locations/${localStorage.getItem('locationID')}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    //API Response Function
    handleResForFeatureList = async (from: string, message: Message) => {
        if (this.featureLitsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', "Something went wrong")
            } else {
                this.setState({ featureListData: apiResponse?.data?.attributes })
            }
        }

    }

    handleEnableMenuCall = async (from: string, message: Message) => {
        if (this.enbleMenuCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (!apiResponse.errors) { 
                if(apiResponse.data.attributes.disable_menu === false) {
                    ToastMessage("success", "Menu enabled successfully for customers")
                } else {
                    ToastMessage("success", " Menu disabled successfully for customers")
                }
            }
        }

    }

    handleResForRestaurantPhoto = async (from: string, message: Message) => {
        if (this.restaurantPhotoCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', "Something went wrong")
            } else {
                this.props.sendData(apiResponse.data)
                this.setState({ restaurantPhoto: apiResponse.data.attributes.restaurant_images, overviewData: apiResponse.data.attributes })
            }
        }

    }

    handleResForDeleteRestaurantImg = async (from: string, message: Message) => {
        if (this.deleteImageCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', "Something went wrong")
            } else {
                ToastMessage('success', "Image Deleted Successfully")
                this.setState({ anchorElPhotoMoreBtn: null })
                this.getRestaurantPhoto()
            }
        }

    }

    handleResForAllMenu = async (from: string, message: Message) => {
        if (this.getMenuCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.data) {
                this.setState({ restaurantMenu: apiResponse?.data })
            }
        }

    }

    handleShowMenuStatus = async (from: string, message: Message) => {
        if (this.showMenuStatusCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.data) {
                this.setState({ checkedG: apiResponse?.data.attributes.disable_menu })
            }
        }

    }

    handleResForTags = async (from: string, message: Message) => {
        if (this.getTagDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse.errors) {
                ToastMessage('error', "Something went wrong")
            } else {
                this.setState({ restaurantTagData: apiResponse.data || [] })
            }
        }

    }

    handleResForUpdateImage = async (from: string, message: Message) => {
        if (this.updateImageCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

      if (apiResponse.errors) {
        ToastMessage('error', "Something went wrong")
        this.setState({ anchorElPhotoMoreBtn: null })
      } else {
        ToastMessage('success', "Image Updated Successfully")
        this.setState({ overviewData: apiResponse.data.attributes, anchorElPhotoMoreBtn: null })
        this.props.sendData(apiResponse.data)
        location.reload();
      }
    }

    }

    // Customizable Area End

}