export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BackArrow = require("../assets/backArrowImg.png")
export const PlusImage = require("../assets/plusImg.png")
export const ResturantImage1 = require("../assets/resturantimg1.png")
export const ResturantImage2 = require("../assets/resturantimg2.png")
export const ResturantImage3 = require("../assets/resturantimg3.png")
export const ResturantImage4 = require("../assets/resturantimg4.png")
export const UploadCutImg = require("../assets/uploadImgCut.png")
export const SmallPlusImage = require("../assets/smallPlusImg.png")
export const CongratulationsImg = require("../assets/congratulations.png")
export const LocationImg = require("../assets/locationImg.png")
export const logo = require("../assets/logo.png")
export const fuduLogo = require("../assets/fudu.jpg")
export const appleLogo = require("../assets/applelogo.jpg")
export const googleLogo = require("../assets/googlelogo.jpg")
export const fbLogo = require("../assets/fblogo.jpg")
export const back = require("../assets/back.jpg")
export const GPS = require("../assets/GPS.png")
