import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import ToastMessage from "../../../components/src/ToastMessage";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleToggle:()=> void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  resturent: any;
  current_resturent: any;
  open: boolean;
  anchorEl: any;
  profileopen: boolean;
  profileanchorEl: any;
  imageFile: any;
  restaurantDetail: any;
  userDetails:any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class TopNavController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllResturentnameCallId: string = ''
  updateResturentCallId: string = ''
  GetimageResturentCallId:string=''
  getUserDetailsCallId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.state = {
      resturent: [],
      current_resturent: localStorage.getItem("locationID"),
      open: false,
      anchorEl: null,
      profileopen: false,
      profileanchorEl: null,
      imageFile: null,
      restaurantDetail: "",
      userDetails:{
        
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getResturentName();
    this.GetimageResturent();
    this.getUserDetails();
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getAllResturentnameCallId) {
      this.getAllResturent(responseJson);
    }
    else if (apiRequestCallId === this.updateResturentCallId) {
      this.updateResturentname(responseJson);
    }
    else if (apiRequestCallId === this.GetimageResturentCallId) {
      if (!responseJson.errors) {
        this.setState({restaurantDetail: responseJson.data?.attributes });
      } else {
        ToastMessage("error", "Something Went to  Wrong");
      }
    }
    else if (apiRequestCallId === this.getUserDetailsCallId) {
      this.setState({userDetails: responseJson.data?.attributes });
    }
    // Customizable Area End

  }


  // Customizable Area Start
  getAllResturent = async (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ resturent: responseJson.data.map((el: { id: string; attributes: { restaurant_nick_name: string; }; }) => { return { id: el.id, label: el.attributes.restaurant_nick_name } }) });
    } else {
      ToastMessage("error", "Something Went to  Wrong");
    }
  }
  updateResturentname = async (responseJson: any) => {
    if (!responseJson.errors) {
      localStorage.setItem("locationID", responseJson.data.id)
      window.location.reload()
    } else {
      ToastMessage("error", "Something Went to  Wrong");
    }
  }
  handleChange = (event: any) => {

    this.setState({ current_resturent: event.target.value }, () => {
      this.updateResturent();

    })
  }
  getResturentName = () => {
    let tokenValue = localStorage.getItem('authToken');
    const headers = {
      "Content-Type": "application/json",
      token: tokenValue,
    };
    const req = apiCall({
      httpBody: {},
      header: headers,
      url: 'bx_block_location/restaurant_locations',
      httpMethod: 'GET'
    })
    this.getAllResturentnameCallId = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  updateResturent = () => {
    let token = localStorage.getItem('authToken');
    const headerData = {
      token: token,
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: `bx_block_location/restaurant_locations/${this.state.current_resturent}/activate`,
      httpMethod: 'PUT'
    })

    this.updateResturentCallId = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  handleClick = (event: any) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickProfile = (event: any) => {
    this.setState({ profileopen: true, profileanchorEl: event.currentTarget });
  };

  handleCloseProfile = () => {
    this.setState({ profileopen: false });
  };
  profileLogout = () => {
    window.location.href = '/'
    localStorage.clear()
  }
  GetimageResturent = async () => {
    const locationID=localStorage.getItem("locationID")
    let token = localStorage.getItem('authToken');
    const header = {
      token: token,
    };
    const req = apiCall({
      httpBody: {},
      header: header,
      url:   `bx_block_location/restaurant_locations/${locationID}`,
      httpMethod: 'GET'
    })

    this.GetimageResturentCallId = req.messageId;
    runEngine.sendMessage(req.id, req);
  };

  getUserDetails = async () => {
    let token = localStorage.getItem('authToken');
    const header = {
      token: token,
    };
    const req = apiCall({
      httpBody: {},
      header: header,
      url:   `account_block/user_detail`,
      httpMethod: 'GET'
    })

    this.getUserDetailsCallId = req.messageId;
    runEngine.sendMessage(req.id, req);
  };
  // Customizable Area End

}
