import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Avatar,
    Paper, ImageList, ImageListItemBar, ImageListItem, Card, CardContent, CardActions, ListItem, List, ListItemIcon, ListItemText, Chip, CardMedia, Menu, MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { nonVegIcon, vegIcon } from "./assets"
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import RestaurantRoundedIcon from '@material-ui/icons/RestaurantRounded';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import GoogleMapReact from 'google-map-react';
import CreateIcon from '@material-ui/icons/Create';
import EditOverview from "./EditOverview.web";
import FeaturesEdit from "./FeaturesEdit.web";
import AddPhotosModal from "./AddPhotosModal.web";
import EditTags from "./EditTags.web";
import ProfileMenuUpload from '../../MenuOrdering/src/ProfileMenuUpload.web'
import ShowCreatedMenu from '../../MenuOrdering/src/ShowCreatedMenu.web'
import {Link} from "react-router-dom"
// Customizable Area End

// Customizable Area Start
const profileTheme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        subtitle2: {
            letterSpacing: "1px",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "22px",
            color: "#9CA3AF"
        },
        body1: {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#4B5563"
        },
        h5: {
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "22px",
            textTransform: "uppercase"
        },
        h4: {
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "19.36px",
            textAlign: "center"
        },
        body2: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "17px",
            color: "#9CA3AF",
            textAlign: "center"
        },
        h3: {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "17px",
        },
        h2: {
            fontWeight: 400,
            fontSize: "12px",
        },
        h1: {
            fontSize: "10px",
            fontWeight: 400
        }
    },
});


export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            minWidth: "92px",
            height: "36px",
            borderRadius: "21px",
            textTransform: "none",
            '&:hover': {
                opacity: "0.7",
            },
        },
    }),
)((props: any) => <Button {...props} />);

export const StyledButton2: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "100%",
            minHeight: "300px",
            backgroundColor: "#ECF0FF",
            border: "1px dashed #096FFF",
            borderRadius: "8px",
            '&:hover': {
                border: "3px dashed #096FFF",
            }
        },
    }),
)((props: any) => <IconButton {...props} />);
// Customizable Area End


import AboutPageController, {
    Props,
    configJSON,
} from "./AboutPageController";


export default class AboutPage extends AboutPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={profileTheme}>

                {/* Overview Paper */}
                <Paper elevation={3} style={webStyle.childrenSection as React.CSSProperties}>
                    <Grid container style={{ marginBottom: "10px" }} alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Overview</Typography>
                        <StyledButton data-test-id="overviewEditBtn" variant="contained" color="primary" onClick={this.handleOpenOverviewEditModal}>
                            Edit
                        </StyledButton>
                    </Grid>
                    <Typography variant="body1">
                        {this.state.overviewData.overview}
                    </Typography>
                    <Grid container style={webStyle.overviewMenu}>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="subtitle2">Restaurant Name</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.state.overviewData.restaurant_name}
                            </Typography>

                            <Typography variant="subtitle2">Contact No</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.state.overviewData.full_phone_number}
                            </Typography>

                            <Typography variant="subtitle2">Average Price for 2 People</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {`${this.state.overviewData.currency_type} ${this.state.overviewData.average_price}`}
                            </Typography>

                            <Typography variant="subtitle2">Website</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.state.overviewData.website}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="subtitle2">Location</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.state.overviewData.location}
                            </Typography>

                            <Typography variant="subtitle2">Email ID</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.state.overviewData.email}
                            </Typography>

                            <Typography variant="subtitle2">Operational Hours</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.state.overviewData.oprational_hour}
                            </Typography>

              <Typography variant="subtitle2">Address</Typography>
              <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                {this.state.overviewData.address}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography gutterBottom variant="subtitle2">Map Location</Typography>
              <div style={webStyle.mapContainer}>
                {/* @ts-ignore */}
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  options={{ fullscreenControl: false, keyboardShortcuts: false }}
                  center={{ lat: this.state.overviewData.latitude, lng: this.state.overviewData.longitude }}
                  zoom={16}
                >
                  <RestaurantRoundedIcon style={{ background: "#096FFF", color: "white", padding: "3px", borderRadius: "50%" }} />
                </GoogleMapReact>
              </div>
            </Grid>
          </Grid>
          {this.state.showOverview && <EditOverview navigation={'overview-model'} id={'overviewId'} show={this.state.showOverview} handleClose={this.handleCloseOverviewEdit} />}
        </Paper>

                {/* Feature Paper */}
                <Paper elevation={3} style={webStyle.childrenSection as React.CSSProperties}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Features</Typography>
                        <StyledButton data-test-id="featureEditBtn" variant="contained" color="primary" onClick={this.handleOpenFeatureEditModal}>
                            Edit
                        </StyledButton>
                    </Grid>
                    <Grid container style={webStyle.featureMenu} justifyContent="space-between">
                        <Grid item xs={12} lg={4}>
                            <Typography variant="subtitle2">Parking</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.returnYesNo(this.state?.featureListData?.parking_availability)}
                            </Typography>

                            <Typography variant="subtitle2">Kids Area</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.returnYesNo(this.state?.featureListData?.kids_area)}
                            </Typography>

                            <Typography variant="subtitle2">Shisha</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.returnYesNo(this.state?.featureListData?.sheesha)}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="subtitle2">Valet Parking</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.returnYesNo(this.state.featureListData?.valet_parking_availability)}
                            </Typography>

                            <Typography variant="subtitle2">Dining Type</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                               { configJSON.dining[this.state.featureListData?.dining]}
                            </Typography>

                            <Typography variant="subtitle2">Food Type</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                            { configJSON.foodType[this.state.featureListData?.category]}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography variant="subtitle2">Serves Alcohol</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.returnYesNo(this.state.featureListData?.serves_alcohol)}
                            </Typography>

                            <Typography variant="subtitle2">Speciality</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.state.featureListData?.speciality}
                            </Typography>

                            <Typography variant="subtitle2">Smoking Area</Typography>
                            <Typography style={webStyle.overviewMenuTable as React.CSSProperties} variant="body1">
                                {this.returnYesNo(this.state.featureListData?.smoking_area)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <FeaturesEdit navigation={'feature-model'} id={'featureId'} show={this.state.showFeatures} handleClose={this.handleCloseFeatureEdit} />
                </Paper>

                {/* Photos Paper */}
                <Paper elevation={3} style={webStyle.childrenSection as React.CSSProperties}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Photos</Typography>
                        <StyledButton data-test-id="photoAddBtn" variant="contained" color="primary" onClick={this.handleOpenPhotoAddModal}>
                            Add more
                        </StyledButton>
                    </Grid>
                    <Grid container spacing={2} style={webStyle.gridMenu}>
                        <ImageList style={{ width: '100%',margin: '0px -10px 0px 10px' }} >
                            {this.state.restaurantPhoto?.map((item: any, index: any) => {
                                return (
                                    <Grid item key={index} xs={12} sm={4} md={3} lg={2} style={{ width: '100%', minWidth: '18%' ,margin:'9px'}}>
                                            <ImageListItem key={index} style={{ width: '100%' }}>
                                                {/* @ts-ignore */}
                                                <img loading="lazy" style={{ width: '100%', height: '200px',borderRadius: '25px' }} src={item.url} />

                                                <ImageListItemBar
                                                    style={{ top: 0, background: 'none' }}
                                                    actionIcon={
                                                        <IconButton
                                                            data-test-id="moreIconBtn"
                                                            style={{
                                                                width: '24px',
                                                                height: '24px',
                                                                margin: '6px',
                                                                borderRadius: '50%',
                                                                backgroundColor: 'rgba(0, 0, 0, 0.24)',
                                                            }}
                                                            onClick={(e) => this.handleClickOpenMenu(e, item.id, item)}
                                                        >
                                                            <MoreHorizIcon style={{ color: 'white' }} />
                                                        </IconButton>
                                                    }
                                                    actionPosition="right"
                                                />
                                                <Menu
                                                    anchorEl={this.state.anchorElPhotoMoreBtn}
                                                    open={Boolean(this.state.anchorElPhotoMoreBtn)}
                                                    onClose={this.handleCloseMenu}
                                                    getContentAnchorEl={null}
                                                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                >
                                                    <MenuItem data-test-id="profileOption" onClick={() => this.setImageFromGallery(this.state.galleryImgData.url)}>
                                                        Make profile photo
                                                    </MenuItem>
                                                    <MenuItem data-test-id="coverOption" onClick={() => this.setImageFromGallery('', this.state.galleryImgData.url)}>
                                                        Make cover photo
                                                    </MenuItem>
                                                    <MenuItem data-test-id="menuDelOption" onClick={() => this.deleteImg()}>
                                                        Delete photo
                                                    </MenuItem>
                                                        <a href={this.state.galleryImgData?.url} target="_blank" style={{ textDecoration: 'none', color: '#4B5563' }}>
                                                    <MenuItem data-test-id="menuDelOption">
                                                            View
                                                    </MenuItem>
                                                        </a>
                                                </Menu>
                                            </ImageListItem>
                                    </Grid>
                                );
                            })}
                        </ImageList>

                    </Grid>
                    <AddPhotosModal navigation={'AddPhotosModal'} id={'AddPhotosModal'}
                        show={this.state.showAddPhotoModal} handleClose={this.handleCloseAddPhotoModal} />
                </Paper>

                {/* Menu Paper */}
                <Paper elevation={3} style={webStyle.childrenSection as React.CSSProperties}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Menu</Typography>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.checkedG} data-test-id = "menu_enable"
                            onChange={this.handleMenuChange} name="checkedG"/>}
                            label={this.state.checkedG ? "Enable menu for customers" : "Disable menu for customers"}
                        />
                        <StyledButton data-test-id="menuAddBtn" onClick={this.handleOpenMenuAddModal} variant="contained" color="primary">
                            Add more
                        </StyledButton>
                    </Grid>
                    <Grid container alignItems="stretch" style={webStyle.featureMenu} spacing={4}>
                        {this.state.restaurantMenu.map((data: any) => {
                            return (
                                <Grid item xs={12} lg={3} alignItems="stretch">
                                    <Card style={webStyle.cardRage}>
                                        <CardMedia
                                            style={{ height: "180px" }}
                                            image={data?.attributes?.image?.url}
                                        />
                                        <CardContent style={webStyle.cardContentContainer}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="h3" component="h2">
                                                        {data.attributes.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h3">
                                                        {data.attributes.price}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent="space-between" alignItems="center" style={webStyle.secondContainer}>
                                                <Grid item style={webStyle.menuData}>
                                                    <img src={data.attributes.item_type == 'veg' ? vegIcon : nonVegIcon} alt="food_type" height={12} width={12} />
                                                    <Typography variant="h1">
                                                        {data.attributes.item_type}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton data-test-id="menuEditBtn" style={{ padding: "0" }} onClick={() => this.handleOpenMenuEdit(data.id)}>
                                                        <CreateIcon style={{ fontSize: "16px" }} color="primary" />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            <Typography gutterBottom variant='h2' style={webStyle.descriptionRage as React.CSSProperties}>
                                                {data.attributes.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}

                        <Grid item xs={12} lg={3}>
                            <StyledButton2 onClick={this.handleOpenMenuAddModal}>
                                <AddRoundedIcon style={{ fontSize: "32px" }} color='primary' />
                            </StyledButton2>
                        </Grid>
                    </Grid>

                    <ProfileMenuUpload navigation={'ProfileMenuUpload-model'}
                        show={this.state.showAddMenuModal} handleClose={this.handleCloseMenuAdd} />
                    <ShowCreatedMenu navigation={'ShowCreatedMenu-model'} show={this.state.showEditMenuModal} handleClose={this.handleCloseMenuEdit} editID={this.state.menuEditID} />
                </Paper>

                {/* Table Layout  */}
                <Paper elevation={3} style={webStyle.childrenSectionT as React.CSSProperties}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Layout</Typography>
                       <Link to ="/TableLayout">
                        <StyledButton variant="contained" color="primary">
                            Go to table Layout screens
                      </StyledButton>
                      </Link>
                    </Grid>
                
                </Paper>

                {/* Tags Paper */}
                <Paper elevation={3} style={webStyle.childrenSection as React.CSSProperties}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Tags</Typography>
                        <StyledButton data-test-id="tagsEditBtn" variant="contained" color="primary" onClick={this.handleOpenEditTagsModal} >
                            Edit
                        </StyledButton>
                    </Grid>
                    <Grid container style={webStyle.featureMenu} spacing={4}>
                        {this.state.restaurantTagData.map((val: any) => {
                            return (
                                <Grid item>
                                    <Chip style={webStyle.tagChipRage as React.CSSProperties} label={val.attributes.title} />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <EditTags navigation={'tags-model'} id={'tagId'} show={this.state.showTags} handleClose={this.handleCloseEditTag} />
                </Paper>

            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    childrenSection: {
        marginTop: "24px",
        padding: "24px",
        borderRadius: "8px",
        position: "relative",
        overflowY: "auto",
        minHeight:"50px"
    },
    childrenSectionT: {
        marginTop: "24px",
        borderRadius: "8px",
        position: "relative",
        overflowY: "auto",
        padding: "24px",
    },
    overviewMenu: {
        marginTop: "24px"
    },
    featureMenu: {
        marginTop: "17px",
    },
    gridMenu: {
        display:'flex',
        marginTop: "17px",
        gridTemplateColumns: 'repeat(5, 1fr)',
    },
    overviewMenuTable: {
        marginBottom: "16px",
        maxWidth: "360px",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap:'break-word',
    },
    gridImage: {
        objectFit: "cover",
        borderRadius: "8px",
        width: "100%",
        height: "100%"
    },
    imageMoreIcon: {
        background: "none"
    },
    mapContainer: {
        height: '95%',
        width: '100%',
        minHeight: '200px'
    },
    cardRoot: {
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.121613)",
        borderRadius: "8px"
    },
    cardAavtar: {
        width: "84px",
        height: "84px",
        margin: "0px auto",
        marginBottom: "16px"
    },
    cardList: {
        padding: "2px"
    },
    cardBtnConatainer: {
        display: 'flex',
        justifyContent: "space-around",
        padding: "16px"
    },
    menuData: {
        display: "flex",
        gap: "8px",
        alignItems: "center"
    },
    cardRage: {
        border: "1px solid #ECF0FF",
        minHeight: "298px",
        borderRadius: "8px"
    },
    secondContainer: {
        marginTop: "10px"
    },
    cardContentContainer: {
        padding: "12px",
    },
    descriptionRage: {
        color: "#4B5563",
        marginTop: "14px",
        "overflow": "hidden",
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "WebkitLineClamp": "2",
        wordBreak: "break-all"
    },
    tagChipRage: {
        backgroundColor: "#F9FAFB",
        fontSize: "14px",
        fontWeight: "400"
    },
    tableSection:{
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        height: "150px",
        width: "100%",
        margin: "30px",
        padding:"30px"
    }
};
// Customizable Area End