import * as React from 'react';


import {
  Button,
  // Customizable Area Start
  Grid, Modal, ButtonProps, FormHelperText, Select
  // Customizable Area End
} from "@material-ui/core";

import { CSSProperties } from "react";


import EditOverviewController, {
  Props,
} from "./EditOverviewController";
import { BlueCrossImg } from "../src/assets";
import FormControl from '@material-ui/core/FormControl';
import * as Yup from "yup";
import { Formik } from "formik";
import GoogleMapReact from 'google-map-react';
import PlacesAutocomplete from "react-places-autocomplete";
import { withStyles, styled, createStyles } from "@material-ui/core/styles";
const phoneNumberRegex = /^(\+)?\d{7,15}$/;
const startTimeSchema = {
  start_hours: Yup.string().required("Can't be Empty").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
  start_mins: Yup.string().required("Can't be Empty").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
  start_time_type: Yup.string().required("Can't be Empty"),
};
const endTimeSchema = {
  end_hours: Yup.string().required("Can't be Empty").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
  end_mins: Yup.string().required("Can't be Empty").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
  end_time_type: Yup.string().required("Can't be Empty"),
};

const otherFieldsSchema = {
  overview_area: Yup.string().required("Can't be Empty"),
  avg_price_two: Yup.string().matches(/^\d+(\.\d+)?$/, 'Only numbers are allowed').required("Can't be Empty"),
  req_name: Yup.string().required("Can't be Empty"),
  contect: Yup.string()
    .matches(phoneNumberRegex, 'Phone number should be between 7 to 15 digits')
    .required("Can't be Empty"),
  website: Yup.string().required("Can't be Empty").matches(/^(www\.|http:\/\/|https:\/\/)/, "Website must start with 'www', 'http://', or 'https://'"),
  email: Yup.string().required("Can't be Empty").email("Please enter valid Email"),
  address: Yup.string().required("Can't be Empty"),
};

const StyledSelect: any = withStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        border: 'none'
      },
      '&:focus': {
        border: 'none',
        boxShadow: 'none'
      },
      '&:before': {
        border: 'none'
      },
      '&:after': {
        border: 'none'
      }
    },
    select: {
      '&:hover': {
        border: 'none'
      },
      '&:focus': {
        border: 'none',
        boxShadow: 'none'
      },
      '&:before': {
        border: 'none'
      },
      '&:after': {
        border: 'none'
      }
    }
  })
)((props: any) => <Select {...props} />);


const Listbox: any = styled('ul')(() => ({
  margin: 0,
  padding: 0,
  zIndex: 1000,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: 'white',
  overflow: 'auto',
}));

export default class EditOverview extends EditOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  genInput = (formikFun: any, title: string, testID: string, inputType: string, name: string, currencyType?: boolean) => {
    return (
      <FormControl style={{ paddingTop: "8px" }} variant="standard" fullWidth>
        <span style={overviewStyle.lable}>
          {title}
        </span>
        {currencyType &&
          <div style={overviewStyle.selectContainer}>
            <select name="currency_type" onChange={formikFun.handleChange} value={formikFun.values.currency_type} style={overviewStyle.selectRage}>
              <option value="KWD">KWD</option>
              <option value="USD">USD</option>
            </select>
            <input data-test-id={testID} onChange={formikFun.handleChange} value={formikFun.values[name]} type={inputType} name={name} style={overviewStyle.formInput1} />
          </div>
        }
        {!currencyType && <input data-test-id={testID} onChange={formikFun.handleChange} value={formikFun.values[name]} type={inputType} name={name} style={overviewStyle.formInput} />}
        {
          formikFun.touched[name] && formikFun.errors[name] &&
          (<FormHelperText error={true} style={{ marginTop: '-10px', fontSize: "14px" }} >
            {formikFun.errors[name]}
          </FormHelperText>
          )
        }
      </FormControl>
    )
  }
  getSuggestionClassName = (suggestion: any) =>
    suggestion.active ? "suggestion-item--active" : "suggestion-item";

  getSuggestionStyle = (suggestion: any) =>
    suggestion.active
      ? { backgroundColor: "#fafafa", cursor: "pointer" }
      : { backgroundColor: "#ffffff", cursor: "pointer" };
  // Customizable Area End
  render() {
    const { start_hours, start_time_type, start_mins, end_hours, end_time_type, end_mins } = this.formatTime(this.state.overViewData?.attributes?.oprational_hour || '00:00 PM  - 00:00 AM');
    return (

      <Modal
        open={this.props.show}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div style={{ width: "70%", height: "97%", position: "absolute", top: "1%", left: "10%", backgroundColor: "white", boxShadow: "24", paddingLeft: "40px", overflowY: 'auto' }}>
          <h2 style={{ display: "flex", justifyContent: "center" }}>Edit Overview </h2>
          <img src={BlueCrossImg} style={{
            position: "absolute",
            top: "18px",
            right: "18px"
          }}
            data-testid='close_icon' onClick={() => this.props.handleClose()} />

          {this.state.overViewData?.id && <Formik
            initialValues={{
              overview_area: this.state.overViewData?.attributes?.overview || '',
              req_name: this.state.overViewData?.attributes?.restaurant_name || '',
              contect: this.state.overViewData?.attributes?.full_phone_number || '',
              currency_type: this.state.overViewData?.attributes?.currency_type || 'KWD',
              website: this.state.overViewData?.attributes?.website || '',
              email: this.state.overViewData?.attributes?.email || '',
              start_hours: start_hours,
              start_mins: start_mins,
              start_time_type: start_time_type,
              end_hours: end_hours,
              end_mins: end_mins,
              end_time_type: end_time_type,
              address: this.state.overViewData?.attributes?.address,
              avg_price_two: this.state.overViewData?.attributes?.average_price || ''
            }}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              ...startTimeSchema,
              ...endTimeSchema,
              ...otherFieldsSchema
            })}
            onSubmit={(e) => {
              this.updateOverview(e);
            }}
          >{({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit
          }) => {
            const hasErrors = (
              (touched.start_hours && errors.start_hours) ||
              (touched.end_hours && errors.end_hours) || (touched.start_mins && errors.start_mins) || (touched.end_mins && errors.end_mins)
            );

            return (<>
              <form onSubmit={handleSubmit} >
                <Grid container style={overviewStyle.container}>

                  <Grid item lg={6} xs={12} style={{ marginTop: '20px' }}>

                    {this.genInput({ handleChange, errors, touched, values }, 'Restaurant name', 'input_restaurant', 'text', 'req_name')}

                    <FormControl style={{ paddingTop: "8px" }} variant="standard" fullWidth>
                      <span style={overviewStyle.lable}>
                        Location
                      </span>

                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={(address) => this.setState({ address })}
                        onSelect={this.handleSelect}
                        data-testid='location'
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input

                              style={overviewStyle.formInput}
                              {...getInputProps({
                                placeholder: "Search Places...",
                                className: "location-search-input"
                              })}
                            />
                            <Listbox className="autocomplete-dropdown-container" style={{ overflowY: 'auto', maxHeight: '100px', width: '80%', cursor: 'pointer' }}>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className: this.getSuggestionClassName(suggestion),
                                    style: this.getSuggestionStyle(suggestion),
                                  })}
                                  style={{ overflowWrap: 'break-word' }}
                                >
                                  {suggestion.description}
                                </div>
                              ))}
                            </Listbox>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </FormControl>

                    {this.genInput({ handleChange, errors, touched, values }, 'Contact No.', 'input_contact_no', 'text', 'contect')}

                    {this.genInput({ handleChange, errors, touched, values }, 'Email ID', 'input_email', 'text', 'email')}

                    {this.genInput({ handleChange, errors, touched, values }, 'Average price for 2 people', 'input_avg_price', 'text', 'avg_price_two', true)}

                    <FormControl variant="standard" style={{ paddingTop: "8px" }} fullWidth>
                      <span style={overviewStyle.lable}>
                        Operational hours
                      </span>
                      <Grid container style={overviewStyle.timeConatiner}>
                        <Grid item xs={12} lg={5} style={{ ...overviewStyle.timeDiv }}>
                          <input onChange={handleChange} data-test-id='input_start_hours' name="start_hours" value={values.start_hours} type='text' onKeyDown={e => this.preventETypeNumber(e)} style={overviewStyle.inputTime} maxLength={2} />
                          <h3>:</h3>
                          <input onChange={handleChange} data-test-id='input_start_mins' name="start_mins" value={values.start_mins} type='text' onKeyDown={e => this.preventETypeNumber(e)} style={overviewStyle.inputTime} maxLength={2} />
                          <StyledSelect onChange={handleChange} data-test-id='input_start_type' name="start_time_type" style={overviewStyle.selectHrs} value={values.start_time_type}>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </StyledSelect>
                        </Grid>
                        <Grid item xs={12} lg={2}> <p style={{ ...overviewStyle.timeDiv, marginLeft: "5px" }}> to </p></Grid>
                        <Grid item xs={12} lg={5} style={{ ...overviewStyle.timeDiv }}>
                          <input onChange={handleChange} data-test-id='input_end_hour' name="end_hours" type='text' value={values.end_hours} onKeyDown={e => this.preventETypeNumber(e)} style={overviewStyle.inputTime} maxLength={2} />
                          <h3>:</h3>
                          <input onChange={handleChange} data-test-id='input_end_mins' name="end_mins" type='text' value={values.end_mins} onKeyDown={e => this.preventETypeNumber(e)} style={overviewStyle.inputTime} maxLength={2} />

                          <StyledSelect onChange={handleChange} data-test-id='input_end_type' name="end_time_type" style={overviewStyle.selectHrs} value={values.end_time_type}>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </StyledSelect>
                        </Grid>
                      </Grid>

                      {hasErrors && (
                        <FormHelperText error={true} style={{ marginTop: '-10px', fontSize: "14px" }}>
                          {errors.start_hours || errors.start_mins || errors.start_time_type || errors.end_hours || errors.end_mins || errors.end_time_type}
                        </FormHelperText>
                      )}
                    </FormControl>

                    {this.genInput({ handleChange, errors, touched, values }, 'Website', 'input_website', 'text', 'website')}
                  </Grid>

                  <Grid item lg={6} xs={12} style={{ marginTop: '20px' }}>

                    <label style={overviewStyle.lable}>Map Location</label>
                    <div style={{ width: "90%", height: "50%" }}>

                      <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyDggGoFSnzL7Vley8afXotWIAJWB4-DZcA" }}
                        options={{ fullscreenControl: false, keyboardShortcuts: false }}
                        center={{ lat: this.state.latitude, lng: this.state.longitude }}
                        zoom={this.state.zoom}
                        data-test-id='gmap'
                        onGoogleApiLoaded={({ map, maps }: any) => this.handleMarkerPosition(map, maps)} />

                    </div>

                    {this.genInput({ handleChange, errors, touched, values }, 'Address', 'input_address', 'text', 'address')}

                    {this.genInput({ handleChange, errors, touched, values }, 'Overview', 'input_overview', 'text', 'overview_area')}

                    <Grid container style={{ width: '90%', height: '90px', marginTop: "11px" }}>
                      <Grid item xs={12} lg={6}>
                        <Button color={'primary'} data-test-id='button_cancle' type="submit" {...overviewStyle.formbuttoncancle} variant="outlined" onClick={() => this.props.handleClose()}  >Cancel</Button>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Button color={'primary'} data-test-id='button_next' type="submit" {...overviewStyle.formbutton} variant="contained" >Next</Button>
                      </Grid>
                    </Grid>

                  </Grid>

                </Grid>
              </form>
            </>);
          }}
          </Formik>}

        </div>
      </Modal>
    );
  }
}
interface StyleType {
  lable: CSSProperties;
  formInput: CSSProperties;
  formbutton: ButtonProps;
  formbuttoncancle: ButtonProps;
  container: any;
  timeConatiner: CSSProperties;
  timeDiv: CSSProperties;
  inputTime: CSSProperties;
  selectHrs: CSSProperties;
  formInput1: CSSProperties;
  selectRage: CSSProperties;
  selectContainer: CSSProperties;
}
const overviewStyle: StyleType = {
  lable: {
    color: "#9CA3AF",
    fontSize: "16px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    marginTop: "5px",
  },
  formInput: {
    backgroundColor: "#F9FAFB",
    width: "80%",
    height: "40px",
    fontSize: "14px",
    borderRadius: "5px",
    margin: "10px 0px",
    border: "1px solid #E5E7EB",
    padding: '8px',
  },
  selectContainer: {
    width: '96%',
  },
  formInput1: {
    backgroundColor: "#F9FAFB",
    width: "72%",
    height: "40px",
    fontSize: "14px",
    borderRadius: "0px 5px 5px 0px",
    margin: "10px 0px",
    border: "1px solid #E5E7EB",
    padding: '8px',
  },
  selectRage: {
    backgroundColor: "#F9FAFB",
    height: "40px",
    fontSize: "14px",
    borderRadius: "5px 0px 0px 5px",
    margin: "10px 0px",
    border: "1px solid #E5E7EB",
    padding: '8px',
  },
  formbutton: {
    style: {
      width: "90%",
      marginTop: "20px",
      textTransform: "none",
      fontSize: "16px",
      color: "white",
      borderRadius: '8px'
    },

  },
  formbuttoncancle: {
    style: {
      width: "90%",
      marginTop: "20px",
      textTransform: "none",
      fontSize: "16px",
      border: '1px solid #096FFF',
      borderRadius: '10px',
      color: "#096FFF",
    }
  },
  container: {
    padding: "10px 0px 0px 15px",
    border: "1px solid #E5E7EB",
    borderRadius: "15px",
    marginBottom: "15px",
    display: 'flex',
    width: '97%',
    justifyContent: 'space-evenly'
  },
  timeConatiner: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    width: "88%"
  }
  ,
  timeDiv: {
    display: "flex",
    alignItems: "center",
    gap: '20px'
  },
  inputTime: {
    width: "30px",
    height: "32px",
    padding: "5px",
    backgroundColor: "#f3f4f6",
    border: "none",
    borderRadius: "2px"
  },
  selectHrs: {
    height: "32px",
    padding: "2px",
    marginLeft: "5px",
    backgroundColor: "#f3f4f6",
    border: "none",
    borderRadius: "2px"
  },
}
