import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import ToastMessage from "../../../components/src/ToastMessage";
import { apiCall } from "../../../components/src/common";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleClose: () => void;
    show: boolean;
}

export interface S {
    // Customizable Area Start
    tagsData: any
    storeTag: any
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditTagsControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    updateTagCallId: string = "";
    getTagsCallId: string = "";
    storeTagCallId:string='';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            tagsData: [],
            storeTag: [],
            // Customizable Area End
        };

        // Customizable Area Start

        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }
        // Customizable Area Start

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (!apiRequestCallId || !responseJson) {
            ToastMessage("error", "Something Went Wrong");
            return;
        }

        if (apiRequestCallId === this.updateTagCallId) {
            this.handleUpdateTags(responseJson);
        } else if (apiRequestCallId === this.getTagsCallId) {  
            this.handleGetAllTags(responseJson);   
        } else if (apiRequestCallId===this.storeTagCallId){   
             this.handleGetUserags(responseJson);
        } 
        // Customizable Area End

    }

    // Customizable Area Start

    handleUpdateTags =async (responseJson:any) => {
            ToastMessage("success", "Tag Added");
            this.props.handleClose()
    }
   
    handleGetAllTags =async (responseJson:any) => {
        if (!responseJson.errors) {
            this.setState({ tagsData: responseJson.data.map((el: { id: string; attributes: { title: string; }; }) => { return { id: el.id, title: el.attributes.title } }) });
        } else {
            ToastMessage("error", "Something Went to  Wrong");
        }
    }

   
    handleGetUserags =async (responseJson:any) => {
        if (responseJson?.data || responseJson.message) {
            if(responseJson?.data?.length >0 ){
             this.setState({ storeTag: responseJson.data.map((el: { id: string; attributes: { title: string; }; }) => { return { id: el.id, title: el.attributes.title } }) });
            this.setState({ tagsData: this.state.tagsData.filter((item: { id: string; }) => {
                return !responseJson.data.some((currentItem: { id: string; }) => {
                  return currentItem.id === item.id;
                });
              })});
            }
        } else {
            ToastMessage("error", "Something Went to  Wrong");
        }
    }

  async componentDidMount() {
    await this.getTagsData();
    await this.storeTagData()
  }

    updateTags = () => {
        const headerData = {
            "Content-Type": "application/json",
            "token": localStorage.getItem("authToken")
        };

        const httpBody = {
            data: {
                 "attributes": {
                    "tag_ids": this.state.storeTag.map((el: { id: string; }) => el.id)
                }
            }
        };
        const req = apiCall({
            httpBody: httpBody,
            header: headerData,
            url: 'account_block/tags/update_tags',
            httpMethod: 'PUT'
        })

        this.updateTagCallId = req.messageId;
        runEngine.sendMessage(req.id, req);
    }

    


    getTagsData = async () => {
        const headergetData = {
            "Content-Type": "application/json",
            "token": localStorage.getItem("authToken")
        };

        const req = apiCall({
            httpBody: {},
            header: headergetData,
            url: 'account_block/tags',
            httpMethod: 'GET'
        })
        this.getTagsCallId = req?.messageId;
        runEngine.sendMessage(req?.id, req);

    }
   
    storeTagData = async () => {
        const headergetData = {
            "Content-Type": "application/json",
            "token": localStorage.getItem("authToken")
        };

        const req = apiCall({
            httpBody: {},
            header: headergetData,
            url: 'account_block/tags/current_user_tags',
            httpMethod: 'GET'
        })
        this.storeTagCallId = req?.messageId;
        runEngine.sendMessage(req?.id, req);

    }

    showTagData = (index: string) => {
        this.setState({ storeTag: [...this.state.storeTag, this.state.tagsData.find((el: { id: string; }) => el.id === index)] })
        this.setState({ tagsData: this.state.tagsData.filter((i: any) => i.id !== index) })
    }
    deleteTag = (index: string) => {
        this.setState({ tagsData: [...this.state.tagsData, this.state.storeTag.find((el: { id: string; }) => el.id === index)] })
        this.setState({ storeTag: this.state.storeTag.filter((i: any) => i.id !== index) })
    }

    // Customizable Area End
}
