import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SupportChatController, { Props } from "./SupportChatController";
import { men, send, attachtransparent } from "./assets";

const Styles = () => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px"
  },

  needBlock: {
    padding: "10px 0 30px 20px"
  },

  needheading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#111111",
    margin: "0"
  },

  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "16px",
    margin: "25px 0 0",
    height:'100%',
  },

  chatheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 30px",
    borderBottom: "1px solid #EBEBEB"
  },

  open: {
    color: "#FF0A0A",
    backgroundColor: "#FFCECE",
    padding: "7px 16px",
    fontSize: "12px",
    borderRadius: "18px",
    border: "1px solid"
  },

  closed: {
    color: "#0FBB4C",
    backgroundColor: "#E2FFEC",
    padding: "7px 25px",
    fontSize: "12px",
    borderRadius: "18px",
    border: "1px solid"
  },

  chatbody: {
    padding: "20px 30px"
  },

  profiledetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px"
  },

  profileimage: {
    height: "24px",
    width: "24px",
    borderRadius: "100%",
    marginRight: "10px"
  },

  profilename: {
    fontSize: "15px",
    color: "#111827",
    fontWeight: 500,
  },

  chattextbox: {
    background: "#F8F8F8",
    padding: "10px 15px",
    maxWidth: "450px",
    borderRadius: "0 10px 10px 10px",
    marginBottom: "10px",
    backgroundColor:'#f7f4f2',
  },

  chattext: {
    color: "#111827",
    fontSize: "14px",
    margin: "0 0 5px 0"
  },

  chattime: {
    color: "#9CA3AF",
    fontSize: "12px",
    display: "flex",
    justifyContent: "end"
  },

  rightsidetextbox: {
    display: "flex",
    justifyContent: "end"
  },

  rightsidetext: {
    background: "#F1F8FF",
    padding: "10px 15px",
    maxWidth: "450px",
    borderRadius: "10px 0 10px 10px",
    marginBottom: "10px",
    backgroundColor: '#f0f0ff'
  },

  inputarea: {
    padding: "20px 30px",
    position: "relative"
  },

  input: {
    padding: "6px 10px",
    outline: "none",
    marginTop: "10px",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    width: "100%",
    background: "#f9fafb",
    height: "50px !important"
  },

  imagebox: {
    position: "absolute",
    top: "41px",
    right: "45px"
  },

  attachtransparent: {
    height: "20px",
    width: "20px",
    paddingRight: "10px",
    marginBottom:"5px",
  },

  send: {
    height: "30px",
    width: "30px"
  },

  heading: {
    fontSize: "20px"
  },

  btnbox: {
    backgroundColor: "#DBE7F9 !important",
    padding: "20px 30px",
    display: "flex",
    width:'92%',
    marginLeft:'2%',
    borderRadius:'10px',

  },

  text: {
    fontSize: "16px",
    marginRight: "15px"
  },

  btntransparent: {
    background: "#fff",
    border: "1px solid #096fff",
    color: "#096fff",
    minHeight: "40px",
    minWidth: "150px",
    borderRadius: "8px",
    fontSize: "15px"
  },

  btntheme: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "40px",
    minWidth: "150px",
    borderRadius: "8px",
    fontSize: "15px",
    marginRight: "15px"
  }
});
// Customizable Area End

class SupportChat extends SupportChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    /* @ts-ignore */
    const { classes } = this.props;

    return (
      // Customizable Area Start
        <Grid item sm={12} style={{height:'87%'}}>
          <div className={classes.needBlock}>
            <h2 className={classes.needheading}>Support</h2>
            <Grid className={classes.wrapperSubscreption}>
              <section style={{paddingBottom:'26px'}}>
                <div className={classes.chatheader}>
                  <h1 className={classes.heading}>Query Response</h1>
                  <div>
                  {this.state.chatStatus === false? <span className={classes.closed}>Closed</span>:""}
                    {/* <span className={classes.open}>Open</span> */}
                  </div>
                </div>
                <div className={classes.chatbody}>
                  {[0, 1, 2].map((elem, i) => {
                    return (
                      <section key={i}>
                        {/* Left Side Part  */}
                        <div className={classes.chatcontent}>
                          <div className={classes.profiledetails}>
                            <img
                              src={men}
                              alt=""
                              className={classes.profileimage}
                            />
                            <span className={classes.profilename}>
                              Victor Roberts
                            </span>
                          </div>
                          <div className={classes.chattextbox}>
                            <p className={classes.chattext}>
                              Hi Justin! We just wanted to welcome you to our
                              team.
                            </p>
                            <span className={classes.chattime}>09 : 52 am</span>
                          </div>
                        </div>
                        {/* Right Side Part Text of other user  */}
                        <div className={classes.rightsidetextbox}>
                          <div className={classes.rightsidetext}>
                            <p className={classes.chattext}>
                              Hi Justin! We just wanted to welcome you to our
                              team.
                            </p>
                            <span className={classes.chattime}>09 : 52 am</span>
                          </div>
                        </div>
                      </section>
                    );
                  })}
                </div>

            {this.state.chatStatus===false? <div className={classes.btnbox}>
                  <p className={classes.text}>
                    Are you satisfied with the resolution provided by the team ?
                  </p>
                  <button className={classes.btntheme}>Yes</button>
                  <button className={classes.btntransparent} onClick={()=>this.setState({chatStatus:true})}>No</button>
               </div>:
                <div className={classes.inputarea}>
                  <input
                    type="text"
                    placeholder="Type a message..."
                    className={classes.input}
                  />
                  <div className={classes.imagebox}>
                    <img
                      src={attachtransparent}
                      alt=""
                      className={classes.attachtransparent}
                    />
                    <img src={send} alt="" className={classes.send} />
                  </div>
                </div>}
              </section>
            </Grid>
          </div>
        </Grid>
      // Customizable Area End
    );
  }
}

/* @ts-ignore */

SupportChat.propTypes = {
  classes: PropTypes.object.isRequired
};

/* @ts-ignore */
export default withStyles(Styles)(SupportChat);
export { SupportChat };
// Customizable Area End
