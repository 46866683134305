import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Dialog,
    ImageList, ImageListItem
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import AddIcon from '@material-ui/icons/Add';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Customizable Area End

// Customizable Area Start
const customTheme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: "#9CA3AF" },
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h1: {
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "27px"
        },
        caption: {
            fontSize: "12px",
            fontWeight: 300,
            lineHeight: "24px"
        },
        body1: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px"
        }
    },
});

export const StyledButton1: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            minHeight: "64px",
            textTransform: 'none',
            fontSize: "16px",
            fontWeight: 500,
            borderRadius: "8px"
        },
    }),
)((props: any) => <Button {...props} />);

export const StyledButton2: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            width: "100%",
            minHeight: "300px",
            backgroundColor: "#ECF0FF",
            border: "1px dashed #096FFF",
            borderRadius: "8px"
        },
    }),
)((props: any) => <IconButton {...props} />);

// Customizable Area End

// Customizable Area Start
import AddPhotosModalController, {
    Props,
} from "./AddPhotosModalController";
// Customizable Area End

export default class AddPhotosModal extends AddPhotosModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={customTheme} >

        <Dialog

          maxWidth='md'
          open={this.props.show}
          style={{ height: '100vh' }}
        >
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            style={{ zIndex: 9999 }}
            rtl={false}
          />
          <Grid container>
            <Grid container style={webStyle.dialogHeadingSection}>

              <Typography style={{ padding: "16px 0px", marginLeft: '50px' }} variant="h1">Add Restaurant Images</Typography>
              <IconButton onClick={this.handleCloseModal} data-test-id="cancel_icon">
                <CancelIcon color="primary" />
              </IconButton>
            </Grid>

            <Grid item xs={12} style={webStyle.dialogMainSection}>
              {!this.state.openUploadedImg && <>
                <div>
                  <label htmlFor="file-upload">
                    <StyledButton2 component="span" data-test-id="button2">
                      <AddRoundedIcon style={{ fontSize: "24px" }} color='primary' />
                    </StyledButton2>
                  </label>
                  <input
                    data-test-id="fileInput"
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                    onChange={this.handleFileUpload}
                    multiple
                    accept="image/*"
                  />
                </div>
                <Typography gutterBottom color="secondary" variant="caption">
                  You can select multiple images
                </Typography>
                <Typography color="secondary" variant="body1">
                  Supported image type: <span style={{ color: "#4B5563" }}>
                    .jpg, .png
                  </span>
                </Typography>
                <Typography color="secondary" variant="body1">
                  Maximum image size:  <span style={{ color: "#4B5563" }}>
                    5MB per image
                  </span>
                </Typography><Typography color="secondary" variant="body1">
                  No. of images accepted: <span style={{ color: "#4B5563" }}>
                    Upto 10 images
                  </span>
                </Typography>
              </>}

              {this.state.openUploadedImg &&
                <div style={{ padding: "0px 96px" }}>
                  <ImageList rowHeight={160} cols={2} >
                    {this.state.displayFiles?.map((item: any, index: any) => {
                      return (
                        <Grid item lg={3} md={3} sm={4} xs={12}>
                          <ImageListItem key={index} style={webStyle.ImageList as React.CSSProperties}>
                            <img
                              alt={item.name}
                              src={URL.createObjectURL(item)}
                              style={webStyle.gridImage as React.CSSProperties}
                            />

                            <div style={{ position: 'absolute', top: '-5px', right: '0px' }}>
                              <IconButton
                                onClick={() => this.deleteImg(index)} data-test-id="deleteImg">
                                <CancelRoundedIcon
                                  color="primary" style={{ fontSize: "18px" }} />
                              </IconButton>
                            </div>

                          </ImageListItem>
                        </Grid>
                      )
                    })}
                  </ImageList>
                  <br />
                  <Typography onClick={this.goUploadPage}
                    data-test-id="addMoreButton"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      color: '#096FFF',
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}>
                    <AddIcon style={{ background: "#ECF0FF", marginRight: "12px" }} color='primary' />
                    Add More
                  </Typography>
                </div>
              }


              <Grid container direction="row" spacing={2} style={{ margin: "48px 0px" }}>
                <Grid item xs={12} lg={6}>
                  <StyledButton1 onClick={this.handleCloseModal} fullWidth variant="outlined" color="primary" data-test-id="cancel_button">
                    Cancel
                  </StyledButton1>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <StyledButton1 data-test-id='addbtn' onClick={this.FileUpload} fullWidth variant="contained" color="primary" style={{ opacity: this.state.openUploadedImg ? 1 : 0.5 }}>
                    Add
                  </StyledButton1>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  dialogHeadingSection: {
    borderBottom: "1px solid rgb(226, 228, 230)",
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },

  dialogMainSection: {
    padding: "0px 50px",
    marginTop: "40px"
  },

  gridImage: {
    objectFit: "cover",
    borderRadius: "8px",
    width: "148px",
    height: "148px"
  },
  ImageList: {
    borderRadius: "8px",
    width: "156px",
    height: "150px",
    marginLeft: ' 10px'
  },
};
// Customizable Area End

