export const BlueRountImg = require("../assets/blueRound.png");
export const BlueEyeImg = require("../assets/blueEye.png")
export const BlueEditImg = require("../assets/blueEdit.png")
export const ManImg = require("../assets/manImg.png")
export const recent = require("../assets/recent.png")
export const photo = require("../assets/photo.png")
export const photo2 = require("../assets/photo2.png")
export const photo3 = require("../assets/photo3.png")
export const group = require("../assets/group.png")
export const trend = require("../assets/trend.png")
export const home = require("../assets/home.png")
export const message = require("../assets/message.png")
export const restaurant = require("../assets/restaurant.png")
export const profile = require("../assets/profile.png")
export const icon = require("../assets/searchicon.png")
export const home1= require("../assets/home1.png")
export const rectangle = require("../assets/rectangle.png")
export const dots = require("../assets/dots.png")
export const QueueManImg = require("../assets/manageQueueManImg.png")
export const logo = require("../assets/logo.png");
export const likeLogo= require("../assets/Shapelike.png")
export const res1 =  require("../assets/res1.png")
export const drive = require("../assets/drive.png")
export const reserve = require("../assets/reserve.png")
export const takeaway= require("../assets/takeaway.png")
export const hamburger = require("../assets/hamburger.png")
export const location = require("../assets/location.png")
export const groups = require("../assets/groups.png")
export const shareIcon = require("../assets/share.png")
export const edit = require("../assets/edit.png")
export const arrow = require("../assets/arrow.png")
export const homeLine = require("../assets/homeLine.png")
export const discount = require("../assets/discount.png")
export const dropDown = require("../assets/downArrow.png")
export const success = require("../assets/success.png")
export const chats = require("../assets/chats.png")
export const setting = require("../assets/Settings.png")
export const Logoutpng = require("../assets/logout.png")
export const myreservations = require("../assets/My-reservations.png")
export const menuIcon = require("../assets/Shapemenu.png")
export const cancelImg = require("../assets/cancel.png")
export const shareIcon1 = require("../assets/shareIcon.png")
export const pending = require("../assets/pending.png")
export const member= require("../assets/members.png")
export const filter = require("../assets/filter.png")
export const add = require("../assets/add.png")
export const card = require("../assets/card.png")

export const favourites= require("../assets/favourites.png")
export const orders = require("../assets/Myorders.png")
export const voucher = require("../assets/voucher.png")
export const payment = require("../assets/earnings.png")
export const helpUs = require("../assets/HelpCenter.png")
export const aboutUs = require("../assets/AboutUs.png")
export const whatsapp = require("../assets/whatsapp.png") 

export const cashImg  = require("../assets/cash.png")
export const masterCard = require("../assets/mastercard.png")
export const visaCard = require("../assets/visacard.png")
export const minus = require("../assets/minusIcon.png")
export const user = require("../assets/user.png")
export const xButton = require("../assets/xbutton.png");
export const banner = require("../assets/Bitmap.png");
export const backArrow = require("../assets/back.jpg")
export const image = require("../assets/image.jpg")
export const resTab = require("../assets/resTab.png")
export const messageBlue = require("../assets/messageBlue.png")
export const profileBlue = require("../assets/profileBlue.png")


// Settings page icons
export const userSettings = require("../assets/account_user.png")
export const bellSettings = require("../assets/account_bell.png")
export const influencerSettings = require("../assets/account_influencer.png")
export const passwordSettings = require("../assets/account_password.png")
export const TCSettings = require("../assets/account_T_and_C.png")
export const prifvacySettings = require("../assets/account_privacy.png")
export const verifiedSettings = require("../assets/group_check.png")

// drawer page icon
export const myProposal = require("../assets/my_proposal.png")
export const myProposalProfile= require("../assets/profile123.png")

export const bellIcon = require("../assets/bell.png")
export const table = require('../assets/table.png')
