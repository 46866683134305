import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  checked: boolean;
  termsCheckbox:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changepasswordCallId: string = ""
  notificationOnOffCallID: string = ""
  checkboxOnOffCallID: string = ""
  DietaryRestricationAPICallID: string = ""
  GetDietaryRestricationAPICallID: string = ""
  DeleteDietaryRestricationAPICallID: string = ""
  PostFoodPrefrencesCallID: string = ""
  GetFoodPrefrencesCallID: string = ""
  DeleteFoodPrefrencesCallID: string = ""
  ReservationSettingCallID: string = ""
  PatchReservationCallID: string = ""
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      checked: true,
      termsCheckbox:false,
    };

    // Customizable Area Start

    // Customizable Area End


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!apiRequestCallId || !responseJson) {
      ToastMessage("error", "Something Went Wrong");
      return;
    }

    if (apiRequestCallId === this.notificationOnOffCallID) {
      this.setState({ checked: responseJson.data?.attributes?.notification_on })
    } else if (apiRequestCallId === this.checkboxOnOffCallID) {
      this.setState({ termsCheckbox: responseJson.data?.attributes?.terms_and_condition_checked })
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => { },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };


  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  
  HandlePrivacypolicy = () => {
    window.open(window.location.origin + '/PrivacyPolicy', '_blank');

  }
  HandleTermsCondition = () => {
    window.open(window.location.origin + '/TermsAndCondition', '_blank');

  }
  signout = () => {
    window.location.href = '/'
    localStorage.clear()
  }

  notificationOnOff = (event: React.ChangeEvent<HTMLInputElement>) => {
    const headerData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    const httpBody = {
      "data": {
        "attributes": {
          "notification_on": event.currentTarget.checked,
        }
      }
    }
    const req = apiCall({
      httpBody: httpBody,
      header: headerData,
      url: 'account_block/accounts',
      httpMethod: 'PUT'
    })
    this.notificationOnOffCallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  checkboxOnOff = (event: any) => {
    const headerData = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("authToken")
    };
    const httpBody = {
      "data": {
        "attributes": {
          "terms_and_condition_checked": event.currentTarget.termsCheckbox,
        }
      }
    }
    const req = apiCall({
      httpBody: httpBody,
      header: headerData,
      url: 'account_block/accounts',
      httpMethod: 'PUT'
    })
    this.checkboxOnOffCallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }
  // Customizable Area End
}
