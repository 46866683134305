import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Modal,
  Platform,
  FlatList,
  Image
} from "react-native";
import AntDesign from 'react-native-vector-icons/AntDesign';
// Customizable Area End

import { Props, configJSON } from "./FavouritesController";

import FavouritesController from "./FavouritesController";
import Scale from "../../../components/src/Scale";
import { image} from "../../dashboard/src/assets";

export default class Favourites extends FavouritesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <ScrollView style={styles.container}>
        {/* Customizable Area Start */}
        <View style={{ marginTop: 20}}>
        <View style={{marginHorizontal: 20,}}>
            <View >
                <TouchableOpacity testID="backId" onPress={()=>this.props.navigation.goBack()}>
                        <View style={styles.circleFav}>
                            <AntDesign name='arrowleft' size={28} color='#4B5563' />
                        </View>
                </TouchableOpacity>
              
            </View>
            <Text style={styles.textStyle}>My Favourites</Text>
         </View>
          <View >
            <View style={{

              marginTop: 20,
              flexDirection: "row",
              justifyContent: "space-around"
            }}>
              <TouchableOpacity onPress={this.rest}
              >
                <View>
                  <Text style={this.state.restaurants ? {
                    fontSize: Scale(18),
                    fontWeight: "bold",
                    color: "#326CF7"
                  } : { fontSize: Scale(18), fontWeight: "bold" }} >
                    Places
                  </Text>

                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={this.food}
              >
                <View>
                  <Text style={this.state.food ? {
                    fontSize: Scale(18),
                    fontWeight: "bold",
                    color: "#326CF7"
                  } : { fontSize: Scale(18), fontWeight: "bold" }} >
                    Foods
                  </Text>

                </View>
              </TouchableOpacity>

            </View>

            <View style={{ borderWidth: 0.7, width: "100%", borderColor: "#F2F2F2", backgroundColor: "#F2F2F2", marginTop: Scale(12), flexDirection: 'row' }}>
              <View style={[{ marginLeft: Scale(50), borderWidth: 1, width: "26%", borderStyle: "solid" }, { borderColor: this.state.restaurants ? '#096FFF' : "#F2F2F2", }]}></View>
              <View style={[{ marginLeft: Scale(100), borderWidth: 1, width: "26%", borderStyle: "solid" }, { borderColor: this.state.food ? '#096FFF' : "#F2F2F2", }]}></View>
            </View>

          </View>

          <FlatList
              testID="RestType"
              data={this.getFavList()}
              renderItem={({item}:any)=>{
                const data=item??{
                  attributes:{
                    favouriteable_type:"",
                    restaurant_location:{
                      restaurant_profile_image:"",
                      favouriteable_id:"",
                      distance:"",
                      restaurant_name:"",
                      address:"",
                      oprational_hour:"",
                      average_price:""
                    }
                  
                }
                }
                if(data.attributes.favouriteable_type==="BxBlockLocation::RestaurantLocation"){
                  return (
                    <View key={data} style={styles.mainContent}>
                      <TouchableOpacity testID="RestaurantDetailsId" onPress={() => this.navigateOnNext(item)}>
                        <Image
                          style={styles.imageCover}
                          resizeMode="cover"
                          source={
                            data.attributes.restaurant_location.restaurant_profile_image != null ?
                              {
                                uri: data.attributes.restaurant_location.restaurant_profile_image.toString(),
                              } :
                              { uri: '' }}
                        ></Image>

                        <View style={styles.likeImageBtn1}>

                          <TouchableOpacity testID="onLike" onPress={() => this.heartFavDisLike(data.item?.attributes.favouriteable_id, data.item)} >
                            <AntDesign name='heart' size={14} color="blue" />
                          </TouchableOpacity>

                        </View>

                        <View style={styles.offerView1}>
                          <View style={[styles.offerShadow1]}>
                            <Text style={styles.offerText1}>Flat 15% OFF on Total Bill</Text>
                          </View>
                          <View style={styles.kilometerView1}>
                            <Text style={styles.kilometerText1}>{Math.round(data.attributes.distance)} Kms</Text>
                          </View>
                        </View>
                        <View style={styles.resNameView}>
                          {data.attributes.restaurant_location.restaurant_name != null ? <Text style={styles.resNameText}>
                            {data.attributes.restaurant_location
                              ?.restaurant_name.length > 25
                              ? `${data.attributes.restaurant_location.restaurant_name?.slice(
                                0,
                                25
                              )}...`
                              : data.attributes.restaurant_location
                                .restaurant_name}
                          </Text> : <Text style={styles.resNameText} >N/A</Text>}
                          <Text>{data.attributes.restaurant_location.address?.slice(0, 12)}</Text>
                        </View>
                        <View style={styles.rowView}>
                          <TouchableOpacity style={styles.rowTouch}>
                            <Text style={styles.timeData}>
                              Timings :{" "}
                              {
                                data.attributes.restaurant_location
                                  .oprational_hour
                              }
                            </Text>
                          </TouchableOpacity>
                          <Text style={styles.kdValue}>{`KD ${data.attributes.restaurant_location
                            .average_price
                            } for two`}</Text>
                        </View>
                        <View style={styles.bottomView} />

                        <View style={styles.imageStatic}>
                          <Image
                            style={styles.imageS2}
                            resizeMode="cover"
                            source={image}
                          ></Image>
                          <Text style={styles.safety1}>
                            Follows all Max Safety measures to ensure your food is
                            safe
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  )
                }else{
                  return (
                    <View style={styles.menuItemListWrapper}>
            
                    <TouchableOpacity testID='onSelectPress' style={styles.menuListContentWrapper} onPress={()=>this.navigateToNext(data.attributes.restaurant_location.item_name)}>
                      {data.attributes.restaurant_location === null || data.attributes.restaurant_location === undefined ? <View style={[styles.menuImgSytle, { backgroundColor: "#ECECEC" }]} />
                        :
                        <Image style={styles.menuImgSytle} source={{ uri: data.attributes.restaurant_location.dish_image.name }} />
                      }
                      <View style={styles.MenuDetailsWrapper}>
                        <View style={{ flexDirection: "row" }}>
                        {data.attributes.restaurant_location.item_name != null ? <Text style={styles.menuTitle}>
                            {data.attributes.restaurant_location.item_name.length > 25
                              ? `${data.attributes.restaurant_location.item_name?.slice(
                                0,
                                25
                              )}...`
                              : data.attributes.restaurant_location.item_name}
                          </Text> : <Text style={styles.resNameText} >N/A</Text>}
                        </View>
                        <Text numberOfLines={1} style={styles.greyTextStyle}>{data.attributes.restaurant_location.category_type}</Text>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity testID="favDisLike" onPress={() => this.heartFavDisLike(data.id,data)}
                     style={styles.LikeIconWrapper}>
                    <AntDesign name='heart' size={24} color="#326cf7" />
                    </TouchableOpacity>
                  </View>
                  )
                }
              }}
              
              contentContainerStyle={{ paddingBottom: "6%" }}

            />
         
        

        </View>
        
        {/* Customizable Area End */}
      </ScrollView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffffff",
  },
  mainContent: {
    marginHorizontal: 20,
    margin: 5, 
    borderRadius: 11, 
    marginTop: 19, 
    shadowColor: 'grey', 
    shadowRadius: 15, 
    shadowOffset: { height: 10, width: 5 }, 
    shadowOpacity: 1, 
    backgroundColor: 'white', 
    marginBottom:  Scale(30) , 
    elevation: Platform.OS == 'android' ? 5 : 0
  },
  imageCover: {
    width: "100%", 
    height: 150, 
    borderTopLeftRadius: 11, 
    borderTopRightRadius: 11
  },
  resNameView: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    padding: 10 
  },
  likeImageBtn1: {
    width: 20,
    height: 20,
    position: "absolute",
    backgroundColor: "#F9FAFB",
    borderRadius: 10,
    padding: 3,
    top: 10,
    right: 10,
    zIndex: 10,
  },
  offerShadow1: {
    backgroundColor: '#00000090',
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    padding: 3,
  },
  offerView1: {
    flexDirection: 'row',
    position: 'absolute',
    top: 105,
    width: "100%"
  },
  offerText1: {
    fontSize: 10,
    paddingLeft: 6,
    color: 'white',
    fontWeight: 'bold'
  },
  kilometerView1: {
    backgroundColor: '#00000090',
    borderRadius: 6,
    position: 'absolute',
    padding: 3,
    alignSelf: 'flex-end',
    right: 10
  },
  kilometerText1: {
    fontSize: 10,
    paddingLeft: 6,
    color: 'white',
    fontWeight: 'bold'
  },
  resNameText: {
    fontWeight: 'bold', 
    width: "60%", 
    fontSize: Scale(16)
  },
  rowView: {
    flexDirection: 'row', 
    justifyContent: 'space-between',
     padding: 10,
  },
  rowTouch: {
    paddingHorizontal: 5, 
    padding: 3, 
    backgroundColor: "#326CF7", 
    borderRadius: 15, 
    borderWidth: 1, 
    borderColor: '#8A9094', 
    flexDirection: 'row',
  },
  timeData: {
    fontSize: 11, 
    color: 'white', 
    fontWeight: "400", 
    backgroundColor: '#336CF7' 
  },
  kdValue: {
    fontWeight: 'bold' 
  },
  bottomView: {
    borderWidth: 0.5, 
    borderColor: '#ECECEC'
  },
  imageStatic: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center'
  },
  imageS2: {
    width: 35,
    height: 25,
    borderRadius: 5
  },
  safety1: {
    paddingLeft: 9,
    width: '85%',
    color: 'gray',
    flexWrap: 'wrap',
    fontSize: Scale(10.6)
  },
  textStyle: {
    fontWeight: '600',
    fontSize: Scale(35),
    marginTop: Scale(30)

  },
  circleFav: {
    width: 55,
    height: 55,
    borderRadius: 55 / 2,
    borderColor: '#F3F4F6',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 3,
    marginTop: 30
  },
  menuItemListWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: Scale(20),
    marginHorizontal:20
 },
 menuListContentWrapper: {
  flexDirection: "row",
  alignItems: "center",
},
menuImgSytle: {
  height: Scale(60),
  width: Scale(60),
  borderRadius: 10
},
greyTextStyle: {
  marginTop: Scale(5),
  fontSize: Scale(17),
  color: 'grey',
  fontWeight: '600'
},
LikeIconWrapper: {
 backgroundColor:"white"
},
MenuDetailsWrapper: { marginLeft: Scale(15), width: Scale(245) },
menuTitle:{ fontSize: Scale(20), color: "black", fontWeight: "600" }
});
// Customizable Area End
