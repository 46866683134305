import React, { CSSProperties } from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import ContectFuduChatController, { Props } from "./ContectFuduChatController";
import { men, send, attachtransparent } from "./assets";
interface StyleType {
  root: any;
  needBlock: CSSProperties;
  needheading: CSSProperties;
  wrapperSubscreption: CSSProperties;
  chatheader: CSSProperties;
  open: CSSProperties;
  closed: CSSProperties;
  chatbody: CSSProperties;
  profiledetails: CSSProperties;
  profileimage: CSSProperties;
  profilename: CSSProperties;
  chattextbox: CSSProperties;
  chattext: CSSProperties;
  chattime: CSSProperties;
  rightsidetextbox: CSSProperties;
  rightsidetext: CSSProperties;
  inputarea: CSSProperties;
  input: CSSProperties;
  imagebox: CSSProperties;
  attachtransparent: CSSProperties;
  send: CSSProperties;
  heading: CSSProperties;
  btnbox: CSSProperties;
  text: CSSProperties;
  btntransparent: CSSProperties;
  btntheme: CSSProperties;
}
const WebStyles: StyleType = {
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px"
  },

  needBlock: {
    padding: "10px 0 30px 20px"
  },

  needheading: {
    color: "#111111",
    fontSize: "24px",
    margin: "0",
    fontWeight: 600,
  },
  open: {
    color: "#FF0A0A",
    padding: "7px 16px",
    backgroundColor: "#FFCECE",
    borderRadius: "18px",
    fontSize: "12px",
    border: "1px solid",
  },
  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
    margin: "25px 0 0",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    height: 'auto',
    minHeight:'100vh'
  },

  chatheader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #EBEBEB",
    padding: "0 30px",
  },

  closed: {
    backgroundColor: "#E2FFEC",
    color: "#0FBB4C",
    padding: "7px 25px",
    borderRadius: "18px",
    fontSize: "12px",
    border: "1px solid"
  },


  profiledetails: {
    alignItems: "center",
    display: "flex",
    marginBottom: "12px"
  },

  chatbody: {
    padding: "20px 30px"
  },
  chattextbox: {
    background: "#F8F8F8",
    maxWidth: "450px",
    padding: "10px 15px",
    marginBottom: "10px",
    borderRadius: "0 10px 10px 10px",
    backgroundColor: '#f7f4f2',
  },
  profileimage: {
    height: "24px",
    borderRadius: "100%",
    width: "24px",
    marginRight: "10px"
  },

  profilename: {
    fontSize: "15px",
    fontWeight: 500,
    color: "#111827",
  },

  chattext: {
    color: "#111827",
    fontSize: "14px",
    margin: "0 0 5px 0",
    maxWidth: "450px",
    textAlign: "justify",
    wordWrap: "break-word"
  },

  rightsidetext: {
    background: "#F1F8FF",
    marginBottom: "10px",
    padding: "10px 15px",
    borderRadius: "10px 0 10px 10px",
    maxWidth: "450px",
    backgroundColor: "#f0f0ff"
  },
  rightsidetextbox: {
    justifyContent: "end",
    display: "flex",
  },

  input: {
    padding: "6px 10px",
    marginTop: "10px",
    width: "100%",
    outline: "none",
    borderRadius: "5px",
    border: "1px solid #e5e7eb",
    background: "#f9fafb",
    height: "50px"
  },

  chattime: {
    color: "#9CA3AF",
    justifyContent: "end",
    fontSize: "12px",
    display: "flex",
  },

  inputarea: {
    padding: "20px 30px",
    position: "relative"
  },

  attachtransparent: {
    paddingRight: "10px",
    height: "20px",
    width: "20px",
    marginBottom: "5px",
  },
  imagebox: {
    top: "41px",
    position: "absolute",
    right: "45px"
  },
  send: {
    height: "30px",
    width: "30px"
  },

  btnbox: {
    backgroundColor: "#DBE7F9 !important",
    display: "flex",
    padding: "20px 30px",
    marginLeft: '2%',
    width: '92%',
    borderRadius: '10px',

  },
  btntheme: {
    border: "1px solid #096fff",
    background: "#096fff",
    minHeight: "40px",
    color: "#fff",
    borderRadius: "8px",
    fontSize: "15px",
    minWidth: "150px",
    marginRight: "15px"
  },
  heading: {
    fontSize: "20px"
  },
  text: {
    fontSize: "16px",
    marginRight: "15px"
  },

  btntransparent: {
    background: "#fff",
    color: "#096fff",
    border: "1px solid #096fff",
    minWidth: "150px",
    borderRadius: "8px",
    minHeight: "40px",
    fontSize: "15px"
  },


};
// Customizable Area End

class ContectFuduChat extends ContectFuduChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {

    return (
      // Customizable Area Start
      <Grid item sm={12} style={{ height: 'auto' }}>
        <div style={WebStyles.needBlock}>
          <h2 style={WebStyles.needheading}>Contact Fudu</h2>
          <Grid style={WebStyles.wrapperSubscreption}>
            <section style={{ paddingBottom: '26px' }}>
              <div style={WebStyles.chatheader}>
                <h1 style={WebStyles.heading}>Query Response</h1>
                <div>
                  {this.state.chatStatus === false ? <span style={WebStyles.closed}>Closed</span> : ""}
                  {/* <span style={WebStyles.open}>Open</span> */}
                </div>
              </div>
              <div style={WebStyles.chatbody}>
                {/* {[0, 1, 2].map((elem, i) => {
                    return (
                      <section key={i}> */}
                {/* Left Side Part  */}
                <div>
                  <div style={WebStyles.profiledetails}>
                    <img
                      src={men}
                      alt=""
                      style={WebStyles.profileimage}
                    />
                    <span style={WebStyles.profilename}>
                      Victor Roberts
                    </span>
                  </div>
                  <div style={WebStyles.chattextbox}>
                    <p style={WebStyles.chattext}>
                      {this.state.contactDatabyId}
                    </p>
                    <span style={WebStyles.chattime}>09 : 52 am</span>
                  </div>
                </div>
                {/* Right Side Part Text of other user  */}
                {/* <div style={WebStyles.rightsidetextbox}>
                          <div style={WebStyles.rightsidetext}>
                            <p style={WebStyles.chattext}>
                              Hi Justin! We just wanted to welcome you to our
                              team.
                            </p>
                            <span style={WebStyles.chattime}>09 : 52 am</span>
                          </div>
                        </div>
                      </section>
                    );
                  })} */}
              </div>

              {this.state.chatStatus === false ? <div style={WebStyles.btnbox}>
                <button style={WebStyles.btntheme} data-test-id='button_replay' onClick={() => this.setState({ chatStatus: true })}>Replay</button>
              </div> :
                <div style={WebStyles.inputarea}>
                  <input
                    type="text"
                    placeholder="Type a message..."
                    style={WebStyles.input}
                  />
                  <div style={WebStyles.imagebox}>
                    <img
                      src={attachtransparent}
                      alt=""
                      style={WebStyles.attachtransparent}
                    />
                    <img src={send} alt="" style={WebStyles.send} />
                  </div>
                </div>}
            </section>
          </Grid>
        </div>
      </Grid>
      // Customizable Area End
    );
  }
}

export default ContectFuduChat;
// Customizable Area End
