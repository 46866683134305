import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class SignUpControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCompleteProfileCallId: string = "";
    apiCompleteProfileCallId2: string = "";
    apiAccountDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {

        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        window.scrollTo(0, 0)
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForCompleteProfile(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
    handleGoBack = () => {
        this.props.navigation.navigate('CompleteAddress')
    }

    preventETypeNumber = (e: any) => {
        const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Shift"];
        return (allowedKeys.includes(e.key) ? false : !/^[^A-Za-z\W_]+$/.test(e.key)) && e.preventDefault();
    }


    getWorkingTimeString = (data: any) => {
        let start_hours = data.start_hours.toString().length > 1 ? data.start_hours : `0${data.start_hours}`
        let start_mins = data.start_mins.toString().length > 1 ? data.start_mins : `0${data.start_mins}`
        let end_hours = data.end_hours.toString().length > 1 ? data.end_hours : `0${data.end_hours}`
        let end_mins = data.end_mins.toString().length > 1 ? data.end_mins : `0${data.end_mins}`
        let starting_time = `${start_hours}:${start_mins} ${data.start_time_type}`
        let closing_time = `${end_hours}:${end_mins} ${data.end_time_type}`
        return { starting_time, closing_time }
    }

    //API Function
    completeProfile = (dataObj: any) => {
        this.completeProfile2(dataObj)
        const workingTime = this.getWorkingTimeString(dataObj)
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem("SignUpToken")
        };
        const data = {
            "parking_availability": JSON.parse(dataObj.parking_availability.toLowerCase()),
            "valet_parking_availability": JSON.parse(dataObj.valet_parking.toLowerCase()),
            "kids_area": JSON.parse(dataObj.kids_area.toLowerCase()),
            "serves_alcohol": JSON.parse(dataObj.serves_alcohol.toLowerCase()),
            "sheesha": JSON.parse(dataObj.Shisha.toLowerCase()),
            "smoking_area": JSON.parse(dataObj.smoking_area.toLowerCase()),
            "dining": Number(dataObj.dining),
            "starting_time": workingTime.starting_time,
            "closing_time": workingTime.closing_time,
            "speciality": dataObj.speciality,
            "category": Number(dataObj.category),
            "average_price": dataObj.avg_price_two,
            "restaurant_location_id": localStorage.getItem('accountID'),
            "type_of_place": Number(dataObj.place_type),
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCompleteProfileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_block/features'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    completeProfile2 = (dataObj: any) => {
        const Params = localStorage.getItem('accountID')
        const workingTime = this.getWorkingTimeString(dataObj)
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem("SignUpToken")
        };

        const data = {
            "average_price": dataObj.avg_price_two,
            "currency_type": dataObj.currency_type,
            "oprational_hour": `${workingTime.starting_time.trim()} - ${workingTime.closing_time.trim()}`
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCompleteProfileCallId2 = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_location/restaurant_locations/${Params}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    settingUpAccountDetails = (value: any) => {
        const headers = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
        };

        const attr = {
            "name": value.full_name,
        };

        const data = {
            "type": "email_account",
            attributes: attr
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiAccountDetailsCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_detail'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    //API Response Function
    handleResForCompleteProfile = async (from: string, message: Message) => {
        if (this.apiCompleteProfileCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && responseJson.data.attributes) {
                this.props.navigation.navigate('ResturantImgUpload')
                ToastMessage("success", "All Details Added Successfully")
            } else {
                ToastMessage("error", "Something Went Wrong")
            }

        }

    }

    // Customizable Area End

}