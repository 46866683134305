import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  passwordEyeToggleForPassword: boolean;
  passwordInputTypeForPassword: boolean;
  loader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EmailLoginControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallIdWeb: string = "";
  susbcriptionPurchasedId: string = "";
  apiEmailLoginIs: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      email: "",
      passwordEyeToggleForPassword: false,
      passwordInputTypeForPassword: true,
      loader: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForLogin(from, message);
    this.loginResForUSer(from, message);
    // Customizable Area End
  }

  // Customizable Area Start

  //Web logic Function
  handleEmailSubmit = (value: any) => {
    localStorage.setItem("userEmail", value);
    localStorage.setItem("SignUpEmail", value);
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginIs = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.create_login}?email=${value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePasswordSubmit = (value: any) => {
    let email = localStorage.getItem("userEmail");
    this.doEmailLogInWeb(email, value);
  };

  goBackToEmailPage = () => {
    localStorage.removeItem("userEmail")
    this.props.navigation.navigate("EmailAccountLogin");

  };


  goBackToEmailPageEdit = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  };

  handlePasswordToggle = () => {
    this.setState(
      {
        passwordEyeToggleForPassword: !this.state.passwordEyeToggleForPassword
      },
      () => {
        this.setState({
          passwordInputTypeForPassword:
            this.state.passwordEyeToggleForPassword === true ? false : true
        });
      }
    );
  };

  //API Function
  doEmailLogInWeb = (email: any, password: any) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };
    const attrs = {
      login_type: "website",
      email_or_mobile: email,
      password: password
    };
    const data = {
      type: "email_account",
      attributes: attrs
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailLoginCallIdWeb = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginWebAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // subscriptionPurchasedData = () => {
  //     this.setState({ loader: true})
  //     const headers = {
  //         "Content-Type": configJSON.validationApiContentType,
  //         token: localStorage.getItem("authToken"),
  //     };

  //     const getValidationsMsg = new Message(
  //         getName(MessageEnum.RestAPIRequestMessage)
  //     );
  //     this.susbcriptionPurchasedId = getValidationsMsg.messageId;
  //     getValidationsMsg.addData(
  //         getName(MessageEnum.RestAPIResponceEndPointMessage),
  //         configJSON.SUBSCRIPTIONApi
  //     );

  //     getValidationsMsg.addData(
  //         getName(MessageEnum.RestAPIRequestHeaderMessage),
  //         JSON.stringify(headers)
  //     );
  //     getValidationsMsg.addData(
  //         getName(MessageEnum.RestAPIRequestMethodMessage),
  //         configJSON.validationApiMethodType
  //     );

  //     runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  //   };

  userNavigatedScreen=(value: any)=>{
    if (value.is_account_details_present) {
      this.props.navigation.navigate("LoginPassword");
    } else {
      localStorage.setItem("SignUpToken",value.data.meta.token)
      this.props.navigation.navigate('SignUpOTPVerify')
      ToastMessage(
        "error",
        value.data.meta.message || "Something went Wrong"
      );
    }
  }
  loginResponceCheck = (data: any) =>{
    if (data.message) {
      ToastMessage(
        "error",
        data.message || "Something went Wrong"
      );
    }else{
      this.userNavigatedScreen(data)
    }
  }
  //API Response Function
  loginResForUSer = async (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiEmailLoginIs) {
      if (responseJson) {
        this.loginResponceCheck(responseJson)
      }
      else {
        ToastMessage("error", "Something went Wrong")
      }
    }
  }
  incompleteSignupStep = (step: string) => {
    switch (step) {
      case "step1": 
      this.props.navigation.navigate('LocationSignUp')
        break;
      case "step2":
        this.props.navigation.navigate('CompleteAddress')
        break;
      case "step3":
        this.props.navigation.navigate('CompleteSignUp')
        break;
      default:
        this.props.navigation.navigate('ResturantImgUpload')
    }
  }
  signUpFlowClick = (responseJson: any, value: any)=>{
     if (value === null) {
          localStorage.clear();
          localStorage.setItem("authToken", responseJson.meta.token);
          localStorage.setItem(
            "locationID",
            responseJson.data.attributes.restaurant_locations[0].id
          );
          localStorage.setItem(
            "user_name",
            responseJson.data.attributes.user_name
          );
          ToastMessage("success", responseJson.meta.message);
          window.location.href = "/dashboard";
          // this.subscriptionPurchasedData();
        }
        else {
          localStorage.setItem("SignUpToken", responseJson.meta.token);
          localStorage.setItem(
            "accountID",
            responseJson.data.attributes.restaurant_locations[0].id
          );
          localStorage.setItem(
            "user_name",
            responseJson.data.attributes.user_name
          );
          this.incompleteSignupStep(value)
        }
  }

  handleResForLogin = async (from: string, message: Message) => {
    if (
      this.apiEmailLoginCallIdWeb ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.signUpFlowClick(responseJson,responseJson.data.attributes.incomplete_signup_step)
      } else {
        //Check Error Response
        ToastMessage(
          "error",
          responseJson.errors[0].message || "Something went Wrong"
        );
      }
    }
    // if (this.susbcriptionPurchasedId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    //     const responseJson = message.getData(
    //         getName(MessageEnum.RestAPIResponceSuccessMessage)
    //     );
    //     if (responseJson?.data) {
    //         ToastMessage("success", "You are successfully logged in")
    //         localStorage.setItem("plan_type", responseJson?.data?.attributes?.plan?.attributes?.plan_type);
    //         window.location.href = '/dashboard';
    //     } else {
    //         ToastMessage("error", responseJson?.message)
    //         this.props.navigation.navigate("EmailAccountLogin");
    //         localStorage.clear();
    //     }
    // }
  };

  // Customizable Area End
}
