import React from "react";

// Customizable Area Start
import {
    Grid,
    Paper, Typography, IconButton, Card, CardContent, CardMedia, Button, Box,
    Menu, MenuItem,
    TextField,
    Switch
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import { ToastContainer } from 'react-toastify';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CancelIcon from '@material-ui/icons/Cancel';
import { vegIcon,nonVegIcon } from "./assets";
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: { main: '#096FFF' },
        secondary: { main: '#9CA3AF' },
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h2: {
            fontSize: "24px",
            fontWeight: 400
        },
        body1: {
            fontSize: "14px",
            fontWeight: 500
        },
        body2: {
            fontSize: "12px",
            fontWeight: 400
        },
        caption: {
            fontSize: "10px",
            fontWeight: 400
        }
    },
});

export const SwitchStyle = withStyles((theme) => ({
    root: {
      width: 42, height: 20, padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(22px)',
        color: 'white',
        '& + $track': {
          backgroundColor: '#03AC26', opacity: 1, border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: 'grey',border: '6px solid #fff',
      },
    },
    thumb: {
      width: 18, height: 18,
    },
    track: {
      borderRadius: 30 / 2, border: `1px solid ${theme.palette.grey[400]}`,
      opacity: 1,
      backgroundColor: theme.palette.grey[400],

      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},

    focusVisible: {},
  }))(({ classes, ...props }: any) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        defaultChecked
        disableRipple
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          switchBase: classes.switchBase,
          checked: classes.checked,
          track: classes.track, }}

        {...props}
      />
    );
  });

export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            minWidth: "110px",
            height: "36px",
            borderRadius: "8px",
            textTransform: "none",
            '&:hover': {
                opacity: "0.7",
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import MenuListingController, {
    Props
} from "./MenuListingController";
import ProfileMenuUpload from "./ProfileMenuUpload.web";
import ShowCreatedMenu from "./ShowCreatedMenu.web";
import ShowDescription from "./ShowDescription.web";

export default class MenuListing extends MenuListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    

    GenCard = (ele: any, id: any) => {
        return (
            <Card style={webStyle.cardRage} key={id}>
                <CardMedia
                    style={{height: "180px", position: "relative" }}
                    image={ele.attributes?.image?.url}
                >
                    <IconButton style={{
                            width: "24px",
                            height: "24px",
                            margin: "6px",
                            borderRadius: "50%",
                            backgroundColor: "rgba(0, 0, 0, 0.24)",
                            position: "absolute",
                            right: 5,
                            top: 5
                    }}
                    data-test-id={`anchor-${id}`}
                        onClick={(e) => {
                            this.handleClickopenAnchor(e,ele.id)
                            // this.handleOpenDeletePopup(ele.id)
                        }}>
                        <MoreHorizIcon style={{ color: "white" }} />
                    </IconButton>

                    <Menu
                        anchorEl={this.state.anchorElMenuMoreBtn}
                        disableScrollLock={false}
                        open={Boolean(this.state.anchorElMenuMoreBtn)}
                        onClose={this.handleCloseMenuDelete}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <MenuItem onClick={() => this.handleDeleteMenu()}
                        data-test-id={`deletemo-${id}`}
                        >
                            Delete Menu
                        </MenuItem>
                    </Menu>
                </CardMedia>
                <CardContent style={webStyle.cardContentContainer}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body1" component="h2">
                                {ele.attributes.item_name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                                {/* ${ele.attributes.price} */}
                                KD{this.findLeastPrice(ele.id)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" style={webStyle.secondContainer}>
                        <Grid item style={webStyle.menuData}>
                        {ele.attributes.item_type==="non_veg"? 
                            <img src={nonVegIcon} alt="food_type" height={12} width={12} /> :
                            <img src={vegIcon} alt="food_type" height={12} width={12} />
                         }
                            <Typography variant="caption">
                                {ele.attributes.item_type==="non_veg"? "Non-veg": "Veg"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton style={{ padding: "0" }}
                            data-test-id={`editmo-${id}`}
                                onClick={() => {
                                    // this.setState({ openEditMenuModal: true })
                                    this.handleEditMenuModal(ele.id)
                                }}
                            >
                                <CreateIcon style={{ fontSize: "16px" }} color="primary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Typography gutterBottom variant='body2' style={{
                            color: "#4B5563",
                            marginTop: "14px",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2, // Limit to 2 lines
                            wordBreak: "break-all",
                            marginBottom: "10px",
                            textOverflow: "ellipsis", // Add ellipsis at the end
                    }}   
                    id="mouseenter"                            
                    >
                        <span
                           data-test-id = "mouseEnter"
                           onMouseEnter={() => this.handleMouseEnter(ele.attributes?.description)}
                           onMouseLeave={() => this.handleMouseLeave()}
                         style={{cursor: "pointer"}}  
                        >
                          {ele.attributes.description}
                        </span>

                    </Typography>

                </CardContent>
            </Card>
        )
    }

    // Customizable Area End

    render() {          
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Typography variant="h2" style={{fontWeight:600,marginTop:'5px'}}>My Menu</Typography>
                <div style={webStyle.mainWrapper}>
                    {/* @ts-ignore */}
                    <Paper elevation={3} style={webStyle.childrenSection}>
                        <Grid container spacing={2} alignItems="center" justifyContent="space-between" className="Grid-container">
                            <Grid  xs={12} lg={6} md={6}>
                                <TextField
                                    style={{width:'97%',minWidth:'120px'}}
                                    className="search-field"
                                    data-test-id="text"
                                    variant="outlined"
                                    size="small"
                                    placeholder="search menu by name..."
                                    type="text"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={this.state.searchMenu}
                                    onChange={(e: any) => {
                                        this.HandleSearch(e)
                                    }}
                                    onKeyPress={this.searchMenuCall}
                                />
                            </Grid>


                            <Grid xs={12} lg={6} md={6}>
                                <Grid 
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"

                                >
                                 <Grid item xs={1}>   
                                    <IconButton style={{ padding: "0" }}
                                      data-test-id='openmenu'  onClick={() => this.setState({ openMenuFilter: true })}
                                    >
                                        <FilterListOutlinedIcon />
                                    </IconButton>
                                </Grid> 
                                <Grid item className="manage-btn">
                                    <Button variant="contained" 
                                       data-test-id = "openmanage"
                                        style={{
                                            minWidth: "110px",
                                            height: "36px",
                                            background: "transparent",
                                            borderRadius: "10px",
                                            boxShadow: "none",
                                            border: "1px solid #096FFF",
                                            color: "#096FFF",
                                            textTransform: "none",
                                            marginRight: "15px"
                                          }}
                                          onClick={()=>{this.getManageMenuTimings();this.setState({openManageModel: true})}}
                                    >
                                        Manage meal timings
                                    </Button>
                                    <StyledButton variant="contained" color="primary" className="styled-btn"
                                       data-test-id='profilemenu' onClick={() => this.setState({ openProfileMenuUploadModal: true })}
                                    >
                                        Add Menu
                                    </StyledButton>
                                </Grid>      
                                </Grid>
                            </Grid>

                            <Grid container data-test-id='ppppdivv'>
                               {this.state.openMenuFilter && <div className="menulisting-filter">
                                        <Box
                                            boxShadow={3}
                                            bgcolor="background.paper"
                                            m={1}
                                            p={1}
                                            data-test-id='ppppdivv1'
                                            style={{ width: '10rem', height: '24rem' }}
                                        >
                                            <div data-test-id='ppppdivv11' style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                <Typography style={{ fontSize: '19px', fontWeight: 600 }}>FILTER</Typography>
                                                <Typography style={{ fontSize: '19px', fontWeight: 600, marginLeft: '-5px', cursor: "pointer" }}
                                                 data-test-id="openmenufilter"   onClick={() => { this.setState({ openMenuFilter: false }) }}
                                                >
                                                    X
                                                </Typography>

                                            </div>
                                            <FormControl component="fieldset">
                                                <FormGroup>
                                                    <FormControlLabel
                                                    data-test-id="AllForm" 
                                                        control={<Checkbox checked={this.state.All}
                                                            value=""
                                                            data-test-id="All" 
                                                             onChange={(e: any) => {
                                                                this.setState({
                                                                    All: true, breakfast: false, lunch: false, dinner: false, allDay: false,
                                                                    veg: false, nonVeg: false,
                                                                    allCategoryValue: e.target.value
                                                                })
                                                            }}
                                                            name="all" />}
                                                        label="All"
                                                    />
                                                    
                                                    <FormControlLabel
                                                    data-test-id="breakfastAll"
                                                        control={<Checkbox checked={this.state.breakfast}
                                                            value="breakfast"
                                                            data-test-id="breakfast" 
                                                            onChange={(e: any) => {
                                                               this.setState({
                                                                    breakfast: true, All: false, lunch: false, dinner: false, allDay: false,
                                                                    allCategoryValue: e.target.value
                                                                })
                                                            }}
                                                            name="breakfast" />}
                                                        label="Breakfast"
                                                    />
                                                    <FormControlLabel
                                                    data-test-id="lunchAll"
                                                        control={<Checkbox checked={this.state.lunch}
                                                            value="lunch"
                                                            data-test-id='lunchA'
                                                            onChange={(e: any) => {
                                                                this.setState({
                                                                    lunch: true, All: false, breakfast: false, dinner: false, allDay: false,
                                                                    allCategoryValue: e.target.value
                                                                })
                                                            }}
                                                            name="lunch" />}
                                                        label="Lunch"
                                                    />
                                                    <FormControlLabel
                                                    data-test-id="dinnerAll"

                                                        control={<Checkbox checked={this.state.dinner}
                                                            value="dinner"
                                                    data-test-id="dinnerA"

                                                            onChange={(e: any) => {
                                                                this.setState({
                                                                    dinner: true, All: false, breakfast: false, lunch: false, allDay: false,
                                                                    allCategoryValue: e.target.value
                                                                })
                                                            }}
                                                            name="dinner" />}
                                                        label="Dinner"
                                                    />
                                                    <FormControlLabel
                                                    data-test-id="AllDay"
                                                        control={<Checkbox checked={this.state.allDay}
                                                            value="all_day"
                                                    data-test-id="AllDay_a"
                                                            
                                                            onChange={(e: any) => {
                                                                this.setState({
                                                                    allDay: true, All: false, breakfast: false, lunch: false, dinner: false,
                                                                    allCategoryValue: e.target.value
                                                                })
                                                            }}
                                                            name="all_day" />}
                                                        label="All Day"
                                                    />
                                                    <FormControlLabel
                                                      data-test-id = "vegi"
                                                        control={<Checkbox checked={this.state.veg}
                                                            value="veg"
                                                            data-test-id = "vegi_v"
                                                            onChange={(e: any) => {
                                                                this.setState({
                                                                    veg: true, nonVeg: false,
                                                                    allItemValue: e.target.value
                                                                })
                                                            }}
                                                            name="veg" />}
                                                        label="Veg"
                                                    />
                                                    <FormControlLabel
                                                       data-test-id = "non_vegi"
                                                        control={<Checkbox checked={this.state.nonVeg}
                                                            value="non_veg"
                                                            data-test-id = "non_vegin"
                                                            onChange={(e: any) => {
                                                                this.setState({
                                                                    nonVeg: true, veg: false,
                                                                    allItemValue: e.target.value
                                                                })
                                                            }}
                                                            name="non_veg" />}
                                                        label="Non-veg"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                            <Button variant='contained' color='primary' style={{ width: '157px', marginTop: '10px', marginLeft: "2px" }}
                                                onClick={this.filterMenuCall}
                                                data-test-id="apply"
                                            >
                                                apply filter</Button>
                                        </Box>
                                </div>}
                            </Grid>
                        </Grid>

                        <Grid container style={webStyle.menuContainer} spacing={4}>

                            {
                            this.state.loader ? <PulseLoader color="#096fff" className="loader" /> : (this.state.allMenuListingData && this.state.allMenuListingData?.length > 0 &&
                                this.state.allMenuListingData?.map((item: any, index: any) => {
                                    return (
                                        <>
                                        <Grid item xs={12} lg={3} key={index} style={{width:'100%'}}>
                                            {this.GenCard(item, index)}
                                        </Grid>
                                        </>
                                    )
                                }))
                            }
                            {<p style={{position:"relative",top:"250px",left:"50%",textTransform:"capitalize"}}>{this.state.menuErr}</p>}
                        </Grid>
                    </Paper>
                    <div>
                        {/* @ts-ignore */}
                        <ProfileMenuUpload navigation={undefined} show={this.state.openProfileMenuUploadModal} handleClose={this.handleCloseProfileMenuUploadModal} />
                    </div>
                </div>

                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={this.state.openDeleteModal}
                    onClose={this.handleModalClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" style={webStyle.diaHeader}>
                        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>Delete Menu Item</Typography>

                        <IconButton
                            data-test-id ="iconbtn"
                            aria-label="close"
                            style={{
                                position: "absolute",
                                right: "0px",
                                top: "0px",
                                color: "blue",
                            }}
                            onClick={() => this.setState({ openDeleteModal: false })}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography style={{ marginBottom: "20px" }}>
                            Are you sure you want to delete this menu item? <br />
                            Once deleted, all the data for this item will be permanently <br />
                            deleted.
                        </Typography>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "space-around", marginBottom: "30px" }}>
                        <Button
                            data-test-id="btnRemoveCase"
                            style={{
                                textTransform: "capitalize",
                                color: "#5C8BF6",
                                border: "1px solid #5C8BF6",
                                borderRadius: "20px",
                                width: "40%"
                            }}
                            onClick={this.deleteMenuApiCall}
                        >
                            Yes, delete
                        </Button>
                        <Button
                            // onClick={this.handleClose}
                            data-test-id="deleteModalbtn"
                            color="primary"
                            style={{
                                textTransform: "capitalize",
                                background: "#5C8BF6",
                                color: "#fff",
                                borderRadius: "20px",
                                width: "40%"
                            }}
                            onClick={() => this.setState({ openDeleteModal: false })}
                        >
                            No, keep it
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    maxWidth="md"
                    open={this.state.openManageModel}
                    onClose={this.handleManageModalClose}
                    aria-labelledby="max-width-dialog-title"
                >
           <ToastContainer
                position="top-center"
            autoClose={1500}
             hideProgressBar={true}
            newestOnTop={false}
              closeOnClick={true}
            style={{ zIndex: 9999 }}
            rtl={false}
          />
                    <DialogTitle id="max-width-dialog-title" style={{marginTop:"20px", marginBottom: "20px"}}>
                        <Typography style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>Manage Meal Timings</Typography>

                        <IconButton
                            aria-label="close"
                            style={{
                                position: "absolute",
                                right: "0px",
                                top: "0px",
                                color: "blue",
                            }}
                            onClick={this.handleManageModalClose}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogActions style={{ justifyContent: "space-around", marginBottom: "30px" }}>
                        <Grid container>
                            
                            <Grid item xs={1} />

                            <Grid item xs={8} style={{marginBottom: "20px"}}>
                                <div>
                                   
                                                <form onSubmit={this.handleSubmit}>
                                                    <Grid>
                                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                          <Grid item xs={2}>
                                                          <Typography style={webStyle.timeTitle}>
                                                                Breakfast
                                                            </Typography>
                                                          </Grid>
                                                          <Grid item xs={10} style={{display: "flex", alignItems: "center"}}>
                                                            <FormControlLabel
                                                                control={<SwitchStyle checked=
                                                                    {this.state.checkedBb}
                                                                    onChange={this.handleBrSwitchChange}
                                                                    name="checkedBb"
                                                                />
                                                                }
                                                                label=""
                                                            />
                                                            <Typography style={webStyle.timeTitle}>
                                                                Open
                                                            </Typography>
                                                           </Grid> 
                                                          </Grid>  
                                                           <Grid container> 
                                                           <Grid item xs={2}/>
                                                           <Grid item xs={10} style={{marginBottom:"30px", marginLeft:"-20px"}}>
                                                            <div style={webStyle.mainTimeBox} >
                                                                <div style={webStyle.manageTimeBox}>                                                   
                                                                    <input 
                                                                      value={this.state.Bstart_hours}
                                                                      onChange={this.handleChange} 
                                                                      name="Bstart_hours" type='text' placeholder='hh' data-test-id="srtHrsInp" 
                                                                       style={webStyle.manageTime} />
                                                                    <h3>:</h3>
                                                                    <input onChange={this.handleChange} 
                                                                      value={this.state.Bstart_mins}
                                                                      name="Bstart_mins" type='text'
                                                                       placeholder='mm'  style={webStyle.manageTime} />
                                                                    <select onChange={this.handleChange} 
                                                                      value={this.state.Bstart_time_type}
                                                                      name="Bstart_time_type" 
                                                                      style={webStyle.manageTime}>
                                                                        <option value="AM">AM</option>
                                                                        <option value="PM">PM</option>
                                                                    </select>
                                                                    </div>

                                                                <span>to</span>
                                                                
                                                                <div style={webStyle.manageTimeBox}>
                                                                    <input onChange={this.handleChange} value={this.state.Bend_hours}
                                                                      name="Bend_hours" type='text' placeholder='hh' 
                                                                       style={webStyle.manageTime} />
                                                                    <h3>:</h3>
                                                                    <input value={this.state.Bend_mins}
                                                                     onChange={this.handleChange} name="Bend_mins"
                                                                      type='text' placeholder='mm'  
                                                                      style={webStyle.manageTime} />
                                                                    <select 
                                                                     value={this.state.Bend_time_type}
                                                                     onChange={this.handleChange} 
                                                                     name="Bend_time_type" style={webStyle.manageTime}>
                                                                        <option value="AM">AM</option>
                                                                        <option value="PM">PM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                                   
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                          <Grid item xs={2}>
                                                          <Typography style={webStyle.timeTitle}>
                                                                Lunch
                                                            </Typography>
                                                          </Grid>
                                                          <Grid item xs={10} style={{display:"flex",alignItems:"center"}}>
                                                            <FormControlLabel
                                                                control={<SwitchStyle checked= {this.state.checkedBl}
                                                                    onChange={this.handleLuSwitchChange} name="checkedBl"
                                                                />
                                                                }
                                                                label=""
                                                            />
                                                            <Typography style={webStyle.timeTitle}>
                                                                Open
                                                            </Typography>
                                                           </Grid> 
                                                          </Grid>  
                                                           <Grid container> 
                                                           <Grid item xs={2}/>
                                                           <Grid item xs={10} style={{marginBottom:"30px", marginLeft:"-20px"}}>
                                                            <div style={webStyle.mainTimeBox} >
                                                                <div style={webStyle.manageTimeBox}>
                                                                    <input value={this.state.Lstart_hours}
                                                                     onChange={this.handleChange} name="Lstart_hours" type='text' placeholder='hh'  style={webStyle.manageTime} />
                                                                    <h3>:</h3>
                                                                    <input onChange={this.handleChange} 
                                                                    value={this.state.Lstart_mins}
                                                                    name="Lstart_mins" type='text' placeholder='mm'  style={webStyle.manageTime} />
                                                                    <select onChange={this.handleChange} 
                                                                       value={this.state.Lstart_time_type}
                                                                       name="Lstart_time_type" style={webStyle.manageTime}>
                                                                        <option value="PM">PM</option>
                                                                        
                                                                        <option value="AM">AM</option>
                                                                    </select>
                                                                </div>
                                                                <span>to</span>
                                                                <div style={webStyle.manageTimeBox}>
                                                                    <input onChange={this.handleChange} value={this.state.Lend_hours} name="Lend_hours" type='text' placeholder='hh'  style={webStyle.manageTime} />
                                                                    <h3>:</h3>
                                                                    <input value={this.state.Lend_mins} onChange={this.handleChange} name="Lend_mins" type='text' placeholder='mm'  style={webStyle.manageTime} />
                                                                    <select value={this.state.Lend_time_type}
                                                                    onChange={this.handleChange} name="Lend_time_type" style={webStyle.manageTime}>
                                                                        <option value="PM">PM</option>
                                                                        <option value="AM">AM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                          <Grid item xs={2}>
                                                          <Typography style={webStyle.timeTitle}>
                                                                Dinner
                                                            </Typography>
                                                          </Grid>
                                                          <Grid item xs={10} style={{display:"flex",alignItems:"center"}}>
                                                            <FormControlLabel
                                                                control={<SwitchStyle checked={this.state.checkedBd}
                                                                    onChange={this.handleDiSwitchChange}
                                                                    name="checkedBd"/>}
                                                                label=""
                                                            />
                                                            <Typography style={webStyle.timeTitle}>
                                                                Open
                                                            </Typography>
                                                           </Grid> 
                                                          </Grid>  
                                                           <Grid container> 
                                                           <Grid item xs={2}/>
                                                           <Grid item xs={10} style={{marginBottom:"30px", marginLeft:"-20px"}}>
                                                            <div style={webStyle.mainTimeBox} >
                                                                <div style={webStyle.manageTimeBox}>
                                                                    <input value={this.state.Dstart_hours}
                                                                      onChange={this.handleChange} name="Dstart_hours" type='text' placeholder='hh'  style={webStyle.manageTime} />
                                                                    <h3>:</h3>
                                                                    <input onChange={this.handleChange} value={this.state.Dstart_mins}
                                                                     name="Dstart_mins" type='text' placeholder='mm'  style={webStyle.manageTime} />
                                                                    <select value={this.state.Dstart_time_type}
                                                                      onChange={this.handleChange} name="Dstart_time_type" style={webStyle.manageTime}>
                                                                        <option value="PM">PM</option>
                                                                        <option value="AM">AM</option>
                                                                    </select>
                                                                </div>
                                                                <span>to</span>
                                                                <div style={webStyle.manageTimeBox}>
                                                                    <input onChange={this.handleChange} name="Dend_hours" value={this.state.Dend_hours}
                                                                    type='text' placeholder='hh'  
                                                                    style={webStyle.manageTime} />
                                                                    <h3>:</h3>
                                                                    <input onChange={this.handleChange} value={this.state.Dend_mins}
                                                                    name="Dend_mins" type='text' placeholder='mm'  style={webStyle.manageTime} />
                                                                    <select onChange={this.handleChange} value={this.state.Dend_time_type}
                                                                    name="Dend_time_type" style={webStyle.manageTime}>
                                                                        <option value="PM">PM</option>
                                                                        <option value="AM">AM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                       
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                <Grid container justifyContent="space-between"
                                                    style={{marginTop: "10px"}} xs={12} spacing={2}
                                                 >
                                                   <Grid item xs={2}/> 
                                                    <Grid item xs={5} lg={5}>
                                                     <Button
                                                       variant="contained"
                                                         style={{
                                                         height: "64px",
                                                         width: "90%",
                                                         background: "transparent",
                                                         color: "#096FFF",
                                                         borderRadius: "10px",
                                                         boxShadow: "none",
                                                         border: "1px solid #096FFF",
                                                         textTransform: "none",
                                                         }}
                                                        data-test-id="manageClose"
                                                        onClick={this.handleManageModalClose}
                                                        >
                                                       Cancel
                                                       </Button>
                                                      </Grid>
                                                <Grid item xs={5} lg={5}>
                                                   <Button
                                                     data-test-id="manageSubmitBtn"
                                                      style={{
                                                       width: "90%",
                                                       background: this.hasEmptyManageFields()? "#89CFF0" : "#096FFF",
                                                       borderRadius: "10px",
                                                       color: "#fff",
                                                       height: "64px",
                                                       textTransform: "none"
                                                       }}
                                                      variant="contained"
                                                       type="submit"
                                                    color="primary"
                                                      disabled = {this.hasEmptyManageFields()}  
                                                    >
                                                        Save
                                                   </Button>
                                                 </Grid>
                                                </Grid>
                                              </form>

                                </div>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>
                    </DialogActions>
                </Dialog>

                <ShowCreatedMenu navigation={undefined} show={this.state.openEditMenuModal} handleClose={this.handleEditMenuModalClose} editID={this.state.openEditMenuId} />

                <div>
                    <ShowDescription navigation={undefined} handleClose={this.handleMouseLeave} show={this.state.openDescriptionModal} decsID={this.state.descriptionId}/>
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start  
const webStyle = {
    mainWrapper: {
        marginTop: "30px",
        // minHeight:'100vh'
        // padding: "0px 24px",
    },
    childrenSection: {
        marginTop: "24px",
        padding: "24px",
        borderRadius: "8px",
        position: "relative",
        minHeight:'100vh',
        overflow:'auto'
    },
    menuContainer: {
        marginTop: "17px"
    },
    menuData: {
        display: "flex",
        gap: "8px",
        alignItems: "center"
    },
    cardRage: {
        border: "1px solid #ECF0FF",
        borderRadius: "8px",
        maxHeight: "300px",
        minHeight: "300px"
    },
    diaHeader: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid lightGrey",
        marginBottom: "20px"
    },
    moreHoriIcon: {
        width: "24px",
        height: "24px",
        margin: "6px",
        borderRadius: "50%",
        backgroundColor: "rgba(0, 0, 0, 0.24)",
        position: "absolute",
        right: 5,
        top: 5
    },
    secondContainer: {
        marginTop: "10px"
    },
    cardContentContainer: {
        padding: "12px",
    },
    descriptionRage: {
        color: "#4B5563",
        marginTop: "14px",
        "overflow": "hidden",
        "display": "-webkit-box",
        "WebkitBoxOrient": "vertical",
        "WebkitLineClamp": "2",
        wordBreak: "break-all"
    },
    mainTimeBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },

    manageTimeBox: {
        height: "32px",
        display: "flex",
        alignItems: "center",
        gap: '5px',
        border: "1px solid #D8D8D8",
        borderRadius: "5px"
    },

    manageTime: {
        width: "59px",
        height: "32px",
        padding: "5px",
        backgroundColor: "#f3f4f6",
        border: "none",
        borderRadius: "2px"
    },
    timeTitle: {
        color: "#44445D",
        fontSize: "14px",
    },
    error_msg: {
        color: "red",
        marginTop: "5px"
    }

};
// Customizable Area End
