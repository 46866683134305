import React from "react";
// Customizable Area Start
import { Grid, Select, MenuItem } from "@material-ui/core";
import SupportProfileController, { Props } from "./SupportProfileController";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { attach, close } from "./assets";
import PulseLoader from "react-spinners/PulseLoader";

const Styles = () => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "20px",
  },
  needBlock: {
    padding: "10px 0px 0px 0",
  },
  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "16px",
    marginTop: "25px",
    padding: "25px 30px",
    height: '93vh',
    marginRight:'20px'
  },
  heading: {
    fontSize: "24px",
    marginBottom: "30px",
  },
  needselect: {
    width: "100% !important",
    borderRadius: "8px",
    height: "38px",
    marginTop: "10px",
  },
  mb10: {
    marginBottom: "10px",
  },
  mb20: {
    marginBottom: "20px",
  },
  textarea: {
    padding: "6px 10px",
    outline: "none",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    width: "100%",
    minHeight: "112px",
    resize: "none",
    marginTop: "10px",
  },
  btntransparent: {
    background: "#fff",
    minWidth: "200px",
    color: "#096fff",
    borderRadius: "8px",
    fontSize: "15px",
    marginRight: "15px",
    minHeight: "64px",
    border: "1px solid #096fff",
    marginTop:'12px'
  },
  btntheme: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    opacity: '0.5',
    marginTop:'12px'
  },
  btnopacity: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    opacity: '1',
    marginTop:'12px'
  },
  imgupload: {
    display: "none",
  },
  imgdiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  ml15: {
    marginLeft: "15px",
   
  },
  btndiv: {
    marginTop: "20px",
  },
  error: {
    fontSize: "15px",
    color: "red",
    marginTop: "5px",
  },
  themeColor: {
    color: "#096fff",
  },
  labelfont: {
    fontSize: "15px",
  },
  attachFileName: {
    fontSize: "15px",
    marginLeft: "10px",
    textDecoration:'underline'
  },

});

const StyledSelect = withStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
    },
    select: {
      paddingRight: '12px',
    },
    outlined: {
      '&$focused $notchedOutline': {
        border: 'none',
      },
    },
    focused: {},
    notchedOutline: {},
    icon: {
      position: 'absolute',
      right: 8,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  })
)(props => (
  <Select
    {...props}
    variant="outlined"
    displayEmpty
    /* @ts-ignore */
    MenuProps={{
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
      getContentAnchorEl: null,
      disableScrollLock: true,
    }}
  >
    <MenuItem value="" selected disabled>Select a topic</MenuItem>
    <MenuItem value="food_order">Order</MenuItem>
    <MenuItem value="payment">Payment</MenuItem>
    <MenuItem value="subscription">Subscription</MenuItem>
    <MenuItem value="others">Others</MenuItem>
  </Select>
));


// Customizable Area End

class SupportProfile extends SupportProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { data, errors, attachFileName } = this.state;
    const { needHelp, queryDesc, attachFile } = this.state.data;
    const enable = needHelp.length > 0 &&
      queryDesc.length > 0 && attachFile.length > 0;
    /* @ts-ignore */
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <Grid item sm={12} style={{ height: '87%' }}>
          <h2 style={{marginTop:'5px'}}>Support</h2>
        {this.state.loader ? <PulseLoader color="#096fff" className="loader" /> :
        <div className={classes.needBlock}>
          <Grid className={classes.wrapperSubscreption}>
            <Grid item lg={6}>
              <h1 className={classes.heading}>How can we help you ?</h1>
              <div className={classes.mb20}>
                <label htmlFor="help" className={classes.labelfont}>
                  What do you need help with ?
                </label>
                <br />
                <StyledSelect
                  /* @ts-ignore */
                  name="needHelp"
                  id="help"
                  className={classes.needselect}
                  data-testid="needhelp"
                  value={data.needHelp}
                  disableUnderline
                  onChange={(event: any) => this.handleInputChange(event)}
                />
                {errors.needHelp && (
                  <p className={classes.error}>{errors.needHelp}</p>
                )}
              </div>
              <div>
                <label htmlFor="desc" className={classes.labelfont}>
                  Query Description
                  <sup className={classes.themeColor}>*</sup>
                </label>
                <textarea
                  id="desc"
                  className={classes.textarea}
                  name="queryDesc"
                  placeholder="Explain what you need help with..."
                  data-testid="desc"
                  value={data.queryDesc}
                  onChange={(event) => this.handleInputChange(event)}
                />
                {errors.queryDesc && (
                  <p className={classes.error}>{errors.queryDesc}</p>
                )}
              </div>
              <div className={classes.imgdiv}>
                <label htmlFor="img">
                  <img src={attach} alt="" />
                  <input
                    type="file"
                    id="img"
                    className={classes.imgupload}
                    name="attachFile"
                    accept="image/*, image/jpeg, image/jpg, image/svg" 
                    data-testid="attachfile"
                    value={data.attachFile}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </label>
                {/* @ts-ignore  */}
                <p className={classes.attachFileName}>{attachFileName.name}</p>
                {attachFileName && (
                  <img
                    src={close}
                    alt=""
                    className={classes.ml15}
                    onClick={() => this.deleteFile()}
                    data-testid="deletefile"
                  />
                )}
              </div>
              {errors.attachFile && (
                <p className={classes.error}>{errors.attachFile}</p>
              )}
              <div className={classes.btndiv}>
                <button
                  className={classes.btntransparent}
                  onClick={() => this.cancelTicket()}
                  data-testid="cancel"
                >
                  Cancel
                </button>
                <button
                  className={!enable ? classes.btntheme : classes.btnopacity}
                  onClick={() => this.handleSubmit()}
                  data-testid="submit"
                  disabled={!enable}
                >
                  Submit
                </button>
              </div>
            </Grid>
          </Grid>
        </div>}
      </Grid>
      // Customizable Area End
    );
  }
}

/* @ts-ignore */

SupportProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

/* @ts-ignore */
export default withStyles(Styles)(SupportProfile);
export { SupportProfile };
// Customizable Area End
