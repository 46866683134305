import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  // Customizable Area Start
  propopasModalOpen: boolean;
  activeTab: number;
  suggestedInfluencersData: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ProfileInfluencersControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),

      getName(MessageEnum.RestAPIResponceMessage),
      
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      propopasModalOpen: false,
      activeTab: 0,
      suggestedInfluencersData: []
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  handleTabChange = (_event: any, newValue: any) => {
    this.setState({ activeTab: newValue });
  };

  // Customizable Area End

}