export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const groupImage = require("../assets/groupImage.png");
export const viewImage = require("../assets/viewImage.png");
export const lion = require("../assets/lion.jpeg");
export const right = require("../assets/right.png");
export const heart = require("../assets/heart.png");
export const key = require("../assets/key.png");
export const pickkok = require("../assets/pickkok.jpeg");
export const back = require("../assets/back.png");
export const heartTwo = require("../assets/heartTwo.png");
export const plus = require("../assets/plus.png");






