import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  handleClose: () => void;
  show: boolean;
  viewID: any;
  accountId: any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  viewReasonData: any;
  counterModalOpen: boolean;
  description: any;
  imageFile: any;
  imageName: string | null;
  currency_type: any,
  posts: any,
  price: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewReasonController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  getViewReasonCallId: any;
  counterOfferProposalcallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      viewReasonData: '',
      counterModalOpen: false,
      description: "",
      imageFile: "",
      imageName: "",
      currency_type: "",
      posts: "",
      price: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

 
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.show !== this.props.show) {
        this.getViewReasonCall();
    }
}
 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.HandleViewReasonCall(from, message)
    this.handleCounterOfferProposalcall(from, message)

    // Customizable Area End

    // Customizable Area Start

}
  // Customizable Area End

  handleCounterOfferModalClose=()=>{
    this.setState({counterModalOpen: false, posts: "",price: "",description: "", imageFile: "",imageName: ""})
  }

  ReaderImg = async (event: any) => {
    this.setState({imageFile:event.target.files[0]})
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ imageName: file.name });
    }
  }

  handleChangeCurrencyType = (event:any)=> {
    this.setState({currency_type: event.target.value})
  }

  handleChangePosts = (event:any)=> {
    if (!isNaN(event.target.value)){
      this.setState({posts: event.target.value})
    }
  }

  handleChangePrice = (event:any)=> {
    if(!isNaN(event.target.value)){
      this.setState({price: event.target.value})
    }
  }

  handleChangeDesc =(event:any)=> {
    this.setState({description: event.target.value})
  }

  // web events
  getViewReasonCall = () => {
    this.setState({viewReasonData: ''})
    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getViewReasonCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/view_reason/${this.props.viewID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  counterOfferPropsal = () => {
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
      "token": localStorage.getItem("authToken")
    };

    let formdata = new FormData();
    formdata.append("data[proposal]", this.state.description);
    formdata.append("data[image]", this.state.imageFile);
    formdata.append("data[post_per_month]", this.state.posts);
    formdata.append("data[price]", this.state.price);
    formdata.append("[data][restaurant_location_id]",RestaurantId );
    formdata.append("[data][account_id]", this.props.accountId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.counterOfferProposalcallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/counter_offer`
    );
    console.log(this.props.accountId, "influe id.....");
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  HandleViewReasonCall = async (from: string, message: Message) => {
    if (this.getViewReasonCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
            this.setState({ viewReasonData: apiResponse.data?.attributes})
        }
    }
  }

  handleCounterOfferProposalcall = async (from: string, message: Message) => {
    if (this.counterOfferProposalcallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!apiResponse.errors) {
        ToastMessage("success", "Proposal sent successfully")
        this.setState({counterModalOpen: false,
          posts: "",price: "",description: "", imageFile: "",imageName: ""
        })
      }
    }
  }


  // Customizable Area End
}
