import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { NavLink } from "react-router-dom";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import { Box } from "@material-ui/core";
const Styles = (theme: any) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "16px 24px 24px 24px",
    [theme.breakpoints.down('sm')]: {
    }
  },
  sidenav: {
    backgroundColor: "#FFFFFF",
    padding: "50px 8px 30px",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: "16px",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down('sm')]: {
    }
  },
  sidetest: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '250px',
      transition: 'transform 0.3s ease-in-out',
      position: 'absolute',
      zIndex: 2,
      top: '50px',
      left: '0px'

    }
  },
  sidemenu: {
    listStyle: "none",
    padding: "0",
    margin: "10px 0 0 0",
  },
  sidelistActive: {
    color: "#FFFFFF!important",
    backgroundColor: "#096FFF",
    borderRadius: "16px",
  },
  sidelist: {
    color: "#9F9FAE",
    fontSize: "14px",
    fontWeight: "400",
    padding: "13px 0px 5px 15px",
    margin: "10px 0px",
    display: "flex",
    alignItems: "center",
    textDecoration: 'none',
    '&:hover': {
      color: "#FFFFFF",
      backgroundColor: "#096FFF",
      borderRadius: "16px"
    },
  },
  test: {
    color: "#9F9FAE",
    fontSize: "14px",
    fontWeight: "400",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    textDecoration: 'none',
    '&:hover': {
      color: "#FFFFFF",
      backgroundColor: "#096FFF",
      borderRadius: "16px"
    },
    justifyContent: "center"
  },
  navicon: {
    paddingRight: "6px",
  },
  sidearrow: {
    background: "#ECF0FF",
    borderRadius: "8px 0px 0px 8px",
    display: "inline-block",
    position: "absolute",
    top: "10px",
    right: "-6",
    padding: "10px 4px 10px 7px",
    color: "#BAC4FF",
    '&:hover': {
      right: "0",
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  hidden: {
    [theme.breakpoints.down('sm')]: {
      zIndex: -1,
      transition: 'translateX(-100%)',
      display: 'none'
    }
  },
  imageSvg: {
    filter: 'grayscale(100%)',
    transition: 'fill 0.3s ease',

    '&:hover': {
      filter: 'none',
      color: 'black'
    }
  },
  childAlignment: {
    padding: "10px 0px 10px 24px",
    [theme.breakpoints.down('sm')]: {
      padding: "10px 0px 0px 0px"
    }
  },
  navTextExpanded: {
    display: 'block',
    paddingBottom: '5px'
  },
  navTextCollapsed: {
    display: 'none'
  },

});

const sidebarcontent = [
  {
    "title": "Dashboard",
    "path": "/dashboard",
    "icon": [require('./asset/dashbordIcon.svg'), require('./asset/dashbord_hover.svg')]
  },
  {
    "title": "My Orders",
    "path": "/OrderDetailView",
    "icon": [require('./asset/order2.svg'), require('./asset/order_hover.svg')]
  },
  {
    "title": "My Profile",
    "path": "/UserProfile",
    "icon": [require('./asset/profile3.svg'), require('./asset/profile_hover.svg')]
  },
  {
    "title": "Serving Hours",
    "path": "/Servinghours",
    "icon": [require('./asset/serving_hour.svg'), require('./asset/serving_hour_hover.svg')]
  },
  {
    "title": "My Locations",
    "path": "/MyLocation",
    "icon": [require('./asset/location4.svg'), require('./asset/location_hover.svg')]
  },
  {
    "title": "My Subscription",
    "path": "/SubscriptionPurchased",
    "icon": [require('./asset/subscription5.svg'), require('./asset/subscription_hover.svg')]
  },
  {
    "title": "Offers and Discounts",
    "path": "/ShowOffersAndDiscounts",
    "icon": [require('./asset/offerdiscount7.svg'), require('./asset/offer_hover.svg')]
  },
  {
    "title": "My Menu",
    "path": "/MenuListing",
    "icon": [require('./asset/menu.svg'), require('./asset/menu_hover.svg')]
  },
  {
    "title": "Posts",
    "path": "/Posts",
    "icon": [require('./asset/post9.svg'), require('./asset/post_hover.svg')]
  },
  {
    "title": "Settings",
    "path": "/Settings",
    "icon": [require('./asset/setting.svg'), require('./asset/setting_hover.svg')]
  },
  {
    "title": "Contact Fudu",
    "path": "/ContactFuduData",
    "icon": [require('./asset/contactfudu11.svg'), require('./asset/contact_hover.svg')]
  },
  {
    "title": "Support",
    "path": "/SupportProfileData",
    "icon": [require('./asset/support12.svg'), require('./asset/Support_hover.svg')]
  },

  {
    "title": "Help Centre",
    "path": "/HelpCentre",
    "icon": [require('./asset/help_icon.svg'), require('./asset/help_hover.svg')]
  },
]

// basic sidbar content 10
const basicSubscriptionPlan = [
  {
    "title": "Dashboard",
    "path": "/dashboard",
    "icon": [require('./asset/dashbordIcon.svg'), require('./asset/dashbord_hover.svg')]
  },
  {
    "title": "My Orders",
    "path": "/OrderDetailView",
    "icon": [require('./asset/order2.svg'), require('./asset/order_hover.svg')]
  },
  {
    "title": "My Profile",
    "path": "/UserProfile",
    "icon": [require('./asset/profile3.svg'), require('./asset/profile_hover.svg')]
  },
  {
    "title": "Serving Hours",
    "path": "/Servinghours",
    "icon": [require('./asset/serving_hour.svg'), require('./asset/serving_hour_hover.svg')]
  },
  {
    "title": "My Locations",
    "path": "/MyLocation",
    "icon": [require('./asset/location4.svg'), require('./asset/location_hover.svg')]
  },
  {
    "title": "My Subscription",
    "path": "/SubscriptionPurchased",
    "icon": [require('./asset/subscription5.svg'), require('./asset/subscription_hover.svg')]
  },
  {
    "title": "Offers and Discounts",
    "path": "/ShowOffersAndDiscounts",
    "icon": [require('./asset/offerdiscount7.svg'), require('./asset/offer_hover.svg')]
  },
  {
    "title": "My Menu",
    "path": "/MenuListing",
    "icon": [require('./asset/menu.svg'), require('./asset/menu_hover.svg')]
  },
  {
    "title": "Posts",
    "path": "/Posts",
    "icon": [require('./asset/post9.svg'), require('./asset/post_hover.svg')]
  },
  {
    "title": "Settings",
    "path": "/Settings",
    "icon": [require('./asset/setting.svg'), require('./asset/setting_hover.svg')]
  }
]

// premium sidebar content 13
const premiumSubscriptionPlan = [
  {
    "title": "Dashboard",
    "path": "/dashboard",
    "icon": [require('./asset/dashbordIcon.svg'), require('./asset/dashbord_hover.svg')]
  },
  {
    "title": "My Orders",
    "path": "/OrderDetailView",
    "icon": [require('./asset/order2.svg'), require('./asset/order_hover.svg')]
  },
  {
    "title": "My Profile",
    "path": "/UserProfile",
    "icon": [require('./asset/profile3.svg'), require('./asset/profile_hover.svg')]
  },
  {
    "title": "Serving Hours",
    "path": "/Servinghours",
    "icon": [require('./asset/serving_hour.svg'), require('./asset/serving_hour_hover.svg')]
  },
  {
    "title": "My Locations",
    "path": "/MyLocation",
    "icon": [require('./asset/location4.svg'), require('./asset/location_hover.svg')]
  },
  {
    "title": "My Subscription",
    "path": "/SubscriptionPurchased",
    "icon": [require('./asset/subscription5.svg'), require('./asset/subscription_hover.svg')]
  },
  {
    "title": "Offers and Discounts",
    "path": "/ShowOffersAndDiscounts",
    "icon": [require('./asset/offerdiscount7.svg'), require('./asset/offer_hover.svg')]
  },
  {
    "title": "My Menu",
    "path": "/MenuListing",
    "icon": [require('./asset/menu.svg'), require('./asset/menu_hover.svg')]
  },
  {
    "title": "Posts",
    "path": "/Posts",
    "icon": [require('./asset/post9.svg'), require('./asset/post_hover.svg')]
  },
  {
    "title": "Settings",
    "path": "/Settings",
    "icon": [require('./asset/setting.svg'), require('./asset/setting_hover.svg')]
  },
  {
    "title": "Contact Fudu",
    "path": "/ContactFuduData",
    "icon": [require('./asset/contactfudu11.svg'), require('./asset/contact_hover.svg')]
  },
  {
    "title": "Support",
    "path": "/SupportProfileData",
    "icon": [require('./asset/support12.svg'), require('./asset/Support_hover.svg')]
  },

  {
    "title": "Help Centre",
    "path": "/HelpCentre",
    "icon": [require('./asset/help_icon.svg'), require('./asset/help_hover.svg')]
  },
]

// customise sidebar content 7
const customiseSubscriptionPlan = [
  {
    "title": "Dashboard",
    "path": "/dashboard",
    "icon": [require('./asset/dashbordIcon.svg'), require('./asset/dashbord_hover.svg')]
  },
  {
    "title": "My Orders",
    "path": "/OrderDetailView",
    "icon": [require('./asset/order2.svg'), require('./asset/order_hover.svg')]
  },
  {
    "title": "My Profile",
    "path": "/UserProfile",
    "icon": [require('./asset/profile3.svg'), require('./asset/profile_hover.svg')]
  },
  {
    "title": "Serving Hours",
    "path": "/Servinghours",
    "icon": [require('./asset/serving_hour.svg'), require('./asset/serving_hour_hover.svg')]
  },
  {
    "title": "My Locations",
    "path": "/MyLocation",
    "icon": [require('./asset/location4.svg'), require('./asset/location_hover.svg')]
  },
  {
    "title": "My Subscription",
    "path": "/SubscriptionPurchased",
    "icon": [require('./asset/subscription5.svg'), require('./asset/subscription_hover.svg')]
  },
  {
    "title": "Offers and Discounts",
    "path": "/ShowOffersAndDiscounts",
    "icon": [require('./asset/offerdiscount7.svg'), require('./asset/offer_hover.svg')]
  }
]

class Sidebar extends Component<any, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      sideBarState: {
        sidebarWidth: 2,
        pageWidth: 10,
        expanded: true,
      }
    }
  }

  handleSidebarCollapse = () => {
    this.setState({
      sideBarState: { ...this.state.sideBarState, expanded: false, pageWidth: 11, sidebarWidth: 1 }
    })
  }
  handleSidebarExpand = () => {
    this.setState({
      sideBarState: { ...this.state.sideBarState, expanded: true, pageWidth: 10, sidebarWidth: 2 }
    })
  }

  logout = () => {
    localStorage.clear()
    window.location.href = '/'
  }

  render() {
    const { toggle, classes, children } = this.props;
    const userType = localStorage.getItem("plan_type")
    let subscriptionPlan;
    switch (userType) {
      case "basic":
        subscriptionPlan = basicSubscriptionPlan;
        break;
      case "premium":
        subscriptionPlan = premiumSubscriptionPlan;
        break;
      default:
        subscriptionPlan = customiseSubscriptionPlan;
    }

    return (
      <>
        <div className={classes.root}>
          <Grid container >
            <Grid
              className={`${classes.sidetest} ${toggle ? '' : classes.hidden}`}
              item
              lg={this.state.sideBarState.sidebarWidth}
              xs={this.state.sideBarState.sidebarWidth}
              md={this.state.sideBarState.sidebarWidth}
              sm={this.state.sideBarState.sidebarWidth} >
              <div className={classes.sidenav}>
                <Box className={classes.sidearrow} onClick={this.state.sideBarState.expanded ? this.handleSidebarCollapse : this.handleSidebarExpand}>
                  {this.state.sideBarState.expanded ? <ArrowBackIosIcon style={{ fontSize: "18px" }} /> :
                    <ArrowForwardIosRoundedIcon style={{ fontSize: "18px" }} />}
                </Box>
                <nav className={classes.sidemenu}>
                  {
                    sidebarcontent?.map((val, index) => {
                      return (
                        <SvgImageRander imageList={val.icon} classes={classes} index={index} val={val} state={this.state} closeSidebar={this.props.handleClose} />
                      )
                    }

                    )}

                  <NavLink onClick={this.logout} to='/logout' className={this.state.sideBarState.expanded ? classes.sidelist : classes.test} activeClassName={classes.sidelistActive}
                  >
                    <Icon className={classes.navicon}>
                      <ExitToAppIcon />
                    </Icon>
                    <span className={this.state.sideBarState.expanded ? classes.navTextExpanded : classes.navTextCollapsed}>
                      Logout
                    </span>
                  </NavLink>

                </nav>
              </div>
            </Grid>
            <Grid className={classes.childAlignment} item lg={this.state.sideBarState.pageWidth} xs={12} md={this.state.sideBarState.pageWidth} sm={this.state.sideBarState.pageWidth}>
              <main>{children}</main>
            </Grid>

          </Grid>
        </div>
      </>
    )
  }
}

{/* @ts-ignore */ }
Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggle: PropTypes.bool,
  handleClose: PropTypes.func
};
{/* @ts-ignore */ }
export default withStyles(Styles)(Sidebar);
const SvgImageRander = ({ imageList, classes, index, val, state, closeSidebar }: any) => {
  const [isHover, setIsHover] = useState(false);
  const isActive = window.location.pathname.startsWith(val.path);

  return (
    <NavLink
      className={state.sideBarState.expanded ? classes.sidelist : classes.test}
      key={index}
      exact
      to={val.path}
      isActive={() => isActive}
      activeClassName={classes.sidelistActive}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={closeSidebar}
    >
      <Icon className={classes.navicon}>
        {isActive || isHover ? (
          <img src={imageList[1] || ""} className={classes.imageSvg} />
        ) : (
          <img src={imageList[0] || ""} className={classes.imageSvg} />
        )}
      </Icon>
      <span
        className={
          state.sideBarState.expanded
            ? classes.navTextExpanded
            : classes.navTextCollapsed
        }

      >
        {val.title}
      </span>
    </NavLink>
  );
};