import React from "react";
// Customizable Area Start
import { Grid, Modal, Box, Tooltip } from "@material-ui/core";
import LocationControllerWeb, { Props } from "./LocationControllerWeb";
import { cancel, deleteicon, edit, kuwait } from "./assets";
// @ts-ignore
import GoogleMapReact from "google-map-react";
import { Formik } from "formik";
import * as Yup from "yup";
import PulseLoader from "react-spinners/PulseLoader";
import AddAnotherLocation from "./AddAnotherLocation.web";
import "./MyLocation.css";
import PlacesAutocomplete from "react-places-autocomplete";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import CityComponent from "./Dropdown.web";
// Customizable Area End

// Customizable Area Start
const websiteReg = "(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})";

const schema = Yup.object().shape({
  restaurant_name: Yup.string().required("This Field is required *"),
  location: Yup.string().required("This Field is required *"),
  restaurant_nick_name: Yup.string().required("This Field is required *"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  full_phone_number: Yup.string()
    .min(7, "Please enter valid mobile number")
    .max(15, "Please enter valid mobile number")
    .required("Please enter mobile number "),
  average_price: Yup.string().required("This Field is required *"),
  website: Yup.string()
    .matches(
      // @ts-ignore
      websiteReg,
      "Please enter a valid url"
    )
    .required("This Field is required *"),
  address: Yup.string().required("This Field is required *"),
  overview: Yup.string().required("This Field is required *"),
  start_hours: Yup.string().required("Can't be Empty").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
  start_mins: Yup.string().required("Can't be Empty").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
  start_time_type: Yup.string().required("Can't be Empty"),
  end_hours: Yup.string().required("Can't be Empty").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
  end_mins: Yup.string().required("Can't be Empty").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
  end_time_type: Yup.string().required("Can't be Empty"),
  city: Yup.string().test('required',
    'City is Required', (val) => val !== "placeholder"),
  state: Yup.string().test('required',
    'State is Required', (val) => val !== "placeholder"),
  country: Yup.string().test('required',
    'Country is Required', (val) => val !== "placeholder"),
});

const secondFormSchema = Yup.object().shape({
  parking_availability: Yup.string().required("This Field is required *"),
  valet_parking_availability: Yup.string().required("This Field is required *"),
  serves_alcohol: Yup.string().required("This Field is required *"),
  kids_area: Yup.string().required("This Field is required *"),
  dining: Yup.string().required("This Field is required *"),
  sheesha: Yup.string().required("This Field is required *"),
  speciality: Yup.string().required("This Field is required *"),
  category: Yup.string().required("This Field is required *"),
  smoking_area: Yup.string().required("This Field is required *"),
  type_of_place: Yup.string().required("This Field is required *"),
});

// Customizable Area End
class MyLocation extends LocationControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  AddInput = (formikFunction: any, touched: any, errors: any, inputType: string, inputName: string, inputPlaceholderLabel: string, value: string) => {
    return (
      <div className="mb15">
        <label>{inputPlaceholderLabel}</label>
        <input
          autoComplete='false'
          onChange={formikFunction.handleChange}
          onBlur={formikFunction.handleBlur}
          type={inputType}
          name={inputName}
          placeholder={inputPlaceholderLabel}
          value={value} step="any"/>
        {touched[inputName] && errors[inputName] && <p className="err">{errors[inputName]}</p>}
      </div>
    )
  }

  render() {
    // Customizable Area Start
    const { getAllMyLocationData, editStep, singleFormValues, loader, getSingleCreateFeatureData, selectedRestaurantNickName } = this.state;
    const { start_hours, start_time_type, start_mins, end_hours, end_time_type, end_mins } = this.formatTime(this.state.singleFormValues?.oprational_hour || '00:00 PM  - 00:00 AM');
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Grid container>
          <Grid item sm={12}>
            <h2 className="section-title">My Locations</h2>
            <div className="mylocation">
              <Grid>
                <section>
                  {/* @ts-ignore  */}
                  <AddAnotherLocation />
                  <h1 className="mb30">Choose your location</h1>
                  <Grid container>
                    {getAllMyLocationData.length > 0 &&
                      getAllMyLocationData.map((elem: any) => (
                        <Grid item lg={6} md={6} xs={12} key={elem.id}>
                          <Grid item lg={8} md={12}>
                            <div style={{ position: "relative" }}>
                              <div className="edit-delete-div">
                                <img
                                  src={edit}
                                  alt=""
                                  className="mr10"
                                  data-testid="editbtn"
                                  onClick={() => {
                                    this.editAnotherLocationModal(elem?.id);
                                    this.getSingleRestaurantLocation(elem?.id);
                                    this.getSingleCreateFeature(elem?.id)
                                  }}
                                />
                                {elem.attributes.status === "active" ? (<Tooltip title="You can't delete this location you have to choose another location and then delete">
                                  <img
                                    src={deleteicon}
                                    alt="deleteIcon"
                                    className="delete_not_allowed"
                                  />
                                </Tooltip>) : (<img
                                  src={deleteicon}
                                  alt="deleteIcon"
                                  data-testid="deleteModalClick"
                                  onClick={() => this.showDeleteModal(elem.id, elem.attributes.location, elem.attributes.restaurant_nick_name)}
                                />)}
                              </div>
                            </div>
                            <label htmlFor={elem.id} className="label-box">
                              <div>
                                <input
                                  type="radio"
                                  id={elem.id}
                                  name={elem.type}
                                  data-testid="radioButton"
                                  onChange={(e) => this.onChangeHandler(e.target.checked, elem.id)}
                                  defaultChecked={this.activeTest(elem.attributes.status)}
                                />
                              </div>
                              <div className={`${elem.attributes.status === "active" && "blueborder"} image-box`}>
                                <div className="text-center">
                                  <img src={kuwait} alt="" className="mb30" />
                                  <p className="text-capitalize mt0" id="para">
                                    {elem.attributes.restaurant_nick_name}
                                  </p>
                                </div>
                              </div>
                            </label>

                          </Grid>

                          {/* Delete Modal  */}
                          <Modal
                            open={this.state.isVisibleDeleteModal}
                            onClose={this.hideDeleteModal}
                          >
                            <Box className="modalContainer deleteModal">
                              <div className="modal-header">
                                <span className="fs18 fw500">Delete Location</span>
                                <img
                                  src={cancel}
                                  alt=""
                                  onClick={this.hideDeleteModal}
                                />
                              </div>
                              <div className="modal-body">
                                <p className="modal-body-content">
                                  Are you sure you want to delete this location
                                  - {selectedRestaurantNickName} ? Once deleted, all the data for
                                  this location will be permanently deleted.
                                </p>
                              </div>
                              <div className="text-center mb30">
                                <button
                                  className="btnTransparentRadius"
                                  onClick={this.deleteRestaurantLocation}
                                  data-testid="deletebtn"
                                >
                                  Yes, delete
                                </button>
                                <button
                                  className="btnThemeRadius"
                                  data-testid="deletemodalclose"
                                  onClick={this.hideDeleteModal}
                                  style={{ marginTop: '10px' }}
                                >
                                  No, keep it
                                </button>
                              </div>
                            </Box>
                          </Modal>
                          {/* Delete Modal  */}

                          {/* Edit another Location Modal */}
                          <Modal
                            open={this.state.editAnotherLocationModal}
                            onClose={this.hideEditAnotherLocationModal}
                          >
                            <Box className="modalContainer">
                              <div className="modal-close-btn">
                                <img
                                  src={cancel}
                                  alt=""
                                  onClick={this.hideEditAnotherLocationModal}
                                />
                                <h1 className="add-head">
                                  Edit Location
                                </h1>
                              </div>
                              <section className="add-modal-body">
                                <section className="add-modal-content">
                                  {loader ? (<PulseLoader color="#096fff" className="loader" />) :
                                    (editStep === false && (
                                      <Formik
                                        enableReinitialize
                                        initialValues={{
                                          restaurant_name: singleFormValues?.restaurant_name,
                                          location: singleFormValues?.location,
                                          restaurant_nick_name: singleFormValues?.restaurant_nick_name,
                                          full_phone_number:
                                            singleFormValues?.full_phone_number,
                                          email: singleFormValues?.email,
                                          start_hours: start_hours,
                                          start_mins: start_mins,
                                          start_time_type: start_time_type,
                                          end_hours: end_hours,
                                          end_mins: end_mins,
                                          end_time_type: end_time_type,
                                          average_price:
                                            singleFormValues?.average_price,
                                          website: singleFormValues?.website,
                                          address: singleFormValues?.address,
                                          overview: singleFormValues?.overview,
                                          country: singleFormValues?.country,
                                          state: singleFormValues?.state,
                                          city: singleFormValues?.city,
                                          currency_type: singleFormValues?.currency_type
                                        }}
                                        validationSchema={schema}
                                        data-testid='mylocation-formik'
                                        onSubmit={this.editNextSubmit}
                                        render={({
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit, values, setFieldValue
                                        }) => {
                                          return (
                                            <form onSubmit={handleSubmit}>
                                              <Grid container spacing={6}>
                                                <Grid item lg={6}>
                                                  <div className="mb15">
                                                    <label htmlFor="resname">Restaurant Name</label>
                                                    <input
                                                      type="text"
                                                      placeholder="Restaurant Name"
                                                      id="resname"
                                                      value={values.restaurant_name}
                                                    />
                                                  </div>
                                                  <div className="mb15">
                                                    <label htmlFor="location">Location</label>
                                                    <PlacesAutocomplete
                                                      value={this.state.addressWebEdit}
                                                      onChange={(addressWebEdit) => this.setState({ addressWebEdit })}
                                                      onSelect={this.editHandleSelect}
                                                      data-testid='location'
                                                    >
                                                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                          <input
                                                            {...getInputProps({
                                                              placeholder: "Search Places...",
                                                              className: "location-search-input"
                                                            })}
                                                          />
                                                          <div className="autocomplete-dropdown-container" style={{ overflowY: 'auto', maxHeight: '100px', width: '43%', cursor: 'pointer', marginBottom: "5px" }}>
                                                            {suggestions.map((suggestion) => {
                                                              return (
                                                                <div
                                                                  {...getSuggestionItemProps(suggestion)}
                                                                  style={{ overflowWrap: 'break-word', fontSize: "15px", padding: "3px" }}>
                                                                  {suggestion.description}
                                                                </div>
                                                              );
                                                            })}
                                                          </div>
                                                        </div>
                                                      )}
                                                    </PlacesAutocomplete>
                                                  </div>
                                                  {this.AddInput({ handleChange, handleBlur }, touched, errors, "text", "restaurant_nick_name", "Restaurant Nick Name", values.restaurant_nick_name)}
                                                  <div className="mb15">
                                                    <label>Country</label>
                                                    <CountryDropdown
                                                      value={this.state.singleFormValues?.country}
                                                      onChange={this.handleCountryChangeEdit}
                                                      valueType="short"
                                                      labelType="full"
                                                      // @ts-ignore 
                                                      className="edit-select"
                                                    />
                                                  </div>
                                                  <div className="mb15">
                                                    <label>State/Region</label>
                                                    <RegionDropdown
                                                      country={this.state.singleFormValues?.country || ''}
                                                      value={this.state.singleFormValues?.state}
                                                      onChange={this.handleStateChangeEdit}
                                                      countryValueType="short"
                                                      valueType="short"
                                                      labelType="full"
                                                      // @ts-ignore 
                                                      className="edit-select"
                                                      defaultOptionLabel={this.state.singleFormValues?.state}
                                                    />
                                                  </div>
                                                  <div className="mb15">
                                                    <CityComponent countryCode={this.state.singleFormValues?.country} cityCode={this.state.singleFormValues?.state}
                                                      value={this.state.singleFormValues?.city}
                                                      handleChange={this.handleCityChangeEdit}>
                                                    </CityComponent>
                                                  </div>
                                                  {this.AddInput({ handleChange, handleBlur }, touched, errors, "tel", "full_phone_number", "Contact no.", values.full_phone_number)}
                                                  {this.AddInput({ handleChange, handleBlur }, touched, errors, "email", "email", "Email", values.email)}
                                                  <div>
                                                    <label>Operational Hours</label>
                                                    <div className="operational-box">
                                                      <div className="working-hours">
                                                        <input placeholder="hh" type="text" maxLength={2} name="start_hours" onChange={handleChange} onBlur={handleBlur} value={values.start_hours} onKeyDown={e => this.preventTypeNumber(e)} data-testid='start_hours' />
                                                        <span>:</span>
                                                        <input type="text" maxLength={2} placeholder="mm" value={values.start_mins} name="start_mins" onChange={handleChange} onBlur={handleBlur} onKeyDown={e => this.preventTypeNumber(e)} data-testid='start_mins' />
                                                        <select onChange={handleChange} name="start_time_type" value={values.start_time_type} data-testid='start_time_type'>
                                                          <option value="AM">AM</option>
                                                          <option value="PM">PM</option>
                                                        </select>
                                                        <span className="work-to">to</span>
                                                        <input maxLength={2} type="text" placeholder="hh" onChange={handleChange} onBlur={handleBlur} name="end_hours" value={values.end_hours} onKeyDown={e => this.preventTypeNumber(e)} data-testid='end_hours' />
                                                        <span>:</span>
                                                        <input type="text" maxLength={2} placeholder="mm" onChange={handleChange} onBlur={handleBlur} name="end_mins" value={values.end_mins} onKeyDown={e => this.preventTypeNumber(e)} data-testid='end_minutes' />
                                                        <select onChange={handleChange} data-test-id='input_end_type' name="end_time_type" value={values.end_time_type} data-testid='end_time_type'>
                                                          <option value="AM">AM</option>
                                                          <option value="PM">PM</option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <p className="err">{errors.start_hours || errors.start_mins || errors.end_hours || errors.end_mins}</p>
                                                  </div>
                                                </Grid>
                                                <Grid item lg={6}>
                                                  <label>Map Location</label>
                                                  <div style={{ height: "270px", width: "100%", margin: "5px 0 20px 0" }}>
                                                    <GoogleMapReact
                                                      bootstrapURLKeys={{ key: "AIzaSyDggGoFSnzL7Vley8afXotWIAJWB4-DZcA" }}
                                                      options={{ fullscreenControl: false, keyboardShortcuts: false }}
                                                      center={{ lat: this.state.latitudeWebEdit, lng: this.state.longitudeWebEdit }}
                                                      zoom={15}
                                                      data-testid='gmapp'
                                                      onChange={() => setFieldValue('location', this.state.addressWebEdit)}
                                                      onGoogleApiLoaded={({ map, maps }: any) => this.handleMarkerPositionUpdate(map, maps)}
                                                    />
                                                  </div>
                                                  <div className="dai">
                                                    <select name="currency_type" onChange={handleChange} className="currency-select" value={values.currency_type}>
                                                      <option value="KWD" selected>KWD</option>
                                                      <option value="USD">USD</option>
                                                    </select>
                                                    <div className="w-100">
                                                      {this.AddInput({ handleChange, handleBlur }, touched, errors, "number", "average_price", "Average Price", values.average_price)}
                                                    </div>
                                                  </div>
                                                  {this.AddInput({ handleChange, handleBlur }, touched, errors, "text", "website", "Website", values.website)}
                                                  {this.AddInput({ handleChange, handleBlur }, touched, errors, "text", "address", "Address", values.address)}
                                                  {this.AddInput({ handleChange, handleBlur }, touched, errors, "text", "overview", "Overview", values.overview)}
                                                  <div style={{ marginTop: "25px" }}>
                                                    <button
                                                      className="btnTransparent mr10"
                                                      onClick={
                                                        this
                                                          .hideEditAnotherLocationModal
                                                      }
                                                      data-testid="cancelEditBtn"
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      className="btnTheme"
                                                      type="submit"
                                                      data-testid="editnextbtn"
                                                    >
                                                      Next
                                                    </button>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </form>
                                          )
                                        }}
                                      />
                                    )
                                    )}

                                  {editStep === true && (
                                    <Formik
                                      initialValues={{
                                        parking_availability:
                                          getSingleCreateFeatureData?.attributes?.parking_availability,
                                        valet_parking_availability:
                                          getSingleCreateFeatureData?.attributes?.valet_parking_availability,
                                        serves_alcohol:
                                          getSingleCreateFeatureData?.attributes?.serves_alcohol,
                                        kids_area: getSingleCreateFeatureData?.attributes?.kids_area,
                                        dining: getSingleCreateFeatureData?.attributes?.dining,
                                        sheesha: getSingleCreateFeatureData?.attributes?.sheesha,
                                        speciality:
                                          getSingleCreateFeatureData?.attributes?.speciality,
                                        category: getSingleCreateFeatureData?.attributes?.category,
                                        smoking_area:
                                          getSingleCreateFeatureData?.attributes?.smoking_area,
                                        type_of_place:
                                          getSingleCreateFeatureData?.attributes?.type_of_place,
                                      }}
                                      validationSchema={secondFormSchema}
                                      onSubmit={(values) => {
                                        let PreviousValues = JSON.parse(
                                          // @ts-ignore
                                          localStorage.getItem("editFirstFormValues")
                                        );
                                        this.upDateRestaurantLocation(elem?.id, PreviousValues)
                                        this.setState({
                                          editAnotherLocationModal: false, editStep: false, updateRestaurant: values
                                        });
                                      }}
                                    >
                                      {({
                                        values,
                                        errors,
                                        touched,
                                        handleSubmit,
                                        handleChange,
                                        handleBlur
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                          <Grid container spacing={6}>
                                            <>
                                              <Grid item lg={6}>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">
                                                    Parking Availability
                                                  </p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="parkyes"
                                                        name="parking_availability"
                                                        value="true"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.parking_availability === true}
                                                      />
                                                      <label htmlFor="parkyes">
                                                        Yes
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="parkno"
                                                        name="parking_availability"
                                                        value="false"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.parking_availability === false}
                                                      />
                                                      <label htmlFor="parkno">
                                                        No
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">
                                                    Valet Parking Availability
                                                  </p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="valetparkyes"
                                                        name="valet_parking_availability"
                                                        value="true"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.valet_parking_availability === true}
                                                      />
                                                      <label htmlFor="valetparkyes">
                                                        Yes
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="valetparkno"
                                                        name="valet_parking_availability"
                                                        value="false"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.valet_parking_availability === false}
                                                      />
                                                      <label htmlFor="valetparkno">
                                                        No
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">
                                                    Serves Alcohol
                                                  </p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="alcoholyes"
                                                        name="serves_alcohol"
                                                        value="true"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.serves_alcohol === true}
                                                      />
                                                      <label htmlFor="alcoholyes">
                                                        Yes
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="alcoholno"
                                                        name="serves_alcohol"
                                                        value="false"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.serves_alcohol === false}
                                                      />
                                                      <label htmlFor="alcoholno">
                                                        No
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">
                                                    Kids Area
                                                  </p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="kidsyes"
                                                        name="kids_area"
                                                        value="true"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.kids_area === true}
                                                      />
                                                      <label htmlFor="kidsyes">
                                                        Yes
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="kidsno"
                                                        name="kids_area"
                                                        value="false"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.kids_area === false}
                                                      />
                                                      <label htmlFor="kidsno">
                                                        No
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">Dining</p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="dining"
                                                        name="dining"
                                                        value="fine"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.dining === "fine"}
                                                      />
                                                      <label htmlFor="dining">
                                                        Fine
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="casual"
                                                        name="dining"
                                                        value="casual"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.dining === "casual"}
                                                      />
                                                      <label htmlFor="casual">
                                                        Casual
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="both"
                                                        name="dining"
                                                        value="both"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.dining === "both"}
                                                      />
                                                      <label htmlFor="both">
                                                        Both
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Grid>
                                              <Grid item lg={6}>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">Shisha</p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="sheeshayes"
                                                        name="sheesha"
                                                        value="true"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.sheesha === true}
                                                      />
                                                      <label htmlFor="sheeshayes">
                                                        Yes
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="sheeshano"
                                                        name="sheesha"
                                                        value="false"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.sheesha === false}
                                                      />
                                                      <label htmlFor="sheeshano">
                                                        No
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="radio-box">
                                                  <label htmlFor="speciality">
                                                    Speciality
                                                  </label>
                                                  <input
                                                    type="text"
                                                    id="speciality"
                                                    placeholder="Speciality"
                                                    name="speciality"
                                                    value={values.speciality}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                  />
                                                  {errors.speciality && touched.speciality && (
                                                    <p className="err">{errors.speciality}</p>
                                                  )}
                                                </div>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">
                                                    Category
                                                  </p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="veg"
                                                        name="category"
                                                        value="veg"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.category === "veg"}
                                                      />
                                                      <label htmlFor="veg">
                                                        Veg
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="nonveg"
                                                        name="category"
                                                        value="non_veg"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.category === "non_veg"}
                                                      />
                                                      <label htmlFor="nonveg">
                                                        Non-veg
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="catboth"
                                                        name="category"
                                                        value="both_category"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.category === "both_category"}
                                                      />
                                                      <label htmlFor="catboth">
                                                        Both
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">
                                                    Smoking Area
                                                  </p>
                                                  <div className="sheesha_radio">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="smokingyes"
                                                        name="smoking_area"
                                                        value="true"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.smoking_area === true
                                                        }
                                                      />
                                                      <label htmlFor="smokingyes">
                                                        Yes
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        id="smokingno"
                                                        name="smoking_area"
                                                        value="false"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        defaultChecked={values.smoking_area === false
                                                        }
                                                      />
                                                      <label htmlFor="smokingno">
                                                        No
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="radio-box">
                                                  <p className="mt0 mb10">
                                                    Type of Place
                                                  </p>
                                                  <input
                                                    type="radio"
                                                    id="sheesha"
                                                    name="type_of_place"
                                                    value="shisha"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    defaultChecked={values.type_of_place === "shisha"}
                                                  />
                                                  <label htmlFor="sheesha">
                                                  Shisha
                                                  </label>
                                                  <input
                                                    type="radio"
                                                    id="premium"
                                                    name="type_of_place"
                                                    value="premium"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    defaultChecked={values.type_of_place === "premium"}
                                                  />
                                                  <label htmlFor="premium">
                                                    Premium
                                                  </label>
                                                  <input
                                                    type="radio"
                                                    id="outdoor"
                                                    name="type_of_place"
                                                    value="outdoor"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    defaultChecked={values.type_of_place === "outdoor"}
                                                  />
                                                  <label htmlFor="outdoor">
                                                    Outdoor
                                                  </label>
                                                </div>
                                                <div>
                                                  <div>
                                                    <button
                                                      className="btnTransparent mr10"
                                                      onClick={
                                                        this
                                                          .hideEditAnotherLocationModal
                                                      }
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      className="btnTheme"
                                                      type="submit"
                                                      data-testid="eaddbtn"
                                                    >
                                                      Update
                                                    </button>
                                                  </div>
                                                </div>
                                              </Grid>
                                            </>
                                          </Grid>
                                        </form>
                                      )}
                                    </Formik>
                                  )}
                                </section>
                              </section>
                            </Box>
                          </Modal>
                          {/* Edit another Location Modal */}
                        </Grid>
                      ))}
                  </Grid>
                </section>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

export default MyLocation;
export { MyLocation };
// Customizable Area End

