import React from "react";

import {
  Grid,
  // Customizable Area Start
  Typography,
  Switch,
  Checkbox,
  MenuItem,
  Select,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#6200ee",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    padding: 0,
    height: 26,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    "&$checked": {

      transform: "translateX(16px)",
      color: 'white',
      "& + $track": {
        opacity: 1,
        backgroundColor: "#52d869",
        border: "none",
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    }
  },
  thumb: {
    width: 24,
    height: 24,
  },

  track: {
    backgroundColor: "#9CA3AF",
    borderRadius: 26 / 2,
    transition: theme.transitions.create(["background-color", "border"]),
    opacity: 1,
  },
  checked: {},
  focusVisible: {}

}))(({ classes, ...props }: any) => {

  return (

    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple

      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props} />
  );
});

export const GreenCheckbox: React.ComponentType<any> = withStyles({
  root: {
    "&$checked": {
      color: 'green',
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);


// Customizable Area End

import InternalTeamEmailsController, {
  Props,
} from "./InternalTeamEmailsController.web";

export default class InternalTeamEmails extends InternalTeamEmailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { visible,selectedOptions } = this.state;
    return (
      <ThemeProvider theme={theme}>

        <Grid style={{ height: 'auto', margin: "10px" }}>
          <Grid style={webStyleinternal.gridStyle}>
            <div
              style={{
                display: "flex", justifyContent: "space-between",
              }}
            >
              <div>
                <Typography
                  variant="h5"
                  style={webStyleinternal.typographystyle as any}
                >
                  Reservation Summary Email - Internal
                </Typography>
              </div>
              <div>
                <IOSSwitch />
              </div>
            </div>
            <p style={{ fontSize: "16px", fontWeight: 500, marginTop: '10px', marginBottom: '-10px' }}>
              Automated reservation summary email
            </p>
            <p
              style={{ color: "rgba(156, 163, 175, 1)", fontSize: "16px" }}
            >
              Send time(s) for automated reservation summary email of all
              reservation in an upcoming shift
            </p>
            {["Breakfast", "Lunch", "Dinner", "All Day"].map((value) => (
              <div>
                <GreenCheckbox data-test-id="checkbox" />
                <label>{value}</label>
              </div>
            ))}

            {this.state.SelectsendTime.map((_fields: any, allId: any) => {
              return (
                <Grid container style={{ marginLeft: '10px' }} key={allId}>
                  <Grid item xs={12} md={6}>
                    <Select
                      variant="outlined"
                      fullWidth
                      name='internalcheck1'
                      data-test-id="select"
                      value={selectedOptions.internalcheck1}
                      onChange={this.handleselectChange}
                      style={webStyleinternal.selectmenu}
                      MenuProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                      }}
                    >
                      {['10:30 PM', '11:00 PM', '11:30 PM', '12:00 AM'].map((obj, index) => (
                        <MenuItem value={obj} key={obj}>{obj}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      variant="outlined"
                      fullWidth
                      data-test-id="select"
                      name='internalcheck2'
                      value={selectedOptions.internalcheck2}
                      onChange={this.handleselectChange}
                      style={webStyleinternal.selectmenu}
                      MenuProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                      }}
                    >
                      {['same day', 'next day'].map((obj, index) => (
                        <MenuItem value={obj} key={obj}>{obj}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              )
            })}
            <div style={{ margin: '10px', color: "rgba(9, 111, 255, 1)", fontSize: "14px", cursor: 'pointer' }} onClick={this.addSendTime} data-test-id='addTime'>
              + Add another send time
            </div>
          </Grid>
          <Grid>
            <Grid
              style={webStyleinternal.gridstyle2}
            >
              <Grid>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "rgba(17, 24, 39, 1)",
                    fontSize: "16px",

                  }}
                >
                  Feedback Summary Email - Internal
                </Typography>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div>
                    <p style={{ fontSize: "16px", fontWeight: 500, marginTop: '20px', marginBottom: '-7px' }}>
                      Automated feedback summary email-internal
                    </p>
                    <p
                      style={{
                        color: "rgba(156, 163, 175, 1)",
                        fontSize: "16px"
                      }}
                    >Automated reservation feedback summary email, including all feedback received in the last 24 hours
                    </p>
                  </div>
                  <div style={{ marginBottom: '35px' }}>
                    <IOSSwitch
                      name="visible"
                      data-test-id='checkedB'
                      value={visible}
                      onChange={this.handlechangeSwitch}
                    />
                  </div>
                </Grid>

                {visible ? (
                  <div>

                    <Select
                      variant="outlined"
                      fullWidth
                      name='internalcheck3'
                      data-test-id="select"
                      value={selectedOptions.internalcheck3}
                      onChange={this.handleselectChange}
                      style={{ ...webStyleinternal.selectmenu, width: '31%' }}
                      MenuProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                        getContentAnchorEl: null,
                        disableScrollLock: true,
                      }}
                    >
                      {['10:30 PM', '11:00 PM', '11:30 PM', '12:00 AM'].map((obj) => {
                        return (
                          <MenuItem value={obj} key={obj}>{obj}</MenuItem>
                        )
                      })}
                    </Select>

                  </div>
                ) : (
                  null
                )}
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyleinternal = {
  gridStyle: {
    border: "1px solid rgba(235, 235, 235, 1)",
    borderRadius: '4px',
    marginTop: "35px",
    padding: "20px",
  },

  gridstyle2: {
    border: "1px solid rgba(235, 235, 235, 1)",
    marginTop: '35px',
    borderRadius: '4px',
    padding: "20px",
  },
  selectmenu: {
    width: "60%",
    height: "40px",
    margin: "2px",
    borderRadius: "8px",
    padding: "3px",
    fontSize: "14px",
    background: '#F3F3F3',
    minWidth: '158px'
  },
  typographystyle: {
    color: "rgba(17, 24, 39, 1)",
    fontWeight: "bold",
    fontSize: "18px",
  },
}

// Customizable Area End

