import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core"
import ShowDescriptionController from './ShowDescriptionController.web';

export interface Props {
    navigation: any;
    // Customizable Area Start
    classes?: any;
    handleClose: () => void;
    show: boolean;
    decsID: any;
    // Customizable Area End
  }

export default class ShowDescription extends ShowDescriptionController {
    render() {
        return (
            <div>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" >
                        <Typography style={{
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "green"
                           }}
                        >
                            Here is the full description
                        </Typography>
                    </DialogTitle>

                    <DialogContent>
                        <Typography style={{ fontSize: "16px", textAlign: "center" }}>
                            {this.props.decsID}
                        </Typography>
                    </DialogContent>

                </Dialog>
            </div>
        )
    }
}



