import React from 'react'
// Customizable Area Start
import {
    Typography,
    Grid,
    Avatar,
    Button,
    Box,
    TextareaAutosize,
    Card, CardContent, CardActions, ListItem, List, ListItemAvatar, ListItemText, CardMedia, Menu, MenuItem
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ToastContainer } from 'react-toastify';
import PendingPostsController from './PendingPostsController'
import { StyledButton } from './PostDetails.web';
import {dummyImg} from "./assets"
import ShowDetailedPendingPosts from './ShowDetailedPendingPosts.web';
import PulseLoader from "react-spinners/PulseLoader";
// Customizable Area End

// Customizable Area Start
export default class PendingPosts extends PendingPostsController {
  render() {
    return (
    <div>
    <Grid container spacing={3} direction="row">
     {this.state.loader ? <PulseLoader color="#096fff" className="loader" />  : (this.state.getPendingPostsData.length > 0 ?
     (this.state.getPendingPostsData?.map((item:any, index:any)=> {
        return (
         <Grid item xs={12} lg={3}> 

            <Card style={webStyle.postCardRoot} key={index}>

                <List style={webStyle.cardList}>

                    <ListItem style={webStyle.cardListItem}>

                        <ListItemAvatar style={{ minWidth: '48px' }}>

                            <Avatar src={item.attributes?.profile_picture?.url} 
                              style={{ width: "32px", height: "32px" }} 
                            />
                        </ListItemAvatar>
                        <ListItemText disableTypography
                            secondary={<Typography variant="caption" color="secondary">
                              {item.attributes?.day_ago}
                              </Typography>}
                            primary={<Typography variant="h5">
                              {item.attributes?.accounts.user_name}
                            </Typography>} />
                    </ListItem>
                    <div>
                <IconButton 
                style={{width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        backgroundColor: "rgba(0, 0, 0, 0.24)",
                        cursor: "pointer",
                        margin: "6px",
                        top: 20,
                        right: 10,
                      }}
                    data-test-id={`anchor-${index}`}
                    onClick={(e) => 
                      {
                        this.handleClickopenAnchor(e,item.id,item.attributes?.description,item.attributes?.files)
                     }}
                    >
                    <MoreHorizIcon style={{ color: "white" }} />
                </IconButton>
                <Menu
                    disableScrollLock={false} anchorEl={this.state.anchorElMenuMoreBtn}
                    open={Boolean(this.state.anchorElMenuMoreBtn)}
                    onClose={this.handleCloseMenuDelete}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    getContentAnchorEl={null}
                >
                    <MenuItem onClick={() => 
                    this.handleOpenDetailsModal()
                  }
                    data-test-id={`deletemo-${index}`}
                    > View post details
                    </MenuItem>
                </Menu>
                </div>
                </List>
                { item.attributes?.files.length == 0 && 
                    <div>
                    <CardMedia
                    style={{ width: '100%', height: "170px" }}
                    image={dummyImg}
                    title="Paella dish"
                    /> 
                    <p style={{textAlign: "center",fontSize:"8px",
                       color:"red"}}>
                        No pictures posted yet!
                    </p>
                    </div>       
                }
                {(item.attributes?.files && item.attributes?.files.length !== 0) &&
                <Carousel
                        autoPlay={false}
                        indicators={true}
                        swipe
                        navButtonsAlwaysVisible
                        NextIcon={<ArrowForwardIosIcon fontSize='small' style={{color: "#fff"}}/>}            
                        PrevIcon={<ArrowBackIosIcon fontSize='small' />}
                        className="my-carousel"
                    >    
            {item.attributes?.files.map((file: any, index: number) => (
                <CardMedia key={index} style={{ width: '100%', height: '170px' }}>
                    {file.url.endsWith('.jpg') || file.url.endsWith('.jpeg') || 
                    file.url.endsWith('.png') ? (
                        <img
                            src={file.url} alt=""
                            style={{ objectFit: 'cover',
                             width: '100%',
                              height: '100%' }}
                        />
                    ) : file.url.endsWith('.mp4') &&
                    (
                        <video width="100%" height="100%" controls>
                         <source 
                           src={file.url} 
                           type="video/mp4" 
                           />
                        </video>
                    )}
                </CardMedia>
            ))}
         </Carousel>
        } 

                <CardContent>            
                    <Typography variant="caption" 
                       style={{color: "#4B5563",lineHeight: "16px",overflow: "hidden",display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2, // Limit to 2 lines
                        wordBreak: "break-all",
                        height: "35px",
                        textOverflow: "ellipsis", // Add ellipsis at the end
                       }}  
                    >
              {item.attributes?.description}
              </Typography>
              </CardContent>
                <CardActions style={{padding: "9px 12px 16px 12px"}}>
                    <StyledButton fullWidth variant="outlined" 
                      color="primary"  
                      data-test-id='reject_btn'
                      onClick={()=> this.RejectCall(item.id)}
                    >
                        Reject
                    </StyledButton>
                    <StyledButton 
                     data-test-id='approve_btn'
                      fullWidth 
                      variant="contained" 
                      color="primary"
                      onClick={()=> this.ApproveApiCall(item.id)}
                    >
                        Approve
                    </StyledButton>
                </CardActions>
            </Card>
        </Grid>    
          )
       }
      )) : <p className='noPosts'>No posts available</p>)
    } 
    </Grid>  

          <Dialog
              fullWidth
              maxWidth="sm"
              open={this.state.openRejectModal}
              onClose={this.handleCloseReject}
              aria-labelledby="max-width-dialog-title"
            >
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            style={{ zIndex: 9999 }}
            rtl={false}
          />
              <DialogTitle
                id="max-width-dialog-title"
                style={webStyle.diaHeader}
              >
                <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>Reject Post</Typography>

                <IconButton
                  data-test-id ="viewclose"
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "6px",
                    color: "blue",
                  }}
                  onClick={() => 
                    this.handleCloseReject()
                  }
                >
                  <CancelIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent >
                <form style={webStyle.form} noValidate>
                    <Box>
                      <Typography style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', marginBottom: "10px", fontWeight:500}}>
                        Please share rejection reason
                     </Typography>
                    </Box>
                    <TextareaAutosize
                            aria-label="maximum height"
                            minRows={4}
                            placeholder="Enter rejection reason..."
                            id="description"
                            value={this.state.rejectReason}
                            onChange={(e: any) => {
                                this.handleChangeReason(e)
                            }}
                            name="description"
                            style={{
                                maxWidth: "100%",
                                minWidth: "100%",
                                borderRadius: "8px",
                                backgroundColor: "#F9FAFB",
                                border: "0.2px solid gray",
                                fontFamily: "inter",
                                padding: "11px"
                            }}
                        />
                  
                  <Box style={{ display: "flex", justifyContent: "space-between", margin: "30px" }}>
                    <Button
                      data-test-id="btnRemoveCase"
                      style={{
                        textTransform: "capitalize",
                        color: "#5C8BF6",
                        border: "1px solid #5C8BF6",
                        borderRadius: "20px",
                        width: "45%"
                      }}
                      onClick={()=>this.handleCloseReject()}
                    >
                      Cancel
                    </Button>
                    <Button
                     data-test-id = "modalClose"
                      color="primary"
                      style={{
                        textTransform: "capitalize",
                        background: "#5C8BF6",
                        color: "#fff",
                        borderRadius: "20px",
                        width: "45%"
                      }}
                      onClick={()=> {
                        this.RejectApiCall()
                      }}
                      disabled={this.state.isrejected}
                    >
                       Reject
                    </Button>
                  </Box>

                </form>
              </DialogContent>

            </Dialog>

            <div>
              <ShowDetailedPendingPosts navigation={undefined} handleClose={this.handleCloseDetailsModal} 
              show={this.state.openImgCardModal} postID={this.state.detailsShowId} 
              detailsData={this.state.showDetatilsData} imagesData={this.state.showImageData}/>
            </div>

 </div>
)}}

// Customizable Area Start
const webStyle = {
    childrenSection: {
        padding: "24px",
        marginTop: "24px",
        position: "relative",
        borderRadius: "8px",
    },
    form: {
        padding: "10px 10px 20px 10px",
        display: "flex",
        margin: "auto",
        flexDirection: "column",
      } as React.CSSProperties,
    diaHeader: {
        justifyContent: "space-between",
        borderBottom: "1px solid lightGrey",
        padding: "18px 30px",
        display: "flex",
      },
    
    cardList: {
        padding: "0px",
        display: "flex"
    },

    featureMenu: {
        marginTop: "17px"
    },
    cardListItem: {
        padding: "12px"
    },
    postCardRoot: {
        borderRadius: "8px",
        boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.12)",
        maxHeight: "400px",
        minHeight: "400px"
    }

};
// Customizable Area End