import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    otpValue: string;
    timer: number;
    disabledButton: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class SignUpOTPVerifyControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiOTPVerifyCallId: any;
    apiSendOTPAgainCallId: any;
    timerID: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            otpValue: '',
            timer: 60,
            disabledButton: false,
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        if (this.state.timer > 0) {
            this.timerID = setInterval(
              () => this.setState((prevState) => ({ timer: prevState.timer - 1 })), 1000
            );
          }
        // this.timerID = setInterval(() => this.tick(), 1000); // update every second
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForOtpVerify(from, message)
        this.handleResForsendOTPAgain(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
    async componentWillUnmount() {
        if (this.timerID) clearInterval(this.timerID);
    }

    handleGoBack = () => {
        this.props.navigation.navigate('SignUpEmailVerify')
    }

    handleChangeOTP = (value: any) => {
        this.setState({
            otpValue: value
        })
    }

    tick = () => {
        if (this.state.timer <= 0) {
            if (this.timerID) clearInterval(this.timerID);
            // handle timeout here
        } else {
            this.setState(prevState => ({ timer: prevState.timer - 1 }));
        }
    }

    //API Function
    doOTPVerify = () => {
        if (this.state.otpValue.length != 4) {
            return ToastMessage("error", "Please enter OTP")
        }
        this.setState({disabledButton: true})
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": localStorage.getItem("SignUpToken")
        };

        const attrs = {
            pin: this.state.otpValue,
        };

        const data = {
            attributes: attrs
        };

        const httpBody = {
            data: data
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiOTPVerifyCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'email_otp_verification'
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    sendOTPAgain = () => {
        if(this.state.timer > 0){
            return ToastMessage("error", "OTP is already sent to your registered Email ID, you can try again after few seconds")
        }

        this.setState({otpValue: "" , timer: 0})
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail
        };
        const attrs = {
            email: localStorage.getItem("SignUpEmail"),
            "role": 1
        };
        const data = {
            type: "email_account",
            attributes: attrs
        };
        const httpBody = {
            data: data
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiSendOTPAgainCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_block/email_resend_otp'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //API Response Function
    handleResForOtpVerify = async (from: string, message: Message) => {
        if (this.apiOTPVerifyCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse && apiResponse.token) {
                localStorage.setItem("SignUpToken", apiResponse.token)
                localStorage.removeItem("SignUpEmail")
                this.setState({disabledButton: false})
                this.props.navigation.navigate('SignUpDetails')
                ToastMessage("success", "OTP Verified Successfully")
            } else {
                this.setState({disabledButton: false})
                ToastMessage("error", apiResponse.errors[0].pin || "OTP Expired please try again")
            }
        }

    }

    handleResForsendOTPAgain = async (from: string, message: Message) => {
        if (this.apiSendOTPAgainCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse && apiResponse.meta && apiResponse.meta.token) {
                localStorage.setItem("SignUpToken", apiResponse.meta.token)
                this.setState({timer:60})
                // this.timerID = setInterval(() => this.tick(), 1000);
                ToastMessage("success", "OTP is sent to your Email")
            } else {
                ToastMessage("error", apiResponse.message[0].account)
            }
        }

    }

    // Customizable Area End

}