import { toast } from 'react-toastify';

const ToastMessage = (type:string,message:string) => {
     // @ts-ignore
    toast[type](message)
    document.body.addEventListener("click",() => {
        toast.dismiss()
    });
}

export default ToastMessage;