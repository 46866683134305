import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  passwordEyeToggleForPassword: boolean;
  passwordInputTypeForPassword: string;
  passwordEyeToggleForCon_Password: boolean;
  passwordInputTypeForCon_Password: string;
  flag: boolean;
  disabledButton: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ResetPwdAPICallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      passwordEyeToggleForPassword: false,
      passwordInputTypeForPassword: 'password',
      passwordEyeToggleForCon_Password: false,
      passwordInputTypeForCon_Password: 'password',
      flag: true,
      disabledButton: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.ResetPwdAPICallId) {
          if (!responseJson?.errors && responseJson?.data?.attributes) {
            ToastMessage("success", responseJson.meta.message)
            this.props.navigation.navigate("EmailAccountLogin")
            localStorage.clear()
          }
          else {
            this.setState({disabledButton: false})
            ToastMessage("error", responseJson?.errors[0]?.message || "Something went wrong")
          }
        }
      }

    }

    // Customizable Area End
  }

  // Customizable Area End

  // web events

  // Customizable Area Start

  handlePasswordToggle = () => {
    this.setState({
      passwordEyeToggleForPassword: !this.state.passwordEyeToggleForPassword,
    }, () => {
      this.setState({
        passwordInputTypeForPassword: this.state.passwordEyeToggleForPassword == this.state.flag ? "text" : "password"
      })
    })
  }

  handlePasswordToggleForCon_Password = () => {
    this.setState({
      passwordEyeToggleForCon_Password: !this.state.passwordEyeToggleForCon_Password,
    }, () => {
      this.setState({
        passwordInputTypeForCon_Password: this.state.passwordEyeToggleForCon_Password == this.state.flag ? "text" : "password"
      })
    })
  }

  handleGoBack = () => {
    this.props.navigation.navigate("EmailAccountLogin")
  } 

  ResetPasswordCallapi = async (values: any) => {
    this.setState({disabledButton: true})
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token": localStorage.getItem("forgotpwdToken")
    };
    
    const data = {
      "type": "email_account",
      "attributes": {
        "password": values.new_password,
        "password_confirmation": values.confirm_password
      }
    }

    const httpBody = {
      token: localStorage.getItem("forgotpwdToken"),
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ResetPwdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/reset_password`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  passwordAPICall = async () => {

    const headerData = {
      "token": localStorage.getItem("forgotpwdToken"),
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    
    const dataValue = {
      "type": "email_account",
      "attributes": {
      }
    }

    const httpBody = {
      token: localStorage.getItem("forgotpwdToken"),
      data: dataValue
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ResetPwdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

}
