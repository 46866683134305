import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area End
}

interface S {
    allowAccess: boolean;
    gpsLocation: string;
    isSpinnerShowing: boolean;
    // Customizable Area Start
    planListData: any;
    openCustomizePlanModal: boolean;
    basicPlanType: string;
    basicPrice: any;
    premiumPice: any;
    premiumPlanType: string;
    createSubscriptionResponse: any;
    basicPlanId:number;
    premiumPlanId:number
    selectedPlan:string;
    planDataDuration:any;
    showSubscriptionModal:boolean;
    loader:boolean;
    viewMore:boolean;
    viewMorePremium:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscriptionDetailsController extends BlockComponent<Props, S, SS> {
    getCityApiCallId: any;
    updateDefaultCityApiCallId: any;
    googleMapAPIId: any;
    // Customizable Area Start
    planListDataId: string = "";
    createSubscriptionId: string = "";
    subscriptionPaymentRequestId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        // Customizable Area Start
        this.state = {
            allowAccess: true,
            gpsLocation: "",
            isSpinnerShowing: false,
            planListData: [],
            openCustomizePlanModal: false,
            basicPlanType: "monthly",
            basicPrice: 0,
            basicPlanId:17,
            premiumPice: 0,
            premiumPlanId:20,
            premiumPlanType: "monthly",
            createSubscriptionResponse: "",

            // newly implemented
            selectedPlan:"monthly",
            planDataDuration : {
                basic:"",
                premium: ""
              },
            showSubscriptionModal:true,
            loader:false,
            viewMore:false,
            viewMorePremium:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.planListDataId) {
                this.setState({ planListData: responseJson, basicPrice:responseJson.basic?.[0]?.attributes?.price, premiumPice:responseJson.premium?.[0]?.attributes?.price, loader:false})
            }
            if (apiRequestCallId === this.createSubscriptionId) {
                this.setState({ createSubscriptionResponse: responseJson?.data });
                this.subscriptionPaymentRequest(responseJson?.data?.id)
            }
            if (apiRequestCallId === this.subscriptionPaymentRequestId) {
                if (responseJson?.data) {
                    this.openPaymentPopUp(responseJson?.data?.attributes?.redirect_url);
                }
            }
        }
        runEngine.debugLog("Message Recived", message);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        this.planListData();
    }

    planListData = () => {
        this.setState({ loader: true})
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("SignUpToken"),
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.planListDataId = getValidationsMsg.messageId;
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.planListApiEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    };

    handleCustomizeModal = () => {
        this.setState({ openCustomizePlanModal: true })
    }

    handleCloseCustomizePlanModal = () => {
        this.setState({ openCustomizePlanModal: false })
    }

    onChangeHandler = (e: any, elem: any, value: string) => {
        if (value === "basic") {
          this.setState({ basicPlanType: e.target.value, planDataDuration:{ ...this.state.planDataDuration, basic:e.target.value, }});
          const index = elem.findIndex((el: any) => el.attributes.duration === e.target.value);
          this.setState({ basicPrice: elem[index].attributes.price, basicPlanId: elem[index].id });
        }
      
        if (value === "premium") {
          this.setState({ premiumPlanType: e.target.value, planDataDuration:{ ...this.state.planDataDuration, premium:e.target.value }});
          const index = elem.findIndex((el: any) => el.attributes.duration === e.target.value);
          this.setState({ premiumPice: elem[index].attributes.price, premiumPlanId: elem[index].id });
        }
        
    };

    createSubscription = (planType:string) => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem("SignUpToken"),
        };
        const requestBody = {
            subscription: { plan_id: planType === "basic" ? this.state.basicPlanId : this.state.premiumPlanId }
        };
        const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.createSubscriptionId = getValidationsMsg.messageId;
        getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.createSubscriptionApiEndPoint);
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),

            JSON.stringify(requestBody)
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    subscriptionPaymentRequest = (id: number) => {

        const headers = {
            "Content-Type": configJSON.validationApiContentType, token: localStorage.getItem("SignUpToken"),

        };
        const requestBody = {
             subscription_id: id };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage));
        this.subscriptionPaymentRequestId = getValidationsMsg.messageId;
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.subscriptionPaymentRequestApiEndPoint);
        getValidationsMsg.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),

            JSON.stringify(headers)
        );
        getValidationsMsg.addData(

            getName(MessageEnum.RestAPIRequestMethodMessage),

            configJSON.postApiMethodType

        );
        getValidationsMsg.addData(
            
            getName(MessageEnum.RestAPIRequestBodyMessage),

            JSON.stringify(requestBody)
        );


        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    openPaymentPopUp = (redirectUrl: any) => {
        
        const left = window.screenX + (window.outerWidth - 500) / 2;
        
        const top = window.screenY + (window.outerHeight - 400) / 2.5;

        const title = `WINDOW TITLE`;
        // Open the URL in the current tab
        window.location.href = redirectUrl;
        // Change the current tab's properties to appear as a popup
        window.resizeTo(500, 400);
        window.moveBy(left, top);
        document.title = title;
    }

    // Customizable Area End
}
