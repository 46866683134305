import React from 'react'

// Customizable Area Start
import {
    AppBar, Toolbar, Box, MenuItem, Select,Avatar,Hidden,Link,Menu, SvgIconProps
} from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
const resturentIcon = require('../../navigationmenu/assets/resturent-icon.png');
const Navlogo = require('../../navigationmenu/assets/navlogo.png');
const profileimg = require('../../navigationmenu/assets/dummyimg.png');
import NotificationMenu from './NotificationMenu.web';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from "react-router-dom";
// Customizable Area End


// Customizable Area Start
const classes = {
    appbar: {
        backgroundColor: "#096FFF",
    },
    alterToolbar: {
        display: "flex",
        justifyContent: 'space-between',
        width: "94%"
    },
    navIcondiv: {
        display: "flex",
        width: '34%',
        alignItems: "center",
        justifyContent: 'space-around',
        gap:'29px'
    },
    avatar: {
        width: "32px",
        height: "32px"
    },
    navIcon: {
        backgroundColor: "white",
        color: "#096FFF",
        width: "34px",
        height: "34px",
        cursor:'pointer',
    },
    header: {
        maxWidth: '450px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '16px'
    },
    firstBox: {
        display: "flex",
        gap: "145px",
        alignItems: "center",
        justifyContent: 'space-around',
    },mb3:{marginBottom:'3px',fontize:'large'} as SvgIconProps
}

const StyledSelect: any = withStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        select: {
            paddingRight: '12px',
            paddingLeft: '10px'
        },
        icon: {
            color: '#096FFF',
            position: 'absolute',
            right: 9,
            top: '50%',
            transform: 'translateY(-50%)',
        },
    })
)((props: any) => <Select {...props} MenuProps={{
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
    disableScrollLock: true,
    getContentAnchorEl: null,
}} />);

const StyledMenu: any = withStyles((theme: Theme) => {}
)((props: any) => <Menu {...props}
  getContentAnchorEl={null}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  PaperProps={{
    style: {
      width: "150px",
      borderRadius: '5px'
    }
  }}
  disableScrollLock={true}
/>);
// Customizable Area End

import TopNavController, {
    Props,
} from "./TopNavController.web";

export default class TopNav extends TopNavController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <AppBar position='sticky' style={classes.appbar}>
                <Toolbar style={classes.alterToolbar}>
                    <Box style={classes.firstBox}>
                        <Box>
                        <Hidden  mdUp={true} >
                          <MenuIcon  fontSize='large' onClick={this.props.handleToggle} />
                        </Hidden>
                        <img src={Navlogo} alt="navlogo" style={{marginLeft:'10px'}} />
                        </Box>
                    <Hidden smDown>
                        <span style={classes.header as any}>Welcome, {this.state.userDetails.user_name}</span>
                        </Hidden>
                              </Box>
                    <Box style={classes.navIcondiv}>
                  <Hidden smDown>
                        <Avatar src={this.state.restaurantDetail.restaurant_profile_image} alt='logo' style={{
                            width: "50px",
                            height: "50px", 
                            border: "2px solid #fd91d8",
                        }} />
                        </Hidden>
                        <StyledSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            data-test-id='resturent_select'
                            disableUnderline
                            value={this.state.current_resturent}
                            onChange={(event:any)=>this.handleChange(event)}
                            style={{ backgroundColor: 'white', borderRadius: '5px', width: '30%' ,minWidth:'70px'}}>
                            {this.state.resturent.map((option:any) => (
                                <MenuItem key={option.id} value={option.id} >
                                   {option.label.slice(0, 20)}
                                           {option.label?.length < 20 ? "" : "..."}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                       
                        <Avatar style={classes.navIcon} onClick={(event) =>this.handleClick(event)} data-test-id='notify_icon'>
                            <NotificationsIcon />
                        </Avatar>
                        <NotificationMenu anchorElprops={this.state.anchorEl} handleCloseNot={this.handleClose} navigation={'menu'} id={'Id'} show={this.state.open}/>
                        <Hidden smDown>
                        <Avatar style={classes.navIcon} >
                           <NavLink to='/Settings' style={{color:'#096FFF'}}> <SettingsIcon /></NavLink>
                        </Avatar>
                        <div onClick={this.handleClickProfile} style={{display:'flex',cursor:'pointer'}}>
                        <Avatar style={classes.avatar}>{this.state.userDetails?.user_name?.substring(0,2)}</Avatar>
                        <ArrowDropDownIcon />
                        </div>
                        <StyledMenu
                            id="basic-menu"
                            anchorEl={this.state.profileanchorEl}
                            open={this.state.profileopen}
                            onClose={this.handleCloseProfile}
                            data-test-id='profile_menu'>
                                
                            <MenuItem onClick={this.profileLogout}>Logout</MenuItem>
                            
                        </StyledMenu>
                        </Hidden>
                    </Box>
                </Toolbar>
            </AppBar >
            // Customizable Area End
        )
    }
}

// Customizable Area Start
              
// Customizable Area End
