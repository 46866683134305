import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/common";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  show: boolean;
  anchorElprops: any;
  handleCloseNot: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  open: boolean,
  anchorEl: any,
  menuItems:any[],
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class NotificationMenuController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllNotificationcallID:string=""
  putAllNotificationcallID:string=""
  getMarkAsAllReadcallID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this)
    console.disableYellowBox = true;
    // Customizable Area Start

    this.state = {
      open: false,
      anchorEl: null,
      menuItems:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getAllNotification();
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getAllNotificationcallID) {
      this.setState({menuItems:responseJson?.data})
    }else if (apiRequestCallId === this.putAllNotificationcallID) {
     this.getAllNotification();
    }else if (apiRequestCallId === this.getMarkAsAllReadcallID) {
      if(responseJson?.data){
        this.setState({menuItems:responseJson?.data})
      }
     }
    // Customizable Area End

  }


  // Customizable Area Start

  componentDidUpdate(prevProps:any, prevState:any) {
    if (prevProps.show !== this.props.show) {
      this.getAllNotification();
    }
  }

  getAllNotification = () => {
    let token = localStorage.getItem('authToken');
    const headerData = {
      token: token,
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: `bx_block_notifications/notifications`,
      httpMethod: 'GET'
    })

    this.getAllNotificationcallID = req.messageId;
   runEngine.sendMessage(req.id, req)
  }
  
  putAllNotification = (index:any) => {
    let token = localStorage.getItem('authToken');
    const headerData = {
      token: token,
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: `/bx_block_notifications/notifications/${index}`,
      httpMethod: 'PUT'
    })
    this.putAllNotificationcallID = req.messageId;
   runEngine.sendMessage(req.id, req)
  }

  getMarkAsAllRead = () => {
    let token = localStorage.getItem('authToken');
    const headerData = {
      token: token,
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: `bx_block_notifications/mark_all_as_readed`,
      httpMethod: 'GET'
    })
    this.getMarkAsAllReadcallID = req.messageId;
    runEngine.sendMessage(req.id, req)
  }
  MenuItemConcat=()=>{
    if(Array.isArray(this.state.menuItems)){
    const filterConcat= this.state.menuItems.filter((menuItem: { attributes: { is_read: boolean; }; }) => menuItem.attributes.is_read === false)
    .concat(this.state.menuItems.filter((menuItem: { attributes: { is_read: boolean; }; }) => menuItem.attributes.is_read === true))
    .slice(0, 10);
    return filterConcat;}else{
      return []
    }
  }
  menuitemonClick=(menuItem:any)=>{
    if (!menuItem.attributes.is_read) {
      this.putAllNotification(menuItem.id);
     this.props.handleCloseNot()
    }
  }
  // Customizable Area End

}
