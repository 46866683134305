import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    email: string;
    isValid: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}


export default class SignUpEmailVerifyControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailVerifyCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            email: '',
            isValid: false,
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        localStorage.removeItem("SignUpEmail")
        localStorage.removeItem("userEmail")
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResForEmailVerify(from, message)
        // Customizable Area End
    }

    // Customizable Area Start

    //Web logic Function
    handleEmailSubmit = (value: any) => {
        this.setState({
            email: value, isValid: true
        }, () => this.doEmailVerify())
    }

    //API Function
    doEmailVerify = () => {
        const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail
        };
        const attrs = {
            email: this.state.email,
            role: 1,
        };
        const data = {
            type: "email_account",
            attributes: attrs
        };
        const httpBody = {
            data: data
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiEmailVerifyCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'account_block/accounts'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    //API Response Function
    handleResForEmailVerify = async (from: string, message: Message) => {
        if (this.apiEmailVerifyCallId == message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                localStorage.setItem("SignUpToken", responseJson.meta.token)
                localStorage.setItem("SignUpEmail", this.state.email)
                this.props.navigation.navigate('SignUpOTPVerify')
                ToastMessage("success", "OTP is sent to your Email")
            } else {
                ToastMessage("error", responseJson.error)
                this.setState({isValid: false})
            }
        }

    }

    // Customizable Area End

}