import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  handleClose: () => void;
  show: boolean;
  contTimeID: any;
  profilePic: any;
  userName: any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  getContractTimeData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContractTimeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  getContractTimeCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      getContractTimeData: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

 
  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.show !== this.props.show) {
        this.getContractTimeLineCall();
    }
}
 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.HandleContractTimeLineCall(from, message)

    // Customizable Area End

    // Customizable Area Start

}
  // Customizable Area End

  // web events
  
  getContractTimeLineCall = () => {
    let RestaurantId: any = localStorage.getItem("locationID")
    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getContractTimeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_influencer/contract_timeline?restaurant_location_id=${RestaurantId}&account_id=${this.props.contTimeID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  HandleContractTimeLineCall = async (from: string, message: Message) => {
    if (this.getContractTimeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        if (!apiResponse?.errors) {
            this.setState({ getContractTimeData: apiResponse.data})
            console.log("offers....", apiResponse.offer_date);
            
        }
    }
  }
  // Customizable Area End
}
