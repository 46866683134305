import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  handleClose: () => void;
  show: boolean;
  postID: any;
  detailsData: any;
  imagesData: any[];
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShowDetailedApprovedPostsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),getName(MessageEnum.RestAPIResponceMessage),getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

 
  async componentDidMount() {
    super.componentDidMount();  
    // Customizable Area Start
    // Customizable Area End
} 
 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
}
  // Customizable Area End

}
