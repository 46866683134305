// Customizable Area Start
import React from "react";
import {
  Tabs, 
  Tab,
  Paper
} from "@material-ui/core";
import Box from '@material-ui/core/Grid'
import {user1,user2,user3,user4} from './assets';
import { withStyles, Theme, createStyles, createTheme,ThemeProvider} from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
const styles = {
  indicator: {
    backgroundColor: '#BEBEBE'
  }
};
const theme = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        minWidth: "140px",
      },
      icon: {
        right: "10px",
      },
    },
    MuiTableCell: {
      body: {
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
      },
    },
  },
});
export const StyledTab2: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16.94px",
            padding: "7px 16px",
            color: "#9CA3AF",
            minWidth: "0px",
            '&:hover': {
                color: '#40a9ff'
            },
            '&$selected': {
                color: '#096FFF',
                fontWeight: 500,
            }
        },
        selected: {},
    }),
)((props: any) => <Tab {...props} />);
// Customizable Area End

import ProfileInfluencersControllerWeb, { Props } from "./ProfileInfluencersControllerWeb"
import InfluencersProposals from "./InfluencersProposals.web";
import  SuggestedInfluencers  from "./SuggestedInfluencers.web";
import ShortListed from "./ShortListed.web";
import  ActiveInfluencers  from "./ActiveInfluencers.web";

export default class ProfileInfluencers extends ProfileInfluencersControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    
    return (
      <ThemeProvider theme={theme} >
      <Box>
      <Paper elevation={3} style={{
        marginTop: "24px",
        padding: "24px",
        borderRadius: "8px",
        position: "relative"
      }}>
        <Box style={webStyle.influencersBox}>
        <Tabs 
          data-test-id ="tabs"
          value={this.state.activeTab} 
          onChange={this.handleTabChange} 
          indicatorColor="primary"
          TabIndicatorProps={{ style: { display: "none" } }}
          >
          <StyledTab2 label="SUGGESTED INFLUENCERS" />
          <StyledTab2 label="SHORTLISTED" />
          <StyledTab2 label="PROPOSALS" />
        </Tabs>
         {this.state.activeTab === 0 && <SuggestedInfluencers navigation={undefined} id={""}/>}
         {this.state.activeTab === 1 && <ShortListed navigation={undefined} id={""}/>}
        {this.state.activeTab === 2 && <InfluencersProposals navigation={undefined} id={""}/>}

        </Box>
      </Paper>
     </Box> 
     </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export const MessageData = [
    {
        img:user1,
        name:"Samantha Trope",
        id:"@influcer_id",
        public:"1.25 K",
        followers:"1.54 K",
        following:"1,232",
    },
    {
        img:user2,
        name:"Joana",
        id:"@influcer_id",
        public:"1.25 K",
        followers:"1.54 K",
        following:"1,232",
    },
    {
        img:user3,
        name:"Sam Wikins",
        id:"@influcer_id",
        public:"1.25 K",
        followers:"1.54 K",
        following:"1,232",
    },
    {
        img:user4,
        name:"Folna Wikins",
        id:"@influcer_id",
        public:"1.25 K",
        followers:"1.54 K",
        following:"1,232",
    },
    {
        img:user1,
        name:"Samantha Trope",
        id:"@influcer_id",
        public:"1.25 K",
        followers:"1.54 K",
        following:"1,232",
        
    },
    {
        img:user2,
        name:"Joana",
        id:"@influcer_id",
        public:"1.25 K",
        followers:"1.54 K",
        following:"1,232",
    },
   
  ]
// Customizable Area End

// Customizable Area Start
const webStyle = {
    childrenSection: {
      marginTop: "24px",
      padding: "24px",
      borderRadius: "8px",
      position: "relative"
  }, 
    influencersBox: {
        backgroundColor: "#fff",
        borderRadius: "26px",
    }
}
