import React from 'react'
// Customizable Area Start
import {
    Typography,
    Grid,
    Avatar,
    Card, CardContent, CardActions, ListItem, List, ListItemAvatar, ListItemText, CardMedia,IconButton,
    Menu, MenuItem,
} from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {dummyImg} from "./assets"
import './ImageCard.css';

import ApprovedPostsController from './ApprovedPostsController';
import ShowDetailedApprovedPosts from './ShowDetailedApprovedPosts.web';
import PulseLoader from "react-spinners/PulseLoader";
import "./ImageCard.css"
// Customizable Area End

// Customizable Area Start
export default class ApprovedPosts extends ApprovedPostsController {
  render() {
    return (
    <div>
    <Grid container spacing={3} direction="row">
    {this.state.loader ? <PulseLoader color="#096fff" className="loader" /> : (this.state.getApprovedPostsData.length > 0 ?
     (this.state.getApprovedPostsData?.map((item:any, index:any)=> {
        return (
         <Grid item xs={12} lg={3}>  
            <Card style={webStyle.postCardRoot} key={index}>
                <List 
                 style={webStyle.cardList}
                >
                    <ListItem style={webStyle.cardListItem}>
                        <ListItemAvatar 
                            style={{ minWidth: '48px' }}>
                            <Avatar 
                             src={item.attributes?.profile_picture?.url} 
                             style={{ width: "32px", 
                                      height: "32px" 
                                    }} 
                             />
                        </ListItemAvatar>
                        <ListItemText disableTypography
                            secondary={<Typography variant="caption" 
                                        color="secondary">
                                {item.attributes?.day_ago}
                            </Typography>}
                            primary={
                             <Typography variant="h5">
                                {item.attributes?.accounts.user_name}
                            </Typography>
                        } />
                    </ListItem>
                <div>
                <IconButton style={{
                    height: "24px",
                        width: "24px",
                        borderRadius: "50%",
                        margin: "6px",
                        right: 10,
                        top: 20,
                        cursor: "pointer",
                        backgroundColor: "rgba(0, 0, 0, 0.24)",
                      }}
                    data-test-id={`anchor-${index}`}
                    onClick={
                        (e) => {
                        this.handleClickopenAnchor(e,item.id,item.attributes?.description,item.attributes?.files)
                        }}
                    >
                    <MoreHorizIcon style={{ color: "white" }} />
                </IconButton>

                <Menu
                    anchorEl={this.state.anchorElMenuMoreBtn}
                    disableScrollLock={false}
                    onClose={this.handleCloseMenuDelete}
                    open={Boolean(this.state.anchorElMenuMoreBtn)}
                    getContentAnchorEl={null}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                >
                    <MenuItem 
                     onClick={() => 
                        this.handleOpenDetailsModal()
                    }
                     data-test-id={`deletemo-${index}`}
                    > 
                    View post details
                    </MenuItem>
                </Menu>
                </div>
                </List>
                { item.attributes?.files.length == 0 && 
                    <div>
                    <CardMedia
                       style={{ width: '100%', 
                                height: "170px" 
                            }}
                       image={dummyImg}
                       title="Paella dish"
                    /> 
                    <p style={{textAlign: "center",
                               fontSize:"8px",
                               color:"red"}}>No pictures posted yet!</p>
                    </div>       
                }
                {(item.attributes?.files && item.attributes?.files.length !== 0) &&
                 <Carousel
                 autoPlay={false}
                 indicators={true}
                 swipe
                 navButtonsAlwaysVisible
                 NextIcon={<ArrowForwardIosIcon fontSize='small' style={{color: "#fff"}}/>}            
                 PrevIcon={<ArrowBackIosIcon fontSize='small' />}
                 className="my-carousel"
             >    
     {item.attributes?.files.map((file: any, index: number) => (
         <CardMedia key={index} style={{ width: '100%',
                                         height: '170px' 
                                         }}
                                         >
             {file.url.endsWith('.jpg') || file.url.endsWith('.jpeg') || file.url.endsWith('.png') ? (
                 <img
                     src={file.url}
                     alt=""
                     style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                 />
             ) : (<video width="100%" height="100%" controls >
                <source  src={file.url} type='video/mp4' />
             </video>)}

         </CardMedia>
     ))}
    </Carousel>
    }   
         <CardContent>
                    <Typography variant="caption"  style={{
                        color: "#4B5563",lineHeight: "16px",marginTop: "5px", overflow: "hidden",display: "-webkit-box",
                        WebkitLineClamp: 2, 
                        marginBottom: "5px",
                        textOverflow: "ellipsis", 
                        WebkitBoxOrient: "vertical",
                        wordBreak: "break-all",
                        height: "35px"
                       }}  >
                        {item.attributes?.description}
                    </Typography>

                </CardContent>
                <CardActions 
                  style={{padding: "9px 12px 16px 12px"}}
                >
                 <FavoriteIcon style={{color: "#316cf8", marginRight: "7px"}}/>
                 <Typography style={{lineHeight: "16px"}}>
                   {item.attributes?.accounts?.likes} Likes
                 </Typography>
                </CardActions>
            </Card>
        </Grid>    
          )
       })
       ) : <p className='noPosts'>No posts available</p>)
    } 
    </Grid> 
    <div>
    <ShowDetailedApprovedPosts navigation={undefined} 
     handleClose={this.handleCloseDetailsModal}
     show={this.state.openImgCardModal} postID={this.state.detailsShowId}
     detailsData={this.state.showDetatilsData} 
     imagesData={this.state.showImageData} 
    />  
    </div>
 </div>
)}}

// Customizable Area Start
const webStyle = {
    featureMenu: {
        marginTop: "17px"
    },
    childrenSection: {
        position: "relative",
        marginTop: "24px",
        borderRadius: "8px",
        padding: "24px",
    },
    
    cardListItem: {
        padding: "12px"
    },
    cardList: {
        padding: "0px",
        display: "flex"
    },

    cardActionBtnContainer: {
        padding: "9px 12px 16px 12px"
    },
    postCardRoot: {
        borderRadius: "8px",
        boxShadow: "0px 12px 36px rgba(0, 0, 0, 0.12)",
        maxHeight: "400px",
        minHeight: "400px"
    },
};
// Customizable Area End