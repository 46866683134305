import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  email: any;
  buttonFlag: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotPwdController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ForgotPwdAPICallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: '' || localStorage.getItem('forgotpwdEmail'),
      buttonFlag: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.ForgotPwdAPICallId) {
          if (responseJson?.data && responseJson?.data?.attributes) {
            localStorage.setItem("forgotpwdToken", responseJson?.meta?.token)
            localStorage.setItem("forgotpwdEmail", responseJson.data.attributes.email)
            this.setState({buttonFlag: false})
            ToastMessage("success", "OTP is sent to your Email")
            this.props.navigation.navigate("ForgotPasswordOTP")
          }
          else {
            this.setState({buttonFlag: false})
            ToastMessage("error", responseJson.errors[0].email)
          }
        }
      }

    }

    // Customizable Area End
  }

  // Customizable Area End

  // web events

  // Customizable Area Start

  handleGoBack=()=>{
    this.props.navigation.navigate("EmailAccountLogin")
  }

  callForgotPwdApi = (values: any) => {
    this.setState({buttonFlag: true})
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };

    const attrs = {
      email: values.email,
      login_type: "website"
    };
    
    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ForgotPwdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/forgot_password`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  // Customizable Area End
}
