export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const attach = require("../assets/attach.png");
export const men = require("../assets/men.png");
export const close = require("../assets/close.svg");
export const attachtransparent = require("../assets/attachtransparent.png");
export const send = require("../assets/send.png");
export const group_arrow = require("../assets/group_arrow.png");
export const group_arrow2 = require("../assets/group_arrow2.png");
export const group_arrow3 = require("../assets/group_arrow3.png");
export const group_arrow4 = require("../assets/group_arrow4.png");
