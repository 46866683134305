import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Drag from "dragking"
import ToastMessage from "../../../components/src/ToastMessage";

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    location: any;
    // Customizable Area End
}

interface S {
    allowAccess: boolean;
    gpsLocation: string;
    isSpinnerShowing: boolean;
    // Customizable Area Start
    tableLayout: any;
    roomNames: any;
    setRoom: any;
    allCreatedDragsData: any[];
    activeDrags: number;
    deltaPosition: {
        x: number, y: number;
    },
    controlledPosition: {
        x: number, y: number;
    },
    dragData: any;
    allTablesListData: any;
    allPlantsandDecorationData: any;
    allOtherFacilitiesData: any;
    savedRoomName: any;
    addRoom: boolean;
    roomName: any;
    inputCount: number;
    singleRoom: any;
    singleRoomLayout: any;
    anchorEl: any;
    anchorEl1: any;
    popoverId: any;
    bookableSeats: any;
    activeButtonId: any;
    updatedState: boolean;
    popId: any;
    isDragging: boolean;
    deleteRoomId: any;
    addRoomSet: boolean;
    updateTStatus: any;
    getDraggedItem: any;
    tablename: any,
    tableStatus: boolean,
    draggedImg: any,
    draggedDataType: any;
    updateTableName: any,
    plantorOthersName: any;
    draggedItemType: any,
    checkedB: any,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class TableLayoutController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTablesCallId: any;
    getPlantsandListsCallId: any;
    GetOtherFacilitiesCallId: any;
    CreateRoomcallId: any;
    getAllRoomListApiCallId: any;
    showRoomCallId: any;
    updateRoomcallId: any;
    deleteTableApiCallId: any;
    deleteRoomApiCallId: any;
    dragAreaRef: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.ReciveUserCredentials)
            // Customizable Area End
        ];

        // Customizable Area Start
        this.state = {
            allowAccess: true,
            gpsLocation: "",
            isSpinnerShowing: false,
            tableLayout: [],
            roomNames: [''],
            setRoom: "",
            allCreatedDragsData: [],
            activeDrags: 0,
            deltaPosition: {
                x: 0,
                y: 0
            },
            controlledPosition: {
                x: -400,
                y: 200
            },
            dragData: "",
            allTablesListData: [],
            allPlantsandDecorationData: [],
            allOtherFacilitiesData: [],
            savedRoomName: "",
            addRoom: false,
            roomName: "",
            inputCount: 1,
            singleRoom: null,
            singleRoomLayout: [],
            anchorEl: null,
            anchorEl1: null,
            popoverId: null,
            bookableSeats: null,
            activeButtonId: null,
            updatedState: false,
            popId: null,
            isDragging: false,
            deleteRoomId: null,
            addRoomSet: false,
            updateTStatus: false,
            getDraggedItem: null,
            tablename: "",
            tableStatus: true,
            draggedImg: null,
            draggedDataType: null,
            updateTableName: null,
            draggedItemType: null,
            plantorOthersName: null,
            checkedB: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
    
        // Customizable Area Start
        this.getTablesListsCall();
        this.GetAllPlantsandDecorationsCall()
        this.GetOtherFacilitiesCall();
        this.getAllRoomListApiCall()
        setTimeout(()=> {
         let drag = new Drag(".drag-area", ".drags-item").start().getconsoleXY()
         this.setState({dragData: drag})         
        },1000)
      
        // Customizable Area End
      }

  componentDidUpdate(prevProps: any, prevState: any) {
    if(this.state.dragData.points){
      this.state.dragData.points.style.pointerEvents = "auto"
      this.state.dragData.points.addEventListener("mouseup", (e:any)=> {        
        const dragType = this.state.dragData.points.dataset.type=="table"
        let {table_name, tableStatus} = this.state.dragData.points.dataset
        this.handleClickFirstDragged(e,this.state.dragData.points.id,table_name, tableStatus,dragType)
      })
    }
  
   }   


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleGetTablesListsCall(from, message)
        this.handleGetAllPlantsandDecorationsCall(from, message)
        this.handleGetOtherFacilitiesCall(from, message)
        this.handleCreateRoomcall(from, message)
        this.handleGetAllRoomCall(from, message)
        this.handleShowRoomCall(from, message)
        this.handleUpdateRoomcall(from, message)
        this.HandleDeleteTableCall(from, message)
        this.HandleDeleteRoomCall(from, message)
    // Customizable Area End
    }

    // Customizable Area Start

    handleNameChange = async(event: any) => {
      this.setState({tablename: event.target.value})
      this.state.dragData.points.dataset.table_name = event.target.value
    }

    handleSwitchChange = async (event: any) => {
      this.setState({tableStatus: !this.state.tableStatus},()=>{
        this.state.dragData.points.dataset.tableStatus = this.state.tableStatus
      })
    }

    handleTableSwitchChange = async(event: any) => {
      let i = this.state.singleRoomLayout.findIndex((d:any)=> {
        return d.id == this.state.activeButtonId
       })
       this.state.singleRoomLayout[i].available_for_booking = event.target.checked;
       this.setState({updateTStatus: event.target.checked})
    }

    handleClickFirstDragged = async(event: any, id: any, tname:any, tstatus: any, itemType: any)=> {
      this.setState({
        anchorEl1: event.currentTarget,
        // popoverId: id,
        tablename: tname,
        popId: id,
        tableStatus: tstatus? true : false,
        draggedImg: event.target.id,
        draggedDataType: itemType
      });
    }

    handleDeleteDraggedItem = async(imgId: any) => {
       this.setState({anchorEl1: null})

       document.getElementById(this.state.draggedImg)?.remove()
    }

    handleFirstDragClose = () => {
      this.setState({
        anchorEl1: null,
        popId: null
      })
    }

    handleClickChip = (chipId: any,render:any=true) => {
      
      this.setState({ singleRoom: null ,deleteRoomId: chipId});
      setTimeout(() => {
        // Access and manipulate the DOM element using the ref
        const dragArea = this.dragAreaRef.current;
  
        if(dragArea) {
          while (dragArea.lastElementChild) {
            
              dragArea.removeChild(dragArea.lastElementChild);
            
          }
          if(render){
            this.showRoomApiCall(chipId);
          }
        }
      }, 30);
    }
  

    handleChangeRoom = async(event:any)=> {
      this.setState({ roomName: event.target.value })
    }

    handleAddRoom =()=> {
      this.handleClickChip(15,false)
      this.setState({singleRoom: null, deleteRoomId: null, addRoomSet: true })
    }

    handleDelete = async(chipToDelete:any, roomId: any) => {
      
      this.setState((prevState:any) => ({
        roomNames: prevState.roomNames.filter(
          (chip:any) => chip.name !== chipToDelete
        ),
      }));
        // Access and manipulate the DOM element using the ref
            this.deleteRoomApiCall(roomId)
            this.setState({singleRoom: null})   
    };

    handleMouseDown = async(e:any, id:any) => {
      e.preventDefault(); 
      e.stopPropagation(); 
      //  e.target.style.pointerEvents = "none"
       this.state.dragData.activecurrentId = e.target
    };
  
    handleClick = (event: any, id: any, seats: any, updateStatus: any, updateTName: any,itemType: any, plantsName: any) => { 
     
      this.setState({
        anchorEl: event.currentTarget,
        popoverId: id,
        activeButtonId: id,
        bookableSeats: seats,
        updateTStatus: updateStatus,
        updateTableName: updateTName,
        plantorOthersName: plantsName,
        draggedItemType: itemType,
      });
    };

    handleClose = () => {
      this.setState({
        anchorEl: null,
        popoverId: null,
        activeButtonId: null,
        bookableSeats: null
      });
    };

    handleUpdateTnameChange=async(event: any) => {
     let i = this.state.singleRoomLayout.findIndex((d:any)=> {
      return d.id == this.state.activeButtonId
     })
     this.state.singleRoomLayout[i].table_no = event.target.value;
       this.setState({updateTableName: event.target.value})
    }

  showRoomApiCall= (roomId: any)=> {
    const header = {
      "token": localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showRoomCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_layout/rooms/${roomId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   
    getTablesListsCall = () => {
        const header = {
          "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getTablesCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_layout/tables`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }

      GetAllPlantsandDecorationsCall = () => {
        const header = {
          "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPlantsandListsCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_layout/plants_and_decorations`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }

      GetOtherFacilitiesCall = () => {
        const header = {
          "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetOtherFacilitiesCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_layout/other_facilities`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }

      getAllRoomListApiCall = () => {
        const header = {
          "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllRoomListApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_layout/rooms`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      } 

      CreateRoomcall = async () => {
       

        const header = {
          "token": localStorage.getItem("authToken"),
          "Content-Type": "application/json"
        };
    
      let XY=this.state.dragData.getAllIdAndXY(this.state.roomName);


    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.CreateRoomcallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify({data:XY})
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_layout/rooms`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `POST`
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      updateRoomcall = async () => {
     
      

        const header = {
          "token": localStorage.getItem("authToken"),
          "Content-Type": "application/json"
        };
    
        let XY=this.state.dragData.getAllIdAndXYupdate(this.state);


       
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.updateRoomcallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify({data:XY})
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_layout/rooms/${this.state.singleRoom.id}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `PATCH`
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      deleteTableApiCall = (tableId:any) => {
        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteTableApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_layout/room_layout_properties/${tableId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `DELETE`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteRoomApiCall = (roomId:any) => {
      const header = {
          "token": localStorage.getItem("authToken")
      };
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteRoomApiCallId = requestMessage.messageId;
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_layout/rooms/${roomId}`
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `DELETE`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

      handleGetTablesListsCall = async (from: string, message: Message) => {
        if (this.getTablesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (!apiResponse.errors) {
              this.setState({ allTablesListData: apiResponse.data })
            }
        }
      }

      handleGetAllPlantsandDecorationsCall = async (from: string, message: Message) => {
        if (this.getPlantsandListsCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (!apiResponse.errors) {
              this.setState({ allPlantsandDecorationData: apiResponse.data })
            }
        }
      }  

      handleGetOtherFacilitiesCall = async (from: string, message: Message) => {
        if (this.GetOtherFacilitiesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (!apiResponse.errors) {
              this.setState({ allOtherFacilitiesData: apiResponse.data })
            }
        }
      }  

      handleCreateRoomcall = async (from: string, message: Message) => {
        if (this.CreateRoomcallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (!apiResponse?.errors) {
            ToastMessage("success", "Room created successfully")
            this.setState({roomName: "", singleRoom: null, deleteRoomId: apiResponse.id})
            this.getAllRoomListApiCall() 
          } else {
            ToastMessage("error","room name" +" "+ apiResponse.errors[0].name)
            this.setState({singleRoom: null, addRoomSet: true})
          }
        }
      }

      handleGetAllRoomCall = async (from: string, message: Message) => {
        if (this.getAllRoomListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (!apiResponse.errors) {
                this.setState({ roomNames: apiResponse?.rooms })
            } 
        }
      }  

      handleShowRoomCall = async (from: string, message: Message) => {
        if (this.showRoomCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (!apiResponse.errors) {
            
              this.setState({ singleRoom: apiResponse, 
                singleRoomLayout: apiResponse.room_layout_properties
              })
            }
          }
        }
    
        handleUpdateRoomcall = async (from: string, message: Message) => {
          if (this.updateRoomcallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            if (!apiResponse?.errors) {
              ToastMessage("success", "Room updated successfully")
            }
          }
        }

        HandleDeleteTableCall = async (from: string, message: Message) => {
          if (this.deleteTableApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
              const apiResponse = message.getData(
                  getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
  
              if (!apiResponse.errors) {
                  ToastMessage('success', apiResponse.message)
                  this.setState({ anchorEl: null, popoverId: null})
                  this.showRoomApiCall(this.state.deleteRoomId)
              }
          }
      }    

      HandleDeleteRoomCall = async (from: string, message: Message) => {
        if (this.deleteRoomApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (!apiResponse.errors) {
                ToastMessage('success', apiResponse.message)
                this.getAllRoomListApiCall()                
            }
        }
    }      

    // Customizable Area End
}
