Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
// exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Chat9";
exports.labelBodyText = "Chat9 Body";

exports.btnExampleTitle = "CLICK ME";
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.GetAllTicketsApiEndPoint = "bx_block_ticketsystem/supports"
exports.GetsearchDescription = "bx_block_ticketsystem/search"
// Customizable Area End