import React from "react";
import {
  Button,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  TableHead,
  Typography,
  FormControl,
  TextField,
  Menu,
  Grid
} from "@material-ui/core";
import Box from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { group_arrow, group_arrow2, group_arrow3, group_arrow4 } from "./assets";


import InfluencersProposalsControllerWeb, { Props } from "./InfluencersProposalsControllerWeb"
import ViewReason from "./ViewReason.web";
import ContractTime from "./ContractTime.web";


const Styles = () => ({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end"
  },
  paginationselect: {
    border: "1px solid #e5e7eb",
    borderRadius: "3px",
    background: "#fff",
    height: "31px",
    padding: "2px 16px 0 3px",
    margin: "0 10px"
  },
  opactiy_0: {
    opacity: 0.5,
    cursor: "not-allowed",
    border: 0,
    borderRadius: "100%"
  },
  pointer: {
    cursor: "pointer",
    border: 0,
    borderRadius: "100%"
  }
})

export class InfluencersProposals extends InfluencersProposalsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    // @ts-ignore
    const { classes } = this.props;
    const { proposalsInfluencersListData, proposalSearchData } = this.state;

    return (
      <React.Fragment>
        <Box style={{ backgroundColor: "#fff", border: "0.2px solid #F2F2F2", borderRadius: "10px", marginLeft: "10px" }}>
          <Box className="influencers-search">
            <Grid xs={12} lg={6} md={6}>
              <TextField
                style={{ width: '98%', minWidth: '120px' }}
                data-test-id="text"
                variant="outlined"
                size="small"
                placeholder="Search by name..."
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                  ),
                }}
                value={this.state.searchProposal}
                onChange={(e: any) => {
                  this.HandleProposalSearch(e)
                }}
                // onKeyPress={this.searchProposalInfluencersCall}
              />
            </Grid>
          </Box>
          <TableContainer style={{ marginLeft: "10px" }}>
            <Table>
              <TableHead style={{ borderBottom: "hidden" }}>
                <TableRow>
                  <TableCell
                    style={{ textTransform: "uppercase", fontWeight: 600, fontSize: "12px", color: "#9CA3AF" }}
                  >
                    INFLUENCER NAME
                  </TableCell>
                  <TableCell style={{ textTransform: "uppercase", fontWeight: 600, color: "#9CA3AF", fontSize: "12px" }}>
                    POSTS/MONTH
                  </TableCell>
                  <TableCell
                    style={{ textTransform: "uppercase", color: "#9CA3AF", fontWeight: 600, fontSize: "12px" }}
                  >
                    PAYMENT/POST
                  </TableCell>
                  <TableCell style={{
                    color: "#9CA3AF", fontWeight: 600, fontSize: "12px", textDecoration: "none",
                    display: "flex", alignItems: "center", borderBottom:"none"
                  }}>
                    FOLLOWERS
                    <div style={{ display: "flex", 
                                  flexDirection: "column", 
                                  marginLeft: "6px" 
                                }}
                    >
                    <ExpandLessIcon style={{ color: "#6B7280", marginBottom: "-6px" }} />
                      <ExpandMoreIcon style={{ color: "#6B7280", marginTop: "-6px" }} />
                    </div>
                  </TableCell>
                  <TableCell style={{ textTransform: "uppercase", color: "#9CA3AF", fontWeight: 600, fontSize: "12px" }}>
                    Contract
                  </TableCell>

                  <TableCell
                    style={{ display: "flex", alignItems: "center", fontWeight: 600, color: "#9CA3AF", fontSize: "12px",borderBottom:"none" }}
                  >
                    ROI
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "6px" }}>
                      <ExpandLessIcon style={{color: "#6B7280",
                                              marginBottom: "-6px" 
                                              }} 
                                              />
                      <ExpandMoreIcon style={{ color: "#6B7280", marginTop: "-6px" }} />
                    </div>
                  </TableCell>

                  <TableCell
                    style={{ textTransform: "uppercase", color: "#9CA3AF", fontWeight: 600, fontSize: "12px" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{ textTransform: "uppercase", fontWeight: 600, fontSize: "12px", color: "#9CA3AF" }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              {proposalSearchData?.length == 0 ? (
                <TableBody>
                  {proposalsInfluencersListData?.data?.data?.map((el: any, index: number) => {
                    return (
                      <>
                        <TableRow
                          style={{
                            border: "none",
                          }}
                          key={index}
                        >
                          <TableCell
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "none",
                              fontWeight: "bold"
                            }}
                          >
                            <img
                              src={el?.attributes?.profile_picture?.url}
                              alt=""
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50px",
                              }}
                            />
                            &nbsp;&nbsp;{el?.attributes?.influencer_id?.influencer_name}
                          </TableCell>
                          <TableCell
                            style={{
                              border: "none",
                              fontWeight: "bold"
                            }}
                          >
                            {el.attributes?.post_per_month}
                          </TableCell>
                          <TableCell
                            style={{
                              border: "none",
                              fontWeight: "bold"
                            }}
                          >
                            KD &nbsp;{el.attributes?.price}
                          </TableCell>
                          <TableCell
                            style={{
                              border: "none",
                              fontWeight: "bold"
                            }}
                          >
                            {el.attributes?.follwers}
                          </TableCell>
                          <TableCell
                            style={{
                              border: "none",
                              fontWeight: "bold"
                            }}
                          >
                            {el.attributes?.contract_days}
                          </TableCell>
                          <TableCell
                            style={{
                              color: "green",
                              display: "flex",
                              alignItems: "center",
                              border: "none"
                            }}
                          >
                            
                            <ArrowUpwardIcon /> &nbsp; {el?.attributes?.roi} %
                          </TableCell>
                          <TableCell
                            style={{
                              border: "none",
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                background: this.changeBackgroundColor(el?.attributes?.status),
                                color: "#fff",
                                borderRadius: "20px",
                                minWidth: 85,
                                fontSize: "10px",

                              }}
                            >
                              {this.handleSetStatus(el?.attributes.status)}
                            </Button>
                          </TableCell>
                          <TableCell
                           data-test-id={`anchor-0`}
                            style={{
                              border: "none",
                              color: "#4eabf8",
                              textDecoration: "underline",
                              cursor: "pointer"
                            }}
                            onClick={(e: any) => { this.handleActionBox(e,el.id,el?.attributes.status,el?.attributes?.influencer_id?.influencer_id, 
                              el?.attributes?.image?.url, el?.attributes?.influencer_id?.influencer_name)
                             }}
                          >
                            <MoreHorizIcon style={{ color: "#9CA3AF" }} />
                          </TableCell>
                          <Menu
                            anchorEl={this.state.openAction}
                            open={Boolean(this.state.openAction)}
                            onClose={this.handleCloseActionBox}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            style={{
                              marginLeft: "-85px", marginTop: "-40px", border: "none",
                            }}

                            PaperProps={{
                              style: { boxShadow: 'rgba(0, 0, 0, 0.1) 0.5px 1px 2px' }
                            }}
                          >
                           {this.state.proposalStatus == "rejected" &&  
                            <MenuItem
                              data-test-id={`viewreason-${index}`}
                              style={{
                                fontSize: '14px',
                                fontWeight: 500,
                                color: "#111827",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                this.handleOpenViewReason()
                              }
                            >
                             View reason 
                            </MenuItem>
                          }
                            <MenuItem
                              data-test-id={`contractTime-${index}`}
                              style={{
                                color: "#111827",
                                fontWeight: 500,
                                fontSize: '14px',
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                this.handleContractTime()
                              }}
                            >
                              Contract timeline
                            </MenuItem>

                          </Menu>
                        </TableRow>
                      </>
                    )
                  })}
              <div>
                <ViewReason navigation={undefined} handleClose={this.handleViewReasonClose} show={this.state.openViewReason} viewID={this.state.viewReasonId} accountId={this.state.contractTimeId} />
               </div>
               <div>
                <ContractTime navigation={undefined} handleClose={this.handleContractTimeClose} show={this.state.openContractTime}
                    contTimeID={this.state.contractTimeId} profilePic={this.state.profileImg} userName={this.state.influencerName}/>
               </div>
                </TableBody>
              ) : (
                <TableBody>
                  {proposalSearchData?.map((el: any, index: number) => {
                    return (
                      <>
                        <TableRow style={{ border: "none" }} key={index}>

                          <TableCell style={{ display: "flex", alignItems: "center", border: "none", fontWeight: "bold" }}>

                            <img src={el?.attributes?.image?.url} alt=""
                              style={{ width: "25px", height: "25px", borderRadius: "50px" }}
                            />
                            &nbsp;&nbsp;{el?.attributes?.influencer_id?.influencer_name}

                          </TableCell>
                          <TableCell style={{ border: "none", fontWeight: "bold" }}>
                            {el.post_per_month}                          </TableCell>
                          <TableCell style={{ border: "none", fontWeight: "bold" }}>
                             KD{el.price}
                          </TableCell>
                          <TableCell
                            style={{ border: "none", fontWeight: "bold" }}
                          >
                            {el.follwers}
                          </TableCell>
                          <TableCell
                            style={{
                              border: "none",
                              fontWeight: "bold"
                            }}
                          >
                            {el.contract_days}
                          </TableCell>
                          <TableCell style={{ color: "green", display: "flex", alignItems: "center", border: "none" }} >
                            {" "}
                            <ArrowUpwardIcon /> &nbsp; {el?.attributes?.roi} %{" "}

                          </TableCell>
                          <TableCell style={{border: "none"}}>
                            <Button
                              variant="contained"
                              style={{
                                color: "#fff",
                                borderRadius: "20px",
                                background: this.changeBackgroundColor(el?.attributes?.status),
                                fontSize: "10px",
                              }}
                              >
                              {this.handleSetStatus(el?.attributes?.status)}
                            </Button>
                          </TableCell>

                          <TableCell
                            style={{ border: "none", color: "#4eabf8", textDecoration: "underline", cursor: "pointer" }}
                            data-test-id ={`searchAction-${index}`}

                            onClick={(e: any) => {
                               this.handleActionBox(e,el.id,el?.attributes?.status,el?.attributes?.influencer_id?.influencer_id,
                                el?.attributes?.image?.url, el?.attributes?.influencer_id?.influencer_name
                                ) }}
                          >
                            <MoreHorizIcon style={{ color: "#9CA3AF" }} />
                          </TableCell>
                          <Menu
                          
                            anchorEl={this.state.openAction}
                            onClose={this.handleCloseActionBox}
                            open={Boolean(this.state.openAction)}
                            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            getContentAnchorEl={null}

                            style={{
                              marginLeft: "-85px",
                              marginTop: "-40px",
                              border: "none"
                            }}

                            PaperProps={{ style: { boxShadow: 'rgba(0, 0, 0, 0.1) 0.5px 1px 2px' } }}
                          >
                            <MenuItem
                              style={{ fontSize: '14px', fontWeight: 500, color: "#111827", cursor: "pointer" }}
                              data-test-id = {`openView-${index}`}
                              onClick={() => this.handleOpenViewReason()}
                            >View reason
                            </MenuItem>
                            <MenuItem
                              data-test-id={`contracttime-${index}`}
                              style={{ color: "#111827", fontWeight: 500, fontSize: '14px', cursor: "pointer" }}
                              onClick={() => { this.handleContractTime() }}
                            >Contract timeline
                            </MenuItem>
                          </Menu>

                        </TableRow>
                      </>
                    )
                  })}
                </TableBody>
              )}

            </Table>
          </TableContainer>

        </Box>
        {proposalsInfluencersListData?.data?.data?.length > 0 &&
          <Box style={webPage.paginationBox as React.CSSProperties}>
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px",marginRight: "0px" }}>
              <Typography>
                Rows per page
              </Typography>
              <FormControl variant="outlined" >
                {!this.state.perRow &&
                  <InputLabel htmlFor="page" shrink={false}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "20%",
                    transform: "translate(-50%, -50%)",
                  }}
                  >
                    10
                  </InputLabel>
                }
                <Select
                  style={{
                    border: "1px solid #FEE7D7",
                    height: "38px",
                    width: "50%",
                    borderRadius: "4px",
                    background: "#F9FAFB",
                    paddingLeft: "7px",
                    minWidth:"0px",
                    paddingRight: "0px",
                    whiteSpace: "break-spaces"
                  }}
                  id="page"
                  data-test-id = "perrow"
                  value={this.state.perRow}
                  onChange={(e: any) => this.handleChangeRow(e)}
                >
                  {[10,20,50,100].map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <section className={classes.pagination}>
                <button className={`${proposalsInfluencersListData?.meta?.current_page === 1 ? classes.opactiy_0 : classes.pointer}`} disabled={proposalsInfluencersListData?.meta?.current_page === 1} onClick={() => this.nextPageData(proposalsInfluencersListData.meta, "firstpage")} data-testid="firstpage">
                  <img src={group_arrow2} alt="" />
                </button>
                <button className={`${proposalsInfluencersListData?.meta?.current_page === 1 ? classes.opactiy_0 : classes.pointer}`} disabled={proposalsInfluencersListData?.meta?.current_page === 1} onClick={() => this.nextPageData(proposalsInfluencersListData.meta, "previouspage")} data-testid="previouspage">
                  <img src={group_arrow} alt="" />
                </button>
                <span style={{ margin: "0 15px" }}>Shows {proposalsInfluencersListData?.meta?.current_page} out of {proposalsInfluencersListData?.meta?.total_pages}</span>
                <button className={`${proposalsInfluencersListData?.meta?.next_page === null ? classes.opactiy_0 : classes.pointer}`} disabled={proposalsInfluencersListData?.meta?.next_page === null} onClick={() => this.nextPageData(proposalsInfluencersListData.meta, "nextpage")} data-testid="nextpage">
                  <img src={group_arrow3} alt="" />
                </button>
                <button className={`${proposalsInfluencersListData?.meta?.next_page === null ? classes.opactiy_0 : classes.pointer}`} disabled={proposalsInfluencersListData?.meta?.next_page === null} onClick={() => this.nextPageData(proposalsInfluencersListData.meta, "lastpage")} data-testid="lastpage">
                  <img src={group_arrow4} alt="" />
                </button>
              </section>
            </Box>
          </Box>
        } 
      </React.Fragment>
    );
  }
  // Customizable Area End
}
export default withStyles(Styles)(InfluencersProposals);
// Customizable Area Start
const webPage = {
  diaHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid lightGrey",
    padding: "18px 30px"
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    color: "blue",
  } as React.CSSProperties,

  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: "10px 10px 20px 10px"
    // width: "fit-content",
  } as React.CSSProperties,
  formControl: {
    marginTop: 10,
    minWidth: 120,
  } as React.CSSProperties,

  type: {
    color: "#9CA3AF",
    fontSize: "14px",
    marginLeft: "10px"
  },

  typeBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "20px",
    marginBottom: "30px"
  },

  type1: {
    color: "#111827",
    marginLeft: "20px",
    fontSize: "16px"
  },

  paginationBox: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "60px",
    right: "35px",
    marginBottm: "100px",
  }
  
};

// Customizable Area End

