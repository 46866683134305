import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import ToastMessage from "../../../components/src/ToastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  patchAPIData: (formData: any) => void
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedOptions: any;
  switchButton: {
    waitinglistSwitch: boolean,
    visibleTableLayout: boolean,
    visible: boolean,
  },
  selectedDate: {
    selectFormDate: string,
    selectToDate: string,
    waitinglistDistance: string,
  },
  reservationCheckbox: {
    reservConfirm: boolean;
    Phone: boolean,
    Email: boolean,
    EitherPhoneOrEmail: boolean,
    FullName: boolean,
    AutoAssignTable: boolean,
    multiReservation: boolean,
    problemReservation: boolean,
    coverdBookBySeating: boolean
  },
  isChecked: boolean;
  disableMenu: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReservationVenueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ReservationSettingCallID: string = ""
  PatchReservationCallID: string = ""
  getDisableMenuCallId: string = ""
  // Customizable Area End


  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      selectedOptions: {
        MaximumWaitTime: '',
        WithInterval: '',
        Waitlist: '',
        radioselect: ['']
      },
      switchButton: {
        waitinglistSwitch: false,
        visibleTableLayout: false,
        visible: false,
      },
      selectedDate: {
        selectFormDate: '',
        selectToDate: '',
        waitinglistDistance: '',
      },
      reservationCheckbox: {
        reservConfirm: false,
        Phone: false,
        Email: false,
        EitherPhoneOrEmail: false,
        FullName: false,
        AutoAssignTable: false,
        multiReservation: false,
        problemReservation: false,
        coverdBookBySeating: false
      },
      disableMenu: false,
      isChecked: false,
    };

    // Customizable Area Start
    // Customizable Area End


    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (!apiRequestCallId || !responseJson) {

      ToastMessage("error", "Something Went Wrong");
      return;
    }
    if (apiRequestCallId === this.ReservationSettingCallID) {
      this.hangleResForReservationSetting(responseJson)
    }
    else if (apiRequestCallId === this.getDisableMenuCallId) {
      if (responseJson.data?.attributes?.disable_menu === false) {
        ToastMessage("success", "Menu enabled successfully for customers")
      } else {
        ToastMessage("success", " Menu disabled successfully for customers")
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.GetReservationSetting();
    this.GetMenuDisbleReservationSetting();
  }

  hangleResForReservationSetting = (responseJson: any) => {
    const resData = responseJson.data?.attributes;
    if (resData) {
      this.setState({
        isChecked: resData?.disable_online_reservation,
        selectedDate: {
          ...this.state.selectedDate, selectFormDate: resData.disable_online_reservation_from, waitinglistDistance: resData.maximum_distance_for_waitinglist,
          selectToDate: resData.disable_online_reservation_to,
        }, switchButton: { ...this.state.switchButton, waitinglistSwitch: resData.enable_waiting_list, visibleTableLayout: resData.enable_table_layout },
        reservationCheckbox: {
          ...this.state.reservationCheckbox, reservConfirm: resData.auto_confirm_reservation, Phone: resData.phone, Email: resData.email,
          EitherPhoneOrEmail: resData.phone_or_email, FullName: resData.full_name, AutoAssignTable: resData.auto_assign_table, multiReservation: resData.multiple_reservation_guest,
          problemReservation: resData.show_problem_reservation, coverdBookBySeating: resData.covers_booked_byseating_area_time
        },
        selectedOptions: { ...this.state.selectedOptions, MaximumWaitTime: resData.maximum_wait_time, WithInterval: resData.with_interval, Waitlist: resData.waitlist_auto_remove_timing, radioselect: resData.default_seating_area_internal_search }
      })
    }
  }
  handlecheckboxchange = (e: any) => {
    this.setState({ isChecked: e.target.checked })
    if (this.state.isChecked === true) {
      this.setState((prevState) => ({
        selectedDate: {
          ...prevState.selectedDate,
          selectFormDate: '',
          selectToDate: '',
        },
      }));
    }

  }

  handlereservationCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    this.setState((prevState) => ({
      reservationCheckbox: {
        ...prevState.reservationCheckbox,
        [name]: checked,
      },
    }));
  }

  handlerChangeDisbleMEnu = (event: any) => {
    this.setState({ disableMenu: event.target.checked });
  }

  changeSwitchBtn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    this.setState((prevState) => ({
      switchButton: {
        ...prevState.switchButton,
        [name]: checked,
      },
    }));
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {

    if (prevState.isChecked !== this.state.isChecked || prevState.selectedDate !== this.state.selectedDate || prevState.switchButton !== this.state.switchButton || prevState.reservationCheckbox !== this.state.reservationCheckbox || prevState.selectedOptions !== this.state.selectedOptions || prevState.disableMenu !==this.state.disableMenu) {
      this.props.patchAPIData(this.state);
    }
  }
  handleselectChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      selectedOptions: {
        ...prevState.selectedOptions,
        [name]: value,
      },
    }));
  };

  handleRadioChange = (event: any) => {
    const selectedValue = event.target.value;

    this.setState((prevState) => ({
      selectedOptions: {
        ...prevState.selectedOptions,
        radioselect: [selectedValue],
      },
    }));
  };

  handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      selectedDate: {
        ...prevState.selectedDate,
        [name]: value,
      },
    }));
  }

  GetReservationSetting = () => {
    const headerData = {
      "token": localStorage.getItem("authToken")
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: configJSON.reservationSettingURL,
      httpMethod: 'GET'
    })
    this.ReservationSettingCallID = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  onlyEnterNumber = (e: any) => {
    const validChars = '0123456789';
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  }

  GetMenuDisbleReservationSetting = () => {
    const headerData = {
      "token": localStorage.getItem("authToken")
    };
    const req = apiCall({
      httpBody: {},
      header: headerData,
      url: `bx_block_location/restaurant_locations/${localStorage.getItem("locationID")}`,
      httpMethod: 'GET'
    })
    this.getDisableMenuCallId = req.messageId;
    runEngine.sendMessage(req.id, req);
  }

  // Customizable Area End
}
