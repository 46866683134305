import React from 'react'

// Customizable Area Start
import { Button, Grid, Hidden } from '@material-ui/core'
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import { withStyles } from '@material-ui/core/styles';
import { sliderWeb1, sliderWeb2, sliderWeb3 } from './assets'
// Customizable Area End

// Customizable Area Start
const sliderdata = [
    {
        "img": sliderWeb1,
        "title": "Follow Favourite Foodie",
        "para": "Connect with Fudu Community and explore the world of food with friends or food influencers you like."
    },
    {
        "img": sliderWeb2,
        "title": "Earn Money",
        "para": "Earn money by sharing and endorsing a restaurant, the money earned will be transfered to your bank account."
    },
    {
        "img": sliderWeb3,
        "title": "Easier to Order Food",
        "para": "You can order food whenever and wherever you are, Fudu offers dine in, delivery, drive thru, and take away orders."
    }

]
const Styles = (theme: any) => ({
    root: {
        padding: "50px 0",
        overflowX: "hidden"
    },
    testimonialWrapper: {
        maxWidth: "420px",
        margin: "0 auto",
        width: "100%",
        textAlign: "center"
    },
    testimonialText: {
        color: "#4B5563",
    },
    testimonialtitle: {
        fontSize: "24px",
        fontWeight: "500",
        margin: "34px 0 15px",
        color: "#111827",
    }
});
// Customizable Area End

// Customizable Area Start
class CarouselDisplay extends React.Component {
    static propTypes: { classes: PropTypes.Validator<object>; };

    render() {
        {/* @ts-ignore */ }
        const { classes, children } = this.props;
        return (
            <>
                <Grid container>
                    <Hidden xsDown>
                        <Grid item lg={6} sm={6}  className={classes.root}>
                            {/* @ts-ignore */}
                            <Carousel
                                // duration={"1500"}
                                navButtonsAlwaysInvisible={true}
                                IndicatorIcon={<Button></Button>}
                                indicatorIconButtonProps={{
                                    style: {
                                        margin: "2px",
                                        width: "30px",
                                        height: "3px",
                                        borderRadius: "4px",
                                        backgroundColor: "#F2F2F2",
                                    }
                                }}
                                activeIndicatorIconButtonProps={{
                                    style: {
                                        backgroundColor: '#096FFF'
                                    }
                                }}
                            >
                                {sliderdata.map((data) => {
                                    return (
                                        <div className={classes.testimonialWrapper}>
                                            <div>
                                                <img src={data.img} alt="df" width="330" height="350" />
                                            </div>
                                            <div className={classes.testimonialText}>
                                                <h3 className={classes.testimonialtitle}>{data.title}</h3>
                                                <p style={{ minHeight: "60px" }}>{data.para}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </Grid>
                    </Hidden>

                    <Grid item lg={6} sm={6} xs={12}>
                        <main>{children}</main>
                    </Grid>
                </Grid>
            </>
        )
    }
}

CarouselDisplay.propTypes = {
    classes: PropTypes.object.isRequired,
};
{/* @ts-ignore */ }
export default withStyles(Styles)(CarouselDisplay);
// Customizable Area End
