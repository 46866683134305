import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { v4 as uuidv4 } from 'uuid';
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose: () => void;
  show: boolean;
  modalType: any;
  timeData: any;
  getReservationData: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  AllSwitch: {
    sunday: boolean,
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
  },
  SelectTime: any
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditReservationServingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ReservationServingDayDataCallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      AllSwitch: {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      },
      SelectTime: {
        sunday: [{ id: 0, value: { start: '', end: '' } }],
        monday: [{ id: 0, value: { start: '', end: '' } }],
        tuesday: [{ id: 0, value: { start: '', end: '' } }],
        wednesday: [{ id: 0, value: { start: '', end: '' } }],
        thursday: [{ id: 0, value: { start: '', end: '' } }],
        friday: [{ id: 0, value: { start: '', end: '' } }],
        saturday: [{ id: 0, value: { start: '', end: '' } }],
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    let dummyAllS = { ...this.state.AllSwitch };
    let dummySelectTime = { ...this.state.SelectTime };
    this.props.timeData.map((week: { day: any; day_id: string, status: string; servingHours: { hour_id: any; openingTimeStr: any; closingTimeStr: any; }[]; }) => {
      dummyAllS = { ...dummyAllS, [`${week.day}`]: week.status === "opened" };
      dummySelectTime = {
        ...dummySelectTime, [`${week.day}`]: week.servingHours.map((time: { hour_id: any; openingTimeStr: any; closingTimeStr: any; }) => {
          return ({ parent_id: week.day_id, id: time.hour_id, value: { start: this.formateDateStr(time.openingTimeStr), end: this.formateDateStr(time.closingTimeStr) } });
        })
      };

    });
    this.setState({ AllSwitch: dummyAllS, SelectTime: dummySelectTime })
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (apiRequestCallId === this.ReservationServingDayDataCallID) {
      this.props.getReservationData();
      this.props.handleClose();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  formateDateStr = (dateString: string) => {
    const date = new Date(dateString);
    const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    const adjustedDate = new Date(utcTime + (5.5 * 60 * 60000));

    const hours = adjustedDate.getHours();
    const minutes = adjustedDate.getMinutes();
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const timeString = `${formattedHours}:${formattedMinutes}`;

    return timeString;
  }
  handleSwitchToggle = (day: any) => {
    this.setState((prevState: any) => ({
      AllSwitch: {
        ...prevState.AllSwitch,
        [day]: !prevState.AllSwitch[day],
      },
    }));
  };
  addHours = (day: string) => {
    const { SelectTime } = this.state;
    this.setState({
      SelectTime: { ...SelectTime, [day]: [...SelectTime[day], { id: uuidv4(), value: {} }] }
    });
  }

  deleteField = (day: any, kId: any) => {
    this.setState((prevState) => {
      const dataArr = prevState.SelectTime[day];
      dataArr.splice(kId, 1);
      const updatedSelectTime = {
        ...prevState.SelectTime,
        [day]: dataArr,
      };

      return { SelectTime: updatedSelectTime };
    });
  };

  handleChangeTime = (e: any, day: any, index: any, field: any) => {
    const updatedTimes = [...this.state.SelectTime[day]];
    const { value } = e.target;

    if (field === 'startTime') {
      updatedTimes[index].value.start = value;
    } else if (field === 'endTime') {
      updatedTimes[index].value.end = value;
    }

    this.setState(prevState => ({
      SelectTime: {
        ...prevState.SelectTime,
        [day]: [...updatedTimes]
      }
    }));
  };

  getCategory = () => {
    if (this.props.modalType.includes("Reservations")) {
      return 0;
    } else if (this.props.modalType.includes("Pick")) {
      return 1;
    } else if (this.props.modalType.includes("Delivery")) {
      return 3;
    } else if (this.props.modalType.includes("Drive")) {
      return 4
    } else {
      return '';
    }
  }

  PostReservationServingDayData = async () => {
    const { AllSwitch, SelectTime } = this.state;

    let tokenValue = await localStorage.getItem('authToken');
    const headers = {
      token: tokenValue,
      'Content-Type': 'application/json'
    };

    const httpBody = {
      "serving_days_attributes": configJSON.reservationServingDay.filter((day: string) => { const firstData = SelectTime[`${day as keyof S['AllSwitch']}`][0]; return (firstData.value.start && firstData.value.end) }).map((day: any) => {
        return ({
          ...{
            "category": this.getCategory(),
            "status": AllSwitch[day as keyof S['AllSwitch']] ? "opened" : "closed",
            "day": day,
            "serving_hours_attributes": SelectTime[`${day as keyof S['AllSwitch']}`].map((time: { id: string, value: { start: any; end: any; }; }) => {
              return ({
                ...{
                  "opening_time": time.value.start,
                  "closing_time": time.value.end
                }, ...(this.props.modalType.includes("Edit") ? { "id": time.id } : {})
              })
            })
          }, ...(this.props.modalType.includes("Edit") ? { id: SelectTime[`${day as keyof S['AllSwitch']}`][0].parent_id } : {})
        })
      })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_location/serving_days'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.ReservationServingDayDataCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End

}

