Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Get Location";
exports.labelSorryText = "Sorry!";
exports.labelSorryBodyText = "There is no VR Centre in your city";
exports.cityListApiEndPoing = "bx_block_location/cities";
exports.updateProfileEndPoint = "bx_block_profile/profiles";
exports.validationApiMethodType = "GET";
exports.getApiMethodType = "GET";
exports.updateMethodType = "PUT";
exports.btnGetLocationTitle = "Get Location";
exports.btnDenyLocationTitle = "Do not Allow";
exports.btnSelectCenterTitle = "Select a Centre";
exports.errorTitle = "Error";

// Customizable Area Start
exports.phoneRegxReciver = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
exports.GetAllLocationData = "bx_block_location/restaurant_locations";
exports.createFeature = "account_block/features"
exports.singleFeatureLocation = "account_block/current_restaurant_feature?restaurant_location_id="
exports.deleteMethodType = "DELETE";
exports.patchMethodType = "PATCH";
exports.putMethodType = "PUT";
exports.noSearchMessage = "No result found"
exports.placeholderMessage = 'Search a restaurant'
// Customizable Area End
