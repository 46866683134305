// Customizable Area Start
import React from "react";
import {
    Typography,
    Grid,
    Button,
    Select,
    FormControl,
    RadioGroup,
    Radio,
    MenuItem,
    FormLabel,
    FormControlLabel
} from "@material-ui/core";

import Box from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Card from "@material-ui/core/Card";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { RedDeleteImg } from "./assets"
import {SwitchStyle} from "../../AdminConsole/src/AdminConsole.web"

// Customizable Area End

// Customizable Area Start
import ShowDiscountCouponController, { Props } from "./ShowDiscountCouponController.web";
// Customizable Area 


const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#52d869',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const currentDate = new Date();

export default class ShowDiscountCoupon extends ShowDiscountCouponController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    render() {

        return (

            <React.Fragment>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={this.props.show}
                    onClose={this.props.handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <ToastContainer
                        position="top-center"
                        autoClose={3500}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        style={{ zIndex: 9999 }}
                        rtl={false}
                    />

                    <DialogTitle id="max-width-dialog-title" style={{ padding: "0" }}>
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <IconButton
                                aria-label="close"
                                style={webStyle.closeButton}
                                onClick={
                                    this.props.handleClose
                                }
                            >
                                <CancelIcon style={{ color: "#096FFF" }} />
                            </IconButton>
                            <Box style={{ textAlign: "center" }}>
                                <Typography variant="h6"
                                    style={{ fontFamily: "Inter", fontWeight: "bold", fontSize: "32px", color: "#111827", marginTop: "-20px" }}>
                                    Create Discount
                                </Typography>
                                <Typography variant="body2" style={{
                                    fontFamily: "Inter", fontSize: "24px", color: "#515961", fontWeight: 500,
                                    marginBottom: "20px"
                                }}>
                                    Create a discount coupon
                                </Typography>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Card style={webStyle.postLinkModal}>
                            <Grid
                                container
                                style={{
                                    padding: "10px",
                                }}
                                className="profile-image"
                            >
                                <Grid item xs={12} lg={5}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "column",
                                            marginLeft: "40px",
                                            marginBottom:'15px',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                alignSelf: "start",
                                                marginBottom: "14px",
                                                color: "#9CA3AF"
                                            }}
                                        >
                                            Attach image for banner
                                        </Typography>
                                        <Box
                                            style={webStyle.mainImgBox}
                                        >
                                            <Box style={{ width: "100%" }}>
                                                {this.state.showCouponImgData ?
                                                    (<Box style={{ border: "none", borderRadius: "10px" }}>
                                                        <img
                                                            src={this.state.showCouponImgData.url}
                                                            style={{
                                                                objectFit: "cover",
                                                                borderRadius: "8px",
                                                                width: "100%",
                                                                height: "100%",
                                                                padding: 0
                                                            }}
                                                        />
                                                    </Box>)
                                                    :
                                                    (<Box >
                                                        {this.state.imageFile ? <img
                                                            src={URL.createObjectURL(
                                                                new Blob([this.state.imageFile], { type: 'image/png' })
                                                            )}
                                                            style={{
                                                                borderRadius: "8px",
                                                                objectFit: "cover",
                                                                height: "100%",
                                                                width: "100%",
                                                                padding: 0
                                                            }}
                                                        /> :
                                                            <Box style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                border: "2px dashed #096FFF",
                                                                alignItems: "center",
                                                                flexDirection: "column",
                                                                gridGap: "5px",
                                                                height: "100%",
                                                                width: "100%",
                                                                background: "#ECF0FF",
                                                                borderRadius: "10px"
                                                            }}>
                                                                <Button
                                                                    component="label"
                                                                    style={webStyle.Postbtn}
                                                                >
                                                                    +
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        id="image-upload"
                                                                        value={this.state.imageFile}
                                                                        hidden
                                                                        onChange={this.ReaderImg}
                                                                    />
                                                                </Button>
                                                            </Box>
                                                        }
                                                    </Box>)
                                                }
                                            </Box>


                                        </Box>
                                        <Box style={{ display: "flex" }}>
                                            <Box style={{ marginTop: "10px" }}>
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: "#9CA3AF",
                                                        fontFamily: "inter"
                                                    }}
                                                >
                                                    Supported Image type :{""}
                                                    <span style={{ color: "#4B5563" }}>.jpg, .png</span>
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        color: "#9CA3AF",
                                                        fontFamily: "inter"
                                                    }}
                                                >
                                                    Maximum image size :{" "}
                                                    <span style={{ color: "#4B5563" }}>5MB per image</span>
                                                </Typography>
                                            </Box>
                                            {(this.state.showCouponImgData || this.state.imageFile) &&
                                                <Box style={{ marginLeft: "5px" }}>

                                                    <Button
                                                        component="label"
                                                        style={{ marginTop: "-13px" }}
                                                    >
                                                        <EditIcon color="primary" />
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            id="image-upload"
                                                            // value={this.state.imageFile}
                                                            hidden
                                                            onChange={this.ReaderImg}
                                                        />
                                                    </Button>


                                                    <img src={RedDeleteImg} style={{ marginTop: "20px", marginLeft: "-5px" }}
                                                        onClick={() => this.setState({ imageFile: "", showCouponImgData: "" })}
                                                    />
                                                </Box>
                                            }
                                        </Box>

                                    </Box>
                                </Grid>

                                <Grid item xs={12} lg={7}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend" style={webStyle.formlabel}>
                                                    Category*
                                                </FormLabel>
                                                <RadioGroup
                                                    name="radioValue"
                                                    value={this.state.showCouponData?.coupon_category}
                                                    onChange={(e: any) => {
                                                        this.setState({ showCouponData: { ...this.state.showCouponData, coupon_category: e.target.value } })
                                                    }}
                                                    className="showCategoryRadio"
                                                >
                                                    <FormControlLabel value="breakfast" control={<Radio color="primary" />}
                                                        checked={this.state.showCouponData.coupon_category === "breakfast" ? true : false} label="Breakfast" />
                                                    <FormControlLabel value="lunch" control={<Radio color="primary" />}
                                                        checked={this.state.showCouponData.coupon_category === "lunch" ? true : false} label="Lunch" />
                                                    <FormControlLabel value="dinner" control={<Radio color="primary" />}
                                                        checked={this.state.showCouponData.coupon_category === "dinner" ? true : false} label="Dinner" />
                                                    <FormControlLabel value="all_day" control={<Radio color="primary" />}
                                                        checked={this.state.showCouponData.coupon_category === "all_day" ? true : false} label="All Day" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormLabel component="legend" style={webStyle.formlabel}>
                                                Coupon Name*
                                            </FormLabel>
                                            <FormControl style={webStyle.messageField}>
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    id="CouponName"
                                                    placeholder="Enter coupon name"
                                                    type="text"
                                                    style={{
                                                        fontFamily: "Verdana",
                                                    }}
                                                    name="CouponName"
                                                    value={this.state.showCouponData?.coupon_name}
                                                    onChange={(e: any) => {
                                                        this.setState({ showCouponData: { ...this.state.showCouponData, coupon_name: e.target.value } })
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <FormLabel component="legend" style={webStyle.formlabel2}>
                                            Discount Amount or Percentage*
                                        </FormLabel>
                                        <Grid container style={{ display: "flex" }} spacing={3}>
                                            <Grid item lg={4} xs={12} style={{paddingBottom:"0px"}}>
                                                <Select
                                                 data-test-id = "type_select"
                                                    id="sel"
                                                    style={{
                                                        border: "1px solid gray",
                                                        height: "40px", width: "100%",
                                                        marginTop: "10px", marginRight: "15px",
                                                        borderRadius: "4px", background: "#F9FAFB",
                                                        paddingLeft: "10px"

                                                    }}
                                                    label="% percentage"
                                                    value={this.state.showCouponData?.discount_type ?? ""}
                                                    onChange={(e: any) => {
                                                        this.setState({ showCouponData: { ...this.state.showCouponData, discount_type: e.target.value } })

                                                    }}
                                                >
                                                    {DiscountPercentage.map((value: any, index: any) => {
                                                        return (
                                                            <MenuItem value={value}>
                                                                {value}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </Grid>
                                            {/* <Grid item lg={1}/> */}
                                            <Grid item lg={8} xs={12} style={{paddingBottom: "0px"}}>
                                                <FormControl
                                                    style={{ background: "#F9FAFB", width: "90%", marginTop: "10px" }}>
                                                    <TextField
                                                        data-test-id="txtInputConfirmPassword"
                                                        variant="outlined"
                                                        size="small"
                                                        id="discounts"
                                                        placeholder="Enter amount/percentage"
                                                        type="text"
                                                        style={{
                                                            fontFamily: "Verdana",
                                                            width: "99%"
                                                        }}
                                                        name="discounts"
                                                        value={this.state.showCouponData?.discount}
                                                        onChange={(e: any) => {
                                                            this.handleChangeDiscount(e)
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                              {/* <Grid container spacing={3}> */}
                                                <Grid item xs={12} lg={6} style={{paddingTop:"0px"}} >
                                                    <FormLabel component="legend" style={webStyle.formlabel2} >
                                                        Upto Amount
                                                    </FormLabel>
                                                    <FormControl
                                                        style={webStyle.smallMessageField}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            id="upto_amount"
                                                            placeholder="Max. discount amount"                                 
                                                            name="upto_amount"
                                                            value={this.state.showCouponData?.up_to_ammount}
                                                            onChange={(e: any) => {
                                                                this.handleChangeUptoAmount(e)
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} lg={6} className="max-quantity">
                                                    <FormLabel component="legend" style={webStyle.formlabel2} >
                                                        Max. Quantity*
                                                    </FormLabel>
                                                    <FormControl
                                                        style={webStyle.smallMessageField}
                                                    >
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            id="max_quantity"
                                                            placeholder="No. of times coupon can be used"
                                                            // style={{width: "292px", fontFamily: "inter",}}
                                                            name="max_quantity"
                                                            value={this.state.showCouponData?.max_quantity}
                                                            onChange={(e: any) => {
                                                                this.handleChangeMaxQuantity(e)
                                                            }}
                                                            // className="max-amount"
                                                        />
                                                    </FormControl>
                                                </Grid>
                                              {/* </Grid> */}
                                        </Grid>
                                        <Grid item style={{ marginTop: "10px" }}>
                                            <FormLabel component="legend" style={webStyle.formlabel}>
                                                Coupon Code*
                                            </FormLabel>
                                            <FormControl style={webStyle.messageField}>
                                                <TextField
                                                    data-test-id="txtInputConfirmPassword"
                                                    variant="outlined"
                                                    size="small"
                                                    id="Coupon_code"
                                                    placeholder="Enter Coupon Code"
                                                    type="text"
                                                    style={{
                                                        fontFamily: "Verdana",
                                                    }}
                                                    name="Coupon_code"
                                                    fullWidth
                                                    value={this.state.showCouponData?.code}
                                                    onChange={(e: any) => {
                                                        this.setState({ showCouponData: { ...this.state.showCouponData, code: e.target.value.toUpperCase() } })
                                                    }}
                                                />
                                            </FormControl>
                                            {this.state.showCodeError && this.state.CouponCode &&
                                                <p style={{ color: "red", fontSize: "12px", marginBottom: "-10px" }}>
                                                    Coupon code {this.state.showCodeErrorData}
                                                </p>
                                            }
                                        </Grid>
                                        <Grid container direction="row" style={{display:'flex',width:'96%'}}>
                                            <Grid item xs={12} lg={6}>
                                                <FormLabel component="legend" style={webStyle.formlabel3} >
                                                    Offer Valid From*
                                                </FormLabel>
                                                <input
                                                    style={webStyle.dateField}
                                                    type="date"
                                                    id = "offer_from"
                                                    value={String(this.state.showCouponData?.valid_from).length < 12 ? this.state.showCouponData?.valid_from : this.godate(this.state.showCouponData?.valid_from)}
                                                    onChange={(e: any) => {
                                                        // this.setState({ showCouponData: { ...this.state.showCouponData, valid_from: e.target.value } })


                                                        this.handleUpdatedDateValidation(e)
                                                    }}
                                                    min={currentDate.toISOString().split('T')[0]}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <FormLabel component="legend" style={webStyle.formlabel3} >
                                                    Offer Valid Upto*
                                                </FormLabel>
                                                <input
                                                    style={webStyle.dateField}
                                                    type="date"
                                                    id= "offer_upto"
                                                    value={String(this.state.showCouponData?.valid_to).length < 12 ? this.state.showCouponData?.valid_to : this.godate(this.state.showCouponData?.valid_to)}

                                                    onChange={(e: any) => {
                                                        // this.setState({ showCouponData: { ...this.state.showCouponData, valid_to: e.target.value } })
                                                        this.handleUpdatedDateValidationTo(e)
                                                    }}
                                                    min={currentDate.toISOString().split('T')[0]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="column">
                                            <FormLabel component="legend" style={webStyle.formlabel3} >
                                                Coupon Description*
                                            </FormLabel>

                                            <TextareaAutosize
                                                aria-label="maximum height"
                                                minRows={5}
                                                placeholder="Enter Coupon"
                                                id="description"
                                                value={this.state.showCouponData?.description}
                                                onChange={(e: any) => {
                                                    this.setState({ showCouponData: { ...this.state.showCouponData, description: e.target.value } })
                                                }}
                                                name="description"
                                                style={{
                                                    maxWidth: "93%",
                                                    minWidth: "89%",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#F9FAFB",
                                                    border: "0.2px solid gray",
                                                    fontFamily: "inter",
                                                    padding: "11px"
                                                }}
                                            />
                                        </Grid>
                                        <Grid container style={{ display: "flex", marginTop: "20px" }}>
                                            <Typography style={webStyle.switchBtn}>
                                                Coupon Status?
                                            </Typography>
                                            <FormGroup>
                                                <FormControlLabel
                                                    //  @ts-ignore
                                                    control={<SwitchStyle checked={this.state.showCouponData?.coupon_status ? true : false}
                                                        onChange={this.handleChange}
                                                        name="checkedB"
                                                    // name= {this.state.showCouponData?.coupon_status}
                                                    />}
                                                    label="Active"
                                                    id="switch"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid container justifyContent="space-between"
                                            style={{ marginTop: "10px", marginBottom: "10px" }}
                                            xs={12} spacing={2}
                                        >
                                            <Grid item xs={12} lg={6}>
                                                <Button variant="contained" style={{
                                                    width: "90%",
                                                    background: "transparent",
                                                    borderRadius: "10px",
                                                    color: "#096FFF",
                                                    height: "54px",
                                                    border: "1px solid #096FFF",
                                                    boxShadow: "none",
                                                    marginLeft: "5px",
                                                    textTransform: "none"
                                                }}
                                                    onClick={
                                                        this.props.handleClose
                                                    }
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Button
                                                    data-test-id="Edit"
                                                    style={{
                                                        height: "54px",
                                                        width: "90%",
                                                        marginLeft:'5px',
                                                        borderRadius: "10px",
                                                        background: "#096FFF",
                                                        color: "#fff",
                                                        textTransform: "none"
                                                    }}
                                                    variant="contained"
                                                    type="submit"
                                                    onClick={this.editDiscountCouponCall}
                                                >
                                                    Update Coupon
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </DialogContent>
                </Dialog>
            </React.Fragment>

        );
    }
    // Customizable Area End
}

// Customizable Area Start
const DiscountPercentage = ["flat", "percentage"]
// Customizable Area End

// Customizable Area Start
const webStyle = {
    butnStyle: {
        height: "54px",
        width: "90%",
        marginLeft: "-25px",
        borderRadius: "10px",
        background: "#096FFF",
        color: "#fff",
        textTransform: "none"
    },
    postLinkModal: {
        boxShadow: "none",
        border: "1px solid #B6B5BB",
        borderRadius: "10px",
        marginBottom: "14px"
    },
    cancelbutnStyle: {
        width: "90%",
        background: "transparent",
        borderRadius: "10px",
        color: "#096FFF",
        height: "54px",
        border: "1px solid #096FFF",
        boxShadow: "none",
        marginLeft: "5px",
        textTransform: "none"
    },

    mainImgBox: {
        height: 310,
        width: "82%",
        borderRadius: "10px",
        padding: "0px",
    },
    messageField: {
        background: "#F9FAFB",
        width: "93%",
        marginTop: "10px",
    },
    Postbtn: {
        background: "transparent",
        border: "none",
        color: "#096FFF",
        width: "150px",
        fontSize: "50px",
    },
    smallMessageField: {
        marginTop: "10px",
        backgroundColor: "#F9FAFB",
        border: "none",
        width: "93%"
    },
    formlabel: {
        fontFamily: "inter",
        fontSize: "16px",
        color: "#111827",
        fontWeight: 600,
    },
    formlabel2: {
        marginTop: "15px",
        fontSize: "16px",
        fontFamily: "inter",
        fontWeight: 600,
        color: "#111827",
    },
    formlabel3: {
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "inter",
        marginTop: "15px",
        marginBottom: "15px",
        color: "#111827",
    },
    dateField: {
        height: "40px",
         width: "93%",
        backgroundColor: "#F9FAFB",
        border: "0.2px solid gray",
        borderRadius: "8px"
    },
    switchBtn: {
        marginTop: "10px",
        marginRight: "30px",
        fontSize: "16px",
        fontFamily: "inter",
        fontWeight: 600,
        color: "#111827",
    },
    diaHeader: {
        display: "flex",
        flexDirection: "column"
    } as React.CSSProperties,
    closeButton: {
        alignSelf: "end",
    },
};

// Customizable Area End


