Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";
exports.googleSignupEndPoint = 'account_block/create_social_account'
exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.emailRegx = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ 
exports.phoneRegx = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
exports.urlRegx = /^(https?:\/\/)?([A-Za-z0-9\-]+\.)+[A-Za-z]{2,}(\/\S*)*$/
exports.pageTitle = "Complete Signup"
exports.resturantPageTitle = "Restaurant Images"
exports.fillAccountPageTitle = "Fill Your Account Details"
exports.emailVerifyErrorMessage = "Please enter a valid Email"
exports.signUpLoc = "mapImg"
exports.signupOTPPageTitle = "Confirm Your Email Address"
exports.validationApiMethodType = "GET";
exports.error = "Please enter a Password"
exports.errorFour= "Password and Confirm password doesn't match"
exports.retypeError = "Please enter confirm Password"
exports.errorTwo=  "Password is too short"
exports.errorThree =  "Password is too short"
exports.errorFive = "Please enter Full OTP"
exports.errorFullNameMin = "Please enter atleat 3 character."
exports.fullNameRegex = /^[a-zA-Z][a-zA-Z\\s]+$/;
exports.errroMsgFullName = "Full name should be only alphabets."
exports.succesOTPMsg ="An OTP has been sent to your Email ID successfully"
exports.errorSix = 'Password must be at least 8 characters and must include at least one upper case letter, one lower case letter, one special character, and one numeric digit.'
exports.countryCode = "+91"
exports.errOTPMessage = "Entered OTP is incorrect."
exports.emailID= "Email ID"
exports.phoneNumber = "Mobile Number"
exports.exitAppMessage = "Are you sure you want to exit app?"
exports.holdOnMessage = 'Hold on!'
// Customizable Area End
