import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Paper, FormControl, FormHelperText, RadioGroup, FormControlLabel, Radio
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Customizable Area End

// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            marginTop: "15px",
            textTransform: "none",
            padding: "20px",
            fontSize: "16px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);
const rem = "Selection is Required"
// Customizable Area End

import SignUpControllerWeb, {
    Props,
    configJSON,
} from './SignUpControllerWeb';

export default class CompleteSignUp extends SignUpControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    GenRadioDiv = (formikFn: any, inputName: any, title: string, optionArr: any, InputObj?: any, currencyType?: boolean) => {
        return (
            <div style={webStyleSignup.formWrapper}>

                <FormControl variant="standard" fullWidth>
                    <Typography variant="h6">{title}</Typography>
                    {!InputObj && <RadioGroup
                        row={true}
                        name={inputName}
                        onChange={formikFn.handleChange}
                    >
                        <div style={webStyleSignup.radioBtnContainer}>
                            {optionArr.map((data: any) => {
                                return (
                                    <FormControlLabel control={<Radio color="primary" />} value={data.value} label={<Typography variant="subtitle1">{data.label}</Typography>} />
                                )
                            })}

                        </div>
                    </RadioGroup>}

                    {InputObj &&
                        <div style={{ display: 'flex' }}>
                            {currencyType && <select onChange={formikFn.handleChange}
                                value={formikFn.values.currency_type}
                                style={webStyleSignup.selectRage} name="currency_type" >
                                <option value="KWD">KD</option>
                                <option value="USD">USD</option>
                            </select>}
                            <input placeholder={InputObj.placeholder}
                                onChange={formikFn.handleChange} type={InputObj.type} step={InputObj.step}name={inputName} style={webStyleSignup.formInput} />
                        </div>
                    }

                    {
                        formikFn.touched[inputName] &&
                        formikFn.errors[inputName] &&
                        (<FormHelperText error={true} style={{ fontSize: "16px" }}>
                            {formikFn.errors[inputName]}
                        </FormHelperText>
                        )
                    }
                </FormControl>

            </div>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyleSignup.root}>
                    {/* @ts-ignore */}
                    <Paper style={webStyleSignup.formdiv}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                            <Grid item style={webStyleSignup.welcomeFormDiv} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <IconButton data-test-id='goBackBtn' onClick={this.handleGoBack} style={webStyleSignup.backbutton}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{configJSON.pageTitle}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Formik
                                            data-test-id='formikId'
                                            initialValues={{
                                                currency_type: 'KWD',
                                                place_type: '',
                                                parking_availability: '',
                                                valet_parking: '',
                                                serves_alcohol: '',
                                                kids_area: '',
                                                dining: '',
                                                Shisha: '',
                                                category: '',
                                                smoking_area: '',
                                                speciality: '',
                                                avg_price_two: '',
                                                start_hours: '',
                                                start_mins: '',
                                                start_time_type: 'AM',
                                                end_hours: '',
                                                end_mins: '',
                                                end_time_type: 'AM'
                                            }}
                                            validateOnBlur={false}
                                            validationSchema={Yup.object().shape({
                                                place_type: Yup.string().required(rem),
                                                parking_availability: Yup.string().required(rem),
                                                valet_parking: Yup.string().required(rem),
                                                serves_alcohol: Yup.string().required(rem),
                                                kids_area: Yup.string().required(rem),
                                                dining: Yup.string().required(rem),
                                                Shisha: Yup.string().required(rem),
                                                category: Yup.string().required(rem),
                                                smoking_area: Yup.string().required(rem),
                                                speciality: Yup.string().required("Can't be Empty"),
                                                avg_price_two: Yup.number()
                                                .typeError('Must be a number')
                                                .positive('Must be a positive number')
                                                .test('is-float-or-integer', 'Must be an integer or a float', value => {
                                                  if (value === undefined) return true;
                                                  return /^[+-]?\d+(\.\d+)?$/.test(value);
                                                })
                                                .required('Required'),
                                                start_hours: Yup.string().required("Can't be Empty hours").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
                                                start_mins: Yup.string().required("Can't be Empty minutes").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
                                                start_time_type: Yup.string().required("Can't be Empty"),
                                                end_hours: Yup.string().required("Can't be Empty hours").test('Hours', "Hours can't be more than 12", val => val * 1 <= 12),
                                                end_mins: Yup.string().required("Can't be Empty minutes").test('Mins', "Minutes can't be more than 59", val => val * 1 <= 59),
                                                end_time_type: Yup.string().required("Can't be Empty")
                                            })}
                                            onSubmit={(dataObject) => {
                                                this.completeProfile(dataObject)
                                            }}
                                            render={({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit
                                            }) => {
                                                return (
                                                    // @ts-ignore
                                                    <form onSubmit={handleSubmit} style={webStyleSignup.formTest}>


                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'parking_availability', 'Parking Availability',
                                                            [{ label: 'Yes', value: "true" }, { label: 'No', value: "false" }]
                                                        )}

                                                        {this.GenRadioDiv(
                                                            //@ts-ignore
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'valet_parking', 'Valet Parking Availability',
                                                            [{ label: 'Yes', value: "true" }, { label: 'No', value: "false" }]
                                                        )}

                                                        <div style={webStyleSignup.formWrapper}>
                                                            <Typography variant="h6">
                                                                Working Hours
                                                            </Typography>
                                                            <div style={webStyleSignup.timeConatiner}>
                                                                <div style={webStyleSignup.timeDiv}>
                                                                    <input onChange={handleChange} name="start_hours" type='text' data-test-id="srtHrsInp"
                                                                        placeholder='hh' style={webStyleSignup.inputTime} />
                                                                    <h3>:</h3>
                                                                    <input onChange={handleChange} name="start_mins" type='text' placeholder='mm' style={webStyleSignup.inputTime} />
                                                                    <select onChange={handleChange} name="start_time_type" style={webStyleSignup.selectHrs}>
                                                                        <option value="AM">AM</option>
                                                                        <option value="PM">PM</option>
                                                                    </select>
                                                                </div>
                                                                <span>to</span>
                                                                <div style={webStyleSignup.timeDiv}>
                                                                    <input onChange={handleChange} name="end_hours" type='text' placeholder='hh' style={webStyleSignup.inputTime} />
                                                                    <h3>:</h3>
                                                                    <input onChange={handleChange} name="end_mins" type='text' placeholder='mm' style={webStyleSignup.inputTime} />
                                                                    <select onChange={handleChange} name="end_time_type" style={webStyleSignup.selectHrs}>
                                                                        <option value="AM">AM</option>
                                                                        <option value="PM">PM</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <FormHelperText
                                                                error={true}
                                                                style={{ fontSize: "16px" }}
                                                            >
                                                                {
                                                                    errors.start_hours ||
                                                                    errors.start_mins ||
                                                                    errors.start_time_type ||
                                                                    errors.end_hours ||
                                                                    errors.end_mins ||
                                                                    errors.end_time_type
                                                                }
                                                            </FormHelperText>  
                                                        </div>

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'serves_alcohol', ' Serves Alcohol',
                                                            [{ label: 'Yes', value: "true" }, { label: 'No', value: "false" }]
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'kids_area', 'Kids Area',
                                                            [{ label: 'Yes', value: "true" }, { label: 'No', value: "false" }]
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'dining', 'Dining',
                                                            [{ label: 'Fine', value: "0" },
                                                            { label: 'Casual', value: "1" },
                                                            { label: 'Both', value: "2" }
                                                            ]
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'speciality', 'Speciality', '',
                                                            { placeholder: "type speciality", type: "text" }
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'Shisha', 'Shisha',
                                                            [{ label: 'Yes', value: "true" }, { label: 'No', value: "false" }]
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'category', 'Category',
                                                            [
                                                                { label: 'Veg', value: "0" },
                                                                { label: 'Non-veg', value: "1" },
                                                                { label: 'Both', value: "2" }
                                                            ]
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'smoking_area', 'Smoking Area',
                                                            [{ label: 'Yes', value: "true" }, { label: 'No', value: "false" }]
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit, values },
                                                            'avg_price_two', 'Average price for 2 people (approx.)', '',
                                                            { placeholder: "type here", type: "text" ,step:"0.1"
                                                        }, true
                                                        )}

                                                        {this.GenRadioDiv(
                                                            { errors, touched, handleChange, handleBlur, handleSubmit },
                                                            'place_type', 'Type of Place',
                                                            [
                                                                { label: 'Shisha', value: "0" },
                                                                { label: 'Premium', value: "1" },
                                                                { label: 'Outdoor', value: "2" }
                                                            ]
                                                        )}

                                                        <StyledButton data-test-id="formSubmitBtn" fullWidth type="submit" variant="contained">
                                                            Next
                                                        </StyledButton>
                                                    </form>
                                                )
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
    },
    typography: {
        "fontFamily": `inter, sans-serif`,
        h5: {
            fontSize: "35px",
            fontWeight: 500,
        },
        h6: {
            fontSize: "16px",
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: "14px",
            fontWeight: 400,
            whiteSpace: "nowrap",
            color: "#4B5563"
        }
    }
});

const webStyleSignup = {
    formdiv: {
        maxWidth: "488px",
        margin: "0 auto",
        padding: "30px 0 24px 0px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "40px",
        minHeight: "620px",
        position: "relative"
    },
    welcomeFormDiv: {
        width: "329px",
        height: "auto"
    },
    formInput: {
        backgroundColor: "#F9FAFB;",
        width: "100%",
        height: "40px",
        fontSize: "16px",
        paddingLeft: "20px",
        margin: "16px 0px",
        alignSelf: "center",
        border: "1px solid #E5E7EB",
        borderRadius: "8px 8px 8px 8px"
    },
    selectRage: {
        backgroundColor: "#F9FAFB",
        height: "40px",
        fontSize: "16px",
        margin: "16px 0px",
        border: "1px solid #E5E7EB",
        borderRadius: "8px 0px 0px 8px"
    },
    backbutton: {
        border: "2px solid #F3F4F6",
    },
    formTest: {
        display: "flex",
        flexDirection: "Column"
    },
    root: {
        padding: "50px 0",
        overflowX: "hidden"
    },
    formWrapper: {
        padding: "10px 10px 0 10px",
        border: "1px solid #E5E7EB",
        borderRadius: "8px",
        marginBottom: "15px"
    },
    radioBtnContainer: {
        width: "75%",
        display: "flex",
        justifyContent: "space-between"
    },
    timeConatiner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
    timeDiv: {
        display: "flex",
        alignItems: "center",
        gap: '5px'
    },
    inputTime: {
        width: "32px",
        height: "32px",
        padding: "5px",
        backgroundColor: "#f3f4f6",
        border: "none",
        borderRadius: "2px"
    },
    selectHrs: {
        height: "32px",
        padding: "5px",
        marginLeft: "5px",
        backgroundColor: "#f3f4f6",
        border: "none",
        borderRadius: "2px"
    },
}
// Customizable Area End


