Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Reservations";
exports.labelBodyText = "Reservations Body";

exports.btnExampleTitle = "CLICK ME";
exports.reservedTime = ['12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '07:00 PM', '07:30 PM', '08:00 PM', '08:30 PM', '09:00 PM', "09:30 PM", "10:00 PM"];
exports.waitingTime=["10 min", "15 min", "20 min", "25 min", "30 min", "35 min", "40 min", "45 min", "50 min", "1 hours"]
exports.creatField = { customerName: "Customer Name",  customerEmail: "Customer Email", customerPhone: "Customer Phone", NoofPeople: "Number of People",reservationDate:"Date",reservationTime:"Reservation Time",WaitingTime:"Waiting Time" ,specialOccation: "Special Occasion"} 
exports.reservationServingDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
exports.orderList = { 'sunday': 0, 'monday': 1, 'tuesday': 2, 'wednesday': 3, 'thursday': 4, 'friday': 5, 'saturday': 6 }
// Customizable Area End