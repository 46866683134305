import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid, Select, MenuItem, Checkbox, Collapse, RadioGroup, Radio, ListItemText
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PulseLoader from "react-spinners/PulseLoader";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const BlueRadio = withStyles({
  root: {
    "&$checked": {
      color: "rgba(9, 111, 255, 1)"
    }
  },
  checked: {}

})(({ checked, ...props }: any) => <Radio color="default" checked={checked} {...props} />);

export const CustomCheckbox: React.ComponentType<any> = withStyles({
  root: {
    "&$checked": {
      color: '#096fff',
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);
// Customizable Area End

import ReservationsController, {
  Props,
  configJSON,
} from "./ReservationsController";

export default class Reservations extends ReservationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderWaitingTimeSection() {
    const { WaitingList, reservationSelect, errorMessage } = this.state;

    if (!WaitingList) {
      return null;
    }

    return (
      <Grid style={{ display: 'grid', marginBottom: '15px' }}>
        <label style={webStyle.labelfont}>Waiting Time</label>
        <Select
          name='WaitingTime'
          variant="outlined"
          fullWidth
          displayEmpty
          data-test-id="WaitingTime"
          style={webStyle.selectmenuWaiting}
          value={reservationSelect.WaitingTime}
          onChange={(e) => this.handleChangeSelect(e)}
          MenuProps={{
            disableScrollLock: false,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          }}
        >
          <MenuItem value="" selected disabled style={{ color: "gray" }}>Select a waiting time</MenuItem>
          {configJSON.waitingTime.map((obj: any, index: any) => (
            <MenuItem value={obj} key={index}>{obj}</MenuItem>
          ))}
        </Select>
        {errorMessage.WaitingTime && (
          <div style={{ color: 'red' }}>{errorMessage.WaitingTime}</div>
        )}
      </Grid>
    );
  }

  renderReservationDateTime() {
    const timeSlotOfDay = this.state.reservationDateTime.reservationDate ? this.state.timeSlots[this.getCurrectDay()] : [];
    return (
      <Grid container style={{ display: 'flex', justifyContent: "space-between", width: '81%', marginBottom: '15px' }} lg={10}>
        <Grid item xs={12} md={6} lg={4} style={{ display: 'grid' }}>
          <label style={webStyle.labelfont}>
            Reservation Date
          </label>
          <input
            name="reservationDate"
            type="date"
            data-test-id="reservationTime"
            value={this.state.reservationDateTime.reservationDate}
            onChange={(e) => this.handleChangeDateTime(e)}
            min={new Date().toISOString().split('T')[0]}
            style={webStyle.inputbox}
          />
          {this.state.errorMessage.reservationDate && (
            <div style={{ color: 'red' }}>{this.state.errorMessage.reservationDate}</div>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={4} style={{ marginRight: '30px', display: 'grid' }}>
          <label style={webStyle.labelfont}>
            Reservation Time
          </label>
          <Select
            variant="outlined"
            fullWidth
            displayEmpty
            name='reservationTime'
            style={webStyle.selectmenu}
            data-test-id="reservationTimeDate"
            value={this.state.reservationDateTime.reservationTime}
            onChange={(e) => this.handleChangeDateTime(e)}
          >
            <MenuItem value="" selected disabled >Choose a time</MenuItem>
            {timeSlotOfDay.map((obj: any, index: any) => (
              <MenuItem value={obj} key={index}>
                {obj}
              </MenuItem>
            ))}
          </Select>
          {this.state.errorMessage.reservationTime && (
            <div style={{ color: 'red' }}>{this.state.errorMessage.reservationTime}</div>
          )}
        </Grid>
      </Grid>
    );
  }

  renderSpecialOccasion() {
    if (this.state.specialOccassion === true) {
      return (
        <Grid item xs={12} md={6} lg={4} style={{ marginRight: '20px', display: 'grid', gap: '12px' }}>
          <Select
            name='specialOccation'
            variant="outlined"
            fullWidth
            displayEmpty
            data-test-id="specialOccation"
            style={webStyle.selectmenu}
            value={this.state.reservationSelect.specialOccation}
            onChange={(e) => this.handleChangeSelect(e)}
            MenuProps={{
              disableScrollLock: false,
              getContentAnchorEl: null,
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
          >
            <MenuItem value="" selected disabled>Select a special occasion</MenuItem>
            {this.state.specialOccasion?.map((value: any, index: any) => (
              <MenuItem value={value.name} key={index}> {value.name} </MenuItem>
            ))}
          </Select>
          {this.state.errorMessage.specialOccassion && (
            <div style={{ color: 'red' }}>{this.state.errorMessage.specialOccassion}</div>
          )}
        </Grid>
      );
    }
    return null;
  }

  renderAvailableTables() {
    let content = null;

    if (this.state.isLoader === true) {
      content = <PulseLoader color="#096fff" style={{
        position: 'relative',
        top: '35px',
        left: '50px'
      }} />;
    } else if (this.state.availabelTable && this.state.availabelTable.length > 0) {
      content = this.state.availabelTable?.map((table: any, id: any) => (
        <div style={{ ...webStyle.secondbox, marginTop: '20px' }} key={id}>
          <div style={{ ...webStyle.Roomfont }}>
            <label onClick={() => this.handleAvailableTable(table.id)} style={{ cursor: 'pointer', display: 'flex' }} data-test-id="handleAvailableTable">
              <span style={{
                maxWidth: '230px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>{table.attributes.name}</span> ({table.attributes.room_layout_properties.filter((tabs: any) => tabs.available_for_booking).length} tables available)
            </label>
            <Collapse in={this.state.showTable[table.id]} timeout="auto" unmountOnExit style={{ marginTop: '-10px' }}>
              <Grid>
                <RadioGroup style={{ marginTop: '10px' }} data-test-id="loungeRoomTable">
                  {table.attributes?.room_layout_properties.filter((tabs: any) => tabs.available_for_booking).length > 0 ? (
                    table.attributes.room_layout_properties
                      .filter((tabs: any) => tabs.available_for_booking)
                      .map((tableName: any, tableID: any) => (
                        <label key={tableID} htmlFor={`rs_${tableName.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                          <BlueRadio
                            name="room"
                            id={`rs_${tableName.id}`}
                            value={tableName.id}
                            checked={this.state.RoomTableSelect.includes(tableName.id.toString())}
                            onClick={(e: any) => this.handleRoomTableChange(e)}
                            data-test-id="handleRoomTableChangeid"
                          />
                          <span style={{
                            maxWidth: '300px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}>{tableName?.table_no || `Table ${tableName.id}`}</span>
                        </label>
                      ))
                  ) : (
                    <p>No available tables found</p>
                  )}
                </RadioGroup>
              </Grid>
            </Collapse>
          </div>
          {this.state.showTable[table.id] ? (
            <ExpandLessIcon style={webStyle.arrowright} onClick={() => this.handleAvailableTable(table.id)} data-test-id='icon' />
          ) : (
            <ExpandMoreIcon style={webStyle.arrowright} onClick={() => this.handleAvailableTable(table.id)} data-test-id='icon' />
          )}
        </div>
      ));
    } else {
      content = <p>No available tables found</p>;
    }

    return content;
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={{ height: '100%' }}>
          <Grid item sm={12} style={{ height: '100%' }}>
            <h2 style={webStyle.needheading}>My Orders</h2>
            <Grid style={webStyle.wrapperSubscreption}>
              <Grid item lg={7}>
                <h1 style={webStyle.heading}>Create Reservation</h1>
                <Grid style={webStyle.mb20}>
                  <label style={webStyle.labelfont}>
                    Customer Name
                  </label>
                  <br />
                  <input
                    data-test-id='input' type='text' name="customerName"
                    placeholder="Enter customer's name"
                    value={this.state.reservationDetails.customerName}
                    onChange={this.handleChange}
                    style={webStyle.formInput} />
                  {this.state.errorMessage.customerName && (
                    <div style={{ color: 'red' }}>Customer Name should be at least 3 characters</div>
                  )}

                </Grid>
                <Grid style={webStyle.mb20}>
                  <label style={webStyle.labelfont}>
                    Customer Email ID
                  </label>
                  <br />
                  <input
                    data-test-id='input' type='text' name="customerEmail"
                    placeholder="Enter customer's email id"
                    value={this.state.reservationDetails.customerEmail}
                    onChange={this.handleChange}
                    style={webStyle.formInput} />
                  {this.state.errorMessage.customerEmail && (
                    <div style={{ color: 'red' }}>{this.state.errorMessage.customerEmail}</div>
                  )}

                </Grid>
                <Grid style={webStyle.mb20}>
                  <label style={webStyle.labelfont}>
                    Customer Phone No.
                  </label>
                  <br />
                  <input
                    data-test-id='input' type='text' name="customerPhone"
                    placeholder="Enter customer's contact number"
                    value={this.state.reservationDetails.customerPhone}
                    onChange={this.handleChange}
                    style={webStyle.formInput} />
                  {this.state.errorMessage.customerPhone && (
                    <div style={{ color: 'red' }}>Phone number should be between 7 to 15 digits</div>
                  )}
                </Grid>
                <Grid style={webStyle.mb20}>
                  <label style={webStyle.labelfont}>
                    No. of People
                  </label>
                  <br />
                  <input
                    data-test-id='input' type='text' name="NoofPeople"
                    placeholder="total no of people"
                    value={this.state.reservationDetails.NoofPeople}
                    onChange={this.handleChange}
                    onKeyPress={this.onlyEnterNumber}
                    style={webStyle.formInput} />
                  {this.state.errorMessage.NoofPeople && (
                    <div style={{ color: 'red' }}>{this.state.errorMessage.NoofPeople}</div>
                  )}
                </Grid>
                <Grid style={{ marginBottom: '15px', marginLeft: '-10px' }}>
                  <CustomCheckbox data-test-id="checkboxWaiting" onChange={this.handleWaitingListChange} /> <span style={webStyle.labelfont}>Apply for Waiting List</span>
                </Grid>
                {this.renderWaitingTimeSection()}
                {this.state.WaitingList === false && this.renderReservationDateTime()}
                <Grid container style={{ display: 'flex', justifyContent: "space-between", width: '81%' }} lg={10}>
                  <Grid item xs={12} md={6} lg={4} style={{ display: 'grid', gap: '12px' }}>
                    <label style={webStyle.labelfont}>User Dietary Restriction</label>
                    <Select
                      name='UserDietary'
                      variant="outlined"
                      fullWidth
                      displayEmpty
                      data-test-id="UserDietary"
                      multiple
                      style={webStyle.selectmenu}
                      value={this.state.dietarySelect.UserDietary}
                      onChange={(e) => this.handleDietaryChange(e)}
                      renderValue={(selected: any) => (
                        <div>
                          {Array.isArray(selected) && selected.map((value: any) => (
                            <span key={value}>{value}, </span>
                          ))}
                        </div>
                      )}
                      MenuProps={{
                        disableScrollLock: false,
                        getContentAnchorEl: null,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                      }}
                    >
                      {this.state.Dietary.map((obj: any, i: any) => (
                        <MenuItem value={obj.name} key={i}> <CustomCheckbox
                          checked={this.state.dietarySelect.UserDietary.indexOf(obj.name) > -1}
                        />
                          <ListItemText primary={obj.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} style={{ marginRight: '30px', display: 'grid', gap: '12px' }}>
                    <label style={webStyle.labelfont}>User Food Preferences</label>
                    <Select
                      name='GuestDietary'
                      variant="outlined"
                      fullWidth
                      displayEmpty
                      data-test-id="GuestDietary"
                      multiple={true}
                      style={webStyle.selectmenu}
                      value={this.state.dietarySelect.GuestDietary}
                      onChange={(e) => this.handleDietaryChange(e)}
                      renderValue={(select: any) => (
                        <div>
                          {Array.isArray(select) && select.map((obj: any) => (
                            <span key={obj}>{obj}, </span>
                          ))}
                        </div>
                      )}
                      MenuProps={{
                        disableScrollLock: false,
                        getContentAnchorEl: null,
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                      }}
                    >
                      {this.state.FoodPrefrences.map((value: any, index: any) => (
                        <MenuItem value={value.name} key={index}> <CustomCheckbox
                          checked={this.state.dietarySelect.GuestDietary.indexOf(value.name) > -1}
                        />
                          <ListItemText primary={value.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid style={{ marginTop: '15px', marginLeft: '-10px' }}>
                  <CustomCheckbox data-test-id="checkbox" onChange={this.handleCheckboxChange} /> <span style={webStyle.labelfont}>Special Occasion</span>
                </Grid>
                {this.renderSpecialOccasion()}
                <Grid style={{ marginTop: '15px' }}>
                  <label style={{ ...webStyle.labelfont }} >
                    Available Table
                  </label>
                  {this.renderAvailableTables()}
                </Grid>
                <Grid style={webStyle.btndiv}>
                  <button
                    style={webStyle.btntransparent}
                    data-testid="cancel"
                    onClick={this.reservationCancel}
                  >
                    Cancel
                  </button>

                  <button
                    style={webStyle.btntheme}
                    data-testid="submit"
                    onClick={this.createReservationPostAPI}
                  >
                    Next
                  </button>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  needheading: {
    fontSize: "24px",
    fontWeight: 600,
    color: "#111111",
    margin: "0",
    marginTop: '6px'
  },

  wrapperSubscreption: {
    backgroundColor: "#FFFFFF",
    boxShadow: "20px 10px 40px rgb(0 0 0 / 12%)",
    borderRadius: "8px",
    padding: "25px 30px",
    height: 'auto',
    marginTop: '30px',
    marginRight: '25px'
  },
  secondbox: {
    width: '80%',
    marginBottom: "20px",
    border: '1px solid rgb(213, 213, 213)',
    borderRadius: '4px',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrowright: {
    margin: '10px',
    height: '25px',
    width: '25px',
    cursor: 'pointer'
  },
  heading: {
    fontSize: "24px",
    marginBottom: "30px",
  },

  mb20: {
    marginBottom: "20px",
  },

  labelfont: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
  },

  Roomfont: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    paddingLeft: '20px',
    paddingTop: '13px'
  },
  formInput: {
    background: 'rgb(244, 247, 249)',
    color: '#001F49',
    width: "80%",
    height: "40px",
    fontSize: "14px",
    borderRadius: "8px",
    margin: "10px 0px",
    border: "1px solid #E5E7EB",
    padding: '8px',
    outline: 'none',
  },

  btntransparent: {
    background: "#fff",
    border: "1px solid #096fff",
    color: "#096fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    marginRight: "15px",
    marginTop: '12px',
    cursor: 'pointer'
  },

  btntheme: {
    background: "#096fff",
    border: "1px solid #096fff",
    color: "#fff",
    minHeight: "64px",
    minWidth: "200px",
    borderRadius: "8px",
    fontSize: "15px",
    marginTop: '12px',
    cursor: 'pointer'
  },
  btndiv: {
    marginTop: "35px",
    marginBottom: '35px'
  },
  selectmenu: {
    height: "40px",
    margin: "2px",
    borderRadius: "8px",
    padding: "3px",
    fontSize: "14px",
    background: '#F3F3F3',
    maxWidth: '240px',
    color: 'rgb(45, 41, 41)'
  },
  selectmenuWaiting: {
    height: "40px",
    margin: "2px",
    borderRadius: "8px",
    padding: "3px",
    fontSize: "14px",
    background: '#F3F3F3',
    width: '79%',
    marginTop: '15px',
    color: 'rgb(45, 41, 41)'
  },
  inputbox: {
    width: "100%",
    height: "40px",
    marginBottom: "15px",
    borderRadius: "5px",
    fontSize: "14px",
    marginTop: "14px",
    maxWidth: '230px'
  }
};
// Customizable Area End
