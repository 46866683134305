import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ToastMessage from "../../../components/src/ToastMessage";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    classes?: any;
    handleClose: () => void;
    show: boolean;
    showDiscountCouponId: any;
    editCouponId: any;
    // Customizable Area End
}

interface S {

    // Customizable Area Start
    BookingOpen: boolean;
    radioValue: string;
    couponName: string;
    discount: any;
    uptoAmount: any | number;
    maxQuantity: any | number;
    CouponCode: any;
    discountType: any;
    offerValidFromDate: any;
    offerValidUpto: any;
    quantity: number;
    description: string;
    // checkedB: any;
    imageFile: any | ArrayBuffer | null | unknown | File;
    showCouponData: any;
    showCouponImgData: any;
    searchCoupon: string;
    isLoading: boolean;
    openFilter: boolean;
    active: boolean;
    filter1: boolean;
    filter2: boolean;
    showEditimg: boolean;
    deleteImg: boolean;
    editedImgData: any;
    showCodeError: boolean;
    showCodeErrorData: any;
    email: any;

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ShowDiscountCouponController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showCouponCallId: any;
    editDiscountCouponCallId: any;
    editImgCallId: any;
    deleteImgCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            email: '',
            BookingOpen: true,
            radioValue: "",
            couponName: "",
            discount: "",
            quantity: 0,
            uptoAmount: 0,
            maxQuantity: 0,
            discountType: "% percentage",
            CouponCode: "",
            offerValidFromDate: "",
            offerValidUpto: "",
            description: "",
            // checkedB: "",
            showCouponData: "",
            imageFile: "",
            showCouponImgData: "",
            searchCoupon: "",
            isLoading: false,
            openFilter: false,
            active: false,
            filter1: false,
            filter2: false,
            showEditimg: false,
            deleteImg: false,
            editedImgData: "",
            showCodeError: false,
            showCodeErrorData: "",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }
    // Customizable Area End


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.HandleShowCoupnCall(from, message)
        this.HandleEditDiscountCouponCall(from, message)
        // Customizable Area End
    }
    // Customizable Area End


    // Customizable Area Start
  
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.show !== this.props.show) {
            this.showCoupnCall();
        }
    }

    godate = (y:any) => {
        let px =y.split("T")
        return `${px[0]}`
    }

    ReaderImg = (e: any) => {
        const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
        const fileSizeLimitInMB = 5;
        if (!e.target.files || e.target.files.length === 0) {
            ToastMessage('error', 'Please select an image file.');
            return;
        }
        const file = e.target.files[0];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB <= fileSizeLimitInMB) {
            if (allowedFormats.includes(file.type)) {
                this.setState({ imageFile: file,showCouponImgData: "" });
            } else {
                ToastMessage('error', `File '${file.name}' is not in PNG, JPEG, or JPG format.`);
            }
        } else {
            ToastMessage('error', `File '${file.name}' exceeds the ${fileSizeLimitInMB}MB file size limit.`);
        }
    }

    handleChange = (event: any) => {
        this.setState({showCouponData:{ ...this.state.showCouponData, coupon_status: !this.state.showCouponData.coupon_status }})
    }

    handleClose = () => {
        this.setState({ BookingOpen: false })
    };

    handleChangeDiscount = async(event1:any) => {
        if (!isNaN(event1.target.value)) {
        this.setState({ showCouponData: { ...this.state.showCouponData, discount: event1.target.value }})
        }
      }

    handleChangeUptoAmount = async(event2:any) => {
        if (!isNaN(event2.target.value)) {
            this.setState({ showCouponData: { ...this.state.showCouponData, up_to_ammount: event2.target.value } })
        }
      } 
      
    handleChangeMaxQuantity = async(event3:any) => {
        if (!isNaN(event3.target.value)) {
            this.setState({ showCouponData: { ...this.state.showCouponData, max_quantity: event3.target.value } })
        }
      }   

    handleUpdatedDateValidation = (event:any) => {
        this.setState({ showCouponData: { ...this.state.showCouponData, valid_from: event.target.value } })
        const currentDate = new Date();
        
        if (event.target.value < currentDate ) {
          ToastMessage("error",'Date should not be earlier than current date');
          this.setState({ showCouponData: { ...this.state.showCouponData, valid_from: "" } })
        }
        else if (this.state.showCouponData.valid_to && event.target.value > this.state.showCouponData.valid_to) {
          ToastMessage("error",'Offer valid date should not be older than offer valid upto date');
          this.setState({ showCouponData: { ...this.state.showCouponData, valid_from: "" } })
        }
      }
    
      handleUpdatedDateValidationTo = (event:any) => {
        this.setState({ showCouponData: { ...this.state.showCouponData, valid_to: event.target.value } })
        if (event.target.value < this.state.showCouponData?.valid_from) {
          ToastMessage("error","Offer valid updo date should not be earlier than offer valid from date")
          this.setState({ showCouponData: { ...this.state.showCouponData, valid_to: "" } })
        }
      }

    showCoupnCall = () => {

        let EditId = localStorage.getItem("editId")
        console.log("edit id..", EditId);

        const header = {
            "token": localStorage.getItem("authToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showCouponCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_coupon_cg/coupon_code_generator/${this.props.showDiscountCouponId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `GET`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    editDiscountCouponCall = () => {
        if ( !this.state.showCouponImgData && !this.state.imageFile ){
            ToastMessage('error',"Please add an Image")
        } 
        else {
        const header = {
            "token": localStorage.getItem("authToken")
        };

        let formdata = new FormData();
        formdata.append("data[coupon_category]", this.state.showCouponData?.coupon_category);
        formdata.append("data[coupon_name]", this.state.showCouponData?.coupon_name);
        formdata.append("data[description]", this.state.showCouponData?.description);
        formdata.append("data[code]", this.state.showCouponData?.code);
        formdata.append("data[discount_type]", this.state.showCouponData?.discount_type);
        formdata.append("data[discount]", this.state.showCouponData?.discount);
        formdata.append("data[valid_from]", this.state.showCouponData?.valid_from);
        formdata.append("data[valid_to]", this.state.showCouponData?.valid_to);
        formdata.append("data[coupon_status]", this.state.showCouponData.coupon_status);
        formdata.append("data[up_to_ammount]", this.state.showCouponData?.up_to_ammount);
        formdata.append("data[max_quantity]", this.state.showCouponData?.max_quantity);
        if (!this.state.showCouponImgData && !this.state.imageFile) {
            formdata.append("delete_image", 'true');
        }
        if (this.state.imageFile) {
            formdata.append("data[image]", this.state.imageFile);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editDiscountCouponCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_coupon_cg/coupon_code_generator/${this.props.showDiscountCouponId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PUT`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
   }

    HandleShowCoupnCall = async (from: string, message: Message) => {
        if (this.showCouponCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage('error', apiResponse.errors)
            } else {
                this.setState({
                    showCouponData: apiResponse?.data?.attributes,
                    showCouponImgData: apiResponse?.data?.attributes?.image
                })
                console.log("getting show coupon data ...", apiResponse.data);
            }
        }
    }

    HandleEditDiscountCouponCall = async (from: string, message: Message) => {
        if (this.editDiscountCouponCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                ToastMessage("error",apiResponse?.data?.attributes?.errors?.code[0])
                this.setState({showCodeErrorData: apiResponse?.data?.attributes?.errors?.code[0]})
            } else {
                console.log("edit api chal rahe...", apiResponse)
                this.setState({showCouponData: apiResponse?.data?.attributes})
                this.props.handleClose()
            }
        }
    }

    // Customizable Area End
}
