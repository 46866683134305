import React from "react";

// Customizable Area Start
import {
    Button,
    Typography,
    IconButton,
    Grid,
    Paper
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormHelperText from '@material-ui/core/FormHelperText';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// Customizable Area End

// Customizable Area Start
export const StyledButton: any = withStyles((theme: Theme) =>
    createStyles({
        root:
        {
            marginTop: "10px",
            textTransform: "none",
            padding: "20px",
            fontSize: "13px",
            backgroundColor: "#096FFF",
            color: "white",
            '&:hover': {
                opacity: "0.7",
                backgroundColor: "#096FFF"
            },
        },
    }),
)((props: any) => <Button {...props} />);
// Customizable Area End

import SignUpDetailsControllerWeb, {
    Props,
    configJSON,
} from './SignUpDetailsControllerWeb';

export default class SignUpDetails extends SignUpDetailsControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    GenInput = (formikFn: any, inputType: string, inputName: string, inputPlaceholder: string, eyeIconToggleFn?: any, eyeIconToggleState?: any) => {
        return (
            <div style={{ position: 'relative', marginTop: "-10px"}}>
                  <input

                    autoComplete='false'

                    onChange={formikFn.handleChange}

                    onBlur={formikFn.handleBlur}

                    name={inputName}
                    type={inputType}

                    style={webStyle.formInput}

                    placeholder={inputPlaceholder} 
                  />
                  {
                    formikFn.touched[inputName] 
                    &&
                    formikFn.errors[inputName] 
                    &&
                    <FormHelperText 
                      error={true} 
                      style={webStyle.errorMessage}
                    >

                    {formikFn.errors[inputName]}

                    </FormHelperText>
                }

                {eyeIconToggleFn &&
                    <IconButton 
                      onClick={eyeIconToggleFn} 
                     //@ts-ignore
                      style={webStyle.eyeIcon as React.CSSProperties}>

                        {eyeIconToggleState ? <VisibilityOffIcon /> : <VisibilityIcon />}

                    </IconButton>}
            </div>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {/* @ts-ignore */}
                <div style={webStyle.root}>

                    {/* @ts-ignore */}
                    <Paper style={webStyle.formdiv}
                    >

                        <Grid container direction="column" justifyContent="flex-start" alignItems="center">

                            <Grid item style={webStyle.welcomeFormDiv} xs={12}>

                                <Grid container spacing={2}>

                                    <Grid item>
                                        <IconButton onClick={this.handleGoBack} style={webStyle.backbutton}>

                                            <ArrowBackIcon />

                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="h5">{configJSON.fillAccountPageTitle}</Typography>
                                    </Grid>

                                    <Grid item>
                                        <Formik
                                            initialValues={{
                                                full_name: '',
                                                password: '',
                                                confirm_password: ''
                                            }}
                                            validateOnBlur={false}
                                            validationSchema={Yup.object().shape({
                                                full_name: Yup.string().required("Full Name is Required"),
                                                password: Yup.string().required('Please enter Password').min(8, 'Minimum 8 charcters Required')
                                                    .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, 'Passwords should contain a minimum of 8 characters with at least one upper case alphabet, one lower case alphabet, one special character, and one numeric digit'),
                                                confirm_password: Yup.string()
                                                    .required('Confirm Password is Required')
                                                    .oneOf([Yup.ref('password')], 'Your Passwords do not Match')
                                            })}
                                            onSubmit={(dataObject) => {
                                                this.settingUpAccountDetails(dataObject)
                                            }}
                                            render={({
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit
                                            }) => {
                                                return (
                                                    // @ts-ignore
                                                    <form onSubmit={handleSubmit} style={webStyle.formTest}>

                                                        {this.GenInput({ handleChange, handleBlur, touched, errors }, 'text', 'full_name', 'Your full name')}

                                                        {this.GenInput({ handleChange, handleBlur, touched, errors }, this.state.passwordInputTypeForPassword, 'password', 'Password', this.handlePasswordToggle, this.state.passwordEyeToggleForPassword)}

                                                        {this.GenInput({ handleChange, handleBlur, touched, errors }, this.state.passwordInputTypeForCon_Password, 'confirm_password', 'Confirm Password', this.handlePasswordToggleForCon_Password, this.state.passwordEyeToggleForCon_Password)}

                                                        <StyledButton data-test-id='formSubmitBtn' fullWidth type="submit" variant="contained">
                                                            Confirm
                                                        </StyledButton>

                                                    </form>
                                                )
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({

    palette: {
    },

    typography: {

        "fontFamily": `inter, sans-serif`,

        h5: {
            fontSize: "35px",
            marginBottom: "24px",
            fontWeight: 500,
        }

    }

});

const webStyle = {

    welcomeFormDiv: {
        width: "319px",
        height: "auto"
    },
    formdiv: {
        position: "relative",
        maxWidth: "488px",
        margin: "0 auto",
        padding: "30px 0 30px 0px",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "40px",
        minHeight: "420px",
    },

    eyeIcon: {
        position: "absolute",
        right: "10px",
        top: "10px"
    },
   
    formInput: {
        backgroundColor: "#F9FAFB",
        width: "319px",
        height: "64px",
        padding: "20px",
        border: "none",
        fontSize: "16px",
        borderRadius: "8px"
    },
   
    backbutton: {
        border: "2px solid #F3F4F6",
    },
    errorMessage: {
        paddingLeft: "10px",
        fontSize: "15px"
    },
    root: {
        padding: "50px 0",
        overflowX: "hidden",
    },
    formTest: {
        display: "flex",
        gap: "12px",
        flexDirection: "Column",
    },
    
    selectRagePlaceholder: {
        backgroundColor: "#F9FAFB",
        height: "64px",
        padding: "20px",
        fontSize: "16px",
        border: "none",
        borderRadius: "8px",
        color: "grey"
    },
    selectRage: {
        backgroundColor: "#F9FAFB",
        height: "64px",
        fontSize: "16px",
        border: "none",
        borderRadius: "8px",
        padding: "20px",
    },
}
// Customizable Area End


