// Customizable Area Start
import React from "react";
import {
  Typography,
  Grid,
  Button,
  TextField
} from "@material-ui/core";
import Box from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { user1, user2, user3, user4 } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import "./TableLayout.css"
// Customizable Area End

// Customizable Area Start

// Customizable Area End


import SuggestedInfluencersController, {Props} from "./SuggestedInfluencersController";

export default class SuggestedInfluencers extends SuggestedInfluencersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  render() {
    return (
      <Box style={{ marginBottom: '30px' }}>
            <Box className="influencers-search" >
                <Grid xs={12} lg={6} md={6}>
                  <TextField
                    style={{ width: '98%', minWidth: '120px' }}
                    data-test-id="text"
                    variant="outlined"
                    size="small"
                    placeholder="Search by name..."
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                    value = {this.state.suggestedSearch}
                    onChange={(e: any) => {
                      this.HandleSuggestedSearch(e)
                    }}
                    // onKeyPress={this.searchSuggestedInfluencersCall}
                  />
                </Grid>
          </Box>     
              <Grid container spacing={2} style={{marginTop: "50px"}}>
                {this.state.suggestedInfluencersData?.data?.length > 0 ? this.state.suggestedInfluencersData?.data?.map((item: any, i: number) => {
                  return (
                    <Grid item xs={6} sm={3} key={i}>
                      <Box
                        style={{
                          boxShadow:
                          "rgba(1, 1, 1, 0.2) 0px 5px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 2px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                          textAlign: "center",
                          display: "grid",
                          gridGap: "10px",
                          padding: "8px",
                          maxHeight: "300px",
                          minHeight: "300px"
                        }}
                      >
                        <Box style={{ display: "flex", justifyContent: "end" }}>
                          <CloseIcon
                            data-test-id="btnCloseIcon"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </Box>
                        <Box
                          style={{
                            width: "75px",
                            height: "75px",
                            borderRadius: "50%",
                            margin: "auto"
                          }}
                        >
                          <img
                            src={item.attributes.profile_picture?.url}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%"
                            }}
                          />
                        </Box>

                        <Typography
                          style={{ fontSize: "16px", fontWeight: "bold",
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',      
                         }}
                        >
                          {item.attributes.accounts.user_name !== null ? item.attributes.accounts.user_name : "unknown"}
                        </Typography>
                        {item.attributes.accounts.user_handle_name? (
                        <Typography style={{ fontSize: "14px", color: "grey",whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',
                         }}>
                          @{item.attributes.accounts.user_handle_name}
                        </Typography>
                        ) : (
                          <Typography style={{
                            fontSize: "14px",
                             color: "grey"
                          }}>
                            NA
                          </Typography>
                        )}
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={4}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              {item.attributes.accounts.posts_count}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "grey", fontSize: "10px" }}
                            >
                              Posts
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              {item.attributes.accounts.followers_count}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "grey", fontSize: "10px" }}
                            >
                              Followers
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={4}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: "bold", fontSize: "16px" }}
                            >
                              {item.attributes.accounts.followings_count}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              style={{ color: "grey", fontSize: "10px" }}
                            >
                              Following
                            </Typography>
                          </Grid>
                        </Grid>

                        <Button
                          data-test-id="ones"
                          style={{
                            textTransform: "capitalize",
                            background: "#5C8BF6",
                            color: "#fff",
                            borderRadius: "20px",
                            marginBottom: "10px"
                          }}
                          onClick={()=>this.createShortListcall(item.id)}
                        >
                          Add to Shortlist
                        </Button>
                      </Box>
                    </Grid>
                  );
                }) : <p className="noData" style={{
                  width: "305px",
                }}>Influencer Result is not found</p>}
              </Grid>
    </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const webStyle = {
  diaHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid lightGrey"
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "0px",
    color: "blue"
  },

  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
    // width: "fit-content",
  } ,
  formControl: {
    marginTop: 10,
    minWidth: 120
  } ,
  Postbtn: {
    background: "transparent",
    border: "none",
    color: "blue",
    textDecoration: "underline",
    textTransform: "capitalize"
  } 
};

export const MessageData = [
  {
    img: user1,
    name: "Samantha Trope",
    id: "@influcer_id",
    public: "1.25 K",
    followers: "1.54 K",
    following: "1,232"
  },
  {
    img: user2,
    name: "Joana",
    id: "@influcer_id",
    public: "1.25 K",
    followers: "1.54 K",
    following: "1,232"
  },
  {
    img: user3,
    name: "Sam Wikins",
    id: "@influcer_id",
    public: "1.25 K",
    followers: "1.54 K",
    following: "1,232"
  },
  {
    img: user4,
    name: "Folna Wikins",
    id: "@influcer_id",
    public: "1.25 K",
    followers: "1.54 K",
    following: "1,232"
  },
  {
    img: user1,
    name: "Samantha Trope",
    id: "@influcer_id",
    public: "1.25 K",
    followers: "1.54 K",
    following: "1,232"
  },
  {
    img: user2,
    name: "Joana",
    id: "@influcer_id",
    public: "1.25 K",
    followers: "1.54 K",
    following: "1,232"
  }
];
// Customizable Area End
