import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ToastMessage from "../../../components/src/ToastMessage";
// @ts-ignore 
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDggGoFSnzL7Vley8afXotWIAJWB4-DZcA");
Geocode.enableDebug();
import  {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { Country, State, City } from "country-state-city";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  allowAccess: boolean;
  gpsLocation: string;
  isSpinnerShowing: boolean;
  // Customizable Area Start
  getAllMyLocationData: any;
  isVisibleDeleteModal: boolean;
  addAnotherLocationModal: boolean;
  step: any;
  singleFormValues: any;
  editAnotherLocationModal: boolean;
  editStep: boolean;
  loader: boolean;
  selectedRestaurant: any;
  selectedRestaurantLocation:string;
  restaurantNo:any;
  getSingleCreateFeatureData:any;
  updateRestaurant:any;
  latitudeWeb:any;
  longitudeWeb:any;
  addressWeb:any;
  latitudeWebEdit:any;
  longitudeWebEdit:any;
  addressWebEdit:any;
  selectedRestaurantNickName:string;
  stateOfCountryAdd: any;
  citiesOfStateAdd: any;
  country: string;	
  state: string;	
  city: string;
  cityArray : any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LocationControllerWeb extends BlockComponent<Props, S, SS> {
  getCityApiCallId: any;
  updateDefaultCityApiCallId: any;
  googleMapAPIId: any;
  // Customizable Area Start
  getAllMyLocationDataId: string = "";
  deleteRestaurantLocationId: string = "";
  addAnotherLocationId: string = "";
  updateDefaultCityApiCallIdPlaceId:any
  updateRestaurantLocationId: string = "";
  getSingleRestaurantLocationId: string = "";
  createFeatureId:string="";
  updateCreateFeatureId:string=""
  getSingleCreateFeatureId:string=""
  selectLocationId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      allowAccess: true,
      gpsLocation: "",
      isSpinnerShowing: false,
      getAllMyLocationData: [],
      isVisibleDeleteModal: false,
      addAnotherLocationModal: false,
      step: false,
      editStep: false,
      singleFormValues: {},
      editAnotherLocationModal: false,
      loader: false,
      selectedRestaurant: "",
      selectedRestaurantLocation: "",
      restaurantNo:"",
      getSingleCreateFeatureData:{},
      updateRestaurant : "",
      latitudeWeb:0.00,
      longitudeWeb:0.00,
      addressWeb:"",
      latitudeWebEdit:18.5204,
      longitudeWebEdit:73.8567,
      addressWebEdit:"",
      selectedRestaurantNickName:"",
      stateOfCountryAdd: [],
      citiesOfStateAdd: [],
      country: "",	
      state: "",	
      city: "",
      cityArray : []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.getAllMyLocationDataId:
        if (responseJson) {
          this.setState({ getAllMyLocationData: responseJson.data });
        }
        break;
      case this.deleteRestaurantLocationId:
        this.deleteRestaurantLocation(responseJson)
          window.location.reload();
        break;
      case this.addAnotherLocationId:
        if (responseJson) {
          ToastMessage("success", "Location added successfully");
          this.createFeature(this.state.restaurantNo,responseJson.data.id)
        }
        break;

      case this.updateRestaurantLocationId:
        if (responseJson) {
          ToastMessage("success", "Details updated successfully");
          this.upDateCreateFeature(this.state.updateRestaurant,this.state.getSingleCreateFeatureData.id)
          window.location.reload();
        }
        break;
      case this.getSingleRestaurantLocationId:
        if (responseJson) {
          this.setState({
            singleFormValues: responseJson?.data?.attributes,
            addressWebEdit:responseJson.data?.attributes?.location, latitudeWebEdit: responseJson.data?.attributes?.latitude,  longitudeWebEdit: responseJson.data?.attributes?.longitude,
            loader: false,
          });
        }
        break;
      case this.getSingleCreateFeatureId:
        if (responseJson) {
          this.setState({
            getSingleCreateFeatureData: responseJson?.data,
          });
        }
        break;
      case this.selectLocationId:
          if (responseJson) {
            localStorage.setItem("locationID", responseJson.data.id)
            window.location.reload()
          }
          break;
      case this.createFeatureId:
        if (responseJson) {
          window.location.reload()
        }
        break;
      default:
        break;
    }
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getAllMyLocationData();
      // Set the initial pre-filled data for the dropdowns	
      const preFilledData = {	
        country: this.state.singleFormValues.country,	
        state: this.state.singleFormValues.state,	
        city: this.state.singleFormValues.city,	
      };	
      this.setState(preFilledData);
  }

  showDeleteModal = (id: any, restaurant_location:string, restaurant_nick_name:string) => {
    this.setState({ isVisibleDeleteModal: true, selectedRestaurant: id, selectedRestaurantLocation:restaurant_location,selectedRestaurantNickName: restaurant_nick_name});
  };

  hideDeleteModal = () => {
    this.setState({ isVisibleDeleteModal: false });
  };

  addAnotherLocationModal = (value: any) => {
    this.setState({addAnotherLocationModal: true});
  };

  hideAddAnotherLocationModal = () => {
    this.setState({ addAnotherLocationModal: false, step:false , latitudeWeb:0.00, longitudeWeb:0.00, addressWeb: ""});
  };

  editAnotherLocationModal = (id: any) => {
    this.setState({
      selectedRestaurant: id, editAnotherLocationModal: true, loader: true,
    });
  };

  hideEditAnotherLocationModal = () => {
    this.setState({ editAnotherLocationModal: false, editStep:false });
  };

  getAllMyLocationData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllMyLocationDataId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllLocationData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  deleteRestaurantLocation = (responseJson:any) => {
    
    if(this.state.getAllMyLocationData.length <= 1){
      return ToastMessage("error", "You can't delete this Location")
    }
    
    let restaurnantDeleteId = this.state.selectedRestaurant;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteRestaurantLocationId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetAllLocationData}/${restaurnantDeleteId}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    this.setState({ isVisibleDeleteModal: false });
  };

  getWorkingTimeString = (data: any) => {
    let s_hours = String(data.start_hours).length > 1 ? data.start_hours : `0${data.start_hours}`
    let s_mins = String(data.start_mins).length > 1 ? data.start_mins : `0${data.start_mins}`
  
    let e_hours = String(data.end_hours).length > 1 ? data.end_hours : `0${data.end_hours}`
    let e_mins = String(data.end_mins).length > 1 ? data.end_mins : `0${data.end_mins}`
  
    let starting_time = `${s_hours}:${s_mins} ${data.start_time_type}`
    let closing_time = `${e_hours}:${e_mins} ${data.end_time_type}`
    return { starting_time, closing_time }

  }

  getSingleRestaurantLocation = (id: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSingleRestaurantLocationId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetAllLocationData}/${id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  editNextSubmit = (values: any) => {
    localStorage.setItem("editFirstFormValues", JSON.stringify(values));
    this.setState({ editStep: true });
  };

  firstEditAddButton = (values: any) => {
    this.setState({ editAnotherLocationModal: false, editStep: false });
  };

  addNextButton = (values: any) => {
    localStorage.setItem("firstFormValues", JSON.stringify(values));
    this.setState({ step: true });
  };

  // Api calling function for add location first form(post method)
  addLocation = (values:any,) => {
    
    const workTime = this.getWorkingTimeString(values)

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestBody = {
      data: {  location: values.location,
      full_phone_number: values.full_phone_number,
      email: values.email,
      start_hours: values.start_hours,
      start_mins: values.start_mins,
      start_time_type: values.start_time_type,
      end_hours: values.end_hours,
      end_mins: values.end_mins,
      end_time_type: values.end_time_type,
      average_price: values.average_price,
      website: values.website,
      address: values.address,
      overview: values.overview, "oprational_hour": `${workTime.starting_time} - ${workTime.closing_time}`,
      latitude:this.state.latitudeWeb, longitude:this.state.longitudeWeb,
      restaurant_name:values.restaurant_name, restaurant_nick_name:values.restaurant_nick_name,
      city: values.city,
      state: JSON.parse(values.state).isoCode,
      country: values.country,
      currency_type:values.currency_type },
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAnotherLocationId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetAllLocationData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Api calling function for add location second form(post method)
  createFeature = (lastFormValues: any,params:any) => {
        
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestBody = {
      data: { ...lastFormValues, restaurant_location_id:params },
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFeature
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    // pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    this.createFeatureId = getValidationsMsg.messageId;

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    localStorage.removeItem("firstFormValues");
    this.setState({ step: false });
  };

  // Api calling function for update location second form(patch method)
  upDateRestaurantLocation = (
    id: any,
    values: any,
  ) => {
    let updatedrestaurnantId = this.state.selectedRestaurant;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const workTimeUpdate = this.getWorkingTimeString(values)

    const requestBody = {
      data: {
        ...values,
        "oprational_hour": `${workTimeUpdate.starting_time} -${workTimeUpdate.closing_time}`,
        "latitude": this.state.latitudeWebEdit,
        "longitude": this.state.longitudeWebEdit,
        "city": this.state.singleFormValues.city,	
        "state": this.state.singleFormValues.state,	
        "country": this.state.singleFormValues.country,
      },
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateRestaurantLocationId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetAllLocationData}/${updatedrestaurnantId}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    localStorage.removeItem("editFirstFormValues");
  };

   // Api calling function for update location second form(patch method)
  upDateCreateFeature = (
    values: any,
    id: any,
  ) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestBody = {
      data: {
        ...values,
      },
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCreateFeatureId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createFeature}/${id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    localStorage.removeItem("editFirstFormValues");
  };

  getSingleCreateFeature = (id: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSingleCreateFeatureId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.singleFeatureLocation}${id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  onChangeHandler = (data:any, id:any) => {
    
    if(id) {
      this.setState({selectedRestaurant:id})
    }
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.selectLocationId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetAllLocationData}/${id}/activate`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  preventETypeNumber = (e: any) => {
    return (e.key == "Backspace" ? false : !/^[^A-Za-z\W_]+$/.test(e.key)) && e.preventDefault()
  }

  formatTime(inputString: string) {
    const [startTimeStr, endTimeStr] = inputString.split('-');

    // Trim any leading or trailing whitespace
    const startTime = startTimeStr.trim();
    const endTime = endTimeStr.trim();

    // Parse the start time components
    const [startHoursStr, startMinsStr, startTimeType] = startTime.split(/:|\s/);
    const startHours = parseInt(startHoursStr, 10);
    const startMins = parseInt(startMinsStr, 10);
    const startMinsPadded = startMins.toString().padStart(2, '0');

    // Parse the end time components
    const [endHoursStr, endMinsStr, endTimeType] = endTime.split(/:|\s/);
    const endHours = parseInt(endHoursStr, 10);
    const endMins = parseInt(endMinsStr, 10);
    const endMinsPadded = endMins.toString().padStart(2, '0');
    // Create an object with the extracted time information
    return {
      start_hours: startHours,
      start_mins: startMinsPadded,
      start_time_type: startTimeType,
      end_hours: endHours,
      end_mins: endMinsPadded,
      end_time_type: endTimeType
    };
  }


// Handle Marker Position for Add Another Location 
handleMarkerPosition = (map:any, maps:any) => {
  
  const geocoder = new maps.Geocoder();
    const latlng = {
      lat: this.state.latitudeWeb,
      lng: this.state.longitudeWeb
    };
    geocoder.geocode({ location: latlng }, (results:any, status:any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.setState({ addressWeb: results[0].formatted_address });
        } 
      }
    });

    let mark = new maps.Marker({
      position: { lat: this.state.latitudeWeb, lng: this.state.longitudeWeb },
      map,
      draggable: true,
    });
    
    maps.event.addListener(mark, 'dragend', () => {
      const position = mark.getPosition();
      this.setState({ latitudeWeb: position.lat(), longitudeWeb: position.lng() });
      const latlng = {
        lat: position.lat(),
        lng: position.lng()
      };
      geocoder.geocode({ location: latlng }, (results:any, status:any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.setState({ addressWeb: results[0].formatted_address });
          }
         }
      });
    });

    this.componentDidUpdate = () => {
      mark.setPosition({ lat: this.state.latitudeWeb, lng: this.state.longitudeWeb });
    };
};

// Handle Marker Position for Edit or update Location 
handleMarkerPositionUpdate = (map:any, maps:any) => {
  
  const geocoder = new maps.Geocoder();
    const latlng = {
      lat: this.state.latitudeWebEdit,
      lng: this.state.longitudeWebEdit
    };
    geocoder.geocode({ location: latlng }, (results:any, status:any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.setState({ addressWebEdit: results[0].formatted_address });
        } 
      }
    });

    let mark = new maps.Marker({
      position: { lat: this.state.latitudeWebEdit, lng: this.state.longitudeWebEdit },
      map,
      draggable: true,
    });
    
    maps.event.addListener(mark, 'dragend', () => {
      const position = mark.getPosition();
      this.setState({ latitudeWebEdit: position.lat(), longitudeWebEdit: position.lng() });
      const latlng = {
        lat: position.lat(),
        lng: position.lng()
      };
      geocoder.geocode({ location: latlng }, (results:any, status:any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.setState({ addressWebEdit: results[0].formatted_address });
          }
         }
      });
    });

    this.componentDidUpdate = () => {
      mark.setPosition({ lat: this.state.latitudeWebEdit, lng: this.state.longitudeWebEdit });
    };
};

// Handle select for add location (auto complete dropdowm) 
addHandleSelect = async (value:any) => {
  try {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);
    this.setState({ addressWeb: value, latitudeWeb:lat,longitudeWeb:lng});
  } catch (error) {
    console.log("Error", error);
  }
};

// Handle select for edit location (auto complete dropdowm) 
editHandleSelect = async (value:any) => {
  try {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);
    this.setState({ addressWebEdit: value, latitudeWebEdit:lat,longitudeWebEdit:lng});
  } catch (error) {
    console.log("Error", error);
  }
};

// For Tab Functionality of Add Location 
preventTypeNumber = (e: any) => {
  const addAllowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Shift"];
  return (addAllowedKeys.includes(e.key) ? false : !/^[^A-Za-z\W_]+$/.test(e.key)) && e.preventDefault();
}

activeTest = (data:any) => {
  return data === "active" ? true : false
}

handleCountryChange = (e: any, handleChange: any,setFieldValue:any) => {
  this.setState({ stateOfCountryAdd: [], citiesOfStateAdd: [] })
  setFieldValue("state","placeholder")
  setFieldValue("city","placeholder")  
  const statesArray = State.getStatesOfCountry(e.target.value);
  this.setState({ stateOfCountryAdd: statesArray })
  handleChange(e)
};

handleStateChange = (e: any, handleChange: any,setFieldValue:any) => {
  let hh = JSON.parse(e.target.value)
  setFieldValue("city","placeholder")
  const citiesArray = City.getCitiesOfState(hh.countryCode, hh.isoCode);
  this.setState({ citiesOfStateAdd: citiesArray })
  handleChange(e)
};

handleCityChange = (e: any, handleChange: any,setFieldValue?:any) => {
  handleChange(e)
};

  // Define event handlers for updating the form values	
  handleCountryChangeEdit = (val: string) => {	
    this.setState({ singleFormValues: { ...this.state.singleFormValues, country: val } })	
  };	
  handleStateChangeEdit = (val: string) => {	
    this.setState({ singleFormValues: { ...this.state.singleFormValues, state: val } })	
  };	
  handleCityChangeEdit = (e: any) => {	
    this.setState({ singleFormValues: { ...this.state.singleFormValues, city: e.target.value } })	
  };	
    
  getCountryOptions = () => {	
    return Country.getAllCountries().map((country) => ({	
      value: country.isoCode,	
      label: country.name,	
    }));	
  };	
  getStateOptions = () => {	
    const { country } = this.state;	
    if (country) {	
      return State.getStatesOfCountry(country).map((state) => ({	
        value: state.isoCode,	
        label: state.name,	
      }));	
    }	
    return [];	
  };	
  getCityOptions = () => {
    const { country, state } = this.state.singleFormValues;	
    if (country && state) {	
      const cities = City.getCitiesOfState(state, country).map(city => ({	
        value: city.name,	
        displayValue: city.name	
    }))	
      this.setState({cityArray:[...cities]})	
        return; 	
    }	
    this.setState({cityArray:[]})	
  };	
  // Customizable Area End
}
