import React from 'react'

// Customizable Area Start
import { Grid } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
class AddCard extends React.Component<any, any> {

    render() {
        return (
            <>
                <h2>Payments {">"} Add Card</h2>
                <Grid container>
                    <Grid item sm={12}>
                        <div className="mylocation">
                            <div className='amount-section'>
                                <h3>Add Card</h3>
                            </div>
                            <Grid container>
                                <Grid item lg={5}>
                                    <div className="add-card">
                                        <input type="number" placeholder="Card number" />
                                        <input type="number" placeholder="CVV" />
                                        <input type="number" placeholder="Expiry date (mm/yy)" />
                                        <input type="text" placeholder="Name on card" />
                                        <div className="pay-btn-section">
                                            <button className="btn-transparent" onClick={()=> this.props.navigation.navigate("BuySubscription")} data-testid="cancel">Cancel</button>
                                            <button className="btn-theme" style={{ fontWeight: 600 }}>Add Card</button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default AddCard;
// Customizable Area End
