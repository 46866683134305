export const imgLocation = require("../assets/location.png");
export const imgLogo = require("../assets/logo.png");
export const deleteicon = require("../assets/delete.png");
export const kuwait = require("../assets/kuwait.png");
export const cancel = require("../assets/cancel.png");
export const pin = require("../assets/pin.png");
export const GPS = require("../assets/GPS.png");
export const Search = require("../assets/search.png");
export const location = require('../assets/navigation.png')
export const mapBG = require('../assets/mapBG.jpeg')
export const edit = require('../assets/edit.png')
export const locationpin = require('../assets/locationpin.png')
export const back = require('../assets/back.jpg')
export const check  = require('../assets/check.png')
export const CrossTwo = require("../assets/cancelTwo.png");
export const editLine = require('../assets/editLine.png')
export const locationDot = require('../assets/locationDot.png')
export const groupLocation = require('../assets/group.png')
export const groupIcone = require('../assets/groupIcone.png')

